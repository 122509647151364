import {
  ImportantLinkDetailsPageFirstDatasetExtendedModel,
  ImportantLinkDetailsPageSecondDatasetExtendedModel,
} from '../../../app/services/dme/api/types';

export const initialValues = {
  detailModel: {
    tab: '',
    link_ref_category_name: '',
    link_ref_category_id: 0,
    link_name: '',
    link_description: '',
    link_url: '',
    row_created_datetime_utc: '',
    row_modified_datetime_utc: '',
    email: '',
  } as ImportantLinkDetailsPageFirstDatasetExtendedModel,
  tagModel: [] as Array<ImportantLinkDetailsPageSecondDatasetExtendedModel>,
};
