import { Box, Button, FormControl, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Row } from 'react-table';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import {
  RolesAppAccessRefDevelopmentEnvironmentModel,
  RolesAppAccessSelectedDevelopmentEnvironmentMutateModel,
} from '../../../../app/services/dme/api/types';
import { SelectProps, SelectValue } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import { DevEnvDistinct, buildDevEnvDistinct } from '../../constants';
import AddDevelopmentEnvironmentModal, {
  AddDeveloperEnvironmentForwardProps,
  addDevelopmentEnvironmentId,
} from './AddDevelopmentEnvironmentModal';
import { useAppAccessByRolesSelectedContext } from './AppAccessByRolesSelectedContext';

const AppAccessByRolesSelectedDevelopmentEnvironment = () => {
  const {
    developmentEnvironmentDetail: { data, isLoading, isFetching },
    refDevEnvDetail,
    refAccLvlDetail,
    isEdit,
    form,
    onChangeValue,
    refetchRefTable,
  } = useAppAccessByRolesSelectedContext();

  const [devEnvDistinct, setDevEnvDistinct] = useState<DevEnvDistinct | null>(null);
  const [addDevEnv, setAddDevEnv] = useState<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel | null>(null);
  const addDeveloperEnvironmentRef = useRef<AddDeveloperEnvironmentForwardProps>(null);

  const Header = useMemo(() => {
    const headers = [];

    headers.push(
      isEdit
        ? {
            Header: 'Name',
            Cell: ({
              row: { original, index },
            }: {
              row: Row<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel>;
            }) => {
              return original.dp_role_developer_environment_id > 0 ? (
                <>{original.developer_environment_name}</>
              ) : (
                <Tooltip label={original.developer_environment_name}>
                  <FormControl
                    minW="200px"
                    isInvalid={
                      !!(
                        form?.errors.devEnv &&
                        (form.errors.devEnv as unknown as RolesAppAccessSelectedDevelopmentEnvironmentMutateModel[])[
                          index
                        ]?.ref_developer_environment_id
                      ) &&
                      !!(
                        form?.touched.devEnv &&
                        (form.touched.devEnv as unknown as RolesAppAccessSelectedDevelopmentEnvironmentMutateModel[])[
                          index
                        ]?.ref_developer_environment_id
                      )
                    }
                  >
                    <Select
                      size="sm"
                      useBasicStyles
                      maxMenuHeight={250}
                      placeholder="Select name..."
                      menuPortalTarget={document.body}
                      value={getValue(original, 'developer_environment_name')}
                      options={
                        devEnvDistinct
                          ? [
                              {
                                label: 'Add new environment',
                                value: addDevelopmentEnvironmentId,
                              },
                              ...Object.entries(devEnvDistinct).map(([key, value]) => ({
                                label: key,
                                value: getOptionValue(value.ids),
                                isDisabled: getOptionIsDisabled(value.ids),
                              })),
                            ]
                          : undefined
                      }
                      onChange={handleUpdate(original)}
                    />
                  </FormControl>
                </Tooltip>
              );
            },
          }
        : {
            Header: 'Name',
            accessor: 'developer_environment_name',
            styles: { whiteSpace: 'initial' },
          },
    );

    headers.push(
      {
        Header: 'Description',
        accessor: 'developer_environment_desc',
        styles: { whiteSpace: 'initial' },
      },
      {
        Header: 'Platform',
        accessor: 'cloud_platform',
        styles: { whiteSpace: 'initial' },
      },
      {
        Header: 'Service',
        accessor: 'cloud_service',
        styles: { whiteSpace: 'initial' },
      },
    );

    if (isEdit) {
      headers.push(
        {
          Header: 'Properties',
          styles: { whiteSpace: 'initial' },
          Cell: ({
            row: { original, index },
          }: {
            row: Row<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel>;
          }) => {
            const propertiesDistinct =
              devEnvDistinct && devEnvDistinct[original.developer_environment_name]?.environment_properties;

            return original.dp_role_developer_environment_id > 0 || !propertiesDistinct ? (
              <>{original.environment_properties}</>
            ) : (
              <Tooltip label={original.environment_properties}>
                <Box minW="200px">
                  <Select
                    size="sm"
                    useBasicStyles
                    maxMenuHeight={250}
                    placeholder="Select properties..."
                    menuPortalTarget={document.body}
                    value={getValue(original, 'environment_properties')}
                    options={
                      propertiesDistinct
                        ? Object.entries(propertiesDistinct).map(([key, value]) => ({
                            label: key,
                            value: value.ids[0],
                            isDisabled: getOptionIsDisabled(value.ids),
                          }))
                        : undefined
                    }
                    onChange={handleUpdate(original)}
                  />
                </Box>
              </Tooltip>
            );
          },
        },
        {
          Header: 'Access Level',
          styles: { whiteSpace: 'initial' },
          Cell: ({
            row: { original, index },
          }: {
            row: Row<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel>;
          }) => {
            const accessLevelDistinct =
              devEnvDistinct &&
              devEnvDistinct[original.developer_environment_name]?.environment_properties[
                original.environment_properties
              ].access_level;
            return original.dp_role_developer_environment_id > 0 || !accessLevelDistinct ? (
              <>{original.access_level}</>
            ) : (
              <Tooltip label={original.access_level}>
                <Box minW="200px">
                  <Select
                    size="sm"
                    useBasicStyles
                    maxMenuHeight={250}
                    placeholder="Select access level..."
                    menuPortalTarget={document.body}
                    value={getValue(original, 'access_level')}
                    options={
                      accessLevelDistinct
                        ? Object.entries(accessLevelDistinct).map(([key, value]) => ({
                            label: key,
                            value: value.ids[0],
                            isDisabled: getOptionIsDisabled(value.ids),
                          }))
                        : undefined
                    }
                    onChange={handleUpdate(original)}
                  />
                </Box>
              </Tooltip>
            );
          },
        },
        {
          Header: 'Action',
          styles: { whiteSpace: 'initial', textAlign: 'center' },
          Cell: ({ row: { original } }: { row: Row<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel> }) => {
            return (
              <Tooltip label="Delete">
                <IconButton
                  pr="3"
                  aria-label="Delete"
                  color="brand.error"
                  variant="link"
                  icon={<FaTrash />}
                  minWidth={1}
                  onClick={handleDelete(original)}
                />
              </Tooltip>
            );
          },
        },
      );
    } else {
      headers.push(
        {
          Header: 'Properties',
          accessor: 'environment_properties',
          styles: { whiteSpace: 'initial' },
        },
        {
          Header: 'Access Level',
          accessor: 'access_level',
          styles: { whiteSpace: 'initial' },
        },
        {
          Header: 'Last Modified',
          Cell: ({
            row: { original },
          }: {
            row: { original: RolesAppAccessSelectedDevelopmentEnvironmentMutateModel };
          }) => {
            return <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>;
          },
          styles: { whiteSpace: 'initial', width: '190px' },
        },
      );
    }

    return headers;
  }, [isEdit, form, refDevEnvDetail.data, devEnvDistinct]);

  const handleUpdate =
    (original: RolesAppAccessSelectedDevelopmentEnvironmentMutateModel): SelectProps<number>['onChange'] =>
    e => {
      if (e?.value === addDevelopmentEnvironmentId) {
        setAddDevEnv({ ...original, ref_developer_environment_id: 0 });
        addDeveloperEnvironmentRef.current?.onOpen();
      } else {
        form &&
          onChangeValue(
            'devEnv',
            form.values.devEnv.map(m =>
              m.dp_role_developer_environment_id === original.dp_role_developer_environment_id
                ? {
                    ...m,
                    ...refDevEnvDetail.data?.find(f => f.ref_developer_environment_id === e?.value),
                  }
                : m,
            ),
          );
      }
    };

  const getValue = useCallback(
    (
      original: RolesAppAccessSelectedDevelopmentEnvironmentMutateModel,
      field: keyof RolesAppAccessRefDevelopmentEnvironmentModel,
    ): SelectValue<number> => {
      const devEnv = refDevEnvDetail.data?.find(
        f => f.ref_developer_environment_id === original.ref_developer_environment_id,
      );
      return devEnv
        ? {
            label: devEnv[field] as string,
            value: original.ref_developer_environment_id,
          }
        : null;
    },
    [refDevEnvDetail.data],
  );

  const getOptionValue = useCallback(
    (ids: number[]) => {
      let id = ids[0];

      for (let i = 0; i < ids.length; i++) {
        if (!form?.values.devEnv?.some(s => s.ref_developer_environment_id === ids[i] && s.isDeleted !== true)) {
          id = ids[i];
          break;
        }
      }

      return id;
    },
    [form?.values.devEnv],
  );

  const getOptionIsDisabled = useCallback(
    (ids: number[]) => {
      return ids.every(
        id => form?.values.devEnv?.some(s => s.ref_developer_environment_id === id && s.isDeleted !== true),
      );
    },
    [form?.values.devEnv],
  );

  const handleCreate = () => {
    if (form) {
      let dp_role_developer_environment_id =
        form.values.devEnv.reduce(
          (prev, curr) => (prev < curr.dp_role_developer_environment_id ? prev : curr.dp_role_developer_environment_id),
          0,
        ) ?? 0;
      dp_role_developer_environment_id -= 1;

      onChangeValue('devEnv', [
        ...form.values.devEnv,
        {
          dataset: '',
          role_name: '',
          dp_role_developer_environment_id: dp_role_developer_environment_id,
          ref_developer_environment_id: dp_role_developer_environment_id,
          developer_environment_name: '',
          developer_environment_desc: '',
          cloud_platform: '',
          cloud_service: '',
          access_level: '',
          environment_properties: '',
          row_modified_datetime_utc: '',
          isDeleted: false,
        },
      ]);
    }
  };

  const handleDelete = (original: RolesAppAccessSelectedDevelopmentEnvironmentMutateModel) => () => {
    if (form)
      onChangeValue(
        'devEnv',
        form.values.devEnv.map(m =>
          m.dp_role_developer_environment_id === original.dp_role_developer_environment_id
            ? { ...m, isDeleted: true }
            : m,
        ),
      );
  };

  const displayedData = useMemo(() => {
    return isEdit && form ? form.values.devEnv.filter(f => !f.isDeleted) : data;
  }, [data, form, isEdit]);

  useEffect(() => {
    if (refDevEnvDetail.data) {
      const obj = buildDevEnvDistinct(refDevEnvDetail.data);
      setDevEnvDistinct(obj);
    }
  }, [refDevEnvDetail.data]);

  useEffect(() => {
    if (
      addDevEnv &&
      addDevEnv.ref_developer_environment_id &&
      refDevEnvDetail.data?.find(f => f.ref_developer_environment_id === addDevEnv.ref_developer_environment_id)
    ) {
      handleUpdate(addDevEnv)!({ value: addDevEnv.ref_developer_environment_id, label: 'none' }, {} as any);
      setAddDevEnv(null);
    }
  }, [addDevEnv, refDevEnvDetail.data]);

  return (
    <>
      {isLoading || refDevEnvDetail.isLoading || refDevEnvDetail.isFetching || refAccLvlDetail.isLoading ? (
        <>Loading...</>
      ) : (
        <VStack>
          <CustomTable
            variant="table"
            headers={Header}
            isLoading={isLoading}
            isFetching={isFetching}
            data={displayedData || []}
            pageCount={0}
            pageSize={displayedData ? displayedData.length : 0}
            totalRecords={displayedData ? displayedData.length : 0}
            search=""
            onPageChange={index => {}}
            onPageSizeChange={size => {}}
            onPageSearch={search => {}}
            onSort={() => {}}
            hidePagination={true}
            tableSort={true}
            showNoRecords
            // onRowClick={onClickRow}
          />

          {isEdit && (
            <Box>
              <Button colorScheme="brand.main" size="sm" onClick={handleCreate}>
                Create New
              </Button>
            </Box>
          )}
        </VStack>
      )}
      <AddDevelopmentEnvironmentModal
        ref={addDeveloperEnvironmentRef}
        onCreated={async params => {
          refetchRefTable('developer_environment');
          addDevEnv && setAddDevEnv({ ...addDevEnv, ...params });
        }}
      />
    </>
  );
};

export default AppAccessByRolesSelectedDevelopmentEnvironment;
