import * as Yup from 'yup';

export type BuildSchemaArgs = {
  type: 'id' | 'boolean' | 'string' | 'url';
  label: string;
  max?: number;
};

export function buildSchema(details: BuildSchemaArgs) {
  const { type, label, max } = details;
  if (type === 'string') {
    if (!max) throw new Error(`No type found for type ${type}: ${JSON.stringify(details)}`);
    return Yup.string().label(label).max(max).required();
  } else if (type === 'url') {
    if (!max) throw new Error(`No type found for type ${type}: ${JSON.stringify(details)}`);
    return Yup.string().label(label).max(max).required().url();
  } else if (type === 'id') {
    return Yup.number()
      .label(label)
      .required()
      .moreThan(0, label + ' is a required field');
  } else if (type === 'boolean') {
    return Yup.boolean().label(label).required();
  } else {
    throw new Error(`No schema found: ${JSON.stringify(details)}`);
  }
}
