import { Alert, VStack } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { DataProductDetailsPageDiscussionModel } from '../../../../../app/services/dme/api/types';
import PostItem from './PostItem';

type Props = {
  postList: DataProductDetailsPageDiscussionModel[];
  onClickRow: (user: DataProductDetailsPageDiscussionModel | null) => void;
  selectedPost: DataProductDetailsPageDiscussionModel | null;
};

const PostList: FC<Props> = ({ postList, selectedPost, onClickRow }) => {
  return (
    <VStack maxH="calc(100vh - 250px)" overflowY="auto" spacing={4}>
      {postList && postList.length == 0 && <Alert status="error">No Post(s)</Alert>}
      {postList.map((m: DataProductDetailsPageDiscussionModel) => (
        <Fragment key={m.dp_post_id}>
          <PostItem dp_post_id={selectedPost?.dp_post_id} selectedPost={m} onClick={onClickRow} />
        </Fragment>
      ))}
    </VStack>
  );
};

export default PostList;
