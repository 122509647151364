import { DynamicObject } from '../../../types/appType';
import { dmeApi } from './index';
import {
  GetGlossaryPaginatedDto,
  GetGlossaryPaginatedFilterModel,
  GetGlossaryPaginatedModel,
  LoreLanguageDetailsDiscussionTabModel,
  LoreLanguageDetailsPageModel,
  LoreLanguageModel,
  LoreLanguageResultPageModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getGlossaryPaginatedFilter: builder.query<GetGlossaryPaginatedFilterModel[], void>({
      query: () => '/lorelanguage/category/list',
      transformResponse: (response: GetGlossaryPaginatedFilterModel[]) =>
        response.map(m => ({ ...m, filter_header: 'Domain' })),
    }),

    getGlossaryPaginated: builder.query<GetGlossaryPaginatedModel, GetGlossaryPaginatedDto>({
      query: ({ filters, ...params }) => ({
        url: `/lorelanguage/paginated/result`,
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].ref_domain_id`] = val.ref_domain_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
      providesTags: ['GlossaryList'],
    }),

    getLoreLanguageList: builder.query<LoreLanguageModel[], void>({
      query: () => `/lorelanguage/list`,
      providesTags: ['GlossaryList'],
    }),

    getLoreLanguageResultPage: builder.query<LoreLanguageResultPageModel, void>({
      query: () => `/lorelanguage/result`,
      providesTags: ['GlossaryList'],
    }),

    getLoreLanguageDetailsPageByLoreLanguageId: builder.query<LoreLanguageDetailsPageModel, number>({
      query: loreLanguageId => `/lorelanguage/details/${loreLanguageId}`,
      providesTags: ['GlossaryList'],
    }),

    getLoreLanguageDetailsDiscussionTab: builder.query<
      Array<LoreLanguageDetailsDiscussionTabModel>,
      {
        llPostId: number;
        query: string;
      }
    >({
      query: params => `/lorelanguage/details/discussion/comments/${params.llPostId}?${params.query}`,
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGlossaryPaginatedFilterQuery,
  useGetGlossaryPaginatedQuery,
  useGetLoreLanguageListQuery,
  useGetLoreLanguageResultPageQuery,
  useGetLoreLanguageDetailsPageByLoreLanguageIdQuery,
  useGetLoreLanguageDetailsDiscussionTabQuery,
  useLazyGetLoreLanguageDetailsDiscussionTabQuery,
  useLazyGetLoreLanguageDetailsPageByLoreLanguageIdQuery,
} = extendedApi;
