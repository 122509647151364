import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { usePostRefDomainMutation } from '../../../app/services/dme/api/refDomain';
import { useGetRefUserListQuery } from '../../../app/services/dme/api/user';

const FormSchema = Yup.object().shape({
  domain_name: Yup.string().label('Domain Name').required(),
  domain_desc: Yup.string().label('Description').required(),
  ref_user_id: Yup.number().label('Owner').required().moreThan(0, 'Owner is a required field'),
});

const AddDomain = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const { data: refUserData, isLoading: isLoadingRefUser } = useGetRefUserListQuery({ disabled_flag: false });
  const [postAsync, postDetail] = usePostRefDomainMutation();
  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      domain_name: '',
      domain_desc: '',
      ref_user_id: 0,
    },
    onSubmit: values => {
      postAsync(values);
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
  };

  useEffect(() => {
    const { isSuccess, isError, isLoading } = postDetail;

    if (isSuccess) {
      setAlertMessage('Domain successfully submitted.');
      resetForm();
      setTimeout(() => {
        clearModal();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [postDetail]);

  const user = refUserData?.find(f => f.ref_user_id === values.ref_user_id);

  return isLoadingRefUser ? (
    <>Loading...</>
  ) : !refUserData ? (
    <></>
  ) : (
    <form onSubmit={handleSubmit}>
      <VStack>
        <HStack alignItems="center">
          <HStack flex={1}>
            <Heading size="md" whiteSpace="nowrap">
              Add Domain
            </Heading>
            <ButtonGroup>
              <Button isLoading={postDetail.isLoading} variant="outline" colorScheme="brand.main" type="submit">
                Save
              </Button>
            </ButtonGroup>
          </HStack>
          {alertMessage && (
            <Alert py={2} status={postDetail.isSuccess ? 'success' : 'error'}>
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}
        </HStack>
        <Divider />
        <HStack py={5}>
          <VStack spacing={5} width="container.sm">
            <FormControl isInvalid={!!errors.domain_name && touched.domain_name} display="flex">
              <FormLabel minW="100px" htmlFor="domain_name">
                Domain
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="domain_name"
                  name="domain_name"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      domain_name: e.target.value.trim(),
                    })
                  }
                  value={values.domain_name}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.domain_name}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.domain_desc && touched.domain_desc} alignItems="start">
              <Box textAlign="right">
                {values.domain_desc.length > 0 && <Text as="small">{`${values.domain_desc.length}/500`}</Text>}
              </Box>
              <Flex>
                <FormLabel minW="100px" htmlFor="domain_desc">
                  Description
                </FormLabel>
                <Box width="full">
                  <Textarea
                    size="sm"
                    id="domain_desc"
                    name="domain_desc"
                    onChange={handleChange}
                    onBlur={e =>
                      setValues({
                        ...values,
                        domain_desc: e.target.value.trim(),
                      })
                    }
                    value={values.domain_desc}
                    maxLength={500}
                  />
                  <FormErrorMessage>{errors.domain_desc}</FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <FormControl isInvalid={!!errors.ref_user_id && touched.ref_user_id} display="flex">
              <FormLabel minW="100px" htmlFor="ref_user_id">
                Owner
              </FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="ref_user_id"
                  name="ref_user_id"
                  useBasicStyles
                  value={{
                    label: `${user?.first_name || ''} ${user?.last_name || ''}`.trim(),
                    value: values.ref_user_id,
                  }}
                  options={[
                    ...refUserData.map(m => {
                      return {
                        label: `${m.first_name} ${m.last_name}`,
                        value: m.ref_user_id,
                      };
                    }),
                  ]}
                  onChange={e => {
                    setValues({
                      ...values,
                      ref_user_id: parseInt(e?.value?.toString() || '0'),
                    });
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: provided => ({
                      ...provided,
                      zIndex: 1401,
                    }),
                  }}
                  maxMenuHeight={300}
                />
                <FormErrorMessage>{errors.ref_user_id}</FormErrorMessage>
              </Box>
            </FormControl>
          </VStack>
        </HStack>
      </VStack>
    </form>
  );
};

export default AddDomain;
