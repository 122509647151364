import { CrumbModel, siteBreadcrumbSliceActions } from '../../components/SiteBreadcrumb/slice';
import { store } from '../state/store';

export const getActivePathColor = (path: string) => (window?.location.pathname.indexOf(path) >= 0 ? 'teal' : 'gray');

export const isCommunityPage = (location?: any) => (location || window?.location).pathname === '/community';

export const addBreadcrumb = (crumb: CrumbModel, index?: number) => {
  store.dispatch(siteBreadcrumbSliceActions.addBreadcrumb({ index, crumb }));
};

export const setBreadcrumb = (crumbs: CrumbModel[]) => {
  store.dispatch(siteBreadcrumbSliceActions.setBreadcrumb(crumbs));
};

export const sliceBreadcrumb = (start: number, end: number) => {
  store.dispatch(siteBreadcrumbSliceActions.sliceBreadcrumb({ start, end }));
};

export const clearBreadcrumb = () => {
  store.dispatch(siteBreadcrumbSliceActions.clearBreadcrumb());
};
