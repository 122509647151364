import { dmeApi } from '.';
import { removeUnwantedCharacters } from '../../../helpers/stringHelper';
import {
  CustomRequestDetailsPageDiscussionModel,
  CustomRequestPostAttachmentModel,
  ToggleCustomRequestPostReactionModel,
  UpdateCustomRequestPostModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getCustomRequestDetailsDiscussionPostsByCustomRequestId: builder.query<
      CustomRequestDetailsPageDiscussionModel[],
      number
    >({
      query: cr_custom_request_id => `/customrequest/discussions/posts/cr_custom_request_id/${cr_custom_request_id}`,
    }),

    getCustomRequestPostById: builder.query<CustomRequestDetailsPageDiscussionModel[], number>({
      query: cr_post_id => `/customrequest/discussions/posts/cr_post_id/${cr_post_id}`,
    }),

    getCustomRequestPostAttachmentsById: builder.query<Array<CustomRequestPostAttachmentModel>, number>({
      query: cr_post_id => `/customrequestpost/attachment/cr_post_id/${cr_post_id}`,
      providesTags: ['CustomRequestPostAttachments'],
    }),

    addCustomRequestPost: builder.mutation<{ cr_post_id: number }, FormData>({
      query: params => ({
        url: '/customrequestpost',
        method: 'POST',
        body: params,
      }),
    }),

    postCustomRequestPostToggleReaction: builder.mutation<void, ToggleCustomRequestPostReactionModel>({
      query: params => ({
        url: '/customrequestpost/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateCustomRequestPost: builder.mutation<void, UpdateCustomRequestPostModel>({
      query: params => ({
        url: '/customrequestpost',
        method: 'PUT',
        body: { ...params, post_message: removeUnwantedCharacters(params.post_message) },
      }),
    }),

    deleteCustomRequestPost: builder.mutation<void, number>({
      query: cr_post_id => ({
        url: `/CustomRequestpost?${new URLSearchParams({ cr_post_id: cr_post_id.toString() }).toString()}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetCustomRequestDetailsDiscussionPostsByCustomRequestIdQuery,
  useLazyGetCustomRequestPostByIdQuery,
  useGetCustomRequestPostAttachmentsByIdQuery,
  useAddCustomRequestPostMutation,
  usePostCustomRequestPostToggleReactionMutation,
  useUpdateCustomRequestPostMutation,
  useDeleteCustomRequestPostMutation,
} = extendedApi;
