import {
  AlertIcon,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  VStack,
  Input,
  Text,
  Alert,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FaTrash } from 'react-icons/fa';
import { useDeleteRolesAppAccessRoleByRoleIdMutation } from '../../../app/services/dme/api/rolesAppAccess';
import { RolesAppAccessListDataModel } from '../../../app/services/dme/api/types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

type Props = {
  role: RolesAppAccessListDataModel;
};

const FormSchema = Yup.object().shape({
  delete_text: Yup.string().label('').required().equals(['delete']),
  roleId: Yup.number().required().moreThan(0, 'Owner is a required field'),
});

const AppAccessByRolesDeleteRoleButton = ({ role }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteAsync, deleteDetails] = useDeleteRolesAppAccessRoleByRoleIdMutation();

  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      roleId: role.ref_role_id,
      delete_text: '',
    },
    onSubmit: values => {
      deleteAsync(values.roleId);
    },
  });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOpen();
  };

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = deleteDetails;

    if (isSuccess) {
      setAlertMessage('Data Product successfully deleted.');
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [deleteDetails]);

  return (
    <>
      <Tooltip label="Delete">
        <IconButton
          aria-label="Delete"
          color="brand.error"
          variant="link"
          icon={<FaTrash />}
          onClick={handleClick}
          minWidth={1}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Role</ModalHeader>
          <ModalCloseButton isDisabled={deleteDetails.isLoading} />

          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={5}>
                <Alert status="error">
                  <AlertIcon />
                  <Text>
                    You are deleting role{' '}
                    <Text as="span" fontWeight="bold">
                      {role.role_name}
                    </Text>
                  </Text>
                </Alert>
                <FormControl isInvalid={!!errors.delete_text && touched.delete_text}>
                  <FormLabel>Confirm deletion by typing "delete"</FormLabel>
                  <Input
                    id="delete_text"
                    name="delete_text"
                    placeholder="delete"
                    onChange={handleChange}
                    value={values.delete_text}
                    maxLength={100}
                  />
                </FormControl>

                <VStack spacing={5}>
                  {alertMessage && (
                    <Alert status={deleteDetails.isSuccess ? 'success' : 'error'}>
                      <AlertIcon />
                      {alertMessage}
                    </Alert>
                  )}
                </VStack>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3} ml="auto" isDisabled={deleteDetails.isLoading}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.error"
                bg="brand.error"
                isLoading={deleteDetails.isLoading}
                isDisabled={(alertMessage !== '' && deleteDetails.isSuccess) || values.delete_text !== 'delete'}
              >
                Delete
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AppAccessByRolesDeleteRoleButton;
