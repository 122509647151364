import { VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../../../app/helpers/dateHelper';
import { useGetRhPortfolioSelectedRolesByRhpidQuery } from '../../../../../app/services/dme/api/reportHub';
import { ReportHubSelectedRolesTabModel } from '../../../../../app/services/dme/api/types';
import { CustomTableHeader } from '../../../../../app/types/appType';
import CustomTable from '../../../../../components/CustomTable';

const ReportHubExplorePortfoliosSelectedViewRoles = () => {
  const params = useParams();

  const rhpId: number = parseInt(params?.rhpId || '0') || 0;
  const { data, isLoading, isFetching } = useGetRhPortfolioSelectedRolesByRhpidQuery({ rhpId }, { skip: rhpId === 0 });

  const Header: CustomTableHeader<ReportHubSelectedRolesTabModel> = [
    {
      Header: 'Role Name',
      accessor: 'role_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Business Justification',
      accessor: 'access_request_business_justification',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Expiration Date',
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedRolesTabModel>) => {
        return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
      },
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Requested Date',
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedRolesTabModel>) => {
        return <>{convertUtcToLocal(original.access_requested_datetime_utc) || '-'}</>;
      },
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Requestor',
      accessor: 'requestor',
      styles: { whiteSpace: 'initial' },
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      {isLoading || isFetching ? (
        <>Loading...</>
      ) : (
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={false}
          isFetching={false}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          showNoRecords
        />
      )}
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedViewRoles;
