import { Box, Divider, Heading, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetGoEdsRequestCreationQaListQuery } from '../../../../app/services/dme/api/governanceOfficeEds';
import { AddGovernanceOfficeEdsModel, GoEdsRequestCreationQaModel } from '../../../../app/services/dme/api/types';
import { useGetRefUserListQuery } from '../../../../app/services/dme/api/user';
import {
  MyEdsRequestCreateRequestProps,
  initialValuesMyEdsRequestCreateRequest,
  myEdsRequestCreateRequestTabs,
} from './utils';

const MyExternalDataSharingRequestsCreateRequest = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [data, setData] = useState<AddGovernanceOfficeEdsModel>(initialValuesMyEdsRequestCreateRequest);
  const [questions, setQuestions] = useState<GoEdsRequestCreationQaModel[]>([]);

  const location = useLocation();

  //! we are just pre-fetching these here
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const qaListDetails = useGetGoEdsRequestCreationQaListQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const usersDetails = useGetRefUserListQuery({ disabled_flag: false });

  useEffect(() => {
    addBreadcrumb({ label: 'Create Request', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (!qaListDetails.data || qaListDetails.data.length <= 0) return;

    const getQuestion = (list: GoEdsRequestCreationQaModel[], id: number) => {
      return list.find(q => q.go_eds_ref_question_id === id);
    };

    const list: GoEdsRequestCreationQaModel[] = [];
    let index = 0;
    let question: GoEdsRequestCreationQaModel | undefined = qaListDetails.data[0];

    while (index < qaListDetails.data.length && !!question) {
      list.push(question);
      if (question && question.goto_go_eds_ref_question_id) {
        const tmp = getQuestion(qaListDetails.data, question.goto_go_eds_ref_question_id);
        const notInList = !getQuestion(list, tmp?.go_eds_ref_question_id ?? 0);
        if (tmp && notInList) {
          question = tmp;
        } else {
          question = undefined;
        }
      } else {
        question = undefined;
      }
    }

    setQuestions(list);
    setData(d => ({
      ...d,
      answers: list.map(m => ({
        go_eds_ref_question_id: m.go_eds_ref_question_id,
        go_eds_ref_question_answer_id: m.question_type === 'Yes/No Questions' ? 0 : m.go_eds_ref_question_answer_id,
      })),
      answer_attachments: [],
    }));
  }, [qaListDetails.data, qaListDetails.isSuccess]);

  return (
    <VStack>
      <Heading size="md">Create Request</Heading>
      <Divider />

      <Tabs index={tabIndex}>
        <TabList>
          {myEdsRequestCreateRequestTabs.map((m, i) => (
            <Tab key={i}>{m.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          <Box p={3}>
            {createElement<MyEdsRequestCreateRequestProps>(myEdsRequestCreateRequestTabs[tabIndex].component, {
              tabIndex,
              setTabIndex,
              data,
              setData,
              questions,
            })}
          </Box>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default MyExternalDataSharingRequestsCreateRequest;
