import { serialize } from 'object-to-formdata';
import { dmeApi } from '.';
import { DynamicObject } from '../../../types/appType';
import { PaginatedQueryParams } from '../../types';
import {
  AddGovernanceOfficeEdsModel,
  AddStatusGovernanceOfficeEdsModel,
  GoEdsAttachmentsByRequestDetailIdModel,
  GoEdsExploreAssociateUserModel,
  GoEdsExploreModel,
  GoEdsMyRequestAssociateUserModel,
  GoEdsMyRequestModel,
  GoEdsRequestCreationQaModel,
  GoEdsRequestForApprovalModel,
  GoEdsRequestSelectedDetailsModel,
  GoEdsRequestSelectedQaModel,
  GoEdsRequestSelectedStatusModel,
  GoEdsRequestSelectedUserModel,
  GoEdsRequestSelectedVoteModel,
  GoEdsRequestStatusModel,
  VoteGovernanceOfficeEdsBodyModel,
  VoteGovernanceOfficeEdsResultModel,
} from './types';

const baseUrl = 'governanceofficeeds';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: build => ({
    // SP: p_go_eds_request_explore_page_filter_applied
    getGoEdsExploreList: build.query<
      GoEdsExploreModel[],
      PaginatedQueryParams<GoEdsExploreModel> & {
        filters: { filter_order: number; filter_id: number }[];
        date_start_filter: string;
        date_end_filter: string;
      }
    >({
      query: ({ filters, ...params }) => ({
        url: baseUrl + '/explore/list',
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].filter_order`] = val.filter_order;
            obj[`filters[${i}].filter_id`] = val.filter_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
    }),

    // SP: p_go_eds_associate_users_list_for_explore
    getGoEdsExploreAssociateUserList: build.query<GoEdsExploreAssociateUserModel[], void>({
      query: () => baseUrl + '/explore/associate-user/list',
    }),

    // SP: p_go_eds_my_request_filter_applied
    getGoEdsMyRequestList: build.query<
      GoEdsMyRequestModel[],
      PaginatedQueryParams<GoEdsMyRequestModel> & {
        filters: { filter_order: number; filter_id: number }[];
        date_start_filter: string;
        date_end_filter: string;
      }
    >({
      query: ({ filters, ...params }) => ({
        url: baseUrl + '/list',
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].filter_order`] = val.filter_order;
            obj[`filters[${i}].filter_id`] = val.filter_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
      providesTags: ['GoEdsMyRequestList'],
    }),

    // SP: p_go_eds_request_status_list
    getGoEdsRequestStatusList: build.query<GoEdsRequestStatusModel[], void>({
      query: () => baseUrl + '/request-status/list',
    }),

    // SP: p_go_eds_associate_users_list
    getGoEdsMyRequestAssociateUserList: build.query<GoEdsMyRequestAssociateUserModel[], void>({
      query: () => baseUrl + '/associate-user/list',
    }),

    // VW: vw_go_eds_request_select_page_details_tab
    // SP: p_go_eds_get_request_details_by_id
    getGoEdsRequestSelectedDetails: build.query<GoEdsRequestSelectedDetailsModel, { goEdsId: number }>({
      query: ({ goEdsId }) => baseUrl + `/selected/details/${goEdsId}`,
      transformResponse: (res: GoEdsRequestSelectedDetailsModel[]) => res[0],
    }),

    // SP: p_go_eds_get_request_status_by_id
    getGoEdsRequestSelectedStatus: build.query<GoEdsRequestSelectedStatusModel[], { goEdsId: number }>({
      query: ({ goEdsId }) => baseUrl + `/selected/status/${goEdsId}`,
    }),

    // SP: p_go_eds_get_request_votes_by_id
    getGoEdsRequestSelectedVote: build.query<GoEdsRequestSelectedVoteModel[], { goEdsId: number }>({
      query: ({ goEdsId }) => baseUrl + `/selected/votes/${goEdsId}`,
    }),

    // SP: p_go_eds_get_request_associated_users_by_id
    getGoEdsRequestSelectedUser: build.query<GoEdsRequestSelectedUserModel[], { goEdsId: number }>({
      query: ({ goEdsId }) => baseUrl + `/selected/users/${goEdsId}`,
    }),

    // VW: vw_go_eds_request_select_page_details_tab_qa
    // SP: p_go_eds_get_request_details_qa_by_id
    getGoEdsRequestSelectedQa: build.query<GoEdsRequestSelectedQaModel[], { goEdsId: number }>({
      query: ({ goEdsId }) => baseUrl + `/selected/question-and-answer/${goEdsId}`,
    }),

    // VW:
    // SP: p_go_eds_get_request_details_attachment_list
    getGoEdsAttachmentsByRequestDetailId: build.query<
      GoEdsAttachmentsByRequestDetailIdModel[],
      { go_eds_user_request_detail_id: number }
    >({
      query: ({ go_eds_user_request_detail_id }) =>
        baseUrl + `/attachment/list/go_eds_user_request_detail_id/${go_eds_user_request_detail_id}`,
    }),

    // SP: p_go_eds_create_request_qa_list
    getGoEdsRequestCreationQaList: build.query<GoEdsRequestCreationQaModel[], void>({
      query: () => baseUrl + `/question-and-answer/list`,
    }),

    // SP: p_go_eds_request_for_approval_list
    getGoEdsRequestForApprovalList: build.query<GoEdsRequestForApprovalModel[], void>({
      query: () => baseUrl + `/request-for-approval/list`,
      providesTags: ['GoEdsApprovalList'],
    }),

    addGoEdsRequest: build.mutation<{ go_eds_request_id: number }, AddGovernanceOfficeEdsModel>({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body: serialize(body, {
          indices: true,
          allowEmptyArrays: false,
          dotsForObjectNotation: true,
        }),
      }),
    }),

    addStatusGovernanceOfficeEds: build.mutation<
      { go_eds_request_status_id: number },
      AddStatusGovernanceOfficeEdsModel
    >({
      query: body => ({
        url: baseUrl + '/add-status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GoEdsMyRequestList'],
    }),

    voteGovernanceOfficeEds: build.mutation<VoteGovernanceOfficeEdsResultModel, VoteGovernanceOfficeEdsBodyModel>({
      query: body => ({
        url: baseUrl + '/vote',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GoEdsApprovalList'],
    }),

    refetchGoEdsRequestForApprovalList: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['GoEdsApprovalList'],
    }),

    deleteGovernanceOfficeEds: build.mutation<boolean, number>({
      query: go_eds_request_id => ({
        url: baseUrl + `/go_eds_request_id/${go_eds_request_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GoEdsMyRequestList'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetGoEdsExploreListQuery,
  useGetGoEdsExploreAssociateUserListQuery,
  useGetGoEdsMyRequestListQuery,
  useGetGoEdsRequestStatusListQuery,
  useGetGoEdsMyRequestAssociateUserListQuery,
  useGetGoEdsRequestSelectedDetailsQuery,
  useGetGoEdsRequestSelectedStatusQuery,
  useGetGoEdsRequestSelectedVoteQuery,
  useGetGoEdsRequestSelectedUserQuery,
  useGetGoEdsRequestSelectedQaQuery,
  useGetGoEdsAttachmentsByRequestDetailIdQuery,
  useGetGoEdsRequestCreationQaListQuery,
  useGetGoEdsRequestForApprovalListQuery,
  useAddGoEdsRequestMutation,
  useAddStatusGovernanceOfficeEdsMutation,
  useVoteGovernanceOfficeEdsMutation,
  useRefetchGoEdsRequestForApprovalListMutation,
  useDeleteGovernanceOfficeEdsMutation,
} = extendedApi;
