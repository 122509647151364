import { Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetRefTagSelectedImportantLinkQuery } from '../../../../app/services/dme/api/refTags';
import { GetRefTagSelectedImportantLinkModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

const ExploreTagsSelectedImportantLinks = () => {
  const params = useParams();
  const refTagId: number = parseInt(params?.id || '') || 0;
  const { data, isLoading, isFetching } = useGetRefTagSelectedImportantLinkQuery(refTagId, {
    skip: refTagId === 0,
  });

  const Header = [
    {
      Header: 'Category',
      accessor: 'link_ref_category_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Name - Definition',
      accessor: 'll_definition',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: GetRefTagSelectedImportantLinkModel } }) => {
        return (
          <Text>
            <Text fontWeight="bold" as="span">
              {original.link_name}
            </Text>
            {' - '}
            {original.link_description}
          </Text>
        );
      },
    },
  ];

  return (
    <CustomTable
      variant="table"
      headers={Header}
      isLoading={isLoading}
      isFetching={isFetching}
      data={data || []}
      pageCount={0}
      pageSize={10}
      totalRecords={data ? data.length : 0}
      search=""
      onPageChange={index => {}}
      onPageSizeChange={size => {}}
      onPageSearch={search => {}}
      onSort={() => {}}
      hidePagination={true}
      tableSort={true}
      showNoRecords
    />
  );
};

export default ExploreTagsSelectedImportantLinks;
