import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { AddRoleModel } from '.';
import { ValueOf } from '../../../app/helpers/utilities';
import { useGetRolesAppAccessRefAppAccessListQuery } from '../../../app/services/dme/api/rolesAppAccess';
import { RolesAppAccessSelectedAppAccessModel } from '../../../app/services/dme/api/types';
import EditableCell from '../../../components/CustomTable/EditableCell';
import { Row } from 'react-table';
import { FaTrash } from 'react-icons/fa';

type Props = {
  data: AddRoleModel;
  onChangeData: (field: keyof AddRoleModel, data: ValueOf<AddRoleModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};
const AddRoleAppAccess: React.FC<Props> = ({ data, onChangeData, errors, touched, title }) => {
  const refAppAccessDetail = useGetRolesAppAccessRefAppAccessListQuery();

  const generateIdForAddedItem = (): number => {
    return data.appAccess && data.appAccess.length > 0
      ? Math.min.apply(
          Math,
          data.appAccess.map(m => m.role_app_access_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow: RolesAppAccessSelectedAppAccessModel = {
      role_app_access_id: generateIdForAddedItem(),
      dataset: '',
      ref_app_access_id: 0,
      app_access_name: '',
      app_access_display_name: '',
      app_access_desc: '',
      row_modified_datetime_utc: '',
    };
    onChangeData('appAccess', data.appAccess ? [...data.appAccess, newRow] : [newRow]);
  };

  const onUpdateData = (
    rowIndex: number,
    columnId: string,
    value: string | File | number | number[],
    fileColumnName?: string,
  ) => {
    onChangeData(
      'appAccess',
      data.appAccess.map((row, index) => {
        if (index === rowIndex && refAppAccessDetail.data) {
          const appAccess = refAppAccessDetail.data.find(f => f.ref_app_access_id === (value as number));
          return { ...row, ...appAccess };
        }
        return row;
      }),
    );
  };

  const onRemove = (item: RolesAppAccessSelectedAppAccessModel) => {
    onChangeData(
      'appAccess',
      data.appAccess.filter(f => f.role_app_access_id !== item.role_app_access_id),
    );
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">{title}</Heading>
      </HStack>
      {refAppAccessDetail.isLoading ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return (
      <VStack>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>App Access Name</Th>
              <Th>App Access Display Name</Th>
              <Th>Action</Th>
            </Tr>
            {data.appAccess.map((d, index) => (
              <Tr key={d.role_app_access_id}>
                <Td>
                  {refAppAccessDetail.data && (
                    <EditableCell
                      name={`modeModel.${index}.ref_delivery_mode_id`}
                      value={d.ref_app_access_id}
                      row={{ index: index } as Row}
                      column={{ id: 'ref_app_access_id' } as any}
                      updateData={onUpdateData}
                      type="dropdown"
                      dropdownOptions={refAppAccessDetail.data.map(m => {
                        return {
                          text: m.app_access_name,
                          value: m.ref_app_access_id.toString(),
                          isDisabled: data.appAccess.some(s => s.ref_app_access_id === m.ref_app_access_id),
                        };
                      })}
                      isInvalid={
                        !!(
                          errors.appAccess &&
                          (errors.appAccess as unknown as RolesAppAccessSelectedAppAccessModel[])[index]
                            ?.ref_app_access_id
                        ) &&
                        !!(
                          touched.appAccess &&
                          (touched.appAccess as unknown as RolesAppAccessSelectedAppAccessModel[])[index]
                            ?.ref_app_access_id
                        )
                      }
                    />
                  )}
                </Td>
                <Td>{d.app_access_display_name}</Td>
                <Td textAlign="center">
                  {
                    <ButtonGroup spacing={1}>
                      <Tooltip label="Delete">
                        <IconButton
                          color="brand.error"
                          variant="link"
                          aria-label="Delete"
                          icon={<FaTrash />}
                          onClick={() => onRemove(d)}
                          minWidth={1}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
            Create New
          </Button>
        </Box>
      </VStack>
    );
  }
};

export default AddRoleAppAccess;
