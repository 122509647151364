import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RefUserModel } from '../services/dme/api/types';
export type AppState = {
  // isUserOnboard: boolean;
  // isUserConsent: boolean;
  //refUserId: number;
  isProfileEditSuccess: boolean | null;
  logonUser: RefUserModel | null;
};

const initialState: AppState = {
  //isUserOnboard: false,
  //isUserConsent: false,
  isProfileEditSuccess: null,
  //refUserId: 0,
  logonUser: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // setIsUserOnboard: (state, action: PayloadAction<boolean>) => {
    //   state.isUserOnboard = action.payload;
    // },
    // setIsUserConsent: (state, action: PayloadAction<boolean>) => {
    //   state.isUserConsent = action.payload;
    // },
    // setRefUserId: (state, action: PayloadAction<number>) => {
    //   state.refUserId = action.payload;
    // },
    setLogonUser: (state, action: PayloadAction<RefUserModel>) => {
      state.logonUser = action.payload;
    },
    setIsProfileEditSuccess: (state, action: PayloadAction<boolean>) => {
      state.isProfileEditSuccess = action.payload;
    },
  },
});

export const {
  // setIsUserOnboard,
  // setIsUserConsent,
  //setRefUserId,
  setIsProfileEditSuccess,
  setLogonUser,
} = userSlice.actions;
export default userSlice.reducer;
