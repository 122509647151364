import { Box, ButtonGroup, HStack, Heading, IconButton, Text, Tooltip, VStack, useClipboard } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaRegCopy, FaUnlockAlt } from 'react-icons/fa';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import { viewableExt } from '../../../../app/constants';
import { getFileName } from '../../../../app/helpers/stringHelper';
import { useGetDataProductSelectModesByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';
import { DataProductSelectPageModesModel } from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';
import RequestAccessDialog from '../../RequestAccess';
import DataProductSelectedModeTable from './ModeTable';

type Props = {};

const DataProductSelectedMode = (props: Props) => {
  const params = useParams();
  const [productId, setProductId] = useState(0);
  const { logonUser } = useAppSelector(s => s.user);
  const { onCopy, setValue, value } = useClipboard('');

  useEffect(() => {
    if (value) onCopy();
  }, [value]);

  useEffect(() => {
    setProductId(+(params?.dataProductId || 0));
  }, [params]);

  const { data, isLoading } = useGetDataProductSelectModesByProductIdQuery(productId, {
    skip: !productId,
  });

  const onClickDownload = (url: string) => {
    window.open(url, '_blank');
  };

  const Header = [
    {
      Header: 'Request Access',
      styles: { whiteSpace: 'initial', textAlign: 'center', maxWidth: '100px' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: Row<DataProductSelectPageModesModel> }) => {
        return (
          <>
            <ButtonGroup spacing={2}>
              {logonUser && (
                <RequestAccessDialog
                  email={logonUser?.email}
                  dp_data_product_id={parseInt(params.dataProductId || '0')}
                  dp_data_product_delivery_mode_id={original.dp_data_product_delivery_mode_id}
                  triggerElement={
                    <Tooltip label="Request Access">
                      <IconButton
                        variant="link"
                        aria-label="Request Access"
                        icon={<FaUnlockAlt />}
                        minWidth={1}
                        colorScheme="brand.main"
                      />
                    </Tooltip>
                  }
                />
              )}
            </ButtonGroup>
          </>
        );
      },
    },
    // {
    //   Header: "Mode",
    //   accessor: "mode",
    //   styles: { whiteSpace: "initial" },
    // },
    {
      Header: 'Mode - Description',
      accessor: 'mode',
      // dont know why but all these to make it stick to exact width
      styles: { whiteSpace: 'initial', minWidth: '350px', maxWidth: '350px', width: '350px' },
      Cell: ({ row: { original } }: { row: Row<DataProductSelectPageModesModel> }) => {
        return (
          <Text>
            <Text as="span" fontWeight="bold">
              {original.mode}
            </Text>
            {' - ' + original.mode_description}
          </Text>
        );
      },
    },
    {
      Header: 'Properties',
      accessor: 'properties',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: Row<DataProductSelectPageModesModel> }) => {
        return (
          <HStack>
            <Text whiteSpace="initial" maxW="350px">
              {original.properties}
            </Text>
            <IconButton
              ml="2"
              size="sm"
              aria-label="copy property"
              icon={<FaRegCopy />}
              variant="ghost"
              onClick={() => setValue(original.properties)}
              color="gray.400"
            />
          </HStack>
        );
      },
    },
    {
      Header: 'Documentation',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPageModesModel } }) => {
        const isViewable = original.documentation
          ? viewableExt.some(s => s.includes(original.documentation.split('.').pop() ?? 'not-viewable'))
          : false;
        const fileName = original.documentation ? getFileName(original.documentation).split('-document-')[1] : '';
        return (
          <>
            {original.documentation ? (
              <Box alignItems="center" display="flex" minW="150px">
                <Text noOfLines={1} whiteSpace="normal" wordBreak="break-all" title={fileName}>
                  {fileName}
                </Text>
                <IconButton
                  variant="link"
                  color="links"
                  size="md"
                  aria-label={isViewable ? 'View' : 'Download'}
                  icon={isViewable ? <HiOutlineExternalLink /> : <MdOutlineSimCardDownload />}
                  title={isViewable ? 'View' : 'Download'}
                  onClick={() => onClickDownload(original.documentation)}
                />
              </Box>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      Header: 'Preview Data',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPageModesModel } }) => {
        const isViewable = original.preview_data
          ? viewableExt.some(s => s.includes(original.preview_data.split('.').pop() ?? 'not-viewable'))
          : false;

        const fileName = original.preview_data ? getFileName(original.preview_data).split('-preview-')[1] : '';
        return (
          <>
            {original.preview_data ? (
              <Box alignItems="center" display="flex" minW="150px">
                <Text noOfLines={1} whiteSpace="normal" wordBreak="break-all" title={fileName}>
                  {fileName}
                </Text>
                <IconButton
                  variant="link"
                  color="links"
                  size="md"
                  aria-label={isViewable ? 'View' : 'Download'}
                  icon={isViewable ? <HiOutlineExternalLink /> : <MdOutlineSimCardDownload />}
                  title={isViewable ? 'View' : 'Download'}
                  onClick={() => onClickDownload(original.preview_data)}
                />
              </Box>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      Header: 'Refresh Frequency',
      accessor: 'refresh_frequency',
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Data Product Mode</Heading>
      {isLoading ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <DataProductSelectedModeTable
          variant="table"
          headers={Header}
          isLoading={isLoading}
          isFetching={isLoading}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
        />
      </HStack>
    );
  }
};

export default DataProductSelectedMode;
