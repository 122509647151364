import { Box, Divider, HStack, Heading } from '@chakra-ui/react';
import ExplorePlatformServicesTable from './Table';
import ExplorePlatformServicesFilter from './Filter';


type Props = {};

// TODO
//  AppAccess.MyEdsRequestsWrite
//  AppAccess.exploreGovWrite

const ExplorePortfolios = (props: Props) => {
  return (
    <Box w="100%">
      <HStack mb="6">
        <Heading as="h2" size="sm">
        Explore Platform Portfolios
        </Heading>
      </HStack>
      <HStack gap="6" alignItems="start">
        <ExplorePlatformServicesFilter />
        <ExplorePlatformServicesTable />
      </HStack>
    </Box>
  );
};

export default ExplorePortfolios;
