import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import appReducer from '../slices/appSlice';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import userReducer from './../slices/userSlice';
import userRoleReducer from './../slices/userRoleSlice';
import pubNubReducer from './../slices/pubNubSlice';
import { dmeApi } from '../services/dme/api';
import siteBreadcrumbReducer from '../../components/SiteBreadcrumb/slice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    //user: persistedReducer,
    user: userReducer,
    app: appReducer,
    userRole: userRoleReducer,
    siteBreadcrumb: siteBreadcrumbReducer,
    pubNub: pubNubReducer,
    [dmeApi.reducerPath]: dmeApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(dmeApi.middleware),
});

/*

*/

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const persistor = persistStore(store);
