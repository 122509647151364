import { useEffect, useState } from 'react';
import { Box, Divider, Flex, HStack, VStack } from '@chakra-ui/react';
import Header from './components/Header';
import { UnauthenticatedTemplate, useMsal, MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import {
  AuthenticationResult,
  EventType,
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';

import Routing from './pages/Routing';
import { b2cPolicies, loginRequest } from './app/services/auth/authConfig';
import Footer from './components/Footer';
import 'focus-visible/dist/focus-visible';
import PageLoading from './components/PageLoading';
import SideNav from './components/SideNav';
import AuthenticatedUserWrapper from './features/AuthenticatedUserWrapper';
import Heap from './features/Heap';
import SiteBreadcrumb from './components/SiteBreadcrumb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PubNubWrapper from './features/PubNubWrapper';
import { usePostPimVerifyUserMutation } from './app/services/dme/api/pim';

dayjs.extend(customParseFormat);

const App = ({ instance }: { instance: PublicClientApplication }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // useIsAuthenticated() only works inside AuthenticatedTemplate
  const [ssoObjectId, setSsoObjectId] = useState('');
  const [postPimVerifyUser] = usePostPimVerifyUserMutation();
  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    (async () => {
      const callbackId = instance.addEventCallback(async (event: any) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
          if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
            if (event.interactionType === InteractionType.Redirect) {
              instance.loginRedirect(b2cPolicies.authorities.forgotPassword as RedirectRequest);
            } else if (event.interactionType === InteractionType.Popup) {
              instance.loginPopup(b2cPolicies.authorities.forgotPassword as RedirectRequest).catch((e: any) => {
                return;
              });
            }
          }
        }

        if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          if (event?.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            instance.setActiveAccount(account);

            if (event.eventType === EventType.LOGIN_SUCCESS && account?.localAccountId) {
              setSsoObjectId(account.localAccountId);
            }

            //redirect the user to where they were before they logged in
            if (payload.state) {
              window.location.href = payload.state;
            }
            /**
             * We need to reject id tokens that were not issued with the default sign-in policy.
             * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (event.payload.authority === b2cPolicies.authorities.forgotPassword.authority.toLowerCase()) {
              window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
              return instance.logout();
            }
          }
        }
      });

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    })();
  }, []);

  useEffect(() => {
    if (ssoObjectId) {
      // will trigger only on signin
      postPimVerifyUser({ sso_object_id: ssoObjectId });
    }
  }, [ssoObjectId]);

  return (
    <MsalProvider instance={instance}>
      <UnauthenticatedTemplate>
        <UnAuthRedirect />
      </UnauthenticatedTemplate>
      <Flex direction="column" align="stretch" alignItems="center" minH="100vh">
        {isAuthenticated && (
          <>
            <Heap />
            <Header />
          </>
        )}

        {/* <Header /> */}
        <Box flex={1} overflow="auto" mt="60px" p="0" w="100%">
          <HStack alignItems="stretch" spacing={0}>
            <Box flexGrow={1}>
              <SideNav />
            </Box>
            <VStack spacing={0} w="100%">
              <SiteBreadcrumb />
              <Divider />
              <Flex p={7} bgColor="white">
                <AuthenticatedUserWrapper onAuthenticated={() => setIsAuthenticated(true)}>
                  <PubNubWrapper>
                    <Routing />
                  </PubNubWrapper>
                </AuthenticatedUserWrapper>
              </Flex>
            </VStack>
          </HStack>
        </Box>

        <Footer />
      </Flex>
    </MsalProvider>
  );
};

const UnAuthRedirect = () => {
  const { inProgress } = useMsal();
  const instance = globalThis.msalInstance;
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log(e);
      });
    }
  }, []);
  return <PageLoading />;
};

export default App;
