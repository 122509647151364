import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MyRoleDetailEnvironmentTabModel } from '../../../../app/services/dme/api/types';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { LoreLanguageDetailsPageDomainModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

type Props = {
  data: MyRoleDetailEnvironmentTabModel[];
};

const MyRoleDetailEnvTab = ({ data }: Props) => {
  const Header = [
    {
      Header: 'Environment Name',
      accessor: 'developer_environment_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Environment Desc',
      accessor: 'developer_environment_desc',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Platform',
      accessor: 'cloud_platform',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Service',
      accessor: 'cloud_service',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Properties',
      accessor: 'environment_properties',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Access Level',
      accessor: 'access_level',
      styles: { whiteSpace: 'initial' },
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Domain</Heading>
      {displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={false}
          isFetching={false}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          showNoRecords
        />
      </HStack>
    );
  }
};

export default MyRoleDetailEnvTab;
