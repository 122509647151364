import {
  DataProductSelectPageDetailsModel,
  DataProductSelectPageModesModel,
  DataProductSelectPagePayloadElementTagsModel,
  DataProductSelectPageTagsModel,
} from '../../../app/services/dme/api/types';
import { LineageModel } from '../../../app/services/types';

export const activeDpStateId = 1;
export const initialValues = {
  detailModel: {
    dp_data_product_id: 0,
    data_product_name: '',
    ref_domain_id: 0,
    domain: '',
    developer_user_id: 0,
    developer_first_name: '',
    developer_last_name: '',
    domain_owner_user_id: 0,
    domain_owner_first_name: '',
    domain_owner_last_name: '',
    data_product_desc: '',
    row_created_datetime_utc: '',
    last_modified_datetime_utc: '',
    pii_present_flag: false,
    phi_present_flag: false,
    data_product_type_id: 0,
    data_product_lineage_filename: '',
    dp_data_product_state_id: activeDpStateId,
  } as DataProductSelectPageDetailsModel,
  payloadModel: [
    //   {
    //   dp_data_product_id: 0,
    //   dp_data_product_payload_id: 0,
    //   data_element: "",
    //   data_element_data_type: "",
    //   data_element_description: "",
    //   data_element_tag: "",
    //   is_deleted_flag: false,
    //   ref_tag_ids: []
    // }
  ] as Array<DataProductSelectPagePayloadElementTagsModel>,
  modeModel: [] as Array<DataProductSelectPageModesModel>,
  lineageModel: {
    dp_lineage_filename: '',
    dp_lineage_file: null,
  } as LineageModel,
  tagModel: [] as Array<DataProductSelectPageTagsModel>,
};
