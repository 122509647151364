import {
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { FC, useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { MdGroup } from 'react-icons/md';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { addBreadcrumb } from '../../../../../../app/helpers/navigationHelpers';
import { useLazyGetReportHubDetailsDiscussionPostsByReportHubIdQuery } from '../../../../../../app/services/dme/api/reportHubPost';
import { ReportHubDetailsPageDiscussionModel } from '../../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../../app/state/hooks';
import PanelCard from '../../../../../../components/PanelCard';
import PubNubConstants from '../../../../../../features/PubNubWrapper/constants';
import ReportHubCommentAttachmentList from './Attachment/AttachmentList';
import CommentList from './CommentList';
import PostList from './PostList';
import ReportHubCreatePost from './modals/CreatePostModal';

type Props = {};
export const channelNameRhMutation = 'reporthub_create_post';

const ReportHubExplorePortfoliosSelectedViewDiscussion: FC<Props> = props => {
  const location = useLocation();
  const params = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null);
  const [commentsOccupants, setcommentsOccupants] = useState<string[]>([]);
  const [posts, setPosts] = useState<ReportHubDetailsPageDiscussionModel[]>([]);
  const { messageEvent } = useAppSelector(s => s.pubNub);
  const pubnub = usePubNub();
  const [triggerGetPosts, { data, isSuccess }] = useLazyGetReportHubDetailsDiscussionPostsByReportHubIdQuery();

  const [selectedPost, setSelectedPost] = useState<ReportHubDetailsPageDiscussionModel | null>(null);

  const rhpId: number = parseInt(params?.rhpId || '0');

  useEffect(() => {
    if (data && data.length) setPosts(data);
  }, [data]);

  useEffect(() => {
    if (params && params.selectedTab === 'discussion') {
      triggerGetPosts(rhpId);
      // addBreadcrumb({ label: "Result", path: "/report-hub" }, 2);
      addBreadcrumb({ label: 'Selected', path: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    if (selectedCommentId) {
      onOpen();
    } else {
      onClose();
    }
  }, [selectedCommentId]);

  useEffect(() => {
    pubnub.subscribe({
      channels: [channelNameRhMutation],
    });

    return () => {
      pubnub.unsubscribe({
        channels: [channelNameRhMutation],
      });
    };
  }, [pubnub, isSuccess]);

  useEffect(() => {
    if (messageEvent?.channel === channelNameRhMutation) {
      const { message } = messageEvent;

      if (message.rhpId !== rhpId) return;

      switch (message.type) {
        case PubNubConstants.MessageEvent.Type.NEW:
          setPosts(s => [message.data, ...s]);
          break;
        case PubNubConstants.MessageEvent.Type.UPDATE:
          const updatedData = message.data;
          setPosts(s =>
            s.map(m => {
              if (m.rh_post_id === updatedData.rh_post_id) {
                return updatedData;
              } else {
                return m;
              }
            }),
          );
          break;
        case PubNubConstants.MessageEvent.Type.DELETE:
          setPosts(posts => posts.filter(p => p.rh_post_id !== message.postId));
          break;
      }
    }
  }, [messageEvent]);

  useEffect(() => {
    const id = parseInt(queryParams.get('post') || '0');
    if (posts && id && id !== selectedPost?.rh_post_id) {
      const postElement = document.getElementById(`${id}`);
      const post = posts.find(f => f.rh_post_id === id);
      if (postElement && post) {
        postElement.scrollIntoView({ behavior: 'smooth' });
        setSelectedPost(post);
      }
    }
  }, [posts, queryParams]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Discussion</Heading>
      <Grid templateColumns="repeat(3, minmax(450px, 1fr))" gap={3}>
        <GridItem>
          <PanelCard
            header={
              <VStack w="full">
                <HStack justifyContent="space-between" alignItems="start">
                  <Heading size="sm">Posts</Heading>
                  <ReportHubCreatePost rhpId={parseInt(params.rhpId || '0')} />
                </HStack>
                <Divider />
              </VStack>
            }
            fullHeight
          >
            {posts ? (
              <PostList
                postList={posts}
                onClickRow={f => {
                  setQueryParams(f ? { post: f?.rh_post_id.toString() } : {});
                  setSelectedCommentId(null);
                }}
                selectedPost={selectedPost}
              />
            ) : (
              <>Loading...</>
            )}
          </PanelCard>
        </GridItem>
        {selectedPost && (
          <>
            <GridItem>
              <PanelCard
                header={
                  <VStack w="full">
                    <HStack w="full" justifyContent="space-between" mb={3}>
                      <HStack>
                        <Heading size="sm" flex={1}>
                          Comments
                        </Heading>
                        {commentsOccupants.length > 0 && (
                          <Tooltip
                            label={
                              <VStack spacing={0}>
                                <Heading size="xs">In here</Heading>
                                {commentsOccupants.map((m, i) => (
                                  <Text key={i}>{m}</Text>
                                ))}
                              </VStack>
                            }
                          >
                            <span style={{ display: 'inline-flex' }}>
                              <MdGroup />
                              <Text fontSize={12} ml="1px">
                                {commentsOccupants.length}
                              </Text>
                            </span>
                          </Tooltip>
                        )}
                      </HStack>

                      <IconButton
                        variant="link"
                        aria-label="Close"
                        icon={<CgClose />}
                        onClick={() => {
                          setQueryParams({});
                          setSelectedPost(null);
                          setSelectedCommentId(null);
                        }}
                      />
                    </HStack>
                    <Divider />
                  </VStack>
                }
                fullHeight
              >
                <CommentList
                  selectedPost={selectedPost}
                  onOccupants={o => setcommentsOccupants(o)}
                  onClickAttachment={a => setSelectedCommentId(a)}
                />
              </PanelCard>
            </GridItem>
          </>
        )}
        {
          <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Attachments</ModalHeader>
              <ModalCloseButton onClick={() => setSelectedCommentId(null)} />
              <ModalBody>
                {selectedCommentId && <ReportHubCommentAttachmentList commentId={selectedCommentId} />}
              </ModalBody>

              <ModalFooter>
                <Button onClick={() => setSelectedCommentId(null)} mr={3} ml="auto">
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        }
      </Grid>
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedViewDiscussion;
