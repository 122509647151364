import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { channelNameIlMutation } from '..';
import { useDeleteImportantLinkPostMutation } from '../../../../../../app/services/dme/api/importantLinkPost';
import { ImportantLinkDetailsPageDiscussionModel } from '../../../../../../app/services/dme/api/types';
import PubNubConstants from '../../../../../../features/PubNubWrapper/constants';

const ImportantLinkPostDeleteButton = ({ post }: { post: ImportantLinkDetailsPageDiscussionModel }) => {
  const params = useParams();
  const pubnub = usePubNub();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAsync, deleteDetails] = useDeleteImportantLinkPostMutation();
  const [alertMessage, setAlertMessage] = useState('');

  const importantLinkId: number = parseInt(params.id || '0');

  const handleClose = () => {
    setAlertMessage('');
    onClose();
    deleteDetails.reset();
  };

  const handleClick = async () => {
    setAlertMessage('');

    try {
      if (!post?.link_post_id) return;

      await deleteAsync(post.link_post_id);

      pubnub.publish({
        channel: channelNameIlMutation,
        message: {
          importantLinkId,
          postId: post.link_post_id,
          type: PubNubConstants.MessageEvent.Type.DELETE,
        },
      });

      setAlertMessage('Post successfully deleted.');
      setTimeout(() => {
        handleClose();
      }, 100);
    } catch (error) {
      setAlertMessage('There was an error processing your request, please try again later.');
    }
  };

  return (
    <>
      <Button
        leftIcon={<FaTrashAlt />}
        variant="link"
        size="xs"
        _hover={{ textDecoration: 'none' }}
        sx={{ span: { marginRight: 1 } }}
        onClick={onOpen}
        isDisabled={deleteDetails.isLoading}
        isLoading={deleteDetails.isLoading}
      />
      <Modal isOpen={isOpen} onClose={handleClose} size="md" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Post</ModalHeader>
          <ModalCloseButton isDisabled={deleteDetails.isLoading} onClick={handleClose} />
          <ModalBody>
            <VStack>
              {!deleteDetails.isSuccess && <Text>Are you sure you want to delete this post?</Text>}
              {alertMessage && (
                <Alert status={deleteDetails.isSuccess ? 'success' : 'error'}>
                  <AlertIcon />
                  {alertMessage}
                </Alert>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={handleClose} ml="auto" isDisabled={deleteDetails.isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.main"
              isLoading={deleteDetails.isLoading}
              isDisabled={alertMessage !== '' && deleteDetails.isSuccess}
              onClick={handleClick}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportantLinkPostDeleteButton;
