import { dmeApi } from './index';
import { PostLoreLanguageModel, PutLoreLanguageModel } from '../../types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    postLoreLanguage: builder.mutation<void, PostLoreLanguageModel>({
      query: params => ({
        url: `/editorlorelanguage/add`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['GlossaryList'],
    }),

    putLoreLanguage: builder.mutation<void, PutLoreLanguageModel>({
      query: params => ({
        url: `/editorlorelanguage/update`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['GlossaryList'],
    }),

    deleteLoreLanguage: builder.mutation<void, number>({
      query: lllorelanguageid => ({
        url: `/editorlorelanguage/delete/lllorelanguageid/${lllorelanguageid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GlossaryList'],
    }),
  }),
  overrideExisting: false,
});

export const { usePostLoreLanguageMutation, usePutLoreLanguageMutation, useDeleteLoreLanguageMutation } = extendedApi;
