import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Spinner,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { GoCheck, GoX } from 'react-icons/go';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../../app/constants';
import { isError400Range } from '../../../app/helpers/apiHelper';
import useDebounce from '../../../app/hooks/useDebounce';
import { useAddRefTagMutation, useLazyGetRefTagByNameQuery } from '../../../app/services/dme/api/refTags';
import { tagNameMinLength } from '../constants';

const AddTags = () => {
  const toast = useToast();

  const [getAsync, getDetail] = useLazyGetRefTagByNameQuery();
  const [postAsync, postDetail] = useAddRefTagMutation();

  const TagFormSchema = Yup.object().shape({
    tag_name: Yup.string()
      .label('Tag Name')
      .required()
      .min(tagNameMinLength)
      .max(300, 'Text exceed the character limit of 300')
      .test((str, { createError }) => {
        return !!getDetail.data ? createError({ message: 'Tag name already exists!', path: 'tag_name' }) : true;
      }),
    tag_desc: Yup.string().label('Tag Description').required().max(3000, 'Text exceed the character limit of 3000'),
  });

  const { handleSubmit, errors, touched, handleChange, values, submitForm, resetForm, setValues, setTouched } =
    useFormik({
      enableReinitialize: true,
      validationSchema: TagFormSchema,
      initialValues: {
        tag_name: '',
        tag_desc: '',
      },
      onSubmit: (values, form) => {
        postAsync({
          tag_name: values.tag_name,
          tag_desc: values.tag_desc,
        })
          .unwrap()
          .then(() => {
            toast({ description: `Tag successfully added`, status: 'success' });
            clearModal();
          })
          .catch(e => {
            toast({
              description: isError400Range(e) && e.data?.error_message ? e.data.error_message : defaultErrorMessage,
              status: 'error',
            });
          });
      },
    });

  const debounceTagName = useDebounce(values.tag_name, 400);

  useEffect(() => {
    if (debounceTagName.length >= tagNameMinLength) {
      getAsync(debounceTagName)
        .unwrap()
        .then(() => {
          setTouched({ ...touched, tag_name: true });
        });
    }
  }, [debounceTagName]);

  const clearModal = () => {
    resetForm();
  };

  const isInvalidTagName = !!errors.tag_name && !!touched.tag_name;

  return (
    <>
      <VStack>
        <Heading size="md">Add New Tag</Heading>

        <Divider />

        <form onSubmit={handleSubmit}>
          <HStack alignItems="start">
            <VStack spacing="5" pt={5} w="container.sm">
              <FormControl isInvalid={isInvalidTagName} display="flex">
                <FormLabel minW="150px" htmlFor="tag_name">
                  Tag Name
                </FormLabel>
                <Box w="full">
                  <Input
                    size="sm"
                    id="tag_name"
                    name="tag_name"
                    onChange={handleChange}
                    onBlur={e =>
                      setValues({
                        ...values,
                        tag_name: e.target.value.trim(),
                      })
                    }
                    value={values.tag_name}
                    maxLength={300}
                  />
                  <FormErrorMessage>{errors.tag_name}</FormErrorMessage>
                </Box>
              </FormControl>

              <FormControl isInvalid={!!errors.tag_desc && touched.tag_desc} alignItems="start">
                <Box textAlign="right">
                  {values.tag_desc.length > 0 && <Text as="small">{`${values.tag_desc.length}/${3000}`}</Text>}
                </Box>
                <Flex>
                  <FormLabel minW="150px" htmlFor="tag_desc">
                    Tag Description
                  </FormLabel>
                  <Box width="full">
                    <Textarea
                      size="sm"
                      id="tag_desc"
                      name="tag_desc"
                      onChange={handleChange}
                      onBlur={e =>
                        setValues({
                          ...values,
                          tag_desc: e.target.value.trim(),
                        })
                      }
                      value={values.tag_desc}
                      maxLength={3000}
                    />
                    <FormErrorMessage>{errors.tag_desc}</FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>

              <ButtonGroup>
                <Button
                  isDisabled={postDetail.isLoading || getDetail.isLoading || getDetail.isFetching}
                  variant="outline"
                  onClick={() => clearModal()}
                  ml="auto"
                >
                  Cancel
                </Button>
                <Button
                  isLoading={postDetail.isLoading}
                  isDisabled={postDetail.isLoading || getDetail.isLoading || getDetail.isFetching}
                  variant="solid"
                  colorScheme="brand.main"
                  type="submit"
                >
                  Submit
                </Button>
              </ButtonGroup>
            </VStack>

            <Box>
              {getDetail.isLoading || getDetail.isFetching ? (
                <Spinner mt="6" color="blue.500" />
              ) : !!touched.tag_name ? (
                <Icon
                  mt="6"
                  aria-label={isInvalidTagName ? 'Tag name is invalid' : 'Tag name is valid'}
                  as={isInvalidTagName ? GoX : GoCheck}
                  color="white"
                  bg={isInvalidTagName ? 'red.500' : 'green.500'}
                  rounded="full"
                  w="6"
                  h="6"
                />
              ) : (
                <></>
              )}
            </Box>
          </HStack>
        </form>
      </VStack>
    </>
  );
};

export default AddTags;
