import {
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  UseToastOptions,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { AiOutlineStop } from 'react-icons/ai';
import { BsFillPencilFill } from 'react-icons/bs';
import { GoCheck, GoX } from 'react-icons/go';
import { MdOutlineCancel, MdOutlinePending } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { channelNameCustomRequestApproval } from '.';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import {
  useLazyGetCustomRequestByCrIdQuery,
  useLazyGetCustomRequestByStatusIdWithApproversQuery,
  usePostCustomRequestStatusMutation,
  usePutCustomRequestClaimMutation,
} from '../../../app/services/dme/api/customRequest';
import { useGetRefRequestStatusQuery } from '../../../app/services/dme/api/refRequestStatus';
import { CustomRequestApprovalModel, GetCustomRequestByStatusIdModel, GetRefRequestStatusModel } from '../../../app/services/dme/api/types';
import { useGetRefUserListQuery } from '../../../app/services/dme/api/user';
import { useAppSelector } from '../../../app/state/hooks';
import CustomTable from '../../../components/CustomTable';
import PubNubConstants from '../../../features/PubNubWrapper/constants';
import { customRequestNotesMaxLength } from '../../../features/CustomRequest/constants';

type Props = {
  status: GetRefRequestStatusModel;
  tabIndex: number;
};

type StatusRow = { row: Row<CustomRequestApprovalModel> };
const _openDelay = 1000;

const customRequest = {
  // TODO move to src/app/constants/env.ts
  status: {
    approved: {
      id: 2,
      name: 'New',
    },
    denied: {
      id: 5,
      name: 'Denied',
    },
    pending: {
      id: 6,
      name: 'Pending',
    },
  },
};
const _toastOptionsError: UseToastOptions = {
  description: 'Something went wrong, please try again later or contact admin',
  status: 'error',
};

// TODO optimistic update built-in rtk-query: https://redux-toolkit.js.org/rtk-query/usage/examples#react-optimistic-updates
const CustomRequestsApprovalTab: React.FC<Props> = ({ status, tabIndex }) => {
  const [listAsync, { data: apiData, isLoading, isFetching, isSuccess }] = useLazyGetCustomRequestByStatusIdWithApproversQuery();
  const usersDetail = useGetRefUserListQuery();
  const statusDetail = useGetRefRequestStatusQuery();
  const [getAsync, getDetail] = useLazyGetCustomRequestByCrIdQuery();
  const [postAsync, postDetail] = usePostCustomRequestStatusMutation();
  const [claimAsync, claimDetail] = usePutCustomRequestClaimMutation();
  const toast = useToast();

  const pubnub = usePubNub();
  const { messageEvent } = useAppSelector(s => s.pubNub);
  const { logonUser } = useAppSelector(s => s.user);
  const userWriteAccess = useIsUserHasRequiredAppAccess('customrequestapproval.write');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<CustomRequestApprovalModel | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<{ value: number; label: string } | null>(null);
  const [requestNotes, setRequestNotes] = useState<string>('');
  const [data, setData] = useState<CustomRequestApprovalModel[] | undefined>();
  const [localStatus, setLocalStatus] = useState(status);

  // New state for handling assignment
  const [selectedApprover, setSelectedApprover] = useState<number | null>(null);
  const [showAssignDropdown, setShowAssignDropdown] = useState<number | null>(null);

  const isNewTab = localStatus.ref_request_status_id === 1;

  const handleClaim =
    (data: CustomRequestApprovalModel, claimed_flag: boolean, user_id: number) =>
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (logonUser) {
        optimisticUpdate({ ...data, claimed_flag, claimed_user_id: claimed_flag ? logonUser.ref_user_id : null });
        claimAsync({
          cr_custom_request_id: data.cr_custom_request_id,
          claimed_flag,
          ref_user_id: user_id,
        })
          .unwrap()
          .then(() => handlePublish(data, PubNubConstants.MessageEvent.Type.CLAIM))
          .catch(() => {
            optimisticUpdate(data);
            toast(_toastOptionsError);
          });
      }
    };

  const handleChangeStatusFromNew =
    (entry: CustomRequestApprovalModel, id: number, name: string) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if ((id === customRequest.status.denied.id || id === customRequest.status.pending.id) && statusDetail.data) {
        setSelectedStatus({
          label: name,
          value: id,
        });
        setSelected(entry);
        onOpen();
      } else {
        const request_status_name =
          statusDetail.data?.find(f => f.ref_request_status_id === id)?.request_status_name ?? '';
        optimisticUpdate({ ...entry, request_status_name });
        postAsync({
          cr_custom_request_id: entry.cr_custom_request_id,
          ref_request_status_id: id,
          notes: '',
        })
          .unwrap()
          .then(() => {
            toast({ description: `Request Status changed to ${request_status_name.toLowerCase()}`, status: 'success' });
            handlePublish(entry);
          })
          .catch(() => {
            optimisticUpdate(entry);
            toast(_toastOptionsError);
          });
      }
    };

  const handleSave = () => {
    if (selected && selectedStatus) {
      onClose();
      const request_status_name =
        statusDetail.data?.find(f => f.ref_request_status_id === selectedStatus.value)?.request_status_name ?? '';
      optimisticUpdate({ ...selected, request_status_name });
      postAsync({
        cr_custom_request_id: selected.cr_custom_request_id,
        ref_request_status_id: selectedStatus.value,
        notes: requestNotes,
      })
        .unwrap()
        .then(() => {
          toast({ description: `Request Status changed to ${request_status_name.toLowerCase()}`, status: 'success' });
          handlePublish(selected);
          handleClose();
        })
        .catch(() => {
          optimisticUpdate(selected);
          toast(_toastOptionsError);
        });
    }
  };

  const optimisticUpdate = (entry: CustomRequestApprovalModel) => {
    // optimistic update before real update,
    pubnub.publish({
      channel: channelNameCustomRequestApproval,
      message: {
        data: entry,
        type: 'n/a', // `type` used for fetching status list
      },
    });
  };

  const handlePublish = (entry: CustomRequestApprovalModel, type = PubNubConstants.MessageEvent.Type.UPDATE) => {
    getAsync(entry.cr_custom_request_id)
      .unwrap()
      .then(newData =>
        pubnub.publish({
          channel: channelNameCustomRequestApproval,
          message: {
            data: newData,
            type, // `type` used for fetching status list
          },
        }),
      );
  };

  const handleSelect =
    (original: CustomRequestApprovalModel) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (statusDetail.data) {
        setSelected(original);
        onOpen();
      }
    };

  const handleClose = () => {
    onClose();
    setSelected(null);
    setSelectedStatus(null);
    setRequestNotes('');
  };

  // Triggered when "Assign" is clicked
  const handleAssignClick = (original: CustomRequestApprovalModel) => {
    setShowAssignDropdown(original.cr_custom_request_id);
    handleClaim(original, false, 0);
  };

  // Corrected handleApproverSelect to directly update the state
  const handleApproverSelect = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      setSelectedApprover(selectedOption.value); // Set selected approver ID
    } else {
      setSelectedApprover(null); // Reset if no approver is selected
    }
  };

  const handleAssignRequestToApprover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, claimed_flag:boolean, data: CustomRequestApprovalModel) => {
    e.stopPropagation();  // Prevent click from triggering other events
    if (selectedApprover) {
      optimisticUpdate({ ...data, claimed_flag, claimed_user_id: selectedApprover });
      claimAsync({
        cr_custom_request_id: data.cr_custom_request_id,
        claimed_flag: true,
        ref_user_id: selectedApprover, // Use the selected approver ID
      })
      .unwrap()
      .then(() => {
        toast({ description: `Request assigned to approver successfully`, status: 'success' });
        handlePublish(data, PubNubConstants.MessageEvent.Type.CLAIM)
        setSelectedApprover(null); // Reset after successful assignment
        setShowAssignDropdown(null); // Close dropdown after selection
      })
      .catch(() => {
        optimisticUpdate(data);
        toast(_toastOptionsError);
      });
    }
  };

  const approverOptions = apiData?.approvers
    ?.filter(approver=> logonUser?.ref_user_id !== approver.ref_user_id)
    .map(approver => ({
      value: approver.ref_user_id,
      label: `${approver.first_name} ${approver.last_name}`,
  })) || [];

  const Header = [
    ...(!userWriteAccess
      ? []
      : [
          {
            Header: 'Action',
            styles: { minWidth: 400, whiteSpace: 'initial' },
            Cell: ({ row: { original } }: StatusRow) => {
              const userHasClaimed = data?.some(s => s.claimed_user_id === logonUser?.ref_user_id);
              const claimedBy = usersDetail.data?.find(f => f.ref_user_id === original.claimed_user_id);
              const claimedByFullName =
                claimedBy && (`${claimedBy.first_name || ''} ${claimedBy.last_name || ''}`.trim() || claimedBy.email);
              return isNewTab && original.request_status_name === 'New'? (
                <HStack>
                  {original.claimed_flag && original.claimed_user_id === logonUser?.ref_user_id? (
                    <HStack>
                      <Tooltip label="Approve" openDelay={_openDelay}>
                        <IconButton
                          aria-label="Approve"
                          size="sm"
                          colorScheme="brand.main"
                          icon={<GoCheck />}
                          onClick={handleChangeStatusFromNew(
                            original,
                            customRequest.status.approved.id,
                            customRequest.status.approved.name,
                          )}
                          isDisabled={claimDetail.isLoading || postDetail.isLoading || getDetail.isLoading}
                        />
                      </Tooltip>
                      <Tooltip label="Deny" openDelay={_openDelay}>
                        <IconButton
                          aria-label="Deny"
                          size="sm"
                          bg="brand.error"
                          colorScheme="brand.error"
                          icon={<GoX />}
                          onClick={handleChangeStatusFromNew(
                            original,
                            customRequest.status.denied.id,
                            customRequest.status.denied.name,
                          )}
                          isDisabled={claimDetail.isLoading || postDetail.isLoading || getDetail.isLoading}
                        />
                      </Tooltip>
                      <Tooltip label="Pending" openDelay={_openDelay}>
                        <IconButton
                          aria-label="Pending"
                          size="sm"
                          bg="brand.main"
                          variant="outline"
                          colorScheme="brand.main"
                          icon={<MdOutlinePending />}
                          onClick={handleChangeStatusFromNew(
                            original,
                            customRequest.status.pending.id,
                            customRequest.status.pending.name,
                          )}
                          isDisabled={claimDetail.isLoading || postDetail.isLoading || getDetail.isLoading}
                        />
                      </Tooltip>
                    </HStack>
                  ) : (
                    <HStack
                      style={{ 
                        width: '100%',
                      }}
                    >
                      {showAssignDropdown !== original.cr_custom_request_id && (
                        <>
                          <Tooltip label={claimedByFullName}>
                            <Button
                              size="sm"
                              colorScheme={original.claimed_flag ? 'orange.800' : 'brand.main'}
                              bg={original.claimed_flag ? 'orange.400' : 'brand.main.default'}
                              onClick={original.claimed_flag ? e => e.stopPropagation() : handleClaim(original, true, 0)}
                              isDisabled={
                                (userHasClaimed && !original.claimed_flag) ||
                                claimDetail.isLoading ||
                                postDetail.isLoading ||
                                getDetail.isLoading
                              }
                            >
                              {original.claimed_flag ? 'Claimed' : 'Claim'}
                            </Button>
                          </Tooltip>

                          <Tooltip label="Assign to Approver">
                            <Button
                              size="sm"
                              colorScheme='brand.main.default'
                              bg='brand.main.default'
                              onClick={(e) => {
                                e.stopPropagation() 
                                handleAssignClick(original)
                              }}
                              isDisabled={(userHasClaimed && !original.claimed_flag) || original.claimed_flag ||claimDetail.isLoading || postDetail.isLoading || getDetail.isLoading}
                            >
                              Assign
                            </Button>
                          </Tooltip>
                        </>
                      )}

                      {/* Dropdown for Approvers */}
                      {showAssignDropdown === original.cr_custom_request_id && apiData?.approvers && (
                          <Box
                            onClick={(e) => e.stopPropagation()} // Stop propagation on the container to prevent row click
                            style={{ 
                              display: 'flex',
                              rowGap: '8px',
                              flexGrow: 1,
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                width: '100%',
                                columnGap: '8px',
                              }}
                            >
                              <Box
                                style={{
                                    flexGrow: 1,
                                  }}>
                                <Select
                                  size = 'sm'
                                  placeholder="Select Approver"
                                  options={approverOptions}
                                  onChange={handleApproverSelect} // Correctly handles option selection
                                  value={approverOptions.find(option => option.value === selectedApprover) || null} // Maintain selected value
                                />
                              </Box>
                              <Button
                                size="sm"
                                colorScheme= "brand.main.default"  // Use a valid color scheme
                                bg="brand.main.default" // Set specific background color
                                onClick={(e) => handleAssignRequestToApprover(e, true, original)}
                                isDisabled={!selectedApprover} // Disable if no approver is selected
                              >
                                Assign
                              </Button>

                              <Tooltip label="cancel" openDelay={_openDelay}>
                                <IconButton
                                  aria-label="cancel"
                                  size="sm"
                                  bg="brand.main"
                                  variant="outline"
                                  colorScheme="brand.main"
                                  icon={<MdOutlineCancel />}
                                  onClick={() =>{
                                    setSelectedApprover(null); // Reset after successful assignment
                                    setShowAssignDropdown(null); // Close dropdown after selection
                                    handleClaim(original, false, 0);
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                      )}
                    </HStack>
                  )}

                  {original.claimed_flag && (
                    <Tooltip label="Unclaim" openDelay={_openDelay}>
                      <IconButton
                        aria-label="Unclaim"
                        size="sm"
                        icon={<AiOutlineStop />}
                        onClick={handleClaim(original, false, 0)}
                        isDisabled={claimDetail.isLoading || getDetail.isLoading}
                      />
                    </Tooltip>
                  )}
                </HStack>
              ) : (
                <Tooltip label="Change Status" openDelay={500}>
                  <IconButton
                    isDisabled={postDetail.isLoading || getDetail.isLoading}
                    colorScheme="brand.main"
                    size="sm"
                    aria-label="Change Status"
                    icon={<BsFillPencilFill />}
                    onClick={handleSelect(original)}
                  />
                </Tooltip>
              );
            },
          },
        ]),
    {
      Header: 'ID',
      accessor: 'cr_custom_request_id',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'User',
      accessor: 'first_name',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: StatusRow) => {
        return <>{(original.first_name || '') + ' ' + (original.last_name || '')}</>;
      },
    },
    {
      Header: 'Title',
      accessor: 'request_title',
      styles: { maxWidth: '250px', whiteSpace: 'initial' },
    },
    {
      Header: 'Details',
      accessor: 'request_details',
      styles: { maxWidth: '400px', whiteSpace: 'initial' },
    },
    {
      Header: 'Expiration Date',
      styles: {},
      Cell: ({ row: { original } }: StatusRow) => {
        return <>{convertUtcToLocal(original.request_expiration_datetime_utc, 'MMMM D, YYYY h:mm A') || 'N/A'}</>;
      },
    },
    {
      Header: 'Creation Date',
      styles: {},
      Cell: ({ row: { original } }: StatusRow) => {
        return <>{convertUtcToLocal(original.request_datetime_utc, 'MMMM D, YYYY h:mm A') || 'N/A'}</>;
      },
    },
  ];

  useEffect(() => {
    setData(undefined);
    listAsync(status.ref_request_status_id);
    setLocalStatus(status);
  }, [status.ref_request_status_id]);

  useEffect(() => {
    if (isSuccess && apiData?.requests) {
      setData(apiData.requests);  // Update with new structure
    }
  }, [apiData, isSuccess]);

  useEffect(() => {
    pubnub.subscribe({
      channels: [channelNameCustomRequestApproval],
      //withPresence: true,
    });

    return () => {
      pubnub.unsubscribe({
        channels: [channelNameCustomRequestApproval],
      });
    };
  }, [pubnub]);

  useEffect(() => {
    if (messageEvent?.channel === channelNameCustomRequestApproval) {
      const { message } = messageEvent;
      const newData: GetCustomRequestByStatusIdModel = message.data;
      if (newData.request_status_name === localStatus.request_status_name) {
        const existsInData = !!data?.some(f => f.cr_custom_request_id === newData.cr_custom_request_id);
        if (existsInData) {
          // update
          setData(
            list =>
              list?.map(m => {
                if (m.cr_custom_request_id === newData.cr_custom_request_id) return newData;
                return m;
              }),
          );
        } else {
          // add
          setData(list => [...(list ?? []), newData]);
        }
      } else {
        // delete
        setData(list => list?.filter(f => f.cr_custom_request_id !== newData.cr_custom_request_id));
      }
    }
  }, [messageEvent]);

  return (
    <>
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isFetching}
        data={data || []}
        pageCount={0}
        pageSize={5}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={() => {}}
        onPageSizeChange={() => {}}
        onPageSearch={() => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        onRowClick={(e) => navigate(`./selected/id/${e.cr_custom_request_id}/details`)}
        styles={{
          tableContainer: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflowX: 'unset',
            overflowY: 'unset',
            maxWidth: '100%',
          },
        }}
      />

      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Status</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            <VStack gap={4}>
              <Box>
                <Text fontWeight="bold" as="span">
                  Title
                </Text>
                <Text>{selected?.request_title}</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Status</Text>
                {!!statusDetail.data && (
                  <Select
                    size="sm"
                    useBasicStyles
                    placeholder="Select status..."
                    value={selectedStatus}
                    options={statusDetail.data
                      .filter(f => f.ref_request_status_id !== 1)
                      .map(m => ({
                        label: m.request_status_name,
                        value: m.ref_request_status_id,
                        isDisabled: m.ref_request_status_id === localStatus.ref_request_status_id,
                      }))}
                    isReadOnly={isNewTab}
                    onChange={e => setSelectedStatus(e)}
                  />
                )}
              </Box>

              <Box>
                <HStack justifyContent="space-between" w="100%">
                  <Text fontWeight="bold">Notes</Text>
                  <Text>
                    {requestNotes.length > 0 && <Text as="small">{`${requestNotes.length}/${customRequestNotesMaxLength}`}</Text>}
                  </Text>
                </HStack>
                <Textarea
                  size="sm"
                  id="requestNotes"
                  name="requestNotes"
                  onChange={e => setRequestNotes(e.target.value)}
                  onBlur={e => setRequestNotes(e.target.value.trim())}
                  value={requestNotes}
                  maxLength={customRequestNotesMaxLength}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="brand.main" isDisabled={!selectedStatus} onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomRequestsApprovalTab;