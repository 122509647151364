export const cleanTinValue = (tin: any) => (tin?.toString() || '').replace('-', '').trim();

export const isValidString = (inputString: any) => {
  return !!inputString && typeof inputString === 'string' && !!inputString.length;
};

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(/[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/);
};

export const getFileName = (path: string) => {
  return path.replace(/^.*[\\\/]/, '');
};

export const removeImageFromHtmlString = (text: string): [string, boolean] => {
  try {
    const document = new DOMParser().parseFromString(text, 'text/html');
    let hasImage = false;
    for (var i = document.images.length; i-- > 0; ) {
      hasImage = true;
      document.images[i].parentNode?.removeChild(document.images[i]);
    }
    return [document.body.innerHTML, hasImage];
  } catch (error) {
    return [text, false];
  }
};

export const stringOrHtmlIsEmpty = (text: string | undefined | null): boolean => {
  if (!text || text.trim() === '' || text.trim() === '<p><br></p>') return true;

  const doc = new DOMParser().parseFromString(text, 'text/xml');
  if (doc?.firstChild?.textContent?.trim() === '') return true;

  return false;
};

export const getName = (obj: { [key: string]: any }): string => {
  const fname = obj?.first_name ? obj.first_name : '';
  const lname = obj?.last_name ? obj.last_name : '';
  const email = obj?.email ? obj.email : '';
  return !fname && !lname ? email : !fname ? lname : fname + (lname ? ` ${lname}` : '');
};

export function removeUnwantedCharacters(str: string) {
  return str.replace(/﻿/g, '');
}
