import { Button } from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { MdOutlineThumbUpAlt, MdThumbUpAlt } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { channelNameIlMutation } from '.';
import {
  useLazyGetImportantLinkPostByIdQuery,
  usePostImportantLinkPostToggleReactionMutation,
} from '../../../../../app/services/dme/api/importantLinkPost';
import { ImportantLinkDetailsPageDiscussionModel } from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import PubNubConstants from '../../../../../features/PubNubWrapper/constants';

type Props = {
  post: ImportantLinkDetailsPageDiscussionModel;
};

const ImportantLinkPostReactButton = ({ post }: Props) => {
  const params = useParams();
  const pubnub = usePubNub();
  const { logonUser } = useAppSelector(s => s.user);

  const [getAsync, getDetail] = useLazyGetImportantLinkPostByIdQuery();
  const [reactAsync, reactDetail] = usePostImportantLinkPostToggleReactionMutation();

  const toggleReaction = (link_post_id: number) => async () => {
    if (logonUser) {
      const importantLinkId: number = parseInt(params.id || '0');

      await reactAsync({
        link_post_id,
        reaction_user_id: logonUser.ref_user_id,
        ref_reaction_id: 1,
      });

      const data = await getAsync(post.link_post_id).unwrap();
      await pubnub.publish({
        channel: channelNameIlMutation,
        message: {
          importantLinkId,
          data: data[0],
          type: PubNubConstants.MessageEvent.Type.UPDATE,
        },
      });
    }
  };

  return (
    <Button
      leftIcon={
        post.reactor_user_id_list &&
        post.reactor_user_id_list.split(',').some(s => s === `${logonUser?.ref_user_id}`) ? (
          <MdThumbUpAlt />
        ) : (
          <MdOutlineThumbUpAlt />
        )
      }
      variant="link"
      size="sm"
      onClick={toggleReaction(post.link_post_id)}
      isLoading={reactDetail.isLoading || getDetail.isLoading}
      isDisabled={reactDetail.isLoading || getDetail.isLoading}
      _hover={{ textDecoration: 'none' }}
      sx={{ span: { marginRight: 1 } }}
    >
      {post.cnt_post_reactions}
    </Button>
  );
};

export default ImportantLinkPostReactButton;
