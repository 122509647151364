import { Box, ButtonGroup, IconButton, Tag, Text, Tooltip } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import {
  useGetGlossaryPaginatedFilterQuery,
  useGetGlossaryPaginatedQuery,
} from '../../../../app/services/dme/api/glossary';
import { GetGlossaryPaginatedFilterModel, LoreLanguageFirstDatasetModel } from '../../../../app/services/dme/api/types';
import { SortType } from '../../../../app/services/types';
import { DynamicObject } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import AppAuth from '../../../../features/AppAuth';
import DeleteLoreLanguageDialog from './DeleteGlossaryDialog';

type Props = {};
const InitialSortBy: SortType<LoreLanguageFirstDatasetModel> = {
  id: 'll_term',
  desc: false,
};

const ResultData: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const navigate = useNavigate();
  const { data: filterData } = useGetGlossaryPaginatedFilterQuery();

  const filters = useMemo(() => {
    const tmpFilters: { filter_header: string; filter_detail: string[] }[] = [];
    searchParams.forEach((value, key) => {
      tmpFilters.push({
        filter_header: key,
        filter_detail: [...decodeURIComponent(value).split(',')],
      });
    });
    if (tmpFilters.length > 0 && filterData) {
      const initFilters: GetGlossaryPaginatedFilterModel[] = [];
      tmpFilters.forEach(filter => {
        filter.filter_detail.forEach((domain_name: string) => {
          const item = filterData.find(f => f.domain_name === domain_name);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      return initFilters.map(({ ref_domain_id }) => ({ ref_domain_id }));
    }
    return [];
  }, [filterData, searchParams]);

  const { data, isLoading, isFetching } = useGetGlossaryPaginatedQuery({
    filters,
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
  });

  const Header = [
    {
      Header: 'Action',
      isSortable: false,
      isAction: true,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: ({ row: { original } }: { row: { original: LoreLanguageFirstDatasetModel } }) => {
        return (
          <>
            <AppAuth requiredAppAccess={AppAccess.EditLoreLanguageWrite}>
              {isAuthorized =>
                isAuthorized ? (
                  <ButtonGroup spacing={4}>
                    <Tooltip label="Edit">
                      <IconButton
                        color="brand.main.default"
                        variant="link"
                        aria-label="Edit"
                        icon={<BsFillPencilFill />}
                        onClick={() => navigate(`./selected/id/${original.ll_lore_language_id}/details`)}
                        minWidth={1}
                      />
                    </Tooltip>
                    <DeleteLoreLanguageDialog
                      ll_lore_language_id={original.ll_lore_language_id}
                      triggerElement={
                        <Tooltip label="Delete">
                          <IconButton
                            color="brand.error"
                            variant="link"
                            aria-label="Delete"
                            icon={<FaTrash />}
                            minWidth={1}
                          />
                        </Tooltip>
                      }
                    />
                  </ButtonGroup>
                ) : (
                  <Tag>No Action</Tag>
                )
              }
            </AppAuth>
          </>
        );
      },
    },
    {
      Header: 'Term',
      accessor: 'll_term',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: LoreLanguageFirstDatasetModel } }) => {
        return (
          <Box>
            <Text maxW="350px" noOfLines={1} title={original.ll_term}>
              {original.ll_term}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Definition',
      accessor: 'll_definition',
      styles: { whiteSpace: 'initial' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: LoreLanguageFirstDatasetModel } }) => {
        return (
          <Box maxW="800px">
            <Text noOfLines={3} title={original.ll_definition}>
              {original.ll_definition}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Last Modified Date',
      accessor: 'last_modified',
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: LoreLanguageFirstDatasetModel } }) => {
        return <>{convertUtcToLocal(original.last_modified) || '-'}</>;
      },
    },
  ];

  // const onClickRow = (row: LoreLanguageFirstDatasetModel) => {
  //   navigate(`./selected/id/${row.ll_lore_language_id}/details`);
  // };

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        isLoading={isLoading}
        isFetching={isFetching}
        data={data?.data ?? []}
        pageCount={data?.total_pages || 0}
        pageSize={data?.page_size || pageSize}
        totalRecords={data?.total_records || 0}
        pageIndex={pageNumber - 1}
        headers={Header}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [InitialSortBy] }}
        manualSortBy
        disableSortRemove
        onPageChange={index => {
          setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          setPageNumber(1);
          setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          setPageNumber(1);

          const params: DynamicObject = {};
          searchParams.forEach((val, key) => (params[key] = val));
          setSearchParams({ ...params, search: search }, { replace: true });
        }}
        onSort={sort => {
          if (sort[0]) {
            setPageNumber(1);
            setSortBy(sort[0]);
          }
        }}
        styles={{ pagination: { justifyContent: 'start' }, header: { justifyContent: 'left' } }}
        manual={true}
      />
    </Box>
  );
};

export default ResultData;
