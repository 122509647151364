import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC, useRef } from 'react';
import * as Yup from 'yup';
import { buildSchema } from '../../../../app/helpers/formSchemabuilder';
import { AddGovernanceOfficeEdsModel } from '../../../../app/services/dme/api/types';
import {
  MyEdsRequestCreateRequestProps,
  myEdsRequestCreateRequestFields,
  myEdsRequestCreateRequestTabs,
} from './utils';

const MyExternalDataSharingRequestsCreateRequestDetails: FC<MyEdsRequestCreateRequestProps> = ({
  tabIndex,
  setTabIndex,
  data,
  setData,
}) => {
  const actionRef = useRef<number>(0);

  const FormSchema = Yup.object().shape({
    request_subject: buildSchema(myEdsRequestCreateRequestFields.request_subject),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddGovernanceOfficeEdsModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      if (actionRef.current) {
        setData(d => ({ ...d, ...values }));
        setTabIndex(i => i + actionRef.current);
      }
    },
  });

  return (
    <VStack w="container.md">
      <FormControl isInvalid={!!errors.request_subject && !!touched.request_subject} display="flex">
        <FormLabel minW="150px">{myEdsRequestCreateRequestFields.request_subject.label}</FormLabel>
        <Box w="100%">
          <Input
            size="sm"
            id="request_subject"
            name="request_subject"
            value={values.request_subject ?? ''}
            maxLength={myEdsRequestCreateRequestFields.request_subject.max}
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormErrorMessage>{errors.request_subject}</FormErrorMessage>
        </Box>
      </FormControl>

      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {myEdsRequestCreateRequestTabs.length}
        </Text>
        <ButtonGroup>
          <Button size="sm" isDisabled={tabIndex === 0} onClick={() => {}}>
            Previous
          </Button>
          <Button
            size="sm"
            isDisabled={tabIndex === myEdsRequestCreateRequestTabs.length - 1}
            onClick={() => {
              actionRef.current = 1;
              submitForm();
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default MyExternalDataSharingRequestsCreateRequestDetails;
