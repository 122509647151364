import { VStack, Heading, HStack, Text, IconButton } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { MdOutlineFilterList } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useGetDataProductSelectTagsByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';
import { DataProductSelectPageTagsModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

type Props = {};

const DataProductSelectedTags = (props: Props) => {
  const params = useParams();
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    setProductId(+(params?.dataProductId || 0));
  }, [params]);

  const { data, isLoading } = useGetDataProductSelectTagsByProductIdQuery(productId, {
    skip: !productId,
  });

  const Header = [
    {
      Header: 'Name',
      accessor: 'tag_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Description',
      accessor: 'tag_desc',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Created By',
      accessor: 'first_name',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPageTagsModel } }) => {
        return <>{`${original.first_name || ''} ${original.last_name || ''}`}</>;
      },
    },
    {
      Header: 'Created Date',
      accessor: 'row_created_datetime_utc',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPageTagsModel } }) => {
        return <>{convertUtcToLocal(original.row_created_datetime_utc) || '-'}</>;
      },
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Data Product Tags</Heading>
      {isLoading ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={isLoading}
          isFetching={isLoading}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
        />
      </HStack>
    );
  }
};

export default DataProductSelectedTags;
