import { Box, Divider, Flex, IconButton } from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppSize } from '../../app/constants';
import { setIsSideNavOpen } from '../../app/slices/appSlice';
import { useAppSelector } from '../../app/state/hooks';
import AppAuth from '../../features/AppAuth';
import AuthenticatedUserWrapper from '../../features/AuthenticatedUserWrapper';
import NavItem from './NavItem';
import navItems from './navItems';

type Props = {};

const SideNav = (props: Props) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const dispatch = useDispatch();
  const { isSideNavOpen } = useAppSelector(s => s.app);
  const isProd = process.env.REACT_APP_ENV === 'PROD';

  return (
    <>
      {['/claims', '/unauthorized'].includes(location.pathname) ? null : (
        <Flex
          id="SideNav"
          direction="column"
          minH="100vh"
          px={1}
          py={1}
          overflowX="hidden"
          style={{
            WebkitTransition: 'width 100ms ease-in-out',
            MozTransition: 'width 100ms ease-in-out',
            OTransition: 'width 100ms ease-in-out',
            transition: 'width 100ms ease-in-out',
          }}
          w={isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close}
        >
          {isSideNavOpen ? (
            <IconButton
              maxW="30px"
              ml="auto"
              aria-label="Hide"
              onClick={() => dispatch(setIsSideNavOpen(false))}
              icon={<HiOutlineChevronDoubleLeft />}
            />
          ) : (
            <IconButton
              maxW="30px"
              ml="auto"
              aria-label="Show"
              onClick={() => dispatch(setIsSideNavOpen(true))}
              icon={<HiOutlineChevronDoubleRight />}
            />
          )}

          <AuthenticatedUserWrapper onAuthenticated={() => setIsAuthenticated(true)}>
            <Divider mt={1} />
            <Box overflowY="scroll" maxH="calc(100vh - 100px - 0.75rem)">
              {(isProd ? navItems.filter(n => !n.hideInProd) : navItems).map((m, i) => (
                <Fragment key={i}>
                  {m.requiredAppAccess ? (
                    <AppAuth key={m.text} requiredAppAccess={m.requiredAppAccess}>
                      <NavItem item={m} isOpen={isSideNavOpen} />
                    </AppAuth>
                  ) : (
                    <NavItem item={m} isOpen={isSideNavOpen} />
                  )}
                </Fragment>
              ))}
            </Box>
          </AuthenticatedUserWrapper>
        </Flex>
      )}
    </>
  );
};

export default SideNav;
