import { Box, Divider, HStack, Heading, Skeleton, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { createElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import env from '../../../app/constants/env';
import { addBreadcrumb } from '../../../app/helpers/navigationHelpers';
import { useGetGoEdsRequestSelectedDetailsQuery } from '../../../app/services/dme/api/governanceOfficeEds';
import GovernanceOfficeSelectedAssociatedUsers from './AssociatedUsers';
import GovernanceOfficeSelectedDetails from './Details';
import GovernanceOfficeSelectedDiscussion from './Discussion';
import GovernanceOfficeSelectedStatus from './Status';
import GovernanceOfficeSelectedVotes from './Votes';

type Props = {};
const tabs: {
  label: string;
  path: 'details' | 'status' | 'votes' | 'discussion' | 'associated-users';
  component: ({ data }: any) => JSX.Element;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: GovernanceOfficeSelectedDetails,
  },
  {
    label: 'Status',
    path: 'status',
    component: GovernanceOfficeSelectedStatus,
  },
  {
    label: 'Votes',
    path: 'votes',
    component: GovernanceOfficeSelectedVotes,
  },
  {
    label: 'Discussion',
    path: 'discussion',
    component: GovernanceOfficeSelectedDiscussion,
  },
  {
    label: 'Associated Users',
    path: 'associated-users',
    component: GovernanceOfficeSelectedAssociatedUsers,
  },
];

const GovernanceOfficeSelected = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const goEdsId: number = parseInt(params?.goEdsId || '');
  const { data, isLoading, isFetching } = useGetGoEdsRequestSelectedDetailsQuery({ goEdsId }, { skip: goEdsId === 0 });

  const displayedTabs = useMemo(() => {
    const hideVotesTab =
      data?.go_eds_request_status_id === env.db.goEds.status.developingId ||
      data?.go_eds_request_status_id === env.db.goEds.status.discussionId;

    return hideVotesTab ? tabs.filter(t => t.path !== 'votes') : tabs;
  }, [data]);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== displayedTabs[tabIndex]?.path) {
      if (!displayedTabs[tabIndex]?.path) {
        setTabIndex(0);
      } else {
        navigate('./../' + displayedTabs[tabIndex].path + location.search);
      }
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = displayedTabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params, displayedTabs]);

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <VStack w="100%">
      <HStack>
        {isLoading || isFetching ? (
          <Skeleton height="18px" w="56" mb="1.5" />
        ) : (
          <Heading size="md">{data && `Request # ${data.go_eds_request_id}`}</Heading>
        )}
      </HStack>
      <Divider />

      {isLoading || isFetching ? (
        <Box mt="2">Loading...</Box>
      ) : (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {displayedTabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>
              {data && tabIndex !== undefined ? (
                displayedTabs[tabIndex] && createElement(displayedTabs[tabIndex].component, {})
              ) : (
                <></>
              )}
            </Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default GovernanceOfficeSelected;
