import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { InfoweaveModel } from '../../app/services/dme/api/types';

type Props = {
  children: ReactNode;
};

// export type InfoweaveFilterTabName = "archive" | "bookmark" | "focus" | "all";
export type InfoweaveFilterTabName = 'archive' | 'bookmark' | 'focus';
export type InfoweaveReadFilter = 'all' | 'read' | 'unread';
type InfoweaveContextModel = {
  selectedIds: number[];
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
  setInfoweaveForModal: React.Dispatch<React.SetStateAction<InfoweaveData | null>>;
};
export const InfoweaveTagFilterOrder = 1;
export const InfoweaveParams = {
  tab: 'tab',
  search: 'search',
  read: 'view',
  startDate: 'startdate',
  endDate: 'enddate',
  tags: 'tags',
};
export const InfoweaveEvent = {
  bookmark: 1,
  unbookmark: 2,
  archive: 3,
  unarchive: 4,
  read: 5,
  new: 6,
};
export type InfoweaveData = InfoweaveModel & {
  tags: InfoweaveModel[];
};
export const colorBlue = '#0A7AFF';

const InfoweaveContext = createContext<InfoweaveContextModel>({
  selectedIds: [],
  setSelectedIds: () => {},
  setInfoweaveForModal: () => {},
});

const InfoweaveContextProvider: FC<Props> = ({ children }) => {
  const [infoweaveForModal, setInfoweaveForModal] = useState<InfoweaveData | null>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (infoweaveForModal) onOpen();
    else onClose();
  }, [infoweaveForModal]);

  const handleClose = () => {
    setInfoweaveForModal(null);
  };

  return (
    <InfoweaveContext.Provider
      value={{
        selectedIds,
        setSelectedIds,
        setInfoweaveForModal,
      }}
    >
      {children}

      <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{infoweaveForModal?.infoweave_message}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{infoweaveForModal?.message_announcement}</ModalBody>

          <ModalFooter gap={3}>
            <Button colorScheme="brand.main" onClick={handleClose}>
              Exit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </InfoweaveContext.Provider>
  );
};

export const useInfoweaveContext = () => {
  const context = useContext(InfoweaveContext);
  if (!context) throw new Error('InfoweaveContext not found');
  return context;
};

export default InfoweaveContextProvider;
