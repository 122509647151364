import { dmeApi } from '.';
import { GetRefAccessLevelListModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefAccessLevelList: builder.query<GetRefAccessLevelListModel[], void>({
      query: () => `/refaccesslevel`,
    }),
  }),
});

export const { useGetRefAccessLevelListQuery, useLazyGetRefAccessLevelListQuery } = extendedApi;
