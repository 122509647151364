/* eslint-disable react-hooks/exhaustive-deps */
import { VStack, Divider, Heading, List, ListItem } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useGetUserRoleListByRefUserIdV2Query } from '../../../app/services/dme/api/userRole';
import { RefUserModel, UserRoleModel } from '../../../app/services/dme/api/types';

type IProps = {
  user: RefUserModel;
};

const UserRole: FC<IProps> = ({ user }) => {
  const userRoleQry = useGetUserRoleListByRefUserIdV2Query(user.ref_user_id, {
    refetchOnMountOrArgChange: true,
  });

  const [userRoleList, setUserRoleList] = useState<UserRoleModel[]>([]);

  useEffect(() => {
    const result: UserRoleModel[] = userRoleQry.data?.slice().filter(m => !m.disabled_flag) ?? [];
    result.sort((a, b) => a.role_name.localeCompare(b.role_name));
    setUserRoleList(result);
  }, [userRoleQry.data]);

  const displayUserRoles =
    userRoleList.length > 0 ? (
      <>
        <Divider />
        {userRoleList.map((m, index) => (
          <List key={index}>
            <ListItem>{m.role_name}</ListItem>
          </List>
        ))}
      </>
    ) : (
      <>No Roles assigned to user</>
    );
  return (
    <VStack align="stretch" spacing={4}>
      <Heading as="h6" size="md" lineHeight="1.9">
        Roles
      </Heading>
      <VStack spacing={2} alignItems="flex-start">
        {userRoleQry.isFetching ? <>Loading...</> : displayUserRoles}
      </VStack>
    </VStack>
  );
};

export default UserRole;
