import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { ChangeEvent, FC, ReactNode, useCallback, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { CgClose } from 'react-icons/cg';
import { FaFile } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import * as Yup from 'yup';
import { useGetGoEdsRequestCreationQaListQuery } from '../../../../app/services/dme/api/governanceOfficeEds';
import {
  AddGovernanceOfficeEdsAnswerModel,
  AddGovernanceOfficeEdsModel,
  GoEdsQuestionTypeModel,
} from '../../../../app/services/dme/api/types';
import { appColors } from '../../../../app/theme';
import CustomDatePicker, { customDatePickerDefaultPropsConfig } from '../../../../components/CustomDatePicker';
import { MyEdsRequestCreateRequestProps, myEdsRequestCreateRequestTabs } from './utils';

const defaultErrorMessage = 'This question is required';
const startOfEpochDate = dayjs(new Date(0)).startOf('day').toDate();
const maxDate = dayjs().add(5, 'year').toDate();

const determineNoErros = (type: GoEdsQuestionTypeModel, answer: AddGovernanceOfficeEdsAnswerModel) => {
  if (type === 'Extended Open Text Box') {
    return !!answer.user_answer_text_box && !!answer.go_eds_ref_question_answer_id;
  } else if (type === 'Yes/No Questions') {
    return !!answer.go_eds_ref_question_answer_id;
  } else if (type === 'Date Picker') {
    return (
      !!answer.user_answer_date_picker_datetime_utc && dayjs(answer.user_answer_date_picker_datetime_utc).isValid()
    );
  }

  return false;
};

const MyExternalDataSharingRequestsCreateRequestQuestionAndAnswer: FC<MyEdsRequestCreateRequestProps> = ({
  tabIndex,
  setTabIndex,
  data,
  setData,
  questions,
}) => {
  const actionRef = useRef<number>(0);
  const attachmentRef = useRef<AddGovernanceOfficeEdsModel['answer_attachments']>([]);

  const toast = useToast();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const qaListDetails = useGetGoEdsRequestCreationQaListQuery();

  const FormSchema = Yup.object().shape({
    answers: Yup.array().of(
      Yup.object().test((a, { createError, parent, schema, options, resolve, path }) => {
        const answer = a as unknown as AddGovernanceOfficeEdsAnswerModel;
        const questionIndex = questions.findIndex(q => q.go_eds_ref_question_id === answer.go_eds_ref_question_id);
        const question = questions[questionIndex];
        if (!question) {
          return createError({
            message: 'Invalid Question',
            path: 'go_eds_ref_question_id',
          });
        }

        const errors: Yup.ValidationError[] = [];

        const type = question.question_type;
        const hasNoError = determineNoErros(type, answer);
        if (!hasNoError) {
          errors.push(
            new Yup.ValidationError(defaultErrorMessage, undefined, `answers.${questionIndex}.go_eds_ref_question_id`),
          );
        }

        const questionTmp = qaListDetails.data?.find(
          q => q.go_eds_ref_question_answer_id === answer.go_eds_ref_question_answer_id,
        );
        const hasErrorAttachment =
          questionTmp?.attachment_enabled_flag &&
          questionTmp?.attachment_required_flag &&
          !(attachmentRef.current.filter(a => a.go_eds_ref_question_id === answer.go_eds_ref_question_id)?.length ?? 0);
        if (hasErrorAttachment) {
          errors.push(
            new Yup.ValidationError('Attachment required', undefined, `answers.${questionIndex}.attachments`),
          );
        }

        return errors.length <= 0 ? true : createError({ message: () => errors });
      }),
    ),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddGovernanceOfficeEdsModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      if (actionRef.current) {
        setData(d => ({ ...d, ...values }));
        setTabIndex(i => i + actionRef.current);
      }
    },
  });

  return qaListDetails.isLoading || qaListDetails.isFetching ? (
    <>Loading...</>
  ) : (
    <VStack pt="4">
      {questions.map((question, i) => (
        <Box key={question.go_eds_ref_question_id} pb="4">
          <Text fontWeight="bold">{question.question_instruction_text}</Text>

          {(() => {
            const type = question.question_type;
            const answer = values.answers.find(a => a.go_eds_ref_question_id === question.go_eds_ref_question_id);
            const answerDate = answer?.user_answer_date_picker_datetime_utc
              ? dayjs(answer?.user_answer_date_picker_datetime_utc)
              : undefined;
            const qRadioList =
              qaListDetails.data?.filter(q => q.go_eds_ref_question_id === question.go_eds_ref_question_id) ?? [];
            const attachments = values.answer_attachments.filter(
              a => a.go_eds_ref_question_id === question.go_eds_ref_question_id,
            );
            const attachmentLimit = question.attachment_limit ?? 5;

            return (
              <>
                <FormControl
                  isInvalid={
                    !!errors.answers &&
                    !!(errors.answers[i] as any)?.go_eds_ref_question_id &&
                    !!touched.answers &&
                    !!touched.answers[i]?.go_eds_ref_question_id
                  }
                  h="100%"
                >
                  {type === 'Extended Open Text Box' ? (
                    <Textarea
                      id={'answers.' + i + '.go_eds_ref_question_id'}
                      name={'answers.' + i + '.go_eds_ref_question_id'}
                      value={answer?.user_answer_text_box}
                      onChange={e => {
                        const tmp = values.answers.map(r => structuredClone(r));
                        tmp[i].user_answer_text_box = e.target.value;
                        setValues({ ...values, answers: tmp });
                      }}
                    />
                  ) : type === 'Yes/No Questions' ? (
                    <RadioGroup
                      onChange={e => {
                        const tmp = values.answers.map(r => structuredClone(r));
                        tmp[i].go_eds_ref_question_answer_id = parseInt(e);
                        setValues({ ...values, answers: tmp });
                      }}
                      value={answer?.go_eds_ref_question_answer_id.toString()}
                    >
                      <Stack direction="row">
                        {qRadioList.map(q => (
                          <Radio
                            key={q.go_eds_ref_question_answer_id}
                            value={q.go_eds_ref_question_answer_id.toString()}
                            colorScheme="brand.main"
                          >
                            {q.answer_option}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  ) : type === 'Date Picker' ? (
                    <Box w="fit-content">
                      {' '}
                      <CustomDatePicker
                        id={'answers.' + i + '.go_eds_ref_question_id'}
                        name={'answers.' + i + '.go_eds_ref_question_id'}
                        date={answerDate?.toDate()}
                        onDateChange={(date: Date) => {
                          const tmp = values.answers.map(r => structuredClone(r));
                          tmp[i].user_answer_date_picker_datetime_utc = dayjs(date).format('YYYY-MM-DD');
                          setValues({ ...values, answers: tmp });
                        }}
                        minDate={startOfEpochDate}
                        maxDate={maxDate}
                        propsConfigs={{
                          ...customDatePickerDefaultPropsConfig,
                          inputProps: {
                            placeholder: 'YYYY-MM-DD',
                            size: 'sm',
                            bg: '#ffffff',
                            // rounded: 'md',
                            value: answer?.user_answer_date_picker_datetime_utc,
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                              const tmp = values.answers.map(r => structuredClone(r));
                              const d = dayjs(e.target.value);
                              if (d.isValid()) {
                                tmp[i].user_answer_date_picker_datetime_utc = dayjs(d).format('YYYY-MM-DD');
                                setValues({ ...values, answers: tmp });
                              } else {
                                tmp[i].user_answer_date_picker_datetime_utc = undefined;
                                setValues({ ...values, answers: tmp });
                              }
                            },
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <FormErrorMessage>
                    {!!errors.answers && (errors.answers[i] as any)?.go_eds_ref_question_id}
                  </FormErrorMessage>
                </FormControl>

                {question.attachment_enabled_flag && (
                  <FormControl
                    isInvalid={
                      !!errors.answers &&
                      !!(errors.answers[i] as any)?.attachments &&
                      !!touched.answers &&
                      !!touched.answers[i]?.go_eds_ref_question_id
                    }
                    h="100%"
                  >
                    <Box mt="2">
                      {/* <Textarea
                        id={'answers.' + i + '.attachment'}
                        name={'answers.' + i + '.attachment'}
                        value={answer?.user_answer_text_box}
                        onChange={e => {}}
                      /> */}
                      <StyledDropzone
                        maxFiles={attachmentLimit}
                        containerProps={{ style: { minHeight: '124px' } }}
                        isInvalid={
                          !!errors.answers &&
                          !!(errors.answers[i] as any)?.attachments &&
                          !!touched.answers &&
                          !!touched.answers[i]?.go_eds_ref_question_id
                        }
                        onChooseFile={files => {
                          if (files.length > 0) {
                            setValues(values => {
                              const tmp = values.answer_attachments.map(r => structuredClone(r));
                              let list = tmp.filter(a => a.go_eds_ref_question_id === question.go_eds_ref_question_id);
                              const excludedList = tmp.filter(
                                a => a.go_eds_ref_question_id !== question.go_eds_ref_question_id,
                              );

                              list.push(
                                ...files.map(file => ({
                                  go_eds_ref_question_id: question.go_eds_ref_question_id,
                                  file,
                                })),
                              );
                              console.log(list, excludedList);

                              if (list.length > attachmentLimit) {
                                list = list.slice(0, attachmentLimit);
                                toast({
                                  description: `File attachment limit reached (${attachmentLimit})`,
                                  status: 'info',
                                });
                              }

                              console.log(list, excludedList);

                              attachmentRef.current = [...excludedList, ...list];
                              return { ...values, answer_attachments: [...excludedList, ...list] };
                            });
                          }
                        }}
                        children={
                          (attachments?.length ?? 0) > 0 ? (
                            <HStack flexWrap="wrap" gap="2">
                              {attachments?.map((attachment, ii) => (
                                <HStack key={ii} border="1px" m="0 !important" borderRadius="md" pl="2">
                                  <FaFile />
                                  <Text>{attachment.file.name}</Text>
                                  <IconButton
                                    borderRadius="md"
                                    aria-label="remove file"
                                    icon={<CgClose />}
                                    onClick={e => {
                                      e.stopPropagation();

                                      setValues(values => {
                                        const tmp = values.answer_attachments.map(r => structuredClone(r));
                                        const excludedList = tmp.filter(
                                          a => a.go_eds_ref_question_id !== question.go_eds_ref_question_id,
                                        );

                                        attachments?.splice(ii, 1);

                                        attachmentRef.current = [...excludedList, ...attachments];
                                        return { ...values, answer_attachments: [...excludedList, ...attachments] };
                                      });
                                    }}
                                  />
                                </HStack>
                              ))}
                            </HStack>
                          ) : undefined
                        }
                      />
                    </Box>
                    <FormErrorMessage>{!!errors.answers && (errors.answers[i] as any)?.attachments}</FormErrorMessage>
                  </FormControl>
                )}
              </>
            );
          })()}
        </Box>
      ))}

      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {myEdsRequestCreateRequestTabs.length}
        </Text>
        <ButtonGroup>
          <Button
            size="sm"
            onClick={() => {
              actionRef.current = -1;
              submitForm();
            }}
          >
            Previous
          </Button>
          <Button
            size="sm"
            isDisabled={tabIndex === myEdsRequestCreateRequestTabs.length - 1}
            onClick={() => {
              actionRef.current = 1;
              submitForm();
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#585858',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  justifyContent: 'center',
};

const focusedStyle = {
  borderColor: appColors.brand.main.default,
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#E53E3E',
};

type StyledDropzoneProps = {
  isInvalid?: boolean;
  children?: ReactNode;
  onChooseFile: (acceptedFiles: File[]) => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  maxFiles: number;
};
const StyledDropzone: FC<StyledDropzoneProps> = ({ isInvalid, children, onChooseFile, containerProps, maxFiles }) => {
  const oneMegaByte = 1_048_576;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChooseFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    multiple: maxFiles > 1,
    maxFiles,
    maxSize: oneMegaByte * 25,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'image/*': ['.png', '.jpeg', '.jpg', '.gif'],
    },
    onDrop,
  });

  const style: React.CSSProperties = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject || isInvalid ? rejectStyle : {}),
      ...containerProps?.style,
    }),
    [isFocused, isDragAccept, isDragReject, isInvalid],
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {children ? (
          children
        ) : (
          <>
            <FiDownload size="40px" />
            <Text mt="2">
              <Text as="span" fontWeight="bold">
                Choose a file
              </Text>{' '}
              or drag it here
            </Text>
          </>
        )}
      </div>
    </div>
  );
};

export default MyExternalDataSharingRequestsCreateRequestQuestionAndAnswer;
