import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { GoEdsExploreModel } from '../../../app/services/dme/api/types';
import { SortType } from '../../../app/services/types';
import { CustomTableHeader } from '../../../app/types/appType';

export const ExploreDataSharingRequestsInitialSortBy: SortType<GoEdsExploreModel> = {
  id: 'go_eds_request_id',
  desc: false,
};

export const ExploreDataSharingRequestsHeader: CustomTableHeader<GoEdsExploreModel> = [
  {
    Header: 'Request #',
    accessor: 'go_eds_request_id',
    isSortable: false,
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<GoEdsExploreModel>) => {
      return (
        <Link as={RouterLink} color="links" to={`./selected/id/${original.go_eds_request_id}/details`}>
          {original.go_eds_request_id}
        </Link>
      );
    },
  },
  {
    Header: 'Subject',
    accessor: 'request_subject',
    isSortable: false,
    styles: {
      whiteSpace: 'initial',
      verticalAlign: 'top',
      width: '300px',
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  {
    Header: 'Created By',
    accessor: 'first_name',
    isSortable: false,
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<GoEdsExploreModel>) => {
      return <>{original.first_name + ' ' + original.last_name}</>;
    },
  },
  {
    Header: 'Status',
    accessor: 'request_status_name',
    isSortable: false,
    styles: { verticalAlign: 'top' },
  },
  {
    Header: 'Associated Users',
    accessor: 'num_of_associated_user',
    isSortable: false,
    styles: { verticalAlign: 'top' },
  },
  {
    Header: 'Created Date',
    accessor: 'row_created_datetime_utc',
    isSortable: false,
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<GoEdsExploreModel>) => {
      return <>{convertUtcToLocal(original.row_created_datetime_utc, 'YYYY-MM-D') || '-'}</>;
    },
  },
  {
    Header: 'Last Modified Date',
    accessor: 'row_modified_datetime_utc',
    isSortable: false,
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<GoEdsExploreModel>) => {
      return <>{convertUtcToLocal(original.row_modified_datetime_utc, 'YYYY-MM-D') || '-'}</>;
    },
  },
];

export const goEdsSearchParams = {
  status: 'status',
  user: 'user',
  startDate: 'startdate',
  endDate: 'enddate',
  sortBy: 'sortBy',
  sortOrder: 'sortOrder',
};
