import { Alert, VStack } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import PostItem from './PostItem';
import { ReportHubDetailsPageDiscussionModel } from '../../../../../../app/services/dme/api/types';

type Props = {
  postList: ReportHubDetailsPageDiscussionModel[];
  onClickRow: (user: ReportHubDetailsPageDiscussionModel | null) => void;
  selectedPost: ReportHubDetailsPageDiscussionModel | null;
};

const PostList: FC<Props> = ({ postList, selectedPost, onClickRow }) => {
  return (
    <VStack maxH="calc(100vh - 250px)" overflowY="auto" spacing={4}>
      {postList && postList.length == 0 && <Alert status="error">No Post(s)</Alert>}
      {postList.map((m: ReportHubDetailsPageDiscussionModel) => (
        <Fragment key={m.rh_post_id}>
          <PostItem rh_post_id={selectedPost?.rh_post_id} selectedPost={m} onClick={onClickRow} />
        </Fragment>
      ))}
    </VStack>
  );
};

export default PostList;
