import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { FC, useEffect, useState } from 'react';
import { BsChatDots } from 'react-icons/bs';
import { CgAttachment } from 'react-icons/cg';
import { MdOutlineThumbUpAlt } from 'react-icons/md';
import { AppAccess } from '../../../../../app/constants/appAccesses';
import { convertUtcToLocal } from '../../../../../app/helpers/dateHelper';
import { DataProductDetailsPageDiscussionModel } from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import AppAuth from '../../../../../features/AppAuth';
import PubNubConstants from '../../../../../features/PubNubWrapper/constants';
import DataProductPostAttachmentList from './Attachment/PostAttachmentList';
import DataProductPostDeleteButton from './Modals/DeletePostModal';
import DataProductPostUpdateButton from './Modals/UpdatePostModal';
import DataProductPostReactButton from './PostReactButton';
import { DefaultInnerHtmlStyle } from '../../../../../app/constants';

type Props = {
  dp_post_id?: number;
  selectedPost: DataProductDetailsPageDiscussionModel;
  onClick: (d: DataProductDetailsPageDiscussionModel) => void;
};

const PostItem: FC<Props> = ({ dp_post_id, selectedPost, onClick }) => {
  const pubnub = usePubNub();
  const { logonUser } = useAppSelector(s => s.user);
  const { signalEvent } = useAppSelector(s => s.pubNub);
  const attachmentModal = useDisclosure();
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const [channelName, setChannelName] = useState('');
  const [incrementCommentCount, setIncrementCommentCount] = useState(0);

  useEffect(() => {
    const _channelName = `dp_post_id_${selectedPost.dp_post_id}`;
    setChannelName(_channelName);
    pubnub.subscribe({
      channels: [_channelName],
    });

    return () => {
      pubnub.unsubscribe({
        channels: [_channelName],
      });
    };
  }, [selectedPost.dp_post_id]);

  useEffect(() => {
    if (signalEvent?.channel === channelName) {
      const { message } = signalEvent;
      if (message === PubNubConstants.SignalEvent.INCREMENT_COMMENT_COUNT) {
        setIncrementCommentCount(s => ++s);
      }
    }
  }, [signalEvent]);

  useEffect(() => {
    if (selectedPostId) {
      attachmentModal.onOpen();
    } else {
      attachmentModal.onClose();
    }
  }, [selectedPostId]);

  return (
    <>
      <Box
        py={3}
        id={selectedPost.dp_post_id.toString()}
        key={selectedPost.dp_post_id}
        h="100%"
        fontWeight="normal"
        border="1px"
        borderColor="gray.200"
        bg={dp_post_id === selectedPost.dp_post_id ? 'gray.200' : 'gray.50'}
        paddingX={4}
        paddingY={3}
        borderRadius={5}
        _hover={{ bg: 'gray.200' }}
      >
        <VStack w="full" alignItems="baseline" spacing={2}>
          <VStack w="full" alignItems="baseline" spacing={3} onClick={() => onClick(selectedPost)} cursor="pointer">
            <Heading
              title={selectedPost.post_subject}
              whiteSpace="normal"
              wordBreak="break-all"
              size="sm"
              noOfLines={1}
            >
              {selectedPost.post_subject}
            </Heading>
            <Box className='ql-editor' bg="white" textAlign="left" px={3} py={2} borderWidth={1} w="full" maxH="120px" overflowY="auto">
              <Text
                whiteSpace="initial"
                as="small"
                dangerouslySetInnerHTML={{ __html: selectedPost.post_message }}
                sx={DefaultInnerHtmlStyle}
              />
            </Box>
            <HStack justifyContent="space-between" w="full">
              <Text fontSize={14} fontWeight="medium">
                {selectedPost.post_created_user}
              </Text>
              <Text fontSize={12}>{convertUtcToLocal(selectedPost.post_created, 'MMM D, YYYY h:mm A')}</Text>
            </HStack>
          </VStack>
          <Divider borderColor="gray.300" />
          <HStack alignItems="center" w="full" justifyContent="space-between">
            <HStack>
              <Button
                leftIcon={<BsChatDots />}
                variant="link"
                size="xs"
                _hover={{ textDecoration: 'none' }}
                onClick={() => onClick(selectedPost)}
              >
                {selectedPost.cnt_comments + incrementCommentCount}
              </Button>
              <Button
                leftIcon={<CgAttachment />}
                variant="link"
                size="xs"
                _hover={{ textDecoration: 'none' }}
                sx={{ span: { marginRight: 1 } }}
                onClick={() => {
                  return selectedPost.cnt_post_attachments > 0 ? setSelectedPostId(selectedPost.dp_post_id) : null;
                }}
              >
                {selectedPost.cnt_post_attachments}
              </Button>
              {/* <Tag size="sm" variant="ghost" alignItems="center" display="flex" mr="96">
              <TagLeftIcon as={MdOutlineThumbUpAlt} />
              <TagLabel>{selectedPost.cnt_post_reactions}</TagLabel>
            </Tag> */}
              <DataProductPostReactButton post={selectedPost} />
            </HStack>

            {logonUser?.email === selectedPost.post_created_user && (
              <HStack>
                <DataProductPostUpdateButton post={selectedPost} />
                <DataProductPostDeleteButton post={selectedPost} />
              </HStack>
            )}
          </HStack>
        </VStack>
      </Box>

      {
        <Modal
          isOpen={attachmentModal.isOpen}
          onClose={attachmentModal.onClose}
          size="2xl"
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Attachments</ModalHeader>
            <ModalCloseButton onClick={() => setSelectedPostId(null)} />
            <ModalBody>{selectedPostId && <DataProductPostAttachmentList postId={selectedPostId} />}</ModalBody>

            <ModalFooter>
              <Button onClick={() => setSelectedPostId(null)} mr={3} ml="auto">
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }
    </>
  );
};

export default PostItem;
