import { Box, Button, ButtonGroup, Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../../app/helpers/dateHelper';
import { useGetRhPortfolioSelectedDetailsByRhpidQuery } from '../../../../../app/services/dme/api/reportHub';
import { useAppSelector } from '../../../../../app/state/hooks';

type Props = {};

const ReportHubExplorePortfoliosSelectedViewDetails: FC<Props> = props => {
  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const rhpId: number = parseInt(params?.rhpId || '0');
  const { data, isLoading, isFetching } = useGetRhPortfolioSelectedDetailsByRhpidQuery(
    { userId, rhpId },
    {
      skip: userId === 0 || rhpId === 0,
    },
  );

  return (
    <VStack spacing={5} p={1} pt={3}>
      {isLoading || isFetching ? (
        <>Loading...</>
      ) : (
        <>
          <Table size="sm">
            <Tbody
              sx={{
                '& tr > th': {
                  maxW: '110px',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Tr>
                <Th>Domain</Th>
                <Td>{data?.domain}</Td>
                <Td>&nbsp;</Td>
                <Th>Developer</Th>
                <Td>{data?.developer_first_name + ' ' + data?.developer_last_name}</Td>
              </Tr>
              <Tr>
                <Th>Date Created</Th>
                <Td>{convertUtcToLocal(data?.row_created_datetime_utc, 'MMMM D, YYYY h:mm A')}</Td>
                <Td>&nbsp;</Td>
                <Th>Last Date Modified</Th>
                <Td>{convertUtcToLocal(data?.last_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}</Td>
              </Tr>
              <Tr>
                <Th>PHI</Th>
                <Td>{data?.phi_present_flag ? 'Yes' : 'No'}</Td>
                <Td>&nbsp;</Td>
                <Th>PII</Th>
                <Td>{data?.pii_present_flag ? 'Yes' : 'No'}</Td>
              </Tr>
              <Tr>
                <Th>Description</Th>
                <Td colSpan={4}>{data?.report_portfolio_desc}</Td>
              </Tr>
            </Tbody>
          </Table>

          {data?.has_access_flag && data.report_portfolio_link && (
            <Box w="100%" textAlign="right">
              <ButtonGroup>
                <Button
                  colorScheme="brand.main"
                  as={RouterLink}
                  to={data.report_portfolio_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Portfolio
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </>
      )}
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedViewDetails;
