import { HStack, IconButton, Link } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { HiOutlineBookmark } from 'react-icons/hi';
import { HiBookmark } from 'react-icons/hi2';
import { MdInventory2, MdOutlineInventory2 } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Row } from 'react-table';
import { usePostInfoweaveStatusMutation } from '../../../app/services/dme/api/infoweave';
import {
  InfoweaveData,
  InfoweaveEvent,
  InfoweaveFilterTabName,
  InfoweaveParams,
  colorBlue,
  useInfoweaveContext,
} from '../InfoweaveContext';

const ActionsCell = ({ row: { original } }: { row: Row<InfoweaveData> }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [postAsync, postDetails] = usePostInfoweaveStatusMutation();
  const { setInfoweaveForModal } = useInfoweaveContext();

  const tabParam = useMemo(() => searchParams.get(InfoweaveParams.tab) as InfoweaveFilterTabName, [searchParams]);

  const handleOnclickBookmark = () => {
    postAsync({
      dme_infoweave_ids: [original.dme_infoweave_id],
      dme_infoweave_ref_event_type_id: original.is_bookmarked ? InfoweaveEvent.unbookmark : InfoweaveEvent.bookmark,
    });
  };

  const handleOnclickArchive = () => {
    postAsync({
      dme_infoweave_ids: [original.dme_infoweave_id],
      dme_infoweave_ref_event_type_id: original.is_archived ? InfoweaveEvent.unarchive : InfoweaveEvent.archive,
    });
  };

  const handleOnclickLink = () => {
    postAsync({
      dme_infoweave_ids: [original.dme_infoweave_id],
      dme_infoweave_ref_event_type_id: InfoweaveEvent.read,
    });
    if (original.message_announcement) {
      setInfoweaveForModal(original);
    } else {
      navigate(original.link);
      window.scrollTo(0, 0);
    }
  };

  const postedEventId = postDetails.originalArgs?.dme_infoweave_ref_event_type_id;

  return (
    <>
      <HStack gap="1">
        <IconButton
          key={original.dme_infoweave_id}
          size="lg"
          color={colorBlue}
          icon={original.is_bookmarked ? <HiBookmark /> : <HiOutlineBookmark />}
          title="Bookmark"
          aria-label="Bookmark"
          variant="link"
          minW="1"
          isDisabled={postDetails.isLoading}
          isLoading={
            (postedEventId === InfoweaveEvent.bookmark || postedEventId === InfoweaveEvent.unbookmark) &&
            postDetails.isLoading
          }
          onClick={handleOnclickBookmark}
        />
        <IconButton
          size="lg"
          color={colorBlue}
          icon={original.is_archived ? <MdInventory2 /> : <MdOutlineInventory2 />}
          aria-label="Archive"
          title="Archive"
          variant="link"
          minW="1"
          isDisabled={postDetails.isLoading}
          isLoading={
            (postedEventId === InfoweaveEvent.archive || postedEventId === InfoweaveEvent.unarchive) &&
            postDetails.isLoading
          }
          onClick={handleOnclickArchive}
        />
        {original.message_announcement ? (
          <IconButton
            size="lg"
            color={colorBlue}
            icon={<BiLinkExternal />}
            aria-label="Go to link"
            title={'Go to link'}
            variant="link"
            minW="1"
            isDisabled={postDetails.isLoading}
            isLoading={postedEventId === InfoweaveEvent.read && postDetails.isLoading}
            onClick={handleOnclickLink}
          />
        ) : (
          <Link
            color={colorBlue}
            href={original.link}
            onClick={() => {
              postAsync({
                dme_infoweave_ids: [original.dme_infoweave_id],
                dme_infoweave_ref_event_type_id: InfoweaveEvent.read,
              });
            }}
          >
            <BiLinkExternal size="18px" />
          </Link>
        )}
      </HStack>
    </>
  );
};

export default ActionsCell;
