import { Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetRhMyAccessSelectedDetailsByIdQuery } from '../../../../app/services/dme/api/reportHub';
import { useAppSelector } from '../../../../app/state/hooks';

const ReportHubMyAccessSelectedDetails = () => {
  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const requirestId: number = parseInt(params?.requirestId || '0') || 0;
  const { data, isLoading, isFetching } = useGetRhMyAccessSelectedDetailsByIdQuery(
    { ref_user_id: logonUser?.ref_user_id ?? 0, access_method: params.accessMethod ?? '', request_id: requirestId },
    {
      skip: requirestId === 0 || !params.accessMethod || !logonUser?.ref_user_id,
    },
  );

  return (
    <>
      <VStack spacing={5} p={1} pt={3}>
        {isLoading || isFetching ? (
          <>Loading...</>
        ) : (
          <VStack pt={3}>
            <Table>
              <Tbody>
                <Tr>
                  <Th>Method</Th>
                  <Td>{data?.access_method}</Td>
                  <Td>&nbsp;</Td>
                  <Th>Request ID</Th>
                  <Td>{data?.request_id}</Td>
                </Tr>

                <Tr>
                  <Th>Role Name</Th>
                  <Td>{data?.role_name ?? '-'}</Td>
                  <Td>&nbsp;</Td>
                  <Th>Requestor</Th>
                  <Td>{data?.requestor}</Td>
                </Tr>

                <Tr>
                  <Th>Domain</Th>
                  <Td>{data?.domain}</Td>
                  <Td>&nbsp;</Td>
                  <Th>Current Status</Th>
                  <Td>{data?.current_status}</Td>
                </Tr>

                <Tr>
                  <Th>Access Business Justification</Th>
                  <Td>{data?.access_request_business_justification}</Td>
                  <Td>&nbsp;</Td>
                  <Th>Denied Explanation</Th>
                  <Td>{data?.access_denied_notes ?? '-'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </VStack>
        )}
      </VStack>
    </>
  );
};

export default ReportHubMyAccessSelectedDetails;
