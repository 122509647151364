import {
  VStack,
  Heading,
  HStack,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Flex,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdInfo } from 'react-icons/md';
import { Row } from 'react-table';
import { ValueOf } from '../../../../app/helpers/utilities';
import { useGetRefTagListQuery } from '../../../../app/services/dme/api/refTags';
import { DataProductSelectPageTagsModel } from '../../../../app/services/dme/api/types';
import { EditDataProductModel } from '../../../../app/services/types';
import EditableCell from '../../../../components/CustomTable/EditableCell';

type Props = {
  initialValues: EditDataProductModel;
  data: EditDataProductModel;
  onChangeData: (field: keyof EditDataProductModel, data: ValueOf<EditDataProductModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const DataProductSelectedTags = ({ data, onChangeData, errors, touched, title, tabErrorIndex }: Props) => {
  const { data: tagsData, isLoading: isLoadingTags, isFetching: isFetchingTags } = useGetRefTagListQuery();

  const generateIdForAddedItem = (): number => {
    return data.tagModel && data.tagModel.some(f => f.dp_tag_data_product_id < 1)
      ? Math.min.apply(
          Math,
          data.tagModel.map(m => m.dp_tag_data_product_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow = {
      dp_tag_data_product_id: generateIdForAddedItem(),
      dp_data_product_id: data.data_product_id,
      tag_name: '',
      tag_desc: '',
      notes: '',
      row_created_user_id: 0,
      row_created_datetime_utc: '',
      first_name: '',
      last_name: '',
      is_deleted_flag: false,
      ref_tag_id: 0,
    };
    onChangeData('tagModel', data.tagModel ? [...data.tagModel, newRow] : [newRow]);
  };

  const onUpdateData = (rowIndex: number, columnId: string, value: string | File | number | number[]) => {
    const tagDesc = tagsData ? tagsData.find(f => f.ref_tag_id === value)?.tag_desc || '' : '';
    data.tagModel &&
      onChangeData(
        'tagModel',
        (data.tagModel &&
          data.tagModel.map((row, index) => {
            if (index === rowIndex && data.tagModel) {
              return {
                ...data.tagModel[rowIndex],
                [columnId]: value,
                tag_desc: columnId === 'ref_tag_id' ? tagDesc : data.tagModel[rowIndex].tag_desc,
              };
            }
            return row;
          })) ||
          [],
      );
  };

  const onRemove = (item: DataProductSelectPageTagsModel) => {
    data.tagModel &&
      onChangeData(
        'tagModel',
        data.tagModel.filter(f => f.dp_tag_data_product_id != item.dp_tag_data_product_id),
      );
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">{title}</Heading>
        <Button
          size="sm"
          leftIcon={<FiRefreshCcw />}
          onClick={() => {
            data.tagModel && onChangeData('tagModel', data.tagModel, true);
          }}
        >
          Refresh
        </Button>
      </HStack>
      {isLoadingTags || isFetchingTags ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data.tagModel ? null : (
      <VStack>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>
                <Flex>
                  Name
                  <Tooltip label="Tag Name must be unique">
                    <IconButton
                      minW={6}
                      variant="link"
                      aria-label="Info"
                      icon={<MdInfo />}
                      color={tabErrorIndex === 4 ? 'brand.error' : 'brand.main.default'}
                    />
                  </Tooltip>
                </Flex>
              </Th>
              <Th>Description</Th>
              <Th>Notes</Th>
              <Th>Action</Th>
            </Tr>
            {(data.tagModel?.filter(f => !f.is_deleted_flag) || []).map((d, index) => (
              <Tr key={d.dp_tag_data_product_id}>
                <Td>
                  {tagsData &&
                    (d.dp_tag_data_product_id > 0 ? (
                      <>{d.tag_name}</>
                    ) : (
                      <EditableCell
                        name={`tagModel.${index}.ref_tag_id`}
                        value={d.ref_tag_id}
                        row={{ index: index } as Row}
                        column={{ id: 'ref_tag_id' } as any}
                        updateData={onUpdateData}
                        type="dropdown"
                        dropdownOptions={tagsData.map(m => {
                          return {
                            text: m.tag_name,
                            value: m.ref_tag_id.toString(),
                            isDisabled: data.tagModel?.some(
                              s => s.ref_tag_id === m.ref_tag_id && s.ref_tag_id !== d.ref_tag_id,
                            ),
                          };
                        })}
                        isInvalid={
                          !!(
                            errors.tagModel &&
                            (errors.tagModel as unknown as DataProductSelectPageTagsModel[])[index]?.ref_tag_id
                          ) &&
                          !!(
                            touched.tagModel &&
                            (touched.tagModel as unknown as DataProductSelectPageTagsModel[])[index]?.ref_tag_id
                          )
                        }
                      />
                    ))}
                </Td>
                <Td>{d.tag_desc}</Td>
                <Td>
                  {
                    <EditableCell
                      value={d.notes}
                      row={{ index: index } as Row}
                      column={{ id: 'notes' } as any}
                      updateData={onUpdateData}
                    />
                  }
                </Td>
                <Td textAlign="center">
                  {
                    <ButtonGroup spacing={1}>
                      <Tooltip label="Delete">
                        <IconButton
                          color="brand.error"
                          variant="link"
                          aria-label="Delete"
                          icon={<FaTrash />}
                          onClick={() => onRemove(d)}
                          minWidth={1}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
            Create New
          </Button>
        </Box>
      </VStack>
    );
  }
};

export default DataProductSelectedTags;
