import { dmeApi } from '.';
import { GetRefRequestStatusModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    // SP: p_ref_request_status
    getRefRequestStatus: builder.query<GetRefRequestStatusModel[], void>({
      query: () => '/refrequeststatus/list',
      providesTags: ['CustomRequestByStatusId', 'CustomRequest'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetRefRequestStatusQuery } = extendedApi;
