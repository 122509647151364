import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { FaUser, FaUserCog } from 'react-icons/fa';
import * as Yup from 'yup';
import { buildSchema } from '../../app/helpers/formSchemabuilder';
import {
  useAddRhRoleRequestAccessMutation,
  useAddRhUserRequestAccessMutation,
  useGetRhAccessListQuery,
} from '../../app/services/dme/api/reportHubAccess';
import {
  ReportHubExploreModel,
  ReportHubSelectedDetailsModel,
  ReportHubSelectedModel,
} from '../../app/services/dme/api/types';
import { useAppSelector } from '../../app/state/hooks';
import CustomDatePicker from '../../components/CustomDatePicker';
import RequestQuestionItem from '../Roles/RequestAccess/RequestQuestionItem';

type Props = {
  triggerElement: JSX.Element;
  portfolio?: ReportHubExploreModel | ReportHubSelectedModel | ReportHubSelectedDetailsModel;
};

const minDate = dayjs().toDate();
const maxDate = dayjs().add(1, 'year').toDate();
const defaultDate = dayjs().add(30, 'day').toDate();

const FormSchema = Yup.object().shape({
  ref_role_or_user_id: Yup.object()
    .shape({
      access_type: Yup.string().required(),
      ref_id: buildSchema({ label: 'User or Role', type: 'id' }),
    })
    .required(),
  access_expiration_datetime_utc: Yup.string()
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isValid()
        ? true
        : createError({
            message: 'Expiration Date in invalid',
            path: 'access_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isAfter(minDate)
        ? true
        : createError({
            message: 'Expiration Date must be later than today',
            path: 'access_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isBefore(maxDate)
        ? true
        : createError({
            message: 'Expiration Date may not exceed one year from current date',
            path: 'access_expiration_datetime_utc',
          });
    })
    .label('Expiration Date')
    .required(),
  access_request_business_justification: Yup.string()
    .label('Business Justification')
    .required()
    .max(1500, 'Text exceed the character limit of 1500'),
});

const ReportHubRequestAccess: FC<Props> = ({ portfolio, triggerElement }) => {
  const [expirationDate, setExpirationDate] = useState<Date>(defaultDate);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const { logonUser } = useAppSelector(s => s.user);

  const accessListDetails = useGetRhAccessListQuery({ email: logonUser?.email ?? '' }, { skip: !logonUser?.email });
  const [addRoleRequest, addRoleRequestDetails] = useAddRhRoleRequestAccessMutation();
  const [addUserRequest, addUserRequestDetails] = useAddRhUserRequestAccessMutation();

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_role_or_user_id: {
        access_type: '' as 'user' | 'role' | '',
        ref_id: 0,
      },
      access_expiration_datetime_utc: dayjs(defaultDate).format('YYYY-MM-DD'),
      access_request_business_justification: '',
    },
    onSubmit: async (values, form) => {
      try {
        if (!portfolio) return;
        if (values.ref_role_or_user_id.access_type === 'role') {
          await addRoleRequest({
            rh_report_portfolio_id: portfolio.rh_report_portfolio_id,
            ref_role_id: values.ref_role_or_user_id.ref_id,
            access_expiration_datetime_utc: values.access_expiration_datetime_utc,
            access_request_business_justification: values.access_request_business_justification,
          }).unwrap();
        } else {
          await addUserRequest({
            rh_report_portfolio_id: portfolio.rh_report_portfolio_id,
            ref_user_id: values.ref_role_or_user_id.ref_id,
            access_expiration_datetime_utc: values.access_expiration_datetime_utc,
            access_request_business_justification: values.access_request_business_justification,
          }).unwrap();
        }

        toast({ description: 'Successfully requested access', status: 'success' });
        onClose();
        form.resetForm();
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (isOpen) {
      setExpirationDate(defaultDate);
      resetForm();
    }
  }, [isOpen]);

  const isLoading = addRoleRequestDetails.isLoading || addUserRequestDetails.isLoading;

  return (
    <>
      <Box onClick={onOpen}>{triggerElement}</Box>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false} isCentered>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader fontSize="medium" mr="3">
            Request Access - {portfolio?.report_portfolio_name}
          </ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody>
            <List>
              <ListItem>
                <RequestQuestionItem
                  index={1}
                  label="Choose access for a role or your account"
                  isComplete={values.ref_role_or_user_id.ref_id > 0}
                >
                  <FormControl isInvalid={!!errors.ref_role_or_user_id?.ref_id && touched.ref_role_or_user_id?.ref_id}>
                    <Select
                      id="ref_role_or_user_id"
                      name="ref_role_or_user_id"
                      onChange={e => {
                        e?.value &&
                          setValues(v => ({
                            ...v,
                            ref_role_or_user_id: e?.value,
                          }));
                      }}
                      useBasicStyles
                      size="sm"
                      placeholder={accessListDetails.isLoading || accessListDetails.isFetching ? 'Loading...' : ''}
                      isDisabled={accessListDetails.isLoading || accessListDetails.isFetching}
                      components={{
                        Option: ({ children, ...props }: any) => (
                          <chakraComponents.Option {...props}>
                            {props.data.icon} {children}
                          </chakraComponents.Option>
                        ),
                        SingleValue: ({ children, ...props }: any) => (
                          <chakraComponents.SingleValue {...props}>
                            <Flex align="center">
                              {props.data.icon} {children}
                            </Flex>
                          </chakraComponents.SingleValue>
                        ),
                      }}
                      value={(() => {
                        const val = accessListDetails.data?.find(
                          m => m.ref_role_id === values.ref_role_or_user_id.ref_id,
                        );
                        return val
                          ? {
                              label: val.role_name,
                              icon: <Icon as={val.access_type === 'user' ? FaUser : FaUserCog} mr={2} />,
                              value: {
                                access_type: val.access_type,
                                ref_id: val.ref_role_id,
                              },
                            }
                          : null;
                      })()}
                      options={accessListDetails.data?.map(m => {
                        return {
                          label: m.role_name,
                          icon: <Icon as={m.access_type === 'user' ? FaUser : FaUserCog} mr={2} />,
                          value: {
                            access_type: m.access_type,
                            ref_id: m.ref_role_id,
                          },
                        };
                      })}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: provided => ({
                          ...provided,
                          zIndex: 1401,
                        }),
                      }}
                      maxMenuHeight={500}
                    />
                    <FormErrorMessage>{errors.ref_role_or_user_id?.ref_id}</FormErrorMessage>
                  </FormControl>
                </RequestQuestionItem>
              </ListItem>
              <ListItem>
                <RequestQuestionItem
                  index={3}
                  label={
                    <HStack align="center" spacing={1}>
                      <Text>Choose Expiration</Text>
                      <Text fontWeight="normal" as="small">
                        (YYYY-MM-DD)
                      </Text>
                    </HStack>
                  }
                  isComplete={
                    !errors.access_expiration_datetime_utc && values.access_expiration_datetime_utc.length > 0
                  }
                >
                  <FormControl isInvalid={!!errors.access_expiration_datetime_utc}>
                    <CustomDatePicker
                      id="access_expiration_datetime_utc"
                      name="access_expiration_datetime_utc"
                      date={expirationDate}
                      onDateChange={(date: Date) => {
                        setValues({
                          ...values,
                          access_expiration_datetime_utc: dayjs(date).format('YYYY-MM-DD'),
                        });
                        setExpirationDate(date);
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      propsConfigs={{
                        dateNavBtnProps: {
                          colorScheme: 'brand.main.default',
                          variant: 'outline',
                        },
                        dayOfMonthBtnProps: {
                          defaultBtnProps: {
                            _hover: {
                              background: 'brand.main.default',
                              color: 'white',
                            },
                          },
                          selectedBtnProps: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                          todayBtnProps: {
                            background: 'gray.400',
                          },
                        },
                        inputProps: {
                          placeholder: 'YYYY-MM-DD',
                          size: 'sm',
                          value: values.access_expiration_datetime_utc,
                          onChange: (e: ChangeEvent<HTMLInputElement>) => {
                            setValues({
                              ...values,
                              access_expiration_datetime_utc: e.target.value,
                            });
                            if (dayjs(e.target.value)?.isValid()) {
                              setExpirationDate(dayjs(e.target.value).toDate());
                            }
                          },
                        },
                      }}
                    />
                    <FormErrorMessage>{errors.access_expiration_datetime_utc}</FormErrorMessage>
                  </FormControl>
                </RequestQuestionItem>
              </ListItem>
              <ListItem>
                <RequestQuestionItem
                  hasNoLine
                  index={4}
                  label="Fill in business justification"
                  isComplete={values.access_request_business_justification.length > 0}
                >
                  <FormControl
                    isInvalid={
                      !!errors.access_request_business_justification && touched.access_request_business_justification
                    }
                  >
                    <Textarea
                      id="access_request_business_justification"
                      placeholder="Enter Message"
                      name="access_request_business_justification"
                      onChange={e => {
                        handleChange(e);
                      }}
                      value={values.access_request_business_justification}
                      maxLength={1500}
                    />
                    <FormErrorMessage>{errors.access_request_business_justification}</FormErrorMessage>
                  </FormControl>
                </RequestQuestionItem>
              </ListItem>
            </List>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button
              onClick={onClose}
              ml="auto"
              variant="outline"
              colorScheme="brand.main"
              size="sm"
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button size="sm" colorScheme="brand.main" isLoading={isLoading} onClick={() => handleSubmit()}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportHubRequestAccess;
