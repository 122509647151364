import { dmeApi } from './index';
import {
  LorelanguageCommentAttachmentModel,
  LoreLanguageDetailsDiscussionTabCommentModel,
  ToggleLoreLanguageCommentReactionModel,
  UpdateLoreLanguageCommentModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getLoreLanguageCommentDetail: builder.query<LoreLanguageDetailsDiscussionTabCommentModel, number>({
      query: ll_comment_id => `/lorelanguagecomment/ll_comment_id/${ll_comment_id}`,
    }),

    getLoreLanguageCommentAttachmentsById: builder.query<Array<LorelanguageCommentAttachmentModel>, number>({
      query: ll_comment_id => `/lorelanguagecomment/attachment/${ll_comment_id}`,
      providesTags: ['LoreLanguageCommentAttachments'],
    }),

    postLoreLanguageComment: builder.mutation<{ ll_comment_id: number }, FormData>({
      query: params => ({
        url: '/lorelanguagecomment',
        method: 'POST',
        body: params,
      }),
    }),

    postLoreLanguageCommentToggleReaction: builder.mutation<void, ToggleLoreLanguageCommentReactionModel>({
      query: params => ({
        url: '/lorelanguagecomment/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateLoreLanguageComment: builder.mutation<void, UpdateLoreLanguageCommentModel>({
      query: params => ({
        url: '/lorelanguagecomment',
        method: 'PUT',
        body: params,
      }),
    }),

    deleteLoreLanguageComment: builder.mutation<void, number>({
      query: ll_comment_id => ({
        url: `/lorelanguagecomment?${new URLSearchParams({ ll_comment_id: ll_comment_id.toString() }).toString()}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLoreLanguageCommentAttachmentsByIdQuery,
  useLazyGetLoreLanguageCommentDetailQuery,
  usePostLoreLanguageCommentMutation,
  usePostLoreLanguageCommentToggleReactionMutation,
  useUpdateLoreLanguageCommentMutation,
  useDeleteLoreLanguageCommentMutation,
} = extendedApi;
