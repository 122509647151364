import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useGetDataProductSelectRolesByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';
import { DataProductSelectPageRolesModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

const DataProductSelectedRoles = () => {
  const params = useParams();
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    setProductId(+(params?.dataProductId || 0));
  }, [params]);

  const { data, isLoading } = useGetDataProductSelectRolesByProductIdQuery(productId, {
    skip: !productId,
  });

  const Header = [
    {
      Header: 'Role Name',
      accessor: 'role_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Business Justification',
      accessor: 'access_request_business_justification',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Expiration Date',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPageRolesModel } }) => {
        return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
      },
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Requested Date',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPageRolesModel } }) => {
        return <>{convertUtcToLocal(original.access_requested_datetime_utc) || '-'}</>;
      },
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Requestor',
      accessor: 'requestor',
      styles: { whiteSpace: 'initial' },
    },
  ];
  return (
    <VStack spacing={5} p={1} pt={3} w="100%">
      <Heading size="sm">Roles</Heading>
      {isLoading ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={isLoading}
          isFetching={isLoading}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
        />
      </HStack>
    );
  }
};

export default DataProductSelectedRoles;
