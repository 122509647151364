import { Box } from '@chakra-ui/react';
import { useGetFocusTabCountQuery } from '../../../app/services/dme/api/infoweave';
import { useAppSelector } from '../../../app/state/hooks';
import { appColors } from '../../../app/theme';

const FocusCountBadge = () => {
  const { logonUser } = useAppSelector(s => s.user);

  const focusTabCountDetails = useGetFocusTabCountQuery(logonUser?.ref_user_id ?? -1, {
    skip: (logonUser?.ref_user_id ?? -1) <= 0,
  });

  const num = focusTabCountDetails.data ?? 0;

  if (num <= 0) return null;

  return (
    <Box
      ml="2"
      bg={appColors.brand.error}
      h="4"
      minW="4"
      px="1"
      fontSize="xs"
      rounded="full"
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontWeight="semibold"
    >
      {num}
    </Box>
  );
};

export default FocusCountBadge;
