import { dmeApi } from './index';
import {
  PostRolesAppAccessRoleDto,
  PutRolesAppAccessRoleByRoleIdDto,
  RolesAppAccessListDto,
  RolesAppAccessListModel,
  RolesAppAccessRefAppAccessModel,
  RolesAppAccessRefDevelopmentEnvironmentModel,
  RolesAppAccessSelectedAppAccessModel,
  RolesAppAccessSelectedDataProductModel,
  RolesAppAccessSelectedDetailsModel,
  RolesAppAccessSelectedDevelopmentEnvironmentModel,
  RolesAppAccessSelectedPortfolioModel,
  RolesAppAccessSelectedUsersModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: build => ({
    // SP: p_roles_app_access_by_roles_list
    getRolesAppAccessList: build.query<RolesAppAccessListModel, RolesAppAccessListDto>({
      query: params => ({
        url: `/rolesappaccess/list`,
        params,
      }),
      providesTags: ['RolesAppAccessList'],
    }),

    getRolesAppAccessSelectedDetails: build.query<RolesAppAccessSelectedDetailsModel, number>({
      query: roleId => `/rolesappaccess/selected/details/${roleId}`,
      transformResponse: (response: RolesAppAccessSelectedDetailsModel[]) => {
        return response[0];
      },
    }),

    getRolesAppAccessSelectedAppAccess: build.query<RolesAppAccessSelectedAppAccessModel[], number>({
      query: roleId => `/rolesappaccess/selected/app_access/${roleId}`,
    }),

    getRolesAppAccessSelectedDataProduct: build.query<RolesAppAccessSelectedDataProductModel[], number>({
      query: roleId => `/rolesappaccess/selected/data_product/${roleId}`,
    }),

    getRolesAppAccessSelectedPortfolio: build.query<RolesAppAccessSelectedPortfolioModel[], number>({
      query: roleId => `/rolesappaccess/selected/portfolio/${roleId}`,
    }),

    getRolesAppAccessSelectedDevelopmentEnvironment: build.query<
      RolesAppAccessSelectedDevelopmentEnvironmentModel[],
      number
    >({
      query: roleId => `/rolesappaccess/selected/development_environment/${roleId}`,
    }),

    getRolesAppAccessSelectedUsers: build.query<RolesAppAccessSelectedUsersModel[], number>({
      query: roleId => `/rolesappaccess/selected/users/${roleId}`,
    }),

    getRolesAppAccessRefAppAccessList: build.query<RolesAppAccessRefAppAccessModel[], void>({
      query: () => '/rolesappaccess/ref_app_access/list',
    }),

    getRolesAppAccessRefDevelopmentEnvironmentList: build.query<RolesAppAccessRefDevelopmentEnvironmentModel[], void>({
      query: () => '/rolesappaccess/ref_development_environment/list',
      providesTags: ['RolesAppAccessDevEnv'],
    }),

    postRolesAppAccessRole: build.mutation<boolean, PostRolesAppAccessRoleDto>({
      query: body => ({
        url: `/rolesappaccess`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RolesAppAccessList'],
    }),

    putRolesAppAccessRoleByRoleId: build.mutation<boolean, PutRolesAppAccessRoleByRoleIdDto>({
      query: body => ({
        url: `/rolesappaccess`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['RolesAppAccessList'],
    }),

    deleteRolesAppAccessRoleByRoleId: build.mutation<boolean, number>({
      query: roleId => ({
        url: `/rolesappaccess/role_id/${roleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RolesAppAccessList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRolesAppAccessListQuery,
  useGetRolesAppAccessSelectedDetailsQuery,
  useGetRolesAppAccessSelectedAppAccessQuery,
  useGetRolesAppAccessSelectedDataProductQuery,
  useGetRolesAppAccessSelectedPortfolioQuery,
  useGetRolesAppAccessSelectedDevelopmentEnvironmentQuery,
  useGetRolesAppAccessSelectedUsersQuery,
  useGetRolesAppAccessRefAppAccessListQuery,
  useGetRolesAppAccessRefDevelopmentEnvironmentListQuery,
  useLazyGetRolesAppAccessListQuery,
  useLazyGetRolesAppAccessSelectedDetailsQuery,
  useLazyGetRolesAppAccessSelectedAppAccessQuery,
  useLazyGetRolesAppAccessSelectedDataProductQuery,
  useLazyGetRolesAppAccessSelectedPortfolioQuery,
  useLazyGetRolesAppAccessSelectedDevelopmentEnvironmentQuery,
  useLazyGetRolesAppAccessSelectedUsersQuery,
  useLazyGetRolesAppAccessRefAppAccessListQuery,
  useLazyGetRolesAppAccessRefDevelopmentEnvironmentListQuery,
  usePostRolesAppAccessRoleMutation,
  usePutRolesAppAccessRoleByRoleIdMutation,
  useDeleteRolesAppAccessRoleByRoleIdMutation,
} = extendedApi;
