import { useEffect, useState } from 'react';
export const useDebounce = <T = any>(value: T, timeout = 500) => {
  const [state, setState] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);
    return () => clearTimeout(handler);
  }, [value, timeout]);
  return state;
};

export default useDebounce;
