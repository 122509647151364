import {
  LoreLanguageDetailsPageDetailModel,
  LoreLanguageDetailsPageDomainModel,
  LoreLanguageDetailsPageTagModel,
} from '../../../app/services/dme/api/types';

export const initialValues = {
  detailModel: {
    tab: '',
    ll_term: '',
    ll_definition: '',
    ll_application_context: '',
    last_modified: '',
    editor: '',
    created: '',
  } as LoreLanguageDetailsPageDetailModel,
  domainModel: [] as Array<LoreLanguageDetailsPageDomainModel>,
  tagModel: [] as Array<LoreLanguageDetailsPageTagModel>,
};
