import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FormikErrors, FormikTouched } from 'formik';
import { FC, Fragment } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useGetRefTagListQuery } from '../../../../../app/services/dme/api/refTags';
import { useGetRhPortfolioSelectedTagsByRhpidQuery } from '../../../../../app/services/dme/api/reportHub';
import { UpdateReportHubPortfolioModel } from '../../../../../app/services/dme/api/types';
import { EditComponentProps, tagFields } from '../../../helpers';
import { useAppSelector } from '../../../../../app/state/hooks';

const ReportHubExplorePortfoliosSelectedEditTags: FC<EditComponentProps> = ({ form }) => {
  const {
    handleSubmit,
    errors: rootError,
    touched: rootTouched,
    handleChange,
    values: rootValues,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = form;
  const errors = (rootError?.tags ?? []) as FormikErrors<UpdateReportHubPortfolioModel['tags']>;
  const touched = (rootTouched?.tags ?? []) as FormikTouched<UpdateReportHubPortfolioModel['tags']>;
  const values = rootValues.tags;

  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const rhpId: number = parseInt(params?.rhpId || '0') || 0;
  const { isLoading, isFetching } = useGetRhPortfolioSelectedTagsByRhpidQuery({ userId, rhpId }, { skip: rhpId === 0 });
  const tagsDetails = useGetRefTagListQuery();

  return isLoading || isFetching ? (
    <>Loading...</>
  ) : (
    <VStack>
      {values.length > 0 && (
        <TableContainer>
          <Table
            variant="simple"
            sx={{
              td: {
                verticalAlign: 'top',
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>{tagFields.ref_tag_id.label}</Th>
                <Th>Description</Th>
                <Th>{tagFields.notes.label}</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {values.map((r, i) => {
                return (
                  <Fragment key={i}>
                    <Tr>
                      <Td maxW="300px" w="300px">
                        <FormControl isInvalid={!!errors[i]?.ref_tag_id && !!touched[i]?.ref_tag_id} display="flex">
                          <Box w="100%">
                            <Select
                              size="sm"
                              id="ref_tag_id"
                              name="ref_tag_id"
                              placeholder={tagsDetails.isLoading || tagsDetails.isFetching ? 'Loading...' : ''}
                              isDisabled={tagsDetails.isLoading || tagsDetails.isFetching}
                              useBasicStyles
                              menuPortalTarget={document.body}
                              value={(() => {
                                const val = tagsDetails.data?.find(m => m.ref_tag_id === values[i].ref_tag_id);
                                return val
                                  ? {
                                      label: val.tag_name,
                                      value: val.ref_tag_id,
                                    }
                                  : undefined;
                              })()}
                              options={[
                                ...(
                                  tagsDetails.data?.filter(t => !values.some(v => v.ref_tag_id === t.ref_tag_id)) ?? []
                                ).map(m => {
                                  return {
                                    label: m.tag_name,
                                    value: m.ref_tag_id,
                                  };
                                }),
                              ]}
                              onChange={e => {
                                if (e) {
                                  const tmp = values.map(v => structuredClone(v));
                                  tmp[i].ref_tag_id = e.value;
                                  setValues({ ...rootValues, tags: tmp });
                                }
                              }}
                            />
                            <FormErrorMessage>{errors[i]?.ref_tag_id}</FormErrorMessage>
                          </Box>
                        </FormControl>
                      </Td>
                      <Td maxW="300px" w="300px" whiteSpace="initial">
                        <Text fontSize="sm">
                          {tagsDetails.data?.find(t => t.ref_tag_id === values[i].ref_tag_id)?.tag_desc}
                        </Text>
                      </Td>
                      <Td>
                        <FormControl isInvalid={!!errors[i]?.notes && !!touched[i]?.notes} h="100%">
                          <Input
                            size="sm"
                            id={'tags.' + i + '.notes'}
                            name={'tags.' + i + '.notes'}
                            value={values[i].notes ?? ''}
                            maxLength={tagFields.notes.max}
                            onChange={handleChange}
                            onBlur={handleChange}
                          />
                          <FormErrorMessage>{errors[i]?.notes}</FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <Tooltip label="Delete">
                          <IconButton
                            color="brand.error"
                            variant="link"
                            aria-label="Delete"
                            icon={<FaTrash />}
                            onClick={() => {
                              const tmp = values.map(v => structuredClone(v));
                              tmp.splice(i, 1);
                              setValues({ ...rootValues, tags: tmp });
                            }}
                            minWidth={1}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Box>
        <Button
          colorScheme="brand.main"
          size="sm"
          onClick={() => {
            setValues({
              ...rootValues,
              tags: [
                ...values,
                {
                  ref_tag_id: 0,
                  notes: '',
                },
              ],
            });
          }}
        >
          Create New
        </Button>
      </Box>
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedEditTags;
