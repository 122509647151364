import { IconProps } from '../../app/types/appType';

const AccountBalance = (props: IconProps) => {
  return (
    <svg
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="account_balance">
        <mask
          id="mask0_551_6486"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_551_6486)">
          <path
            id="account_balance_2"
            d="M5.75006 17V9.49999H7.25004V17H5.75006ZM11.25 17V9.49999H12.75V17H11.25ZM2.76929 20.5V19H21.2307V20.5H2.76929ZM16.75 17V9.49999H18.25V17H16.75ZM2.76929 7.49999V6.07694L12 1.55774L21.2307 6.07694V7.49999H2.76929ZM6.31539 6.00001H17.6846L12 3.25001L6.31539 6.00001Z"
            fill={props.color ?? '#13707F'}
          />
        </g>
      </g>
    </svg>
  );
};

export default AccountBalance;
