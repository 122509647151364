import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addBreadcrumb, clearBreadcrumb, sliceBreadcrumb } from '../../app/helpers/navigationHelpers';
import { useAppSelector } from '../../app/state/hooks';
import navItems, { NavItemModel } from '../SideNav/navItems';
import { CrumbModel } from './slice';

type Props = {};

const SiteBreadcrumb = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { crumbs } = useAppSelector(s => s.siteBreadcrumb);

  const [displayCrumbs, setDisplayCrumbs] = useState<CrumbModel[]>([]);

  useEffect(() => {
    const { pathname = '/' } = location;
    if (pathname === '/') {
      clearBreadcrumb();
    } else {
      pathname.split('/').forEach((v, i) => {});

      const pathItems = pathname.split('/');
      if (pathItems.length > 1) {
        addBreadcrumb({ label: 'home', path: '/' }, 0);
      }
      for (let index = 1; index < 3; index++) {
        const pathItem = pathItems[index];
        const pathUrl = pathname
          .split('/')
          .slice(0, index + 1)
          .join('/');
        const pathLabel = getPathLabel(pathUrl);

        if (crumbs[index] && pathLabel !== crumbs[index]?.label) {
          sliceBreadcrumb(0, index + 1);
        }

        if (pathItem) {
          addBreadcrumb(
            {
              label: pathLabel,
              path: pathname
                .split('/')
                .splice(0, index + 1)
                .join('/'),
            },
            index,
          );
        }
      }
    }
  }, [location.pathname]);

  const getPathLabel = (path: string, urls?: Array<NavItemModel>) => {
    urls = urls || navItems;

    let ret = '';
    for (const element of urls) {
      if (path === element.href) {
        ret = element.text;
      } else {
        if (element.subNav) {
          ret = getPathLabel(path, element.subNav);
        }
      }

      if (ret) break;
    }

    return ret;
  };

  const onCrumbClick = (path: string, index: number) => {
    sliceBreadcrumb(0, index + 1);
    navigate(path);
  };

  useEffect(() => {
    //Remove unnecessary repeating crumbs on browser's back button clicked
    //TODO: we have to detect the browser's back button event but for now we'll just check if the new crumb is similar to previous crumb to identify back button clicked

    const crumbLength = crumbs.length;
    if (crumbLength > 3 && crumbs[crumbLength - 3].path === crumbs[crumbLength - 1].path) {
      sliceBreadcrumb(0, crumbs.length - 2);
    }
    setDisplayCrumbs(crumbs);
  }, [crumbs]);

  return (
    <HStack bgColor="white" h={50} p={3}>
      <Breadcrumb>
        {displayCrumbs.map((m, i) => (
          <BreadcrumbItem key={i} isCurrentPage={displayCrumbs.length - 1 === i}>
            <BreadcrumbLink
              as={Button}
              variant="link"
              size="sm"
              isDisabled={displayCrumbs.length - 1 === i}
              onClick={() => {
                onCrumbClick(m.path, i);
              }}
              sx={{
                '&:disabled': {
                  cursor: 'default',
                  color: 'brand.main.default',
                  opacity: 1,
                },
              }}
            >
              {m.label.toUpperCase()}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </HStack>
  );
};

export default SiteBreadcrumb;
