import { Box, Button, ButtonGroup, IconButton, Link, Tag, Text, Tooltip } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import {
  useGetImportanLinkPaginatedQuery,
  useGetLImportantLinkPaginatedFilterQuery,
} from '../../../../app/services/dme/api/importantLink';
import {
  GetLImportantLinkPaginatedFilterModel,
  ImportantLinkResultPageFirstDatasetModel,
} from '../../../../app/services/dme/api/types';
import { SortType } from '../../../../app/services/types';
import { DynamicObject } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import AppAuth from '../../../../features/AppAuth';
import DeleteLoreLinkDialog from './DeleteImportantLinkDialog';

type Props = {};
const InitialSortBy: SortType<ImportantLinkResultPageFirstDatasetModel> = {
  id: 'link_name',
  desc: false,
};

const ResultData: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const navigate = useNavigate();
  const { data: filterData } = useGetLImportantLinkPaginatedFilterQuery();

  const filters = useMemo(() => {
    const tmpFilters: { filter_header: string; filter_detail: string[] }[] = [];
    searchParams.forEach((value, key) => {
      tmpFilters.push({
        filter_header: key,
        filter_detail: [...decodeURIComponent(value).split(',')],
      });
    });
    if (tmpFilters.length > 0 && filterData) {
      const initFilters: GetLImportantLinkPaginatedFilterModel[] = [];
      tmpFilters.forEach(filter => {
        filter.filter_detail.forEach((link_ref_category_name: string) => {
          const item = filterData.find(f => f.link_ref_category_name === link_ref_category_name);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      return initFilters.map(({ link_ref_category_id }) => ({ link_ref_category_id }));
    }
    return [];
  }, [filterData, searchParams]);

  const { data, isLoading, isFetching } = useGetImportanLinkPaginatedQuery({
    filters,
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
  });

  const Header = [
    {
      Header: 'Action',
      isSortable: false,
      isAction: true,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: ({ row: { original } }: { row: { original: ImportantLinkResultPageFirstDatasetModel } }) => {
        return (
          <>
            <AppAuth requiredAppAccess={AppAccess.EditLoreLinkWrite}>
              {isAuthorized =>
                isAuthorized ? (
                  <ButtonGroup spacing={4}>
                    <Tooltip label="Edit">
                      <IconButton
                        color="brand.main.default"
                        variant="link"
                        aria-label="Edit"
                        icon={<BsFillPencilFill />}
                        onClick={() => navigate(`./selected/id/${original.link_lore_link_id}/details`)}
                        minWidth={1}
                      />
                    </Tooltip>
                    <DeleteLoreLinkDialog
                      link_lore_link_id={original.link_lore_link_id}
                      triggerElement={
                        <Tooltip label="Delete">
                          <IconButton
                            color="brand.error"
                            variant="link"
                            aria-label="Delete"
                            icon={<FaTrash />}
                            minWidth={1}
                          />
                        </Tooltip>
                      }
                    />
                  </ButtonGroup>
                ) : (
                  <Tag>No Action</Tag>
                )
              }
            </AppAuth>
          </>
        );
      },
    },
    {
      Header: 'Link',
      accessor: 'link_url',
      styles: { whiteSpace: 'initial' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: ImportantLinkResultPageFirstDatasetModel } }) => {
        return (
          <Box>
            <Link href={original.link_url} isExternal>
              <Button size="sm" colorScheme="brand.main">
                Link
              </Button>
            </Link>
          </Box>
        );
      },
    },
    {
      Header: 'Category',
      accessor: 'link_ref_category_name',
      styles: { whiteSpace: 'initial' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: ImportantLinkResultPageFirstDatasetModel } }) => {
        return (
          <Box maxW="800px">
            <Text noOfLines={3} title={original.link_ref_category_name}>
              {original.link_ref_category_name}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Name - Definition',
      accessor: 'link_name',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: ImportantLinkResultPageFirstDatasetModel } }) => {
        return (
          <Box maxW="350px" noOfLines={3}>
            <Text title={original.link_name + ' - ' + original.link_description}>
              <Text as="span" fontWeight="bold">
                {original.link_name + ' - '}
              </Text>
              {original.link_description}
            </Text>
          </Box>
        );
      },
    },
    // {
    //   Header: "Definition",
    //   accessor: "link_description",
    //   styles: { whiteSpace: "initial" },
    //   Cell: ({ row: { original } }: { row: { original: LoreLinkResultPageFirstDatasetModel } }) => {
    //     return (
    //       <Box maxW="800px">
    //         <Text noOfLines={3} title={original.link_description}>
    //           {original.link_description}
    //         </Text>
    //       </Box>
    //     );
    //   },
    // },
    {
      Header: 'Last Modified Date',
      accessor: 'last_modified',
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: ImportantLinkResultPageFirstDatasetModel } }) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>;
      },
    },
  ];

  // const onClickRow = (row: LoreLinkResultPageFirstDatasetModel) => {
  //   navigate(`./selected/id/${row.link_lore_link_id}/details`);
  // };

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        isLoading={isLoading}
        isFetching={isFetching}
        data={data?.data ?? []}
        pageCount={data?.total_pages || 0}
        pageSize={data?.page_size || pageSize}
        totalRecords={data?.total_records || 0}
        pageIndex={pageNumber - 1}
        headers={Header}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [InitialSortBy] }}
        manualSortBy
        disableSortRemove
        onPageChange={index => {
          setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          setPageNumber(1);
          setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          setPageNumber(1);

          const params: DynamicObject = {};
          searchParams.forEach((val, key) => (params[key] = val));
          setSearchParams({ ...params, search: search }, { replace: true });
        }}
        onSort={sort => {
          if (sort[0]) {
            setPageNumber(1);
            setSortBy(sort[0]);
          }
        }}
        styles={{ pagination: { justifyContent: 'start' }, header: { justifyContent: 'left' } }}
        manual={true}
      />
    </Box>
  );
};

export default ResultData;
