import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { PlatformServicesExploreModel } from '../../../app/services/dme/api/types';
import { CustomTableHeader } from '../../../app/types/appType';
import { SortType } from '../../../app/services/types';

export const ExplorePlatformServicesInitialSortBy: SortType<PlatformServicesExploreModel> = {
  id: 'ps_ref_category_id',
  desc: false,
};

export const ExplorePlatformServicesHeader: CustomTableHeader<PlatformServicesExploreModel> = [
  {
    Header: 'Platform Portfolio',
    accessor: 'ps_portfolio_name',
    isSortable: false,
    styles: {
      whiteSpace: 'initial',
      verticalAlign: 'top',
      width: '300px',
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  {
    Header: 'Platform Portfolio Description',
    accessor: 'ps_portfolio_desc',
    styles: { whiteSpace: 'initial', verticalAlign: 'top' },
  },
  {
    Header: 'Service Category',
    accessor: 'ps_category_name',
    isSortable: false,
    styles: {
      whiteSpace: 'initial',
      verticalAlign: 'top',
      width: '300px',
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  {
    Header: 'Service Category Description',
    accessor: 'ps_category_desc',
    styles: { whiteSpace: 'initial', verticalAlign: 'top' },
  },
];

export const platformServicesSearchParams = {
  portfolio: 'portfolio',
  category: 'category',
  sortBy: 'sortBy',
  sortOrder: 'sortOrder',
};

