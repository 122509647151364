export const createFormData = (obj: any): FormData => {
  const formData = new FormData();
  Object.keys(obj).forEach((key, index) => {
    if (obj[key] instanceof Array<File>) {
      obj[key].forEach((file: File) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};
