import { Box, VStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { FeedbackModel } from '../../../app/services/dme/api/types';

type Props = {
  feedback: FeedbackModel | null;
};

const FeedbackMessage: FC<Props> = ({ feedback }) => {
  return (
    <VStack h="calc(50vh - 170px)" overflowY="auto">
      <Box w="full" p={3} borderRadius={5} bgColor="gray.50">
        <Text>{feedback?.feedback_message}</Text>
      </Box>
    </VStack>
  );
};

export default FeedbackMessage;
