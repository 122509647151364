import { StyleFunctionProps } from '@chakra-ui/react';
import 'quill-emoji/dist/quill-emoji.css';
import brandColors from '../brandColors';

const Quill = (props: StyleFunctionProps) => ({
  '.quill': {
    '.ql-toolbar.ql-snow': {
      border: 0,
      borderBottom: '1px solid #e2e8f0',
    },
    '.ql-container': {
      border: 0,
      '.ql-editor': {
        maxH: 100,
      },
      '.ql-mention-list-container': {
        '.ql-mention-list-item': {
          display: 'flex',
          padding: '3px 9px',
          alignItems: 'center',
          img: {
            blockSize: '27px',
            borderRadius: '50%',
            minWidth: '27px',
            bgColor: brandColors.primary.whiteBlack[100],
          },
          span: {
            marginLeft: 3,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '14px',
          },
        },
      },
    },
    '#emoji-palette': {
      '#tab-panel': {
        '.ap': {
          marginTop: '3px',
        },
        '.bem.bem-middle_finger.ap.ap-middle_finger': {
          display: 'none',
        },
      },
    },
  },
});
export default Quill;
