import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/state/hooks';
import { Text } from '@chakra-ui/react';
import PubNubConstants from './constants';
type Props = {
  channelName: string;
};

const TypingIndicator: FC<Props> = ({ channelName }) => {
  const { signalEvent } = useAppSelector(s => s.pubNub);
  const { logonUser } = useAppSelector(s => s.user);
  const [usersTyping, setUsersTyping] = useState<{ uuid: string; name: string; timeStamp: Dayjs }[]>([]);
  const [triggerTyperTimer, setTriggerTyperTimer] = useState(false);

  useEffect(() => {
    return () => {
      setUsersTyping([]);
    };
  }, []);

  useEffect(() => {
    if (signalEvent?.channel === channelName) {
      const { publisher, timetoken, message } = signalEvent;
      if (logonUser?.email === publisher) {
        return;
      }

      if (message === PubNubConstants.SignalEvent.TYPING) {
        const index = usersTyping.findIndex(f => f.uuid === publisher);

        const timeStamp = dayjs(new Date(+timetoken / 1e4));
        if (index > -1) {
          const newRecord = [...usersTyping];
          newRecord[index] = { ...usersTyping[index], timeStamp };
          setUsersTyping([...newRecord]);
        } else {
          setUsersTyping(s => [...s, { uuid: publisher, name: publisher, timeStamp }]);
        }
      }
    }
  }, [signalEvent]);

  useEffect(() => {
    if (usersTyping.length) {
      setTriggerTyperTimer(true);
    } else {
      setTriggerTyperTimer(false);
    }
  }, [usersTyping]);

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (!usersTyping.length) {
        clearInterval(intervalTimer);
      } else {
        setUsersTyping(s => s.filter(f => dayjs().diff(f.timeStamp, 'ms') < 1000));
      }
    }, 900);

    if (!triggerTyperTimer) {
      clearInterval(intervalTimer);
    }

    return () => {
      clearInterval(intervalTimer);
    };
  }, [triggerTyperTimer]);

  const setTypingMessage = () => {
    const topTwoTypers = usersTyping
      .slice(0, 2)
      .map(m => m.name)
      .join(usersTyping.length <= 2 ? ' and ' : ', ');

    const otherTypersCount = usersTyping.length - 2;

    const otherTypers = otherTypersCount < 1 ? '' : ` and ${otherTypersCount} other${otherTypersCount > 1 ? 's' : ''}`;

    const ret = `${topTwoTypers}${otherTypers} ${usersTyping.length > 1 ? 'are' : 'is'} typing...`;

    return ret;
  };

  return (
    <>
      {usersTyping.length > 0 && (
        <Text fontSize={12} color="brandColors.primary.whiteBlack.300">
          {setTypingMessage()}
        </Text>
      )}
    </>
  );
};

export default TypingIndicator;
