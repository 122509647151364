import { HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultInnerHtmlStyle } from '../../app/constants';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { addBreadcrumb, sliceBreadcrumb } from '../../app/helpers/navigationHelpers';
import { useGetReleaseNoteListQuery } from '../../app/services/dme/api/releaseNotes';
import { ReleaseNoteModel } from '../../app/services/dme/api/types';
import PageLoading from '../../components/PageLoading';
import PanelCard from '../../components/PanelCard';

const ReleaseNotes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState(-1);

  const { data, isLoading, isFetching } = useGetReleaseNoteListQuery();

  const handleSelect = (releaseNote: ReleaseNoteModel) => () => {
    navigate('#' + releaseNote.dme_release_notes_id);
  };

  useEffect(() => {
    if (data && location.hash) {
      const id = location.hash.substring(1);
      const releaseNote = document.getElementById(id);
      if (releaseNote) {
        releaseNote.scrollIntoView({ behavior: 'smooth' });
        setSelectedId(+id);
      }
    }
  }, [data, location.hash]);

  useEffect(() => {
    sliceBreadcrumb(0, 1);
    addBreadcrumb({ label: 'Release Notes', path: location.pathname });
  }, [location.pathname]);

  return (
    <>
      {isLoading || isFetching ? (
        <PageLoading />
      ) : (
        <VStack w="full">
          {data?.map(m => (
            <PanelCard
              key={m.dme_release_notes_id}
              id={m.dme_release_notes_id.toString()}
              scrollMargin="20"
              borderColor={selectedId === m.dme_release_notes_id ? 'blue.200' : 'gray.200'}
              border="2px"
              header={
                <HStack>
                  <Heading mr="auto" size="md" cursor="pointer" onClick={handleSelect(m)}>
                    {m.release_notes_title}
                  </Heading>
                  <Heading size="sm">{convertUtcToLocal(m.row_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}</Heading>
                </HStack>
              }
            >
              <Text fontSize="md" pb="8" mt="-3" fontWeight="semibold">
                {convertUtcToLocal(m.release_date, 'MMMM D, YYYY')}
              </Text>
              <Text
                dangerouslySetInnerHTML={{ __html: m.release_notes_content }}
                sx={{
                  ...DefaultInnerHtmlStyle,
                  ul: {
                    marginLeft: '32px',
                  },
                  'ul li': {
                    marginBottom: '6px',
                  },
                }}
              ></Text>
            </PanelCard>
          ))}
        </VStack>
      )}
    </>
  );
};

export default ReleaseNotes;
