import { removeUnwantedCharacters } from '../../../helpers/stringHelper';
import { dmeApi } from './index';
import {
  LoreLanguageDetailsPageDiscussionModel,
  LorelanguagePostAttachmentModel,
  ToggleLoreLanguagePostReactionModel,
  UpdateLoreLanguagePostModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getLoreLanguageDetailsDiscussionPostsByLoreLanguageId: builder.query<
      Array<LoreLanguageDetailsPageDiscussionModel>,
      number
    >({
      query: loreLanguageId => `/lorelanguage/details/discussion/posts/${loreLanguageId}`,
    }),

    getLoreLanguagePostById: builder.query<LoreLanguageDetailsPageDiscussionModel, number>({
      query: ll_post_id => `/lorelanguage/details/discussion/posts/ll_post_id/${ll_post_id}`,
      transformResponse(response: Array<LoreLanguageDetailsPageDiscussionModel>) {
        return response[0];
      },
    }),

    getLoreLanguagePostAttachmentsById: builder.query<Array<LorelanguagePostAttachmentModel>, number>({
      query: ll_post_id => `/lorelanguagepost/attachment/ll_post_id/${ll_post_id}`,
      providesTags: ['LoreLanguagePostAttachments'],
    }),

    addLoreLanguagePost: builder.mutation<{ ll_post_id: number }, FormData>({
      query: params => ({
        url: '/lorelanguagepost',
        method: 'POST',
        body: params,
      }),
    }),

    postLoreLanguagePostToggleReaction: builder.mutation<void, ToggleLoreLanguagePostReactionModel>({
      query: params => ({
        url: '/lorelanguagepost/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateLoreLanguagePost: builder.mutation<void, UpdateLoreLanguagePostModel>({
      query: params => ({
        url: '/lorelanguagepost',
        method: 'PUT',
        body: { ...params, post_message: removeUnwantedCharacters(params.post_message) },
      }),
    }),

    deleteLoreLanguagePost: builder.mutation<void, number>({
      query: ll_post_id => ({
        url: `/lorelanguagepost?${new URLSearchParams({ ll_post_id: ll_post_id.toString() }).toString()}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLoreLanguagePostAttachmentsByIdQuery,
  useLazyGetLoreLanguageDetailsDiscussionPostsByLoreLanguageIdQuery,
  useLazyGetLoreLanguagePostByIdQuery,
  useAddLoreLanguagePostMutation,
  usePostLoreLanguagePostToggleReactionMutation,
  useUpdateLoreLanguagePostMutation,
  useDeleteLoreLanguagePostMutation,
} = extendedApi;
