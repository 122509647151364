import {
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  FormControl,
  Text,
  Alert,
  AlertIcon,
  Box,
  HStack,
  FormLabel,
  Input,
  ListItem,
  UnorderedList,
  Skeleton,
  Stack,
  Icon,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useDeleteRefDomainMutation } from '../../../app/services/dme/api/refDomain';
import { useLazyGetDataProductListByRefDomainIdQuery } from '../../../app/services/dme/api/dataProduct';
import { MdWarning } from 'react-icons/md';

const FormSchema = Yup.object().shape({
  delete_text: Yup.string().label('').required().equals(['delete']),
  ref_domain_id: Yup.number().required().moreThan(0, 'Owner is a required field'),
});

type Props = {
  ref_domain_id: number;
  domain_name?: string;
  triggerElement: JSX.Element;
  reloadList?: () => void;
};

const DeleteDomainDialog = ({ ref_domain_id, triggerElement, reloadList }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerGetDp, { data, isLoading, isFetching }] = useLazyGetDataProductListByRefDomainIdQuery();
  const [deleteAsync, deleteDetail] = useDeleteRefDomainMutation();
  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_domain_id: ref_domain_id,
      delete_text: '',
    },
    onSubmit: values => {
      deleteAsync(values.ref_domain_id);
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = deleteDetail;

    if (isSuccess) {
      setAlertMessage('Domain successfully deleted.');
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [deleteDetail]);

  useEffect(() => {
    if (!isOpen && deleteDetail.isSuccess) {
      reloadList && reloadList();
    }
    if (isOpen) {
      triggerGetDp(ref_domain_id);
    }
  }, [isOpen]);

  return (
    <>
      <Box
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Domain</ModalHeader>
          <ModalCloseButton isDisabled={isLoading || isFetching || deleteDetail.isLoading} />
          <>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <VStack spacing={5}>
                  <Alert status="error">
                    <AlertIcon />
                    Deleting this Domain will also delete the associated Data Products under this Domain permanently.
                  </Alert>
                  <Box maxH="250px" p={3} bg="gray.100" px={4} overflowY="auto">
                    {isLoading || isFetching ? (
                      <Stack mt={1}>
                        {[...Array(3)].map((m, i) => (
                          <Skeleton key={i} height="18px" />
                        ))}
                      </Stack>
                    ) : !data || (data && data.length == 0) ? (
                      <>No Data Product is associated to this Domain</>
                    ) : (
                      <UnorderedList>
                        {data.map((item, i) => (
                          <ListItem key={i}>
                            <Text noOfLines={1} title={item.data_product_name}>
                              {item.data_product_name}
                            </Text>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    )}
                  </Box>
                  <FormControl isInvalid={!!errors.delete_text && touched.delete_text}>
                    <FormLabel>Confirm deletion by typing "delete"</FormLabel>
                    <Input
                      id="delete_text"
                      name="delete_text"
                      placeholder="delete"
                      onChange={handleChange}
                      value={values.delete_text}
                      maxLength={100}
                    />
                    <HStack alignItems="center" spacing={2} mt={2}>
                      <Icon as={MdWarning} color="brand.error" />
                      <Text as="span">The Operation cannot be undone</Text>
                    </HStack>
                  </FormControl>

                  <VStack spacing={5}>
                    {alertMessage && (
                      <Alert status={deleteDetail.isSuccess ? 'success' : 'error'}>
                        <AlertIcon />
                        {alertMessage}
                      </Alert>
                    )}
                  </VStack>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3} ml="auto" isDisabled={deleteDetail.isLoading}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.error"
                  bg="brand.error"
                  isLoading={isLoading || isFetching || deleteDetail.isLoading}
                  isDisabled={
                    (alertMessage !== '' && (deleteDetail.isSuccess || isLoading || isFetching)) ||
                    values.delete_text !== 'delete'
                  }
                >
                  Delete
                </Button>
              </ModalFooter>
            </form>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteDomainDialog;
