import { SystemStyleObject, UseToastOptions } from '@chakra-ui/react';

export const fileConfig = {
  acceptedFiles: (process.env.REACT_APP_FEEDBACK_FILETYPES || '').split(','),
  maxFileSize: process.env.REACT_APP_FEEDBACK_MAX_FILE_SIZE,
  maxFileCount: process.env.REACT_APP_FEEDBACK_MAX_FILE_COUNT,
};

export const addUserConfig = {
  lore: 'Lore',
  graph: 'graph',
};

export const viewableExt = ['pdf', 'jpeg', 'jpg', 'png', 'gif'];

export const DefaultToastOptions: UseToastOptions = {
  position: 'top-right',
  duration: 3000,
  variant: 'left-accent',
  isClosable: true,
};

export const DefaultInnerHtmlStyle: SystemStyleObject = {
  '& a': {
    color: 'blue',
    textDecoration: 'underline blue',
  },
  'ul, ol': {
    listStylePosition: 'inside',
  },
};

export const AppSize = {
  sideNav: {
    widthNumber: {
      open: 252,
      close: 50,
    },
    width: {
      open: '252px',
      close: '50px',
    },
  },
};

export const defaultErrorMessage = `Something went wrong, please try again later or contact admin`;
