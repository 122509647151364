import { useColorModeValue, Stack, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
type Props = {};

const Footer = (props: Props) => {
  const location = useLocation();
  return (
    <>
      <Stack
        h={63}
        w="100%"
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justifyContent="center"
        alignItems="center"
        bg={useColorModeValue('gray.50', 'gray.900')}
      >
        <Text fontSize="sm">&copy; {new Date().getFullYear()} Data Mesh Experience</Text>
      </Stack>
    </>
  );
};

export default Footer;
