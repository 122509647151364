import { Box, Button, Checkbox, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { useGetLImportantLinkPaginatedFilterQuery } from '../../../app/services/dme/api/importantLink';
import { GetLImportantLinkPaginatedFilterModel } from '../../../app/services/dme/api/types';

type IProps = {};

const categoryFilter = 'Category';
const defaultFilter = [categoryFilter.toLowerCase()];

const FilterPane: FC<IProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetLImportantLinkPaginatedFilterQuery();
  const [toggledFilterGroups, setToggledFilterGroups] = useState<string[]>(defaultFilter);
  const [checkedFilters, setCheckedFilters] = useState<GetLImportantLinkPaginatedFilterModel[] | undefined>();

  const onFilterGroupClick = (filterGroupName: string) => {
    if (toggledFilterGroups.includes(filterGroupName)) {
      setToggledFilterGroups(s => s.filter(f => f.toLowerCase() !== filterGroupName));
    } else {
      setToggledFilterGroups(s => [...s, filterGroupName]);
    }
  };

  const isFilterGroupSelected = (filterGroupName: string) =>
    toggledFilterGroups.includes(filterGroupName.toLowerCase());

  const updateCheckedFilters = (obj: GetLImportantLinkPaginatedFilterModel, isChecked: boolean) => {
    if (isChecked) {
      setCheckedFilters(s => [...(s || []), obj]);
    } else {
      setCheckedFilters(s => s?.filter(f => f.link_ref_category_name !== obj.link_ref_category_name));
    }
  };

  useEffect(() => {
    if (checkedFilters) {
      setSearchParams({
        ...checkedFilters.reduce((obj, val) => {
          const key = val.filter_header.toLowerCase();
          if (obj[key]) {
            obj[key] = obj[key] + ',' + val.link_ref_category_name;
          } else {
            obj[key] = val.link_ref_category_name;
          }
          return obj;
        }, {} as any),
        ...(searchParams.get('search') ? { search: searchParams.get('search') } : {}),
      });
    }
  }, [checkedFilters]);

  useEffect(() => {
    //if there are initial filter coming from url, then auto check filters based on url
    const filters: { filter_header: string; link_ref_category_name: string[] }[] = [];
    searchParams.forEach((value, key) => {
      filters.push({
        filter_header: key,
        link_ref_category_name: [...decodeURIComponent(value).split(',')],
      });
    });
    if (filters.length > 0 && data) {
      const initFilters: GetLImportantLinkPaginatedFilterModel[] = [];
      filters.forEach(filter => {
        filter.link_ref_category_name.forEach((detail: string) => {
          const item = data.find(f => f.link_ref_category_name === detail);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      setCheckedFilters(
        initFilters.map(({ link_ref_category_name, link_ref_category_id }) => ({
          filter_header: categoryFilter,
          link_ref_category_name,
          link_ref_category_id,
        })),
      );

      setToggledFilterGroups(
        initFilters.length > 0 ? initFilters.map(m => m.filter_header.toLowerCase()) : defaultFilter,
      );
    }
  }, [data]);

  return (
    <VStack w="100%" h="100vh" overflowY="scroll" maxW={300} bgColor="gray.50" p={3} pt={2}>
      <HStack justifyContent="space-between">
        <Heading size="md">Filters</Heading>
        <Button
          size="sm"
          onClick={() => {
            setCheckedFilters([]);
            setSearchParams(searchParams.get('search') ? { search: searchParams.get('search') ?? '' } : {}, {
              replace: true,
            });
          }}
        >
          Clear
        </Button>
      </HStack>
      <Divider />
      <VStack>
        <>
          {/* {filterData.length > 0 ? (
            filterData.map(({ filter_header, filter_items }, i) => ( */}
          {isLoading ? (
            <>Loading...</>
          ) : (
            <Box>
              <Button
                w="100%"
                rightIcon={isFilterGroupSelected(categoryFilter) ? <HiChevronDown /> : <HiChevronRight />}
                justifyContent="space-between"
                size="sm"
                onClick={() => {
                  onFilterGroupClick(categoryFilter.toLowerCase());
                }}
              >
                {categoryFilter}
              </Button>
              {isFilterGroupSelected(categoryFilter) && (
                <VStack bgColor="gray.100" p={3} pt={0}>
                  {data?.map(({ link_ref_category_name, link_ref_category_id }, ii) => (
                    <Checkbox
                      key={ii}
                      name={`${link_ref_category_name}`.replace(/\s/g, '')}
                      onChange={(e: any) => {
                        updateCheckedFilters(
                          {
                            link_ref_category_id,
                            link_ref_category_name,
                            filter_header: categoryFilter,
                          },
                          e.target.checked,
                        );
                      }}
                      isChecked={checkedFilters?.some(s => s.link_ref_category_name === link_ref_category_name)}
                      _hover={{ bgColor: 'gray.200' }}
                    >
                      <Text
                        whiteSpace="initial"
                        fontSize="sm"
                        noOfLines={2}
                        title={link_ref_category_name}
                        maxW="210px"
                      >
                        {link_ref_category_name}
                      </Text>
                    </Checkbox>
                  ))}
                </VStack>
              )}
            </Box>
          )}
          {/* ))
          ) : (
            <>No Filter(s) found</>
          )} */}
        </>
      </VStack>
    </VStack>
  );
};

export default FilterPane;
