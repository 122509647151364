import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FC } from 'react';
import { defaultErrorMessage } from '../../../../app/constants';
import { useDeleteReportPortfolioMutation } from '../../../../app/services/dme/api/reportHub';
import { ReportHubExploreModel } from '../../../../app/services/dme/api/types';

type Props = {
  original: ReportHubExploreModel;
  onClose: () => void;
};
const ExplorePortfoliosActionCellDelete: FC<Props> = ({ original, onClose: onCloseMenu }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [deletePortfolio, { isLoading }] = useDeleteReportPortfolioMutation();

  const handleDelete = () => {
    deletePortfolio(original.rh_report_portfolio_id)
      .unwrap()
      .then(() => {
        toast({
          description: `Successfully deleted portfolio`,
        });
        onClose();
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="blackAlpha.900"
        textAlign="left"
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
      >
        <Box w="100%">Delete</Box>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Delete Report Portfolio</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody>
            <Text>
              Are you sure you want to delete portfolio{' '}
              <Text as="span" fontWeight="bold">
                {original.report_portfolio_name}
              </Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme="red" isLoading={isLoading} onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExplorePortfoliosActionCellDelete;
