import { InfoIcon } from '@chakra-ui/icons';
import { Box, CloseButton, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../../app/state/hooks';
import { appColors } from '../../app/theme';
import { MentionPubnubMessage } from '../../app/types/appType';

type Props = {};

const Mentions = (props: Props) => {
  const { logonUser } = useAppSelector(s => s.user);
  const { messageEvent } = useAppSelector(s => s.pubNub);
  const toast = useToast();
  const userRole = useAppSelector(s => s.userRole);

  useEffect(() => {
    if (logonUser && messageEvent?.channel === `mention.${logonUser.email}`) {
      const hasAdminCustomRequestAccess = !!userRole.currentUserUserRole?.some(
        access =>
          access.app_access_name === 'customrequestapproval.read' ||
          access.app_access_name === 'customrequestapproval.write',
      );

      const { fromName, postId, type, commentId, data }: MentionPubnubMessage = messageEvent.message ?? {};
      const msg = commentId ? 'in a comment on post' : 'on post';
      const url1 =
        type === 'Custom Request'
          ? hasAdminCustomRequestAccess
            ? `/admin/custom-requests-approval/none/selected/id/${data.customRequestId}/discussion?post=${postId}`
            : `/custom-requests/my-requests/selected/id/${data.customRequestId}/discussion?post=${postId}`
          : type === 'Data Product'
            ? `/data-products/explore-data-products/selected/domain/${data.domain}/data-product/${data.dataProduct}/id/${data.dataProductId}/discussion?post=${postId}`
            : type === 'Glossary'
              ? `/glossary/explore-glossary/results/selected/id/${data.glossaryId}/discussion?post=${postId}`
              : type === 'Important Link'
                ? `/important-link/explore-important-link/results/selected/id/${data.importantLinkId}/discussion?post=${postId}`
                : type === 'Report Hub'
                  ? `/report-hub/explore-portfolios/selected/id/${data.rhpId}/discussion?post=${postId}`
                  : type === 'Governance Office EDS'
                    ? `/governance-office/explore-data-sharing-requests/selected/id/${data.goEdsId}/discussion?post=${postId}`
                    : type === 'Platform Services'
                    ? `/platform-services/my-requests/selected/id/${data.psRequestId}/discussion?post=${postId}`
                    : '';

      toast({
        status: 'info',
        duration: 9000,
        isClosable: true,
        colorScheme: 'brand.main',
        position: 'bottom-right',
        variant: 'subtle',
        render: ({ onClose }) => (
          <Box bg={appColors.brand.main[100]} p="3" rounded="md" shadow="md">
            <Flex gap="2">
              <InfoIcon color="brand.main.default" alignSelf="start" w="5" h="5" />
              <Flex direction="column" flex="1">
                <Heading as="h5" size="sm">
                  Notification
                </Heading>
                <Text>
                  {`${fromName} mentioned you ${msg} `}
                  <Text as={RouterLink} to={url1} textColor="blue.500" textDecor="underline">
                    {type}
                  </Text>
                </Text>
              </Flex>
              <CloseButton size="sm" alignSelf="start" onClick={() => onClose()} m="-0.5rem" />
            </Flex>
          </Box>
        ),
      });
    }
  }, [messageEvent]);

  return <></>;
};

export default Mentions;
