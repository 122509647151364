import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { getName } from '../../../app/helpers/stringHelper';
import { useGetGoEdsRequestSelectedUserQuery } from '../../../app/services/dme/api/governanceOfficeEds';
import { GoEdsRequestSelectedUserModel } from '../../../app/services/dme/api/types';
import { CustomTableHeader } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';

type Props = {};

const Header: CustomTableHeader<GoEdsRequestSelectedUserModel> = [
  {
    Header: 'Name',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<GoEdsRequestSelectedUserModel>) => {
      return (
        <>
          {getName({ first_name: original.associated_user_first_name, last_name: original.associated_user_last_name })}
        </>
      );
    },
  },
  {
    Header: 'Date Added',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<GoEdsRequestSelectedUserModel>) => {
      return <>{convertUtcToLocal(original.associated_user_added_datetime) || '-'}</>;
    },
  },
];

const GovernanceOfficeSelectedAssociatedUsers = (props: Props) => {
  const params = useParams();

  const goEdsId: number = parseInt(params?.goEdsId || '');
  const { data, isLoading, isFetching } = useGetGoEdsRequestSelectedUserQuery({ goEdsId }, { skip: goEdsId === 0 });

  if (isLoading || isFetching) return <>Loading...</>;
  if (!data) return <></>;

  return (
    <Box w="fit-content">
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isLoading}
        data={data || []}
        pageCount={0}
        pageSize={data ? data.length : 0}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
      />
    </Box>
  );
};

export default GovernanceOfficeSelectedAssociatedUsers;
