import { Heading, HStack, VStack, IconButton, Text, Tag } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { MdOutlineFilterList } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useGetDataProductSelectPayloadElementTagsByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';
import { DataProductSelectPagePayloadElementTagsModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

type Props = {};

const DataProductSelectedPayloadElementTag = (props: Props) => {
  const params = useParams();
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    setProductId(+(params?.dataProductId || 0));
  }, [params]);

  const { data, isLoading } = useGetDataProductSelectPayloadElementTagsByProductIdQuery(productId, {
    skip: !productId,
  });

  const Header = [
    {
      Header: 'Name',
      accessor: 'data_element',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Data Type',
      accessor: 'data_element_data_type',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Description',
      accessor: 'data_element_description',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Tags',
      accessor: 'data_element_tag',
      Cell: ({ row: { original } }: { row: { original: DataProductSelectPagePayloadElementTagsModel } }) => {
        return (
          <HStack spacing={1}>
            {original.data_element_tag && original.data_element_tag.split(', ').map(tag => <Tag>{tag}</Tag>)}
          </HStack>
        );
      },
    },
  ];
  return (
    <VStack spacing={5} p={1} pt={3} w="100%">
      <Heading size="sm">Payload and Element Tags</Heading>
      {isLoading ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={isLoading}
          isFetching={isLoading}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
        />
      </HStack>
    );
  }
};

export default DataProductSelectedPayloadElementTag;
