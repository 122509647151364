import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FC, useEffect, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { defaultErrorMessage } from '../../../app/constants';
import env from '../../../app/constants/env';
import {
  useAddStatusGovernanceOfficeEdsMutation,
  useDeleteGovernanceOfficeEdsMutation,
  useGetGoEdsRequestStatusListQuery,
} from '../../../app/services/dme/api/governanceOfficeEds';
import { GoEdsMyRequestModel } from '../../../app/services/dme/api/types';

const MyExternalDataSharingRequestsTableActionCell = ({
  row: { original },
}: {
  row: { original: GoEdsMyRequestModel };
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="fit-content">
              <PopoverArrow />
              <PopoverBody>
                <VStack py="4" gap="2">
                  <Delete original={original} onClose={onClose} />
                  <ChangeStatus original={original} onClose={onClose} />
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

type Props = {
  original: GoEdsMyRequestModel;
  onClose: () => void;
};

const Delete: FC<Props> = ({ original, onClose: onCloseMenu }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [deleteRequest, { isLoading }] = useDeleteGovernanceOfficeEdsMutation();

  const handleDelete = () => {
    deleteRequest(original.go_eds_request_id)
      .unwrap()
      .then(() => {
        toast({
          description: `Successfully deleted request`,
        });
        onClose();
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="blackAlpha.900"
        textAlign="left"
        isDisabled={
          original.go_eds_ref_request_status_id !== env.db.goEds.status.developingId &&
          original.go_eds_ref_request_status_id !== env.db.goEds.status.discussionId
        }
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
      >
        <Box w="100%">Delete</Box>
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Delete Request</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody>
            <Text>
              Are you sure you want to delete request{' '}
              <Text as="span" fontWeight="bold">
                {original.request_subject}
              </Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme="red" isLoading={isLoading} onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ChangeStatus: FC<Props> = ({ original, onClose: onCloseMenu }) => {
  const [statusId, setStatusId] = useState<number | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const statusListDetails = useGetGoEdsRequestStatusListQuery();
  const [addStatus, { isLoading }] = useAddStatusGovernanceOfficeEdsMutation();

  const handleAddStatus = () => {
    if (!statusId) return;

    addStatus({
      go_eds_request_id: original.go_eds_request_id,
      go_eds_ref_request_status_id: statusId,
    })
      .unwrap()
      .then(() => {
        toast({
          description: `Successfully changed status`,
          status: 'success',
        });
        onClose();
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  useEffect(() => {
    if (isOpen) {
      setStatusId(null);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="blackAlpha.900"
        textAlign="left"
        isDisabled={
          original.go_eds_ref_request_status_id !== env.db.goEds.status.developingId &&
          original.go_eds_ref_request_status_id !== env.db.goEds.status.discussionId
        }
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
      >
        <Box w="100%">Change Status</Box>
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Change Status</ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />

          <ModalBody display="flex" flexDir="column" gap="4">
            <Text>
              Change status of request{' '}
              <Text as="span" fontWeight="bold">
                {original.request_subject}
              </Text>
              ?
            </Text>

            <Select
              size="sm"
              id="go_eds_ref_request_status_id"
              name="go_eds_ref_request_status_id"
              placeholder={statusListDetails.isLoading || statusListDetails.isFetching ? 'Loading...' : ''}
              isDisabled={statusListDetails.isLoading || statusListDetails.isFetching}
              useBasicStyles
              value={(() => {
                const val = statusListDetails.data?.find(m => m.go_eds_ref_request_status_id === statusId);
                return val
                  ? {
                      label: val.request_status_name,
                      value: val.go_eds_ref_request_status_id,
                    }
                  : undefined;
              })()}
              options={[
                ...(statusListDetails.data ?? []).map(m => {
                  return {
                    label: m.request_status_name,
                    value: m.go_eds_ref_request_status_id,
                    isDisabled: m.go_eds_ref_request_status_id !== env.db.goEds.status.submittedId,
                  };
                }),
              ]}
              onChange={e => {
                e && setStatusId(e.value);
              }}
            />
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme="brand.main" isLoading={isLoading} isDisabled={!statusId} onClick={handleAddStatus}>
              Change Status
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyExternalDataSharingRequestsTableActionCell;
