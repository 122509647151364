import {
  Heading,
  HStack,
  VStack,
  IconButton,
  Button,
  ButtonGroup,
  Tooltip,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Flex,
  Radio,
} from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { BsFillCaretDownSquareFill, BsFillCaretUpSquareFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdInfo } from 'react-icons/md';
import { Row } from 'react-table';
import { arrayMove } from '../../../../app/helpers/arrayHelper';
import { ValueOf } from '../../../../app/helpers/utilities';
import { useGetRefTagListQuery } from '../../../../app/services/dme/api/refTags';
import { DataProductSelectPagePayloadElementTagsModel } from '../../../../app/services/dme/api/types';
import { EditDataProductModel } from '../../../../app/services/types';
import EditableCell from '../../../../components/CustomTable/EditableCell';

type Props = {
  initialValues: EditDataProductModel;
  data: EditDataProductModel;
  onChangeData: (field: keyof EditDataProductModel, data: ValueOf<EditDataProductModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const DataProductSelectedPayloadElementTag = ({ data, onChangeData, errors, touched, title, tabErrorIndex }: Props) => {
  const [selectedId, setSelectedId] = useState(-9999);

  const { data: tagsData, isLoading: isLoadingTags, isFetching: isFetchingTags } = useGetRefTagListQuery();

  const generateIdForAddedItem = (): number => {
    return data.payloadModel && data.payloadModel.some(f => f.dp_data_product_payload_id < 1)
      ? Math.min.apply(
          Math,
          data.payloadModel.map(m => m.dp_data_product_payload_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow = {
      dp_data_product_payload_id: generateIdForAddedItem(),
      dp_data_product_id: data.data_product_id,
      data_element: '',
      data_element_data_type: '',
      data_element_description: '',
      data_element_tag: '',
      is_deleted_flag: false,
      ref_tag_ids: [] as number[],
      data_element_order: (data.payloadModel?.length ? data.payloadModel.length : 1) - 1,
    };
    onChangeData('payloadModel', data.payloadModel ? [...data.payloadModel, newRow] : [newRow]);
  };

  const onUpdateData = (rowIndex: number, columnId: string, value: string | File | boolean | number | number[]) => {
    data.payloadModel &&
      onChangeData(
        'payloadModel',
        data.payloadModel.map((row, index) => {
          if (index === rowIndex && data.payloadModel) {
            return {
              ...data.payloadModel[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        }),
      );
  };

  const onRemove = (item: DataProductSelectPagePayloadElementTagsModel) => {
    data.payloadModel &&
      onChangeData(
        'payloadModel',
        data.payloadModel.filter(f => f.dp_data_product_payload_id != item.dp_data_product_payload_id),
      );
  };

  const onMove = (direction: 'up' | 'down', index: number) => {
    const newList = arrayMove(
      data.payloadModel?.filter(f => !f.is_deleted_flag) || [],
      index,
      index + (direction === 'up' ? -1 : 1),
    );
    onChangeData('payloadModel', newList);
  };

  return (
    <VStack spacing={5} p={1} pt={3} w="100%">
      <HStack>
        <Heading size="sm">{title}</Heading>
        <Button
          size="sm"
          leftIcon={<FiRefreshCcw />}
          onClick={() => {
            data.payloadModel && onChangeData('payloadModel', data.payloadModel, true);
          }}
        >
          Refresh
        </Button>
      </HStack>
      {isLoadingTags || isFetchingTags ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data.payloadModel && !tagsData ? null : (
      <VStack>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th></Th>
              <Th>
                <Flex>
                  Name
                  <Tooltip label="Data Element Name must be unique">
                    <IconButton
                      minW={6}
                      variant="link"
                      aria-label="Info"
                      icon={<MdInfo />}
                      color={tabErrorIndex === 1 ? 'brand.error' : 'brand.main.default'}
                    />
                  </Tooltip>
                </Flex>
              </Th>
              <Th>Data Type</Th>
              {/* <Th>Description</Th> */}
              <Th>Tags</Th>
              <Th>Action</Th>
            </Tr>
            {(data.payloadModel?.filter(f => !f.is_deleted_flag) || []).map((d, index, arr) => (
              <Fragment key={d.dp_data_product_payload_id}>
                <Tr sx={{ '& td': { border: 'none' } }}>
                  <Td verticalAlign="top">
                    <Radio
                      value={d.dp_data_product_payload_id.toString()}
                      isChecked={d.dp_data_product_payload_id === selectedId}
                      onChange={() => setSelectedId(d.dp_data_product_payload_id)}
                      pt="2"
                    />
                  </Td>
                  <Td verticalAlign="top">
                    <EditableCell
                      name={`payloadModel.${index}.data_element`}
                      value={d.data_element}
                      row={{ index: index } as Row}
                      column={{ id: 'data_element' } as any}
                      updateData={onUpdateData}
                      isInvalid={
                        !!(
                          errors.payloadModel &&
                          (errors.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[])[index]
                            ?.data_element
                        ) &&
                        !!(
                          touched.payloadModel &&
                          (touched.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[])[index]
                            ?.data_element
                        )
                      }
                    />
                  </Td>
                  <Td verticalAlign="top">
                    <EditableCell
                      name={`payloadModel.${index}.data_element_data_type`}
                      value={d.data_element_data_type}
                      row={{ index: index } as Row}
                      column={{ id: 'data_element_data_type' } as any}
                      updateData={onUpdateData}
                      isInvalid={
                        !!(
                          errors.payloadModel &&
                          (errors.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[])[index]
                            ?.data_element_data_type
                        ) &&
                        !!(
                          touched.payloadModel &&
                          (touched.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[])[index]
                            ?.data_element_data_type
                        )
                      }
                    />
                  </Td>
                  {/* <Td verticalAlign="top">
                  <EditableCell
                    name={`payloadModel.${index}.data_element_description`}
                    value={d.data_element_description}
                    row={{ index: index } as Row}
                    column={{ id: "data_element_description" } as any}
                    updateData={onUpdateData}
                    type="textarea"
                    isInvalid={
                      !!(
                        errors.payloadModel &&
                        (
                          errors.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[]
                        )[index]?.data_element_description
                      ) &&
                      !!(
                        touched.payloadModel &&
                        (
                          touched.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[]
                        )[index]?.data_element_description
                      )
                    }
                  />
                </Td> */}
                  <Td verticalAlign="top" maxW="64">
                    {tagsData && (
                      <EditableCell
                        value={d.ref_tag_ids}
                        row={{ index: index } as Row}
                        column={{ id: 'ref_tag_ids' } as any}
                        updateData={onUpdateData}
                        type="multiple-dropdown"
                        dropdownOptions={tagsData?.map(m => {
                          return { text: m.tag_name, value: m.ref_tag_id.toString() };
                        })}
                      />
                    )}
                  </Td>
                  <Td verticalAlign="top" textAlign="right" w="24">
                    <ButtonGroup spacing={1} pt="2">
                      {d.dp_data_product_payload_id === selectedId && index !== 0 && (
                        <Tooltip label="Move Up">
                          <IconButton
                            variant="link"
                            aria-label="Move Up"
                            icon={<BsFillCaretUpSquareFill />}
                            onClick={() => onMove('up', index)}
                            minWidth={1}
                          />
                        </Tooltip>
                      )}
                      {d.dp_data_product_payload_id === selectedId && index !== arr.length - 1 && (
                        <Tooltip label="Move Down">
                          <IconButton
                            variant="link"
                            aria-label="Move Down"
                            icon={<BsFillCaretDownSquareFill />}
                            onClick={() => onMove('down', index)}
                            minWidth={1}
                          />
                        </Tooltip>
                      )}
                      <Tooltip label="Delete">
                        <IconButton
                          color="brand.error"
                          variant="link"
                          aria-label="Delete"
                          icon={<FaTrash />}
                          onClick={() => onRemove(d)}
                          minWidth={1}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </Td>
                </Tr>
                <Tr>
                  <Td></Td>
                  <Td verticalAlign="top" colSpan={3}>
                    <VStack>
                      <Heading as="h5" fontSize="xs" letterSpacing="wider">
                        DESCRIPTION
                      </Heading>
                      <EditableCell
                        name={`payloadModel.${index}.data_element_description`}
                        value={d.data_element_description}
                        row={{ index: index } as Row}
                        column={{ id: 'data_element_description' } as any}
                        updateData={onUpdateData}
                        type="textarea"
                        isInvalid={
                          !!(
                            errors.payloadModel &&
                            (errors.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[])[index]
                              ?.data_element_description
                          ) &&
                          !!(
                            touched.payloadModel &&
                            (touched.payloadModel as unknown as DataProductSelectPagePayloadElementTagsModel[])[index]
                              ?.data_element_description
                          )
                        }
                      />
                    </VStack>
                  </Td>
                </Tr>
              </Fragment>
            ))}
          </Tbody>
        </Table>
        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
            Create New
          </Button>
        </Box>
      </VStack>
    );
  }
};

export default DataProductSelectedPayloadElementTag;
