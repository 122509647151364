import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC, FunctionComponent, createElement, useEffect, useMemo, useState } from 'react';
import { FaUnlockAlt } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { defaultErrorMessage } from '../../../../app/constants';
import { AppAccess } from '../../../../app/constants/appAccesses';
import env from '../../../../app/constants/env';
import { buildSchema } from '../../../../app/helpers/formSchemabuilder';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useIsUserHasRequiredAppAccess } from '../../../../app/hooks/useIsUserHasRequiredRoles';
import {
  useGetRhPortfolioSelectedDetailsByRhpidQuery,
  useGetRhPortfolioSelectedReportsByRhpidQuery,
  useGetRhPortfolioSelectedTagsByRhpidQuery,
  useUpdateReportPortfolioMutation,
} from '../../../../app/services/dme/api/reportHub';
import {
  ReportHubSelectedReportsTabModel,
  UpdateReportHubPortfolioModel,
} from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';
import AppAuth from '../../../../features/AppAuth';
import ReportHubRequestAccess from '../../RequestAccess';
import {
  EditComponentProps,
  PortfolioDetailsFormSchema,
  PortfolioReportsFormSchema,
  PortfolioTagsFormSchema,
} from '../../helpers';
import ReportHubExplorePortfoliosSelectedEditDetails from './Edit/Details';
import ReportHubExplorePortfoliosSelectedEditReports from './Edit/Reports';
import ReportHubExplorePortfoliosSelectedEditTags from './Edit/Tags';
import ReportHubExplorePortfoliosSelectedViewDetails from './View/Details';
import ReportHubExplorePortfoliosSelectedViewDiscussion from './View/Discussion';
import ReportHubExplorePortfoliosSelectedViewReports from './View/Reports';
import ReportHubExplorePortfoliosSelectedViewRoles from './View/Roles';
import ReportHubExplorePortfoliosSelectedViewTags from './View/Tags';
import ReportHubExplorePortfoliosSelectedViewUsers from './View/Users';

type Props = {};

const tabsEdit: {
  label: string;
  path: string;
  component: FunctionComponent<EditComponentProps>;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: ReportHubExplorePortfoliosSelectedEditDetails,
  },
  {
    label: 'Reports',
    path: 'reports',
    component: ReportHubExplorePortfoliosSelectedEditReports,
  },
  {
    label: 'Tags',
    path: 'tags',
    component: ReportHubExplorePortfoliosSelectedEditTags,
  },
];

const ReportHubExplorePortfoliosSelected: FC<Props> = props => {
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const [isEdit, setIsEdit] = useState(false);

  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const rhpId: number = parseInt(params?.rhpId || '0') || 0;
  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const { data, isLoading, isFetching } = useGetRhPortfolioSelectedDetailsByRhpidQuery(
    { userId, rhpId },
    { skip: userId === 0 || rhpId === 0 },
  );

  //>>> Edit specific
  const reportDetails = useGetRhPortfolioSelectedReportsByRhpidQuery(
    { userId, rhpId },
    { skip: rhpId === 0 && !isEdit },
  );
  const tagsDetails = useGetRhPortfolioSelectedTagsByRhpidQuery({ userId, rhpId }, { skip: rhpId === 0 && !isEdit });
  const [updatePortfolio, updatePortfolioDetails] = useUpdateReportPortfolioMutation();
  const form = useFormik<UpdateReportHubPortfolioModel>({
    enableReinitialize: true,
    validationSchema: PortfolioDetailsFormSchema({
      reports: PortfolioReportsFormSchema,
      tags: PortfolioTagsFormSchema,
      rh_report_portfolio_id: buildSchema({ type: 'id', label: 'Report Portfolio' }),
    }),
    initialValues: {
      rh_report_portfolio_id: data?.rh_report_portfolio_id ?? 0,
      report_portfolio_name: data?.report_portfolio_name ?? '',
      report_portfolio_desc: data?.report_portfolio_desc ?? '',
      ref_domain_id: data?.ref_domain_id ?? 0,
      report_portfolio_developer_user_id: data?.developer_user_id ?? 0,
      report_portfolio_link: data?.report_portfolio_link ?? '',
      report_portfolio_embed_link: data?.report_portfolio_embed_link ?? '',
      ref_report_portfolio_type_id: data?.ref_report_portfolio_type_id ?? 0,
      pii_present_flag: data?.pii_present_flag ?? false,
      phi_present_flag: data?.phi_present_flag ?? false,
      rh_report_portfolio_state_id: data?.rh_report_portfolio_state_id ?? env.db.portfolioStateOperationalId,
      reports:
        (reportDetails.data ?? [])
          .reduce(
            (arr, currVal) => {
              const index = arr.findIndex(s => s.rh_report_id === currVal.rh_report_id);

              // don't push when ref_tag_id=null
              if (index > -1) {
                currVal.ref_tag_id && arr[index].ref_tag_ids.push(currVal.ref_tag_id);
              } else {
                arr.push({ ...currVal, ref_tag_ids: currVal.ref_tag_id ? [currVal.ref_tag_id] : [] });
              }
              return arr;
            },
            [] as (ReportHubSelectedReportsTabModel & { ref_tag_ids: number[] })[],
          )
          .map(r => ({
            report_name: r.report_name,
            report_desc: r.report_desc,
            rh_report_state_id: r.rh_report_state_id,
            report_embed_link: r.report_embed_link,
            report_developer_user_id: r.report_developer_user_id,
            tags: r.ref_tag_ids.map(t => ({ ref_tag_id: t, notes: '' })),
          })) ?? [],
      tags: tagsDetails.data?.map(t => ({ ref_tag_id: t.ref_tag_id, notes: t.notes })) ?? [],
    },
    onSubmit: (values, form) => {
      updatePortfolio(values)
        .unwrap()
        .then(() => {
          toast({ description: 'Portfolio successfully updated', status: 'success' });
          form.resetForm();
        })
        .catch(() => toast({ description: defaultErrorMessage, status: 'error' }));
    },
  });
  const tabErrors: boolean[] = useMemo(() => {
    const { errors, touched } = form;

    const hasErrorDetails = !!(
      (errors.rh_report_portfolio_id && touched.rh_report_portfolio_id) ||
      (errors.report_portfolio_name && touched.report_portfolio_name) ||
      (errors.report_portfolio_desc && touched.report_portfolio_desc) ||
      (errors.ref_domain_id && touched.ref_domain_id) ||
      (errors.report_portfolio_developer_user_id && touched.report_portfolio_developer_user_id) ||
      (errors.report_portfolio_link && touched.report_portfolio_link) ||
      (errors.report_portfolio_embed_link && touched.report_portfolio_embed_link) ||
      (errors.ref_report_portfolio_type_id && touched.ref_report_portfolio_type_id) ||
      (errors.pii_present_flag && touched.pii_present_flag) ||
      (errors.phi_present_flag && touched.phi_present_flag) ||
      (errors.rh_report_portfolio_state_id && touched.rh_report_portfolio_state_id)
    );
    const hasErrorReports = !!errors.reports && !!touched.reports;
    const hasErrorTags = !!errors.tags && !!touched.tags;
    return [hasErrorDetails, hasErrorReports, hasErrorTags];
  }, [form]);
  //<<< Edit specific

  const hasWriteAccess = useIsUserHasRequiredAppAccess(AppAccess.ExploreReportPortfoliosWrite);
  const tabsView: {
    label: string;
    path: string;
    component: FunctionComponent;
  }[] = [
    {
      label: 'Details',
      path: 'details',
      component: ReportHubExplorePortfoliosSelectedViewDetails,
    },
    {
      label: 'Reports',
      path: 'reports',
      component: ReportHubExplorePortfoliosSelectedViewReports,
    },
    {
      label: 'Tags',
      path: 'tags',
      component: ReportHubExplorePortfoliosSelectedViewTags,
    },
    ...(hasWriteAccess
      ? [
          {
            label: 'Roles',
            path: 'roles',
            component: ReportHubExplorePortfoliosSelectedViewRoles,
          },
          {
            label: 'Users',
            path: 'users',
            component: ReportHubExplorePortfoliosSelectedViewUsers,
          },
        ]
      : []),
    {
      label: 'Discussion',
      path: 'discussion',
      component: ReportHubExplorePortfoliosSelectedViewDiscussion,
    },
  ];

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabsView[tabIndex].path) {
      navigate('./../' + tabsView[tabIndex].path + location.search);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabsView.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });

    const isEdit = !!(location.state as { isEdit?: boolean })?.isEdit;
    if (isEdit) setIsEdit(isEdit);
  }, [location]);

  return (
    <VStack w="100%">
      <HStack>
        <Heading size="md">
          {isLoading || isFetching ? <Skeleton height="18px" w="56" mb="1.5" /> : data?.report_portfolio_name}
        </Heading>
        {isEdit ? (
          <AppAuth requiredAppAccess={AppAccess.ExploreReportPortfoliosWrite}>
            <Button
              size="sm"
              colorScheme="brand.main"
              isLoading={updatePortfolioDetails.isLoading}
              onClick={() => form.handleSubmit()}
            >
              Save
            </Button>
            <Button size="sm" isDisabled={updatePortfolioDetails.isLoading} onClick={() => setIsEdit(false)}>
              Cancel
            </Button>
          </AppAuth>
        ) : (
          <>
            <ReportHubRequestAccess
              portfolio={data}
              triggerElement={
                <Button size="sm" variant="outline" colorScheme="brand.main" leftIcon={<FaUnlockAlt />}>
                  Request Access
                </Button>
              }
            />
            <AppAuth requiredAppAccess={AppAccess.ExploreReportPortfoliosWrite}>
              <Button
                size="sm"
                colorScheme="brand.main"
                onClick={() => {
                  setIsEdit(true);
                  form.resetForm();
                  if (tabsView.length - 1 === tabIndex) {
                    setTabIndex(tabsView.length - 2);
                  }
                }}
              >
                Edit
              </Button>
            </AppAuth>
          </>
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && !isEdit && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabsView.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>
              {data && !isLoading && !isFetching ? (
                tabsView[tabIndex] && createElement(tabsView[tabIndex].component)
              ) : (
                <>Loading...</>
              )}
            </Box>
          </TabPanels>
        </Tabs>
      )}

      {tabIndex !== undefined && isEdit && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabsEdit.map((m, i) => (
              <Tab key={i}>
                {m.label} {tabErrors[i] && <Icon color="brand.error" ml={2} as={WarningIcon} />}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>
              {data && !isLoading && !isFetching ? (
                tabsEdit[tabIndex] && createElement(tabsEdit[tabIndex].component, { form })
              ) : (
                <>Loading...</>
              )}
            </Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelected;
