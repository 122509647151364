import { Box, Divider, Heading, Skeleton, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { FunctionComponent, createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetRhMyAccessSelectedDetailsByIdQuery } from '../../../../app/services/dme/api/reportHub';
import { useAppSelector } from '../../../../app/state/hooks';
import ReportHubMyAccessSelectedDetails from './Details';
import ReportHubMyAccessSelectedStatus from './Status';

const tabs: {
  label: string;
  path: string;
  component: FunctionComponent;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: ReportHubMyAccessSelectedDetails,
  },
  {
    label: 'Status',
    path: 'status',
    component: ReportHubMyAccessSelectedStatus,
  },
];

const ReportHubMyAccessSelected = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();

  const { logonUser } = useAppSelector(s => s.user);
  const requirestId: number = parseInt(params?.requirestId || '0') || 0;
  const { data, isLoading, isFetching } = useGetRhMyAccessSelectedDetailsByIdQuery(
    { ref_user_id: logonUser?.ref_user_id ?? 0, access_method: params.accessMethod ?? '', request_id: requirestId },
    {
      skip: requirestId === 0 || !params.accessMethod || !logonUser?.ref_user_id,
    },
  );

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path + location.search);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  return (
    <VStack w="100%">
      <Heading size="md">
        {isLoading || isFetching ? <Skeleton height="18px" w="56" mb="1.5" /> : data?.report_portfolio_name}
      </Heading>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>{tabs[tabIndex] && createElement(tabs[tabIndex].component)}</Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default ReportHubMyAccessSelected;
