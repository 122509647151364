import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount } from '@azure/msal-react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { Link, Link as RouterLink } from 'react-router-dom';
import { loginRequest } from '../../app/services/auth/authConfig';
import { useRefetchInfoweaveListMutation } from '../../app/services/dme/api/infoweave';
import { useAppSelector } from '../../app/state/hooks';
import { persistor } from '../../app/state/store';
import { appColors } from '../../app/theme';
import Feedback from '../../features/Feedback';
import ReleaseNotesLatestVersion from './ReleaseNotesLatestVersion';

type IProps = {};

const Header: FC<IProps> = props => {
  const account = useAccount();
  const instance = globalThis.msalInstance;
  const { isShowMobileTab } = useAppSelector(s => s.app);
  const [userFullName, setUserFullName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [profilePic, setProfilePic] = useState('');

  const [refetchInfoweave] = useRefetchInfoweaveListMutation();

  const { logonUser } = useAppSelector(s => s.user);
  const isTest = process.env.REACT_APP_ENV === 'TEST';

  useEffect(() => {
    const { idTokenClaims } = account as any;
    if (idTokenClaims) {
      const upn = account?.username || (idTokenClaims.emails && idTokenClaims.emails[0]) || '';
      setUserEmail(upn);
    }
  }, [account]);

  useEffect(() => {
    if (
      logonUser &&
      logonUser?.first_name &&
      logonUser?.last_name &&
      logonUser?.first_name !== '' &&
      logonUser?.last_name !== ''
    ) {
      setUserFullName(`${logonUser.first_name} ${logonUser.last_name}`);
    } else {
      setUserFullName(logonUser?.email || '');
    }

    if (logonUser && logonUser.email) {
      const avatarUrl = `${process.env.REACT_APP_CONTAINER_URL}/${process.env.REACT_APP_CONTAINER_NAME}/${logonUser.email}.jpeg`;
      setProfilePic(avatarUrl);
      setUserEmail(logonUser.email);
    }
  }, [logonUser]);

  return (
    <>
      <Flex
        bg="header.bgColor"
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        w="100%"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottomWidth={isTest ? 3 : 0}
        borderBottomColor={isTest ? appColors.testEnvironmentIndicator : ''}
        borderStyle={'solid'}
        borderTopColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        hidden={isShowMobileTab}
        justifyContent="space-between"
        position="fixed"
        zIndex={3}
      >
        <HStack>
          <Heading color="white" size={{ base: 'sm', sm: 'md' }}>
            <Link to="/" onClick={() => refetchInfoweave()}>
              Data Mesh Experience
            </Link>
          </Heading>
        </HStack>
        <Stack justify={'flex-end'} alignItems="center" direction={'row'} spacing={3}>
          <HStack align="stretch" spacing={1} mr={2}>
            {/* <InputGroup>
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray" />} />
              <Input type="text" placeholder="Search" bgColor="white" />
            </InputGroup>
            <IconButton variant="link" aria-label="Notifications" icon={<FaBell />} color="white" /> */}
            {logonUser && <Feedback />}

            <Menu>
              <MenuButton as={IconButton} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
                <Icon as={FaEllipsisV} color="white" mt={1} />
              </MenuButton>
              <MenuList>
                <AuthenticatedTemplate>
                  <MenuGroup>
                    <MenuItem as={RouterLink} to="/release-notes">
                      <Text w="100%" textAlign="center">
                        Release Notes
                      </Text>
                    </MenuItem>
                  </MenuGroup>
                  <Box px="3">
                    <Divider />
                  </Box>
                  <ReleaseNotesLatestVersion />
                </AuthenticatedTemplate>
              </MenuList>
            </Menu>
          </HStack>
          <Menu>
            <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
              {profilePic ? (
                <Avatar size={'sm'} bg="gray.300" src={profilePic} />
              ) : (
                <Avatar size={'sm'} bg="gray.300" name={userFullName} />
              )}
            </MenuButton>

            <MenuList>
              <AuthenticatedTemplate>
                <MenuGroup title={userFullName.length > 0 ? userFullName : userEmail}>
                  <MenuDivider />

                  <MenuItem
                    onClick={() => {
                      instance.logoutRedirect({
                        postLogoutRedirectUri: '/',
                        account: instance.getActiveAccount(),
                      });
                      persistor.purge();
                      sessionStorage.clear();
                      localStorage.clear();
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </MenuGroup>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <MenuGroup>
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      instance.loginRedirect(loginRequest);
                    }}
                  >
                    Sign In
                  </MenuItem>
                </MenuGroup>
              </UnauthenticatedTemplate>
            </MenuList>
          </Menu>
        </Stack>
      </Flex>
    </>
  );
};

export default Header;
