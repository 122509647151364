import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CrumbModel = {
  label: string;
  path: string;
};

export type SiteBreadcrumbState = {
  crumbs: Array<CrumbModel>;
};

const initialState: SiteBreadcrumbState = { crumbs: [] };

export const siteBreadcrumbSlice = createSlice({
  name: 'siteBreadcrumb',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setBreadcrumb: (state, action: PayloadAction<Array<CrumbModel>>) => {
      state.crumbs = action.payload;
    },
    sliceBreadcrumb: (state, action: PayloadAction<{ start: number; end: number }>) => {
      const a = state.crumbs.slice(action.payload.start, action.payload.end);
      state.crumbs = a;
    },
    addBreadcrumb: (state, action: PayloadAction<{ index?: number; crumb: CrumbModel }>) => {
      const { index, crumb } = action.payload;
      const isAlreadyLastCrumb = state.crumbs[state.crumbs.length - 1]?.label === crumb.label;
      if (isAlreadyLastCrumb) return;
      if (index !== undefined && index >= 0) state.crumbs[index] = crumb;
      else state.crumbs = [...state.crumbs, action.payload.crumb];
    },

    clearBreadcrumb: state => {
      state.crumbs = [];
    },
  },
});

export const { addBreadcrumb, setBreadcrumb, clearBreadcrumb, sliceBreadcrumb } = siteBreadcrumbSlice.actions;
export const siteBreadcrumbSliceActions = siteBreadcrumbSlice.actions;
export default siteBreadcrumbSlice.reducer;
