import { Button, Divider, Heading, HStack, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FaUnlockAlt } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { addBreadcrumb } from '../../../app/helpers/navigationHelpers';
import {
  useGetMyRolesExploreRolesDetailQuery,
  useGetMyRolesExploreRolesQuery,
} from '../../../app/services/dme/api/myRoles';
import { MyRolesExploreRolesDetailEnvironmentModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import CustomTable from '../../../components/CustomTable';
import RequestAccessDialog from '../RequestAccess';

type Props = {};

const ExploreRolesDetail = () => {
  const location = useLocation();
  const { logonUser } = useAppSelector(s => s.user);
  const params = useParams();
  const refRoleId = parseInt(params.id || '0');
  const { data, isLoading, isFetching } = useGetMyRolesExploreRolesDetailQuery(refRoleId, { skip: refRoleId == 0 });

  const getRoleList = useGetMyRolesExploreRolesQuery({
    page_number: 1,
    page_size: 9999,
    search_string: '',
    sort_column: 'role_name',
    sort_order: 'asc',
  });

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, []);
  const Header = [
    {
      Header: 'Name',
      accessor: 'developer_environment_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Description',
      accessor: 'developer_environment_desc',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Platform',
      accessor: 'cloud_platform',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Service',
      accessor: 'cloud_service',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Properties',
      accessor: 'environment_properties',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Access Level',
      accessor: 'access_level',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Last Modified',
      Cell: ({ row: { original } }: { row: { original: MyRolesExploreRolesDetailEnvironmentModel } }) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}</>;
      },
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      {isLoading || isFetching ? <>Loading</> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return (
      <VStack w="full">
        <HStack>
          <Heading size="md">{data && data.role.role_name}</Heading>
          {getRoleList.data && logonUser && (
            <RequestAccessDialog
              ref_role_list={getRoleList.data.data}
              ref_role_id={refRoleId.toString()}
              triggerElement={
                <Button variant="outline" colorScheme="brand.main" leftIcon={<FaUnlockAlt />}>
                  Request Access
                </Button>
              }
            />
          )}
        </HStack>
        <Divider />
        <HStack>
          <CustomTable
            variant="table"
            headers={Header}
            isLoading={isLoading}
            isFetching={isFetching}
            data={data?.environments || []}
            pageCount={0}
            pageSize={data?.environments ? data?.environments.length : 0}
            totalRecords={data?.environments ? data?.environments.length : 0}
            search=""
            onPageChange={index => {}}
            onPageSizeChange={size => {}}
            onPageSearch={search => {}}
            onSort={() => {}}
            hidePagination={true}
            tableSort={true}
            showNoRecords
          />
        </HStack>
      </VStack>
    );
  }
};

export default ExploreRolesDetail;
