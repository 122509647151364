import React, { useEffect, useState } from 'react';
import {
  useLazyGetPlatformServicesAdminApprovalListQuery,
  usePutPlatformServiceRequestAccessMutation,
  usePutPlatformServiceAccessAdminApprovalClaimFlagMutation,
} from '../../../app/services/dme/api/platformServices';
import {
  Box,
  HStack,
  Stack,
  Text,
  Button,
  IconButton,
  Tooltip,
  Collapse,
  Grid,
  GridItem,
  VStack,
  Heading,
  Divider,
  Alert,
  AlertIcon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Textarea,
  ModalFooter,
  FormErrorMessage,
} from '@chakra-ui/react';
import { GoCheck, GoX } from 'react-icons/go';
import { AiOutlineStop } from 'react-icons/ai';
import { IoExpand, IoContract } from 'react-icons/io5';
import { useAppSelector } from '../../../app/state/hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppAuth from '../../../features/AppAuth';
import { AppAccess } from '../../../app/constants/appAccesses';
import { ValueOf } from '../../../app/helpers/utilities';
import { AccessAdminApprovalModel, RefUserModel, PlatformServicesAccessAdminApprovalFirstModel, PlatformServicesAccessAdminApprovalSecondModel, PlatformServicesAccessAdminApprovalModel } from '../../../app/services/dme/api/types';
import { usePubNub } from 'pubnub-react';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import PubNubConstants from '../../../features/PubNubWrapper/constants';

type Props = {
  data: AccessAdminApprovalModel;
  refUsers: RefUserModel[];
  setData: (field: keyof AccessAdminApprovalModel, data: ValueOf<AccessAdminApprovalModel>) => void;
};

const FormSchema = Yup.object().shape({
  deny_reason: Yup.string().label('Reason').required().max(1500, 'Text exceed the character limit of 1500'),
});
const _channelName = 'platform_services_request';
const openDelay = 1000;

const AccessApprovalPlatformService = (props: Props) => {
  const { data, refUsers, setData } = props;
  const { sixth_dataset_model } = data as { sixth_dataset_model: PlatformServicesAccessAdminApprovalModel };
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
  const [alertMessage, setAlertMessage] = useState('');
  const [success, setSuccess] = useState<number[]>([]);

  const pubnub = usePubNub();
  const { messageEvent } = useAppSelector(s => s.pubNub);
  const { logonUser } = useAppSelector(s => s.user);
  const userWriteAccess = useIsUserHasRequiredAppAccess('adminaccessapproval.write');

  const [putAsync, putDetails] = usePutPlatformServiceRequestAccessMutation();
  const [putClaimedFlagAsync, putClaimedFlagDetails] = usePutPlatformServiceAccessAdminApprovalClaimFlagMutation();
  const { handleSubmit, errors, touched, handleChange, values, setValues } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: { 
      id: -1, 
      deny_reason: '' 
    },
    onSubmit: (values, form) => {
      putAsync({ 
        ps_request_id: values.id, 
        approved_flag: false, 
        denied_flag: true, 
        denied_notes: values.deny_reason.trim() 
      });
      setAlertMessage('');
      onClose();
      form.resetForm();
    },
  });

  useEffect(() => {
    pubnub.subscribe({
      channels: [_channelName],
    });

    return () => {
      pubnub.unsubscribe({
        channels: [_channelName],
      });
    };
  }, [pubnub]);

  useEffect(() => {
    if (messageEvent?.channel === _channelName) {
      const { message } = messageEvent;
      setData(
        'sixth_dataset_model',
        {
          ...sixth_dataset_model,
          first_dataset_model: sixth_dataset_model?.first_dataset_model.map(item =>
            item.ps_request_id === message.data.ps_request_id ? message.data : item
          ),
        } as PlatformServicesAccessAdminApprovalModel
      );
    }
  }, [messageEvent]);


  useEffect(() => {
    if (putDetails.isSuccess && putDetails.originalArgs?.ps_request_id) {
      setSuccess(s => [...s, putDetails.originalArgs!.ps_request_id]);
      setTimeout(() => {
        setData(
          'sixth_dataset_model',
          {
            ...sixth_dataset_model,
            first_dataset_model: sixth_dataset_model?.first_dataset_model.filter(
              i => i.ps_request_id !== putDetails.originalArgs!.ps_request_id
            ),
          } as PlatformServicesAccessAdminApprovalModel
        );
      }, 3000);
    }
    if (putDetails.error) {
      setAlertMessage('There was an error processing your request, please try again later.');
    }
  }, [putDetails]);

  const handleClaim = (data: PlatformServicesAccessAdminApprovalFirstModel, claim_flag: boolean) => async () => {
    if (logonUser) {
      const newData: PlatformServicesAccessAdminApprovalFirstModel = {
        ...data,
        claimed_flag: claim_flag,
        claimed_user_id: claim_flag ? logonUser.ref_user_id : 0,
      };
      try {
        await pubnub.publish({
          channel: _channelName,
          message: {
            data: newData,
            type: PubNubConstants.MessageEvent.Type.CLAIM,
          },
        });

        await putClaimedFlagAsync({
          ps_request_id: data.ps_request_id,
          claimed_flag: claim_flag,
          claim_user_id: claim_flag ? logonUser.ref_user_id : 0,
        });

        setData(
          'sixth_dataset_model',
          {
            ...sixth_dataset_model,
            first_dataset_model: sixth_dataset_model?.first_dataset_model.map(item =>
              item.ps_request_id === data.ps_request_id ? newData : item
            ),
          } as PlatformServicesAccessAdminApprovalModel
        );
      } catch (ex) {
        await pubnub.publish({
          channel: _channelName,
          message: {
            data: {
              ...newData,
              claimed_flag: !claim_flag,
              claim_user_id: !claim_flag ? logonUser.ref_user_id : 0,
            },
            type: PubNubConstants.MessageEvent.Type.CLAIM,
          },
        });
      }
    }
  };

  const handleApprove = (data: PlatformServicesAccessAdminApprovalFirstModel) => () => {
    putAsync({ 
      ps_request_id: data.ps_request_id, 
      approved_flag: true, 
      denied_flag: false 
    });
    setAlertMessage('');
  };

  const handleDeny = (data: PlatformServicesAccessAdminApprovalFirstModel) => () => {
    setValues({ 
      id: data.ps_request_id, 
      deny_reason: '' 
    });
    onOpen();
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const isSuccess = (data: PlatformServicesAccessAdminApprovalFirstModel) => {
    return success.some(id => id === data.ps_request_id);
  };

  if (!sixth_dataset_model) return <></>;

  const combinedData = sixth_dataset_model.first_dataset_model.map(firstModel => {
    const questionAnswers = sixth_dataset_model.second_dataset_model.filter(secondModel => secondModel.ps_request_id === firstModel.ps_request_id);
    return {
      ...firstModel,
      questionAnswers,
    };
  });

  return (
    <>
      {alertMessage && (
        <Alert status={putDetails.isSuccess ? 'success' : 'error'}>
          <AlertIcon />
          {alertMessage}
        </Alert>
      )}
      {logonUser &&
        combinedData.map((d, i) => {
          const userHasClaimed = combinedData.some(s => s.claimed_user_id === logonUser.ref_user_id);
          const claimedBy = refUsers.find(f => f.ref_user_id === d.claimed_user_id);
          const claimedByFullName = claimedBy && (`${claimedBy.first_name || ''} ${claimedBy.last_name || ''}`.trim() || claimedBy.email);
          return (
            <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
              <HStack alignItems="start">
                <Box>
                  <HStack flexShrink={0} mr="2">
                  {userWriteAccess && (
                      <HStack w="112px">
                        {d.claimed_flag && d.claimed_user_id === logonUser.ref_user_id ? (
                          isSuccess(d) ? (
                            <Alert status="success" paddingY={1} paddingX={2}>
                              <AlertIcon />
                              Success!
                            </Alert>
                          ) : (
                            <>
                              <Tooltip label="Approve" openDelay={openDelay}>
                                <IconButton 
                                  aria-label="Approve" 
                                  size="sm" 
                                  colorScheme="brand.main" 
                                  icon={<GoCheck />} 
                                  onClick={handleApprove(d)} 
                                  isLoading={
                                    putDetails.isLoading &&
                                    putDetails.originalArgs?.approved_flag &&
                                    d.ps_request_id ===
                                      putDetails.originalArgs?.ps_request_id
                                  }
                                  isDisabled={putDetails.isLoading || isSuccess(d)}
                                  />
                              </Tooltip>
                              <Tooltip label="Deny" openDelay={openDelay}>
                                <IconButton 
                                  aria-label="Deny"
                                  size="sm" 
                                  bg="brand.error"
                                  colorScheme="brand.error" 
                                  icon={<GoX />} 
                                  onClick={handleDeny(d)} 
                                  isLoading={
                                    putDetails.isLoading &&
                                    putDetails.originalArgs?.denied_flag &&
                                    d.ps_request_id ===
                                      putDetails.originalArgs?.ps_request_id
                                  }
                                  isDisabled={putDetails.isLoading || isSuccess(d)}
                                  />
                              </Tooltip>
                            </>
                          )
                        ) : (
                          <Tooltip label={claimedByFullName}>
                          <AppAuth requiredAppAccess={AppAccess.AdminAccessApprovalWrite}>
                            {isAuthorized => (
                              <Button 
                                size="sm" 
                                colorScheme={d.claimed_flag ? 'orange.800' : 'brand.main'} 
                                bg={d.claimed_flag ? 'orange.400' : 'brand.main.default'} 
                                onClick={d.claimed_flag ? () => {} : handleClaim(d, true)}
                                isDisabled={
                                  (userHasClaimed && !d.claimed_flag) ||
                                  putDetails.isLoading ||
                                  putClaimedFlagDetails.isLoading ||
                                  !isAuthorized
                                  }
                                >
                                {d.claimed_flag ? 'Claimed' : 'Claim'}
                              </Button>
                            )}
                            </AppAuth>
                          </Tooltip>
                        )}
                        {d.claimed_flag && !isSuccess(d) && (
                          <AppAuth requiredAppAccess={AppAccess.AdminAccessApprovalWrite}>
                            {isAuthorized => (
                              <Tooltip label="Unclaim" openDelay={openDelay}>
                                <IconButton 
                                  aria-label="Unclaim" 
                                  size="sm" 
                                  icon={<AiOutlineStop />} 
                                  onClick={handleClaim(d, false)}
                                  isDisabled={!isAuthorized || putClaimedFlagDetails.isLoading}
                                  />
                              </Tooltip>
                            )}
                          </AppAuth>
                        )}
                    </HStack>
                    )}  

                    <IconButton 
                      aria-label={expanded[d.ps_request_id] ? 'expand card' : 'contract card'} 
                      size="sm" icon={expanded[d.ps_request_id] ? <IoContract /> : <IoExpand />} 
                      onClick={() => 
                        setExpanded(e => ({ 
                          ...e, [d.ps_request_id]: !e[d.ps_request_id] 
                          }))
                        } 
                      />
                  </HStack>
                </Box>
                
                <Stack w="100%">
                  <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                    <Field title="Request ID" value={d.ps_request_id} />
                    <Field title="Category" value={d.ps_category_name} />
                    <Field title="Requestor ID" value={d.request_created_ref_user_id} />
                    <Field title="User" value={`${d.first_name} ${d.last_name}`} />
                  </Grid>
                  <Collapse in={expanded[d.ps_request_id]}>
                    <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                      {d.questionAnswers.map((qa, idx) => (
                        <Field key={idx} title={qa.question_instruction_text} value={qa.reference_table_name ? `${qa.pk_name}` : qa.user_answer_option || qa.user_answer_text_box} />
                      ))}
                    </Grid>
                  </Collapse>
                </Stack>
              </HStack>
            </Box>
          );
        })}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Denial Reason</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl isInvalid={!!errors.deny_reason && touched.deny_reason}>
                <Textarea
                  id="deny_reason"
                  placeholder="Enter Reason"
                  name="deny_reason"
                  onChange={handleChange}
                  value={values.deny_reason}
                  maxLength={1500}
                />
                <FormErrorMessage>{errors.deny_reason}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose} mr={3} ml="auto">
                Cancel
              </Button>
              <Button type="submit" colorScheme="brand.main">
                Deny
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

type FieldProps = {
  title: string;
  value: any;
  w?: string | number;
  maxW?: string | number;
  colSpan?: number;
  valueWordBreak?: 'break-all';
};

function Field({ title, value, w, maxW, colSpan, valueWordBreak }: FieldProps) {
  return (
    <GridItem colSpan={colSpan}>
      <Stack w={w} maxW={maxW} spacing={0}>
        <Text fontWeight="bold">{title}</Text>
        <Text wordBreak={valueWordBreak}>{value}</Text>
      </Stack>
    </GridItem>
  );
}

export default AccessApprovalPlatformService;