import {
    Alert,
    AlertIcon,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { channelNameGoEdsMutation } from '..';
import { stringOrHtmlIsEmpty } from '../../../../../app/helpers/stringHelper';
import { debounceLeading } from '../../../../../app/helpers/utilities';
import {
    useLazyGetGoEdsPostByIdQuery,
    useUpdateGoEdsPostMutation,
} from '../../../../../app/services/dme/api/governanceOfficeEdsPost';
import { GoEdsDetailsPageDiscussionModel } from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import PubNubConstants from '../../../../../features/PubNubWrapper/constants';
import RichTextEditor from '../../../../../features/RichTextEditor';

const FormSchema = Yup.object().shape({
  post_subject: Yup.string().label('Subject').trim().required().max(1000, 'Text exceed the character limit of 1000'),
  post_message: Yup.string()
    .label('Message')
    .required()
    .test((str, { createError }) => {
      return stringOrHtmlIsEmpty(str)
        ? createError({ message: 'Message is a required field', path: 'post_message' })
        : true;
    })
    .max(8000, 'Text exceed the character limit of 8000'),
});

type Props = {
  post: GoEdsDetailsPageDiscussionModel;
};

const GoEdsPostUpdateButton = ({ post }: Props) => {
  const params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [putAsync, putDetail] = useUpdateGoEdsPostMutation();
  const [getAsync, getDetail] = useLazyGetGoEdsPostByIdQuery();
  const pubnub = usePubNub();
  const { logonUser } = useAppSelector(s => s.user);

  const [alertMessage, setAlertMessage] = useState('');
  const [errorPostMessage, setErrorPostMessage] = useState<string>('');

  const goEdsId: number = parseInt(params.goEdsId || '0');

  const setNoPasteImageAlert = debounceLeading((hasImage: boolean) => {
    // added debounceLeading as error will not show on pasting in empty message
    if (hasImage) setErrorPostMessage('Pasting image is not allowed.');
    else setErrorPostMessage('');
  }, 100);

  const { handleSubmit, errors, touched, handleChange, values, resetForm, setFieldValue, setFieldTouched } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      post_subject: post.post_subject,
      post_message: post.post_message,
    },
    onSubmit: ({ post_subject, post_message }) => {
      setErrorPostMessage('');
      if (logonUser) {
        updatePost(post_subject, post_message);
      }
    },
  });

  const updatePost = (post_subject: string, post_message: string) => {
    if (post) putAsync({ go_eds_post_id: post.go_eds_post_id, post_subject, post_message });
  };

  useEffect(() => {
    if (!isOpen) clearModal();
  }, [isOpen]);

  useEffect(() => {
    const handlePutDetailChanges = async () => {
      const { isSuccess, isError } = putDetail;

      if (isSuccess) {
        setAlertMessage('Post successfully updated.');
        const data = await getAsync(post.go_eds_post_id).unwrap();
        pubnub.publish({
          channel: channelNameGoEdsMutation,
          message: {
            goEdsId,
            data: data[0],
            type: PubNubConstants.MessageEvent.Type.UPDATE,
          },
        });
        setTimeout(() => {
          clearModal();
        }, 3000);
      } else if (isError) {
        setAlertMessage('There was an error processing your request, please try again later.');
      } else {
        setAlertMessage('');
      }
    };

    handlePutDetailChanges();
  }, [putDetail]);

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
    setNoPasteImageAlert('');
    onClose();
  };

  return (
    <>
      <Button
        leftIcon={<BsPencil />}
        variant="link"
        size="xs"
        _hover={{ textDecoration: 'none' }}
        sx={{ span: { marginRight: 1 } }}
        isDisabled={putDetail.isLoading}
        isLoading={putDetail.isLoading}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Post</ModalHeader>
          <ModalCloseButton isDisabled={putDetail.isLoading} />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={5}>
                <FormControl isInvalid={!!errors.post_subject && touched.post_subject}>
                  <FormLabel htmlFor="post_subject">Subject</FormLabel>
                  <Input
                    id="post_subject"
                    placeholder="Enter Subject"
                    name="post_subject"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('post_subject', true)}
                    value={values.post_subject}
                    maxLength={1000}
                  />
                  <FormErrorMessage>{errors.post_subject}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errorPostMessage || (!!errors.post_message && touched.post_message)}>
                  <FormLabel htmlFor="post_message">Message</FormLabel>
                  <RichTextEditor
                    value={values.post_message}
                    onChange={(value, { hasImage }) => {
                      setFieldValue('post_message', value);
                      setNoPasteImageAlert(hasImage);
                    }}
                    onBlur={() => setFieldTouched('post_message', true)}
                    removeImages
                  />
                  <FormErrorMessage>{errorPostMessage || errors.post_message}</FormErrorMessage>
                </FormControl>

                {alertMessage && (
                  <Alert status={putDetail.isSuccess ? 'success' : 'error'}>
                    <AlertIcon />
                    {alertMessage}
                  </Alert>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter gap={3}>
              <Button onClick={onClose} ml="auto" isDisabled={putDetail.isLoading || getDetail.isLoading}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={putDetail.isLoading || getDetail.isLoading}
                isDisabled={
                  stringOrHtmlIsEmpty(values.post_subject) ||
                  stringOrHtmlIsEmpty(values.post_message) ||
                  (alertMessage !== '' && putDetail.isSuccess)
                }
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GoEdsPostUpdateButton;
