// TODO add other env variables here
// TODO add yup validation

let portfolioStateOperationalId = parseInt(process.env.REACT_APP_PORTFOLIO_STATE_OPERATIONAL_ID ?? '');
portfolioStateOperationalId = !!portfolioStateOperationalId ? portfolioStateOperationalId : 0; // lets default to zero so it will error and we can fix it

const env = {
  db: {
    portfolioStateOperationalId,
    goEds: {
      status: {
        developingId: 1,
        submittedId: 2,
        discussionId: 3,
        discussionName: 'Discussion',
      },
      vote: {
        yesId: 1,
        noId: 2,
        discussionId: 3,
      },
    },
  },
};

export default env;
