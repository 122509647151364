import { dmeApi } from '.';
import {
  AddPlatformServicesRequestModel,
  // AdminAccessPlatformServiceCreate,
  PlatformServicesExploreModel,
  PostPlatformServiceStatusDto,
  PlatformServicesMyRequestsModel,
  PlatformServicesRequestSelectedStatusModel,
  PlatformServicesRequestSelectedDetailsModel,
  PlatformServicesRefPlatformPortfolioModel,
  PlatformServicesRefCategoryModel,
  PlatformServicesExploreFilterModel,
  PlatformServicesCreateRequestQAListModel,
  AddStatusPlatformServiceModel,
  AddPlatformServiceModel,
  AddPlatformServicesRequestResponseModel,
  PlatformServicesAccessAdminApprovalModel,
  UpdatePlatformServicesRequestApprovalModel,
  UpdatePlatformServicesRequestClaimModel,
} from './types';
import { createFormData } from '../../../helpers/formHelper';

const baseUrl = 'platformservices';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: build => ({

    // SP: p_ps_platform_services_explore_filter_applied
    postPlatformServicesExploreList: build.mutation< PlatformServicesExploreModel[], PlatformServicesExploreFilterModel>({
      query: params => ({
        url: baseUrl + '/explore/filter',
        method: 'POST',
        body: params,
      }),
    }),

    postPlatformServicesStatus: build.mutation<void, PostPlatformServiceStatusDto>({
      query: body => ({
        url: baseUrl + '/status',
        method: 'POST',
        body: createFormData(body),
      }),
      invalidatesTags: ['PlatformServicesRequestList'],
    }),

    getPlatformServicesMyRequests: build.query<PlatformServicesMyRequestsModel[], void>({
      query: () => baseUrl + '/my-requests',
    }),

    getPlatformServicesRequestSelectedDetails: build.query<PlatformServicesRequestSelectedDetailsModel, { psRequestId: number }>({
      query: ({ psRequestId }) => baseUrl + `/selected/details/${psRequestId}`,
      transformResponse: (res: PlatformServicesRequestSelectedDetailsModel[]) => res[0],
    }),

    getPlatformServicesRequestSelectedStatus: build.query<PlatformServicesRequestSelectedStatusModel[], { psRequestId: number }>({
      query: ({ psRequestId }) => baseUrl + `/selected/status/${psRequestId}`,
    }),

    // SP: p_ref_platform_portfolio
    getPlatformServicesRefPlatformPortfolioList: build.query<PlatformServicesRefPlatformPortfolioModel[], void>({
      query: () => baseUrl + 'refplatformportfolio',
    }),
    
    // SP: p_ref_platform_services_category
    getPlatformServicesRefPlatformServicesCategoryList: build.query<PlatformServicesRefCategoryModel[], void>({
      query: () => baseUrl + 'refcategory',
    }),

    // SP: 
    getRequestSelectedQa: build.query<PlatformServicesCreateRequestQAListModel, { psRequestId: number }>({
      query: ({ psRequestId }) => baseUrl + `/selected/question-and-answer/${psRequestId}`,
    }),

    getPlatformServicesRequestCreationQaList: build.query<PlatformServicesCreateRequestQAListModel, { psRefCategoryId: number }>({
      query: ({ psRefCategoryId }) =>  baseUrl + `/createrequestqalist?ps_ref_category_id=${psRefCategoryId}`,
    }),

    // addPlatformServicesRequest: build.mutation<{ ps_request_id: number }, AddPlatformServiceModel>({
    addPlatformServicesRequest: build.mutation<AddPlatformServicesRequestResponseModel, AddPlatformServiceModel>({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
    }),

    addStatusPlatformServicesRequest: build.mutation<
    { ps_ref_request_status_id: number },
    AddStatusPlatformServiceModel
    >({
      query: body => ({
        url: baseUrl + '/add-status',
        method: 'POST',
        body,
      }),
    }),

    // getAdminAccessPlatformServiceCreateList: build.query<AdminAccessPlatformServiceCreate[], void>({
    //   query: () => baseUrl + `/admin/approval`,
    //   providesTags: ['PlatformServicesRequestList'],
    // }),

    getPlatformServicesAdminApprovalList: build.query<PlatformServicesAccessAdminApprovalModel, number >({
      query: ref_user_id =>  baseUrl + `/adminapproval?ref_user_id=${ref_user_id}`,
      providesTags: ['PlatformServicesAdminApproval'],
    }),

    putPlatformServiceRequestAccess: build.mutation<void, UpdatePlatformServicesRequestApprovalModel>({
      query: params => ({
        url: baseUrl + '/approval',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['MyServiceRequest'],
    }),

    putPlatformServiceAccessAdminApprovalClaimFlag: build.mutation<void, UpdatePlatformServicesRequestClaimModel>({
      query: params => ({
        url: baseUrl + '/claim',
        method: 'PUT',
        body: params,
      }),
    }),



  }),

  overrideExisting: true,
});

export const {
  usePostPlatformServicesExploreListMutation,
  useAddPlatformServicesRequestMutation,
  usePostPlatformServicesStatusMutation,
  useAddStatusPlatformServicesRequestMutation,
  // useLazyGetAdminAccessPlatformServiceCreateListQuery,
  useGetPlatformServicesMyRequestsQuery,
  useGetPlatformServicesRequestSelectedStatusQuery,
  useGetPlatformServicesRequestSelectedDetailsQuery,
  useGetPlatformServicesRefPlatformPortfolioListQuery,
  useGetPlatformServicesRefPlatformServicesCategoryListQuery,
  useGetRequestSelectedQaQuery,
  useGetPlatformServicesRequestCreationQaListQuery,
  useGetPlatformServicesAdminApprovalListQuery,
  useLazyGetPlatformServicesAdminApprovalListQuery,
  usePutPlatformServiceRequestAccessMutation,
  usePutPlatformServiceAccessAdminApprovalClaimFlagMutation,
} = extendedApi;
