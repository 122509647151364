import { Box, Text, Divider, Heading, HStack, VStack, Skeleton, Stack } from '@chakra-ui/react';
import { useGetVwRefDomainListQuery } from '../../../app/services/dme/api/refDomain';
import CustomTable from '../../../components/CustomTable';
import { VwRefDomainModel } from '../../../app/services/dme/api/types';

type Props = {};

const ExploreDomain = (props: Props) => {
  const { data, isLoading, isFetching } = useGetVwRefDomainListQuery();
  const Header = () => [
    {
      Header: 'Domain',
      accessor: 'domain_name',
      styles: { whiteSpace: 'initial', wordBreak: 'break-word' },
    },
    {
      Header: 'Description',
      accessor: 'domain_desc',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: VwRefDomainModel } }) => {
        return (
          <Box maxW="800px">
            <Text noOfLines={3} title={original.domain_desc}>
              {original.domain_desc}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Owner',
      accessor: 'ref_user_id',
      styles: { whiteSpace: 'nowrap' },
      Cell: ({ row: { original } }: { row: { original: VwRefDomainModel } }) => {
        return <Text>{`${original.first_name} ${original.last_name}`}</Text>;
      },
    },
    {
      Header: 'Data Products',
      accessor: 'dp_data_product_count',
      styles: { textAlign: 'center' },
    },
  ];

  return (
    <VStack>
      <HStack>
        <Heading size="md">Explore Domain</Heading>
        <Text color="red.500" fontWeight="bold" pl="4"> **Domain pages will no longer be available by October 1st</Text>
      </HStack>
      <Divider />
      <HStack>
        {isLoading ? (
          <Stack mt={1}>
            {[...Array(8)].map((m, i) => (
              <Skeleton key={i} height="18px" />
            ))}
          </Stack>
        ) : (
          <CustomTable
            variant="table"
            headers={Header()}
            isLoading={isLoading}
            isFetching={isFetching}
            data={data || []}
            pageCount={0}
            pageSize={data ? data.length : 0}
            totalRecords={data ? data.length : 0}
            search=""
            onPageChange={index => {}}
            onPageSizeChange={size => {}}
            onPageSearch={search => {}}
            onSort={() => {}}
            hidePagination={true}
            tableSort={true}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default ExploreDomain;
