import { DynamicObject } from '../types/appType';

export type ValueOf<T> = T[keyof T];

export const debounce = (cb: Function, delay = 250) => {
  let timeout: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

export function debounceLeading(cb: Function, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return function (this: unknown, ...args: any) {
    if (!timer) {
      cb.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

export const ensureSafeDecodeUri = (value: any) => {
  try {
    return decodeURIComponent(value);
  } catch (error) {
    return value;
  }
};

export function getSearchParams(searchParams: URLSearchParams) {
  const params: DynamicObject = {};
  searchParams.forEach((val, key) => (params[key] = val));
  return params;
}
