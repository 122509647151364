import { dmeApi } from './index';
import {
  GetRefUserListAppAccessModel,
  GetRefUserListDataProductsModel,
  GetRefUserListDetailsModel,
  GetRefUserListRolesModel,
  GetUserListDto,
  PagedRefUserListModel,
  RefUserModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getUserById: builder.query<RefUserModel, number>({
      query: refUserId => `/user/${refUserId}`,
      providesTags: ['User'],
    }),

    getUserByEmail: builder.query<RefUserModel, string>({
      query: email => `/user/email/${email}`,
      providesTags: ['User'],
    }),

    getUserList: builder.query<PagedRefUserListModel, GetUserListDto>({
      query: params => ({
        url: `/user`,
        params,
      }),
      providesTags: ['UserList'],
    }),

    // vw_ref_user
    getRefUserList: builder.query<RefUserModel[], { disabled_flag?: boolean } | void>({
      query: params => ({
        url: `/user/list`,
        params: params ? params : undefined,
      }),
      providesTags: ['UserList'],
    }),

    // p_eo_onboard_user_list 
    getEoOnboardUserList: builder.query<RefUserModel[], void>({
      query: () => '/user/onboard',
      providesTags: ['UserList'],
    }),

    getRefUserListDetails: builder.query<GetRefUserListDetailsModel, number>({
      query: ref_user_id => `/user/details/ref_user_id/${ref_user_id}`,
      transformResponse: (response: GetRefUserListDetailsModel) => {
        return response;
      },
    }),

    getRefUserListRoles: builder.query<GetRefUserListRolesModel[], number>({
      query: ref_user_id => `/user/list/roles/${ref_user_id}`,
    }),

    getRefUserListAppAccess: builder.query<GetRefUserListAppAccessModel[], number>({
      query: ref_user_id => `/user/list/appaccess/${ref_user_id}`,
    }),

    getRefUserListDataProducts: builder.query<GetRefUserListDataProductsModel[], number>({
      query: ref_user_id => `/user/list/dataproducts/${ref_user_id}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetUserByEmailQuery,
  useLazyGetUserByEmailQuery,
  useGetUserListQuery,
  useGetEoOnboardUserListQuery,
  useGetRefUserListQuery,
  useLazyGetUserListQuery,
  useGetRefUserListDetailsQuery,
  useGetRefUserListRolesQuery,
  useGetRefUserListAppAccessQuery,
  useGetRefUserListDataProductsQuery,
} = extendedApi;
