import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppSize } from '../../../app/constants';
import { AppAccess } from '../../../app/constants/appAccesses';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import {
  useGetGoEdsMyRequestAssociateUserListQuery,
  useGetGoEdsMyRequestListQuery,
  useGetGoEdsRequestStatusListQuery,
} from '../../../app/services/dme/api/governanceOfficeEds';
import { GoEdsMyRequestModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader, DynamicObject } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import MyExternalDataSharingRequestsTableActionCell from './ActionCell';
import {
  MyExternalDataSharingRequestsHeader,
  MyExternalDataSharingRequestsInitialSortBy,
  goEdsSearchParams,
} from './utils';

const filterOrder = {
  status: 1,
  user: 2,
} as const;

const startOfEpochDate = dayjs(new Date(0)).startOf('day').format('YYYY-MM-DD');
const endOfDayToday = dayjs().endOf('day').format('YYYY-MM-DD');

const MyExternalDataSharingRequestsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(MyExternalDataSharingRequestsInitialSortBy);

  const statusParams = searchParams.get(goEdsSearchParams.status);
  const userParams = searchParams.get(goEdsSearchParams.user);
  const startDateParams = searchParams.get(goEdsSearchParams.startDate);
  const endDateParams = searchParams.get(goEdsSearchParams.endDate);
  const sortByParams = searchParams.get(goEdsSearchParams.sortBy) as keyof GoEdsMyRequestModel | null;
  const sortOrderParams = searchParams.get(goEdsSearchParams.sortOrder);

  const userHasWriteAccess = useIsUserHasRequiredAppAccess(AppAccess.MyEdsRequestsWrite);
  const { isSideNavOpen } = useAppSelector(s => s.app);
  const statusList = useGetGoEdsRequestStatusListQuery();
  const userList = useGetGoEdsMyRequestAssociateUserListQuery();
  const filterLoading = statusList.isLoading || statusList.isFetching || userList.isLoading || userList.isFetching;

  const filters = useMemo(() => {
    const list: { filter_order: number; filter_id: number }[] = [];

    if (statusParams) {
      const statusIds = statusParams.split(',');
      statusIds.forEach(go_eds_ref_request_status_id => {
        const s = statusList.data?.find(
          s => s.go_eds_ref_request_status_id.toString() === go_eds_ref_request_status_id,
        );
        if (s) list.push({ filter_order: filterOrder.status, filter_id: s.go_eds_ref_request_status_id });
      });
    }

    if (userParams) {
      const refUserIds = userParams.split(',');
      refUserIds.forEach(refUserId => {
        const user = userList.data?.find(s => s.ref_user_id === parseInt(refUserId));
        if (user) list.push({ filter_order: filterOrder.user, filter_id: user.ref_user_id });
      });
    }

    return list;
  }, [statusParams, userParams, statusList.data, userList]);
  const displayedHeaders = useMemo<CustomTableHeader<GoEdsMyRequestModel>>(() => {
    return [
      ...MyExternalDataSharingRequestsHeader,
      ...(userHasWriteAccess
        ? [
            {
              Header: 'Action',
              styles: { verticalAlign: 'top' },
              Cell: MyExternalDataSharingRequestsTableActionCell,
            },
          ]
        : []),
    ];
  }, [userHasWriteAccess]);

  const { data, isLoading, isFetching } = useGetGoEdsMyRequestListQuery(
    {
      filters,
      date_start_filter: (startDateParams ?? startOfEpochDate) + ' 00:00:00',
      date_end_filter: (endDateParams ?? endOfDayToday) + ' 23:59:59',
      page_number: pageNumber,
      page_size: pageSize,
      search_string: searchParams.get('search') ?? '',
      sort_column: sortByParams ?? sortBy.id,
      sort_order: sortOrderParams === 'desc' ? 'desc' : 'asc',
    },
    { skip: filterLoading },
  );

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  return filterLoading ? (
    <></>
  ) : (
    <CustomTable
      variant="table"
      isLoading={isLoading}
      isFetching={isFetching}
      data={data ?? []}
      pageCount={0}
      pageSize={pageSize}
      totalRecords={0}
      pageIndex={pageNumber - 1}
      headers={displayedHeaders}
      search={searchParams.get('search') ?? ''}
      initialState={{ sortBy: [MyExternalDataSharingRequestsInitialSortBy] }}
      manualSortBy
      disableSortRemove
      hidePagination
      showSearch
      showNoRecords
      onPageChange={index => {
        setPageNumber(index + 1);
      }}
      onPageSizeChange={size => {
        setPageNumber(1);
        setPageSize(size);
      }}
      onPageSearchDebounce={400}
      onPageSearch={search => {
        setPageNumber(1);

        const params: DynamicObject = {};
        searchParams.forEach((val, key) => (params[key] = val));
        setSearchParams({ ...params, search: search }, { replace: true });
      }}
      onSort={sort => {
        if (sort[0]) {
          setPageNumber(1);
          setSortBy(sort[0]);
        }
      }}
      manual={true}
      styles={{
        pagination: { justifyContent: 'start' },
        header: { justifyContent: 'left' },
        searchInput: { w: '100%' },
        tableContainer: {
          sx: {
            maxW: `calc(100vw - 5.75rem - 238px - ${sideNavWidth})`,
            overflow: 'auto',
            table: userHasWriteAccess
              ? {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  tr: {
                    'th:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                    'td:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                }
              : undefined,
          },
        },
      }}
    />
  );
};

export default MyExternalDataSharingRequestsTable;
