import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { RolesAppAccessSelectedPortfolioModel } from '../../../../app/services/dme/api/types';
import { CustomTableHeader } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';
import { useAppAccessByRolesSelectedContext } from './AppAccessByRolesSelectedContext';

const Header: CustomTableHeader<RolesAppAccessSelectedPortfolioModel> = [
  {
    Header: 'Data Product Name',
    accessor: 'report_portfolio_name',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Domain',
    accessor: 'domain_name',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Business Justification',
    accessor: 'access_request_business_justification',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Expiration Date',
    Cell: ({ row: { original } }: { row: { original: RolesAppAccessSelectedPortfolioModel } }) => {
      return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
    },
    styles: { whiteSpace: 'initial', width: '190px' },
  },
  {
    Header: 'Creation Date',
    Cell: ({ row: { original } }: { row: { original: RolesAppAccessSelectedPortfolioModel } }) => {
      return <>{convertUtcToLocal(original.access_requested_datetime_utc) || '-'}</>;
    },
    styles: { whiteSpace: 'initial', width: '190px' },
  },
];

const AppAccessByRolesSelectedReportPortfolios = () => {
  const {
    portfolioDetail: { data, isLoading, isFetching },
  } = useAppAccessByRolesSelectedContext();

  return isLoading ? (
    <>Loading...</>
  ) : (
    <CustomTable
      variant="table"
      headers={Header}
      isLoading={isLoading}
      isFetching={isFetching}
      data={data || []}
      pageCount={0}
      pageSize={data ? data.length : 0}
      totalRecords={data ? data.length : 0}
      search=""
      onPageChange={index => {}}
      onPageSizeChange={size => {}}
      onPageSearch={search => {}}
      onSort={() => {}}
      hidePagination={true}
      tableSort={true}
      showNoRecords
      // onRowClick={onClickRow}
    />
  );
};

export default AppAccessByRolesSelectedReportPortfolios;
