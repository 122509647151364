import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  useLazyGetDeliveryModeAdditionalFieldsQuery,
  useLazyGetRequestAccessModeByDataProductIdQuery,
  useLazyGetRequestAccessRoleUserByEmailQuery,
  usePostRequestAccessMutation,
} from '../../../app/services/dme/api/dataProduct';

import dayjs from 'dayjs';
import { FaUser, FaUserCog } from 'react-icons/fa';
import {
  DeliveryModeAdditionalFieldsModel,
  PostRequestAccessAfModel,
  PostRequestAccessForRoleModel,
  PostRequestAccessForSaModel,
  PostRequestAccessForUserModel,
  RequestAccessModeModel,
} from '../../../app/services/dme/api/types';
import { DynamicObject } from '../../../app/types/appType';
import CustomDatePicker from '../../../components/CustomDatePicker';
import RequestQuestionItem from './RequestQuestionItem';

const modeAfPrefix = 'mode_af_id_';
const minDate = dayjs().toDate();
const maxDate = dayjs().add(1, 'year').toDate();
const defaultDate = dayjs().add(30, 'day').toDate();

const BaseFormSchema = {
  ref_role_or_user_id: Yup.object()
    .shape({
      access_type: Yup.string(),
      ref_role_id: Yup.string().required().label('Role or User'),
    })
    .required(),
  dp_data_product_delivery_mode_id: Yup.string().label('Mode').required(),
  access_expiration_datetime_utc: Yup.string()
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isValid()
        ? true
        : createError({
            message: 'Expiration Date in invalid',
            path: 'access_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isAfter(minDate)
        ? true
        : createError({
            message: 'Expiration Date must be later than today',
            path: 'access_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isBefore(maxDate)
        ? true
        : createError({
            message: 'Expiration Date may not exceed one year from current date',
            path: 'access_expiration_datetime_utc',
          });
    })
    .label('Expiration Date')
    .required(),
  access_request_business_justification: Yup.string()
    .label('Business Justification')
    .required()
    .max(1500, 'Text exceed the character limit of 1500'),
};

type Props = {
  email: string;
  ref_role_or_user_id?: { access_type: string; ref_role_id: string } | undefined;
  dp_data_product_id: number;
  dp_data_product_delivery_mode_id?: number | undefined;
  access_expiration_datetime_utc?: string | undefined;
  access_request_business_justification?: string | undefined;
  triggerElement: JSX.Element;
};

type AfField = DeliveryModeAdditionalFieldsModel & { items: DeliveryModeAdditionalFieldsModel[] };

const RequestAccessDialog = ({
  email,
  ref_role_or_user_id,
  dp_data_product_id,
  dp_data_product_delivery_mode_id,
  access_expiration_datetime_utc,
  access_request_business_justification,
  triggerElement,
}: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [expirationDate, setExpirationDate] = useState<Date>(defaultDate);
  const [afFields, setAfFields] = useState<AfField[] | null>(null);
  const [isLoadingAf, setIsLoadingAf] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [afAsync, afDetail] = useLazyGetDeliveryModeAdditionalFieldsQuery();
  const [postAsync, postDetail] = usePostRequestAccessMutation();
  const [triggerRole, { data: roleUserData, isLoading: isLoadingRoleUser, isFetching: isFetchingRoleUser }] =
    useLazyGetRequestAccessRoleUserByEmailQuery();

  const [triggerMode, { data: modeData, isLoading: isLoadingMode, isFetching: isFetchingMode }] =
    useLazyGetRequestAccessModeByDataProductIdQuery();

  const FormSchema = useMemo(() => {
    return Yup.object().shape({
      ...BaseFormSchema,
      ...afFields?.reduce((obj, field) => {
        const id = modeAfPrefix + field.dp_delivery_mode_additional_field_id;
        if (field.response_type === 'textbox') {
          let validation = Yup.string().label(field.additional_field_label);
          if (field.is_required) {
            validation = validation.concat(Yup.string().required());
          }
          obj[id] = validation;
        } else if (field.response_type === 'radiobutton' || field.response_type === 'dropdown') {
          let validation = Yup.number().label(field.additional_field_label);
          if (field.is_required) {
            validation = validation.concat(Yup.number().required().moreThan(0, 'This is a required field'));
          }
          obj[id] = validation;
        } else if (field.response_type === 'checkbox') {
          let validation: Yup.AnySchema = Yup.array<number[]>().label(field.additional_field_label);
          if (field.is_required) {
            validation = validation.concat(
              Yup.array<number[]>().required().of(Yup.number().moreThan(0, 'Choose atleast one')),
            );
          }
          obj[id] = validation;
        }

        return obj;
      }, {} as DynamicObject),
    });
  }, [afFields]);

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_role_or_user_id: ref_role_or_user_id || {
        access_type: '',
        ref_role_id: '',
      },
      dp_data_product_delivery_mode_id: `${dp_data_product_delivery_mode_id || ''}` || '',
      access_expiration_datetime_utc: dayjs(access_expiration_datetime_utc || defaultDate).format('YYYY-MM-DD'),
      access_request_business_justification: access_request_business_justification || '',
    },
    onSubmit: values => {
      const afList =
        afFields && afFields.length > 0
          ? afFields.reduce((arr, field) => {
              const af: PostRequestAccessAfModel = {
                dp_delivery_mode_af_option_id: field.dp_delivery_mode_af_option_id,
                field_response_text: null,
              };
              const answer: string | number | number[] = values[
                (modeAfPrefix + field.dp_delivery_mode_additional_field_id) as keyof typeof values
              ] as any;
              if (
                !answer ||
                (typeof answer === 'number' && answer < 1) ||
                (Array.isArray(answer) && answer.length < 1)
              ) {
                return arr; // skiped for optional fields and user did not select answer
              }
              if (field.response_type === 'textbox') {
                af.field_response_text = (answer as string).trim();
                arr.push(af);
              } else if (field.response_type === 'radiobutton' || field.response_type === 'dropdown') {
                af.dp_delivery_mode_af_option_id = answer as number;
                arr.push(af);
              } else if (field.response_type === 'checkbox') {
                arr.push(
                  ...(answer as never as number[]).map(m => ({
                    dp_delivery_mode_af_option_id: m,
                    field_response_text: null,
                  })),
                );
              }
              return arr;
            }, [] as PostRequestAccessAfModel[])
          : null;

      let data: PostRequestAccessForRoleModel | PostRequestAccessForUserModel | PostRequestAccessForSaModel = {
        ...values,
        access_request_business_justification: values.access_request_business_justification.trim(),
        dp_data_product_delivery_mode_id: parseInt(values.dp_data_product_delivery_mode_id),
      } as any;

      if (values.ref_role_or_user_id.access_type === 'role') {
        data = {
          ...data,
          ref_role_id: parseInt(values.ref_role_or_user_id.ref_role_id),
          dp_role_data_product_delivery_mode_af_list: afList,
        } as PostRequestAccessForRoleModel;
      } else if (values.ref_role_or_user_id.access_type === 'user') {
        data = {
          ...data,
          ref_user_id: parseInt(values.ref_role_or_user_id.ref_role_id),
          dp_user_data_product_delivery_mode_af_list: afList,
        } as PostRequestAccessForUserModel;
      } else if (values.ref_role_or_user_id.access_type === 'service accounts') {
        data = {
          ...data,
          ref_service_account_id: parseInt(values.ref_role_or_user_id.ref_role_id),
          dp_sa_data_product_delivery_mode_af_list: afList,
        } as PostRequestAccessForSaModel;
      }

      postAsync(data);
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
    setAfFields(null);
    setExpirationDate(defaultDate);
  };

  const selectCustomComponents = {
    Option: ({ children, ...props }: any) => (
      <chakraComponents.Option {...props}>
        {props.data.icon} {children}
      </chakraComponents.Option>
    ),
    SingleValue: ({ children, ...props }: any) => (
      <chakraComponents.SingleValue {...props}>
        <Flex align="center">
          {props.data.icon} {children}
        </Flex>
      </chakraComponents.SingleValue>
    ),
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    } else {
      triggerRole({ email, dp_id: dp_data_product_id, dp_mode_id: dp_data_product_delivery_mode_id })
        .unwrap()
        .then(list => {
          if (list.length === 1) {
            setValues(v => ({
              ...v,
              ref_role_or_user_id: {
                access_type: list[0].access_type,
                ref_role_id: `${list[0].ref_role_id}`,
              },
            }));
          }
        });
      triggerMode(dp_data_product_id);
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = postDetail;

    if (isSuccess) {
      setAlertMessage('Request Access successfully submitted.');
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [postDetail]);

  useEffect(() => {
    // I think we can remove this
    //check if ref_role_id is in [14,15,16]
    if (!isLoadingRoleUser && roleUserData) {
      if (
        ref_role_or_user_id &&
        !roleUserData.some(s => s.ref_role_id.toString() === values.ref_role_or_user_id.ref_role_id)
      ) {
        setValues({
          ...values,
          ref_role_or_user_id: { access_type: '', ref_role_id: '' },
        });
      }
    }
  }, [roleUserData]);

  useEffect(() => {
    const ref_delivery_mode_id = modeData?.find(
      f => f.dp_data_product_delivery_mode_id === parseInt(values.dp_data_product_delivery_mode_id),
    )?.ref_delivery_mode_id;

    if (ref_delivery_mode_id && isOpen && !isLoadingRoleUser) {
      setIsLoadingAf(true);
      afAsync(ref_delivery_mode_id)
        .unwrap()
        .then(res => {
          const afFields: AfField[] = [];
          res.forEach(f1 => {
            if (
              afFields.find(f2 => f2.dp_delivery_mode_additional_field_id === f1.dp_delivery_mode_additional_field_id)
            ) {
              afFields[
                afFields.findIndex(
                  f2 => f2.dp_delivery_mode_additional_field_id === f1.dp_delivery_mode_additional_field_id,
                )
              ].items.push(f1);
            } else {
              afFields.push({
                ...f1,
                items: [f1],
              });
            }
          });
          setAfFields(afFields);

          setValues(v => {
            // fixes a bug where ref_role_or_user_id is overwritten due to fetch of roleUserData and fetch AF where done almost the same time
            // when the bug happens, ref_role_or_user_id will be empty strings
            const ref_role_or_user_id =
              v.ref_role_or_user_id.access_type === '' &&
              v.ref_role_or_user_id.ref_role_id === '' &&
              roleUserData?.length === 1
                ? {
                    access_type: roleUserData[0].access_type,
                    ref_role_id: `${roleUserData[0].ref_role_id}`,
                  }
                : v.ref_role_or_user_id;

            return {
              ...v,
              ref_role_or_user_id,
              ...afFields?.reduce((obj, field) => {
                const id = modeAfPrefix + field.dp_delivery_mode_additional_field_id;
                if (field.response_type === 'textbox') {
                  obj[id] = '';
                } else if (field.response_type === 'radiobutton' || field.response_type === 'dropdown') {
                  obj[id] = 0;
                } else if (field.response_type === 'checkbox') {
                  obj[id] = [];
                }

                return obj;
              }, {} as DynamicObject),
            };
          });
        })
        .finally(() => setIsLoadingAf(false));
    }

    // added isOpen so will trigger when opening modal, to fix a bug.
  }, [values.dp_data_product_delivery_mode_id, modeData, isOpen, isLoadingRoleUser]);

  return (
    <>
      <Box
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Access</ModalHeader>
          <ModalCloseButton isDisabled={postDetail.isLoading} />
          {isLoadingRoleUser || isFetchingRoleUser || isLoadingMode || isFetchingMode ? (
            <ModalBody mb={4}>Loading...</ModalBody>
          ) : (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <List>
                  <ListItem>
                    <RequestQuestionItem
                      index={1}
                      label="Choose access for a role, service account or your account"
                      isComplete={values.ref_role_or_user_id.ref_role_id.length > 0}
                    >
                      <FormControl
                        isInvalid={
                          !!errors.ref_role_or_user_id?.ref_role_id && touched.ref_role_or_user_id?.ref_role_id
                        }
                      >
                        <Select
                          id="ref_role_or_user_id"
                          name="ref_role_or_user_id"
                          onChange={e => {
                            e?.value &&
                              setValues(v => ({
                                ...v,
                                ref_role_or_user_id: e?.value,
                              }));
                          }}
                          useBasicStyles
                          size="sm"
                          components={selectCustomComponents}
                          value={(() => {
                            const val = roleUserData?.find(
                              m => m.ref_role_id.toString() === values.ref_role_or_user_id.ref_role_id,
                            );
                            return val
                              ? {
                                  label: val.role_name,
                                  icon: <Icon as={val.access_type === 'user' ? FaUser : FaUserCog} mr={2} />,
                                  value: {
                                    access_type: val.access_type,
                                    ref_role_id: `${val.ref_role_id}`,
                                  },
                                }
                              : undefined;
                          })()}
                          options={roleUserData?.map(m => {
                            return {
                              label: m.role_name,
                              icon: <Icon as={m.access_type === 'user' ? FaUser : FaUserCog} mr={2} />,
                              value: {
                                access_type: m.access_type,
                                ref_role_id: `${m.ref_role_id}`,
                              },
                            };
                          })}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: provided => ({
                              ...provided,
                              zIndex: 1401,
                            }),
                          }}
                          maxMenuHeight={500}
                        />
                        <FormErrorMessage>{errors.ref_role_or_user_id?.ref_role_id}</FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                  <ListItem>
                    <RequestQuestionItem
                      index={2}
                      label="Choose Mode"
                      isComplete={
                        values.dp_data_product_delivery_mode_id.length > 0 &&
                        afFields?.every(field => {
                          const answer: string | number | number[] = values[
                            (modeAfPrefix + field.dp_delivery_mode_additional_field_id) as keyof typeof values
                          ] as any;
                          if (!field.is_required) return true;
                          if (field.response_type === 'textbox' && typeof answer === 'string') {
                            return !!answer;
                          } else if (
                            (field.response_type === 'radiobutton' || field.response_type === 'dropdown') &&
                            typeof answer === 'number'
                          ) {
                            return answer > 0;
                          } else if (field.response_type === 'checkbox' && Array.isArray(answer)) {
                            return answer.length > 0;
                          }
                          return false;
                        })
                      }
                    >
                      <>
                        <FormControl
                          isInvalid={
                            !!errors.dp_data_product_delivery_mode_id && touched.dp_data_product_delivery_mode_id
                          }
                        >
                          <Select
                            id="dp_data_product_delivery_mode_id"
                            name="dp_data_product_delivery_mode_id"
                            defaultValue={modeData?.map((m: RequestAccessModeModel) => {
                              if (
                                m.dp_data_product_delivery_mode_id.toString() ===
                                values.dp_data_product_delivery_mode_id
                              ) {
                                return {
                                  label: m.delivery_mode,
                                  value: m.dp_data_product_delivery_mode_id,
                                  delivery_mode_properties: m.delivery_mode_properties,
                                };
                              } else {
                                return null;
                              }
                            })}
                            onChange={e => {
                              setValues({
                                ...values,
                                dp_data_product_delivery_mode_id: e?.value?.toString() || '',
                              });
                            }}
                            useBasicStyles
                            size="sm"
                            options={modeData?.map(m => {
                              return {
                                label: m.delivery_mode,
                                value: m.dp_data_product_delivery_mode_id,
                                delivery_mode_properties: m.delivery_mode_properties,
                              };
                            })}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: provided => ({
                                ...provided,
                                zIndex: 1401,
                              }),
                            }}
                            maxMenuHeight={500}
                            components={{
                              Option: props => {
                                const hasDuplicate = !!modeData?.find(
                                  f =>
                                    f.delivery_mode === props.label &&
                                    f.dp_data_product_delivery_mode_id !== props.data?.value,
                                );
                                return (
                                  <chakraComponents.Option {...props}>
                                    <Text>
                                      {props.label}
                                      {hasDuplicate && (
                                        <>
                                          {' '}
                                          <Text as="span" color="gray.300">
                                            ({props.data?.delivery_mode_properties})
                                          </Text>
                                        </>
                                      )}
                                    </Text>
                                  </chakraComponents.Option>
                                );
                              },
                            }}
                          />
                          <FormErrorMessage>{errors.dp_data_product_delivery_mode_id}</FormErrorMessage>
                        </FormControl>

                        {isLoadingAf ? (
                          <>
                            <Skeleton h="5" w="40%" my="2" />
                            <Skeleton h="10" my="2" />
                          </>
                        ) : (
                          afFields && (
                            <Box pt="4">
                              {afFields.map(m1 => (
                                <FormControl
                                  key={m1.dp_delivery_mode_additional_field_id}
                                  pb="2"
                                  isInvalid={
                                    (!!errors[
                                      (modeAfPrefix + m1.dp_delivery_mode_additional_field_id) as keyof typeof errors
                                    ] as never) &&
                                    (touched[
                                      (modeAfPrefix + m1.dp_delivery_mode_additional_field_id) as keyof typeof touched
                                    ] as never)
                                  }
                                >
                                  <FormLabel fontSize="sm">
                                    {m1.additional_field_label + (m1.is_required ? '' : ' (Optional)')}
                                  </FormLabel>
                                  {m1.response_type === 'textbox' ? (
                                    <Textarea
                                      id={modeAfPrefix + m1.dp_delivery_mode_additional_field_id}
                                      name={modeAfPrefix + m1.dp_delivery_mode_additional_field_id}
                                      size="sm"
                                      onChange={e => {
                                        setValues({
                                          ...values,
                                          [modeAfPrefix + m1.dp_delivery_mode_additional_field_id]: e.target.value,
                                        });
                                      }}
                                    />
                                  ) : m1.response_type === 'radiobutton' ? (
                                    <RadioGroup
                                      onChange={e => {
                                        setValues({
                                          ...values,
                                          [modeAfPrefix + m1.dp_delivery_mode_additional_field_id]: +e,
                                        });
                                      }}
                                      value={(values as any)[
                                        modeAfPrefix + m1.dp_delivery_mode_additional_field_id
                                      ]?.toString()}
                                      colorScheme="brand.main"
                                    >
                                      <Stack direction="row">
                                        {m1.items.map(m2 => (
                                          <Radio
                                            key={m2.dp_delivery_mode_af_option_id}
                                            value={m2.dp_delivery_mode_af_option_id?.toString()}
                                          >
                                            {m2.option_display_name}
                                          </Radio>
                                        ))}
                                      </Stack>
                                    </RadioGroup>
                                  ) : m1.response_type === 'checkbox' ? (
                                    <Stack direction="column">
                                      {m1.items.map(m2 => (
                                        <Checkbox
                                          key={m2.dp_delivery_mode_af_option_id}
                                          colorScheme="brand.main"
                                          value={m2.dp_delivery_mode_af_option_id ?? undefined}
                                          onChange={e => {
                                            let val = ((values as any)[
                                              modeAfPrefix + m1.dp_delivery_mode_additional_field_id
                                            ] ?? []) as number[];
                                            if (val.find(f => f === m2.dp_delivery_mode_af_option_id)) {
                                              val = val.filter(f => f !== m2.dp_delivery_mode_af_option_id);
                                            } else {
                                              val.push(m2.dp_delivery_mode_af_option_id!);
                                            }
                                            setValues({
                                              ...values,
                                              [modeAfPrefix + m1.dp_delivery_mode_additional_field_id]: val,
                                            });
                                          }}
                                        >
                                          {m2.option_display_name}
                                        </Checkbox>
                                      ))}
                                    </Stack>
                                  ) : m1.response_type === 'dropdown' ? (
                                    <Select
                                      size="sm"
                                      useBasicStyles
                                      options={m1.items.map(m2 => ({
                                        label: m2.option_display_name,
                                        value: m2.dp_delivery_mode_af_option_id,
                                      }))}
                                      onChange={e => {
                                        e?.value &&
                                          setValues({
                                            ...values,
                                            [modeAfPrefix + m1.dp_delivery_mode_additional_field_id]: e.value,
                                          });
                                      }}
                                    />
                                  ) : null}
                                  <FormErrorMessage>
                                    {
                                      errors[
                                        (modeAfPrefix + m1.dp_delivery_mode_additional_field_id) as keyof typeof errors
                                      ] as string
                                    }
                                  </FormErrorMessage>
                                </FormControl>
                              ))}
                            </Box>
                          )
                        )}
                      </>
                    </RequestQuestionItem>
                  </ListItem>
                  <ListItem>
                    <RequestQuestionItem
                      index={3}
                      label={
                        <HStack align="center" spacing={1}>
                          <Text>Choose Expiration</Text>
                          <Text fontWeight="normal" as="small">
                            (YYYY-MM-DD)
                          </Text>
                        </HStack>
                      }
                      isComplete={
                        !errors.access_expiration_datetime_utc && values.access_expiration_datetime_utc.length > 0
                      }
                    >
                      <FormControl isInvalid={!!errors.access_expiration_datetime_utc}>
                        <CustomDatePicker
                          id="access_expiration_datetime_utc"
                          name="access_expiration_datetime_utc"
                          date={expirationDate}
                          onDateChange={(date: Date) => {
                            setValues({
                              ...values,
                              access_expiration_datetime_utc: dayjs(date).format('YYYY-MM-DD'),
                            });
                            setExpirationDate(date);
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                          propsConfigs={{
                            dateNavBtnProps: {
                              colorScheme: 'brand.main.default',
                              variant: 'outline',
                            },
                            dayOfMonthBtnProps: {
                              defaultBtnProps: {
                                _hover: {
                                  background: 'brand.main.default',
                                  color: 'white',
                                },
                              },
                              selectedBtnProps: {
                                background: 'brand.main.default',
                                color: 'white',
                              },
                              todayBtnProps: {
                                background: 'gray.400',
                              },
                            },
                            inputProps: {
                              placeholder: 'YYYY-MM-DD',
                              size: 'sm',
                              value: values.access_expiration_datetime_utc,
                              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  access_expiration_datetime_utc: e.target.value,
                                });
                                if (dayjs(e.target.value)?.isValid()) {
                                  setExpirationDate(dayjs(e.target.value).toDate());
                                }
                              },
                            },
                          }}
                        />
                        <FormErrorMessage>{errors.access_expiration_datetime_utc}</FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                  <ListItem>
                    <RequestQuestionItem
                      hasNoLine
                      index={4}
                      label="Fill in business justification"
                      isComplete={values.access_request_business_justification.length > 0}
                    >
                      <FormControl
                        isInvalid={
                          !!errors.access_request_business_justification &&
                          touched.access_request_business_justification
                        }
                      >
                        <Textarea
                          id="access_request_business_justification"
                          placeholder="Enter Message"
                          name="access_request_business_justification"
                          onChange={e => {
                            handleChange(e);
                          }}
                          value={values.access_request_business_justification}
                          maxLength={1500}
                        />
                        <FormErrorMessage>{errors.access_request_business_justification}</FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                </List>

                <VStack spacing={5}>
                  {alertMessage && (
                    <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                      <AlertIcon />
                      {alertMessage}
                    </Alert>
                  )}
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3} ml="auto" isDisabled={postDetail.isLoading}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.main"
                  isLoading={postDetail.isLoading}
                  isDisabled={alertMessage !== '' && postDetail.isSuccess}
                >
                  Send
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestAccessDialog;
