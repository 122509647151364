import { Heading, HStack, Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { LoreLanguageDetailsPageDetailModel } from '../../../../app/services/dme/api/types';

type Props = {
  data: LoreLanguageDetailsPageDetailModel[];
};

const LoreLanguageSelectedDetails = ({ data }: Props) => {
  const [localData, setLocalData] = useState<LoreLanguageDetailsPageDetailModel>();
  const location = useLocation();
  useEffect(() => {
    setLocalData(data[0]);
  }, [data]);

  useEffect(() => {
    addBreadcrumb({ label: 'Result', path: '/glossary' }, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Glossary Details</Heading>
      {!data ? (
        <></>
      ) : (
        <HStack>
          <Table size="sm">
            <Tbody
              sx={{
                '& tr > th': {
                  maxW: '50px',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Tr>
                <Th minW={170}>Term</Th>
                <Td>{localData?.ll_term}</Td>
                <Td>&nbsp;</Td>
                <Th minW={110}>Created</Th>
                <Td>{localData?.created && convertUtcToLocal(localData?.created, 'MMMM D, YYYY h:mm A')}</Td>
              </Tr>
              <Tr>
                <Th>Definition</Th>
                <Td>{localData?.ll_definition}</Td>
                <Td>&nbsp;</Td>
                <Th>Last Modified</Th>
                <Td whiteSpace="nowrap">
                  {localData?.last_modified && convertUtcToLocal(localData?.last_modified, 'MMMM D, YYYY h:mm A')}
                </Td>
              </Tr>
              <Tr>
                <Th>Application Context</Th>
                <Td>{localData?.ll_application_context}</Td>
                <Td>&nbsp;</Td>
                <Th>Editor</Th>
                <Td>{localData?.editor || '-'}</Td>
              </Tr>
            </Tbody>
          </Table>
        </HStack>
      )}
    </VStack>
  );
};

export default LoreLanguageSelectedDetails;
