import { BuildSchemaArgs } from "../../../../app/helpers/formSchemabuilder";
import { AddPlatformServiceModel, PlatformServicesCreateRequestQAListFirstModel, PlatformServicesCreateRequestQAListSecondModel, PlatformServicesExploreModel, PlatformServicesCreateRequestQAListModel } from "../../../../app/services/dme/api/types";
import ExplorePlatfromServicesCreateRequestDetails from "./Details";
import ExplorePlatfromServicesCreateRequestQuestionAndAnswer from "./QandA";

export const explorePlatfromServicesCreateRequestTabs = [
    { label: 'Details', component: ExplorePlatfromServicesCreateRequestDetails },
    { label: 'Q & A', component: ExplorePlatfromServicesCreateRequestQuestionAndAnswer },
  ] as const;
  
  export type ExplorePlatfromServicesCreateRequestProps = {
    tabIndex: number;
    setTabIndex: React.Dispatch<React.SetStateAction<number>>;
    data: AddPlatformServiceModel;
    setData: React.Dispatch<React.SetStateAction<AddPlatformServiceModel>>;
    questions: PlatformServicesCreateRequestQAListFirstModel[];
    referenceTableContents: PlatformServicesCreateRequestQAListSecondModel[];
    original: PlatformServicesExploreModel;
  };
  
  export const initialValuesExplorePlatfromServicesCreateRequest: AddPlatformServiceModel = {
    // ps_ref_platform_portfolio_id: 0,
    // request_platform_portfolio: '',
    request_created_ref_user_id: 0,
    ps_ref_category_id: 0,
    // request_service_category: '',
    answers: [],
  };
  
  export const explorePlatfromServicesCreateRequestFields = {
    request_platform_portfolio: { type: 'string', label: 'Platform Portfolio', max: 1000 } as BuildSchemaArgs,
    request_service_category: { type: 'string', label: 'Service Category', max: 1000 } as BuildSchemaArgs,    
  };