import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useGetFeedbackQuery } from '../../../app/services/dme/api/feedback';
import { FeedbackModel } from '../../../app/services/dme/api/types';

type Props = {
  onFeedbackClick: (user: FeedbackModel | null) => void;
  feedback: FeedbackModel | null;
};

const FeedbackList: FC<Props> = ({ onFeedbackClick, feedback }) => {
  const query = useGetFeedbackQuery();

  return (
    <VStack maxH="calc(100vh - 250px)" overflowY="auto">
      {query.isLoading && <Text>Loading...</Text>}
      {query.data?.map(m => (
        <Button
          as="div"
          key={m.dme_feedback_id}
          minH={70}
          fontWeight="normal"
          variant={feedback?.dme_feedback_id === m.dme_feedback_id ? 'solid' : 'ghost'}
          onClick={() => {
            onFeedbackClick(m);
          }}
        >
          <VStack w="full" alignItems="baseline">
            <Heading title={m.feedback_subject} whiteSpace="normal" wordBreak="break-all" size="sm" noOfLines={1}>
              {m.feedback_subject}
            </Heading>
            <HStack>
              <Text fontSize={14} fontWeight="medium">
                {m.first_name} {m.last_name}
              </Text>
              <Text fontSize={12}>{convertUtcToLocal(m.feedback_datetime_utc, 'MMM D, YYYY h:mm A')}</Text>
            </HStack>
          </VStack>
        </Button>
      ))}
    </VStack>
  );
};

export default FeedbackList;
