import { IconProps } from '../../app/types/appType';

const ContactPage = (props: IconProps) => {
  return (
    <svg
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="contact_page">
        <mask
          id="mask0_551_6498"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_551_6498)">
          <path
            id="contact_page_2"
            d="M12 13.7692C12.4859 13.7692 12.899 13.599 13.2394 13.2586C13.5798 12.9182 13.75 12.5051 13.75 12.0192C13.75 11.5333 13.5798 11.1202 13.2394 10.7798C12.899 10.4394 12.4859 10.2692 12 10.2692C11.5141 10.2692 11.1009 10.4394 10.7606 10.7798C10.4202 11.1202 10.25 11.5333 10.25 12.0192C10.25 12.5051 10.4202 12.9182 10.7606 13.2586C11.1009 13.599 11.5141 13.7692 12 13.7692ZM8.25 17.4615H15.75V17.098C15.75 16.7429 15.6544 16.4275 15.4634 16.1519C15.2724 15.8762 15.0109 15.6647 14.6788 15.5173C14.2647 15.3404 13.8349 15.2035 13.3894 15.1067C12.9439 15.0099 12.4807 14.9615 12 14.9615C11.5192 14.9615 11.0561 15.0099 10.6105 15.1067C10.1651 15.2035 9.73524 15.3404 9.32113 15.5173C8.98909 15.6647 8.72756 15.8762 8.53653 16.1519C8.34551 16.4275 8.25 16.7429 8.25 17.098V17.4615ZM17.6922 21.5H6.3077C5.80257 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V4.3077C4.5 3.80257 4.675 3.375 5.025 3.025C5.375 2.675 5.80257 2.5 6.3077 2.5H13.7884L19.5 8.21153V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6922 21.5ZM17.6922 20C17.7692 20 17.8397 19.9679 17.9038 19.9038C17.9679 19.8397 18 19.7692 18 19.6923V8.84998L13.15 3.99998H6.3077C6.23077 3.99998 6.16024 4.03203 6.09612 4.09613C6.03202 4.16024 5.99997 4.23077 5.99997 4.3077V19.6923C5.99997 19.7692 6.03202 19.8397 6.09612 19.9038C6.16024 19.9679 6.23077 20 6.3077 20H17.6922Z"
            fill={props.color ?? '#13707F'}
          />
        </g>
      </g>
    </svg>
  );
};

export default ContactPage;
