import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  HStack,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { AddRoleModel } from '.';
import { ValueOf } from '../../../app/helpers/utilities';
import { useGetRolesAppAccessRefDevelopmentEnvironmentListQuery } from '../../../app/services/dme/api/rolesAppAccess';
import {
  RolesAppAccessRefDevelopmentEnvironmentModel,
  RolesAppAccessSelectedDevelopmentEnvironmentModel,
  RolesAppAccessSelectedDevelopmentEnvironmentMutateModel,
} from '../../../app/services/dme/api/types';
import { SelectProps, SelectValue } from '../../../app/types/appType';
import AddDevelopmentEnvironmentModal, {
  AddDeveloperEnvironmentForwardProps,
  addDevelopmentEnvironmentId,
} from '../AppAccessByRoles/Selected/AddDevelopmentEnvironmentModal';
import { DevEnvDistinct, buildDevEnvDistinct } from '../constants';

type Props = {
  data: AddRoleModel;
  onChangeData: (field: keyof AddRoleModel, data: ValueOf<AddRoleModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const AddRoleDevelopmentEnvironment: React.FC<Props> = ({
  data,
  onChangeData,
  errors,
  touched,
  title,
  tabErrorIndex,
}) => {
  const refDevEnvDetail = useGetRolesAppAccessRefDevelopmentEnvironmentListQuery();

  const [devEnvDistinct, setDevEnvDistinct] = useState<DevEnvDistinct | null>(null);
  const [addDevEnv, setAddDevEnv] = useState<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel | null>(null);
  const addDeveloperEnvironmentRef = useRef<AddDeveloperEnvironmentForwardProps>(null);

  const generateIdForAddedItem = (): number => {
    return data.devEnv && data.devEnv.length > 0
      ? Math.min.apply(
          Math,
          data.devEnv.map(m => m.dp_role_developer_environment_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow: RolesAppAccessSelectedDevelopmentEnvironmentModel = {
      dataset: '',
      role_name: '',
      dp_role_developer_environment_id: generateIdForAddedItem(),
      ref_developer_environment_id: 0,
      developer_environment_name: '',
      developer_environment_desc: '',
      cloud_platform: '',
      cloud_service: '',
      access_level: '',
      environment_properties: '',
      row_modified_datetime_utc: '',
    };
    onChangeData('devEnv', data.devEnv ? [...data.devEnv, newRow] : [newRow]);
  };

  const onUpdateData = (
    rowIndex: number,
    columnId: string,
    value: string | File | number | number[],
    fileColumnName?: string,
  ) => {
    onChangeData(
      'devEnv',
      data.devEnv.map((row, index) => {
        if (index === rowIndex && refDevEnvDetail.data) {
          const devEnv = refDevEnvDetail.data.find(f => f.ref_developer_environment_id === (value as number));
          return { ...row, ...devEnv };
        }
        return row;
      }),
    );
  };

  const onRemove = (item: RolesAppAccessSelectedDevelopmentEnvironmentModel) => {
    onChangeData(
      'devEnv',
      data.devEnv.filter(f => f.ref_developer_environment_id !== item.ref_developer_environment_id),
    );
  };

  const handleUpdate =
    (original: RolesAppAccessSelectedDevelopmentEnvironmentModel): SelectProps<number>['onChange'] =>
    e => {
      if (e?.value === addDevelopmentEnvironmentId) {
        setAddDevEnv({
          ...(original as RolesAppAccessSelectedDevelopmentEnvironmentMutateModel),
          ref_developer_environment_id: 0,
        });
        addDeveloperEnvironmentRef.current?.onOpen();
      } else {
        onChangeData(
          'devEnv',
          data.devEnv.map(m =>
            m.dp_role_developer_environment_id === original.dp_role_developer_environment_id
              ? {
                  ...m,
                  ...refDevEnvDetail.data?.find(f => f.ref_developer_environment_id === e?.value),
                }
              : m,
          ),
        );
      }
    };

  const getValue = useCallback(
    (
      original: RolesAppAccessSelectedDevelopmentEnvironmentModel,
      field: keyof RolesAppAccessRefDevelopmentEnvironmentModel,
    ): SelectValue<number> => {
      const devEnv = refDevEnvDetail.data?.find(
        f => f.ref_developer_environment_id === original.ref_developer_environment_id,
      );
      return devEnv
        ? {
            label: devEnv[field] as string,
            value: original.ref_developer_environment_id,
          }
        : null;
    },
    [refDevEnvDetail.data],
  );

  const getOptionValue = useCallback(
    (ids: number[]) => {
      let id = ids[0];

      for (let i = 0; i < ids.length; i++) {
        if (!data.devEnv?.some(s => s.ref_developer_environment_id === ids[i])) {
          id = ids[i];
          break;
        }
      }

      return id;
    },
    [data.devEnv],
  );

  const getOptionIsDisabled = useCallback(
    (ids: number[]) => {
      return ids.every(id => data.devEnv?.some(s => s.ref_developer_environment_id === id));
    },
    [data.devEnv],
  );

  useEffect(() => {
    if (refDevEnvDetail.data) {
      const obj = buildDevEnvDistinct(refDevEnvDetail.data);
      setDevEnvDistinct(obj);
    }
  }, [refDevEnvDetail.data]);

  useEffect(() => {
    if (
      addDevEnv &&
      addDevEnv.ref_developer_environment_id &&
      refDevEnvDetail.data?.find(f => f.ref_developer_environment_id === addDevEnv.ref_developer_environment_id)
    ) {
      handleUpdate(addDevEnv)!({ value: addDevEnv.ref_developer_environment_id, label: 'none' }, {} as any);
      setAddDevEnv(null);
    }
  }, [addDevEnv, refDevEnvDetail.data]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">{title}</Heading>
      </HStack>
      {refDevEnvDetail.isLoading || refDevEnvDetail.isFetching ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return (
      <>
        <VStack>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th>Platform</Th>
                <Th>Service</Th>
                <Th>Properties</Th>
                <Th>Access Level</Th>
                <Th>Action</Th>
              </Tr>
              {data.devEnv.map((d, index) => {
                const propertiesDistinct =
                  devEnvDistinct && devEnvDistinct[d.developer_environment_name]?.environment_properties;

                const accessLevelDistinct =
                  devEnvDistinct &&
                  devEnvDistinct[d.developer_environment_name]?.environment_properties[d.environment_properties]
                    .access_level;

                return (
                  <Tr key={d.dp_role_developer_environment_id}>
                    <Td>
                      {refDevEnvDetail.data && (
                        <Tooltip label={d.developer_environment_name}>
                          <FormControl
                            minW="200px"
                            isInvalid={
                              !!(
                                errors.devEnv &&
                                (errors.devEnv as unknown as RolesAppAccessSelectedDevelopmentEnvironmentModel[])[index]
                                  ?.ref_developer_environment_id
                              ) &&
                              !!(
                                touched.devEnv &&
                                (touched.devEnv as unknown as RolesAppAccessSelectedDevelopmentEnvironmentModel[])[
                                  index
                                ]?.ref_developer_environment_id
                              )
                            }
                          >
                            <Select
                              size="sm"
                              useBasicStyles
                              maxMenuHeight={250}
                              placeholder="Select name..."
                              menuPortalTarget={document.body}
                              value={getValue(d, 'developer_environment_name')}
                              options={
                                devEnvDistinct
                                  ? [
                                      {
                                        label: 'Add new environment',
                                        value: addDevelopmentEnvironmentId,
                                      },
                                      ...Object.entries(devEnvDistinct).map(([key, value]) => ({
                                        label: key,
                                        value: getOptionValue(value.ids),
                                        isDisabled: getOptionIsDisabled(value.ids),
                                      })),
                                    ]
                                  : undefined
                              }
                              onChange={handleUpdate(d)}
                            />
                          </FormControl>
                        </Tooltip>
                      )}
                    </Td>
                    <Td>{d.developer_environment_desc}</Td>
                    <Td>{d.cloud_platform}</Td>
                    <Td>{d.cloud_service}</Td>
                    <Td>
                      <Tooltip label={d.environment_properties}>
                        <Box minW="200px">
                          {propertiesDistinct && (
                            <Select
                              size="sm"
                              useBasicStyles
                              maxMenuHeight={250}
                              placeholder="Select properties..."
                              menuPortalTarget={document.body}
                              value={getValue(d, 'environment_properties')}
                              options={
                                propertiesDistinct
                                  ? Object.entries(propertiesDistinct).map(([key, value]) => ({
                                      label: key,
                                      value: value.ids[0],
                                      isDisabled: getOptionIsDisabled(value.ids),
                                    }))
                                  : undefined
                              }
                              onChange={handleUpdate(d)}
                            />
                          )}
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      {' '}
                      <Tooltip label={d.access_level}>
                        <Box minW="200px">
                          {accessLevelDistinct && (
                            <Select
                              size="sm"
                              useBasicStyles
                              maxMenuHeight={250}
                              placeholder="Select access level..."
                              menuPortalTarget={document.body}
                              value={getValue(d, 'access_level')}
                              options={
                                accessLevelDistinct
                                  ? Object.entries(accessLevelDistinct).map(([key, value]) => ({
                                      label: key,
                                      value: value.ids[0],
                                      isDisabled: getOptionIsDisabled(value.ids),
                                    }))
                                  : undefined
                              }
                              onChange={handleUpdate(d)}
                            />
                          )}
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td textAlign="center">
                      {
                        <ButtonGroup spacing={1}>
                          <Tooltip label="Delete">
                            <IconButton
                              color="brand.error"
                              variant="link"
                              aria-label="Delete"
                              icon={<FaTrash />}
                              onClick={() => onRemove(d)}
                              minWidth={1}
                            />
                          </Tooltip>
                        </ButtonGroup>
                      }
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Box>
            <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
              Create New
            </Button>
          </Box>
        </VStack>

        <AddDevelopmentEnvironmentModal
          ref={addDeveloperEnvironmentRef}
          onCreated={async params => {
            addDevEnv && setAddDevEnv({ ...addDevEnv, ...params });
          }}
        />
      </>
    );
  }
};

export default AddRoleDevelopmentEnvironment;
