import { Button, Grid, GridItem, Heading, HStack, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addBreadcrumb, sliceBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import Popular from './Popular';
import Summary from './Summary';

type Props = {};

const DataProductHome = (props: Props) => {
  const [totalProduct, setTotalProduct] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const goToResultsPage = () => navigate('results');

  useEffect(() => {
    sliceBreadcrumb(0, 2);
    addBreadcrumb({ label: 'Explore Data Products', path: location.pathname });
  }, []);

  return (
    <Stack height="100vh" p={3} w="100%" pt={2}>
      <HStack>
        <Heading size="md">Total Data Products</Heading>
        {totalProduct != null && (
          <Button isDisabled={!totalProduct} title="View All" size="sm" onClick={goToResultsPage}>
            {totalProduct}
          </Button>
        )}
      </HStack>

      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem>
          <Summary setTotalProduct={count => setTotalProduct(count)} />
        </GridItem>
        <GridItem>
          <Popular />
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default DataProductHome;
