import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import FilterPane from './FilterPane';
import ResultData from './ResultData';

type Props = {};

const ReportHubExplorePortfolios: FC<Props> = props => {
  return (
    <>
      <Flex w="100%">
        <Box w={300}>
          <FilterPane />
        </Box>
        <Box flex={1} px={3}>
          <ResultData />
        </Box>
      </Flex>
    </>
  );
};

export default ReportHubExplorePortfolios;
