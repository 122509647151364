import { Text, Box, Skeleton } from '@chakra-ui/react';
import { useGetReleaseNoteLatestVersionQuery } from '../../app/services/dme/api/releaseNotes';

const ReleaseNotesLatestVersion = () => {
  const { data, isLoading, isFetching } = useGetReleaseNoteLatestVersionQuery();

  return (
    <>
      {isLoading || isFetching ? (
        <Box pt="3" pb="1" px="3">
          <Skeleton height="18px" />
        </Box>
      ) : (
        <Text textAlign="center" pt="2" textColor="gray.400">
          Current Version: {data}
        </Text>
      )}
    </>
  );
};

export default ReleaseNotesLatestVersion;
