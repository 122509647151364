import { RolesAppAccessRefDevelopmentEnvironmentModel } from '../../app/services/dme/api/types';

export type DevEnvDistinct = {
  [key: string]: {
    ids: number[];
    environment_properties: {
      [key: string]: {
        ids: number[];
        access_level: {
          [key: string]: {
            ids: number[];
          };
        };
      };
    };
  };
};

export const buildDevEnvDistinct = (data: RolesAppAccessRefDevelopmentEnvironmentModel[]) => {
  const obj: DevEnvDistinct = {};
  data.forEach(f => {
    const id = f.ref_developer_environment_id;
    const name = f.developer_environment_name;
    const property = f.environment_properties;
    const accessLevel = f.access_level;

    const nameExists = Boolean(obj[name]);
    if (nameExists) {
      obj[name].ids.push(id);

      const propertyExists = Boolean(obj[name].environment_properties[property]);
      if (propertyExists) {
        obj[name].environment_properties[property].ids.push(id);

        const accessLevelExists = Boolean(obj[name].environment_properties[property].access_level[accessLevel]);
        if (accessLevelExists) {
          obj[name].environment_properties[property].access_level[accessLevel].ids.push(id);
        } else {
          obj[name].environment_properties[property].access_level[accessLevel] = {
            ids: [id],
          };
        }
      } else {
        obj[name].environment_properties[property] = {
          ids: [id],
          access_level: {
            [accessLevel]: {
              ids: [id],
            },
          },
        };
      }
    } else {
      obj[name] = {
        ids: [id],
        environment_properties: {
          [property]: {
            ids: [id],
            access_level: {
              [accessLevel]: {
                ids: [id],
              },
            },
          },
        },
      };
    }
  });
  return obj;
};
