import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSearchParams } from '../../../app/helpers/utilities';
import useDebounce from '../../../app/hooks/useDebounce';
import { InfoweaveParams } from '../InfoweaveContext';

export const InfoweaveSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchQueryParams = searchParams.get(InfoweaveParams.search) ?? '';
  const [searchState, setSearchState] = useState(searchQueryParams);
  const debouncedSearch = useDebounce(searchState, 400);

  useEffect(() => {
    const data = { ...getSearchParams(searchParams), [InfoweaveParams.search]: debouncedSearch };

    // scenario: should remove query params in browser if 'search' is empty
    if (!data[InfoweaveParams.search]) delete data[InfoweaveParams.search];

    setSearchParams(data, { replace: true });
  }, [debouncedSearch]);

  useEffect(() => {
    if (!searchQueryParams && searchState) {
      // scenario: when user clicks Home or infoweave button
      setSearchState(searchQueryParams);
    }
  }, [searchQueryParams]);

  return (
    <InputGroup>
      <Input
        placeholder="Search"
        value={searchState}
        onChange={e => {
          setSearchState(e.target.value);
        }}
      />
      <InputRightElement>
        <SearchIcon color="brand.main.default" />
      </InputRightElement>
    </InputGroup>
  );
};
