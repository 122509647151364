import { dmeApi } from './index';
import { RefSquadModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefSquadList: builder.query<RefSquadModel[], void>({
      query: () => `/refsquad`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefSquadListQuery } = extendedApi;
