import {
  Box,
  Divider,
  FormControl,
  Text,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Flex,
  Textarea,
  ButtonGroup,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PostCustomRequestDto } from '../../../app/services/dme/api/types';
import dayjs from 'dayjs';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { useState, useEffect, ChangeEvent } from 'react';
import { usePostCustomRequestMutation } from '../../../app/services/dme/api/customRequest';

const minDate = dayjs().toDate();
const maxDate = dayjs().add(1, 'year').toDate();
// const defaultDate = dayjs().add(30, "day").toDate();
const _requestDetailsMaxLength = 1000;

const FormSchema = Yup.object().shape({
  request_title: Yup.string().label('Title').max(1000).required(),
  request_details: Yup.string().label('Description').max(_requestDetailsMaxLength).required(),
  request_expiration_datetime_utc: Yup.string()
    .test((str, { createError }) => {
      if (!str) return true;
      const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
      return date.isValid()
        ? true
        : createError({
            message: 'Expiration Date in invalid',
            path: 'request_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      if (!str) return true;
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isAfter(minDate)
        ? true
        : createError({
            message: 'Expiration Date must be later than today',
            path: 'request_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      if (!str) return true;
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isBefore(maxDate)
        ? true
        : createError({
            message: 'Expiration Date may not exceed one year from current date',
            path: 'request_expiration_datetime_utc',
          });
    })
    .label('Expiration Date')
    .optional(),
});

const CreateCustomRequest = () => {
  const [expirationDate, setExpirationDate] = useState<Date | undefined>();
  const [alertMessage, setAlertMessage] = useState('');

  const [postAsync, postDetail] = usePostCustomRequestMutation();

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik<PostCustomRequestDto>(
    {
      enableReinitialize: true,
      validationSchema: FormSchema,
      initialValues: {
        request_title: '',
        request_details: '',
        request_expiration_datetime_utc: '',
      },
      onSubmit: values => {
        postAsync(values);
      },
    },
  );

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
    setExpirationDate(undefined);
  };

  useEffect(() => {
    const { isSuccess, isError, isLoading } = postDetail;

    if (isSuccess) {
      clearModal();
      setAlertMessage('Request successfully submitted.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [postDetail]);

  return (
    <VStack>
      <Heading size="md">Create Custom Request</Heading>
      <Divider />

      <form onSubmit={handleSubmit}>
        <VStack spacing="5" pt={5} w="container.sm">
          <FormControl isInvalid={!!errors.request_title && touched.request_title} display="flex">
            <FormLabel minW="150px" htmlFor="request_title">
              Title
            </FormLabel>
            <Box w="full">
              <Input
                size="sm"
                id="request_title"
                name="request_title"
                onChange={handleChange}
                onBlur={e =>
                  setValues({
                    ...values,
                    request_title: e.target.value.trim(),
                  })
                }
                value={values.request_title}
                maxLength={100}
              />
              <FormErrorMessage>{errors.request_title}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl isInvalid={!!errors.request_details && touched.request_details} alignItems="start">
            <Box textAlign="right">
              {values.request_details.length > 0 && (
                <Text as="small">{`${values.request_details.length}/${_requestDetailsMaxLength}`}</Text>
              )}
            </Box>
            <Flex>
              <FormLabel minW="150px" htmlFor="request_details">
                Description
              </FormLabel>
              <Box width="full">
                <Textarea
                  size="sm"
                  id="request_details"
                  name="request_details"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      request_details: e.target.value.trim(),
                    })
                  }
                  value={values.request_details}
                  maxLength={_requestDetailsMaxLength}
                />
                <FormErrorMessage>{errors.request_details}</FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>

          <FormControl isInvalid={!!errors.request_expiration_datetime_utc}>
            <Flex>
              <FormLabel minW="150px" htmlFor="request_expiration_datetime_utc">
                Expiration Date
              </FormLabel>
              <VStack w="100%">
                <CustomDatePicker
                  id="request_expiration_datetime_utc"
                  name="request_expiration_datetime_utc"
                  date={expirationDate}
                  onDateChange={(date: Date) => {
                    setValues({
                      ...values,
                      request_expiration_datetime_utc: dayjs(date).format('YYYY-MM-DD'),
                    });
                    setExpirationDate(date);
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  propsConfigs={{
                    dateNavBtnProps: {
                      colorScheme: 'brand.main.default',
                      variant: 'outline',
                    },
                    dayOfMonthBtnProps: {
                      defaultBtnProps: {
                        _hover: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                      },
                      selectedBtnProps: {
                        background: 'brand.main.default',
                        color: 'white',
                      },
                      todayBtnProps: {
                        background: 'gray.400',
                      },
                    },
                    inputProps: {
                      placeholder: 'YYYY-MM-DD',
                      size: 'sm',
                      value: values.request_expiration_datetime_utc,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          request_expiration_datetime_utc: e.target.value,
                        });
                        if (dayjs(e.target.value)?.isValid()) {
                          setExpirationDate(dayjs(e.target.value).toDate());
                        }
                      },
                    },
                  }}
                />
                <FormErrorMessage>{errors.request_expiration_datetime_utc}</FormErrorMessage>
              </VStack>
            </Flex>
          </FormControl>

          {alertMessage && (
            <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}

          <ButtonGroup>
            <Button isDisabled={postDetail.isLoading} variant="outline" onClick={() => clearModal()} ml="auto">
              Cancel
            </Button>
            <Button isLoading={postDetail.isLoading} variant="solid" colorScheme="brand.main" type="submit">
              Submit
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </VStack>
  );
};

export default CreateCustomRequest;
