import { HStack, Heading, IconButton, Input, InputGroup, InputRightElement, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { BsFileEarmark } from 'react-icons/bs';
import { Validator, useFilePicker } from 'use-file-picker';
import { fileConfig } from '../../../app/constants';
import { ValueOf } from '../../../app/helpers/utilities';
import { AddDataProductModel } from '../../../app/services/types';

type Props = {
  data: AddDataProductModel;
  onChangeData: (field: keyof AddDataProductModel, data: ValueOf<AddDataProductModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const DataProductSelectedLineage = ({ data, onChangeData, title }: Props) => {
  const [openFileSelector, filePicker] = useFilePicker({
    multiple: false,
    accept: fileConfig.acceptedFiles.map(m => '.' + m),
    limitFilesConfig: { max: parseInt(fileConfig.maxFileCount || '0') },
    maxFileSize: parseInt(fileConfig.maxFileSize || '0'),
    readFilesContent: false,
    validators: [fileTypeValidator],
  });

  useEffect(() => {
    if (filePicker.plainFiles.length > 0 && onChangeData) {
      onChangeData('lineageModel', {
        dp_lineage_filename: filePicker.plainFiles[0].name,
        dp_lineage_file: filePicker.plainFiles[0],
      });
    }
  }, [filePicker.plainFiles]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">{title}</Heading>
      </HStack>
      <InputGroup size="sm" maxW="300px">
        <Input value={filePicker.plainFiles[0]?.name || ''} readOnly />
        <InputRightElement>
          <IconButton
            mr="3px"
            variant="solid"
            icon={<BsFileEarmark />}
            h="1.75rem"
            size="sm"
            onClick={openFileSelector}
            aria-label={'Choose File'}
          />
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
};

export default DataProductSelectedLineage;

const fileTypeValidator: Validator = {
  validateBeforeParsing: async (config, plainFiles) =>
    new Promise((res, rej) => {
      const invalidFiles = plainFiles.filter(f => {
        const selectedFileType = (f.name || '').split('.').pop() || '';
        return !fileConfig.acceptedFiles.includes(selectedFileType);
      });
      if (invalidFiles.length) {
        return rej({
          fileTypeError: `File type${invalidFiles.length > 1 ? 's are' : ' is'}  not allowed: ${invalidFiles
            .map(m => m.name)
            .join(', ')}`,
        });
      }
      if (config.limitFilesConfig?.max && plainFiles.length > config.limitFilesConfig.max) {
        return rej({
          fileTypeError: `The number of attachment exceeds its limit (10)`,
        });
      }

      const overSizedFiles = plainFiles.filter(f => {
        return f.size > parseInt(fileConfig.maxFileSize || '0') * 1e6;
      });
      if (overSizedFiles.length) {
        return rej({
          fileTypeError: `${
            overSizedFiles.length > 1 ? 'These files exceed' : 'This file exceeds'
          }  the maximum file size limit: ${overSizedFiles.map(m => m.name).join(', ')}`,
        });
      }
      return res();
    }),
  validateAfterParsing: async () =>
    new Promise((res, rej) => {
      res();
    }),
};
