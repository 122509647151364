import { dmeApi } from './index';
import {
  DataProductCommentAttachmentModel,
  DataProductDetailsDiscussionTabCommentModel,
  DataProductDetailsDiscussionTabModel,
  ToggleDataProductCommentReactionModel,
  UpdateDataProductCommentModel,
} from './types';

const extendApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getDataProductCommentDetail: builder.query<DataProductDetailsDiscussionTabCommentModel, number>({
      query: dp_comment_id => `/dataproductcomment/dp_comment_id/${dp_comment_id}`,
    }),

    getDataProductDetailsDiscussionTab: builder.query<
      Array<DataProductDetailsDiscussionTabModel>,
      {
        dpPostId: number;
        query: string;
      }
    >({
      query: params => `/dataproductcomment/details/discussion/comments/dp_post_id/${params.dpPostId}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getDataProductDetailsDiscussionTabByComment: builder.query<
      Array<DataProductDetailsDiscussionTabModel>,
      {
        dp_comment_id: number;
        query: string;
      }
    >({
      query: params =>
        `/dataproductcomment/details/discussion/comments/dp_comment_id/${params.dp_comment_id}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getDataProductCommentAttachmentsById: builder.query<Array<DataProductCommentAttachmentModel>, number>({
      query: dp_comment_id => `/dataproductcomment/attachment/dp_comment_id/${dp_comment_id}`,
      providesTags: ['DataProductCommentAttachments'],
    }),

    postDataProductComment: builder.mutation<{ dp_comment_id: number }, FormData>({
      query: params => ({
        url: '/dataproductcomment',
        method: 'POST',
        body: params,
      }),
    }),

    postDataProductCommentToggleReaction: builder.mutation<void, ToggleDataProductCommentReactionModel>({
      query: params => ({
        url: '/dataproductcomment/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateDataProductComment: builder.mutation<void, UpdateDataProductCommentModel>({
      query: params => ({
        url: '/dataproductcomment',
        method: 'PUT',
        body: params,
      }),
    }),

    deleteDataProductComment: builder.mutation<void, number>({
      query: dp_comment_id => ({
        url: `/dataproductcomment?${new URLSearchParams({ dp_comment_id: dp_comment_id.toString() }).toString()}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetDataProductDetailsDiscussionTabQuery,
  useLazyGetDataProductDetailsDiscussionTabQuery,
  useGetDataProductCommentAttachmentsByIdQuery,
  useLazyGetDataProductCommentDetailQuery,
  useLazyGetDataProductDetailsDiscussionTabByCommentQuery,
  usePostDataProductCommentMutation,
  usePostDataProductCommentToggleReactionMutation,
  useUpdateDataProductCommentMutation,
  useDeleteDataProductCommentMutation,
} = extendApi;
