import { Button, Grid, GridItem, Heading, HStack, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { RefUserModel } from '../../../app/services/dme/api/types';
import PanelCard from '../../../components/PanelCard';
import AddRefUserDialog from './AddRefUserDialog';
import UserList from './UserList';
import UserRole from './UserRole';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';

export const User = () => {
  const [selectedUser, setSelectedUser] = useState<RefUserModel | null>(null);
  const [addedUser, setAddedUser] = useState<RefUserModel | null>(null);
  const userWriteAccess = useIsUserHasRequiredAppAccess('adminusers.write');

  const onClickUser = (user: RefUserModel | null) => {
    setSelectedUser(user);
  };

  const clearSelected = () => {
    setSelectedUser(null);
  };

  const fullName = `${selectedUser?.first_name?.toLowerCase() || ''} ${selectedUser?.last_name?.toLowerCase() || ''}`;

  return (
    <Grid templateColumns="repeat(2, minmax(450px, 1fr))" gap={3}>
      <GridItem>
        <PanelCard
          header={
            <HStack>
              <Heading as="p" size="md">
                User
              </Heading>
              {userWriteAccess && (
                <AddRefUserDialog
                  onSuccess={e => {
                    setSelectedUser(null);
                    setAddedUser(e);
                  }}
                  triggerElement={
                    <Button variant="outline" colorScheme="brand.main">
                      Invite User
                    </Button>
                  }
                />
              )}
            </HStack>
          }
          fullHeight
        >
          <UserList
            onClickUser={onClickUser}
            selectedUser={selectedUser}
            addedUser={addedUser}
            onUserListChange={clearSelected}
          />
        </PanelCard>
      </GridItem>
      {selectedUser && (
        <GridItem>
          <PanelCard
            header={
              <HStack>
                <Heading as="p" size="md" textTransform={fullName.trim() === '' ? 'lowercase' : 'capitalize'}>
                  {fullName.trim() || selectedUser?.email}
                </Heading>
              </HStack>
            }
            fullHeight
            onClose={() => {
              setSelectedUser(null);
            }}
          >
            <Stack>
              <UserRole user={selectedUser} />
            </Stack>
          </PanelCard>
        </GridItem>
      )}
    </Grid>
  );
};
