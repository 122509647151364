import { Box, Flex } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { addBreadcrumb } from '../../../app/helpers/navigationHelpers';
import FilterPane from './FilterPane';
import ResultData from './Result/ResultData';

type Props = {};

const EditLoreLink: FC<Props> = () => {
  const location = useLocation();

  useEffect(() => {
    addBreadcrumb({ label: 'Edit Important Link', path: location.pathname }, 2);
  }, []);

  return (
    <Flex w="100%">
      <Box w={300}>
        <FilterPane />
      </Box>
      <Box flex={1} px={3}>
        <ResultData />
      </Box>
    </Flex>
  );
};

export default EditLoreLink;
