import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Input,
  Skeleton,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../../../app/constants';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { isError400Range } from '../../../../app/helpers/apiHelper';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetRefTagByIdQuery, useUpdateRefTagMutation } from '../../../../app/services/dme/api/refTags';
import AppAuth from '../../../../features/AppAuth';
import { tagNameMinLength } from '../../constants';
import ExploreTagsSelectedDataProductPayloads from './DataProductPayloads';
import ExploreTagsSelectedDataProducts from './DataProducts';
import ExploreTagsSelectedGlossaries from './Glossaries';
import ExploreTagsSelectedImportantLinks from './ImportantLinks';

const tabs = [
  {
    label: 'Data Products',
    path: 'data-products',
    component: ExploreTagsSelectedDataProducts,
  },
  {
    label: 'Data Product Payloads',
    path: 'data-product-payloads',
    component: ExploreTagsSelectedDataProductPayloads,
  },
  {
    label: 'Glossaries',
    path: 'glossaries',
    component: ExploreTagsSelectedGlossaries,
  },
  {
    label: 'Important Links',
    path: 'important-links',
    component: ExploreTagsSelectedImportantLinks,
  },
];

const TagFormSchema = Yup.object().shape({
  tag_name: Yup.string()
    .label('Tag Name')
    .required()
    .min(tagNameMinLength)
    .max(300, 'Text exceed the character limit of 300'),
  tag_desc: Yup.string().label('Tag Description').required().max(3000, 'Text exceed the character limit of 3000'),
});

const ExploreTagsSelected = () => {
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const refTagId: number = parseInt(params?.id || '') || 0;
  const { data, isLoading, isFetching } = useGetRefTagByIdQuery(refTagId, {
    skip: refTagId === 0,
  });
  const [putAsync, putDetail] = useUpdateRefTagMutation();

  const { handleSubmit, errors, touched, handleChange, values, submitForm, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: TagFormSchema,
    initialValues: {
      tag_name: data?.tag_name || '',
      tag_desc: data?.tag_desc || '',
    },
    onSubmit: (values, form) => {
      putAsync({
        ref_tag_id: refTagId,
        tag_name: values.tag_name,
        tag_desc: values.tag_desc,
      })
        .unwrap()
        .then(() => {
          toast({ description: `Tag successfully updated`, status: 'success' });
          setIsEdit(false);
          form.resetForm();
        })
        .catch(e => {
          toast({
            description: isError400Range(e) && e.data?.error_message ? e.data.error_message : defaultErrorMessage,
            status: 'error',
          });
        });
    },
  });

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path + location.search);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;
    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <VStack w="100%">
      <HStack alignItems="start">
        <VStack w="container.sm">
          {isLoading || isFetching ? (
            <>
              <Skeleton h="18px" w="20%" my="2" />
              <Skeleton h="15px" w="50%" my="2" />
            </>
          ) : (
            data &&
            (isEdit ? (
              <form onSubmit={handleSubmit}>
                <VStack>
                  <FormControl isInvalid={!!errors.tag_name && touched.tag_name}>
                    {/* <FormLabel htmlFor="tag_name">Tag Name</FormLabel> */}
                    <Input
                      id="tag_name"
                      placeholder="Enter Tag Name"
                      name="tag_name"
                      onChange={handleChange}
                      value={values.tag_name}
                      maxLength={1000}
                    />
                    <FormErrorMessage>{errors.tag_name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.tag_desc && touched.tag_desc}>
                    <HStack justifyContent="space-between">
                      {/* <FormLabel htmlFor="tag_desc">Message</FormLabel> */}
                      {/* {values.tag_desc && values.tag_desc.length > 0 && (
                        <Text as="small">{`${values.tag_desc.length}/3000`}</Text>
                      )} */}
                    </HStack>

                    <Textarea
                      id="tag_desc"
                      placeholder="Enter Tag Description"
                      name="tag_desc"
                      onChange={handleChange}
                      value={values.tag_desc}
                      maxLength={3000}
                    />
                    <FormErrorMessage>{errors.tag_desc}</FormErrorMessage>
                  </FormControl>
                </VStack>
              </form>
            ) : (
              <>
                <HStack>
                  <Heading size="md">{data.tag_name}</Heading>
                  <AppAuth requiredAppAccess={AppAccess.ExploreTagsWrite}>
                    <Button variant="solid" colorScheme="brand.main" onClick={() => setIsEdit(true)}>
                      Edit
                    </Button>
                  </AppAuth>
                </HStack>
                <Text>{data.tag_desc}</Text>
              </>
            ))
          )}
        </VStack>
        <AppAuth requiredAppAccess={AppAccess.ExploreTagsWrite}>
          {isEdit && (
            <HStack>
              <Button
                variant="outline"
                colorScheme="brand.main"
                onClick={() => {
                  setIsEdit(false);
                  resetForm();
                }}
                isDisabled={putDetail.isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="brand.main"
                onClick={() => submitForm()}
                isDisabled={putDetail.isLoading}
                isLoading={putDetail.isLoading}
              >
                Save
              </Button>
            </HStack>
          )}
        </AppAuth>
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>{tabs[tabIndex] && createElement(tabs[tabIndex].component)}</Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default ExploreTagsSelected;
