import { dmeApi } from '.';
import {
  AccessAdminApprovalModel,
  AccessAdminApprovalRoleAfModel,
  AccessAdminApprovalUserAfModel,
  UpdateAdminAccessApprovalDmeRoleModel,
  UpdateAdminAccessApprovalDpRoleModel,
  UpdateAdminAccessApprovalDpSaModel,
  UpdateAdminAccessApprovalDpUserModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getAccessAdminApprovalByRefUserId: builder.query<AccessAdminApprovalModel, number>({
      query: ref_user_id => `/accessadminapproval/ref_user_id/${ref_user_id}`,
      providesTags: ['AccessAdminApproval'],
    }),

    getAccessAdminApprovalUserAfByRefUserId: builder.query<AccessAdminApprovalUserAfModel[], number>({
      query: ref_user_id => `/accessadminapproval/additionalfields/user/ref_user_id/${ref_user_id}`,
      providesTags: ['AccessAdminApproval'],
    }),

    getAccessAdminApprovalRoleAfByRefUserId: builder.query<AccessAdminApprovalRoleAfModel[], number>({
      query: ref_user_id => `/accessadminapproval/additionalfields/role/ref_user_id/${ref_user_id}`,
      providesTags: ['AccessAdminApproval'],
    }),

    putAccessAdminApprovalClaimFlag: builder.mutation<void, UpdateAdminAccessApprovalDmeRoleModel>({
      query: params => ({
        url: '/accessadminapproval/dme_role_request',
        method: 'PUT',
        body: params,
      }),
    }),

    putAccessAdminApprovalDpUserClaimFlag: builder.mutation<void, UpdateAdminAccessApprovalDpUserModel>({
      query: params => ({
        url: '/accessadminapproval/dp_user_data_product_delivery_mode',
        method: 'PUT',
        body: params,
      }),
    }),

    putAccessAdminApprovalDpRoleClaimFlag: builder.mutation<void, UpdateAdminAccessApprovalDpRoleModel>({
      query: params => ({
        url: '/accessadminapproval/dp_role_data_product_delivery_mode',
        method: 'PUT',
        body: params,
      }),
    }),

    putAccessAdminApprovalDpSaClaimFlag: builder.mutation<void, UpdateAdminAccessApprovalDpSaModel>({
      query: params => ({
        url: '/accessadminapproval/dp_sa_data_product_delivery_mode',
        method: 'PUT',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAccessAdminApprovalByRefUserIdQuery,
  useGetAccessAdminApprovalUserAfByRefUserIdQuery,
  useGetAccessAdminApprovalRoleAfByRefUserIdQuery,
  usePutAccessAdminApprovalClaimFlagMutation,
  usePutAccessAdminApprovalDpUserClaimFlagMutation,
  usePutAccessAdminApprovalDpRoleClaimFlagMutation,
  usePutAccessAdminApprovalDpSaClaimFlagMutation,
} = extendedApi;
