import { BuildSchemaArgs } from '../../../../app/helpers/formSchemabuilder';
import { AddGovernanceOfficeEdsModel, GoEdsRequestCreationQaModel } from '../../../../app/services/dme/api/types';
import MyExternalDataSharingRequestsCreateRequestAssociatedUsers from './AssociatedUsers';
import MyExternalDataSharingRequestsCreateRequestDetails from './Details';
import MyExternalDataSharingRequestsCreateRequestQuestionAndAnswer from './QuestionAndAnswer';

export const myEdsRequestCreateRequestTabs = [
  { label: 'Details', component: MyExternalDataSharingRequestsCreateRequestDetails },
  { label: 'Q & A', component: MyExternalDataSharingRequestsCreateRequestQuestionAndAnswer },
  { label: 'Associated Users', component: MyExternalDataSharingRequestsCreateRequestAssociatedUsers },
] as const;

export type MyEdsRequestCreateRequestProps = {
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  data: AddGovernanceOfficeEdsModel;
  setData: React.Dispatch<React.SetStateAction<AddGovernanceOfficeEdsModel>>;
  questions: GoEdsRequestCreationQaModel[];
};

export const initialValuesMyEdsRequestCreateRequest: AddGovernanceOfficeEdsModel = {
  request_subject: '',
  answers: [],
  associated_users: [],
  answer_attachments: [],
};

export const myEdsRequestCreateRequestFields = {
  request_subject: { type: 'string', label: 'Subject', max: 1000 } as BuildSchemaArgs,
  associated_users: {
    ref_user_id: { type: 'id', label: 'User' } as BuildSchemaArgs,
    association_context: { type: 'string', label: 'Association Context', max: 2500 } as BuildSchemaArgs,
  },
};
