import { ButtonGroup, Divider, Heading, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppSize } from '../../../app/constants';
import { AppAccess } from '../../../app/constants/appAccesses';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import useDebounce from '../../../app/hooks/useDebounce';
import { useGetRolesAppAccessListQuery, useLazyGetRolesAppAccessListQuery } from '../../../app/services/dme/api/rolesAppAccess';
import { RolesAppAccessListDataModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader, DynamicObject } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import AppAuth from '../../../features/AppAuth';
import AppAccessByRolesDeleteRoleButton from './DeleteRoleButton';

type CellProp = {
  row: { original: RolesAppAccessListDataModel };
};

const Header = (isAuthorized: boolean) => {
  let header = [];

  const DefaultHeader: CustomTableHeader<RolesAppAccessListDataModel> = [
    {
      Header: 'Role',
      accessor: 'role_name',
      styles: { whiteSpace: 'initial', minWidth: '200px', verticalAlign: 'top' },
    },
    {
      Header: 'Description',
      accessor: 'role_desc',
      styles: { whiteSpace: 'initial', minWidth: '350px', verticalAlign: 'top' },
    },
    {
      Header: 'App Access',
      accessor: 'app_access',
      styles: { whiteSpace: 'initial', textAlign: 'center', verticalAlign: 'top' },
    },
    {
      Header: 'Development Access',
      accessor: 'developer_environments',
      styles: { whiteSpace: 'initial', textAlign: 'center', verticalAlign: 'top' },
    },
    {
      Header: 'Data Products',
      accessor: 'data_product',
      styles: { whiteSpace: 'initial', textAlign: 'center', verticalAlign: 'top' },
    },
    {
      Header: 'Report Portfolios',
      accessor: 'report_portfolio',
      styles: { whiteSpace: 'initial', textAlign: 'center', verticalAlign: 'top' },
    },
    {
      Header: 'Users',
      accessor: 'users',
      styles: { whiteSpace: 'initial', textAlign: 'center', verticalAlign: 'top' },
    },
    {
      Header: 'Last Modified',
      Cell: ({ row: { original } }: CellProp) => {
        return <>{convertUtcToLocal(original.last_modified_datetime_utc) || '-'}</>;
      },
      styles: { whiteSpace: 'initial', minWidth: '190px', verticalAlign: 'top' },
    },
  ];

  const AuthenticatedHeader = [
    {
      Header: 'Action',
      styles: { verticalAlign: 'top' },
      Cell: ({ row: { original } }: CellProp) => {
        const navigate = useNavigate();
        return (
          <ButtonGroup spacing={4}>
            <Tooltip label="Edit">
              <IconButton
                aria-label="Edit"
                color="brand.main.default"
                variant="link"
                icon={<BsFillPencilFill />}
                onClick={e => {
                  e.stopPropagation();
                  navigate('./selected/id/' + original.ref_role_id + '/details', { state: { isEdit: true } });
                }}
                minWidth={1}
              />
            </Tooltip>
            <AppAccessByRolesDeleteRoleButton role={original} />
          </ButtonGroup>
        );
      },
    },
  ];

  if (isAuthorized) header.push(...AuthenticatedHeader);
  header.push(...DefaultHeader);

  return header;
};

const AppAccessByRoles = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [lazyLoadDataLength, setLazyLoadDataLength] = useState(0);
  const [appAccessByRoles, setAppAccessByRoles] = useState<RolesAppAccessListDataModel[]>([]);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  // const [search, setSearch] = useState(searchParams.get('search') || '');
  // const [searchDebounce, setSearchDebounce] = useState(searchParams.get('search') || '');

  // const debouncedSearch = useDebounce(search, 400);

  // const { data, isLoading, isFetching } = useGetRolesAppAccessListQuery({
  //   page_number: pageNumber,
  //   page_size: pageSize,
  //   search_string: searchDebounce,
  //   sort_column: 'role_name',
  //   sort_order: 'asc',
  // });

  const [getAppAccessByRoles, { isLoading, isFetching, data }] = useLazyGetRolesAppAccessListQuery();

  const onClickRow = (role: RolesAppAccessListDataModel) => {
    navigate(`./selected/id/${role.ref_role_id}/details`);
  };

  const fetchPagedRoles = async () => {
    const nextPage = pageNumber + 1;

    const response = await getAppAccessByRoles({
      page_number: nextPage,
      page_size: pageSize,
      search_string: searchParams.get('search') ?? '',
      sort_column: 'role_name',
      sort_order: 'asc',
    }).unwrap();

    const newRecords: RolesAppAccessListDataModel[] = [];

    setAppAccessByRoles(s => [...s, ...response.data]);
    if (newRecords.length === 0) {
      forceRemoveLazyLoaderText();
    }
    setPageNumber(nextPage);
  };

  // (workaround)force remove the infinite loader text if data is empty
  const forceRemoveLazyLoaderText = () => {
    setLazyLoadDataLength(s => ++s);
  };

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  // useEffect(() => {
  //   setSearchDebounce(debouncedSearch);
  //   setSearchParams(!!debouncedSearch ? { search: debouncedSearch } : '', { replace: true });
  // }, [debouncedSearch]);

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  useEffect(() => {
    setLazyLoadDataLength(appAccessByRoles.length);
  }, [appAccessByRoles]);

  useEffect(() => {
    const run = async () => {
      setPageNumber(1);
      setAppAccessByRoles([]);
      const response = await getAppAccessByRoles({
        page_number: 1,
        page_size: pageSize,
        search_string: searchParams.get('search') ?? '',
        sort_column: 'role_name',
        sort_order: 'asc',
      }).unwrap();
      setAppAccessByRoles(response.data);

      const tableHeadDiv = document.getElementById('infinite-scroll-table-head');
      if (tableHeadDiv) {
        tableHeadDiv.scrollIntoView({});
        window.scrollTo(0, 0);
      }
    };
    run();
  }, [searchParams.get('search')]);

  return (
    <VStack>
      <Heading size="md" mt="5" mb="2">
        App Access By Roles
      </Heading>
      <Divider />
      <AppAuth requiredAppAccess={AppAccess.AppAccessByRolesWrite}>
        {isAuthorized => (
          <CustomTable
              variant="infinite-scroll-table"
              isLoading={isLoading}
              isFetching={isFetching}
              data={appAccessByRoles}
              pageCount={0}
              pageSize={pageSize}
              totalRecords={data?.total_records || 0}
              pageIndex={pageNumber - 1}
              headers={Header(isAuthorized)}
              search={searchParams.get('search') ?? ''}
              manualSortBy
              disableSortRemove
              onPageChange={index => {
              }}
              onPageSizeChange={size => {
              }}
              onPageSearchDebounce={400}
              onPageSearch={search => {
                const params: DynamicObject = {};
                searchParams.forEach((val, key) => (params[key] = val));
                setSearchParams({ ...params, search: search }, { replace: true });
              }}
              onSort={() => {}}
              manual={true}
              onRowClick={onClickRow}
              infiniteScrollProps={{
                props: {
                  dataLength: lazyLoadDataLength,
                  next: fetchPagedRoles,
                  hasMore: pageNumber < (data?.total_pages ?? 0),
                  loader: appAccessByRoles.length ? <h5>Loading...</h5> : <></>,
                  scrollableTarget: 'scrollableDiv',
                },
                parentProps: {
                  style: {
                    height: 'calc(100dvh - 240px)',
                    overflow: 'auto',
                  },
                },
              }}
              styles={{
                pagination: { justifyContent: 'start' },
                header: { justifyContent: 'left' },
                tableContainer: {
                  sx: {
                    maxW: `calc(100vw - 3.5rem - ${sideNavWidth})`,
                    //maxW: `calc(100vw - 5.75rem - 300px - ${sideNavWidth})`,
                    overflow: 'auto',
                    table: {
                      borderCollapse: 'separate',
                      borderSpacing: '0',
                      'thead > tr': {
                        position: 'sticky',
                        left: 0,
                        top: 0,
                        zIndex: 2,
                        height: 'auto',
                        bg: 'white',
                      },
                      tr: {
                        'th:last-child': {
                          position: 'sticky',
                          right: '0px',
                          zIndex: 2,
                          bg: 'white',
                          w: '90px',
                          borderLeft: '1px',
                          borderColor: 'gray.100',
                        },
                        'td:last-child': {
                          position: 'sticky',
                          right: '0px',
                          zIndex: 2,
                          bg: 'white',
                          w: '90px',
                          borderLeft: '1px',
                          borderColor: 'gray.100',
                        },
                      },
                    },
                  },
                },
              }}
            />
        )}
      </AppAuth>
    </VStack>
  );
};

export default AppAccessByRoles;
