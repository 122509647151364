import {
  Box,
  Button,
  CloseButton,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MultiValue, Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useGetRefTagListQuery } from '../../../app/services/dme/api/refTags';
import { RefTagModel } from '../../../app/services/dme/api/types';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { InfoweaveFilterTabName, InfoweaveParams, InfoweaveReadFilter } from '../InfoweaveContext';

// const minDate = dayjs().toDate();
// const maxDate = dayjs().add(1, "year").toDate();
// const defaultDate = dayjs().add(30, "day").toDate();
const startOfEpochDate = dayjs(new Date(0)).startOf('day').toDate();
const startOfDayToday = dayjs().startOf('day').toDate();
const endOfDayToday = dayjs().endOf('day').toDate();
type RefTagSelectModel = { label: string; value: number; data: RefTagModel };

const StartDateSchema = Yup.string()
  .test((str, { createError }) => {
    const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
    return date.isValid()
      ? true
      : createError({
          message: 'Start Date in invalid',
          path: 'startDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isAfter(startOfEpochDate)
      ? true
      : createError({
          message: 'Start Date must be later than start of epoch',
          path: 'startDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isBefore(startOfDayToday)
      ? true
      : createError({
          message: 'Start Date may not exceed today',
          path: 'startDate',
        });
  })
  .label('Start Date')
  .required();

const EndDateSchema = Yup.string()
  .test((str, { createError }) => {
    const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
    return date.isValid()
      ? true
      : createError({
          message: 'End Date in invalid',
          path: 'endDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isAfter(startOfEpochDate)
      ? true
      : createError({
          message: 'End Date must be later than start of epoch',
          path: 'endDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isBefore(endOfDayToday)
      ? true
      : createError({
          message: 'End Date may not exceed today',
          path: 'endDate',
        });
  })
  .label('End Date')
  .required();

const FormSchema = Yup.object().shape({
  startDate: StartDateSchema,
  endDate: EndDateSchema,
});

const InfoweaveFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tagDetails = useGetRefTagListQuery({ infoweave_flag: true });

  const tabParam = useMemo(() => searchParams.get(InfoweaveParams.tab) as InfoweaveFilterTabName, [searchParams]);
  const readQueryParams: InfoweaveReadFilter = (searchParams.get(InfoweaveParams.read) as InfoweaveReadFilter) ?? 'all';
  const [readFilter, setReadFilter] = useState<InfoweaveReadFilter>(readQueryParams);

  const sdQueryParams = dayjs(searchParams.get(InfoweaveParams.startDate));
  const defaultStartDate = sdQueryParams.isValid() ? sdQueryParams.toDate() : undefined;
  const edQueryParams = dayjs(searchParams.get(InfoweaveParams.endDate));
  const defaultEndDate = edQueryParams.isValid() ? edQueryParams.toDate() : undefined;
  const [startDate, setStartDate] = useState<Date | undefined>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate);

  const [tags, setTags] = useState<MultiValue<RefTagSelectModel>>([]);

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      startDate: defaultStartDate ? dayjs(defaultStartDate).format('YYYY-MM-DD') : undefined,
      endDate: defaultEndDate ? dayjs(defaultEndDate).format('YYYY-MM-DD') : undefined,
    },
    onSubmit: values => {},
  });

  useEffect(() => {
    if (tagDetails.data && tagDetails.isSuccess) {
      const list: RefTagSelectModel[] = [];
      (searchParams.get(InfoweaveParams.tags) || '').split(',').forEach(id => {
        const t = tagDetails.data.find(t => t.ref_tag_id.toString() === id);
        if (t) list.push({ label: t.tag_name, value: t.ref_tag_id, data: t });
        setTags(list);
      });
    }
  }, [tagDetails]);

  useEffect(() => {
    setReadFilter('all');
    setStartDate(undefined);
    setEndDate(undefined);
    setTags([]);
  }, [tabParam]);

  const filterCount = useMemo(() => {
    const readCount = !!searchParams.get(InfoweaveParams.read) ? 1 : 0;
    const dateCount =
      !!searchParams.get(InfoweaveParams.startDate) || !!searchParams.get(InfoweaveParams.endDate) ? 1 : 0;
    const tagsCount = !!searchParams.get(InfoweaveParams.tags) ? 1 : 0;

    return readCount + dateCount + tagsCount;
  }, [searchParams]);

  return (
    <Menu isLazy>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            leftIcon={
              <HStack>
                {filterCount > 0 && (
                  <Box
                    fontSize="12px"
                    color="white"
                    bg="brand.main.default"
                    w="5"
                    h="5"
                    rounded="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {filterCount}
                  </Box>
                )}
                <Icon as={BiFilter} fontSize={24} />
              </HStack>
            }
            rightIcon={<Icon as={isOpen ? FaChevronUp : FaChevronDown} fontSize={14} ml="5" mr="1" />}
          >
            Actions
          </MenuButton>

          <MenuList>
            <Box px="4" w="336px">
              <HStack>
                <Heading as="h5" size="sm" flex={1}>
                  Filters
                </Heading>
                <CloseButton onClick={onClose} />
              </HStack>

              <Box my="4">
                <Text fontWeight="semibold" mb="2">
                  View
                </Text>
                <RadioGroup
                  defaultValue={readQueryParams}
                  colorScheme="brand.main"
                  onChange={e => {
                    setReadFilter(e as InfoweaveReadFilter);
                  }}
                >
                  <Stack ml="2">
                    <Radio value="all">All</Radio>
                    <Radio value="read">Read</Radio>
                    <Radio value="unread">Unread</Radio>
                  </Stack>
                </RadioGroup>
              </Box>

              <Box my="4">
                <Text fontWeight="semibold" mb="2">
                  Date Range
                </Text>

                <HStack>
                  <CustomDatePicker
                    id="startDate"
                    name="startDate"
                    date={startDate}
                    onDateChange={(date: Date) => {
                      setValues({
                        ...values,
                        startDate: dayjs(date).format('YYYY-MM-DD'),
                      });
                      setStartDate(date);
                    }}
                    minDate={startOfEpochDate}
                    maxDate={startOfDayToday}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'brand.main.default',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                        },
                        selectedBtnProps: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.400',
                        },
                      },
                      inputProps: {
                        placeholder: 'YYYY-MM-DD',
                        size: 'sm',
                        value: values.startDate,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            startDate: e.target.value,
                          });
                          if (dayjs(e.target.value)?.isValid()) {
                            setStartDate(dayjs(e.target.value).toDate());
                          } else {
                            setStartDate(undefined);
                          }
                        },
                      },
                    }}
                  />

                  <CustomDatePicker
                    id="endDate"
                    name="endDate"
                    date={endDate}
                    onDateChange={(date: Date) => {
                      setValues({
                        ...values,
                        endDate: dayjs(date).format('YYYY-MM-DD'),
                      });
                      setEndDate(date);
                    }}
                    minDate={startOfEpochDate}
                    maxDate={endOfDayToday}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'brand.main.default',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                        },
                        selectedBtnProps: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.400',
                        },
                      },
                      inputProps: {
                        placeholder: 'YYYY-MM-DD',
                        size: 'sm',
                        value: values.endDate,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            endDate: e.target.value,
                          });
                          if (dayjs(e.target.value)?.isValid()) {
                            setEndDate(dayjs(e.target.value).toDate());
                          } else {
                            setEndDate(undefined);
                          }
                        },
                      },
                    }}
                  />
                </HStack>
              </Box>

              <Box my="4">
                <Text fontWeight="semibold" mb="2">
                  Tags
                </Text>
                <Select
                  placeholder={tagDetails.isLoading || tagDetails.isFetching ? 'Loading...' : 'Select a tag...'}
                  isMulti
                  useBasicStyles
                  value={tags}
                  isDisabled={!tagDetails.data || tagDetails.isLoading || tagDetails.isFetching}
                  options={(tagDetails.data ?? []).map(m => ({ label: m.tag_name, value: m.ref_tag_id, data: m }))}
                  onChange={e => {
                    setTags(e);
                  }}
                  chakraStyles={{
                    // option: (provided, state) => {
                    //   return {
                    //     ...provided,
                    //     color: state.data.data.color_hex || undefined,
                    //   };
                    // },
                    multiValue: (provided, state) => {
                      return {
                        ...provided,
                        bg: state.data.data.color_hex || undefined,
                        color: 'white',
                      };
                    },
                  }}
                />
              </Box>

              <HStack w="100%">
                <Button variant="ghost" ml="auto" color="brand.main.default" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.main"
                  onClick={() => {
                    setSearchParams(
                      s => {
                        if (readFilter) {
                          s.set(InfoweaveParams.read, readFilter);
                        } else {
                          s.delete(InfoweaveParams.read);
                        }

                        if (startDate) {
                          s.set(InfoweaveParams.startDate, dayjs(startDate).format('YYYY-MM-DD'));
                        } else {
                          s.delete(InfoweaveParams.startDate);
                        }

                        if (endDate) {
                          s.set(InfoweaveParams.endDate, dayjs(endDate).format('YYYY-MM-DD'));
                        } else {
                          s.delete(InfoweaveParams.endDate);
                        }

                        if (tags.length > 0) {
                          s.set(InfoweaveParams.tags, tags.map(t => t.value).join(','));
                        } else {
                          s.delete(InfoweaveParams.tags);
                        }

                        return s;
                      },
                      { replace: true },
                    );
                    onClose();
                  }}
                >
                  Apply
                </Button>
              </HStack>
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default InfoweaveFilter;
