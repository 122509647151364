import { Box, Button, HStack, Heading } from '@chakra-ui/react';
import { FaUnlockAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AppAccess } from '../../../app/constants/appAccesses';
import AppAuth from '../../../features/AppAuth';
import MyExternalDataSharingRequestsFilter from './Filter';
import MyExternalDataSharingRequestsTable from './Table';

type Props = {};

const MyExternalDataSharingRequests = (props: Props) => {
  return (
    <Box w="100%">
      <HStack mb="6">
        <Heading as="h2" size="sm">
          My External Data Sharing Requests
        </Heading>
        <AppAuth requiredAppAccess={AppAccess.MyEdsRequestsWrite}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="brand.main"
            leftIcon={<FaUnlockAlt />}
            as={Link}
            to="./create-request"
          >
            Create Request
          </Button>
        </AppAuth>
      </HStack>
      <HStack gap="6" alignItems="start">
        <MyExternalDataSharingRequestsFilter />
        <MyExternalDataSharingRequestsTable />
      </HStack>
    </Box>
  );
};

export default MyExternalDataSharingRequests;
