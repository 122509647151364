import { Grid, GridItem } from '@chakra-ui/react';
import { useState } from 'react';
import { FeedbackModel } from '../../../app/services/dme/api/types';
import PanelCard from '../../../components/PanelCard';
import FeedbackAttachments from './FeedbackAttachments';
import FeedbackList from './FeedbackList';
import FeedbackMessage from './FeedbackMessage';
type Props = {};

const ReviewFeedback = (props: Props) => {
  const [feedback, setFeedback] = useState<FeedbackModel | null>(null);
  return (
    <Grid templateColumns="repeat(3, minmax(450px, 1fr))" gap={3}>
      <GridItem rowSpan={2}>
        <PanelCard header="Feedback" fullHeight>
          <FeedbackList
            onFeedbackClick={f => {
              setFeedback(f);
            }}
            feedback={feedback}
          />
        </PanelCard>
      </GridItem>
      {feedback && (
        <>
          <GridItem colSpan={2}>
            <PanelCard
              header="Message"
              fullHeight
              onClose={() => {
                setFeedback(null);
              }}
            >
              <FeedbackMessage feedback={feedback} />
            </PanelCard>
          </GridItem>
          <GridItem colSpan={2}>
            <PanelCard
              header="Attachment List"
              fullHeight
              // onClose={() => {
              //   setFeedback(null);
              // }}
            >
              <FeedbackAttachments dmeFeedbackId={feedback.dme_feedback_id} />
            </PanelCard>
          </GridItem>
        </>
      )}
    </Grid>
  );
};

export default ReviewFeedback;
