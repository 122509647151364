import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AddPlatformServiceAnswerModel,
  AddPlatformServiceModel,
} from '../../../../app/services/dme/api/types';
import { ExplorePlatfromServicesCreateRequestProps, explorePlatfromServicesCreateRequestTabs, initialValuesExplorePlatfromServicesCreateRequest } from './utils';
import { useAddPlatformServicesRequestMutation, useGetPlatformServicesRequestCreationQaListQuery } from '../../../../app/services/dme/api/platformServices';

const defaultErrorMessage = 'This question is required';

const determineNoErrors = (type: string, answer: AddPlatformServiceAnswerModel) => {
  if (type === 'Extended Open Text Box') {
    return !!answer.user_answer_text_box;
  } else if (type === 'Yes/No Questions' || type === 'Dropdown') {
    return !!answer.user_answer_option || !!answer.reference_table_name_id;
  }
  return false;
};

const ExplorePlatfromServicesCreateRequestQuestionAndAnswer: FC<ExplorePlatfromServicesCreateRequestProps> = ({
  tabIndex,
  setTabIndex,
  data,
  setData,
  questions,
  referenceTableContents,
  original,
}) => {
  const actionRef = useRef<number>(0);
  const toast = useToast();
  const navigate = useNavigate();

  // API call to get the list of questions 
  const psRefCategoryId: number = original.ps_ref_category_id;
  const qaListDetails = useGetPlatformServicesRequestCreationQaListQuery({ psRefCategoryId }, { skip: psRefCategoryId === 0 });
  // API call to add details to request
  const [addRequest, addRequestDetails] = useAddPlatformServicesRequestMutation();

  const FormSchema = Yup.object().shape({
    answers: Yup.array().of(
      Yup.object().test((a, { createError, path }) => {
        const answer = a as unknown as AddPlatformServiceAnswerModel;
        const questionIndex = questions.findIndex(q => q.ps_ref_question_id === answer.ps_ref_question_id);
        const question = questions[questionIndex];
        if (!question) {
          return createError({
            message: 'Invalid Question',
            path: 'ps_ref_question_id',
          });
        }

        const errors: Yup.ValidationError[] = [];
        
        const type = question?.question_type;
        const hasNoError = determineNoErrors(type, answer);
        if (!hasNoError) {
          errors.push(
            new Yup.ValidationError(defaultErrorMessage, undefined, `answers.${questionIndex}.ps_ref_question_id`),
          );
        }

        // return true;
        return errors.length <= 0 ? true : createError({ message: () => errors });
      }),
    ),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    submitForm,
  } = useFormik<AddPlatformServiceModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      if (actionRef.current) {
        const d: AddPlatformServiceModel = { ...data, ...values };
        setData(d);
        if (actionRef.current > 0) {
          addRequest(d)
            .unwrap()
            .then(() => {
              toast({ description: 'Platform service request successfully created.', status: 'success', duration: 10000 });
              setData(initialValuesExplorePlatfromServicesCreateRequest);
              setTabIndex(0);
              navigate('/platform-services/explore-platform-services');
            })
            .catch(() => toast({ description: "Creation failed. Contact admin.", status: 'error', duration: 10000 }));
        } 
      }
    },
  });

  const handleAnswerChange = (index: number, field: string, value: any) => {
    const tmp = values.answers.map(r => ({ ...r }));
    switch(field){
      case 'user_answer_option':
        tmp[index].user_answer_option = value;
        break;
      case 'reference_table_name_id':
        tmp[index].reference_table_name_id = value;
        break;
      default:
        tmp[index].user_answer_text_box = value;
    }
    setValues({ ...values, answers: tmp });
  };

  return qaListDetails.isLoading || qaListDetails.isFetching ? (
    <>Loading...</>
  ) : (
    <VStack pt="4">
      {questions.map((question, i) => (
        <Box key={question.ps_ref_question_id} pb="4">
          <Text fontWeight="bold">{question.question_instruction_text}</Text>
          {(() => {
            return (
              <>
                <FormControl
                  isInvalid={!!errors.answers && !!(errors.answers[i] as any)?.ps_ref_question_id && !!touched.answers && !!touched.answers[i]?.ps_ref_question_id}
                  h="100%"
                >
                  {question.question_type === 'Yes/No Questions' && (
                    <RadioGroup
                      size = "sm"
                      value={values.answers[i]?.user_answer_option || ''}
                      onChange={value => handleAnswerChange(i, 'user_answer_option', value)}
                    >
                      <HStack>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                  {question.question_type === 'Dropdown' && question.use_reference_table_flag === true && (
                    <Select
                      size = "sm"
                      width= '200px'
                      placeholder="Select option"
                      value={values.answers[i]?.reference_table_name_id || ''}
                      onChange={e => handleAnswerChange(i, 'reference_table_name_id', parseInt(e.target.value))}
                    >
                      {referenceTableContents
                        .filter(ref => ref.ps_ref_question_id === question.ps_ref_question_id)
                        .map(ref => (
                          <option key={ref.pk_id} value={ref.pk_id}>{ref.pk_name}</option>
                        ))}
                    </Select>
                  )}
                  {question.question_type === 'Dropdown' && question.use_reference_table_flag === false && (
                    <Select
                      size = "sm"
                      placeholder="Select option"
                      width= '200px'
                      value={values.answers[i]?.user_answer_option || ''}
                      onChange={e => handleAnswerChange(i, 'user_answer_option', e.target.value)}
                    >
                      <option value ="yes">Yes</option>
                      <option value ="no">No</option>
                    </Select>
                  )}
                  {question.question_type === 'Extended Open Text Box' && (
                    <Textarea
                      size = "sm"
                      rows = {4}
                      width="50%"
                      value={values.answers[i]?.user_answer_text_box || ''}
                      onChange={e => handleAnswerChange(i, 'user_answer_text_box', e.target.value)}
                    />
                  )}
                  <FormErrorMessage>{!!errors.answers && (errors.answers[i] as any)?.ps_ref_question_id}</FormErrorMessage>
                </FormControl>
              </>
            );
          })()}
        </Box>
      ))}

      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {explorePlatfromServicesCreateRequestTabs.length}
        </Text>
        <ButtonGroup>
          <Button 
            size="sm"
            isDisabled={tabIndex === 0} onClick={() => setTabIndex(tabIndex - 1)}
            colorScheme="brand.main"
          >
            Previous
          </Button>
          <Button
            size="sm"
            isLoading={addRequestDetails.isLoading}
            colorScheme="brand.main"
            onClick={() => {
              actionRef.current = 1;
              submitForm();
            }}
          >
            Submit
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};


export default ExplorePlatfromServicesCreateRequestQuestionAndAnswer;