import { Alert, Box, IconButton, Table, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { viewableExt } from '../../../app/constants';
import { useGetFeedbackAttachmentsQuery } from '../../../app/services/dme/api/feedback';
import { FeedbackAttachmentModel } from '../../../app/services/dme/api/types';

type Props = {
  dmeFeedbackId: number;
};

const FeedbackAttachments = ({ dmeFeedbackId }: Props) => {
  const { data, isLoading, isFetching } = useGetFeedbackAttachmentsQuery(dmeFeedbackId);

  const onClickDownload = (item: FeedbackAttachmentModel) => {
    console.log('URL', item.feedback_attachment_url);
    window.open(item.feedback_attachment_url, '_blank');
  };
  return (
    <TableContainer h="calc(50vh - 170px)" overflowY="auto">
      {isLoading || isFetching ? (
        <>Loading...</>
      ) : (
        <Table variant="hover" size="sm">
          <Tbody>
            <>
              {data &&
                data.map(item => {
                  const isViewable = viewableExt.some(s =>
                    s.includes(item.feedback_attachment_url?.split('.')?.pop() || ''),
                  );
                  return (
                    <Tr key={item.dme_feedback_attachment_id} _hover={{ bgColor: 'gray.200' }}>
                      <Td>
                        <Box alignItems="center" display="flex" maxW="600px">
                          <Text
                            noOfLines={1}
                            whiteSpace="normal"
                            wordBreak="break-all"
                            title={item.feedback_attachment_name}
                          >
                            {item.feedback_attachment_name}
                          </Text>
                          <IconButton
                            variant="link"
                            color="links"
                            size="md"
                            aria-label={isViewable ? 'View' : 'Download'}
                            icon={isViewable ? <HiOutlineExternalLink /> : <MdOutlineSimCardDownload />}
                            title={isViewable ? 'View' : 'Download'}
                            onClick={() => onClickDownload(item)}
                          />
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
            </>
          </Tbody>
          {data && data.length == 0 && <Alert>No Record(s)</Alert>}
        </Table>
      )}
    </TableContainer>
  );
};

export default FeedbackAttachments;
