import { Alert, VStack } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { ImportantLinkDetailsPageDiscussionModel } from '../../../../../app/services/dme/api/types';
import PostItem from './PostItem';

type Props = {
  postList: ImportantLinkDetailsPageDiscussionModel[];
  onClickRow: (user: ImportantLinkDetailsPageDiscussionModel | null) => void;
  selectedPost: ImportantLinkDetailsPageDiscussionModel | null;
};

const PostList: FC<Props> = ({ postList, selectedPost, onClickRow }) => {
  return (
    <VStack maxH="calc(100vh - 250px)" overflowY="auto" spacing={4}>
      {postList && postList.length == 0 && <Alert status="error">No Post(s)</Alert>}
      {postList.map((m: ImportantLinkDetailsPageDiscussionModel) => (
        <Fragment key={m.link_post_id}>
          <PostItem link_post_id={selectedPost?.link_post_id} selectedPost={m} onClick={onClickRow} />
        </Fragment>
      ))}
    </VStack>
  );
};

export default PostList;
