import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { FormikErrors, FormikTouched } from 'formik';
import { FC, Fragment } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { DefaultInnerHtmlStyle } from '../../../../../app/constants';
import { getName } from '../../../../../app/helpers/stringHelper';
import { useGetRefTagListQuery } from '../../../../../app/services/dme/api/refTags';
import {
  useGetRhAllReportStatesQuery,
  useGetRhPortfolioSelectedReportsByRhpidQuery,
} from '../../../../../app/services/dme/api/reportHub';
import { UpdateReportHubPortfolioModel } from '../../../../../app/services/dme/api/types';
import { useGetRefUserListQuery } from '../../../../../app/services/dme/api/user';
import { useAppSelector } from '../../../../../app/state/hooks';
import { EditComponentProps, reportFields } from '../../../helpers';

const ReportHubExplorePortfoliosSelectedEditReports: FC<EditComponentProps> = ({ form }) => {
  const {
    handleSubmit,
    errors: rootError,
    touched: rootTouched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = form;
  const errors = (rootError?.reports ?? []) as FormikErrors<UpdateReportHubPortfolioModel['reports']>;
  const touched = (rootTouched?.reports ?? []) as FormikTouched<UpdateReportHubPortfolioModel['reports']>;

  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const rhpId: number = parseInt(params?.rhpId || '0') || 0;
  const { isLoading, isFetching } = useGetRhPortfolioSelectedReportsByRhpidQuery(
    { userId, rhpId },
    {
      skip: rhpId === 0,
    },
  );
  const usersDetails = useGetRefUserListQuery({ disabled_flag: false });
  const tagsDetails = useGetRefTagListQuery();
  const statesDetails = useGetRhAllReportStatesQuery();

  const selectedState = (index: number) => {
    return statesDetails.data?.find(f => f.rh_report_state_id === values.reports[index]?.rh_report_state_id);
  };

  return isLoading || isFetching ? (
    <>Loading...</>
  ) : (
    <VStack>
      {values.reports.length > 0 && (
        <TableContainer>
          <Table
            variant="simple"
            sx={{
              td: {
                verticalAlign: 'top',
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>{reportFields.report_name.label}</Th>
                <Th>{reportFields.report_embed_link.label}</Th>
                <Th>Tags</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {values.reports.map((r, i) => {
                return (
                  <Fragment key={i}>
                    <Tr sx={{ '& td': { border: 'none' } }}>
                      <Td>
                        <FormControl isInvalid={!!errors[i]?.report_name && !!touched[i]?.report_name} h="100%">
                          <Input
                            size="sm"
                            id={'reports.' + i + '.report_name'}
                            name={'reports.' + i + '.report_name'}
                            value={r.report_name ?? ''}
                            maxLength={reportFields.report_name.max}
                            onChange={handleChange}
                            onBlur={handleChange}
                          />
                          <FormErrorMessage>{errors[i]?.report_name}</FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <FormControl isInvalid={!!errors[i]?.report_embed_link && !!touched[i]?.report_embed_link}>
                          <Textarea
                            size="sm"
                            id={'reports.' + i + '.report_embed_link'}
                            name={'reports.' + i + '.report_embed_link'}
                            value={r.report_embed_link ?? ''}
                            maxLength={reportFields.report_embed_link.max}
                            onChange={handleChange}
                            onBlur={handleChange}
                          />
                          <FormErrorMessage>{errors[i]?.report_embed_link}</FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td maxW="300px" w="300px">
                        <FormControl isInvalid={!!errors[i]?.tags && !!touched[i]?.tags}>
                          <Box w="100%">
                            <Select
                              size="sm"
                              id={'reports.' + i + '.tags'}
                              name={'reports.' + i + '.tags'}
                              placeholder={tagsDetails.isLoading || tagsDetails.isFetching ? 'Loading...' : ''}
                              isDisabled={tagsDetails.isLoading || tagsDetails.isFetching}
                              useBasicStyles
                              isMulti
                              menuPortalTarget={document.body}
                              value={(() => {
                                return r.tags.map(m => {
                                  const t = tagsDetails.data?.find(t => t.ref_tag_id === m.ref_tag_id);
                                  return { label: t?.tag_name ?? '', value: m.ref_tag_id };
                                });
                              })()}
                              options={[
                                ...(tagsDetails.data ?? []).map(m => {
                                  return {
                                    label: m.tag_name,
                                    value: m.ref_tag_id,
                                  };
                                }),
                              ]}
                              onChange={e => {
                                if (e) {
                                  const tmp = values.reports.map(r => structuredClone(r));
                                  const tags = e.map(entry => ({ ref_tag_id: entry.value, notes: '' }));
                                  tmp[i].tags = tags;
                                  setValues({ ...values, reports: tmp });
                                }
                              }}
                            />
                          </Box>
                          <FormErrorMessage></FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <Tooltip label="Delete">
                          <IconButton
                            color="brand.error"
                            variant="link"
                            aria-label="Delete"
                            icon={<FaTrash />}
                            onClick={() => {
                              const tmp = [...values.reports];
                              tmp.splice(i, 1);
                              setValues({ ...values, reports: tmp });
                            }}
                            minWidth={1}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                    <Tr sx={{ '& td': { border: 'none' } }}>
                      <Td colSpan={3}>
                        <FormControl isInvalid={!!errors[i]?.report_desc && !!touched[i]?.report_desc}>
                          <VStack>
                            <Heading as="h5" fontSize="xs" letterSpacing="wider">
                              DESCRIPTION
                            </Heading>
                            <Textarea
                              size="sm"
                              id={'reports.' + i + '.report_desc'}
                              name={'reports.' + i + '.report_desc'}
                              value={r.report_desc ?? ''}
                              maxLength={reportFields.report_desc.max}
                              onChange={handleChange}
                              onBlur={handleChange}
                            />
                            <FormErrorMessage>{errors[i]?.report_desc}</FormErrorMessage>
                          </VStack>
                        </FormControl>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td colSpan={1}>
                        <FormControl
                          isInvalid={!!errors[i]?.rh_report_state_id && !!touched[i]?.rh_report_state_id}
                          display="flex"
                        >
                          <VStack w="100%">
                            <Heading as="h5" fontSize="xs" letterSpacing="wider">
                              STATE
                            </Heading>
                            <Select
                              id={i + '.rh_report_state_id'}
                              name={i + '.rh_report_state_id'}
                              useBasicStyles
                              menuPortalTarget={document.body}
                              size="sm"
                              placeholder={statesDetails.isLoading || statesDetails.isFetching ? 'Loading...' : ''}
                              isDisabled={statesDetails.isLoading || statesDetails.isFetching}
                              chakraStyles={{
                                container: (provided, state) => {
                                  return {
                                    ...provided,
                                    color: selectedState(i) ? selectedState(i)?.rh_report_state_color : undefined,
                                  };
                                },
                                control: (provided, state) => {
                                  return {
                                    ...provided,
                                    borderColor: selectedState(i) ? selectedState(i)?.rh_report_state_color : 'inherit',
                                    _focusVisible: 'none',
                                    _hover: 'none',
                                  };
                                },
                                option: (provided, state) => {
                                  const isSelected = values.reports[i].rh_report_state_id === state.data.value;
                                  return {
                                    ...provided,
                                    color: isSelected ? 'white' : state.data.data?.rh_report_state_color, // if selected white
                                    bg: isSelected ? state.data.data?.rh_report_state_color : undefined, // if selected state.data.data.rh_report_state_color
                                  };
                                },
                              }}
                              options={(statesDetails.data ?? []).map(m => ({
                                value: m.rh_report_state_id,
                                label: m.rh_report_state_name,
                                data: m,
                              }))}
                              value={(() => {
                                return selectedState(i)
                                  ? {
                                      value: selectedState(i)?.rh_report_state_id,
                                      label: selectedState(i)?.rh_report_state_name,
                                      data: selectedState(i),
                                    }
                                  : undefined;
                              })()}
                              onChange={e => {
                                if (e && e.value) {
                                  const tmp = values.reports.map(r => structuredClone(r));
                                  tmp[i].rh_report_state_id = e.value;
                                  setValues({ ...values, reports: tmp });
                                }
                              }}
                              components={{
                                Option: props => {
                                  return (
                                    <chakraComponents.Option {...props}>
                                      <Box w="100%">
                                        <Tooltip
                                          label={
                                            <Box
                                              dangerouslySetInnerHTML={{
                                                __html: props.data.data?.rh_report_state_description ?? '<></>',
                                              }}
                                              sx={DefaultInnerHtmlStyle}
                                            ></Box>
                                          }
                                          placement="right"
                                        >
                                          <Text>{props.label}</Text>
                                        </Tooltip>
                                      </Box>
                                    </chakraComponents.Option>
                                  );
                                },
                                SingleValue: props => {
                                  return (
                                    <chakraComponents.SingleValue {...props}>
                                      <Tooltip
                                        label={
                                          <Box
                                            dangerouslySetInnerHTML={{
                                              __html: props.data.data?.rh_report_state_description ?? '<></>',
                                            }}
                                            sx={DefaultInnerHtmlStyle}
                                          ></Box>
                                        }
                                        placement="right"
                                      >
                                        {props.data.label}
                                      </Tooltip>
                                    </chakraComponents.SingleValue>
                                  );
                                },
                              }}
                            />
                            <FormErrorMessage>{errors[i]?.rh_report_state_id}</FormErrorMessage>
                          </VStack>
                        </FormControl>
                      </Td>
                      <Td colSpan={1}>
                        <FormControl
                          isInvalid={!!errors[i]?.report_developer_user_id && !!touched[i]?.report_developer_user_id}
                          display="flex"
                        >
                          <VStack w="100%">
                            <Heading as="h5" fontSize="xs" letterSpacing="wider">
                              DEVELOPER
                            </Heading>
                            <Select
                              size="sm"
                              id={i + '.report_developer_user_id'}
                              name={i + '.report_developer_user_id'}
                              placeholder={usersDetails.isLoading || usersDetails.isFetching ? 'Loading...' : ''}
                              isDisabled={usersDetails.isLoading || usersDetails.isFetching}
                              useBasicStyles
                              menuPortalTarget={document.body}
                              value={(() => {
                                const val = usersDetails.data?.find(m => m.ref_user_id === r.report_developer_user_id);
                                return val
                                  ? {
                                      label: getName(val),
                                      value: val.ref_user_id,
                                    }
                                  : undefined;
                              })()}
                              options={(usersDetails.data ?? []).map(m => {
                                return {
                                  label: m.first_name + ' ' + m.last_name,
                                  value: m.ref_user_id,
                                };
                              })}
                              onChange={e => {
                                if (e && e.value) {
                                  const tmp = values.reports.map(r => structuredClone(r));
                                  tmp[i].report_developer_user_id = e.value;
                                  setValues({ ...values, reports: tmp });
                                }
                              }}
                            />
                            <FormErrorMessage>{errors[i]?.report_developer_user_id}</FormErrorMessage>
                          </VStack>
                        </FormControl>
                      </Td>
                      <Td colSpan={2}></Td>
                    </Tr>
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Box>
        <Button
          colorScheme="brand.main"
          size="sm"
          onClick={() => {
            setValues({
              ...values,
              reports: [
                ...values.reports,
                {
                  report_name: '',
                  report_desc: '',
                  rh_report_state_id: 0,
                  report_developer_user_id: 0,
                  report_embed_link: '',
                  tags: [],
                },
              ],
            });
          }}
        >
          Create New
        </Button>
      </Box>
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedEditReports;
