import { useAccount } from '@azure/msal-react';
import { memo, useEffect } from 'react';
import { getIdTokenClaims } from '../../app/services/auth/helper';

const Heap = memo(() => {
  const account = useAccount();
  useEffect(() => {
    const { emails } = getIdTokenClaims(account);
    if (emails) {
      (window as any).heap.identify(emails[0]);
    }
  }, []);

  return <></>;
});

export default Heap;
