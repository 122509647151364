import { dmeApi } from './index';
import { RefRefreshFrequencyModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefRefreshFrequency: builder.query<RefRefreshFrequencyModel[], void>({
      query: () => `/refrefreshfrequency`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefRefreshFrequencyQuery } = extendedApi;
