import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRoleAppAccessByEmailModel } from '../services/dme/api/types';
type UserRoleState = {
  currentUserUserRole?: UserRoleAppAccessByEmailModel[];
};

const initialState: UserRoleState = {};

export const userRoleSlice = createSlice({
  name: 'userRoleSlice',
  initialState,
  reducers: {
    setCurrentUserUserRole: (state, action: PayloadAction<UserRoleAppAccessByEmailModel[]>) => {
      state.currentUserUserRole = action.payload;
    },
  },
});

export const { setCurrentUserUserRole } = userRoleSlice.actions;
export default userRoleSlice.reducer;
