import { DynamicObject } from '../../../types/appType';
import { dmeApi } from './index';
import {
  GetImportantLinkPaginatedDto,
  GetImportantLinkPaginatedModel,
  GetLImportantLinkPaginatedFilterModel,
  ImportantLinkDetailsPageModel,
  ImportantLinkModel,
  ImportantLinkRefCategoryModel,
  ImportantLinkResultPageModel,
  PostImportantLinkModel,
  PutImportantLinkModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getLImportantLinkPaginatedFilter: builder.query<GetLImportantLinkPaginatedFilterModel[], void>({
      query: () => '/linklorelink/category/list',
      transformResponse: (response: GetLImportantLinkPaginatedFilterModel[]) =>
        response.map(m => ({ ...m, filter_header: 'Category' })),
    }),

    getImportanLinkPaginated: builder.query<GetImportantLinkPaginatedModel, GetImportantLinkPaginatedDto>({
      query: ({ filters, ...params }) => ({
        url: `/linklorelink/paginated/result`,
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].link_ref_category_id`] = val.link_ref_category_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
      providesTags: ['ImportantLinkList'],
    }),

    getImportantLinkList: builder.query<ImportantLinkModel[], void>({
      query: () => `/linklorelink/list`,
      providesTags: ['ImportantLinkList'],
    }),

    getImportantLinkResultPage: builder.query<ImportantLinkResultPageModel, void>({
      query: () => `/linklorelink/results/page`,
      providesTags: ['ImportantLinkList'],
    }),

    getImportantLinkDetailsPageByImportantLinkId: builder.query<ImportantLinkDetailsPageModel, number>({
      query: loreLinkId => `/linklorelink/details/page/link_lore_link_id/${loreLinkId}`,
      providesTags: ['ImportantLinkList'],
    }),

    getImportantLinkRefCategoryList: builder.query<ImportantLinkRefCategoryModel[], void>({
      query: () => `/linklorelink/ref_category/list`,
      providesTags: ['LoreLinkRefCategory'],
    }),

    postImportantLink: builder.mutation<void, PostImportantLinkModel>({
      query: params => ({
        url: `/linklorelink`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['ImportantLinkList'],
    }),

    putImportantLink: builder.mutation<void, PutImportantLinkModel>({
      query: params => ({
        url: `/linklorelink`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['ImportantLinkList'],
    }),

    deleteImportantLink: builder.mutation<void, number>({
      query: linkLoreLinkId => ({
        url: `/linklorelink/delete/link_lore_link_id/${linkLoreLinkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ImportantLinkList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLImportantLinkPaginatedFilterQuery,
  useGetImportanLinkPaginatedQuery,
  useGetImportantLinkResultPageQuery,
  useGetImportantLinkDetailsPageByImportantLinkIdQuery,
  useLazyGetImportantLinkDetailsPageByImportantLinkIdQuery,
  useGetImportantLinkListQuery,
  useGetImportantLinkRefCategoryListQuery,
  usePostImportantLinkMutation,
  usePutImportantLinkMutation,
  useDeleteImportantLinkMutation,
} = extendedApi;
