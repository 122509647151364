import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addBreadcrumb, sliceBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import {
  ImportantLinkDetailsPageSecondDatasetModel,
  EditImportantLinkModel,
  PutImportantLinkModel,
} from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { WarningIcon } from '@chakra-ui/icons';
import {
  useGetImportantLinkListQuery,
  useGetImportantLinkRefCategoryListQuery,
  useLazyGetImportantLinkDetailsPageByImportantLinkIdQuery,
  usePutImportantLinkMutation,
} from '../../../../app/services/dme/api/importantLink';
import LoreLinkDetails from './Details';
import LoreLinkTags from './Tags';
import { ValueOf } from '../../../../app/helpers/utilities';
import _ from 'lodash';

type Props = {};

const EditLoreLinkSelected = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const { logonUser } = useAppSelector(s => s.user);
  const [isLoading, setIsLoading] = useState(true);
  const [llId, setllId] = useState(0);

  const [localError, setLocalError] = useState<{ title: string; desc: string }>();
  const [localSuccess, setLocalSuccess] = useState<string>();
  const [tabErrorIndex, setTabErrorIndex] = useState<number>(-1);
  const [putAsync, putDetail] = usePutImportantLinkMutation();
  const { data: loreLinkListData } = useGetImportantLinkListQuery();
  const { data: linkRefCategoryListData, isSuccess } = useGetImportantLinkRefCategoryListQuery();

  const [triggerLoreLink, { data: loreLinkData }] = useLazyGetImportantLinkDetailsPageByImportantLinkIdQuery();

  const tabs = [
    {
      label: 'Details',
      path: 'details',
      component: LoreLinkDetails,
      model: 'detailModel',
    },
    {
      label: 'Tags',
      path: 'tags',
      component: LoreLinkTags,
      model: 'tagModel',
    },
  ];

  const FormSchema = Yup.object().shape({
    link_lore_link_id: Yup.number().moreThan(0).required().label('Important Link'),
    detailModel: Yup.object().shape({
      link_ref_category_id: Yup.number().moreThan(0),
      link_name: Yup.string()
        .label('Name')
        .required()
        .test((str, { createError }) => {
          if (
            loreLinkListData?.some(
              s => s.link_name.toLowerCase() === str?.toLowerCase() && s.link_lore_link_id !== llId,
            )
          ) {
            createError({
              message: 'Name already exist',
              path: 'link_name',
            });
            return false;
          } else {
            return true;
          }
        }),
      link_description: Yup.string().label('Description').required(),
      link_url: Yup.string().label('URL').required().url(),
    }),
    tagModel: Yup.array<ImportantLinkDetailsPageSecondDatasetModel[]>().of(
      Yup.object().shape({
        ref_tag_id: Yup.number().moreThan(0, 'Tag is a required field').required().label('Tag'),
      }),
    ),
  });

  const defaultValue = {
    link_lore_link_id: +(params?.id || 0),
    detailModel: loreLinkData?.first_dataset[0],
    tagModel: loreLinkData?.second_dataset,
  } as EditImportantLinkModel;

  const { handleSubmit, errors, touched, values, resetForm, setValues, setFieldValue, validateForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      link_lore_link_id: defaultValue.link_lore_link_id,
      detailModel: defaultValue.detailModel,
      tagModel: defaultValue.tagModel,
    },

    onSubmit: values => {},
  });

  const validate = async () => {
    setLocalError(undefined);
    handleSubmit(); // will trigger errors

    const validationError = await validateForm(values);
    const tempError = validationError as any;
    console.log({ validationError });
    if (Object.keys(validationError).length > 0) {
      if (validationError.detailModel || Array.isArray(validationError.tagModel)) {
        setLocalError({ title: '', desc: 'Some field(s) are invalid' });
      } else {
        setLocalError({
          title: '',
          desc:
            tempError.detailModel?.toString() || tempError.domainModel?.toString() || tempError.tagModel?.toString(),
        });
      }
    } else {
      await submit();
    }
  };

  const onChange = (field: keyof EditImportantLinkModel, data: ValueOf<EditImportantLinkModel>, isReset?: boolean) => {
    let defaultData = defaultValue[field];
    let tempIndex;
    switch (field) {
      case 'detailModel':
        tempIndex = 0;
        break;
      case 'tagModel':
        tempIndex = 2;
        break;
    }
    setLocalError(undefined);
    setValues({
      ...values,
      [field]: isReset ? defaultData : data,
    });
    if (tabErrorIndex == tempIndex) {
      setTabErrorIndex(-1);
      setLocalError(undefined);
    }
  };

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    putDetail.error && setLocalError(undefined);
  };

  const submit = async () => {
    const finalValues = { ...values };

    const finalValue: PutImportantLinkModel = {
      link_lore_link_id: finalValues.link_lore_link_id,
      link_ref_category_id: values.detailModel?.link_ref_category_id || 0,
      link_name: values.detailModel?.link_name || '',
      link_description: values.detailModel?.link_description || '',
      link_url: values.detailModel?.link_url || '',
      ref_tag_ids: finalValues.tagModel?.filter(f => !f.is_deleted_flag)?.map(m => m.ref_tag_id) || [],
    };

    await putAsync(finalValue);
  };

  const getDeletedItem = (originalData: any[], currentData: any[], key: string) => {
    return (
      originalData
        ?.filter(item => !currentData?.some(s => s[key] == item[key]))
        ?.map(m => ({ ...m, is_deleted_flag: true })) || []
    );
  };

  const onCancel = () => {
    const proceed = () => navigate('../edit-important-link');
    if (_.isEqual(values, defaultValue)) {
      proceed();
    } else {
      if (window.confirm('Changes you made will not be saved. Do you want to leave this page?')) {
        proceed();
      }
    }
  };

  const getTabsData = async (llId: number) => {
    setIsLoading(true);
    await triggerLoreLink(llId);
    setIsLoading(false);
  };

  const onDuplicateError = (index: number) => {
    setTabIndex(index);
    setTabErrorIndex(index);
  };

  useEffect(() => {
    sliceBreadcrumb(0, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path);
      window.scrollTo(0, 0);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab || '';

    if (paramTab.length > 0) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  useEffect(() => {
    (async () => {
      const paramLlId = +(params?.id || 0);

      if (paramLlId > 0) {
        await getTabsData(paramLlId);
        setllId(paramLlId);
      }
    })();
  }, [params.id]);

  useEffect(() => {
    if (linkRefCategoryListData && values.detailModel && !values.detailModel.link_ref_category_id && isSuccess) {
      setFieldValue('detailModel', {
        ...values.detailModel,
        link_ref_category_id: linkRefCategoryListData.find(
          c => c.link_ref_category_name === values.detailModel?.link_ref_category_name,
        )?.link_ref_category_id,
      });
    }
  }, [linkRefCategoryListData, isSuccess, setFieldValue, values.detailModel]);

  useEffect(() => {
    (async () => {
      const { data, isSuccess, isError, isLoading } = putDetail;
      const resData: any = data;
      if (isSuccess && resData?.isSuccess) {
        setLocalSuccess('Important Link successfully updated.');
        await getTabsData(llId);
        resetForm();
        setTimeout(() => {
          setLocalSuccess('');
        }, 3000);
      } else if (resData && !resData?.isSuccess) {
        let tabError = '';
        if (resData.msg.includes('uidx_link_name')) {
          onDuplicateError(0);
          tabError = 'Cannot insert duplicate Important Link Term';
        }
        setLocalError({
          title: '',
          desc: tabError,
        });
      } else if (isError) {
        setLocalError({
          title: '',
          desc: 'There was an error processing your request, please try again later.',
        });
      } else {
        setLocalError(undefined);
      }

      if (isLoading) {
        setLocalSuccess('');
      }
    })();
  }, [putDetail]);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setLocalError(undefined);
    }
  }, [errors]);

  return (
    <VStack w="100%">
      <HStack>
        <HStack flex={1}>
          {loreLinkData?.first_dataset && (
            <Heading
              size="md"
              whiteSpace="initial"
              noOfLines={1}
              maxW="800px"
              title={loreLinkData.first_dataset[0].link_name}
            >
              {loreLinkData.first_dataset[0].link_name}
            </Heading>
          )}

          {logonUser && (
            <ButtonGroup>
              <Button
                isLoading={putDetail.isLoading}
                variant="outline"
                colorScheme="brand.main"
                onClick={() => validate()}
              >
                Save
              </Button>
              <Button variant="solid" onClick={e => onCancel()}>
                Cancel
              </Button>
            </ButtonGroup>
          )}
        </HStack>
        {localError && tabIndex !== undefined && (
          <Alert py={2} status="error">
            <AlertIcon />
            {localError.title && <AlertTitle>{tabs[tabIndex].label}</AlertTitle>}
            <AlertDescription>{localError.desc}</AlertDescription>
          </Alert>
        )}
        {localSuccess && (
          <Alert py={2} status="success">
            <AlertIcon />
            <AlertDescription>{localSuccess}</AlertDescription>
          </Alert>
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => {
              const error = errors as any;
              return (
                <Tab key={i}>
                  {m.label}
                  {(error[m.model] && localError) || tabErrorIndex == i ? (
                    <Icon color="brand.error" ml={2} as={WarningIcon} />
                  ) : (
                    <></>
                  )}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tabs.map((tabPanel, i) => (
              <TabPanel key={i}>
                {tabPanel && values && !isLoading ? (
                  createElement(tabPanel.component, {
                    onChangeData: onChange,
                    data: values,
                    isSubmitting: putDetail.isLoading,
                    errors: errors,
                    touched: touched,
                    title: tabPanel.label,
                    tabErrorIndex: tabErrorIndex,
                  })
                ) : (
                  <>Loading...</>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default EditLoreLinkSelected;
