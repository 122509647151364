import { DynamicObject } from '../../../types/appType';
import { dmeApi } from './index';
import {
  AddRefTagDto,
  GetRefTagPaginatedDto,
  GetRefTagPaginatedFilterModel,
  GetRefTagPaginatedResultModel,
  GetRefTagSelectedDataProductModel,
  GetRefTagSelectedDataProductPayloadModel,
  GetRefTagSelectedGlossaryModel,
  GetRefTagSelectedImportantLinkModel,
  RefTagModel,
  UpdateRefTagDto,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefTagList: builder.query<RefTagModel[], void | { infoweave_flag?: boolean }>({
      query: params => ({
        url: `/reftag/list`,
        params: params ? params : undefined,
      }),
      providesTags: ['RefTag'],
    }),

    getRefTagById: builder.query<RefTagModel, number>({
      query: id => `/reftag/ref_tag_id/${id}`,
      providesTags: ['RefTag'],
    }),

    getRefTagByName: builder.query<RefTagModel, string>({
      query: tagName => `/reftag/tag_name/${tagName}`,
      providesTags: ['RefTag'],
    }),

    // p_ref_tag_explore_filter_applied
    getRefTagPaginatedList: builder.query<GetRefTagPaginatedResultModel, GetRefTagPaginatedDto>({
      query: ({ filters, ...params }) => ({
        url: `/reftag/paginated/list`,
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].filter_order`] = val.filter_order;
            obj[`filters[${i}].filter_detail`] = val.filter_detail;
            return obj;
          }, {} as DynamicObject),
        },
      }),
    }),

    // p_ref_tags_filters
    getRefTagPaginatedFilter: builder.query<GetRefTagPaginatedFilterModel[], void>({
      query: () => '/reftag/paginated/filter',
    }),

    // p_ref_tag_details_dp_tab
    getRefTagSelectedDataProduct: builder.query<GetRefTagSelectedDataProductModel[], number>({
      query: id => `/reftag/select/data_product/${id}`,
    }),

    // p_ref_tag_details_dp_payload_tab
    getRefTagSelectedDataProductPayload: builder.query<GetRefTagSelectedDataProductPayloadModel[], number>({
      query: id => `/reftag/select/data_product_payload/${id}`,
    }),

    // p_ref_tag_details_glossary_tab
    getRefTagSelectedGlossary: builder.query<GetRefTagSelectedGlossaryModel[], number>({
      query: id => `/reftag/select/glossary/${id}`,
    }),

    // p_ref_tag_details_important_link_tab
    getRefTagSelectedImportantLink: builder.query<GetRefTagSelectedImportantLinkModel[], number>({
      query: id => `/reftag/select/important_link/${id}`,
    }),

    addRefTag: builder.mutation<void, AddRefTagDto>({
      query: body => ({
        url: '/reftag',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RefTag'],
    }),

    updateRefTag: builder.mutation<void, UpdateRefTagDto>({
      query: body => ({
        url: '/reftag',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['RefTag'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRefTagListQuery,
  useGetRefTagByIdQuery,
  useGetRefTagByNameQuery,
  useLazyGetRefTagByNameQuery,

  useGetRefTagPaginatedListQuery,
  useGetRefTagPaginatedFilterQuery,
  useGetRefTagSelectedDataProductQuery,
  useGetRefTagSelectedDataProductPayloadQuery,
  useGetRefTagSelectedGlossaryQuery,
  useGetRefTagSelectedImportantLinkQuery,

  useAddRefTagMutation,
  useUpdateRefTagMutation,
} = extendedApi;
