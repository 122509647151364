import { FocusEvent } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { ValueOf } from '../../../app/helpers/utilities';
import { LoreLanguageDetailsPageDetailModel } from '../../../app/services/dme/api/types';
import { AddLoreLanguageModel } from '../../../app/services/types';

type Props = {
  data: AddLoreLanguageModel;
  onChangeData: (field: keyof AddLoreLanguageModel, data: ValueOf<AddLoreLanguageModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const LoreLanguageDetails = ({ data, onChangeData, errors, touched, tabErrorIndex }: Props) => {
  const onBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof LoreLanguageDetailsPageDetailModel,
  ) => {
    data.detailModel &&
      onChangeData('detailModel', {
        ...data.detailModel,
        [field]: e.target.value.trim(),
      });
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      {!data.detailModel ? (
        <></>
      ) : (
        <VStack w="container.md">
          <FormControl
            isInvalid={
              (!!(
                errors.detailModel && (errors.detailModel as unknown as LoreLanguageDetailsPageDetailModel)?.ll_term
              ) &&
                !!(
                  touched.detailModel && (touched.detailModel as unknown as LoreLanguageDetailsPageDetailModel)?.ll_term
                )) ||
              tabErrorIndex === 0
            }
            display="flex"
          >
            <FormLabel minW="150px">Term</FormLabel>
            <Input
              size="sm"
              value={data.detailModel.ll_term}
              onChange={e =>
                data.detailModel && onChangeData('detailModel', { ...data.detailModel, ll_term: e.target.value })
              }
              onBlur={e => onBlur(e, 'll_term')}
            />
            <>
              {(errors.detailModel as any)?.ll_term == 'Term is invalid' ? (
                <Tooltip label="Term already exist">
                  <IconButton
                    color="brand.error"
                    variant="link"
                    aria-label="Term already exist"
                    icon={<MdCancel />}
                    minW={7}
                  />
                </Tooltip>
              ) : (
                data.detailModel.ll_term.length > 0 && (
                  <Tooltip label="Looks good">
                    <IconButton
                      color="brand.main.default"
                      variant="link"
                      aria-label="Term is valid"
                      icon={<MdCheckCircle />}
                      minW={7}
                    />
                  </Tooltip>
                )
              )}
            </>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as LoreLanguageDetailsPageDetailModel)?.ll_definition
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as LoreLanguageDetailsPageDetailModel)?.ll_definition
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Definition</FormLabel>
            <Input
              size="sm"
              value={data.detailModel.ll_definition}
              onChange={e =>
                data.detailModel && onChangeData('detailModel', { ...data.detailModel, ll_definition: e.target.value })
              }
              onBlur={e => onBlur(e, 'll_definition')}
            />
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as LoreLanguageDetailsPageDetailModel)?.ll_application_context
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as LoreLanguageDetailsPageDetailModel)?.ll_application_context
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Application Context</FormLabel>
            <Textarea
              size="sm"
              value={data.detailModel.ll_application_context}
              onChange={e =>
                data.detailModel &&
                onChangeData('detailModel', {
                  ...data.detailModel,
                  ll_application_context: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'll_application_context')}
            ></Textarea>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
        </VStack>
      )}
    </VStack>
  );
};

export default LoreLanguageDetails;
