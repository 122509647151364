import {
    Alert,
    AlertIcon,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { stringOrHtmlIsEmpty } from '../../../../../app/helpers/stringHelper';
import {
    useDeleteGoEdsCommentMutation,
    useLazyGetGoEdsDetailsDiscussionTabByCommentQuery,
    useUpdateGoEdsCommentMutation,
} from '../../../../../app/services/dme/api/governanceOfficeEdsComment';
import { GoEdsDetailsDiscussionTabModel } from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import PubNubConstants from '../../../../../features/PubNubWrapper/constants';
import RichTextEditor from '../../../../../features/RichTextEditor';

type Props = {
  comment?: GoEdsDetailsDiscussionTabModel;
  action?: 'update' | 'delete';
  handleClose: () => void;
  channelName: string;
};

const GoEdsCommentModal = (props: Props) => {
  return (
    <>
      <Update {...props} />
      <Delete {...props} />
    </>
  );
};

const Update = ({ comment, action, channelName, handleClose }: Props) => {
  const pubnub = usePubNub();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logonUser } = useAppSelector(s => s.user);
  const [updateAsync, updateDetails] = useUpdateGoEdsCommentMutation();
  const [getAsync, getDetails] = useLazyGetGoEdsDetailsDiscussionTabByCommentQuery();

  const [alertMessage, setAlertMessage] = useState('');
  const [commentMessage, setCommentMessage] = useState('');

  const handleClick = async () => {
    setAlertMessage('');

    try {
      if (!comment?.go_eds_comment_id || !logonUser) return;

      await updateAsync({
        go_eds_comment_id: comment.go_eds_comment_id,
        comment_by_user_id: logonUser.ref_user_id,
        comment_message: commentMessage.replace(/\uFEFF/g, ''), //clean unwanted white space
      });
      const data = (await getAsync({ go_eds_comment_id: comment.go_eds_comment_id, query: '' }).unwrap())[0];

      pubnub.publish({
        channel: channelName,
        message: {
          type: PubNubConstants.MessageEvent.Type.UPDATE,
          data,
        },
      });

      setAlertMessage('Post successfully updated.');
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (error) {}
  };

  const onTextAreaKeyEvent = (e: any) => {
    setCommentMessage(e);
  };

  useEffect(() => {
    if (action === 'update' && comment?.go_eds_comment_id) {
      onOpen();
      setCommentMessage(comment.comment_message);
    } else {
      onClose();
      setAlertMessage('');
    }
  }, [action, comment]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Comment</ModalHeader>
        <ModalCloseButton isDisabled={updateDetails.isLoading || getDetails.isLoading} onClick={handleClose} />
        <ModalBody>
          <RichTextEditor value={commentMessage} onChange={onTextAreaKeyEvent} />
          {alertMessage && (
            <Alert status={updateDetails.isSuccess ? 'success' : 'error'} mt={2}>
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter gap={3}>
          <Button onClick={handleClose} ml="auto" isDisabled={updateDetails.isLoading || getDetails.isLoading}>
            Cancel
          </Button>
          <Button
            colorScheme="brand.main"
            isLoading={updateDetails.isLoading || getDetails.isLoading}
            isDisabled={
              stringOrHtmlIsEmpty(commentMessage) ||
              updateDetails.isLoading ||
              getDetails.isLoading ||
              (alertMessage !== '' && updateDetails.isSuccess)
            }
            onClick={handleClick}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Delete = ({ comment, action, channelName, handleClose }: Props) => {
  const pubnub = usePubNub();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAsync, deleteDetails] = useDeleteGoEdsCommentMutation();
  const [alertMessage, setAlertMessage] = useState('');

  const handleClick = async () => {
    setAlertMessage('');

    try {
      if (!comment?.go_eds_comment_id) return;

      await deleteAsync(comment.go_eds_comment_id);

      pubnub.publish({
        channel: channelName,
        message: {
          commentId: comment.go_eds_comment_id,
          type: PubNubConstants.MessageEvent.Type.DELETE,
        },
      });

      setAlertMessage('Post successfully deleted.');
      setTimeout(() => {
        handleClose();
      }, 100);
    } catch (error) {
      setAlertMessage('There was an error processing your request, please try again later.');
    }
  };

  useEffect(() => {
    if (action === 'delete' && comment?.go_eds_comment_id) {
      onOpen();
    } else {
      onClose();
      setAlertMessage('');
      deleteDetails.reset();
    }
  }, [action, comment]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Comment</ModalHeader>
        <ModalCloseButton isDisabled={deleteDetails.isLoading} onClick={handleClose} />
        <ModalBody>
          <VStack>
            {!deleteDetails.isSuccess && <Text>Are you sure you want to delete this comment?</Text>}
            {alertMessage && (
              <Alert status={deleteDetails.isSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {alertMessage}
              </Alert>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button onClick={handleClose} ml="auto" isDisabled={deleteDetails.isLoading}>
            Cancel
          </Button>
          <Button
            colorScheme="brand.main"
            isLoading={deleteDetails.isLoading}
            isDisabled={alertMessage !== '' && deleteDetails.isSuccess}
            onClick={handleClick}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GoEdsCommentModal;
