import { Divider, Heading, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRefRequestStatusQuery } from '../../../app/services/dme/api/refRequestStatus';
import { GetRefRequestStatusModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import PubNubConstants from '../../../features/PubNubWrapper/constants';
import CustomRequestsApprovalTab from './CustomRequestsApprovalTab';

export const channelNameCustomRequestApproval = 'custom_requests_approval';

const CustomRequestsApproval = () => {
  const { data: apiData, isLoading, isFetching, refetch } = useGetRefRequestStatusQuery();
  const params = useParams();
  const navigate = useNavigate();
  const pubnub = usePubNub();
  const { messageEvent } = useAppSelector(s => s.pubNub);

  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const [data, setData] = useState<GetRefRequestStatusModel[] | undefined>();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setData(apiData);
  }, [apiData]);

  useEffect(() => {
    if (tabIndex !== undefined && data && params.statusTab !== data[tabIndex].request_status_name.toLowerCase()) {
      navigate('./../' + data[tabIndex].request_status_name.toLowerCase());
    }
  }, [tabIndex, data]);

  useEffect(() => {
    const paramTab = params.statusTab;
    if (paramTab !== undefined && data) {
      const pathIndex = data.findIndex(f => f.request_status_name.toLowerCase() === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params, data]);

  useEffect(() => {
    pubnub.subscribe({
      channels: [channelNameCustomRequestApproval],
      //withPresence: true,
    });

    return () => {
      pubnub.unsubscribe({
        channels: [channelNameCustomRequestApproval],
      });
    };
  }, [pubnub]);

  useEffect(() => {
    if (messageEvent?.channel === channelNameCustomRequestApproval) {
      const { message } = messageEvent;
      if (message.type === PubNubConstants.MessageEvent.Type.UPDATE) {
        refetch();
      }
    }
  }, [messageEvent]);

  return (
    <VStack w="100%">
      <Heading size="md" h="24px">
        Custom Requests Approval
      </Heading>
      <Divider />

      {(isLoading || isFetching) && !data ? (
        <>Loading...</>
      ) : (
        tabIndex !== undefined &&
        data && (
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              {data.map((m, i) => (
                <Tab key={m.ref_request_status_id}>
                  {m.request_status_name} ({m.status_cnt})
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <CustomRequestsApprovalTab status={data[tabIndex]} tabIndex={tabIndex} />
            </TabPanels>
          </Tabs>
        )
      )}
    </VStack>
  );
};

export default CustomRequestsApproval;
