import {
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaUnlockAlt } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import useDebounce from '../../../app/hooks/useDebounce';
import {
  useLazyGetMyRolesExploreRolesQuery,
} from '../../../app/services/dme/api/myRoles';
import { MyRolesExploreRolesDataModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import CustomTable from '../../../components/CustomTable';
import RequestAccessDialog from '../RequestAccess';
import { AppSize } from '../../../app/constants';
import { DynamicObject } from '../../../app/types/appType';

type Props = {};

const ExploreRolesResult = () => {
  const { logonUser } = useAppSelector(s => s.user);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [lazyLoadDataLength, setLazyLoadDataLength] = useState(0);
  const [roles, setRoles] = useState<MyRolesExploreRolesDataModel[]>([]);
  const { isSideNavOpen } = useAppSelector(s => s.app);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const [getRoles, { isLoading, isFetching, data }] = useLazyGetMyRolesExploreRolesQuery();
  const [getAsync, getDetails] = useLazyGetMyRolesExploreRolesQuery();

  const Header = (metadata: MyRolesExploreRolesDataModel[]) => [
    {
      Header: 'Request Access',
      styles: { textAlign: 'center' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: MyRolesExploreRolesDataModel } }) => {
        return (
          <>
            <ButtonGroup spacing={2}>
              {logonUser && (
                <RequestAccessDialog
                  ref_role_list={metadata}
                  ref_role_id={original.ref_role_id}
                  role_name={original.role_name}
                  triggerElement={
                    <Tooltip label="Request Access">
                      <IconButton
                        variant="link"
                        aria-label="Request Access"
                        icon={<FaUnlockAlt />}
                        minWidth={1}
                        colorScheme="brand.main"
                      />
                    </Tooltip>
                  }
                />
              )}
            </ButtonGroup>
          </>
        );
      },
    },
    {
      Header: 'Role Name',
      accessor: 'role_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Description',
      accessor: 'role_desc',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Last Modified',
      Cell: ({ row: { original } }: { row: { original: MyRolesExploreRolesDataModel } }) => {
        return <>{convertUtcToLocal(original.last_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}</>;
      },
    },
    {
      Header: 'Developer Environments',
      accessor: 'developer_environments',
      styles: { textAlign: 'center' },
    },
  ];

  const onClickRow = (row: MyRolesExploreRolesDataModel) => {
    navigate(`./detail/id/${row.ref_role_id}`);
  };

  useEffect(() => {
    getAsync({
      page_number: 1,
      page_size: 99999,
      search_string: searchParams.get('search') ?? '',
      sort_column: 'role_name',
      sort_order: 'asc',
    });
  }, []);

  const fetchPagedRoles = async () => {
    const nextPage = pageNumber + 1;

    const response = await getRoles({
      page_number: nextPage,
      page_size: pageSize,
      search_string: searchParams.get('search') ?? '',
      sort_column: 'role_name',
      sort_order: 'asc',
    }).unwrap();

    const newRecords: MyRolesExploreRolesDataModel[] = [];

    setRoles(s => [...s, ...response.data]);
    if (newRecords.length === 0) {
      forceRemoveLazyLoaderText();
    }
    setPageNumber(nextPage);
  };

  // (workaround)force remove the infinite loader text if data is empty
  const forceRemoveLazyLoaderText = () => {
    setLazyLoadDataLength(s => ++s);
  };

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  useEffect(() => {
    setLazyLoadDataLength(roles.length);
  }, [roles]);

  useEffect(() => {
    const run = async () => {
      setPageNumber(1);
      setRoles([]);
      const response = await getRoles({
        page_number: 1,
        page_size: pageSize,
        search_string: searchParams.get('search') ?? '',
        sort_column: 'role_name',
        sort_order: 'asc',
      }).unwrap();
      setRoles(response.data);

      const tableHeadDiv = document.getElementById('infinite-scroll-table-head');
      if (tableHeadDiv) {
        tableHeadDiv.scrollIntoView({});
        window.scrollTo(0, 0);
      }
    };
    run();
  }, [searchParams.get('search')]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      {displayTable()}
    </VStack>
  );

  function displayTable() {
    return isLoading ? (
      <Stack mt={1}>
        {[...Array(8)].map((m, i) => (
          <Skeleton key={i} height="18px" />
        ))}
      </Stack>
    ) : (
      <VStack>
        <HStack>
          <Heading size="md">Explore Roles</Heading>
          {getDetails.data && logonUser && (
            <RequestAccessDialog
              ref_role_list={getDetails.data?.data ?? []}
              triggerElement={
                <Button variant="outline" colorScheme="brand.main" leftIcon={<FaUnlockAlt />}>
                  Request Access
                </Button>
              }
            />
          )}
        </HStack>
        <Divider />
        <HStack>
          <CustomTable
          variant="infinite-scroll-table"
          isLoading={isLoading}
          isFetching={isFetching}
          data={roles}
          pageCount={0}
          pageSize={pageSize}
          totalRecords={data?.total_records || 0}
          pageIndex={pageNumber - 1}
          headers={Header(roles|| [])}
          search={searchParams.get('search') ?? ''}
          manualSortBy
          disableSortRemove
          onPageChange={index => {
          }}
          onPageSizeChange={size => {
          }}
          onPageSearchDebounce={400}
          onPageSearch={search => {
            const params: DynamicObject = {};
            searchParams.forEach((val, key) => (params[key] = val));
            setSearchParams({ ...params, search: search }, { replace: true });
          }}
          onSort={() => {}}
          manual={true}
          onRowClick={onClickRow}
          infiniteScrollProps={{
            props: {
              dataLength: lazyLoadDataLength,
              next: fetchPagedRoles,
              hasMore: pageNumber < (data?.total_pages ?? 0),
              loader: roles.length ? <h5>Loading...</h5> : <></>,
              scrollableTarget: 'scrollableDiv',
            },
            parentProps: {
              style: {
                height: 'calc(100dvh - 240px)',
                overflow: 'auto',
              },
            },
          }}
          styles={{
            pagination: { justifyContent: 'start' },
            header: { justifyContent: 'left' },
            tableContainer: {
              sx: {
                maxW: `calc(100vw - 3.5rem - ${sideNavWidth})`,
                //maxW: `calc(100vw - 5.75rem - 300px - ${sideNavWidth})`,
                overflow: 'auto',
                table: {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  'thead > tr': {
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    height: 'auto',
                    bg: 'white',
                  },
                  tr: {
                    'th:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                    'td:last-child': {
                      position: 'sticky',
                      right: '0px',
                      zIndex: 2,
                      bg: 'white',
                      w: '90px',
                      borderLeft: '1px',
                      borderColor: 'gray.100',
                    },
                  },
                },
              },
            },
          }}
        />
        </HStack>
      </VStack>
    );
  }
};

export default ExploreRolesResult;
