import { Box, Text, HStack, Stack, Grid, GridItem, Button, Collapse, IconButton, Heading } from '@chakra-ui/react';
import { useGetEmployeeOnboardingRequestByRequestorIdQuery } from '../../../app/services/dme/api/employeeOnboardingRequest';
import { useAppSelector } from '../../../app/state/hooks';
import { useState } from 'react';
import { IoContract, IoExpand } from 'react-icons/io5';

type Props = {};


const MyEmployeeOnboardingRequest = (props: Props) => {
  //get user info
  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;

  const { data, isLoading, isFetching } = useGetEmployeeOnboardingRequestByRequestorIdQuery(userId);
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  if (isLoading || isFetching) return <div>Loading...</div>;

  //Add a check to ensure data is not undefined or null
  if(!data || data.length === 0){
    return <div> No data available</div>
  }

  return (
    <>
      <Box p="3">
        {/* Onboarding Section */}
        <Box maxH="100%" overflowY="auto">
          <Heading size="md" mb={4}>My Onboarding Requests</Heading>
          {data.filter(d => d.start_date !== null && d.end_date === null).length > 0 ? (
            data.filter(d => d.start_date !== null && d.end_date === null).map((d, i) => (
              <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
                <HStack alignItems="start">
                  <Box>
                    <HStack flexShrink={0} mr="2">
                      <IconButton
                        aria-label={expanded[d.eo_request_id] ? 'contract card' : 'expand card'}
                        title={expanded[d.eo_request_id] ? 'Collapse' : 'Expand'}
                        size="sm" icon={expanded[d.eo_request_id] ? <IoContract /> : <IoExpand />}
                        onClick={() =>
                          setExpanded(e => ({
                            ...e,
                            [d.eo_request_id]: !e[d.eo_request_id]
                          }))
                        }
                      />
                    </HStack>
                  </Box>
                  <Stack w="100%">
                    {/* <Text fontSize="inherit" fontWeight="bold">Onboarding</Text> */}
                    <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                      <Field title="Request ID" value={d.eo_request_id} />
                      <Field title="First Name" value={`${d.first_name}`} />
                      <Field title="Last Name" value={`${d.last_name}`} />
                      <Field title="Personal Email Address" value={d.personal_email_address} />
                      {/* <Field title="Start Date" value={d.start_date ? convertUtcToLocal(d.start_date, 'YYYY-MM-DD') : '-'} /> */}
                      <Field title="Start Date" value={d.start_date ? d.start_date: '-'} />
                    </Grid>
                    <Collapse in={expanded[d.eo_request_id]}>
                      <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                        <Field title="Squad" value={d.squad ? d.squad : '-'} />
                        <Field title="Specialty" value={d.specialty ? d.specialty : '-'} />
                        <Field title="Geographical Location" value={d.geographical_location ? d.geographical_location : '-'} />
                        <Field title="Needs Virtual Machine?" value={d.virtual_machine_flag ? 'YES' : 'NO'} />
                        {/* <Field title="Requested Date" value={d.row_created_datetime_utc ? convertUtcToLocal(d.row_created_datetime_utc) : '-'} /> */}
                        <Field title="Requested Date" value={d.row_created_datetime_utc ? d.row_created_datetime_utc : '-'} />
                      </Grid>
                      <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                        <Field title="Additional Notes" value={d.additional_notes ? d.additional_notes : '-'} colSpan={5} />
                      </Grid>
                    </Collapse>
                  </Stack>
                </HStack>
              </Box>
            ))):(
              <Text style={{ marginLeft: 20}}>No data found</Text>
          )}
        </Box>
        
        {/*Divider */}
        <hr style={{
            border: "1px solid brand.main.default",
            marginBottom: '10px',
            marginTop: '10px',
            width: '100%',
        }}/>

        {/* Offboarding Section */}
        <Box maxH="100%" overflowY="auto">
          <Heading size="md" mb={4}>My Offboarding Requests</Heading>
          {data.filter(d => d.end_date !== null).length > 0 ? (
            data.filter(d => d.end_date !== null).map((d, i) => (
              <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
                <HStack alignItems="start">
                  <Box w="40px" />
                  <Stack w="100%">
                    {/* <Text fontSize="inherit" fontWeight="bold">Offboarding</Text> */}
                    <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                      <Field title="Request ID" value={d.eo_request_id} />
                      <Field title="First Name" value={`${d.first_name}`} />
                      <Field title="Last Name" value={`${d.last_name}`} />
                      {/* <Field title="End Date" value={d.end_date ? convertUtcToLocal(d.end_date, 'YYYY-MM-DD') : '-'} /> */}
                      <Field title="End Date" value={d.end_date ? d.end_date: '-'} />
                      <Field title="Requested Date" value={d.row_created_datetime_utc ? d.row_created_datetime_utc: '-'} />
                    </Grid>
                  </Stack>
                </HStack>
              </Box>
            ))):(
              <Text style={{ marginLeft: 20}}>No data found</Text>
          )}
        </Box>
      </Box>
    </>
  );

};

type FieldProps = {
  title: string;
  value: any;
  w?: string | number;
  maxW?: string | number;
  colSpan?: number;
};
function Field({ title, value, w, maxW, colSpan }: FieldProps) {
  return (
    <GridItem colSpan={colSpan}>
      <Stack w={w} maxW={maxW} spacing={0}>
        <Text fontWeight="bold">{title}</Text>
        <Text>{value}</Text>
      </Stack>
    </GridItem>
  );
}

export default MyEmployeeOnboardingRequest;