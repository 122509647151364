import { Box, Divider, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useGetDataProductMostPopularQuery } from '../../../../app/services/dme/api/dataProduct';
import { DataProductMostPopularModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

type Props = {};

const Popular = (props: Props) => {
  const { data, isLoading, isFetching } = useGetDataProductMostPopularQuery();

  const navigate = useNavigate();

  const PopularHeader = [
    {
      Header: 'Data Product Name',
      accessor: 'data_product_name',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Box maxW="250px">
            <Text noOfLines={1} title={original.data_product_name}>
              {original.data_product_name}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'data_product_desc',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Box maxW="800px">
            <Text noOfLines={3} title={original.data_product_desc}>
              {original.data_product_desc}
            </Text>
          </Box>
        );
      },
    },
  ];

  const goToSelectedPage = (row: DataProductMostPopularModel) =>
    navigate(
      `selected/domain/${encodeURIComponent(row.domain_name)}/data-product/${encodeURIComponent(
        row.data_product_name,
      )}/id/${row.dp_data_product_id}/details`,
    );

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <Heading size="sm" mb={2}>
        Most popular last 30 days
      </Heading>
      <Divider />
      <CustomTable
        variant="table"
        headers={PopularHeader}
        isLoading={isLoading}
        isFetching={isFetching}
        data={data || []}
        pageCount={0}
        pageSize={data ? data.length : 0}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        onRowClick={row => goToSelectedPage(row)}
      />
    </Box>
  );
};

export default Popular;
