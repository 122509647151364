import { useParams } from 'react-router-dom';
import { useGetRefUserListRolesQuery } from '../../../../app/services/dme/api/user';
import { HStack, Heading, VStack } from '@chakra-ui/react';
import CustomTable from '../../../../components/CustomTable';
import { Row } from 'react-table';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { GetRefUserListRolesModel } from '../../../../app/services/dme/api/types';

type Props = {};

const Header = [
  {
    Header: 'Role Name',
    accessor: 'role_name',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Role Description',
    accessor: 'role_desc',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Business Justification',
    accessor: 'access_request_business_justification',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Expiration Date',
    Cell: ({ row: { original } }: { row: Row<GetRefUserListRolesModel> }) => {
      return <>{convertUtcToLocal(original.access_expiration_datetime_utc, 'MMMM D, YYYY h:mm A')}</>;
    },
    styles: { width: 'initial' },
  },
  {
    Header: 'Request Date',
    Cell: ({ row: { original } }: { row: Row<GetRefUserListRolesModel> }) => {
      return <>{convertUtcToLocal(original.access_requested_datetime_utc, 'MMMM D, YYYY h:mm A')}</>;
    },
    styles: { width: 'initial' },
  },
];

const UserSelectedRolesTab: React.FC<Props> = () => {
  const params = useParams();
  const id: number = parseInt(params?.id || '');

  const { data, isLoading, isFetching } = useGetRefUserListRolesQuery(id, { skip: !id });

  return isLoading || isFetching ? (
    <>Loading...</>
  ) : (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Roles</Heading>
      {displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={false}
        isFetching={false}
        data={data || []}
        pageCount={0}
        pageSize={data ? data.length : 0}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        showNoRecords
      />
    );
  }
};

export default UserSelectedRolesTab;
