import { dmeApi } from '.';
import { AdminAccessReportHubRole, AdminAccessReportHubUser, RhAccessRolesAndUserModel } from './types';

const baseUrl = '/reports';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: build => ({
    getRhAccessList: build.query<RhAccessRolesAndUserModel[], { email: string }>({
      query: ({ email }) => baseUrl + `/role/user/email_address/${email}`,
    }),

    getAdminAccessReportHubUserList: build.query<AdminAccessReportHubUser[], number>({
      query: userId => baseUrl + `/admin/approval/users/${userId}`,
    }),

    getAdminAccessReportHubRoleList: build.query<AdminAccessReportHubRole[], number>({
      query: userId => baseUrl + `/admin/approval/roles/${userId}`,
    }),

    addRhRoleRequestAccess: build.mutation<
      void,
      {
        rh_report_portfolio_id: number;
        ref_role_id: number;
        access_expiration_datetime_utc: string;
        access_request_business_justification: string;
      }
    >({
      query: body => ({
        url: `/reporthubrolereportportfolio`,
        method: 'POST',
        body,
      }),
    }),

    addRhUserRequestAccess: build.mutation<
      void,
      {
        rh_report_portfolio_id: number;
        ref_user_id: number;
        access_expiration_datetime_utc: string;
        access_request_business_justification: string;
      }
    >({
      query: body => ({
        url: `/reporthubuserreportportfolio`,
        method: 'POST',
        body,
      }),
    }),

    putRhUserRequestAccess: build.mutation<
      void,
      {
        rh_user_report_portfolio_id: number;
        access_approved_flag: boolean;
        access_denied_flag: boolean;
        access_denied_notes?: string;
      }
    >({
      query: body => ({
        url: `/reporthubuserreportportfolio/exploreroles/requestaccess`,
        method: 'POST',
        body,
      }),
    }),

    putRhRoleRequestAccess: build.mutation<
      void,
      {
        rh_role_report_portfolio_id: number;
        access_approved_flag: boolean;
        access_denied_flag: boolean;
        access_denied_notes?: string;
      }
    >({
      query: body => ({
        url: `/reporthubrolereportportfolio/exploreroles/requestaccess`,
        method: 'POST',
        body,
      }),
    }),

    putRhUserRequestAccessClaimFlag: build.mutation<
      void,
      {
        rh_user_report_portfolio_id: number;
        claimed_flag: boolean;
        claim_user_id: number;
      }
    >({
      query: body => ({
        url: `/reporthubuserreportportfolio/rh_user_report_portfolio`,
        method: 'PUT',
        body,
      }),
    }),

    putRhRoleRequestAccessClaimFlag: build.mutation<
      void,
      {
        rh_role_report_portfolio_id: number;
        claimed_flag: boolean;
        claim_user_id: number;
      }
    >({
      query: body => ({
        url: `/reporthubrolereportportfolio/rh_role_report_portfolio`,
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetRhAccessListQuery,
  useLazyGetAdminAccessReportHubUserListQuery,
  useLazyGetAdminAccessReportHubRoleListQuery,

  useAddRhRoleRequestAccessMutation,
  useAddRhUserRequestAccessMutation,

  usePutRhUserRequestAccessMutation,
  usePutRhRoleRequestAccessMutation,
  usePutRhUserRequestAccessClaimFlagMutation,
  usePutRhRoleRequestAccessClaimFlagMutation,
} = extendedApi;
