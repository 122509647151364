import { dmeApi } from './index';
import {
  ImportantLinkCommentAttachmentModel,
  ImportantLinkDetailsDiscussionTabCommentModel,
  ImportantLinkDetailsDiscussionTabModel,
  ToggleImportantLinkCommentReactionModel,
  UpdateImportantLinkCommentModel,
} from './types';

const extendApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getImportantLinkCommentDetail: builder.query<ImportantLinkDetailsDiscussionTabCommentModel, number>({
      query: link_comment_id => `/linkcomment/link_comment_id/${link_comment_id}`,
    }),

    getImportantLinkDetailsDiscussionTab: builder.query<
      Array<ImportantLinkDetailsDiscussionTabModel>,
      {
        ilPostId: number;
        query: string;
      }
    >({
      query: params => `/linkcomment/details/discussion/posts/link_post_id/${params.ilPostId}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getImportantLinkDetailsDiscussionTabByComment: builder.query<
      Array<ImportantLinkDetailsDiscussionTabModel>,
      {
        link_comment_id: number;
        query: string;
      }
    >({
      query: params =>
        `/linkcomment/details/discussion/comments/link_comment_id/${params.link_comment_id}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getImportantLinkCommentAttachmentsById: builder.query<Array<ImportantLinkCommentAttachmentModel>, number>({
      query: link_comment_id => `/linkcomment/attachment/link_comment_id/${link_comment_id}`,
      providesTags: ['ImportantLinkCommentAttachments'],
    }),

    postImportantLinkComment: builder.mutation<{ link_comment_id: number }, FormData>({
      query: params => ({
        url: '/linkcomment',
        method: 'POST',
        body: params,
      }),
    }),

    postImportantLinkCommentToggleReaction: builder.mutation<void, ToggleImportantLinkCommentReactionModel>({
      query: params => ({
        url: '/linkcomment/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateImportantLinkComment: builder.mutation<void, UpdateImportantLinkCommentModel>({
      query: params => ({
        url: '/linkcomment',
        method: 'PUT',
        body: params,
      }),
    }),

    deleteImportantLinkComment: builder.mutation<void, number>({
      query: link_comment_id => ({
        url: `/linkcomment/link_comment_id/${link_comment_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetImportantLinkDetailsDiscussionTabQuery,
  useLazyGetImportantLinkDetailsDiscussionTabQuery,
  useGetImportantLinkCommentAttachmentsByIdQuery,
  useLazyGetImportantLinkCommentDetailQuery,
  useLazyGetImportantLinkDetailsDiscussionTabByCommentQuery,
  usePostImportantLinkCommentMutation,
  usePostImportantLinkCommentToggleReactionMutation,
  useUpdateImportantLinkCommentMutation,
  useDeleteImportantLinkCommentMutation,
} = extendApi;
