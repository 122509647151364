import { Heading, HStack, Table, Tbody, Td, Text, Th, Tr, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useGetDataProductSelectDetailByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';

type Props = {};

const DataProductSelectedDetails = (props: Props) => {
  const params = useParams();
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    setProductId(+(params?.dataProductId || 0));
  }, [params]);

  const { data, isLoading } = useGetDataProductSelectDetailByProductIdQuery(productId, {
    skip: !productId,
  });

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Data Product Details</Heading>
      {isLoading ? (
        <p>Loading...</p>
      ) : !data ? null : (
        <HStack>
          <Table size="sm">
            <Tbody
              sx={{
                '& tr > th': {
                  maxW: '50px',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Tr>
                <Th>Domain</Th>
                <Td>
                  <Text whiteSpace="initial" noOfLines={2} title={data.domain} maxW="400px">
                    {data.domain}
                  </Text>
                </Td>
                <Td>&nbsp;</Td>
                <Th>Created</Th>
                <Td>{convertUtcToLocal(data.row_created_datetime_utc, 'MMMM D, YYYY h:mm A')}</Td>
              </Tr>
              <Tr>
                <Th>Developer</Th>
                <Td>
                  {data.developer_first_name} {data.developer_last_name}
                </Td>
                <Td>&nbsp;</Td>
                <Th minW={100}>Last Modified</Th>
                <Td whiteSpace="nowrap">{convertUtcToLocal(data.last_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}</Td>
              </Tr>
              <Tr>
                <Th>Owner</Th>
                <Td>
                  {data.domain_owner_first_name} {data.domain_owner_last_name}
                </Td>
                <Td>&nbsp;</Td>
                <Th>PII</Th>
                <Td>{data.pii_present_flag ? 'Yes' : 'No'}</Td>
              </Tr>
              <Tr>
                <Th>Type</Th>
                <Td>{data.data_product_type}</Td>
                <Td>&nbsp;</Td>
                <Th>PHI</Th>
                <Td>{data.phi_present_flag ? 'Yes' : 'No'}</Td>
              </Tr>
              <Tr>
                <Th minW={100}>Description</Th>
                <Td colSpan={4}>
                  <Text whiteSpace="pre-wrap" title={data.data_product_desc}>
                    {data.data_product_desc}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </HStack>
      )}
    </VStack>
  );
};

export default DataProductSelectedDetails;
