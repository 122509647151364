/* eslint-disable react-hooks/exhaustive-deps */
import { useAccount } from '@azure/msal-react';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppAuthRequiredAppAccess } from '../../app/constants/appAccesses';
import { getIdTokenClaims } from '../../app/services/auth/helper';
import { useGetUserRoleAccessListByUserEmailQuery } from '../../app/services/dme/api/userRole';
import { setCurrentUserUserRole } from '../../app/slices/userRoleSlice';
import { useAppSelector } from '../../app/state/hooks';
import { checkUserHasRequiredAppAccess } from '../../app/hooks/useIsUserHasRequiredRoles';

type IProps = {
  children: ReactNode | ((isAuthorized: boolean) => React.ReactNode);
  requiredAppAccess: AppAuthRequiredAppAccess;
  onUnAuthorized?: () => void;
};

const AppAuth: FC<IProps> = ({ children, requiredAppAccess, onUnAuthorized }) => {
  const dispatch = useDispatch();
  const { currentUserUserRole } = useAppSelector(s => s.userRole);
  const account = useAccount();
  const [email, setEmail] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isCheckingRole, setIsCheckingRole] = useState(true);
  const { data, isError, isSuccess, error, isLoading, isFetching } = useGetUserRoleAccessListByUserEmailQuery(email, {
    skip: Boolean(currentUserUserRole) || !email,
  });

  useEffect(() => {
    setIsCheckingRole(true);
  }, []);

  useEffect(() => {
    if (currentUserUserRole) {
      const inRole = checkUserHasRequiredAppAccess(currentUserUserRole, requiredAppAccess);

      setIsAuthorized(inRole);
      setIsCheckingRole(false);

      !inRole && onUnAuthorized && onUnAuthorized();
    }
  }, [currentUserUserRole, onUnAuthorized, requiredAppAccess]);

  useEffect(() => {
    const { emails } = getIdTokenClaims(account);
    if (emails?.length) {
      setEmail(emails[0]);
    }
  }, [account]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsCheckingRole(true);
    }
  }, [data, isError, isSuccess, error, isLoading, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCurrentUserUserRole(data));
    }
  }, [isSuccess, data]);

  if (typeof children === 'function') {
    return <>{children(isAuthorized)}</>;
  } else {
    return <>{!isCheckingRole && isAuthorized && <>{children}</>}</>;
  }
};

export default AppAuth;
