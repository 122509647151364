import { FormControl, Heading, Input, Table, Tbody, Td, Textarea, Th, Tr, VStack } from '@chakra-ui/react';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useAppAccessByRolesSelectedContext } from './AppAccessByRolesSelectedContext';

const AppAccessByRolesSelectedDetails = () => {
  const {
    detailsDetail: { data, isLoading },
    form,
    isEdit,
    onChangeValue,
    putDetail,
  } = useAppAccessByRolesSelectedContext();

  return isLoading ? (
    <>Loading...</>
  ) : (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Role Details</Heading>
      <Table size="sm">
        {isEdit ? (
          <Tbody>
            <Tr>
              <Th w="44">Role Name</Th>
              <Td>
                <FormControl
                  isInvalid={
                    !!(form?.errors.details && form?.errors.details?.roleName) &&
                    !!(form?.touched.details && form?.touched.details?.roleName)
                    // || tabErrorIndex === 0
                  }
                  display="flex"
                >
                  <Input
                    size="sm"
                    value={form?.values.details.roleName}
                    onChange={e => {
                      form && onChangeValue('details', { ...form.values.details, roleName: e.target.value });
                    }}
                    isDisabled={putDetail.isLoading}
                  />
                </FormControl>
              </Td>
            </Tr>
            <Tr>
              <Th w="44">Role Description</Th>
              <Td>
                <FormControl
                  isInvalid={
                    !!(form?.errors.details && form?.errors.details?.roleDesc) &&
                    !!(form?.touched.details && form?.touched.details?.roleDesc)
                  }
                  display="flex"
                >
                  <Textarea
                    size="sm"
                    value={form?.values.details.roleDesc}
                    onChange={e => {
                      form && onChangeValue('details', { ...form.values.details, roleDesc: e.target.value });
                    }}
                    isDisabled={putDetail.isLoading}
                  ></Textarea>
                </FormControl>
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody>
            <Tr>
              <Th>Role Name</Th>
              <Td maxW="96">{data?.role_name}</Td>
              <Td>&nbsp;</Td>
              <Th>Last Modified Date</Th>
              <Td>{convertUtcToLocal(data?.row_modified_datetime_utc)}</Td>
            </Tr>
            <Tr>
              <Th>Role Description</Th>
              <Td maxW="96">{data?.role_desc}</Td>
              <Td>&nbsp;</Td>
              <Th>Editor</Th>
              <Td>{data?.email}</Td>
            </Tr>
          </Tbody>
        )}
      </Table>
    </VStack>
  );
};

export default AppAccessByRolesSelectedDetails;
