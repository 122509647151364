import {
  VStack,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { Row } from 'react-table';
import { ValueOf } from '../../../app/helpers/utilities';
import { useGetRefTagListQuery } from '../../../app/services/dme/api/refTags';
import { LoreLanguageDetailsPageTagModel } from '../../../app/services/dme/api/types';
import { AddLoreLanguageModel } from '../../../app/services/types';
import EditableCell from '../../../components/CustomTable/EditableCell';

type Props = {
  data: AddLoreLanguageModel;
  onChangeData: (field: keyof AddLoreLanguageModel, data: ValueOf<AddLoreLanguageModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const LoreLanguageTags = ({ data, onChangeData, errors, touched, title }: Props) => {
  const { data: tagsData, isLoading: isLoadingTags, isFetching: isFetchingTags } = useGetRefTagListQuery();

  const generateIdForAddedItem = (): number => {
    return data.tagModel && data.tagModel.length > 0
      ? Math.min.apply(
          Math,
          data.tagModel.map(m => m.ll_lore_language_tag_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow = {
      tab: '',
      ll_lore_language_tag_id: generateIdForAddedItem(),
      ref_tag_id: 0,
      tag_desc: '',
      tag_name: '',
      tag_modified_datetime_utc: '',
      is_deleted_flag: false,
    };
    onChangeData('tagModel', data.tagModel ? [...data.tagModel, newRow] : [newRow]);
  };

  const onUpdateData = (rowIndex: number, columnId: string, value: string | File | number | number[]) => {
    const tagDesc = tagsData ? tagsData.find(f => f.ref_tag_id === value)?.tag_desc || '' : '';
    data.tagModel &&
      onChangeData(
        'tagModel',
        (data.tagModel &&
          data.tagModel.map((row, index) => {
            if (index === rowIndex && data.tagModel) {
              return {
                ...data.tagModel[rowIndex],
                [columnId]: value,
                tag_desc: columnId === 'ref_tag_id' ? tagDesc : data.tagModel[rowIndex].tag_desc,
              };
            }
            return row;
          })) ||
          [],
      );
  };

  const onRemove = (item: LoreLanguageDetailsPageTagModel) => {
    if (item.ll_lore_language_tag_id > 0) {
      data.tagModel &&
        onChangeData(
          'tagModel',
          data.tagModel &&
            data.tagModel.map((row, index) => {
              if (row.ll_lore_language_tag_id === item.ll_lore_language_tag_id && data.tagModel) {
                return {
                  ...data.tagModel[index],
                  is_deleted_flag: true,
                };
              }
              return row;
            }),
        );
    } else {
      data.tagModel &&
        onChangeData(
          'tagModel',
          data.tagModel.filter(f => f.ll_lore_language_tag_id !== item.ll_lore_language_tag_id),
        );
    }
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">{title}</Heading>
      </HStack>
      {isLoadingTags || isFetchingTags ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data.tagModel ? null : (
      <VStack>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Action</Th>
            </Tr>
            {(data.tagModel?.filter(f => !f.is_deleted_flag) || []).map((d, index) => (
              <Tr key={d.ll_lore_language_tag_id}>
                <Td>
                  {tagsData && (
                    <EditableCell
                      name={`tagModel.${index}.ref_tag_id`}
                      value={d.ref_tag_id}
                      row={{ index: index } as Row}
                      column={{ id: 'ref_tag_id' } as any}
                      updateData={onUpdateData}
                      type="dropdown"
                      dropdownOptions={tagsData.map(m1 => ({
                        text: m1.tag_name,
                        value: m1.ref_tag_id.toString(),
                        isDisabled: data.tagModel?.some(m2 => m2.ref_tag_id === m1.ref_tag_id),
                      }))}
                      isInvalid={
                        !!(
                          errors.tagModel &&
                          (errors.tagModel as unknown as LoreLanguageDetailsPageTagModel[])[index]?.ref_tag_id
                        ) &&
                        !!(
                          touched.tagModel &&
                          (touched.tagModel as unknown as LoreLanguageDetailsPageTagModel[])[index]?.ref_tag_id
                        )
                      }
                    />
                  )}
                </Td>
                <Td>{d.tag_desc}</Td>

                <Td textAlign="center">
                  {
                    <ButtonGroup spacing={1}>
                      <Tooltip label="Delete">
                        <IconButton
                          color="brand.error"
                          variant="link"
                          aria-label="Delete"
                          icon={<FaTrash />}
                          onClick={() => onRemove(d)}
                          minWidth={1}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
            Create New
          </Button>
        </Box>
      </VStack>
    );
  }
};

export default LoreLanguageTags;
