import { useFormik } from 'formik';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  useLazyGetRolesAppAccessRefAppAccessListQuery,
  useLazyGetRolesAppAccessRefDevelopmentEnvironmentListQuery,
  useLazyGetRolesAppAccessSelectedAppAccessQuery,
  useLazyGetRolesAppAccessSelectedDataProductQuery,
  useLazyGetRolesAppAccessSelectedDetailsQuery,
  useLazyGetRolesAppAccessSelectedDevelopmentEnvironmentQuery,
  useLazyGetRolesAppAccessSelectedPortfolioQuery,
  useLazyGetRolesAppAccessSelectedUsersQuery,
  usePutRolesAppAccessRoleByRoleIdMutation,
} from '../../../../app/services/dme/api/rolesAppAccess';
import {
  GetRefAccessLevelListModel,
  RolesAppAccessRefAppAccessModel,
  RolesAppAccessRefDevelopmentEnvironmentModel,
  RolesAppAccessSelectedAppAccessModel,
  RolesAppAccessSelectedAppAccessMutateModel,
  RolesAppAccessSelectedDataProductModel,
  RolesAppAccessSelectedDetailsModel,
  RolesAppAccessSelectedDevelopmentEnvironmentModel,
  RolesAppAccessSelectedDevelopmentEnvironmentMutateModel,
  RolesAppAccessSelectedPortfolioModel,
  RolesAppAccessSelectedUsersModel,
} from '../../../../app/services/dme/api/types';
import * as Yup from 'yup';
import { ValueOf } from '../../../../app/helpers/utilities';
import { useLazyGetRefAccessLevelListQuery } from '../../../../app/services/dme/api/refAccessLevel';

type Detail = {
  isLoading: boolean;
};
type QueryDetail<T = any> = {
  data?: T;
  isFetching: boolean;
} & Detail;
type RefTables = 'developer_environment' | 'access_level';
type Context = {
  roleId: number;
  setRoleId: Dispatch<SetStateAction<number>>;
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  headerError: string | null;
  setHeaderError: Dispatch<SetStateAction<string | null>>;
  detailsDetail: QueryDetail<RolesAppAccessSelectedDetailsModel>;
  appAccessDetail: QueryDetail<RolesAppAccessSelectedAppAccessModel[]>;
  dataProductDetail: QueryDetail<RolesAppAccessSelectedDataProductModel[]>;
  portfolioDetail: QueryDetail<RolesAppAccessSelectedPortfolioModel[]>;
  developmentEnvironmentDetail: QueryDetail<RolesAppAccessSelectedDevelopmentEnvironmentModel[]>;
  usersDetail: QueryDetail<RolesAppAccessSelectedUsersModel[]>;
  refAppAccessDetail: QueryDetail<RolesAppAccessRefAppAccessModel[]>;
  refDevEnvDetail: QueryDetail<RolesAppAccessRefDevelopmentEnvironmentModel[]>;
  refAccLvlDetail: QueryDetail<GetRefAccessLevelListModel[]>;
  refetchRefTable: (table: RefTables) => Promise<void>;
  putDetail: Detail;
  form?: ReturnType<typeof useFormik<AppAccessByRolesSelectedFormData>>;
  onChangeValue: (
    key: keyof AppAccessByRolesSelectedFormData,
    value: ValueOf<AppAccessByRolesSelectedFormData>,
  ) => void;
  handleSave: () => Promise<void>;
};
type Props = {
  children: ReactNode;
};
export type AppAccessByRolesSelectedFormData = {
  roleId: number;
  details: {
    roleName: string;
    roleDesc: string;
  };
  appAccess: RolesAppAccessSelectedAppAccessMutateModel[];
  devEnv: RolesAppAccessSelectedDevelopmentEnvironmentMutateModel[];
};

const defaultDetail: QueryDetail = {
  isFetching: false,
  isLoading: false,
};

const AppAccessByRolesSelectedContext = createContext<Context>({
  roleId: -1,
  setRoleId: () => {},
  isEdit: false,
  setIsEdit: () => {},
  headerError: null,
  setHeaderError: () => {},
  detailsDetail: defaultDetail,
  appAccessDetail: defaultDetail,
  dataProductDetail: defaultDetail,
  portfolioDetail: defaultDetail,
  developmentEnvironmentDetail: defaultDetail,
  usersDetail: defaultDetail,
  refAppAccessDetail: defaultDetail,
  refDevEnvDetail: defaultDetail,
  refAccLvlDetail: defaultDetail,
  putDetail: { isLoading: false },
  onChangeValue: () => {},
  handleSave: async () => {},
  refetchRefTable: async () => {},
});

const FormSchema = Yup.object().shape({
  roleId: Yup.number().moreThan(0).required(),
  details: Yup.object().shape({
    roleName: Yup.string().label('Role Name').required(),
    roleDesc: Yup.string().label('Role Description').required(),
  }),
  appAccess: Yup.array<RolesAppAccessSelectedAppAccessMutateModel[]>()
    .of(
      Yup.object().shape({
        isDeleted: Yup.boolean(),
        ref_app_access_id: Yup.number().when('isDeleted', {
          is: false,
          then: schema => schema.moreThan(0).required(),
        }),
      }),
    )
    .min(1, 'App Access must have at least 1 entry'),
  devEnv: Yup.array<RolesAppAccessSelectedDevelopmentEnvironmentMutateModel[]>().of(
    Yup.object().shape({
      isDeleted: Yup.boolean(),
      ref_developer_environment_id: Yup.number().when('isDeleted', {
        is: false,
        then: schema => schema.moreThan(0).required(),
      }),
    }),
  ),
});

const AppAccessByRolesSelectedProvider = ({ children }: Props) => {
  const [roleId, setRoleId] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);
  const [headerError, setHeaderError] = useState<string | null>(null);

  const [getDetails, detailsDetail] = useLazyGetRolesAppAccessSelectedDetailsQuery();
  const [getAppAccess, appAccessDetail] = useLazyGetRolesAppAccessSelectedAppAccessQuery();
  const [getDataProduct, dataProductDetail] = useLazyGetRolesAppAccessSelectedDataProductQuery();
  const [getPortfolio, portfolioDetail] = useLazyGetRolesAppAccessSelectedPortfolioQuery();
  const [getDevelopmentEnvironment, developmentEnvironmentDetail] =
    useLazyGetRolesAppAccessSelectedDevelopmentEnvironmentQuery();
  const [getUsers, usersDetail] = useLazyGetRolesAppAccessSelectedUsersQuery();
  const [getRefAppAccess, refAppAccessDetail] = useLazyGetRolesAppAccessRefAppAccessListQuery();
  const [getRefDevEnv, refDevEnvDetail] = useLazyGetRolesAppAccessRefDevelopmentEnvironmentListQuery();
  const [getRefAccLvl, refAccLvlDetail] = useLazyGetRefAccessLevelListQuery();

  const [putAsync, putDetail] = usePutRolesAppAccessRoleByRoleIdMutation();

  const form = useFormik<AppAccessByRolesSelectedFormData>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      roleId,
      details: { roleName: detailsDetail.data?.role_name ?? '', roleDesc: detailsDetail.data?.role_desc ?? '' },
      appAccess:
        appAccessDetail.data?.map((m, index) => ({
          ...m,
          isDeleted: false,
        })) || [],
      devEnv:
        developmentEnvironmentDetail.data?.map(m => ({
          ...m,
          isDeleted: false,
        })) || [],
    },

    onSubmit: values => {},
  });

  const fetch = () => {
    if (roleId > 0) {
      form.setValues(values => ({ ...values, roleId }));
      getDetails(roleId)
        .unwrap()
        .then(() => form.resetForm());
      getAppAccess(roleId)
        .unwrap()
        .then(() => form.resetForm());
      getDevelopmentEnvironment(roleId)
        .unwrap()
        .then(() => form.resetForm());
      getDataProduct(roleId);
      getPortfolio(roleId);
      getUsers(roleId);
    }
  };

  const onChangeValue = (
    key: keyof AppAccessByRolesSelectedFormData,
    value: ValueOf<AppAccessByRolesSelectedFormData>,
  ) => {
    form.setValues(values => ({ ...values, [key]: value }));
  };

  const handleSave = async () => {
    setHeaderError(null);
    form.handleSubmit(); // for checking and showing errors only

    const validationError = await form.validateForm(form.values);

    const hasNoAppAccess = form.values.appAccess.filter(f => !f.isDeleted).length <= 0;
    if (hasNoAppAccess) {
      form.setErrors({ ...form.errors, appAccess: 'App Access must have at least 1 entry' });
    }

    if (Object.keys(validationError).length > 0 || hasNoAppAccess) {
      setHeaderError('Some field(s) are invalid');
    } else {
      const appAccessInsert = form.values.appAccess
        .filter(f => !f.isDeleted && f.role_app_access_id <= 0)
        .map(m => m.ref_app_access_id);
      const appAccessDelete = form.values.appAccess
        .filter(f => f.isDeleted && f.role_app_access_id > 0)
        .map(m => m.role_app_access_id);
      const devEnvInsert = form.values.devEnv
        .filter(f => !f.isDeleted && f.dp_role_developer_environment_id <= 0)
        .map(m => m.ref_developer_environment_id);
      const devEnvDelete = form.values.devEnv
        .filter(f => f.isDeleted && f.dp_role_developer_environment_id > 0)
        .map(m => m.dp_role_developer_environment_id);

      await putAsync({
        ref_role_id: roleId,
        update_ref_role_model: {
          role_name: form.values.details.roleName,
          role_desc: form.values.details.roleDesc,
        },
        update_app_access_model: {
          insert_id_list: appAccessInsert,
          delete_id_list: appAccessDelete,
        },
        update_developer_environment_model: {
          insert_id_list: devEnvInsert,
          delete_id_list: devEnvDelete,
        },
      });
      fetch();
    }
  };

  const refetchRefTable = async (table: RefTables) => {
    if (table === 'developer_environment') {
      await getRefDevEnv().unwrap();
    } else if (table === 'access_level') {
      await getRefAccLvl().unwrap();
    }
  };

  useEffect(() => {
    fetch();
  }, [roleId]);

  useEffect(() => {
    if (isEdit) {
      !refAppAccessDetail.data && !refAppAccessDetail.isLoading && getRefAppAccess();
      !refDevEnvDetail.data && !refDevEnvDetail.isLoading && getRefDevEnv();
      !refAccLvlDetail.data && !refAccLvlDetail.isLoading && getRefAccLvl();
    } else {
      setHeaderError(null);
      form.resetForm();
    }
  }, [isEdit]);

  useEffect(() => {
    if (form.touched.appAccess && form.values.appAccess.filter(f => !f.isDeleted).length <= 0) {
      form.setErrors({ ...form.errors, appAccess: 'App Access must have at least 1 entry' });
    }
    if (!form.errors.details && !form.errors.appAccess && !form.errors.devEnv) {
      setHeaderError(null);
    }
  }, [form]);

  return (
    <AppAccessByRolesSelectedContext.Provider
      value={{
        roleId,
        setRoleId,
        isEdit,
        setIsEdit,
        headerError,
        setHeaderError,
        detailsDetail,
        appAccessDetail,
        dataProductDetail,
        portfolioDetail,
        developmentEnvironmentDetail,
        usersDetail,
        refAppAccessDetail,
        refDevEnvDetail,
        refAccLvlDetail,
        putDetail,
        form,
        onChangeValue,
        handleSave,
        refetchRefTable,
      }}
    >
      {children}
    </AppAccessByRolesSelectedContext.Provider>
  );
};

export const useAppAccessByRolesSelectedContext = () => {
  const context = useContext(AppAccessByRolesSelectedContext);
  if (!context) {
    throw new Error('Context error');
  }

  return context;
};

export default AppAccessByRolesSelectedProvider;
