import { removeUnwantedCharacters } from '../../../helpers/stringHelper';
import { dmeApi } from './index';
import {
  ImportantLinkDetailsPageDiscussionModel,
  ImportantLinkPostAttachmentModel,
  ToggleImportantLinkPostReactionModel,
  UpdateImportantLinkPostModel,
} from './types';

const extendApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getImportantLinkDetailsDiscussionPostsByImportantLinkId: builder.query<
      Array<ImportantLinkDetailsPageDiscussionModel>,
      number
    >({
      query: link_lore_link_id => `/linkpost/details/discussion/posts/link_lore_link_id/${link_lore_link_id}`,
    }),

    getImportantLinkPostById: builder.query<Array<ImportantLinkDetailsPageDiscussionModel>, number>({
      query: link_post_id => `/linkpost/details/discussion/posts/link_post_id/${link_post_id}`,
    }),

    getImportantLinkPostAttachmentsById: builder.query<Array<ImportantLinkPostAttachmentModel>, number>({
      query: link_post_id => `/linkpost/attachment/link_post_id/${link_post_id}`,
      providesTags: ['ImportantLinkPostAttachments'],
    }),

    addImportantLinkPost: builder.mutation<{ link_post_id: number }, FormData>({
      query: params => ({
        url: '/linkpost',
        method: 'POST',
        body: params,
      }),
    }),

    postImportantLinkPostToggleReaction: builder.mutation<void, ToggleImportantLinkPostReactionModel>({
      query: params => ({
        url: '/linkpost/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateImportantLinkPost: builder.mutation<void, UpdateImportantLinkPostModel>({
      query: params => ({
        url: '/linkpost',
        method: 'PUT',
        body: { ...params, post_message: removeUnwantedCharacters(params.post_message) },
      }),
    }),

    deleteImportantLinkPost: builder.mutation<void, number>({
      query: link_post_id => ({
        url: `/linkpost/link_post_id/${link_post_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetImportantLinkDetailsDiscussionPostsByImportantLinkIdQuery,
  useLazyGetImportantLinkPostByIdQuery,
  useGetImportantLinkPostAttachmentsByIdQuery,
  useAddImportantLinkPostMutation,
  usePostImportantLinkPostToggleReactionMutation,
  useUpdateImportantLinkPostMutation,
  useDeleteImportantLinkPostMutation,
} = extendApi;
