import { VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../../../app/helpers/dateHelper';
import { useGetRhPortfolioSelectedTagsByRhpidQuery } from '../../../../../app/services/dme/api/reportHub';
import { ReportHubSelectedTagsTabModel } from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import { CustomTableHeader } from '../../../../../app/types/appType';
import CustomTable from '../../../../../components/CustomTable';

type Props = {};

const ReportHubExplorePortfoliosSelectedViewTags: FC<Props> = props => {
  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const rhpId: number = parseInt(params?.rhpId || '0') || 0;
  const { data, isLoading, isFetching } = useGetRhPortfolioSelectedTagsByRhpidQuery(
    { userId, rhpId },
    { skip: rhpId === 0 },
  );

  const Header: CustomTableHeader<ReportHubSelectedTagsTabModel> = [
    {
      Header: 'Name',
      accessor: 'tag_name',
    },
    {
      Header: 'Description',
      accessor: 'tag_desc',
    },
    {
      Header: 'Notes',
      accessor: 'notes',
    },
    {
      Header: 'Created By',
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedTagsTabModel>) => {
        return <>{original.first_name + ' ' + original.last_name}</>;
      },
    },
    {
      Header: 'Created Date',
      isSortable: false,
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedTagsTabModel>) => {
        return <>{convertUtcToLocal(original.row_created_datetime_utc) || '-'}</>;
      },
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      {isLoading || isFetching ? (
        <>Loading...</>
      ) : (
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={false}
          isFetching={false}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          showNoRecords
        />
      )}
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedViewTags;
