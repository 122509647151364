import { dmeApi } from '.';
import { createFormData } from '../../../helpers/formHelper';
import {
  AddEmployeeOffboardingRequestModel,
    AddEmployeeOnboardingRequestModel,
    EmployeeOnboardingAdminApprovalModel,
    EmployeeOnboardingRequestModel,
} from './types';

const baseUrl = 'employeeonboardingrequest';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({

    //my request page
    getEmployeeOnboardingRequestByRequestorId: builder.query<EmployeeOnboardingRequestModel[], number>({
      query: requested_by_ref_user_id => baseUrl+`/requested_by_ref_user_id/${requested_by_ref_user_id}`,
    }),

    //approval
    getEmployeeOnboardingRequestApprovalList: builder.query<EmployeeOnboardingAdminApprovalModel[], number>({
      query: (ref_user_id) => baseUrl+`/adminapproval?ref_user_id=${ref_user_id}`,
    }),

    //add eo request
    addEmployeeOnboardingRequest: builder.mutation<number, AddEmployeeOnboardingRequestModel>({
      query: body => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
    }),

    //add employee offboarding request
    addEmployeeOffboardingRequest: builder.mutation<boolean, AddEmployeeOffboardingRequestModel>({
      query: body => ({
        url: baseUrl+'/offboarding',
        method: 'POST',
        body,
      }),
    }),

  }),
});

export const {
    useAddEmployeeOnboardingRequestMutation,
    useAddEmployeeOffboardingRequestMutation,
    useGetEmployeeOnboardingRequestByRequestorIdQuery,
    useGetEmployeeOnboardingRequestApprovalListQuery,
} = extendedApi;
