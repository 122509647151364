import { StyleFunctionProps } from '@chakra-ui/react';
import brandColors from '../brandColors';

const StepContent = (props: StyleFunctionProps) => ({
  '.chakra-steps .chakra-collapse': {
    display: 'initial !important',
    height: 'auto !important',
    opacity: '1 !important',
  },
});
export default StepContent;
