const PubNubConstants = {
  SignalEvent: {
    TYPING: 'typing_on',
    INCREMENT_COMMENT_COUNT: 'increment_comment_count',
  },
  MessageEvent: {
    Type: {
      NEW: 'new',
      UPDATE: 'update',
      DELETE: 'delete',
      CLAIM: 'claim',
      REFETCH: 'refetch',
    },
  },
};

export default PubNubConstants;
