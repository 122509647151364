import { VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = {};

const GovernanceOffice = (props: Props) => {
  return (
    <VStack w="full">
      <Outlet />
    </VStack>
  );
};

// test
export default GovernanceOffice;
