import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { FC, useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../../../app/constants';
import { buildSchema } from '../../../../app/helpers/formSchemabuilder';
import { getName } from '../../../../app/helpers/stringHelper';
import { useAddGoEdsRequestMutation } from '../../../../app/services/dme/api/governanceOfficeEds';
import { AddGovernanceOfficeEdsModel } from '../../../../app/services/dme/api/types';
import { useGetRefUserListQuery } from '../../../../app/services/dme/api/user';
import {
  MyEdsRequestCreateRequestProps,
  initialValuesMyEdsRequestCreateRequest,
  myEdsRequestCreateRequestFields,
  myEdsRequestCreateRequestTabs,
} from './utils';

const MyExternalDataSharingRequestsCreateRequestAssociatedUsers: FC<MyEdsRequestCreateRequestProps> = ({
  tabIndex,
  setTabIndex,
  data,
  setData,
}) => {
  const actionRef = useRef<number>(0);

  const toast = useToast();

  const usersDetails = useGetRefUserListQuery({ disabled_flag: false });
  const [addRequest, addRequestDetails] = useAddGoEdsRequestMutation();

  const FormSchema = Yup.object().shape({
    associated_users: Yup.array()
      .of(
        Yup.object().shape({
          ref_user_id: buildSchema(myEdsRequestCreateRequestFields.associated_users.ref_user_id),
          association_context: buildSchema(myEdsRequestCreateRequestFields.associated_users.association_context),
        }),
      )
      .min(1, 'Associated Users must have at least one entry.'),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddGovernanceOfficeEdsModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: data,
    onSubmit: (values, form) => {
      if (actionRef.current) {
        const d: AddGovernanceOfficeEdsModel = { ...data, associated_users: values.associated_users };
        setData(d);
        if (actionRef.current > 0) {
          addRequest(d)
            .unwrap()
            .then(() => {
              toast({ description: 'Request successfully added', status: 'success' });
              setData(initialValuesMyEdsRequestCreateRequest);
              setTabIndex(0);
            })
            .catch(() => toast({ description: defaultErrorMessage, status: 'error' }));
        } else {
          setData(d => ({ ...d, ...values }));
          setTabIndex(i => i + actionRef.current);
        }
      }
    },
  });

  return usersDetails.isLoading || usersDetails.isFetching ? (
    <>Loading...</>
  ) : (
    <VStack>
      {values.associated_users.length > 0 && (
        <TableContainer>
          <Table
            variant="simple"
            sx={{
              td: {
                verticalAlign: 'top',
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>{myEdsRequestCreateRequestFields.associated_users.ref_user_id.label}</Th>
                <Th>{myEdsRequestCreateRequestFields.associated_users.association_context.label}</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {values.associated_users.map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <FormControl
                        isInvalid={
                          !!errors.associated_users &&
                          !!(errors.associated_users[i] as any)?.ref_user_id &&
                          !!touched.associated_users &&
                          !!touched.associated_users[i]?.ref_user_id
                        }
                        display="flex"
                      >
                        <Box w="100%">
                          <Select
                            size="sm"
                            id={'associated_users.' + i + '.ref_user_id'}
                            name={'associated_users.' + i + '.ref_user_id'}
                            placeholder={usersDetails.isLoading || usersDetails.isFetching ? 'Loading...' : ''}
                            isDisabled={usersDetails.isLoading || usersDetails.isFetching}
                            useBasicStyles
                            menuPortalTarget={document.body}
                            value={(() => {
                              const val = usersDetails.data?.find(m => m.ref_user_id === r.ref_user_id);
                              return val
                                ? {
                                    label: getName(val),
                                    value: val.ref_user_id,
                                  }
                                : undefined;
                            })()}
                            options={(usersDetails.data ?? []).map(m => {
                              return {
                                label: m.first_name + ' ' + m.last_name,
                                value: m.ref_user_id,
                              };
                            })}
                            onChange={e => {
                              if (e && e.value) {
                                const tmp = values.associated_users.map(r => structuredClone(r));
                                tmp[i].ref_user_id = e.value;
                                setValues({ ...values, associated_users: tmp });
                              }
                            }}
                          />
                          <FormErrorMessage>
                            {!!errors.associated_users && (errors.associated_users[i] as any)?.ref_user_id}
                          </FormErrorMessage>
                        </Box>
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl
                        isInvalid={
                          !!errors.associated_users &&
                          !!(errors.associated_users[i] as any)?.association_context &&
                          !!touched.associated_users &&
                          !!touched.associated_users[i]?.association_context
                        }
                        h="100%"
                      >
                        <Input
                          size="sm"
                          id={'associated_users.' + i + '.association_context'}
                          name={'associated_users.' + i + '.association_context'}
                          value={r.association_context ?? ''}
                          maxLength={myEdsRequestCreateRequestFields.associated_users.association_context.max}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                        <FormErrorMessage>
                          {!!errors.associated_users && (errors.associated_users[i] as any)?.association_context}
                        </FormErrorMessage>
                      </FormControl>
                    </Td>

                    <Td>
                      <Tooltip label="Delete">
                        <IconButton
                          color="brand.error"
                          variant="link"
                          aria-label="Delete"
                          icon={<FaTrash />}
                          onClick={() => {
                            const tmp = [...values.associated_users];
                            tmp.splice(i, 1);
                            setValues(v => ({ ...v, associated_users: tmp }));
                          }}
                          minWidth={1}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {typeof errors.associated_users === 'string' && !!touched.associated_users && (
        <Text color="red.600" fontSize="sm">
          {errors.associated_users}
        </Text>
      )}

      <Box>
        <Button
          colorScheme="brand.main"
          size="sm"
          onClick={() => {
            setValues({
              ...values,
              associated_users: [
                ...values.associated_users,
                {
                  ref_user_id: 0,
                  association_context: '',
                },
              ],
            });
          }}
        >
          Create New
        </Button>
      </Box>

      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {myEdsRequestCreateRequestTabs.length}
        </Text>
        <ButtonGroup>
          <Button
            size="sm"
            isDisabled={addRequestDetails.isLoading}
            onClick={() => {
              actionRef.current = -1;
              submitForm();
            }}
          >
            Previous
          </Button>
          <Button
            size="sm"
            isLoading={addRequestDetails.isLoading}
            colorScheme="brand.main"
            onClick={() => {
              actionRef.current = 1;
              submitForm();
            }}
          >
            Submit
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default MyExternalDataSharingRequestsCreateRequestAssociatedUsers;
