import { Avatar, ButtonGroup, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdInfo } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDebounce from '../../../app/hooks/useDebounce';
import { RefUserModel } from '../../../app/services/dme/api/types';
import { useGetUserListQuery } from '../../../app/services/dme/api/user';
import CustomTable from '../../../components/CustomTable';

type Props = {
  onClickUser: (user: RefUserModel | null) => void;
  selectedUser: RefUserModel | null;
  addedUser: RefUserModel | null;
  onUserListChange?: () => void;
};

const UserList: FC<Props> = ({ selectedUser, addedUser, onClickUser, onUserListChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [searchDebounce, setSearchDebounce] = useState(searchParams.get('search') || '');
  const [tableData, setTableData] = useState<RefUserModel[] | null>(null);
  const navigate = useNavigate();

  const debouncedSearch = useDebounce(search, 400);

  const { data, isLoading, isFetching } = useGetUserListQuery(
    {
      pageNumber: pageNumber,
      pageSize: pageSize,
      q: searchDebounce,
    },
    { refetchOnMountOrArgChange: true },
  );
  const TableHeader = [
    {
      Header: 'Action',
      isAction: true,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: ({ row: { original } }: { row: { original: RefUserModel } }) => {
        const fullName = `${original.first_name} ${original.last_name}`;
        const avatarUrl = `${process.env.REACT_APP_CONTAINER_URL}/${process.env.REACT_APP_CONTAINER_NAME}/${original.email}.jpeg`;
        return (
          <VStack alignItems="end">
            <ButtonGroup spacing={1}>
              <Tooltip label={`ID : ${original.ref_user_id}`}>
                <IconButton minWidth={1} variant="link" aria-label="View Details" icon={<MdInfo />} />
              </Tooltip>

              <Tooltip label="View App">
                <IconButton
                  variant="link"
                  aria-label="View App"
                  icon={<BsFillEyeFill />}
                  onClick={() => navigate(`./selected/id/${original.ref_user_id}/details`)}
                  minWidth={1}
                />
              </Tooltip>
            </ButtonGroup>
            <Avatar
              name={`${fullName.trim() || original.email}`}
              src={avatarUrl}
              size="sm"
              bg="gray.300"
              color="gray.800"
            />
          </VStack>
        );
      },
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
  ];

  // TO DELETE
  const onSelectUser = (data: RefUserModel) => {
    onClickUser(data);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
    onUserListChange && onUserListChange();
    setSearchParams(!!debouncedSearch ? { search: debouncedSearch } : '', { replace: true });
  }, [debouncedSearch]);

  useEffect(() => {
    const newData = data?.data?.map(m => {
      if (addedUser && m.ref_user_id === addedUser.ref_user_id) {
        return { ...m, first_name: addedUser.first_name, last_name: addedUser.last_name };
      } else {
        return m;
      }
    });
    setTableData(newData || null);
  }, [data?.data, addedUser]);

  return (
    <>
      {tableData && (
        <>
          <CustomTable
            variant={'grid'}
            isLoading={isLoading}
            isFetching={isFetching}
            data={tableData ?? []}
            pageCount={data?.total_pages || 0}
            pageSize={data?.page_size || pageSize}
            totalRecords={data?.total_records || 0}
            headers={TableHeader}
            search={search}
            onPageChange={index => {
              setPageNumber(index);
            }}
            onPageSizeChange={size => {
              setPageSize(size);
            }}
            onPageSearch={search => {
              setPageNumber(1);
              setSearch(search);
            }}
            onSort={() => {}}
            rowDisabledOnTrue={row => Boolean(row.disabled_flag)}
            rowActiveOnTrue={row => row.ref_user_id === selectedUser?.ref_user_id}
            styles={{
              searchInput: { w: 'full' },
            }}
          />
        </>
      )}
    </>
  );
};

export default UserList;
