import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { AiOutlineStop } from 'react-icons/ai';
import { GoCheck, GoX } from 'react-icons/go';
import * as Yup from 'yup';
import { AppAccess } from '../../../app/constants/appAccesses';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { ValueOf } from '../../../app/helpers/utilities';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import {
  usePutRhUserRequestAccessClaimFlagMutation,
  usePutRhUserRequestAccessMutation,
} from '../../../app/services/dme/api/reportHubAccess';
import { AccessAdminApprovalModel, AdminAccessReportHubUser, RefUserModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import AppAuth from '../../../features/AppAuth';
import PubNubConstants from '../../../features/PubNubWrapper/constants';

type Props = {
  data: AccessAdminApprovalModel;
  refUsers: RefUserModel[];
  setData: (field: keyof AccessAdminApprovalModel, data: ValueOf<AccessAdminApprovalModel>) => void;
};

const FormSchema = Yup.object().shape({
  deny_reason: Yup.string().label('Reason').required().max(1500, 'Text exceed the character limit of 1500'),
});
const _channelName = 'admin_access_reporthub_user';
const openDelay = 1000;

const AccessApprovalReportHubUser = (props: Props) => {
  const {
    data: { fourth_dataset_model: data },
    refUsers,
    setData,
  } = props;

  const [success, setSuccess] = useState<number[]>([]);
  const [alertMessage, setAlertMessage] = useState('');

  const pubnub = usePubNub();
  const { messageEvent } = useAppSelector(s => s.pubNub);
  const { logonUser } = useAppSelector(s => s.user);
  const userWriteAccess = useIsUserHasRequiredAppAccess('adminaccessapproval.write');

  const [putAsync, putDetails] = usePutRhUserRequestAccessMutation();
  const [putClaimedFlagAsync, putClaimedFlagDetails] = usePutRhUserRequestAccessClaimFlagMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, errors, touched, handleChange, values, setValues } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      id: -1,
      deny_reason: '',
    },
    onSubmit: (values, form) => {
      putAsync({
        rh_user_report_portfolio_id: values.id,
        access_approved_flag: false,
        access_denied_flag: true,
        access_denied_notes: values.deny_reason,
      });
      setAlertMessage('');
      onClose();
      form.resetForm();
    },
  });

  useEffect(() => {
    pubnub.subscribe({
      channels: [_channelName],
      //withPresence: true,
    });

    return () => {
      pubnub.unsubscribe({
        channels: [_channelName],
      });
    };
  }, [pubnub]);

  useEffect(() => {
    if (messageEvent?.channel === _channelName) {
      const { message } = messageEvent;
      setData(
        'fourth_dataset_model',
        (data ?? []).map(item => {
          if (item.rh_user_report_portfolio_id === message.data.rh_user_report_portfolio_id) {
            return message.data;
          } else {
            return item;
          }
        }),
      );
    }
  }, [messageEvent]);

  useEffect(() => {
    if (putDetails.isSuccess && putDetails.originalArgs?.rh_user_report_portfolio_id) {
      setSuccess(s => [...s, putDetails.originalArgs!.rh_user_report_portfolio_id]);
      setTimeout(() => {
        setData(
          'fourth_dataset_model',
          (data ?? []).filter(
            i => i.rh_user_report_portfolio_id !== putDetails.originalArgs!.rh_user_report_portfolio_id,
          ),
        );
      }, 3000);
    }
    if (putDetails.error) {
      setAlertMessage('There was an error processing your request, please try again later.');
    }
  }, [putDetails]);

  const handleClaim = (data: AdminAccessReportHubUser, claim_flag: boolean) => async () => {
    if (logonUser) {
      const newData: AdminAccessReportHubUser = {
        ...data,
        claimed_flag: claim_flag,
        claim_user_id: claim_flag ? logonUser.ref_user_id : 0,
      };
      try {
        await pubnub.publish({
          channel: _channelName,
          message: {
            data: newData,
            type: PubNubConstants.MessageEvent.Type.CLAIM,
          },
        });

        await putClaimedFlagAsync({
          rh_user_report_portfolio_id: data.rh_user_report_portfolio_id,
          claimed_flag: claim_flag,
          claim_user_id: claim_flag ? logonUser.ref_user_id : 0,
        });
      } catch (ex) {
        await pubnub.publish({
          channel: _channelName,
          message: {
            data: {
              ...newData,
              claimed_flag: !claim_flag,
              claim_user_id: !claim_flag ? logonUser.ref_user_id : 0,
            },
            type: PubNubConstants.MessageEvent.Type.CLAIM,
          },
        });
      }
    }
  };

  const handleApprove = (data: AdminAccessReportHubUser) => () => {
    putAsync({
      rh_user_report_portfolio_id: data.rh_user_report_portfolio_id,
      access_approved_flag: true,
      access_denied_flag: false,
    });
    setAlertMessage('');
  };

  const handleDeny = (data: AdminAccessReportHubUser) => () => {
    setValues({ id: data.rh_user_report_portfolio_id, deny_reason: '' });
    onOpen();
  };

  const isSuccess = (data: AdminAccessReportHubUser) => {
    return success.some(id => id === data.rh_user_report_portfolio_id);
  };

  return (
    <>
      {alertMessage && (
        <Alert status={putDetails.isSuccess ? 'success' : 'error'}>
          <AlertIcon />
          {alertMessage}
        </Alert>
      )}
      <Table size="sm">
        <Tbody>
          <Tr>
            {userWriteAccess && <Th w="145px">Action</Th>}
            <Th>User</Th>
            <Th>Portfolio</Th>
            <Th>Business Justification</Th>
            <Th>Expiration Date</Th>
            <Th>Creation Date</Th>
          </Tr>
          {logonUser &&
            (data ?? []).map((d, i) => {
              const userHasClaimed = (data ?? []).some(s => s.claim_user_id === logonUser.ref_user_id);
              const claimedBy = refUsers.find(f => f.ref_user_id === d.claim_user_id);
              const claimedByFullName =
                claimedBy && (`${claimedBy.first_name || ''} ${claimedBy.last_name || ''}`.trim() || claimedBy.email);
              return (
                <Tr key={i}>
                  {userWriteAccess && (
                    <Td>
                      <HStack>
                        {d.claimed_flag && d.claim_user_id === logonUser.ref_user_id ? (
                          isSuccess(d) ? (
                            <Alert status="success" paddingY={1} paddingX={2}>
                              <AlertIcon />
                              Success!
                            </Alert>
                          ) : (
                            <HStack>
                              <Tooltip label="Approve" openDelay={openDelay}>
                                <IconButton
                                  aria-label="Approve"
                                  size="sm"
                                  colorScheme="brand.main"
                                  icon={<GoCheck />}
                                  onClick={handleApprove(d)}
                                  isLoading={
                                    putDetails.isLoading &&
                                    putDetails.originalArgs?.access_approved_flag &&
                                    d.rh_user_report_portfolio_id ===
                                      putDetails.originalArgs?.rh_user_report_portfolio_id
                                  }
                                  isDisabled={putDetails.isLoading}
                                />
                              </Tooltip>
                              <Tooltip label="Deny" openDelay={openDelay}>
                                <IconButton
                                  aria-label="Deny"
                                  size="sm"
                                  bg="brand.error"
                                  colorScheme="brand.error"
                                  icon={<GoX />}
                                  onClick={handleDeny(d)}
                                  isLoading={
                                    putDetails.isLoading &&
                                    putDetails.originalArgs?.access_denied_flag &&
                                    d.rh_user_report_portfolio_id ===
                                      putDetails.originalArgs?.rh_user_report_portfolio_id
                                  }
                                  isDisabled={putDetails.isLoading}
                                />
                              </Tooltip>
                            </HStack>
                          )
                        ) : (
                          <Tooltip label={claimedByFullName}>
                            <AppAuth requiredAppAccess={AppAccess.AdminAccessApprovalWrite}>
                              {isAuthorized => (
                                <Button
                                  size="sm"
                                  colorScheme={d.claimed_flag ? 'orange.800' : 'brand.main'}
                                  bg={d.claimed_flag ? 'orange.400' : 'brand.main.default'}
                                  onClick={d.claimed_flag ? () => {} : handleClaim(d, true)}
                                  isDisabled={
                                    (userHasClaimed && !d.claimed_flag) ||
                                    putDetails.isLoading ||
                                    putClaimedFlagDetails.isLoading ||
                                    !isAuthorized
                                  }
                                >
                                  {d.claimed_flag ? 'Claimed' : 'Claim'}
                                </Button>
                              )}
                            </AppAuth>
                          </Tooltip>
                        )}

                        {d.claimed_flag && !isSuccess(d) && (
                          <AppAuth requiredAppAccess={AppAccess.AdminAccessApprovalWrite}>
                            {isAuthorized => (
                              <Tooltip label="Unclaim" openDelay={openDelay}>
                                <IconButton
                                  aria-label="Unclaim"
                                  size="sm"
                                  icon={<AiOutlineStop />}
                                  onClick={handleClaim(d, false)}
                                  isDisabled={!isAuthorized || putClaimedFlagDetails.isLoading}
                                />
                              </Tooltip>
                            )}
                          </AppAuth>
                        )}
                      </HStack>
                    </Td>
                  )}
                  <Td>{`${d.first_name || ''} ${d.last_name || ''}`}</Td>
                  <Td>{d.report_portfolio_name}</Td>
                  <Td maxW="500px">{d.access_request_business_justification}</Td>
                  <Td>{convertUtcToLocal(d.access_expiration_datetime_utc)}</Td>
                  <Td>{convertUtcToLocal(d.access_requested_datetime_utc)}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Denial Reason</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl isInvalid={!!errors.deny_reason && touched.deny_reason}>
                <Textarea
                  id="deny_reason"
                  placeholder="Enter Reason"
                  name="deny_reason"
                  onChange={handleChange}
                  value={values.deny_reason}
                  maxLength={1500}
                />
                <FormErrorMessage>{errors.deny_reason}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3} ml="auto">
                Cancel
              </Button>
              <Button type="submit" colorScheme="brand.main">
                Deny
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AccessApprovalReportHubUser;
