import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'quill-emoji';
//import "quill-emoji/dist/quill-emoji.css";

import { Box, useStyleConfig } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { getAvatarUrl } from '../../app/helpers/avatarHelper';
import { removeImageFromHtmlString } from '../../app/helpers/stringHelper';
import { debounce } from '../../app/helpers/utilities';
import { useLazyGetUserListQuery } from '../../app/services/dme/api/user';

const toolbarOptions = {
  container: [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      { color: [] },
      { background: [] },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      'emoji',
      'link',
      'video',
      'clean',
    ],
    //[{ header: 1 }, { header: 2 }],
    //[{ script: "sub" }, { script: "super" }],
    //[{ direction: "rtl" }],
    //[{ header: [1, 2, 3, 4, 5, 6, false] }],
    //[{ font: [] }],
    //[{ align: [] }],
    //["link", "image", "video"],
  ],
  handlers: { emoji: function () {} },
};
type OnChangeMeta = {
  hasImage: boolean;
};

export type RichTextEditorProps = {
  value: string;
  removeImages?: boolean;
  onChange: (value: string, meta: OnChangeMeta) => void;
  onBlur?: ReactQuillProps['onBlur'];
};

const RichTextEditor: FC<RichTextEditorProps> = ({ value, onChange: onChangeProps, onBlur, removeImages = false }) => {
  const [quillModule, setQuillModule] = useState<any>();
  const [getUsers] = useLazyGetUserListQuery();
  const styles = useStyleConfig('Textarea');

  const onChange: ReactQuillProps['onChange'] = e => {
    const [text, hasImage] = removeImageFromHtmlString(e);
    onChangeProps(removeImages ? text : e, { hasImage });
  };

  useEffect(() => {
    setQuillModule({
      toolbar: toolbarOptions,
      'emoji-toolbar': true,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        minChars: 1,
        source: debounce(async function (searchTerm: string, renderList: (data: any) => void) {
          if (!searchTerm.trim()) {
            renderList([]);
          } else {
            const matchedPeople = await getUsers({
              q: searchTerm,
              pageNumber: 1,
              pageSize: 10,
              disabled_flag: false,
            }).unwrap();
            const result = matchedPeople.data.map(m => ({
              id: m.email,
              //value: `${m.first_name || ""} ${m.last_name || ""}`,
              value: !(m.first_name && m.last_name) ? m.email : `${m.first_name || ''} ${m.last_name || ''}`,
            }));
            renderList(result);
          }
        }),
        renderLoading: () => 'loading...',
        //positioningStrategy: "fixed",
        //fixMentionsToQuill: true,
        defaultMenuOrientation: 'top',
        renderItem: (item: any, searchTerm: any) => {
          return `<img alt="" src="${getAvatarUrl(item.id)}" /> <span>${item.value}</span>`;
        },
        onSelect: function (item: any, insertItem: any) {
          insertItem({ ...item, value: `<strong>${item.value}</strong>` });
        },
      },
    });
  }, []);

  return (
    <Box
      __css={styles}
      p={0}
      h="auto"
      minH="auto"
      sx={{
        '.quill .ql-container .ql-editor': { maxH: '300px' },
      }}
    >
      {quillModule && (
        <ReactQuill theme="snow" modules={{ ...quillModule }} value={value} onChange={onChange} onBlur={onBlur} />
      )}
    </Box>
  );
};

export default RichTextEditor;
