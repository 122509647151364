import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = {};

const ExploreDataProducts = (props: Props) => {
  return (
    <Box w="100%">
      <Outlet />
    </Box>
  );
};

export default ExploreDataProducts;
