import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { getName } from '../../../app/helpers/stringHelper';
import { useGetGoEdsRequestSelectedVoteQuery } from '../../../app/services/dme/api/governanceOfficeEds';
import { GoEdsRequestSelectedVoteModel } from '../../../app/services/dme/api/types';
import { CustomTableHeader } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';

type Props = {};

const Header: CustomTableHeader<GoEdsRequestSelectedVoteModel> = [
  {
    Header: 'Name',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<GoEdsRequestSelectedVoteModel>) => {
      return <>{getName(original)}</>;
    },
  },
  {
    Header: 'Vote',
    isSortable: false,
    accessor: 'request_vote_name',
    styles: { textTransform: 'capitalize' },
    Cell: ({ row: { original } }: CellProps<GoEdsRequestSelectedVoteModel>) => {
      return <>{original.request_vote_name}</>;
    },
  },
  {
    Header: 'Vote Date',
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<GoEdsRequestSelectedVoteModel>) => {
      return <>{convertUtcToLocal(original.vote_datetime_utc) || '-'}</>;
    },
  },
];

const GovernanceOfficeSelectedVotes = (props: Props) => {
  const params = useParams();

  const goEdsId: number = parseInt(params?.goEdsId || '');
  const { data, isLoading, isFetching } = useGetGoEdsRequestSelectedVoteQuery({ goEdsId }, { skip: goEdsId === 0 });

  if (isLoading || isFetching) return <>Loading...</>;
  if (!data) return <></>;

  return (
    <Box w="fit-content">
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isLoading}
        data={data || []}
        pageCount={0}
        pageSize={data ? data.length : 0}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        showNoRecords
      />
    </Box>
  );
};

export default GovernanceOfficeSelectedVotes;
