import { HStack, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { addBreadcrumb } from '../../app/helpers/navigationHelpers';
import { getSearchParams } from '../../app/helpers/utilities';
import { useGetInfoweaveListQuery } from '../../app/services/dme/api/infoweave';
import CustomTable from '../../components/CustomTable';
import ActionsCell from './Cells/ActionsCell';
import TagsCell from './Cells/TagsCell';
import FocusCountBadge from './Components/FocusCountBadge';
import GroupSelectionButtons from './Components/GroupSelectionButtons';
import InfoweaveFilter from './Components/InfoweaveFilter';
import { InfoweaveSearch } from './Components/InfoweaveSearch';
import InfoweaveContextProvider, {
  InfoweaveData,
  InfoweaveFilterTabName,
  InfoweaveParams,
  InfoweaveReadFilter,
} from './InfoweaveContext';

type Props = {};

const Infoweave = () => {
  return (
    <InfoweaveContextProvider>
      <InfoweaveChild />
    </InfoweaveContextProvider>
  );
};

const tabs: { label: string; path: InfoweaveFilterTabName }[] = [
  {
    label: 'Focus',
    path: 'focus',
  },
  // {
  //   label: "View All",
  //   path: "all",
  // },
  {
    label: 'Bookmarked',
    path: 'bookmark',
  },
  {
    label: 'Archived',
    path: 'archive',
  },
];
const focusIndex = 0;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const defaultStartDate = convertUtcToLocal(dayjs(new Date(0)).startOf('day').toDate(), dateFormat);
const defaultEndDate = convertUtcToLocal(dayjs().endOf('day').toDate(), dateFormat);

const InfoweaveChild = (props: Props) => {
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const [data, setData] = useState<InfoweaveData[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const tabRef = useRef<number | undefined>();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const tabParam = useMemo(() => searchParams.get(InfoweaveParams.tab) as InfoweaveFilterTabName, [searchParams]);
  const readParam = useMemo(() => searchParams.get(InfoweaveParams.read) as InfoweaveReadFilter, [searchParams]);
  const searchParam = useMemo(() => searchParams.get(InfoweaveParams.search), [searchParams]);

  const sdParams = useMemo(() => searchParams.get(InfoweaveParams.startDate), [searchParams]);
  const sdDayjs = dayjs(sdParams);
  const startDateFilter = sdDayjs.isValid()
    ? convertUtcToLocal(sdDayjs.startOf('day').toDate(), dateFormat)
    : defaultStartDate;
  const edParams = useMemo(() => searchParams.get(InfoweaveParams.endDate), [searchParams]);
  const edDayjs = dayjs(edParams);
  const endDateFilter = edDayjs.isValid()
    ? convertUtcToLocal(edDayjs.endOf('day').toDate(), dateFormat)
    : defaultEndDate;

  const tagIds = useMemo(
    () =>
      (searchParams.get(InfoweaveParams.tags)?.split(',') ?? []).map(strId => parseInt(strId)).filter(id => !isNaN(id)),
    [searchParams],
  );

  const {
    data: apiData,
    isLoading,
    isFetching,
  } = useGetInfoweaveListQuery({
    page_number: pageNumber,
    page_size: pageSize,
    tab_name: tabParam || 'all',
    read_filter: readParam || 'all',
    search_string: searchParam ?? '',
    sort_column: 'tag_name',
    sort_order: 'asc',
    start_date_filter: startDateFilter,
    end_date_filter: endDateFilter,
    filters: tagIds.map(id => ({ filter_order: 1, filter_ref_id: id })),
  });

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    addBreadcrumb({ label: 'Home', path: location.pathname });
  }, []);

  useEffect(() => {
    if (isLoading || isFetching) setIsProcessing(true);
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (apiData?.data) {
      if (apiData.data.length <= 0 && pageNumber > 1) {
        // scenario: archiving all entries in last page will make displayed list empty.
        // solution: go the previous page so there are entries displayed
        setPageNumber(pageNumber - 1);
      }

      setIsProcessing(true);
      const tmpList: InfoweaveData[] = [];

      apiData?.data.forEach(infoweave => {
        const index = tmpList.findIndex(s => s.dme_infoweave_id === infoweave.dme_infoweave_id);
        if (index > -1) {
          tmpList[index].tags.push(infoweave);
        } else {
          tmpList.push({
            ...infoweave,
            tags: [infoweave],
          });
        }
      });

      setData(tmpList);
      setIsProcessing(false);
    }

    // add isLoading, isFetching to capture scenario, when no changes from previous data
  }, [apiData, isLoading, isFetching]);

  useEffect(() => {
    if (pageNumber !== 1) setPageNumber(1);
  }, [tagIds, tabParam, readParam, sdParams, edParams]);

  useEffect(() => {
    if (tabIndex !== undefined && tabParam !== tabs[tabIndex].path) {
      // changing tabs will pass here
      const search = searchParam ? { [InfoweaveParams.search]: searchParam } : {};
      setSearchParams(
        {
          ...(tabRef.current !== tabIndex ? {} : getSearchParams(searchParams)),
          [InfoweaveParams.tab]: tabs[tabIndex].path,
          ...search,
        },
        { replace: true },
      );
      tabRef.current = tabIndex;
    } else {
      if (tabIndex === undefined) {
        // most likely scenario to arrive here is first load of page
        const index = tabs.findIndex(t => t.path === tabParam);
        setTabIndex(index > -1 ? index : focusIndex);
        if (index <= -1) {
          setSearchParams(
            {
              ...getSearchParams(searchParams),
              [InfoweaveParams.tab]: tabs[focusIndex].path,
            },
            { replace: true },
          );
          tabRef.current = tabIndex;
        }
      }
    }
  }, [tabIndex, searchParams]);

  return (
    <VStack w="100%">
      <HStack w="100%">
        <InfoweaveSearch />
        <InfoweaveFilter />
      </HStack>

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={m.path}>
                {m.label} {tabs[i].path === 'focus' && <FocusCountBadge />}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <CustomTable
              showNoRecords
              hideHeaders
              manual
              manualSortBy
              variant="table"
              isLoading={isLoading || isProcessing}
              isFetching={isFetching || isProcessing}
              data={data}
              pageCount={apiData?.total_pages || 0}
              pageSize={apiData?.page_size || pageSize}
              totalRecords={apiData?.total_records || 0}
              pageIndex={pageNumber - 1}
              headers={Headers()}
              search={searchParam ?? ''}
              onPageChange={index => {
                setPageNumber(index + 1);
              }}
              onPageSizeChange={size => {
                setPageNumber(1);
                setPageSize(size);
              }}
              // onPageSearchDebounce={400}
              // onPageSearch={(search) => {
              //   setPageNumber(1);

              //   const params: DynamicObject = {};
              //   searchParams.forEach((val, key) => (params[key] = val));
              //   setSearchParams({ ...params, search: search }, { replace: true });
              // }}
              onSort={sort => {
                if (sort[0]) {
                  setPageNumber(1);
                  // setSortBy(sort[0]);
                }
              }}
              styles={{ pagination: { justifyContent: 'start' } }}
              additionalComponents={{
                footerEnd: <GroupSelectionButtons />,
              }}
            />
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

const Headers = () => {
  return [
    {
      Header: 'Tags',
      styles: { whiteSpace: 'nowrap', verticalAlign: 'top', width: '1%' },
      Cell: TagsCell,
    },
    {
      Header: 'Message',
      accessor: 'infoweave_message',
      styles: { whiteSpace: 'initial', verticalAlign: 'top' },
    },
    {
      Header: 'Actions',
      styles: { whiteSpace: 'nowrap', verticalAlign: 'top', width: '1%' },
      Cell: ActionsCell,
    },
  ];
};

export default Infoweave;
