import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetDataProductMyAccessQuery } from '../../../../app/services/dme/api/dataProduct';
import {
  DataProductMyAccessFilterModel,
  DataProductMyAccessFirstDatasetModel,
  DataProductMyAccessModel,
  DataProductMyAccessThirdDatasetModel,
} from '../../../../app/services/dme/api/types';
import { FilterItem } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import FilterPane from '../FilterPane';
import MyAccessDetail from '../MyAccessDetail';
import MyAccessResultData from './MyAccessResultData';

type Props = {};

const MyAccessResult = (props: Props) => {
  const location = useLocation();

  const { logonUser } = useAppSelector(s => s.user);
  const [isResultLoading, setIsResultLoading] = useState(true);
  const { data, isLoading } = useGetDataProductMyAccessQuery(logonUser?.ref_user_id || 0, {
    skip: !logonUser,
  });
  const [selectedAccess, setSelectedAccess] = useState<DataProductMyAccessFirstDatasetModel | null>(null);

  const [mainData, setmainData] = useState<DataProductMyAccessModel | null>(null);
  const [filteredData, setFilteredData] = useState<DataProductMyAccessFirstDatasetModel[] | null>(null);
  const [filterData, setFilterData] = useState<Array<DataProductMyAccessThirdDatasetModel> | null>(null);

  const onFilterChange = (checkedFilters: DataProductMyAccessFilterModel[]) => {
    setIsResultLoading(true);

    if (checkedFilters.length > 0) {
      const filtered2ndData = mainData?.second_dataset_model?.filter(f =>
        checkedFilters.some(s => s.filter_header === f.filter_header && s.filter_detail === f.filter_detail),
      );

      const filterObj: Array<FilterItem> = [];
      filtered2ndData?.forEach(value => {
        const existingFilterIndex = filterObj.findIndex(
          f => f.filter_header.toLowerCase() === value.filter_header.toLowerCase(),
        );
        if (existingFilterIndex > -1) {
          filterObj[existingFilterIndex].ids = [...filterObj[existingFilterIndex].ids, value.request_id];
        } else {
          filterObj.push({
            filter_header: value.filter_header.toLowerCase(),
            ids: [value.request_id],
          });
        }
      });

      let ids: Array<number> = filterObj ? filterObj.map(m => m.ids)[0] : [];
      filterObj.forEach(a => {
        const newDpIds = ids?.filter(f => a.ids.includes(f));
        if (newDpIds) {
          ids = [...newDpIds];
        }
      });

      const filtered1stData = mainData?.first_dataset_model?.filter(f => ids.some(id => f.request_id == id)) || [];

      setFilteredData(filtered1stData);
    } else {
      setFilteredData(mainData?.first_dataset_model || []);
    }
    setIsResultLoading(false);
  };

  useEffect(() => {
    addBreadcrumb({ label: 'Result', path: location.pathname });
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      setmainData(data);
      const filter: DataProductMyAccessFilterModel[] = data.third_dataset_model.reduce(function (
        r: DataProductMyAccessFilterModel[],
        o: DataProductMyAccessFilterModel,
      ) {
        let dataElementExistIndex = r.findIndex(
          f => f.filter_header === o.filter_header && f.filter_order === o.filter_order,
        );
        if (dataElementExistIndex > -1) {
          r[dataElementExistIndex].filter_items = [
            ...(r[dataElementExistIndex].filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r];
        } else {
          const filterItems = [
            ...(o.filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r, { ...o, filter_items: filterItems }];
        }
      }, []);
      setFilterData(filter);
      setFilteredData(data.first_dataset_model);
      setIsResultLoading(false);
    }
  }, [data]);

  return (
    <Flex w="100%">
      <Box w={300}>
        {filterData ? <FilterPane filterData={filterData} onChange={onFilterChange} /> : <>Loading...</>}
      </Box>
      <Box flex={1} px={3}>
        {selectedAccess ? (
          <MyAccessDetail selectedAccess={selectedAccess} onClose={() => setSelectedAccess(null)} />
        ) : (
          <MyAccessResultData
            data={filteredData || []}
            isLoading={isResultLoading}
            // onClickRow={(row: DataProductMyAccessFirstDatasetModel | null) =>
            //   setSelectedAccess(row)
            // }
          />
        )}
      </Box>
    </Flex>
  );
};

export default MyAccessResult;
