import { Box, Flex, VStack } from '@chakra-ui/react';
import FilterPane from './FilterPane';
import Result from './Result/Result';

type Props = {};

const DataProductResult = (props: Props) => {
  return (
    <Flex align="start">
      <Box flexGrow={1}>
        <FilterPane />
      </Box>
      <VStack p={3} pt={2} bgColor="white" w="75%" h="100vh" spacing={2}>
        <Result />
      </VStack>
    </Flex>
  );
};

export default DataProductResult;
