import { dmeApi } from '.';
import { removeUnwantedCharacters } from '../../../helpers/stringHelper';
import {
  ReportHubDetailsPageDiscussionModel,
  ReportHubPostAttachmentModel,
  ToggleReportHubPostReactionModel,
  UpdateReportHubPostModel,
} from './types';

const baseUrl = '/reports';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getReportHubDetailsDiscussionPostsByReportHubId: builder.query<ReportHubDetailsPageDiscussionModel[], number>({
      query: rhpId => baseUrl + `/portfolio/details/discussions/posts/rh_report_portfolio_id/${rhpId}/state_flag/false`,
    }),

    getReportHubPostById: builder.query<ReportHubDetailsPageDiscussionModel[], number>({
      query: rhPostId => baseUrl + `/portfolio/details/discussions/posts/rh_post_id/${rhPostId}`,
    }),

    getReportHubPostAttachmentsById: builder.query<Array<ReportHubPostAttachmentModel>, number>({
      query: rh_post_id => `/reporthubpost/attachment/rh_post_id/${rh_post_id}`,
      providesTags: ['ReportHubPostAttachments'],
    }),

    addReportHubPost: builder.mutation<{ rh_post_id: number }, FormData>({
      query: params => ({
        url: '/reporthubpost',
        method: 'POST',
        body: params,
      }),
    }),

    postReportHubPostToggleReaction: builder.mutation<void, ToggleReportHubPostReactionModel>({
      query: params => ({
        url: '/reporthubpost/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateReportHubPost: builder.mutation<void, UpdateReportHubPostModel>({
      query: params => ({
        url: '/reporthubpost',
        method: 'PUT',
        body: { ...params, post_message: removeUnwantedCharacters(params.post_message) },
      }),
    }),

    deleteReportHubPost: builder.mutation<void, number>({
      query: rh_post_id => ({
        url: `/reporthubpost/` + rh_post_id,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetReportHubDetailsDiscussionPostsByReportHubIdQuery,
  useLazyGetReportHubPostByIdQuery,
  useGetReportHubPostAttachmentsByIdQuery,
  useAddReportHubPostMutation,
  usePostReportHubPostToggleReactionMutation,
  useUpdateReportHubPostMutation,
  useDeleteReportHubPostMutation,
} = extendedApi;
