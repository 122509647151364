import { useParams } from 'react-router-dom';
import { useGetRefUserListDetailsQuery } from '../../../../app/services/dme/api/user';
import { Box, HStack, Heading, Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';

type Props = {};

const UserSelectedDetailsTab: React.FC<Props> = () => {
  const params = useParams();
  const id: number = parseInt(params?.id || '');

  const { data, isLoading, isFetching } = useGetRefUserListDetailsQuery(id, { skip: !id });

  return isLoading || isFetching ? (
    <>Loading...</>
  ) : (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">User Details</Heading>

      <HStack>
        <Table size="sm">
          <Tbody
            sx={{
              '& tr > th': {
                maxW: '50px',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <Tr>
              <Th minW={170}>ID</Th>
              <Td>{data?.ref_user_id}</Td>
              <Td>&nbsp;</Td>
              <Th minW={110}>Created</Th>
              <Td>{convertUtcToLocal(data?.row_created_datetime_utc, 'MMM D, YYYY h:mm A')}</Td>
            </Tr>
            <Tr>
              <Th>First Name</Th>
              <Td>{data?.first_name}</Td>
              <Td>&nbsp;</Td>
              <Th>Last Modified</Th>
              <Td>{convertUtcToLocal(data?.row_modified_datetime_utc, 'MMM D, YYYY h:mm A')}</Td>
            </Tr>
            <Tr>
              <Th>Last Name</Th>
              <Td>{data?.last_name}</Td>
              <Td>&nbsp;</Td>
              <Th></Th>
              <Td></Td>
            </Tr>
            <Tr>
              <Th>Email</Th>
              <Td>{data?.email}</Td>
              <Td>&nbsp;</Td>
              <Th></Th>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </HStack>
    </VStack>
  );
};

export default UserSelectedDetailsTab;
