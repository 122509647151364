import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { MdWarning } from 'react-icons/md';
import * as Yup from 'yup';
import { useDeleteImportantLinkMutation } from '../../../../app/services/dme/api/importantLink';

const FormSchema = Yup.object().shape({
  delete_text: Yup.string().label('').required().equals(['delete']),
  link_lore_link_id: Yup.number().required().moreThan(0, 'Id is a required field'),
});

type Props = {
  link_lore_link_id: number;
  triggerElement: JSX.Element;
  reloadList?: () => void;
};

const DeleteLoreLinkDialog = ({ link_lore_link_id, triggerElement }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAsync, deleteDetail] = useDeleteImportantLinkMutation();
  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      link_lore_link_id: link_lore_link_id,
      delete_text: '',
    },
    onSubmit: values => {
      deleteAsync(values.link_lore_link_id);
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = deleteDetail;

    if (isSuccess) {
      setAlertMessage('Important Link successfully deleted.');
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [deleteDetail]);

  return (
    <>
      <Box
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Important Link</ModalHeader>
          <ModalCloseButton isDisabled={deleteDetail.isLoading} />
          <>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <VStack spacing={5}>
                  <Alert status="error">
                    <AlertIcon />
                    Removing this link will also remove all the information attached to this link including the Domain
                    Specific links, Tags, Discussions and comments.
                  </Alert>
                  <FormControl isInvalid={!!errors.delete_text && touched.delete_text}>
                    <FormLabel>Confirm deletion by typing "delete"</FormLabel>
                    <Input
                      id="delete_text"
                      name="delete_text"
                      placeholder="delete"
                      onChange={handleChange}
                      value={values.delete_text}
                      maxLength={100}
                    />
                    <HStack alignItems="center" spacing={2} mt={2}>
                      <Icon as={MdWarning} color="brand.error" />
                      <Text as="span">The Operation cannot be undone</Text>
                    </HStack>
                  </FormControl>

                  <VStack spacing={5}>
                    {alertMessage && (
                      <Alert status={deleteDetail.isSuccess ? 'success' : 'error'}>
                        <AlertIcon />
                        {alertMessage}
                      </Alert>
                    )}
                  </VStack>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3} ml="auto" isDisabled={deleteDetail.isLoading}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.error"
                  bg="brand.error"
                  isLoading={deleteDetail.isLoading}
                  isDisabled={(alertMessage !== '' && deleteDetail.isSuccess) || values.delete_text !== 'delete'}
                >
                  Delete
                </Button>
              </ModalFooter>
            </form>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteLoreLinkDialog;
