import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useGetCustomRequestMyRequestQuery } from '../../../app/services/dme/api/customRequest';
import {
  GetCustomRequestMyRequestFilterModel,
  GetCustomRequestMyRequestFirstDatasetModel,
  GetCustomRequestMyRequestModel,
  GetCustomRequestMyRequestThirdDatasetModel,
} from '../../../app/services/dme/api/types';
import { FilterItem } from '../../../app/services/types';
import FilterPane from './FilterPane';
import CustomRequestsMyRequestData from './MyRequestsResultData';

type Props = {};

const CustomRequestsMyRequests = (props: Props) => {
  const [isResultLoading, setIsResultLoading] = useState(true);
  const { data, isLoading } = useGetCustomRequestMyRequestQuery();

  const [mainData, setmainData] = useState<GetCustomRequestMyRequestModel | null>(null);
  const [filteredData, setFilteredData] = useState<GetCustomRequestMyRequestFirstDatasetModel[] | null>(null);
  const [filterData, setFilterData] = useState<Array<GetCustomRequestMyRequestThirdDatasetModel> | null>(null);

  const onFilterChange = (checkedFilters: GetCustomRequestMyRequestFilterModel[]) => {
    setIsResultLoading(true);

    if (checkedFilters.length > 0) {
      const filtered2ndData = mainData?.second_dataset_model?.filter(f =>
        checkedFilters.some(s => s.filter_header === f.filter_header && s.filter_detail === f.filter_detail),
      );

      const filterObj: Array<FilterItem> = [];
      filtered2ndData?.forEach(value => {
        const existingFilterIndex = filterObj.findIndex(
          f => f.filter_header.toLowerCase() === value.filter_header.toLowerCase(),
        );
        if (existingFilterIndex > -1) {
          filterObj[existingFilterIndex].ids = [...filterObj[existingFilterIndex].ids, value.cr_custom_request_id];
        } else {
          filterObj.push({
            filter_header: value.filter_header.toLowerCase(),
            ids: [value.cr_custom_request_id],
          });
        }
      });

      let ids: Array<number> = filterObj ? filterObj.map(m => m.ids)[0] : [];
      filterObj.forEach(a => {
        const newCrIds = ids?.filter(f => a.ids.includes(f));
        if (newCrIds) {
          ids = [...newCrIds];
        }
      });

      const filtered1stData =
        mainData?.first_dataset_model?.filter(f => ids.some(id => f.cr_custom_request_id == id)) || [];

      setFilteredData(filtered1stData);
    } else {
      setFilteredData(mainData?.first_dataset_model || []);
    }
    setIsResultLoading(false);
  };

  useEffect(() => {
    if (data && !isLoading) {
      setmainData(data);
      const filter: GetCustomRequestMyRequestFilterModel[] = data.third_dataset_model.reduce(function (
        r: GetCustomRequestMyRequestFilterModel[],
        o: GetCustomRequestMyRequestFilterModel,
      ) {
        let dataElementExistIndex = r.findIndex(
          f => f.filter_header === o.filter_header && f.filter_order === o.filter_order,
        );
        if (dataElementExistIndex > -1) {
          r[dataElementExistIndex].filter_items = [
            ...(r[dataElementExistIndex].filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r];
        } else {
          const filterItems = [
            ...(o.filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r, { ...o, filter_items: filterItems }];
        }
      }, []);
      setFilterData(filter);
      setFilteredData(data.first_dataset_model);
      setIsResultLoading(false);
    }
  }, [data]);

  return (
    <Flex w="100%">
      <Box w={300}>
        {filterData ? <FilterPane filterData={filterData} onChange={onFilterChange} /> : <>Loading...</>}
      </Box>
      <Box flex={1} px={3}>
        <CustomRequestsMyRequestData data={filteredData || []} isLoading={isResultLoading} />
      </Box>
    </Flex>
  );
};

export default CustomRequestsMyRequests;
