import {
    Box,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    VStack,
    Flex,
    ButtonGroup,
    Button,
    Alert,
    AlertIcon,
    Select,
  } from '@chakra-ui/react';
  import { useFormik } from 'formik';
  import * as Yup from 'yup';
  import { AddEmployeeOffboardingRequestModel} from '../../../app/services/dme/api/types';
  import dayjs from 'dayjs';
  import CustomDatePicker from '../../../components/CustomDatePicker';
  import { useState, useEffect, ChangeEvent } from 'react';
import { useGetEoOnboardUserListQuery } from '../../../app/services/dme/api/user';
import { useAddEmployeeOffboardingRequestMutation } from '../../../app/services/dme/api/employeeOnboardingRequest';
 
  const minDate = dayjs().toDate();
  const maxDate = dayjs().add(1, 'year').toDate();
  
  const FormSchema = Yup.object().shape({
    end_date: Yup.string()
      .test((str, { createError }) => {
        if (!str) return true;
        const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
        return date.isValid()
          ? true
          : createError({
              message: 'End Date in invalid',
              path: 'end_date',
            });
      })
      .test((str, { createError }) => {
        if (!str) return true;
        const date = dayjs(str, 'YYYY-MM-DD', true);
        return date.isAfter(minDate)
          ? true
          : createError({
              message: 'End Date must be later than today',
              path: 'end_date',
            });
      })
      .test((str, { createError }) => {
        if (!str) return true;
        const date = dayjs(str, 'YYYY-MM-DD', true);
        return date.isBefore(maxDate)
          ? true
          : createError({
              message: 'End Date may not exceed one year from current date',
              path: 'end_date',
            });
      })
      .label('End Date')
      .required(),
    ref_user_id: Yup.number().label('Employee').required().moreThan(0, 'Employee is a required field'),
  });
  
  const Offboarding = () => {
    const [EndDate, setEndDate] = useState<Date | undefined>();
    const [alertMessage, setAlertMessage] = useState('');
  
    const [postAsync, postDetail] = useAddEmployeeOffboardingRequestMutation();
    const usersDetails = useGetEoOnboardUserListQuery();
  
    const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik<AddEmployeeOffboardingRequestModel>(
      {
        enableReinitialize: true,
        validationSchema: FormSchema,
        initialValues: {
          ref_user_id: 0,
          end_date: '',
        },
        onSubmit: values => {
          postAsync(values);
        },
      },
    );
  
    const clearModal = () => {
      resetForm();
      setAlertMessage('');
      setEndDate(undefined);
    };
  
    useEffect(() => {
      const { isSuccess, isError, isLoading } = postDetail;
  
      if (isSuccess) {
        clearModal();
        setAlertMessage('Request successfully submitted.');
        setTimeout(() => {
          setAlertMessage('');
        }, 3000);
      } else if (isError) {
        setAlertMessage('There was an error processing your request, please try again later.');
      } else {
        setAlertMessage('');
      }
  
      if (isLoading) {
        setAlertMessage('');
      }
    }, [postDetail]);
  
    return (
      <VStack>
        <Heading size="md">Offboarding Request</Heading>
        <Divider />
  
        <form onSubmit={handleSubmit}>
          <VStack spacing="5" pt={5} w="container.sm">
          <FormControl isInvalid={!!errors.ref_user_id && !!touched.ref_user_id} display="flex">
            <FormLabel minW="150px">Employee</FormLabel>
            <Box w="100%">
                <Select
                size="sm"
                id="ref_user_id"
                name="ref_user_id"
                placeholder={usersDetails.isLoading || usersDetails.isFetching ? 'Loading...' : 'Select option'}
                isDisabled={usersDetails.isLoading || usersDetails.isFetching}
                width= '475px'
                value={values.ref_user_id}  // Fix: Provide the actual value directly
                onChange={(e) => {
                    const selectedOption = usersDetails.data?.find(
                    (user) => user.ref_user_id === Number(e.target.value) // Fix: Cast to number to match the `value` type
                    );
                    setValues({
                    ...values,
                    ref_user_id: selectedOption ? selectedOption.ref_user_id : 0,  // Fix: Update the correct value
                    });
                }}
                >
                    {(usersDetails.data ?? []).map(m => (
                        <option key={m.ref_user_id} value={m.ref_user_id}>
                        {m.first_name} {m.last_name}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.ref_user_id}</FormErrorMessage>
            </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.end_date && touched.end_date}>
              <Flex>
                <FormLabel minW="150px" htmlFor="end_date">
                  End Date
                </FormLabel>
                <VStack w="200px">
                  <CustomDatePicker
                    id="end_date"
                    name="end_date"
                    date={EndDate}
                    onDateChange={(date: Date) => {
                      setValues({
                        ...values,
                        end_date: dayjs(date).format('YYYY-MM-DD'),
                      });
                      setEndDate(date);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'brand.main.default',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                        },
                        selectedBtnProps: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.400',
                        },
                      },
                      inputProps: {
                        placeholder: 'YYYY-MM-DD',
                        size: 'sm',
                        value: values.end_date,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            end_date: e.target.value,
                          });
                          if (dayjs(e.target.value)?.isValid()) {
                            setEndDate(dayjs(e.target.value).toDate());
                          }
                        },
                      },
                    }}
                  />
                  <FormErrorMessage>{errors.end_date}</FormErrorMessage>
                </VStack>
              </Flex>
            </FormControl>

            {alertMessage && (
              <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {alertMessage}
              </Alert>
            )}
  
            <ButtonGroup>
              <Button isDisabled={postDetail.isLoading} variant="outline" onClick={() => clearModal()} ml="auto">
                Cancel
              </Button>
              <Button isLoading={postDetail.isLoading} variant="solid" colorScheme="brand.main" type="submit">
                Submit
              </Button>
            </ButtonGroup>
          </VStack>
        </form>
      </VStack>
    );
  };
  
  export default Offboarding;
  