import { Heading, HStack, Link, Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { ImportantLinkDetailsPageFirstDatasetModel } from '../../../../app/services/dme/api/types';

type Props = {
  data: ImportantLinkDetailsPageFirstDatasetModel[];
};

const ImportantLinkSelectedDetails = ({ data }: Props) => {
  const [localData, setLocalData] = useState<ImportantLinkDetailsPageFirstDatasetModel>();
  const location = useLocation();
  useEffect(() => {
    setLocalData(data[0]);
  }, [data]);

  useEffect(() => {
    addBreadcrumb({ label: 'Result', path: '/important-link' }, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Important Link Details</Heading>
      {!data ? (
        <></>
      ) : (
        <HStack>
          <Table size="sm">
            <Tbody
              sx={{
                '& tr > th': {
                  maxW: '50px',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Tr>
                <Th minW={170}>Category</Th>
                <Td>{localData?.link_ref_category_name}</Td>
                <Td>&nbsp;</Td>
                <Th minW={110}>Created</Th>
                <Td>
                  {localData?.row_created_datetime_utc &&
                    convertUtcToLocal(localData?.row_created_datetime_utc, 'MMMM D, YYYY h:mm A')}
                </Td>
              </Tr>
              <Tr>
                <Th>Link Name</Th>
                <Td>{localData?.link_name}</Td>
                <Td>&nbsp;</Td>
                <Th>Last Modified</Th>
                <Td whiteSpace="nowrap">
                  {localData?.row_modified_datetime_utc &&
                    convertUtcToLocal(localData?.row_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}
                </Td>
              </Tr>
              <Tr>
                <Th>Link Description</Th>
                <Td>{localData?.link_description}</Td>
                <Td>&nbsp;</Td>
                <Th>Editor</Th>
                <Td>{localData?.email || '-'}</Td>
              </Tr>
              <Tr>
                <Th>Link URL</Th>
                <Td>
                  <Link color="links" isExternal href={localData?.link_url}>
                    {localData?.link_url}
                  </Link>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </HStack>
      )}
    </VStack>
  );
};

export default ImportantLinkSelectedDetails;
