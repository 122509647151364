import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { RolesAppAccessSelectedUsersModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';
import { useAppAccessByRolesSelectedContext } from './AppAccessByRolesSelectedContext';

const Header = [
  {
    Header: 'Users',
    Cell: ({ row: { original } }: { row: { original: RolesAppAccessSelectedUsersModel } }) => {
      const hasName = !!original.first_name || !!original.last_name;
      return <>{hasName ? original.first_name + ' ' + original.last_name : original.email}</>;
    },
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Business Justification',
    accessor: 'access_request_business_justification',
    styles: { whiteSpace: 'initial' },
  },
  {
    Header: 'Expiration Date',
    Cell: ({ row: { original } }: { row: { original: RolesAppAccessSelectedUsersModel } }) => {
      return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
    },
    styles: { whiteSpace: 'initial', width: '190px' },
  },
  {
    Header: 'Creation Date',
    Cell: ({ row: { original } }: { row: { original: RolesAppAccessSelectedUsersModel } }) => {
      return <>{convertUtcToLocal(original.access_requested_datetime_utc) || '-'}</>;
    },
    styles: { whiteSpace: 'initial', width: '190px' },
  },
];

const AppAccessByRolesSelectedUsers = () => {
  const {
    usersDetail: { data, isLoading, isFetching },
  } = useAppAccessByRolesSelectedContext();

  return isLoading ? (
    <>Loading...</>
  ) : (
    <CustomTable
      variant="table"
      headers={Header}
      isLoading={isLoading}
      isFetching={isFetching}
      data={data || []}
      pageCount={0}
      pageSize={data ? data.length : 0}
      totalRecords={data ? data.length : 0}
      search=""
      onPageChange={index => {}}
      onPageSizeChange={size => {}}
      onPageSearch={search => {}}
      onSort={() => {}}
      hidePagination={true}
      tableSort={true}
      showNoRecords
      // onRowClick={onClickRow}
    />
  );
};

export default AppAccessByRolesSelectedUsers;
