import { Flex, HStack, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { FaArrowCircleRight, FaCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useGetGoEdsRequestSelectedStatusQuery } from '../../../app/services/dme/api/governanceOfficeEds';

type Props = {};

const GovernanceOfficeSelectedStatus = (props: Props) => {
  const params = useParams();

  const goEdsId: number = parseInt(params?.goEdsId || '');
  const { data, isLoading, isFetching } = useGetGoEdsRequestSelectedStatusQuery({ goEdsId }, { skip: goEdsId === 0 });

  if (isLoading || isFetching) return <>Loading...</>;
  if (!data) return <></>;

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">Status:</Heading>
        <Heading size="sm" fontWeight="normal">
          {data && data?.length > 0 && data[0].request_status_name}
        </Heading>
      </HStack>

      <VStack spacing={4}>
        {data &&
          data.map((m, i) => (
            <Flex direction="row" alignItems="center" key={i}>
              <Icon
                as={i === data.length - 1 ? FaArrowCircleRight : FaCircle}
                color={'brandColors.primary.green.300'}
              />
              <Text ml={3} w="28">
                {m.request_status_name}
              </Text>
              <Text ml={1}>:</Text>
              <Text ml={2}>{convertUtcToLocal(m.status_created_datetime_utc)}</Text>
            </Flex>
          ))}
      </VStack>
    </VStack>
  );
};

export default GovernanceOfficeSelectedStatus;
