import { SearchIcon } from '@chakra-ui/icons';
import {
  Alert,
  Badge,
  Box,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  chakra,
  theme,
} from '@chakra-ui/react';
import { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { CgChevronDoubleLeft, CgChevronDoubleRight, CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Column, RowPropGetter, TableState, usePagination, useSortBy, useTable } from 'react-table';
import { debounce } from '../../app/helpers/utilities';
import { SortType } from '../../app/services/types';
import { appColors } from '../../app/theme';
import EditableCell from './EditableCell';

export type IProps<T> = {
  data: T[];
  pageIndex?: number;
  pageCount: number;
  pageSize: number;
  totalRecords?: number;
  isLoading: boolean;
  isFetching: boolean;
  headers: Column<any>[];
  hiddenColumns?: string[];
  tableSort?: boolean;
  search: string;
  hidePagination?: boolean;
  showSearch?: boolean;
  hideHeaders?: boolean;
  showRecordCount?: boolean;
  manual?: boolean;
  manualSortBy?: boolean;
  disableSortRemove?: boolean;
  initialState?: Partial<TableState<object>> | undefined;
  rowDisabledOnTrue?: (row: T) => boolean;
  rowActiveOnTrue?: (row: T) => boolean;
  activeRow?: { property: string; value: any };
  variant: 'table' | 'infinite-scroll-table' | 'grid';
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange?: (size: number) => void;
  onPageSearchDebounce?: number;
  onPageSearch?: (search: string) => void;
  onSort: (sort: SortType<T>[]) => void;
  getRowProps?: (row: any) => RowPropGetter<object> | undefined;
  onRowClick?: (row: T) => void;
  showNoRecords?: boolean;
  updateData?: (
    rowIndex: number,
    columnId: string,
    value: string | File | number | number[],
    fileColumnName?: string,
  ) => void;
  styles?: {
    tableContainer?: TableContainerProps;
    pagination?: FlexProps;
    header?: {
      justifyContent: string;
    };
    searchInput?: {
      w: string;
    };
  };
  additionalComponents?: {
    footerEnd?: ReactNode;
  };
  infiniteScrollProps?: {
    props: Omit<InfiniteScroll['props'], 'children'>;
    parentProps: React.HTMLAttributes<HTMLDivElement>;
  };
};

function CustomTable<T>(props: IProps<T>) {
  const columns = useMemo<Column<any>[]>(() => props.headers, [props.headers]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  // const [search, setSearch] = useState("");
  const searchRef = useRef('');

  const [isTableLoading, setIsTableLoading] = useState(true);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageIndex: 0,
        pageSize: 18,
        hiddenColumns: props.hiddenColumns || [],
        ...props.initialState,
      },
      pageCount: tablePageCount,
      manualPagination: true,
      autoResetPage: false, //prevent re-executing hook with initialState again
      manualSortBy: props.manualSortBy,
      disableSortRemove: props.disableSortRemove,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination,
  );

  const keyUpHandler = (event: any) => {
    event.preventDefault();
    gotoPage(0);
    // props.onPageSearch && props.onPageSearch(search);
    props.onPageSearch && props.onPageSearch(searchRef.current);
  };

  const onRowClick = (row: any) => {
    if (props.onRowClick) {
      props.onRowClick(row.original);
    }
  };

  useEffect(() => {
    // setSearch(props.search);
    searchRef.current = props.search;
  }, [props.search]);

  useEffect(() => {
    setTableData(props.data);
    setTablePageCount(props.pageCount);
  }, [props]);

  useEffect(() => {
    if (!props.manual) {
      const index = pageIndex === 0 ? 1 : pageIndex + 1;
      props.onPageChange(index);
    }
  }, [pageIndex, pageSize, sortBy]);

  useEffect(() => {
    props.onSort(sortBy as SortType<T>[]);
  }, [sortBy]);

  useEffect(() => {
    setIsTableLoading(props.isLoading || props.isFetching);
  }, [props.isLoading, props.isFetching]);

  useEffect(() => {
    if (props.manual) {
      if (props.pageIndex !== null && props.pageIndex !== undefined) {
        gotoPage(props.pageIndex);
      }
    }
  }, [props.pageIndex]);

  const tableView = (
    <TableContainer {...props.styles?.tableContainer}>
      {isTableLoading && page.length <= 0 ? (
        <Loading pageSize={props.pageSize} />
      ) : (
        <>
          <Table variant="simple" size="sm" mt={1} {...getTableProps()}>
            {!props.hideHeaders && (
              <Thead>
                {headerGroups.map(headerGroup => {
                  return (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => {
                        const columnStyle = (column as any).styles;
                        const isSortable = (column as any).isSortable ?? true;
                        const tableSort = props.tableSort ?? true;
                        const applySortIcon = column.isSortedDesc ? (
                          <Icon as={GoTriangleDown} aria-label="sorted descending" />
                        ) : (
                          <Icon as={GoTriangleUp} aria-label="sorted ascending" />
                        );
                        return (
                          <Th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({
                                ...(isSortable ? {} : { onClick: () => {} }),
                              }),
                            )}
                            style={{
                              ...columnStyle,
                              whiteSpace: tableSort ? 'nowrap' : 'initial',
                            }}
                          >
                            {column.render('Header')}
                            {tableSort && isSortable && (
                              <chakra.span pl="3">{column.isSorted ? applySortIcon : null}</chakra.span>
                            )}
                          </Th>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Thead>
            )}
            <Tbody {...getTableBodyProps()}>
              {isTableLoading && page.length > 0 ? (
                <Tr>
                  <Td colSpan={columns.length + 1}>
                    <Loading pageSize={props.pageSize} />
                  </Td>
                </Tr>
              ) : (
                page.map(row => {
                  prepareRow(row);

                  const rowProps = {
                    ...row.getRowProps(props.getRowProps ? props.getRowProps(row) : undefined),
                  };

                  const rowStyle = rowProps.style || {};
                  if (props.rowDisabledOnTrue && props.rowDisabledOnTrue(row.original as unknown as T)) {
                    rowStyle.color = appColors.inactive;
                  }

                  if (props.rowActiveOnTrue && props.rowActiveOnTrue(row.original as unknown as T)) {
                    rowStyle.backgroundColor = appColors.activeRow;
                  }

                  rowProps.style = rowStyle;

                  return (
                    <Tr
                      {...rowProps}
                      _hover={{
                        background: 'gray.200',
                      }}
                      onClick={() => onRowClick(row as unknown as T)}
                      style={{ cursor: props.onRowClick ? 'pointer' : 'initial' }}
                    >
                      {row.cells.map(cell => {
                        const cellColumn = cell.column as any;
                        const columnStyle = cellColumn.styles;
                        const isEditable = cellColumn.isEditable;
                        const type = cellColumn.type as React.HTMLInputTypeAttribute;
                        const dropdownOptions = cellColumn.dropdownOptions;
                        return (
                          <Td
                            verticalAlign={isEditable ? 'top' : 'inherit'}
                            {...cell.getCellProps()}
                            style={columnStyle}
                          >
                            {isEditable && props.updateData ? (
                              <EditableCell
                                value={cell.value}
                                row={row}
                                column={cell.column}
                                updateData={props.updateData}
                                type={type}
                                dropdownOptions={dropdownOptions}
                                fileColumnName={cellColumn.fileColumnName}
                              />
                            ) : (
                              cell.render('Cell')
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
          {props.showNoRecords && page.length == 0 && (
            <Alert w="full" status="error">
              No Record(s) found
            </Alert>
          )}
        </>
      )}
    </TableContainer>
  );

  const infiniteScrollTableView = props.infiniteScrollProps && (
    <div
      id="scrollableDiv"
      style={{
        height: '500px',
        overflow: 'auto',
      }}
      {...props.infiniteScrollProps.parentProps}
    >
      <InfiniteScroll {...props.infiniteScrollProps.props}>
        <div id="infinite-scroll-table-head" />
        <TableContainer {...props.styles?.tableContainer}>
          {isTableLoading && page.length <= 0 ? (
            <Loading pageSize={props.pageSize} />
          ) : (
            <>
              <Table variant="simple" size="sm" mt={1} {...getTableProps()}>
                {!props.hideHeaders && (
                  <Thead>
                    {headerGroups.map(headerGroup => {
                      return (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => {
                            const columnStyle = (column as any).styles;
                            const isSortable = (column as any).isSortable ?? true;
                            const tableSort = props.tableSort ?? true;
                            const applySortIcon = column.isSortedDesc ? (
                              <Icon as={GoTriangleDown} aria-label="sorted descending" />
                            ) : (
                              <Icon as={GoTriangleUp} aria-label="sorted ascending" />
                            );
                            return (
                              <Th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    ...(isSortable ? {} : { onClick: () => {} }),
                                  }),
                                )}
                                style={{
                                  ...columnStyle,
                                  whiteSpace: tableSort ? 'nowrap' : 'initial',
                                }}
                              >
                                {column.render('Header')}
                                {tableSort && isSortable && (
                                  <chakra.span pl="3">{column.isSorted ? applySortIcon : null}</chakra.span>
                                )}
                              </Th>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Thead>
                )}
                <Tbody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);

                    const rowProps = {
                      ...row.getRowProps(props.getRowProps ? props.getRowProps(row) : undefined),
                    };

                    const rowStyle = rowProps.style || {};
                    if (props.rowDisabledOnTrue && props.rowDisabledOnTrue(row.original as unknown as T)) {
                      rowStyle.color = appColors.inactive;
                    }

                    if (props.rowActiveOnTrue && props.rowActiveOnTrue(row.original as unknown as T)) {
                      rowStyle.backgroundColor = appColors.activeRow;
                    }

                    rowProps.style = rowStyle;

                    return (
                      <Tr
                        {...rowProps}
                        _hover={{
                          background: 'gray.200',
                        }}
                        onClick={() => onRowClick(row as unknown as T)}
                        style={{ cursor: props.onRowClick ? 'pointer' : 'initial' }}
                      >
                        {row.cells.map(cell => {
                          const cellColumn = cell.column as any;
                          const columnStyle = cellColumn.styles;
                          const isEditable = cellColumn.isEditable;
                          const type = cellColumn.type as React.HTMLInputTypeAttribute;
                          const dropdownOptions = cellColumn.dropdownOptions;
                          return (
                            <Td
                              verticalAlign={isEditable ? 'top' : 'inherit'}
                              {...cell.getCellProps()}
                              style={columnStyle}
                            >
                              {isEditable && props.updateData ? (
                                <EditableCell
                                  value={cell.value}
                                  row={row}
                                  column={cell.column}
                                  updateData={props.updateData}
                                  type={type}
                                  dropdownOptions={dropdownOptions}
                                  fileColumnName={cellColumn.fileColumnName}
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              {props.showNoRecords && page.length == 0 && (
                <Alert w="full" status="error">
                  No Record(s) found
                </Alert>
              )}
            </>
          )}
        </TableContainer>
      </InfiniteScroll>
    </div>
  );

  const gridView = (
    <Stack>
      {props.isLoading || props.isFetching ? (
        <Stack mt={1}>
          {[...Array(props.pageSize)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      ) : (
        <>
          <Box>
            <List spacing={2}>
              {page.map((row, index) => {
                prepareRow(row);

                const rowProps = {
                  ...row.getRowProps(props.getRowProps ? props.getRowProps(row) : undefined),
                };

                const rowStyle = rowProps.style || {};
                if (props.rowDisabledOnTrue && props.rowDisabledOnTrue(row.original as unknown as T)) {
                  rowStyle.borderLeftColor = appColors.inactive;
                }

                if (props.rowActiveOnTrue && props.rowActiveOnTrue(row.original as unknown as T)) {
                  rowStyle.boxShadow = theme.shadows.md;
                  rowStyle.background = appColors.activeRow;
                }

                rowProps.style = rowStyle;

                return (
                  <ListItem key={index}>
                    <Stack
                      p={2}
                      width="100%"
                      border="1px"
                      borderLeft={'8px'}
                      borderColor="gray.200"
                      borderLeftColor="activeRowIndicator.border"
                      {...rowProps}
                    >
                      <HStack alignItems="start">
                        <Stack flexGrow={1} wordBreak="break-all">
                          {row.cells.map((cell, i) => {
                            const isAction = (cell.column as any).isAction;
                            return (
                              !isAction && (
                                <Flex key={i}>
                                  <Text as="strong" pr={2} whiteSpace="nowrap">
                                    {cell.column.render('Header')}:
                                  </Text>
                                  <Text borderRightColor="gray.200">{cell.render('Cell')}</Text>
                                </Flex>
                              )
                            );
                          })}
                        </Stack>
                        <Stack>
                          {row.cells.map((cell, ii) => {
                            const isAction = (cell.column as any).isAction;
                            return isAction && <Fragment key={ii}>{cell.render('Cell')}</Fragment>;
                          })}
                        </Stack>
                      </HStack>
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </Stack>
  );

  const renderTableOrGrid = (variant: IProps<T>['variant']) => {
    switch (variant) {
      case 'table': {
        return tableView;
      }
      case 'infinite-scroll-table': {
        return infiniteScrollTableView;
      }
      case 'grid': {
        return gridView;
      }
      default:
        return tableView;
    }
  };

  return (
    <VStack align="stretch" w="100%">
      {((!props.hidePagination && !props.hideHeaders) || props.showSearch) && (
        <Flex justifyContent="space-between" mb={2} p={0}>
          <HStack w="100%" justifyContent={props.styles?.header?.justifyContent ?? 'space-between'}>
            {props.showRecordCount ? (
              <Badge alignItems="center" display="flex" px="2" py="1">
                {props.totalRecords || 0} record
                {(props.totalRecords || 0) > 1 && <>s</>}
              </Badge>
            ) : (
              <Box></Box>
            )}
            {props.onPageSearch && (
              <InputGroup justifyContent="flex-end" w={props.styles?.searchInput?.w ?? '320px'}>
                <Input
                  id="search"
                  placeholder="search"
                  name="search"
                  // value={search}
                  // onChange={(e) => setSearch(e.target.value)}
                  // onKeyUp={keyUpHandler}
                  defaultValue={searchRef.current}
                  onChange={e => (searchRef.current = e.target.value)}
                  onKeyUp={
                    props.onPageSearchDebounce ? debounce(keyUpHandler, props.onPageSearchDebounce) : keyUpHandler
                  }
                />
                <InputRightElement children={<SearchIcon className="SearchIcon" color="gray.300" />} />
              </InputGroup>
            )}
          </HStack>
        </Flex>
      )}

      {renderTableOrGrid(props.variant)}

      <Stack>
        {props.variant === 'infinite-scroll-table' || (!props.isLoading && page.length === 0) ? (
          <></>
        ) : (
          <Flex justifyContent={'end'} alignItems="center" mt={4} {...props.styles?.pagination}>
            <HStack hidden={props.hidePagination}>
              <IconButton
                aria-label="first page"
                onClick={() => {
                  if (props.manual) props.onPageChange(0);
                  else gotoPage(0);
                }}
                isDisabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleLeft />}
              />
              <IconButton
                aria-label="previous page"
                onClick={() => {
                  if (props.manual) props.onPageChange(pageIndex - 1);
                  else previousPage();
                }}
                isDisabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronLeft />}
              />
              <Text>{props.pageCount ? `${pageIndex + 1} of ${props.pageCount}` : pageIndex + 1}</Text>
              <IconButton
                aria-label="next page"
                onClick={() => {
                  if (props.manual) props.onPageChange(pageIndex + 1);
                  else nextPage();
                }}
                isDisabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronRight />}
              />
              <IconButton
                aria-label="last page"
                onClick={() => {
                  if (props.manual) props.onPageChange(props.pageCount - 1);
                  else gotoPage(props.pageCount - 1);
                }}
                isDisabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleRight />}
              />
            </HStack>

            {props.additionalComponents?.footerEnd && props.additionalComponents?.footerEnd}
          </Flex>
        )}
      </Stack>
    </VStack>
  );
}

function Loading({ pageSize }: { pageSize: number }) {
  return (
    <Stack mt={1} w="full">
      {[...Array(pageSize)].map((m, i) => (
        <Skeleton key={i} height="18px" />
      ))}
    </Stack>
  );
}

export default CustomTable;
