import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WindowSize } from '../types/appType';
export type AppState = {
  isShowMobileTab: boolean;
  windowSize: WindowSize;
  visitedURLs: string[];
  isSideNavOpen: boolean;
};

const initialState: AppState = {
  isShowMobileTab: false,
  windowSize: { width: 0, height: 0 },
  visitedURLs: [],
  isSideNavOpen: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsShowMobileTab: (state, action: PayloadAction<boolean>) => {
      state.isShowMobileTab = action.payload;
    },
    setWindowSize: (state, action: PayloadAction<WindowSize>) => {
      state.windowSize = action.payload;
    },
    addVisitedUrl: (state, action: PayloadAction<string>) => {
      if (state.visitedURLs[state.visitedURLs.length - 1] === action.payload) {
        return;
      }
      state.visitedURLs.push(action.payload);
    },
    clearVisitedUrl: state => {
      state.visitedURLs = [];
    },
    setIsSideNavOpen: (state, action: PayloadAction<boolean>) => {
      state.isSideNavOpen = action.payload;
    },
  },
});

export const { addVisitedUrl, clearVisitedUrl, setIsShowMobileTab, setWindowSize, setIsSideNavOpen } = appSlice.actions;
export default appSlice.reducer;
