import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { LoreLanguageDetailsPageTagModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

type Props = {
  data: LoreLanguageDetailsPageTagModel[];
};

const LoreLanguageSelectedTags = ({ data }: Props) => {
  const location = useLocation();

  useEffect(() => {
    addBreadcrumb({ label: 'Result', path: '/glossary' }, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  const Header = [
    {
      Header: 'Name',
      accessor: 'tag_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'User Context',
      accessor: 'tag_desc',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Last Modified',
      Cell: ({ row: { original } }: { row: { original: LoreLanguageDetailsPageTagModel } }) => {
        return <>{convertUtcToLocal(original.tag_modified_datetime_utc, 'MMMM D, YYYY h:mm A')}</>;
      },
    },
  ];

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Tag</Heading>
      {displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={false}
          isFetching={false}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          showNoRecords
        />
      </HStack>
    );
  }
};

export default LoreLanguageSelectedTags;
