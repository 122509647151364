import { Box, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetMyRoleDetailsByIdQuery } from '../../../../app/services/dme/api/myRoles';
import MyRolesDetailTab from './Details';
import MyRoleDetailEnvTab from './Environment';
import MyRoleDetailStatusTab from './Status';

type Props = {};
const tabs: {
  label: string;
  path: string;
  dataProperty: string;
  component: ({ data }: any) => JSX.Element;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: MyRolesDetailTab,
    dataProperty: 'details_tab',
  },
  {
    label: 'Status',
    path: 'status',
    component: MyRoleDetailStatusTab,
    dataProperty: 'status_tab',
  },
  {
    label: 'Environment',
    path: 'tag',
    component: MyRoleDetailEnvTab,
    dataProperty: 'environment_tab',
  },
];
const MyRoleSelectedDetail = (props: Props) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const id: number = parseInt(params?.id || '');
  const { data, isLoading, isFetching } = useGetMyRoleDetailsByIdQuery(id, {
    skip: !id,
  });

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      tabIndex !== undefined && navigate('./../' + tabs[tabIndex].path);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  useEffect(() => {
    addBreadcrumb({ label: 'Result', path: '/roles/my-roles' }, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  return (
    <VStack w="100%">
      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>
              {data && !isLoading && !isFetching ? (
                tabs[tabIndex] &&
                createElement(tabs[tabIndex].component, {
                  data: data[tabs[tabIndex].dataProperty as keyof typeof data],
                })
              ) : (
                <>Loading...</>
              )}
            </Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default MyRoleSelectedDetail;
