import { Button } from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { MdOutlineThumbUpAlt, MdThumbUpAlt } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { CustomRequestDetailsPageDiscussionModel } from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';
import {
  useLazyGetCustomRequestPostByIdQuery,
  usePostCustomRequestPostToggleReactionMutation,
} from '../../../../app/services/dme/api/customRequestPost';
import { channelNameCrMutation } from '.';
import PubNubConstants from '../../../PubNubWrapper/constants';

type Props = {
  post: CustomRequestDetailsPageDiscussionModel;
};

const CustomRequestPostReactButton = ({ post }: Props) => {
  const params = useParams();
  const pubnub = usePubNub();
  const { logonUser } = useAppSelector(s => s.user);

  const [getAsync, getDetail] = useLazyGetCustomRequestPostByIdQuery();
  const [reactAsync, reactDetail] = usePostCustomRequestPostToggleReactionMutation();

  const toggleReaction = (cr_post_id: number) => async () => {
    if (logonUser) {
      const customRequestId: number = parseInt(params.customRequestId || '0');

      await reactAsync({
        cr_post_id,
        reaction_user_id: logonUser.ref_user_id,
        ref_reaction_id: 1,
      });

      const data = await getAsync(post.cr_post_id).unwrap();
      await pubnub.publish({
        channel: channelNameCrMutation,
        message: {
          customRequestId,
          data: data[0],
          type: PubNubConstants.MessageEvent.Type.UPDATE,
        },
      });
    }
  };

  return (
    <Button
      leftIcon={
        post.reactor_user_id_list &&
        post.reactor_user_id_list.split(',').some(s => s === `${logonUser?.ref_user_id}`) ? (
          <MdThumbUpAlt />
        ) : (
          <MdOutlineThumbUpAlt />
        )
      }
      variant="link"
      size="sm"
      onClick={toggleReaction(post.cr_post_id)}
      isLoading={reactDetail.isLoading || getDetail.isLoading}
      isDisabled={reactDetail.isLoading || getDetail.isLoading}
      _hover={{ textDecoration: 'none' }}
      sx={{ span: { marginRight: 1 } }}
    >
      {post.cnt_post_reactions}
    </Button>
  );
};

export default CustomRequestPostReactButton;
