import { dmeApi } from './index';
import {
  MyRolesExploreRolesModel,
  PostDmeRoleRequestuserRoleModel,
  MyRoleModel,
  MyRoleDetailModel,
  PutDmeRoleRequestuserRoleModel,
  MyRolesExploreRolesDetailModel,
  MyRolesExploreRolesDataDto,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getMyRolesByRefUserId: builder.query<MyRoleModel, number>({
      query: ref_user_id => `/myroles/${ref_user_id}`,
      providesTags: ['MyRoles'],
    }),

    getMyRoleDetailsById: builder.query<MyRoleDetailModel, number>({
      query: id => `/myroles/details/${id}`,
    }),

    getMyRolesExploreRoles: builder.query<MyRolesExploreRolesModel, MyRolesExploreRolesDataDto>({
      query: params => ({
        url: `/myroles/exploreroles`,
        params,
      }),
    }),

    getMyRolesExploreRolesDetail: builder.query<MyRolesExploreRolesDetailModel, number>({
      query: ref_role_id => `/myroles/exploreroles/detail/${ref_role_id}`,
    }),

    postMyRolesRequestAccess: builder.mutation<void, PostDmeRoleRequestuserRoleModel>({
      query: params => ({
        url: `/myroles/exploreroles/requestaccess`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['MyRoles'],
    }),

    putMyRolesRequestAccess: builder.mutation<void, PutDmeRoleRequestuserRoleModel>({
      query: params => ({
        url: '/myroles/exploreroles/requestaccess',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['MyRoles', 'AccessAdminApproval'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetMyRolesByRefUserIdQuery,
  useGetMyRoleDetailsByIdQuery,
  useGetMyRolesExploreRolesQuery,
  useGetMyRolesExploreRolesDetailQuery,
  useLazyGetMyRolesExploreRolesQuery,

  usePostMyRolesRequestAccessMutation,
  usePutMyRolesRequestAccessMutation,
} = extendedApi;
