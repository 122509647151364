import { Badge, Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { getName } from '../../../../../app/helpers/stringHelper';
import {
  useGetRhAllReportStatesQuery,
  useGetRhPortfolioSelectedDetailsByRhpidQuery,
  useGetRhPortfolioSelectedReportsByRhpidQuery,
} from '../../../../../app/services/dme/api/reportHub';
import { ReportHubSelectedReportsTabModel } from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import { CustomTableHeader } from '../../../../../app/types/appType';
import CustomTable from '../../../../../components/CustomTable';

type Props = {};
type ReportHubSelectedReportsTabModelExtended = ReportHubSelectedReportsTabModel & {
  ref_tags: { ref_tag_id: number; tag_name: string }[];
};

const ReportHubExplorePortfoliosSelectedViewReports: FC<Props> = props => {
  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const rhpId: number = parseInt(params?.rhpId || '0') || 0;
  const statesDetails = useGetRhAllReportStatesQuery();
  const rhPortfolio = useGetRhPortfolioSelectedDetailsByRhpidQuery(
    { userId, rhpId },
    {
      skip: userId === 0 || rhpId === 0,
    },
  );
  const { data, isLoading, isFetching } = useGetRhPortfolioSelectedReportsByRhpidQuery(
    { userId, rhpId },
    {
      skip: rhpId === 0,
    },
  );

  const Header: CustomTableHeader<ReportHubSelectedReportsTabModelExtended> = [
    {
      Header: 'Name',
      accessor: 'report_name',
      styles: { whiteSpace: 'initial', minWidth: '200px', maxWidth: '400px', verticalAlign: 'top' },
    },
    {
      Header: 'Description',
      accessor: 'report_desc',
      styles: { whiteSpace: 'initial', minWidth: '200px', maxWidth: '400px', verticalAlign: 'top' },
    },
    {
      Header: 'Link',
      accessor: 'report_embed_link',
      styles: { verticalAlign: 'top' },
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedReportsTabModelExtended>) => {
        return rhPortfolio.data?.has_access_flag ? (
          <Link as={RouterLink} color="links" to={original.report_embed_link} target="_blank" rel="noopener noreferrer">
            View Report
          </Link>
        ) : (
          <Text cursor="not-allowed" color="blackAlpha.500">
            View Report
          </Text>
        );
      },
    },
    {
      Header: 'State',
      styles: { verticalAlign: 'top' },
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedReportsTabModelExtended>) => {
        const state = statesDetails.data?.find(s => s.rh_report_state_id === original.rh_report_state_id);
        return (
          <Box>
            <Text
              noOfLines={1}
              title={state?.rh_report_state_name}
              color={state?.rh_report_state_color ?? 'white'}
              fontWeight="bold"
            >
              {state?.rh_report_state_name}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Developer Name',
      styles: { verticalAlign: 'top' },
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedReportsTabModelExtended>) => {
        const name = getName({
          first_name: original.report_developer_fname,
          last_name: original.report_developer_lname,
        });
        return (
          <Box>
            <Text title={name}>{name || '-'}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'Tags',
      styles: { verticalAlign: 'top' },
      Cell: ({ row: { original } }: CellProps<ReportHubSelectedReportsTabModelExtended>) => {
        return (
          <HStack flexWrap="wrap" gap="2">
            {original.ref_tags.map(t => (
              <Badge margin="0" marginInlineStart="0 !important" key={t.ref_tag_id} textTransform="initial">
                {t.tag_name}
              </Badge>
            ))}
          </HStack>
        );
      },
    },
  ];

  const displayedData = (data ?? []).reduce((arr, currVal) => {
    const index = arr.findIndex(s => s.rh_report_id === currVal.rh_report_id);
    if (index > -1)
      arr[index].ref_tags.push({
        ref_tag_id: currVal.ref_tag_id,
        tag_name: currVal.report_tag,
      });
    else
      arr.push({
        ...currVal,
        ref_tags: [
          {
            ref_tag_id: currVal.ref_tag_id,
            tag_name: currVal.report_tag,
          },
        ],
      });
    return arr;
  }, [] as ReportHubSelectedReportsTabModelExtended[]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      {isLoading || isFetching || rhPortfolio.isLoading || rhPortfolio.isFetching ? (
        <>Loading...</>
      ) : (
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={false}
          isFetching={false}
          data={displayedData || []}
          pageCount={0}
          pageSize={displayedData ? displayedData.length : 0}
          totalRecords={displayedData ? displayedData.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          showNoRecords
        />
      )}
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedViewReports;
