import { useMemo, useState, useEffect } from 'react';
import { AppSize } from '../../../app/constants';
import { AppAccess } from '../../../app/constants/appAccesses';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import { usePostPlatformServicesExploreListMutation, useGetPlatformServicesRefPlatformPortfolioListQuery, useGetPlatformServicesRefPlatformServicesCategoryListQuery } from '../../../app/services/dme/api/platformServices';
import { PlatformServicesExploreFilterModel, PlatformServicesExploreModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader, DynamicObject } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import ExplorePlatformServicesTableActionCell from './ActionCell';
import { platformServicesSearchParams, ExplorePlatformServicesHeader, ExplorePlatformServicesInitialSortBy } from './utils';
import { Box, Divider, HStack, Skeleton, Stack, VStack } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import ExplorePlatformServicesActionCell from './ActionCell';

const filterOrder = {
  portfolio: 1,
  category: 2,
} as const;

const initialFilterParams: PlatformServicesExploreFilterModel = {
  platformservicesportfoliofilter: [
    {
      filter_order: 1,
      filter_id: 1,
    },
  ],
  search_string: '',
  sort_column: 'ps_portfolio_name',
  sort_order: 'asc',
};

const ExplorePlatformServicesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(ExplorePlatformServicesInitialSortBy);

  const portfolioParams = searchParams.get(platformServicesSearchParams.portfolio);
  const categoryParams = searchParams.get(platformServicesSearchParams.category);
  const sortByParams = searchParams.get(platformServicesSearchParams.sortBy) as keyof PlatformServicesExploreModel | null;
  const sortOrderParams = searchParams.get(platformServicesSearchParams.sortOrder);

  const userHasWriteAccess = useIsUserHasRequiredAppAccess(AppAccess.ExplorePlatformServicesWrite);
  const { isSideNavOpen } = useAppSelector(s => s.app);
  const portfolioList = useGetPlatformServicesRefPlatformPortfolioListQuery();
  const categoryList = useGetPlatformServicesRefPlatformServicesCategoryListQuery();
  const filterLoading = portfolioList.isLoading || portfolioList.isFetching || categoryList.isLoading || categoryList.isFetching;

  const [params, setParams] = useState<PlatformServicesExploreFilterModel>(initialFilterParams);
  const [postPlatformServicesExploreList, { isLoading, error, data }] = usePostPlatformServicesExploreListMutation();


  const handleFetch = async () => {
    try {
      await postPlatformServicesExploreList(params).unwrap();
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const filters = useMemo(() => {
    const list: { filter_order: number; filter_id: number }[] = [];

    if (portfolioParams) {
      const portfolioIds = portfolioParams.split(',');
      portfolioIds.forEach(ps_ref_platform_portfolio_id => {
        const s = portfolioList.data?.find(
          s => s.ps_ref_platform_portfolio_id.toString() === ps_ref_platform_portfolio_id,
        );
        if (s) list.push({ filter_order: filterOrder.portfolio, filter_id: s.ps_ref_platform_portfolio_id });
      });
    }

    if (categoryParams) {
      const categoryIds = categoryParams.split(',');
      categoryIds.forEach(ps_ref_category_id => {
        const s = categoryList.data?.find(
          s => s.ps_ref_category_id.toString() === ps_ref_category_id,
        );
        if (s) list.push({ filter_order: filterOrder.category, filter_id: s.ps_ref_category_id });
      });
    }

    return list;
  }, [portfolioParams, categoryParams, portfolioList.data, categoryList.data]);

  const updateParams = (
    filters: { filter_order: number; filter_id: number }[],
    search_string: string,
    sort_column: keyof PlatformServicesExploreModel,
    sort_order: 'asc' | 'desc'
  ): PlatformServicesExploreFilterModel => {
    return {
      platformservicesportfoliofilter: filters,
      search_string: search_string,
      sort_column: sort_column,
      sort_order: sort_order,
    };
  };

  useEffect(() => {
    const newParams = updateParams(
      filters,
      searchParams.get('search') || '',
      sortByParams || 'ps_portfolio_name',
      sortOrderParams as 'asc' | 'desc' || 'asc'
    );
    setParams(newParams);
  }, [filters, searchParams, sortByParams, sortOrderParams]);

  useEffect(() => {
    handleFetch();
  }, [params]);


  const displayedHeaders = useMemo<CustomTableHeader<PlatformServicesExploreModel>>(() => {
    return [
      ...ExplorePlatformServicesHeader,
      ...(userHasWriteAccess
        ? [
            {
              Header: 'Action',
              styles: { verticalAlign: 'top' },
              Cell: ExplorePlatformServicesActionCell,
            },
          ]
        : []),
    ];
  }, [userHasWriteAccess]);

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  return filterLoading ? (
    <></>
  ) : (
    <div>
      <CustomTable
        variant="table"
        isLoading={isLoading}
        isFetching={false}
        data ={data ?? []}
        pageCount={0}
        pageSize={pageSize}
        totalRecords={0}
        pageIndex={pageNumber - 1}
        headers={displayedHeaders}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [ExplorePlatformServicesInitialSortBy] }}
        manualSortBy
        disableSortRemove
        hidePagination
        showSearch
        showNoRecords
        onPageChange={index => {
          setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          setPageNumber(1);
          setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          setPageNumber(1);

          const params: DynamicObject = {};
          searchParams.forEach((val, key) => (params[key] = val));
          setSearchParams({ ...params, search: search }, { replace: true });
        }}
        onSort={sort => {
          if (sort[0]) {
            setPageNumber(1);
            setSortBy(sort[0]);
          }
        }}
        manual={true}
        styles={{
          pagination: { justifyContent: 'start' },
          header: { justifyContent: 'left' },
          searchInput: { w: '100%' },
          tableContainer: {
            sx: {
              maxW: `calc(100vw - 5.75rem - 238px - ${sideNavWidth})`,
              overflow: 'auto',
              table: {
                borderCollapse: 'separate',
                borderSpacing: '0',
              },
            },
          },
        }}
      />
    </div>
  );
}

export default ExplorePlatformServicesTable;
 