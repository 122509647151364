import {
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
  Alert,
  AlertIcon,
  List,
  ListItem,
  Box,
  Icon,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { chakraComponents, Select } from 'chakra-react-select';
import { usePostMyRolesRequestAccessMutation } from '../../../app/services/dme/api/myRoles';

import dayjs from 'dayjs';
import RequestQuestionItem from './RequestQuestionItem';
import { MyRolesExploreRolesDataModel, PostDmeRoleRequestuserRoleModel } from '../../../app/services/dme/api/types';
import { FaUserCog } from 'react-icons/fa';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { useAppSelector } from '../../../app/state/hooks';

const minDate = dayjs().toDate();
const maxDate = dayjs().add(1, 'year').toDate();
const defaultDate = dayjs().add(30, 'day').toDate();

const FormSchema = Yup.object().shape({
  ref_role_id: Yup.string().label('Role').required(),
  access_expiration_datetime_utc: Yup.string()
    .test((str, { createError }) => {
      const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
      return date.isValid()
        ? true
        : createError({
            message: 'Expiration Date in invalid',
            path: 'access_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isAfter(minDate)
        ? true
        : createError({
            message: 'Expiration Date must be later than today',
            path: 'access_expiration_datetime_utc',
          });
    })
    .test((str, { createError }) => {
      const date = dayjs(str, 'YYYY-MM-DD', true);
      return date.isBefore(maxDate)
        ? true
        : createError({
            message: 'Expiration Date may not exceed one year from current date',
            path: 'access_expiration_datetime_utc',
          });
    })
    .label('Expiration Date')
    .required(),
  access_request_business_justification: Yup.string()
    .label('Business Justification')
    .required()
    .max(1500, 'Text exceed the character limit of 1500'),
});

type Props = {
  ref_role_list: MyRolesExploreRolesDataModel[];
  ref_role_id?: string | undefined;
  role_name?: string | undefined;
  access_expiration_datetime_utc?: string | undefined;
  access_request_business_justification?: string | undefined;
  triggerElement: JSX.Element;
};

const RequestAccessDialog = ({
  ref_role_list,
  ref_role_id,
  access_expiration_datetime_utc,
  access_request_business_justification,
  triggerElement,
}: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [expirationDate, setExpirationDate] = useState<Date>(defaultDate);
  const { logonUser } = useAppSelector(s => s.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postAsync, postDetail] = usePostMyRolesRequestAccessMutation();

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_role_id: ref_role_id || '',
      access_expiration_datetime_utc: dayjs(access_expiration_datetime_utc || defaultDate).format('YYYY-MM-DD'),
      access_request_business_justification: access_request_business_justification || '',
    },
    onSubmit: values => {
      const data: PostDmeRoleRequestuserRoleModel = {
        ...values,
        ref_user_id: logonUser?.ref_user_id?.toString() || '',
      };
      postAsync(data);
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
    setExpirationDate(defaultDate);
  };

  const selectCustomComponents = {
    Option: ({ children, ...props }: any) => (
      <chakraComponents.Option {...props}>
        {props.data.icon} {children}
      </chakraComponents.Option>
    ),
    SingleValue: ({ children, ...props }: any) => (
      <chakraComponents.SingleValue {...props}>
        <Flex align="center">
          {props.data.icon} {children}
        </Flex>
      </chakraComponents.SingleValue>
    ),
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = postDetail;

    if (isSuccess) {
      setAlertMessage('Request Access successfully submitted.');
      resetForm();
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [postDetail]);

  return (
    <>
      <Box
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Access</ModalHeader>
          <ModalCloseButton isDisabled={postDetail.isLoading} />
          <>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <List>
                  {ref_role_list && (
                    <ListItem>
                      <RequestQuestionItem index={1} label="Choose Role" isComplete={values.ref_role_id != ''}>
                        <FormControl isInvalid={!!errors.ref_role_id && touched.ref_role_id}>
                          <Select
                            id="ref_role_id"
                            name="ref_role_id"
                            useBasicStyles
                            size="sm"
                            components={selectCustomComponents}
                            defaultValue={ref_role_list.map((m: MyRolesExploreRolesDataModel) =>
                              m.ref_role_id == ref_role_id
                                ? {
                                    label: m.role_name,
                                    icon: <Icon as={FaUserCog} mr={2} />,
                                    value: m.ref_role_id,
                                  }
                                : null,
                            )}
                            value={(() => {
                              const m = ref_role_list.find(
                                f => f.ref_role_id.toString() === values.ref_role_id.toString(),
                              );
                              return m
                                ? {
                                    label: m.role_name,
                                    icon: <Icon as={FaUserCog} mr={2} />,
                                    value: m.ref_role_id,
                                  }
                                : null;
                            })()}
                            options={ref_role_list.map(m => {
                              return {
                                label: m.role_name,
                                icon: <Icon as={FaUserCog} mr={2} />,
                                value: m.ref_role_id,
                              };
                            })}
                            onChange={e => {
                              setValues({
                                ...values,
                                ref_role_id: e?.value?.toString() || '',
                              });
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: provided => ({
                                ...provided,
                                zIndex: 1401,
                              }),
                            }}
                            maxMenuHeight={500}
                          />
                          <FormErrorMessage>{errors.ref_role_id}</FormErrorMessage>
                        </FormControl>
                      </RequestQuestionItem>
                    </ListItem>
                  )}
                  <ListItem>
                    <RequestQuestionItem
                      index={2}
                      label={
                        <HStack align="center" spacing={1}>
                          <Text>Choose Expiration</Text>
                          <Text fontWeight="normal" as="small">
                            (YYYY-MM-DD)
                          </Text>
                        </HStack>
                      }
                      isComplete={
                        !errors.access_expiration_datetime_utc && values.access_expiration_datetime_utc.length > 0
                      }
                    >
                      <FormControl isInvalid={!!errors.access_expiration_datetime_utc}>
                        <CustomDatePicker
                          id="access_expiration_datetime_utc"
                          name="access_expiration_datetime_utc"
                          date={expirationDate}
                          onDateChange={(date: Date) => {
                            setValues({
                              ...values,
                              access_expiration_datetime_utc: dayjs(date).format('YYYY-MM-DD'),
                            });
                            setExpirationDate(date);
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                          propsConfigs={{
                            dateNavBtnProps: {
                              colorScheme: 'brand.main.default',
                              variant: 'outline',
                            },
                            dayOfMonthBtnProps: {
                              defaultBtnProps: {
                                _hover: {
                                  background: 'brand.main.default',
                                  color: 'white',
                                },
                              },
                              selectedBtnProps: {
                                background: 'brand.main.default',
                                color: 'white',
                              },
                              todayBtnProps: {
                                background: 'gray.400',
                              },
                            },
                            inputProps: {
                              placeholder: 'YYYY-MM-DD',
                              size: 'sm',
                              value: values.access_expiration_datetime_utc,
                              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  access_expiration_datetime_utc: e.target.value,
                                });
                                if (dayjs(e.target.value)?.isValid()) {
                                  setExpirationDate(dayjs(e.target.value).toDate());
                                }
                              },
                            },
                          }}
                        />
                        <FormErrorMessage>{errors.access_expiration_datetime_utc}</FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                  <ListItem>
                    <RequestQuestionItem
                      hasNoLine
                      index={3}
                      label="Fill in business justification"
                      isComplete={values.access_request_business_justification.length > 0}
                    >
                      <FormControl
                        isInvalid={
                          !!errors.access_request_business_justification &&
                          touched.access_request_business_justification
                        }
                      >
                        <Textarea
                          id="access_request_business_justification"
                          placeholder="Enter Message"
                          name="access_request_business_justification"
                          onChange={handleChange}
                          value={values.access_request_business_justification}
                          maxLength={1500}
                        />
                        <FormErrorMessage>{errors.access_request_business_justification}</FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                </List>

                <VStack spacing={5}>
                  {alertMessage && (
                    <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                      <AlertIcon />
                      {alertMessage}
                    </Alert>
                  )}
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3} ml="auto" isDisabled={postDetail.isLoading}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.main"
                  isLoading={postDetail.isLoading}
                  isDisabled={alertMessage !== '' && postDetail.isSuccess}
                >
                  Send
                </Button>
              </ModalFooter>
            </form>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestAccessDialog;
