import { useFormik } from 'formik';
import * as Yup from 'yup';
import env from '../../app/constants/env';
import { AddReportHubPortfolioModel, UpdateReportHubPortfolioModel } from '../../app/services/dme/api/types';
import { BuildSchemaArgs, buildSchema } from '../../app/helpers/formSchemabuilder';

export type EditComponentProps = {
  form: ReturnType<typeof useFormik<UpdateReportHubPortfolioModel>>;
};

export const initialValuesReportPortfolio: AddReportHubPortfolioModel = {
  report_portfolio_name: '',
  report_portfolio_desc: '',
  ref_domain_id: 0,
  report_portfolio_developer_user_id: 0,
  report_portfolio_link: '',
  report_portfolio_embed_link: '',
  ref_report_portfolio_type_id: 0,
  pii_present_flag: false,
  phi_present_flag: false,
  rh_report_portfolio_state_id: env.db.portfolioStateOperationalId,
  reports: [],
  tags: [],
};

export const portfolioFields: { [key: string]: BuildSchemaArgs } = {
  report_portfolio_name: {
    type: 'string',
    label: 'Portfolio Name',
    max: 300,
  },
  report_portfolio_desc: {
    type: 'string',
    label: 'Description',
    max: 2000,
  },
  ref_domain_id: {
    type: 'id',
    label: 'Domain',
  },
  report_portfolio_developer_user_id: {
    type: 'id',
    label: 'Developer',
  },
  report_portfolio_link: {
    type: 'url',
    label: 'Link',
    max: 3000,
  },
  report_portfolio_embed_link: {
    type: 'url',
    label: 'Embed Link',
    max: 3000,
  },
  ref_report_portfolio_type_id: {
    type: 'id',
    label: 'Type',
  },
  phi_present_flag: {
    type: 'boolean',
    label: 'PHI',
  },
  pii_present_flag: {
    type: 'boolean',
    label: 'PII',
  },
  rh_report_portfolio_state_id: {
    type: 'id',
    label: 'State',
  },
};

export const reportFields: { [key: string]: BuildSchemaArgs } = {
  report_name: {
    type: 'string',
    label: 'Name',
    max: 300,
  },
  report_desc: {
    type: 'string',
    label: 'Description',
    max: 2000,
  },
  rh_report_state_id: {
    type: 'id',
    label: 'State',
  },
  report_developer_user_id: {
    type: 'id',
    label: 'Developer',
  },
  report_embed_link: {
    type: 'url',
    label: 'Link',
    max: 3000,
  },
};

export const tagFields: { [key: string]: BuildSchemaArgs } = {
  ref_tag_id: {
    type: 'id',
    label: 'Tag',
  },
  notes: {
    type: 'string',
    label: 'Notes',
    max: 3000,
  },
};

export const PortfolioDetailsFormSchema = (t?: any) => {
  return Yup.object().shape({
    report_portfolio_name: buildSchema(portfolioFields.report_portfolio_name),
    report_portfolio_desc: buildSchema(portfolioFields.report_portfolio_desc),
    ref_domain_id: buildSchema(portfolioFields.ref_domain_id),
    report_portfolio_developer_user_id: buildSchema(portfolioFields.report_portfolio_developer_user_id),
    report_portfolio_link: buildSchema(portfolioFields.report_portfolio_link),
    // report_portfolio_embed_link: buildSchema(portfolioFields.report_portfolio_embed_link),
    ref_report_portfolio_type_id: buildSchema(portfolioFields.ref_report_portfolio_type_id),
    phi_present_flag: buildSchema(portfolioFields.phi_present_flag),
    pii_present_flag: buildSchema(portfolioFields.pii_present_flag),
    rh_report_portfolio_state_id: buildSchema(portfolioFields.rh_report_portfolio_state_id),
    ...t,
  });
};

export const PortfolioReportsFormSchema = Yup.array().of(
  Yup.object().shape({
    report_name: buildSchema(reportFields.report_name),
    report_desc: buildSchema(reportFields.report_desc),
    rh_report_state_id: buildSchema(reportFields.rh_report_state_id),
    report_developer_user_id: buildSchema(reportFields.report_developer_user_id),
    report_embed_link: buildSchema(reportFields.report_embed_link),
    tags: Yup.array().of(
      Yup.object().shape({
        ref_tag_id: buildSchema(tagFields.ref_tag_id),
      }),
    ),
  }),
);

export const PortfolioTagsFormSchema = Yup.array().of(
  Yup.object().shape({
    ref_tag_id: buildSchema(tagFields.ref_tag_id),
    notes: Yup.string().label(tagFields.notes.label),
  }),
);
