import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetCustomRequestSelectedDetailsQuery,
  useGetCustomRequestSelectedStatusQuery,
} from '../../../app/services/dme/api/customRequest';
import { Flex, HStack, Heading, VStack, Text, Icon } from '@chakra-ui/react';
import { FaArrowCircleRight, FaCircle } from 'react-icons/fa';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { GetCustomRequestSelectedStatusModel } from '../../../app/services/dme/api/types';
import dayjs from 'dayjs';

const CustomRequestsSelectedStatus = () => {
  const params = useParams();
  const [requestId, setRequestId] = useState(0);

  const requestDetails = useGetCustomRequestSelectedDetailsQuery(requestId, {
    skip: !requestId,
  });
  const {
    data: statusList,
    isLoading,
    isSuccess,
  } = useGetCustomRequestSelectedStatusQuery(requestId, { skip: !requestId });

  const [data, setData] = useState<GetCustomRequestSelectedStatusModel[]>([]);

  useEffect(() => {
    setRequestId(+(params?.customRequestId || 0));
  }, [params]);

  useEffect(() => {
    const list = [...(statusList ?? [])];
    setData(list);
  }, [statusList]);

  const hasExpiredEntry = !!data.find(f => f.current_status === 'Expiration');
  const isExpired = useMemo(() => {
    if (statusList && requestDetails.data && requestDetails.data.request_expiration_datetime_utc && hasExpiredEntry) {
      return dayjs(requestDetails.data.request_expiration_datetime_utc).diff(dayjs()) < 1;
    }
    return false;
  }, [hasExpiredEntry, statusList, requestDetails.data]);

  return isLoading || requestDetails.isLoading ? (
    <>Loading...</>
  ) : (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">Status:</Heading>
        <Heading size="sm" fontWeight="normal">
          {statusList && statusList?.length > 0 && statusList[0].current_status}
        </Heading>
      </HStack>

      <VStack spacing={4}>
        {data &&
          data.map((m, i) => (
            <Flex direction="row" alignItems="center" key={i}>
              {m.current_status === 'Expiration' ? (
                <Icon
                  as={isExpired ? FaArrowCircleRight : FaCircle}
                  color={isExpired ? 'brandColors.primary.green.300' : 'gray.400'}
                />
              ) : (
                <Icon
                  as={
                    (hasExpiredEntry && !isExpired && i === data.length - 2) ||
                    (!hasExpiredEntry && i === data.length - 1)
                      ? FaArrowCircleRight
                      : FaCircle
                  }
                  color={'brandColors.primary.green.300'}
                />
              )}
              <Text ml={3} w={81}>
                {m.current_status}
              </Text>
              <Text ml={1}>:</Text>
              <Text ml={2}>{convertUtcToLocal(m.row_modified_datetime_utc)}</Text>
              <Text ml={3}>{m.row_modified_user_name}</Text>
            </Flex>
          ))}
      </VStack>
    </VStack>
  );
};

export default CustomRequestsSelectedStatus;
