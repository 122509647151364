import { dmeApi } from './index';
import { PubNubTokenResponseModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getPubNubToken: builder.query<PubNubTokenResponseModel, void>({
      query: () => `/pubnub/token`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetPubNubTokenQuery, useLazyGetPubNubTokenQuery } = extendedApi;
