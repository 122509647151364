import {
  Alert,
  Box,
  Button,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { CgAttachment } from 'react-icons/cg';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { viewableExt } from '../../../app/constants';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { getName } from '../../../app/helpers/stringHelper';
import {
  useGetGoEdsAttachmentsByRequestDetailIdQuery,
  useGetGoEdsRequestSelectedDetailsQuery,
  useGetGoEdsRequestSelectedQaQuery,
} from '../../../app/services/dme/api/governanceOfficeEds';
import {
  GoEdsAttachmentsByRequestDetailIdModel,
  GoEdsRequestSelectedQaModel,
} from '../../../app/services/dme/api/types';

type Props = {};

const GovernanceOfficeSelectedDetails = (props: Props) => {
  const params = useParams();

  const goEdsId: number = parseInt(params?.goEdsId || '');
  const { data, isLoading, isFetching } = useGetGoEdsRequestSelectedDetailsQuery({ goEdsId }, { skip: goEdsId === 0 });
  const qaDetails = useGetGoEdsRequestSelectedQaQuery({ goEdsId }, { skip: goEdsId === 0 });

  if (isLoading || isFetching || qaDetails.isLoading || qaDetails.isFetching) return <>Loading...</>;
  if (!data) return <></>;

  return (
    <VStack
      sx={{
        '& p': { pl: 4 },
      }}
      pt="4"
      gap="4"
      fontSize="14px"
    >
      <Heading size="sm" color="black">
        Subject
      </Heading>
      <Text>{data.request_subject || '-'}</Text>
      <Heading size="sm" color="black">
        Request Details
      </Heading>
      <Table variant="simple" w="fit-content">
        <Tbody
          sx={{
            td: { border: 'none', pl: '0', pr: '4', pb: '4', pt: 0 },
          }}
        >
          <Tr>
            <Td>
              <Text fontWeight="bold">Created By</Text>
            </Td>
            <Td>{getName({ first_name: data.creator_first_name, last_name: data.creator_last_name }) || '-'}</Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontWeight="bold">Last Modified By</Text>
            </Td>
            <Td>{getName({ first_name: data.modified_first_name, last_name: data.modified_last_name }) || ['-']}</Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontWeight="bold">Last Modified</Text>
            </Td>
            <Td>{convertUtcToLocal(data.last_modified_datetime_utc) || '-'}</Td>
          </Tr>
        </Tbody>
      </Table>

      <Heading size="sm" mt="0 !important" color="black">
        Q&A
      </Heading>
      {qaDetails.data?.map((qa, i) => (
        <Box key={i} mt="4">
          <Heading size="sm" pl="4" mb="1">
            {qa.question_instruction_text || '-'}
          </Heading>
          <Text>
            {qa.question_type === 'Extended Open Text Box'
              ? qa.user_answer_text_box
              : qa.question_type === 'Yes/No Questions'
                ? qa.selected_answer
                : qa.question_type === 'Date Picker'
                  ? convertUtcToLocal(qa.user_answer_date_picker_datetime_utc, 'MMMM D, YYYY')
                  : '-'}
          </Text>
          {qa.attachment_enabled_flag && <QaAttachmentButtonModal qa={qa} />}
        </Box>
      ))}
    </VStack>
  );
};

const QaAttachmentButtonModal: FC<{ qa: GoEdsRequestSelectedQaModel }> = ({ qa }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading, isFetching } = useGetGoEdsAttachmentsByRequestDetailIdQuery(
    { go_eds_user_request_detail_id: qa.go_eds_user_request_detail_id },
    { skip: !isOpen },
  );

  const onClickDownload = (item: GoEdsAttachmentsByRequestDetailIdModel) => {
    window.open(item.user_request_detail_attachment_url, '_blank');
  };

  return (
    <>
      <Box ml="3" mt="1">
        <Button
          leftIcon={<CgAttachment />}
          variant="link"
          size="sm"
          _hover={{ textDecoration: 'none' }}
          sx={{ span: { marginRight: 1 } }}
          onClick={() => {
            if (qa.attachment_enabled_flag && qa.attachment_count > 0) {
              onOpen();
            }
          }}
        >
          {qa.attachment_count}
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachments</ModalHeader>
          <ModalCloseButton onClick={onOpen} />
          <ModalBody>
            <VStack maxH="calc(100vh - 250px)" overflowY="auto" spacing={4}>
              {data && data.length == 0 && !isLoading && !isFetching && <Alert status="error">No Attachment(s)</Alert>}
              {isLoading || isFetching ? (
                <>Loading...</>
              ) : (
                data &&
                data.map(m => {
                  const isViewable = viewableExt.some(s =>
                    s.includes(m.user_request_detail_attachment_url?.split('.')?.pop() || ''),
                  );
                  return (
                    <Fragment key={m.go_eds_user_request_detail_attachment_id}>
                      <Box alignItems="center" display="flex" maxW="600px">
                        <Text
                          noOfLines={1}
                          whiteSpace="normal"
                          wordBreak="break-all"
                          title={m.user_request_detail_attachment_name}
                        >
                          {m.user_request_detail_attachment_name}
                        </Text>
                        <IconButton
                          variant="link"
                          color="links"
                          size="md"
                          aria-label={isViewable ? 'View' : 'Download'}
                          icon={isViewable ? <HiOutlineExternalLink /> : <MdOutlineSimCardDownload />}
                          title={isViewable ? 'View' : 'Download'}
                          onClick={() => onClickDownload(m)}
                        />
                      </Box>
                    </Fragment>
                  );
                })
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3} ml="auto">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GovernanceOfficeSelectedDetails;
