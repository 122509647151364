import { Box, Flex } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetRhMyAccess2ndListQuery,
  useGetRhMyAccess3rdListQuery,
  useGetRhMyAccessListQuery,
} from '../../../app/services/dme/api/reportHub';
import {
  RhReportHubMyAccessFilterModel,
  RhReportHubMyAccessFirstDatasetModel,
  RhReportHubMyAccessModel,
  RhReportHubMyAccessThirdDatasetModel,
} from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import FilterPane from './FilterPane';
import ResultData from './ResultData';

type Props = {};

export type RhFilterItem = {
  filter_header: string;
  objs: { request_id: number; access_method: string }[];
};

const ReportHubMyAccess: FC<Props> = props => {
  const location = useLocation();

  const { logonUser } = useAppSelector(s => s.user);
  const [isResultLoading, setIsResultLoading] = useState(true);

  const firstDetails = useGetRhMyAccessListQuery(logonUser?.ref_user_id ?? 0, {
    skip: (logonUser?.ref_user_id ?? 0) === 0,
  });
  const secondDetails = useGetRhMyAccess2ndListQuery(logonUser?.ref_user_id ?? 0, {
    skip: (logonUser?.ref_user_id ?? 0) === 0,
  });
  const thirdDetails = useGetRhMyAccess3rdListQuery(logonUser?.ref_user_id ?? 0, {
    skip: (logonUser?.ref_user_id ?? 0) === 0,
  });
  const data: RhReportHubMyAccessModel | undefined = useMemo(() => {
    return !firstDetails.data || !secondDetails.data || !thirdDetails.data
      ? undefined
      : {
          first_dataset_model: firstDetails.data ?? [],
          second_dataset_model: secondDetails.data ?? [],
          third_dataset_model: thirdDetails.data ?? [],
        };
  }, [firstDetails.data, secondDetails.data, thirdDetails.data]);
  const isLoading = useMemo(() => {
    return firstDetails.isLoading || secondDetails.isLoading || thirdDetails.isLoading;
  }, [firstDetails.isLoading, secondDetails.isLoading, thirdDetails.isLoading]);

  const [selectedAccess, setSelectedAccess] = useState<RhReportHubMyAccessFirstDatasetModel | null>(null);

  const [mainData, setmainData] = useState<RhReportHubMyAccessModel | null>(null);
  const [filteredData, setFilteredData] = useState<RhReportHubMyAccessFirstDatasetModel[] | null>(null);
  const [filterData, setFilterData] = useState<Array<RhReportHubMyAccessThirdDatasetModel> | null>(null);

  const onFilterChange = (checkedFilters: RhReportHubMyAccessFilterModel[]) => {
    setIsResultLoading(true);

    if (checkedFilters.length > 0) {
      const filtered2ndData = mainData?.second_dataset_model?.filter(f =>
        checkedFilters.some(s => s.filter_header === f.filter_header && s.filter_detail === f.filter_detail),
      );

      const filterObj: Array<RhFilterItem> = [];
      filtered2ndData?.forEach(value => {
        const existingFilterIndex = filterObj.findIndex(
          f => f.filter_header.toLowerCase() === value.filter_header.toLowerCase(),
        );
        if (existingFilterIndex > -1) {
          filterObj[existingFilterIndex].objs = [
            ...filterObj[existingFilterIndex].objs,
            { request_id: value.request_id, access_method: value.access_method },
          ];
        } else {
          filterObj.push({
            filter_header: value.filter_header.toLowerCase(),
            objs: [{ request_id: value.request_id, access_method: value.access_method }],
          });
        }
      });

      let objs: RhFilterItem['objs'] = filterObj ? filterObj.map(m => m.objs)[0] : [];
      filterObj.forEach(a => {
        const newObjs = objs?.filter(f => a.objs.includes(f));
        if (newObjs.length) {
          objs = [...newObjs];
        }
      });

      const filtered1stData =
        mainData?.first_dataset_model?.filter(f =>
          objs.some(obj => f.request_id === obj.request_id && f.access_method === obj.access_method),
        ) || [];

      setFilteredData(filtered1stData);
    } else {
      setFilteredData(mainData?.first_dataset_model || []);
    }
    setIsResultLoading(false);
  };

  useEffect(() => {
    if (data && !isLoading) {
      setmainData(data);
      const filter: RhReportHubMyAccessFilterModel[] = data.third_dataset_model.reduce(function (
        r: RhReportHubMyAccessFilterModel[],
        o: RhReportHubMyAccessFilterModel,
      ) {
        let dataElementExistIndex = r.findIndex(
          f => f.filter_header === o.filter_header && f.filter_order === o.filter_order,
        );
        if (dataElementExistIndex > -1) {
          r[dataElementExistIndex].filter_items = [
            ...(r[dataElementExistIndex].filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r];
        } else {
          const filterItems = [
            ...(o.filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r, { ...o, filter_items: filterItems }];
        }
      }, []);
      setFilterData(filter);
      setFilteredData(data.first_dataset_model);
      setIsResultLoading(false);
    }
  }, [data]);

  return (
    <Flex w="100%">
      <Box w={300}>
        {filterData ? <FilterPane filterData={filterData} onChange={onFilterChange} /> : <>Loading...</>}
      </Box>
      <Box flex={1} px={3}>
        <ResultData data={filteredData || []} isLoading={isResultLoading} />
      </Box>
    </Flex>
  );
};

export default ReportHubMyAccess;
