import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { PlatformServicesExploreModel } from '../../../app/services/dme/api/types';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

const ExplorePlatformServicesActionCell = ({ row: { original } }: { row: { original: PlatformServicesExploreModel } }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="fit-content">
              <PopoverArrow />
              <PopoverBody>
                <VStack py="4" gap="2">
                  <ExplorePlatformServicesActionCellCreateRequest original={original} onClose={onClose} />
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

type Props = {
  original: PlatformServicesExploreModel;
  onClose: () => void;
};

const ExplorePlatformServicesActionCellCreateRequest: FC<Props> = ({ original, onClose: onCloseMenu }) => {
  const navigate = useNavigate();

  return (
    <Button
      size="sm"
      _hover={{ textDecoration: 'none' }}
      variant="link"
      fontWeight="normal"
      color="blackAlpha.900"
      textAlign="left"
      onClick={() => {
        navigate(`./create-request`, { state: { original } });
      }}
    >
      <Box w="100%">Create Request</Box>
    </Button>
  );
};

export default ExplorePlatformServicesActionCell;
 