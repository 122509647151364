import { Alert, Box, IconButton, Text, VStack } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { viewableExt } from '../../../../../../app/constants';
import { useGetDataProductPostAttachmentsByIdQuery } from '../../../../../../app/services/dme/api/dataProductPost';
import { DataProductPostAttachmentModel } from '../../../../../../app/services/dme/api/types';

type Props = {
  postId: number;
};

const DataProductPostAttachmentList: FC<Props> = ({ postId }) => {
  const { data, isLoading, isFetching } = useGetDataProductPostAttachmentsByIdQuery(postId, {
    skip: postId == 0,
  });
  const onClickDownload = (item: DataProductPostAttachmentModel) => {
    window.open(item.dp_post_attachment_url, '_blank');
  };

  return (
    <VStack maxH="calc(100vh - 250px)" overflowY="auto" spacing={4}>
      {data && data.length == 0 && !isLoading && !isFetching && <Alert status="error">No Attachment(s)</Alert>}
      {isLoading || isFetching ? (
        <>Loading...</>
      ) : (
        data &&
        data.map((m: DataProductPostAttachmentModel) => {
          const isViewable = viewableExt.some(s => s.includes(m.dp_post_attachment_url?.split('.')?.pop() || ''));
          return (
            <Fragment key={m.dp_post_attachment_id}>
              <Box alignItems="center" display="flex" maxW="600px">
                <Text noOfLines={1} whiteSpace="normal" wordBreak="break-all" title={m.dp_post_attachment_name}>
                  {m.dp_post_attachment_name}
                </Text>
                <IconButton
                  variant="link"
                  color="links"
                  size="md"
                  aria-label={isViewable ? 'View' : 'Download'}
                  icon={isViewable ? <HiOutlineExternalLink /> : <MdOutlineSimCardDownload />}
                  title={isViewable ? 'View' : 'Download'}
                  onClick={() => onClickDownload(m)}
                />
              </Box>
            </Fragment>
          );
        })
      )}
    </VStack>
  );
};

export default DataProductPostAttachmentList;
