import { ElementRef, FC, forwardRef, useImperativeHandle } from 'react';
import { useAppSelector } from '../../app/state/hooks';
import { DiscussionType, MentionPubnubMessage } from '../../app/types/appType';
import { getName } from '../../app/helpers/stringHelper';
import { usePubNub } from 'pubnub-react';

type Props = {};
type BroadcastMentionProps = {
  postId: number;
  commentId?: number;
  message: string;
  type: DiscussionType;
  data: any;
};
type ForwardProps = {
  broadcastMentions: (props: BroadcastMentionProps) => Promise<void>;
};
export type DiscussionHelperRef = ElementRef<typeof DiscussionHelper>;

const DiscussionHelper = forwardRef<ForwardProps, Props>((props, ref) => {
  const { logonUser } = useAppSelector(s => s.user);
  const pubnub = usePubNub();

  useImperativeHandle(ref, () => ({
    broadcastMentions: async ({ postId, commentId, message, type, data }) => {
      const el = document.createElement('div');
      el.innerHTML = message;
      const usersToMention = Array.from(el.getElementsByClassName('mention')).map(m => m.getAttribute('data-id'));
      if (logonUser) {
        const message: MentionPubnubMessage = {
          fromName: getName(logonUser),
          postId,
          commentId,
          type,
          data,
        };
        usersToMention.forEach(async f => {
          await pubnub.publish({
            channel: `mention.${f}`,
            message,
          });
        });
      }
    },
  }));

  return <></>;
});

export default DiscussionHelper;
