import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { ElementRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { defaultErrorMessage } from '../../../../app/constants';
import { useGetRefAccessLevelListQuery } from '../../../../app/services/dme/api/refAccessLevel';
import { usePostRefDeveloperEnvironmentMutation } from '../../../../app/services/dme/api/refDeveloperEnvironment';
import { GetRefAccessLevelListModel } from '../../../../app/services/dme/api/types';
import { SelectValue } from '../../../../app/types/appType';

type OnCreatedParams = {
  ref_developer_environment_id: number;
  developer_environment_name: string;
  developer_environment_desc: string;
  environment_properties: string;
  ref_access_level_id: number;
};
type Props = {
  onCreated: (params: OnCreatedParams) => Promise<void>;
};
type ForwardProps = {
  onOpen: () => void;
};
type AccessLevelDistinct = {
  [key: string]: {
    ids: number[];
    cloud_service: {
      [key: string]: {
        ids: number[];
        access_level: {
          [key: string]: {
            ids: number[];
          };
        };
      };
    };
  };
};
export type AddDeveloperEnvironmentForwardProps = ElementRef<typeof AddDevelopmentEnvironmentModal>;

export const addDevelopmentEnvironmentId = -999;
const FormSchema = Yup.object().shape({
  developer_environment_name: Yup.string().label('Name').required().max(300, 'Text exceed the character limit of 300'),
  developer_environment_desc: Yup.string()
    .label('Description')
    .required()
    .max(1000, 'Text exceed the character limit of 1000'),
  environment_properties: Yup.string()
    .label('Properties')
    .required()
    .max(1000, 'Text exceed the character limit of 1000'),
  ref_access_level_id: Yup.number().label('Platform').required().moreThan(0, 'Platform is a required field'),
});

const AddDevelopmentEnvironmentModal = forwardRef<ForwardProps, Props>(({ onCreated }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [postAsync, postDetail] = usePostRefDeveloperEnvironmentMutation();

  const refAccLvlDetail = useGetRefAccessLevelListQuery();

  const [refAccessLevelDistinct, setRefAccessLevelDistinct] = useState<AccessLevelDistinct | null>(null);

  useImperativeHandle(ref, () => ({
    onOpen() {
      onOpen();
    },
  }));

  const handleClose = () => {
    onClose();
    resetForm();
  };

  const { handleSubmit, errors, touched, handleChange, values, resetForm, setValues } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      developer_environment_name: '',
      developer_environment_desc: '',
      environment_properties: '',
      ref_access_level_id: -1,
    },
    onSubmit: values => {
      const data = {
        developer_environment_name: values.developer_environment_name,
        developer_environment_desc: values.developer_environment_desc,
        environment_properties: values.environment_properties,
        ref_access_level_id: values.ref_access_level_id,
      };
      postAsync(data)
        .unwrap()
        .then(res => {
          onCreated({ ...data, ref_developer_environment_id: res.ref_developer_environment_id });
          handleClose();
          toast({
            description: 'Successfully created developer environment.',
            status: 'success',
          });
        })
        .catch(() => toast({ description: defaultErrorMessage, status: 'error' }));
    },
  });

  const getValue = useCallback(
    (field: keyof GetRefAccessLevelListModel): SelectValue<number> => {
      const accessLevel = refAccLvlDetail.data?.find(f => f.ref_access_level_id === values.ref_access_level_id);
      return accessLevel
        ? {
            label: accessLevel[field] as string,
            value: values.ref_access_level_id,
          }
        : null;
    },
    [refAccLvlDetail.data, values.ref_access_level_id],
  );

  const getOptionValue = useCallback(
    (ids: number[]) => {
      let id = ids[0];

      for (let i = 0; i < ids.length; i++) {
        if (!(ids[i] === values.ref_access_level_id)) {
          id = ids[i];
          break;
        }
      }

      return id;
    },
    [values.ref_access_level_id],
  );

  const getOptionIsDisabled = useCallback(
    (ids: number[]) => {
      return ids.every(id => id === values.ref_access_level_id);
    },
    [values.ref_access_level_id],
  );

  useEffect(() => {
    if (refAccLvlDetail.data) {
      const obj: AccessLevelDistinct = {};
      refAccLvlDetail.data.forEach(f => {
        const id = f.ref_access_level_id;
        const platform = f.cloud_platform;
        const service = f.cloud_service;
        const accessLevel = f.access_level;

        const nameExists = Boolean(obj[platform]);
        if (nameExists) {
          obj[platform].ids.push(id);

          const propertyExists = Boolean(obj[platform].cloud_service[service]);
          if (propertyExists) {
            obj[platform].cloud_service[service].ids.push(id);

            const accessLevelExists = Boolean(obj[platform].cloud_service[service].access_level[accessLevel]);
            if (accessLevelExists) {
              obj[platform].cloud_service[service].access_level[accessLevel].ids.push(id);
            } else {
              obj[platform].cloud_service[service].access_level[accessLevel] = {
                ids: [id],
              };
            }
          } else {
            obj[platform].cloud_service[service] = {
              ids: [id],
              access_level: {
                [accessLevel]: {
                  ids: [id],
                },
              },
            };
          }
        } else {
          obj[platform] = {
            ids: [id],
            cloud_service: {
              [service]: {
                ids: [id],
                access_level: {
                  [accessLevel]: {
                    ids: [id],
                  },
                },
              },
            },
          };
        }
      });
      setRefAccessLevelDistinct(obj);
    }
  }, [refAccLvlDetail.data]);

  const accessLevel = useMemo(() => {
    return refAccLvlDetail.data?.find(f => f.ref_access_level_id === values.ref_access_level_id);
  }, [refAccLvlDetail.data, values.ref_access_level_id]);
  const serviceDistinct = useMemo(() => {
    return refAccessLevelDistinct && accessLevel && refAccessLevelDistinct[accessLevel.cloud_platform].cloud_service;
  }, [accessLevel, refAccessLevelDistinct]);
  const accessLevelDistinct = useMemo(() => {
    return (
      refAccessLevelDistinct &&
      accessLevel &&
      refAccessLevelDistinct[accessLevel.cloud_platform].cloud_service[accessLevel.cloud_service].access_level
    );
  }, [accessLevel, refAccessLevelDistinct]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="md" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Development Environment</ModalHeader>
          <ModalCloseButton onClick={handleClose} isDisabled={postDetail.isLoading} />
          <form onSubmit={handleSubmit}>
            <ModalBody display="flex" flexDir="column" gap="3">
              <FormControl isInvalid={!!errors.developer_environment_name && !!touched.developer_environment_name}>
                <FormLabel htmlFor="developer_environment_name">Name</FormLabel>
                <Input
                  id="developer_environment_name"
                  name="developer_environment_name"
                  placeholder="Enter name..."
                  onChange={handleChange}
                  value={values.developer_environment_name}
                  maxLength={300}
                  size="sm"
                />
                <FormErrorMessage>{errors.developer_environment_name}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.developer_environment_desc && touched.developer_environment_desc}>
                <HStack justifyContent="space-between">
                  <FormLabel htmlFor="developer_environment_desc">Description</FormLabel>
                  {values.developer_environment_desc.length > 0 && (
                    <Text as="small">{`${values.developer_environment_desc.length}/1000`}</Text>
                  )}
                </HStack>

                <Textarea
                  id="developer_environment_desc"
                  name="developer_environment_desc"
                  placeholder="Enter description..."
                  size="sm"
                  onChange={handleChange}
                  value={values.developer_environment_desc}
                  maxLength={1000}
                />
                <FormErrorMessage>{errors.developer_environment_desc}</FormErrorMessage>
              </FormControl>

              <FormControl minW="200px" isInvalid={!!errors.ref_access_level_id && !!touched.ref_access_level_id}>
                <FormLabel htmlFor="cloud_platform">Platform</FormLabel>
                <Select
                  id="cloud_platform"
                  name="cloud_platform"
                  size="sm"
                  useBasicStyles
                  maxMenuHeight={250}
                  placeholder="Select platform..."
                  value={getValue('cloud_platform')}
                  options={
                    refAccessLevelDistinct
                      ? Object.entries(refAccessLevelDistinct).map(([key, value]) => ({
                          label: key,
                          value: getOptionValue(value.ids),
                          // isDisabled: getOptionIsDisabled(value.ids),
                        }))
                      : undefined
                  }
                  onChange={e => {
                    e?.value && setValues({ ...values, ref_access_level_id: e.value });
                  }}
                />
                <FormErrorMessage>{errors.ref_access_level_id}</FormErrorMessage>
              </FormControl>

              <FormControl minW="200px">
                <FormLabel htmlFor="cloud_service">Service</FormLabel>
                <Select
                  id="cloud_service"
                  name="cloud_service"
                  size="sm"
                  useBasicStyles
                  maxMenuHeight={250}
                  placeholder="Select service..."
                  value={getValue('cloud_service')}
                  isDisabled={values.ref_access_level_id < 1}
                  options={
                    serviceDistinct
                      ? Object.entries(serviceDistinct).map(([key, value]) => ({
                          label: key,
                          value: getOptionValue(value.ids),
                          // isDisabled: getOptionIsDisabled(value.ids),
                        }))
                      : undefined
                  }
                  onChange={e => {
                    e?.value && setValues({ ...values, ref_access_level_id: e.value });
                  }}
                />
              </FormControl>

              <FormControl isInvalid={!!errors.environment_properties && touched.environment_properties}>
                <HStack justifyContent="space-between">
                  <FormLabel htmlFor="environment_properties">Properties</FormLabel>
                  {values.environment_properties.length > 0 && (
                    <Text as="small">{`${values.environment_properties.length}/1000`}</Text>
                  )}
                </HStack>

                <Textarea
                  id="environment_properties"
                  name="environment_properties"
                  placeholder="Enter properties..."
                  size="sm"
                  onChange={handleChange}
                  value={values.environment_properties}
                  maxLength={1000}
                />
                <FormErrorMessage>{errors.environment_properties}</FormErrorMessage>
              </FormControl>

              <FormControl minW="200px">
                <FormLabel htmlFor="access_level">Access Level</FormLabel>
                <Select
                  id="access_level"
                  name="access_level"
                  size="sm"
                  useBasicStyles
                  maxMenuHeight={250}
                  placeholder="Select access level..."
                  value={getValue('access_level')}
                  isDisabled={values.ref_access_level_id < 1}
                  options={
                    accessLevelDistinct
                      ? Object.entries(accessLevelDistinct).map(([key, value]) => ({
                          label: key,
                          value: getOptionValue(value.ids),
                          // isDisabled: getOptionIsDisabled(value.ids),
                        }))
                      : undefined
                  }
                  onChange={e => {
                    e?.value && setValues({ ...values, ref_access_level_id: e.value });
                  }}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter gap={3}>
              <Button onClick={handleClose} ml="auto" isDisabled={postDetail.isLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="brand.main"
                type="submit"
                isDisabled={postDetail.isLoading}
                isLoading={postDetail.isLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
});

export default AddDevelopmentEnvironmentModal;
