import { Flex, HStack, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaArrowCircleRight, FaCircle } from 'react-icons/fa';
import { FiCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useGetRhMyAccessSelectedStatusByIdQuery } from '../../../../app/services/dme/api/reportHub';
import { ReportHubMyAccessStatusModel } from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';

const ReportHubMyAccessSelectedStatus = () => {
  const params = useParams();

  const { logonUser } = useAppSelector(s => s.user);
  const requirestId: number = parseInt(params?.requirestId || '0') || 0;
  const {
    data: status,
    isLoading,
    isFetching,
  } = useGetRhMyAccessSelectedStatusByIdQuery(
    { ref_user_id: logonUser?.ref_user_id ?? 0, access_method: params.accessMethod ?? '', request_id: requirestId },
    {
      skip: requirestId === 0 || !params.accessMethod || !logonUser?.ref_user_id,
    },
  );

  const [data, setData] = useState<ReportHubMyAccessStatusModel[]>([]);
  const [highlightArrowIndex, setHighlightArrowIndex] = useState<number>();
  useEffect(() => {
    const sorted = [...(status ?? [])].sort((a, b) => {
      if (a.status_tracking_order > b.status_tracking_order) {
        return 1;
      }
      if (b.status_tracking_order > a.status_tracking_order) {
        return -1;
      }
      return 0;
    });
    const arrowIndex = sorted.findIndex(f => f.status_highlight_arrow);
    setHighlightArrowIndex(arrowIndex);
    setData(sorted);
  }, [status]);

  const getStatusIcon = (row: ReportHubMyAccessStatusModel, index: number) => {
    let color = 'gray.400';
    let icon = FiCircle;
    if (highlightArrowIndex !== undefined && highlightArrowIndex >= 0 && index <= highlightArrowIndex) {
      color = 'brandColors.primary.green.300';
      icon = FaCircle;
    }

    if (row.status_highlight_arrow) {
      return <Icon as={FaArrowCircleRight} color={color} />;
    }

    return <Icon as={icon} color={color} />;
  };

  return (
    <>
      <VStack spacing={5} p={1} pt={3}>
        {isLoading || isFetching ? (
          <>Loading...</>
        ) : (
          <VStack spacing={5} pt={3}>
            <HStack>
              <Heading size="sm">Status:</Heading>
              <Heading size="sm" fontWeight="normal">
                {data && data?.length > 0 && data[0].current_status}
              </Heading>
            </HStack>
            <VStack spacing={4}>
              {data &&
                data.slice(1).map((m, i) => (
                  <Flex direction="row" alignItems="center" key={i}>
                    {getStatusIcon(m, ++i)}
                    <Text ml={3} w={81}>
                      {m.status_tracking}
                    </Text>
                    <Text ml={1}>:</Text>
                    <Text ml={2}>{convertUtcToLocal(m.status_timestamp)}</Text>
                  </Flex>
                ))}
            </VStack>
          </VStack>
        )}
      </VStack>
    </>
  );
};

export default ReportHubMyAccessSelectedStatus;
