import {
  Alert,
  AlertIcon,
  Divider,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppAccess, AppAuthRequiredAppAccess } from '../../../app/constants/appAccesses';
import { ValueOf } from '../../../app/helpers/utilities';
import { useLazyGetAccessAdminApprovalByRefUserIdQuery } from '../../../app/services/dme/api/accessAdminApproval';
import {
  useLazyGetAdminAccessReportHubRoleListQuery,
  useLazyGetAdminAccessReportHubUserListQuery,
} from '../../../app/services/dme/api/reportHubAccess';
import { AccessAdminApprovalModel, PlatformServicesAccessAdminApprovalModel } from '../../../app/services/dme/api/types';
import { useGetRefUserListQuery } from '../../../app/services/dme/api/user';
import { useAppSelector } from '../../../app/state/hooks';
import AppAuth from '../../../features/AppAuth';
import AccessApprovalDataProductRole from './DataProductRole';
import AccessApprovalDataProductUser from './DataProductUser';
import AccessApprovalReportHubRole from './ReportHubRole';
import AccessApprovalReportHubUser from './ReportHubUser';
import AccessApprovalRoleAccess from './RoleAccess';
import AccessApprovalPlatformService from './PlatformService';
import { useLazyGetPlatformServicesAdminApprovalListQuery } from '../../../app/services/dme/api/platformServices';

type Props = {};
type TabType = {
  label: string;
  path: string;
  field: keyof AccessAdminApprovalModel;
  requiredAppAccess: AppAuthRequiredAppAccess;
  component: ({ data }: any) => JSX.Element;
};

const tabs: TabType[] = [
  {
    label: 'Role Access',
    path: 'role-access',
    field: 'first_dataset_model',
    requiredAppAccess: AppAccess.AdminAccessApproval,
    component: AccessApprovalRoleAccess,
  },
  {
    label: 'Data Product - User',
    path: 'data-product-user',
    field: 'second_dataset_model',
    requiredAppAccess: AppAccess.AdminAccessApproval,
    component: AccessApprovalDataProductUser,
  },
  {
    label: 'Data Product - Role',
    path: 'data-product-role',
    field: 'third_dataset_model',
    requiredAppAccess: AppAccess.AdminAccessApproval,
    component: AccessApprovalDataProductRole,
  },
  {
    label: 'Report Hub - User',
    path: 'report-hub-user',
    field: 'fourth_dataset_model',
    requiredAppAccess: AppAccess.AdminAccessApproval,
    component: AccessApprovalReportHubUser,
  },
  {
    label: 'Report Hub - Role',
    path: 'report-hub-role',
    field: 'fifth_dataset_model',
    requiredAppAccess: AppAccess.AdminAccessApproval,
    component: AccessApprovalReportHubRole,
  },
  {
    label: 'Platform Service Request',
    path: 'platform-service-request',
    field: 'sixth_dataset_model',
    requiredAppAccess: AppAccess.AdminAccessApproval,
    component: AccessApprovalPlatformService,
  },
];


const AccessApproval = (props: Props) => {
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const [initialized, setInitialized] = useState(false);
  const [localData, setLocalData] = useState<AccessAdminApprovalModel>({
    first_dataset_model: [],
    second_dataset_model: [],
    third_dataset_model: [],
    fourth_dataset_model: [],
    fifth_dataset_model: [],
    sixth_dataset_model: {
      first_dataset_model: [],
      second_dataset_model: [],
    }
  });

  const params = useParams();
  const navigate = useNavigate();
  const { logonUser } = useAppSelector(s => s.user);
  const [query, { data, isLoading, isFetching }] = useLazyGetAccessAdminApprovalByRefUserIdQuery();

  const [queryUsers, queryUsersDetails] = useLazyGetAdminAccessReportHubUserListQuery();
  const [queryRoles, queryRolesDetails] = useLazyGetAdminAccessReportHubRoleListQuery();

  const [queryPlatformServices, queryPlatformServicesDetails] = useLazyGetPlatformServicesAdminApprovalListQuery();

  const { data: userData, isLoading: isLoadingUser, isFetching: isFetchingUser } = useGetRefUserListQuery();

  useEffect(() => {
    if (logonUser) {
      query(logonUser.ref_user_id)
        .unwrap()
        .then(data => {
          setLocalData(s => ({ ...s, ...data }));
          setInitialized(true);
        });
      queryUsers(logonUser.ref_user_id)
        .unwrap()
        .then(users => {
          setData('fourth_dataset_model', users);
          setInitialized(true);
        });
      queryRoles(logonUser.ref_user_id)
        .unwrap()
        .then(roles => {
          setData('fifth_dataset_model', roles);
          setInitialized(true);
        });
      queryPlatformServices(logonUser.ref_user_id)
        .unwrap()
        .then(platformServicesRequestList => {
          setData('sixth_dataset_model', platformServicesRequestList);
          setInitialized(true);
        });
    }
  }, []);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  // useEffect(() => {
  //   data && setLocalData((s) => ({ ...s, ...data }));
  // }, [data]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const setData = (field: keyof AccessAdminApprovalModel, data: ValueOf<AccessAdminApprovalModel>) => {
    setLocalData(s => ({
      ...s!,
      [field]: data,
    }));
  };

  // const getLabel = (m: TabType, isAuthorized: boolean): string => {
  //   if (isAuthorized && localData && m.field && localData[m.field]?.length && !isLoading && !isFetching) {
  //     return `${m.label} (${localData[m.field]?.length})`;
  //   }
  //   return m.label;
  // };

  const getLabel = (m: TabType, isAuthorized: boolean, isLoading: boolean, isFetching: boolean): string => {
    if (isAuthorized && localData && m.field) {
      // Handle arrays and objects differently
      if (m.field === 'sixth_dataset_model') {
        const sixthModel = localData[m.field] as PlatformServicesAccessAdminApprovalModel;
        const count = sixthModel?.first_dataset_model?.length || 0 + sixthModel?.second_dataset_model?.length || 0;
        return `${m.label} (${count})`;
      } else {
        const length = (localData[m.field] as Array<any>)?.length || 0;
        if (length && !isLoading && !isFetching) {
          return `${m.label} (${length})`;
        }
      }
    }
    return m.label;
  };

  const isAllLoading =
    isLoading ||
    isFetching ||
    queryUsersDetails.isLoading ||
    queryUsersDetails.isFetching ||
    queryRolesDetails.isLoading ||
    queryRolesDetails.isFetching ||
    queryPlatformServicesDetails.isLoading ||
    queryPlatformServicesDetails.isFetching ||   
    isLoadingUser ||
    isFetchingUser;

  return (
    <VStack w="100%">
      <HStack>
        <Heading size="md">Access Approval</Heading>
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((t, i) => (
              <AppAuth key={i} requiredAppAccess={t.requiredAppAccess}>
                {/* {isAuthorized => <Tab key={i}>{getLabel(t, isAuthorized)}</Tab>} */}
                {isAuthorized => (
                  <Tab key={i}>
                    {getLabel(t, isAuthorized, isLoading, isFetching)}
                  </Tab>
                )}
              </AppAuth>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((t, i) => (
              <TabPanel key={i}>
                <AppAuth requiredAppAccess={t.requiredAppAccess}>
                  {isAuthorized => {
                    if (!isAuthorized && !isAllLoading) {
                      return (
                        <Alert status="error">
                          <AlertIcon />
                          Not authorized.
                        </Alert>
                      );
                    }
                    return initialized && userData ? (
                      t &&
                        createElement(t.component, {
                          data: localData,
                          refUsers: userData,
                          setData,
                        })
                    ) : (
                      <>Loading...</>
                    );
                  }}
                </AppAuth>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default AccessApproval;
