import React, { useState, useEffect, useRef, ElementRef, FC } from 'react';
import {
  Alert,
  AlertIcon,
  AlertProps,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { CgAttachment } from 'react-icons/cg';
import { MdOutlineThumbUpAlt, MdThumbUpAlt } from 'react-icons/md';
import { RiSendPlaneFill } from 'react-icons/ri';
import { BsPencil } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { DefaultInnerHtmlStyle } from '../../../../app/constants';
import { getAvatarUrl } from '../../../../app/helpers/avatarHelper';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { createFormData } from '../../../../app/helpers/formHelper';
import { stringOrHtmlIsEmpty } from '../../../../app/helpers/stringHelper';
import { debounceLeading } from '../../../../app/helpers/utilities';
import {
  useGetCustomRequestDetailsDiscussionTabQuery,
  useLazyGetCustomRequestDetailsDiscussionTabByCommentQuery,
  useLazyGetCustomRequestDetailsDiscussionTabQuery,
  usePostCustomRequestCommentMutation,
  usePostCustomRequestCommentToggleReactionMutation,
} from '../../../../app/services/dme/api/customRequestComment';
import {
  CustomRequestDetailsDiscussionTabModel,
  CustomRequestDetailsPageDiscussionModel,
} from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';
import DiscussionHelper, { DiscussionHelperRef } from '../../../Discussion/DiscussionHelper';
import TypingIndicator from '../../../PubNubWrapper/TypingIndicator';
import PubNubConstants from '../../../PubNubWrapper/constants';
import RichTextEditor, { RichTextEditorProps } from '../../../RichTextEditor';
import CustomRequestCommentAttachmentUpload from './Attachment/AttachmentUpload';
import CustomRequestCommentModal from './Modals/CommentModal';
import { useFormik } from 'formik';
import { useGetCustomRequestByCrIdQuery } from '../../../../app/services/dme/api/customRequest';
import { useGetRefUserListDetailsQuery } from '../../../../app/services/dme/api/user';

type Props = {
  selectedPost: CustomRequestDetailsPageDiscussionModel;
  onOccupants?: (occupants: string[]) => void;
  onClickAttachment: (commentId: number | null) => void;
};

const CommentList: FC<Props> = ({ selectedPost, onOccupants, onClickAttachment }) => {
  const pubnub = usePubNub();
  const params = useParams();
  const customRequestId: number = parseInt(params.customRequestId || '0');

  const { logonUser } = useAppSelector(s => s.user);
  const { presenceEvent, messageEvent } = useAppSelector(s => s.pubNub);

  const [channelName, setChannelName] = useState('');
  const [isCurrentUserTyping, setIsCurrentUserTyping] = useState(false);

  const [attachments, setAttachments] = useState<File[]>([]);
  const [alert, setAlert] = useState<{ message: string; status: AlertProps['status'] } | null>(null);

  type AttachmentForwardProps = ElementRef<typeof CustomRequestCommentAttachmentUpload>;

  const attachmentRef = useRef<AttachmentForwardProps>(null);
  const helperRef = useRef<DiscussionHelperRef>(null);

  const [postAsync, postDetail] = usePostCustomRequestCommentMutation();
  const [toggleReactionAsync, toggleReactionAsyncDetail] = usePostCustomRequestCommentToggleReactionMutation();

  const [toggleReactionLoader, setToggleReactionLoader] = useState<{
    cr_comment_id: number;
    isLoading: boolean;
  }>();

  const [getCommentDetail] = useLazyGetCustomRequestDetailsDiscussionTabByCommentQuery();
  const [comments, setComments] = useState<CustomRequestDetailsDiscussionTabModel[]>([]);
  const [selectedComment, setSelectedComment] = useState<{
    comment: CustomRequestDetailsDiscussionTabModel;
    action: 'update' | 'delete';
  } | null>(null);

  const [pageSize] = useState(5);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get User Info
  const { data: crdata, isLoading: isLoadingCR, isFetching: isFetchingCR } = useGetCustomRequestByCrIdQuery(customRequestId, { skip: customRequestId === 0 });
  const refRequestorId = crdata?.ref_requestor_id;
  const skipUserQuery = !refRequestorId || refRequestorId === 0;
  const { data: userdata, isLoading: isLoadingUserData, isFetching: isFetchingUserData } =  useGetRefUserListDetailsQuery(refRequestorId ?? 0, { skip: skipUserQuery});
   
  const mentionId = userdata ? "" + userdata.email : "";
  const mentionValue = userdata ? "<strong>" + userdata.first_name + ' ' + userdata.last_name + "</strong>" : "";

  const initialMention = `<p><br>cc: <span class="mention" data-index="2" data-denotation-char="@" data-id="${mentionId}" data-value="${mentionValue}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${mentionValue}</span></span> </p>`;

  
  const { handleSubmit, errors, touched, handleChange, values, resetForm, setFieldValue, setFieldTouched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      commentMessage: initialMention,
    },
    onSubmit: ({ commentMessage }) => {
      postComment(commentMessage);
    },
  });

  const handleButtonClick = () => {
    handleSubmit();
  };

  const {
    data: initialData,
    isLoading,
    isFetching,
  } = useGetCustomRequestDetailsDiscussionTabQuery(
    {
      crPostId: selectedPost.cr_post_id,
      query: new URLSearchParams({
        pageNumber: '1',
        pageSize: '5',
      }).toString(),
    },
    {
      skip: selectedPost.cr_post_id === 0,
    },
  );
  const [getComments] = useLazyGetCustomRequestDetailsDiscussionTabQuery();
  const [lazyLoadDataLength, setLazyLoadDataLength] = useState(0);

  const bottomRef = useRef(null);

  useEffect(() => {
    setComments([]);
    const _channelName = `cr_post_id_${selectedPost.cr_post_id}_comments`;
    setChannelName(_channelName);
    pubnub.subscribe({
      channels: [_channelName],
      withPresence: true,
    });

    return () => {
      setComments([]);
      pubnub.unsubscribe({
        channels: [_channelName],
      });
      setChannelName('');
    };
  }, [selectedPost.cr_post_id]);

  useEffect(() => {
    if (initialData && initialData.length) {
      setComments(initialData);
    } else {
      setComments([]);
      forceRemoveLazyLoaderText();
    }
  }, [initialData]);

  useEffect(() => {
    setLazyLoadDataLength(comments.length);
  }, [comments]);

  useEffect(() => {
    if (presenceEvent?.channel === channelName) {
      pubnub.hereNow(
        {
          channels: [channelName],
          includeState: true,
        },
        function (status, response) {
          const { occupants } = response.channels[channelName as keyof {}];
          const filterString = occupants.map(m => `id == '${m.uuid}'`);
          pubnub.objects
            .getAllUUIDMetadata({
              filter: filterString.join(' || '),
            })
            .then(d => {
              onOccupants && onOccupants(d.data.map(m => `${m.name}`));
            });
        },
      );
    }
  }, [presenceEvent]);

  useEffect(() => {
    if (messageEvent?.channel === channelName) {
      const { message } = messageEvent;

      switch (message.type) {
        case PubNubConstants.MessageEvent.Type.NEW:
          setComments(s => [message.data, ...s]);
          break;

        case PubNubConstants.MessageEvent.Type.UPDATE:
          const updatedData = message.data;
          setComments(s =>
            s.map(m => {
              if (m.cr_comment_id === updatedData.cr_comment_id) {
                return updatedData;
              } else {
                return m;
              }
            }),
          );
          break;

        case PubNubConstants.MessageEvent.Type.DELETE:
          setComments(s => s.filter(m => m.cr_comment_id !== message.commentId));
          break;

        default:
          break;
      }
    }
  }, [messageEvent]);

  useEffect(() => {
    setTimeout(async () => {
      if (isCurrentUserTyping) {
        await pubnub.signal({
          message: PubNubConstants.SignalEvent.TYPING,
          channel: channelName,
        });

        setIsCurrentUserTyping(false);
      }
    }, 900);
  }, [isCurrentUserTyping]);

  const fetchPagedComments = async () => {
    const nextPage = Math.floor((comments.length + pageSize) / pageSize);

    const response = await getComments({
      crPostId: selectedPost.cr_post_id,
      query: new URLSearchParams({
        pageNumber: nextPage.toString(),
        pageSize: pageSize.toString(),
      }).toString(),
    }).unwrap();

    const newRecords: CustomRequestDetailsDiscussionTabModel[] = [];

    response.forEach(d => {
      if (!comments.some(s => s.cr_comment_id === d.cr_comment_id)) {
        newRecords.push(d);
      }
    });

    setComments(s => [...s, ...newRecords]);
    if (newRecords.length === 0) {
      forceRemoveLazyLoaderText();
    }
  };

  const forceRemoveLazyLoaderText = () => {
    setLazyLoadDataLength(s => ++s);
  };

  const setNoPasteImageAlert = debounceLeading((hasImage: boolean) => {
    if (hasImage) setAlert({ message: 'Pasting image is not allowed.', status: 'warning' });
    else setAlert(null);
  }, 100);

  const onTextAreaKeyEvent: RichTextEditorProps['onChange'] = (text, { hasImage }) => {
    setIsCurrentUserTyping(true);
    setNoPasteImageAlert(hasImage);
    setFieldValue('commentMessage', text);
  };

  const postComment = async (commentMessage: string) => {
    setAlert(null);
    if (logonUser) {
      try {
        const values = {
          comment_by_user_id: logonUser?.ref_user_id,
          cr_post_id: selectedPost.cr_post_id,
          comment_message: commentMessage.replace(/\uFEFF/g, ''), //clean unwanted white space
          attachments: attachments,
        };
        const data = createFormData(values);
        const response = await postAsync(data).unwrap();

        setFieldValue('commentMessage', initialMention);
        await broadcastComment(response, PubNubConstants.MessageEvent.Type.NEW);
        await helperRef.current?.broadcastMentions({
          type: 'Custom Request',
          postId: selectedPost.cr_post_id,
          commentId: response,
          message: commentMessage,
          data: { customRequestId },
        });
        incrementPostCommentCount();
        attachmentRef.current?.resetFilePicker();
      } catch (error) {
        console.error('postAsync error', { error });
      }
    }
  };

  const broadcastComment = async (cr_comment_id: number, messageType: string) => {
    try {
      const result = await getCommentDetail({ cr_comment_id, query: '' }).unwrap();
      pubnub.publish({
        channel: channelName,
        message: {
          data: result[0],
          type: messageType,
        },
      });
    } catch (error) {
      console.error('broadcastComment error', { error });
    }
  };

  const incrementPostCommentCount = () => {
    pubnub.signal({
      message: PubNubConstants.SignalEvent.INCREMENT_COMMENT_COUNT,
      channel: `cr_post_id_${selectedPost.cr_post_id}`,
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFieldValue('commentMessage', initialMention);
    }
  }, [isOpen, setFieldValue]);

  const toggleReaction = (cr_comment_id: number) => async () => {
    if (logonUser) {
      setToggleReactionLoader({
        cr_comment_id,
        isLoading: true,
      });
      await toggleReactionAsync({
        cr_comment_id,
        reaction_user_id: logonUser.ref_user_id,
        ref_reaction_id: 1,
      });
      await broadcastComment(cr_comment_id, PubNubConstants.MessageEvent.Type.UPDATE);

      setToggleReactionLoader({
        cr_comment_id,
        isLoading: false,
      });
    }
  };

  return (
    <Flex flexDirection="column" maxH="calc(100vh - 250px)" h="full">
      <DiscussionHelper ref={helperRef} />
      {(isLoading || isFetching) && comments.length === 0 ? (
        <>Loading...</>
      ) : (
        <>
          {!comments.length && <Alert status="error">No Comment(s)</Alert>}
          <Flex id="scrollableDiv" overflow="auto" flexDirection="column-reverse" px={1}>
            <InfiniteScroll
              dataLength={lazyLoadDataLength}
              next={fetchPagedComments}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={true}
              loader={comments.length ? <h5>Loading...</h5> : <></>}
              scrollableTarget="scrollableDiv"
            >
              {comments.map((m: CustomRequestDetailsDiscussionTabModel) => (
                <VStack
                  key={m.cr_comment_id}
                  w="full"
                  alignItems="baseline"
                  spacing={3}
                  p={3}
                  bgColor="gray.50"
                  borderRadius={4}
                  mb={3}
                >
                  <Flex w="full" alignItems="center">
                    <Avatar size={'sm'} bg="gray.300" src={getAvatarUrl(m.comment_by_user)} />
                    <Text fontSize={14} fontWeight="medium" ml={2}>
                      {m.comment_by_user}
                    </Text>
                    <Text fontSize={12} ml="auto">
                      {convertUtcToLocal(m.comment_created_date_time, 'MMM D, YYYY h:mm A')}
                    </Text>
                  </Flex>
                  <Divider />
                  <Box className="ql-editor" textAlign="left" px={3} py={2} w="full">
                    <Text
                      whiteSpace="initial"
                      as="small"
                      dangerouslySetInnerHTML={{ __html: m.comment_message }}
                      sx={DefaultInnerHtmlStyle}
                    />
                  </Box>
                  <Divider />
                  <HStack alignItems="center" w="full" justifyContent="space-between">
                    <HStack>
                      <Button
                        leftIcon={<CgAttachment />}
                        variant="link"
                        size="sm"
                        _hover={{ textDecoration: 'none' }}
                        sx={{ span: { marginRight: 1 } }}
                        onClick={e => {
                          return parseInt(m.cnt_comment_attachments.toString()) > 0
                            ? onClickAttachment(m.cr_comment_id)
                            : null;
                        }}
                      >
                        {m.cnt_comment_attachments}
                      </Button>

                      <Button
                        leftIcon={
                          m.reactor_user_id_list &&
                          m.reactor_user_id_list.split(',').some(s => s === `${logonUser?.ref_user_id}`) ? (
                            <MdThumbUpAlt />
                          ) : (
                            <MdOutlineThumbUpAlt />
                          )
                        }
                        variant="link"
                        size="sm"
                        onClick={toggleReaction(m.cr_comment_id)}
                        isLoading={
                          toggleReactionLoader &&
                          toggleReactionLoader?.cr_comment_id === m.cr_comment_id &&
                          toggleReactionLoader.isLoading
                        }
                        isDisabled={toggleReactionAsyncDetail.isLoading}
                        _hover={{ textDecoration: 'none' }}
                        sx={{ span: { marginRight: 1 } }}
                      >
                        {m.cnt_comment_reaction}
                      </Button>
                    </HStack>

                    {logonUser?.email === m.comment_by_user && (
                      <HStack>
                        <Button
                          leftIcon={<BsPencil />}
                          variant="link"
                          size="sm"
                          _hover={{ textDecoration: 'none' }}
                          sx={{ span: { marginRight: 1 } }}
                          onClick={() => setSelectedComment({ comment: m, action: 'update' })}
                        />
                        <Button
                          leftIcon={<FaTrashAlt />}
                          variant="link"
                          size="sm"
                          _hover={{ textDecoration: 'none' }}
                          sx={{ span: { marginRight: 1 } }}
                          onClick={() => setSelectedComment({ comment: m, action: 'delete' })}
                        />
                      </HStack>
                    )}
                  </HStack>
                </VStack>
              ))}
            </InfiniteScroll>

            <div ref={bottomRef} />
          </Flex>

          <Flex flexDirection="column" mt="auto" pt={3}>
            <TypingIndicator channelName={channelName} />
            <RichTextEditor value={values.commentMessage} onChange={onTextAreaKeyEvent} removeImages />

            <HStack justifyContent="flex-end" mt={3}>
              {alert && (
                <Alert status={alert.status} h="8">
                  <AlertIcon />
                  {alert.message}
                </Alert>
              )}
              <CustomRequestCommentAttachmentUpload ref={attachmentRef} onChange={files => setAttachments(files)} />
              <Button
                colorScheme="brand.main"
                size="sm"
                rightIcon={<RiSendPlaneFill />}
                onClick={handleButtonClick}
                isDisabled={stringOrHtmlIsEmpty(values.commentMessage)}
                isLoading={postDetail.isLoading}
                minW="77px"
              >
                Send
              </Button>
            </HStack>
          </Flex>
        </>
      )}

      <CustomRequestCommentModal
        {...selectedComment}
        channelName={channelName}
        handleClose={() => {
          setSelectedComment(null);
        }}
      />
    </Flex>
  );
};

export default CommentList;