import { AiOutlineFileAdd, AiOutlineFileDone } from 'react-icons/ai';
import { BiFileFind } from 'react-icons/bi';
import {
  BsCardList,
  BsChatLeftQuoteFill,
  BsFileEarmarkPerson,
  BsFillPencilFill,
  BsFillPersonFill,
  BsPeopleFill,
} from 'react-icons/bs';
import { CgAdd, CgRemove } from 'react-icons/cg';
import { FaCog, FaCogs, FaLightbulb, FaRegFileAlt, FaTags, FaUserCog, FaUserLock, FaUsers } from 'react-icons/fa';
import { IoLinkSharp } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';
import { MdAdminPanelSettings, MdApproval, MdHowToVote, MdOutlineFeed, MdPeople } from 'react-icons/md';
import { RiHomeGearFill } from 'react-icons/ri';
import { SiAzuredataexplorer, SiDatabricks } from 'react-icons/si';
import { TfiCommentsSmiley } from 'react-icons/tfi';
import { AppAccess, AppAuthRequiredAppAccess } from '../../app/constants/appAccesses';
import { AccountBalance, ContactPage } from '../../assets/svg';
import Explore from '../../assets/svg/Explore';

export type NavItemModel = {
  icon?: IconType;
  iconComponent?: ({ data }: any) => JSX.Element;
  text: string;
  href: string;
  requiredAppAccess?: AppAuthRequiredAppAccess;
  subNav?: Array<NavItemModel>;
  strict?: boolean;
  hideInProd?: boolean;
} & (
  | {
      icon: IconType;
    }
  | {
      iconComponent: (props: any) => JSX.Element;
    }
);

const navItems: Array<NavItemModel> = [
  {
    icon: MdOutlineFeed,
    text: 'Infoweave',
    href: '/',
    requiredAppAccess: '*',
    strict: true,
  },
  {
    icon: MdAdminPanelSettings,
    text: 'Admin',
    href: '/admin',
    requiredAppAccess: AppAccess.Admin,
    subNav: [
      {
        icon: FaUsers,
        text: 'Users',
        href: '/admin/users',
        requiredAppAccess: AppAccess.AdminUsers,
      },
      {
        icon: TfiCommentsSmiley,
        text: 'Review Feedback',
        href: '/admin/review-feedback',
        requiredAppAccess: AppAccess.AdminReviewFeedback,
      },
      {
        icon: MdApproval,
        text: 'Access Approval',
        href: '/admin/access-approval',
        requiredAppAccess: AppAccess.AdminAccessApproval,
      },
      {
        icon: AiOutlineFileDone,
        text: 'Custom Requests Approval',
        href: '/admin/custom-requests-approval',
        requiredAppAccess: AppAccess.CustomRequestApproval,
      },
      {
        icon: MdPeople,
        text: 'Employee Onboarding - Offboarding Requests',
        href: '/admin/employee-onboarding-offboarding-requests',
        requiredAppAccess: AppAccess.EORequestApproval,
      },
    ],
  },
  {
    icon: FaRegFileAlt,
    text: 'Custom Requests',
    href: '/custom-requests',
    requiredAppAccess: AppAccess.CustomRequests,
    subNav: [
      {
        icon: AiOutlineFileAdd,
        text: 'Create Request',
        href: '/custom-requests/create-request',
        requiredAppAccess: AppAccess.CreateCustomRequest,
      },
      {
        icon: BsFileEarmarkPerson,
        text: 'My Requests',
        href: '/custom-requests/my-requests',
        requiredAppAccess: AppAccess.MyCustomRequest,
      },
    ],
  },
  {
    icon: FaLightbulb,
    text: 'Data Literacy',
    href: '/data-literacy',
    requiredAppAccess: AppAccess.DataLiteracy,
  },
  {
    icon: SiAzuredataexplorer,
    text: 'Data Products',
    href: '/data-products',
    requiredAppAccess: AppAccess.DataProducts,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Data Products',
        href: '/data-products/explore-data-products',
        requiredAppAccess: AppAccess.ExploreDataProducts,
      },
      {
        icon: FaUserLock,
        text: 'My Access',
        href: '/data-products/my-access',
        requiredAppAccess: AppAccess.DataProductMyAccess,
      },
    ],
  },
  // To be removed Domain from DME
  {
    icon: SiDatabricks,
    text: 'Domain',
    href: '/domain',
    requiredAppAccess: AppAccess.Domain,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Domain',
        href: '/domain/explore-domain',
        requiredAppAccess: AppAccess.ExploreDomain,
      },
      {
        icon: CgAdd,
        text: 'Add Domain',
        href: '/domain/add-domain',
        requiredAppAccess: AppAccess.AddDomain,
      },
      {
        icon: BsFillPencilFill,
        text: 'Edit Domain',
        href: '/domain/edit-domain',
        requiredAppAccess: AppAccess.EditDomain,
      },
    ],
  },
  {
    icon: RiHomeGearFill,
    text: 'Editor - Data Products',
    href: '/editor-data-products',
    requiredAppAccess: AppAccess.EditorDataProducts,
    subNav: [
      {
        icon: CgAdd,
        text: 'Edit Data Products',
        href: '/editor-data-products/edit-data-products',
        requiredAppAccess: AppAccess.EditDataProducts,
      },
      {
        icon: BsFillPencilFill,
        text: 'Add Data Products',
        href: '/editor-data-products/add-data-products',
        requiredAppAccess: AppAccess.AddDataProducts,
      },
    ],
  },
  {
    icon: BsPeopleFill,
    text: 'Employee Onboarding',
    href: '/employee-onboarding',
    requiredAppAccess: AppAccess.EmployeeOnboarding,
    subNav: [
      {
        icon: CgAdd,
        text: 'Create Onboarding Request',
        href: '/employee-onboarding/create-eo-request',
        requiredAppAccess: AppAccess.CreateEORequest,
      },
      {
        icon: BsFillPencilFill,
        text: 'My Requests',
        href: '/employee-onboarding/my-eo-requests',
        requiredAppAccess: AppAccess.MyEORequest,
      },
      {
        icon: CgRemove,
        text: 'Offboarding',
        href: '/employee-onboarding/offboarding',
        requiredAppAccess: AppAccess.Offboarding,
      },
    ],
  }, 
  {
    icon: BsChatLeftQuoteFill,
    text: 'Glossary',
    href: '/glossary',
    requiredAppAccess: AppAccess.LoreLanguage,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Glossary',
        href: '/glossary/explore-glossary',
        requiredAppAccess: AppAccess.ExploreLoreLanguage,
      },
      {
        icon: BsFillPencilFill,
        text: 'Edit Glossary',
        href: '/glossary/edit-glossary',
        requiredAppAccess: AppAccess.EditLoreLanguage,
      },
      {
        icon: CgAdd,
        text: 'Add Glossary',
        href: '/glossary/add-glossary',
        requiredAppAccess: AppAccess.AddLoreLanguage,
      },
    ],
  },
  {
    iconComponent: AccountBalance,
    text: 'Governance Office',
    href: '/governance-office',
    requiredAppAccess: AppAccess.GovernanceOffice,
    subNav: [
      {
        iconComponent: ContactPage,
        text: 'My External Data Sharing Requests',
        href: '/governance-office/my-external-data-sharing-requests',
        requiredAppAccess: AppAccess.MyEdsRequests,
      },
      {
        iconComponent: Explore,
        text: 'Explore External Data Sharing Requests',
        href: '/governance-office/explore-data-sharing-requests',
        requiredAppAccess: AppAccess.ExploreEdsRequests,
      },
      {
        iconComponent: MdHowToVote,
        text: 'External Data Sharing Requests Approval',
        href: '/governance-office/external-data-sharing-requests-approval',
        requiredAppAccess: AppAccess.EdsRequestsApproval,
      },
    ],
  },
  {
    icon: SiDatabricks,
    text: 'Groups',
    href: '/groups',
    requiredAppAccess: AppAccess.Groups,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Squads',
        href: '/groups/squads',
        requiredAppAccess: AppAccess.Squads,
      },
    ],
  },
  {
    icon: IoLinkSharp,
    text: 'Important Link',
    href: '/important-link',
    requiredAppAccess: AppAccess.LoreLink,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Important Link',
        href: '/important-link/explore-important-link',
        requiredAppAccess: AppAccess.ExploreLoreLink,
      },
      {
        icon: BsFillPencilFill,
        text: 'Edit Important Link',
        href: '/important-link/edit-important-link',
        requiredAppAccess: AppAccess.EditLoreLink,
      },
      {
        icon: CgAdd,
        text: 'Add Important Link',
        href: '/important-link/add-important-link',
        requiredAppAccess: AppAccess.AddLoreLink,
      },
    ],
  },
  {
    icon: FaCog,
    text: 'Platform Services',
    href: '/platform-services',
    requiredAppAccess: AppAccess.PlatformServices,
    subNav: [
      {
        iconComponent: Explore,
        text: 'Explore Platform Services',
        href: '/platform-services/explore-platform-services',
        requiredAppAccess: AppAccess.ExplorePlatformServices,
      },
      // {
      //   iconComponent: BsFileEarmarkPerson,
      //   text: 'My Service Requests',
      //   href: '/platform-services/my-requests',
      //     requiredAppAccess: AppAccess.MyPlatformServices,
      // },
    ],
  },
  {
    icon: BsCardList,
    text: 'Report Hub',
    href: '/report-hub',
    requiredAppAccess: AppAccess.ReportHub,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Portfolios',
        href: '/report-hub/explore-portfolios',
        requiredAppAccess: AppAccess.ExploreReportPortfolios,
      },
      {
        icon: CgAdd,
        text: 'Add Portfolio',
        href: '/report-hub/add-portfolio',
        requiredAppAccess: AppAccess.AddReportPortfolios,
      },
      {
        icon: BsFillPencilFill,
        text: 'My Access',
        href: '/report-hub/my-access',
        requiredAppAccess: AppAccess.ReportPortfoliosMyAccess,
      },
    ],
  }, 
  {
    icon: FaUserCog,
    text: 'Roles',
    href: '/roles',
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Roles',
        href: '/roles/explore-roles',
      },
      {
        icon: FaCogs,
        text: 'App Access By Roles',
        href: '/roles/app-access-by-roles',
        requiredAppAccess: AppAccess.AppAccessByRoles,
      },
      {
        icon: CgAdd,
        text: 'Add Role',
        href: '/roles/add-role',
        requiredAppAccess: AppAccess.AddRole,
      },
      {
        icon: BsFillPersonFill,
        text: 'My Roles',
        href: '/roles/my-roles',
      },
    ],
  },
  {
    icon: FaTags,
    text: 'Tags',
    href: '/tags',
    requiredAppAccess: AppAccess.Tags,
    subNav: [
      {
        icon: BiFileFind,
        text: 'Explore Tag',
        href: '/tags/explore-tags',
        requiredAppAccess: AppAccess.ExploreTags,
      },
      {
        icon: CgAdd,
        text: 'Add Tag',
        href: '/tags/add-tag',
        requiredAppAccess: AppAccess.AddTags,
      },
    ],
  },
];

export default navItems;
