import { AddIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Badge,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { usePubNub } from 'pubnub-react';
import { useEffect, useRef, useState } from 'react';
import { CgAttachment, CgClose } from 'react-icons/cg';
import { useFilePicker, Validator } from 'use-file-picker';
import * as Yup from 'yup';
import { channelNameGoEdsMutation } from '..';
import { fileConfig } from '../../../../../app/constants';
import { createFormData } from '../../../../../app/helpers/formHelper';
import { stringOrHtmlIsEmpty } from '../../../../../app/helpers/stringHelper';
import { debounceLeading } from '../../../../../app/helpers/utilities';
import {
    useAddGoEdsPostMutation,
    useLazyGetGoEdsPostByIdQuery,
} from '../../../../../app/services/dme/api/governanceOfficeEdsPost';
import { useAppSelector } from '../../../../../app/state/hooks';
import DiscussionHelper, { DiscussionHelperRef } from '../../../../../features/Discussion/DiscussionHelper';
import PubNubConstants from '../../../../../features/PubNubWrapper/constants';
import RichTextEditor from '../../../../../features/RichTextEditor';

const FormSchema = Yup.object().shape({
  post_subject: Yup.string().label('Subject').trim().required().max(1000, 'Text exceed the character limit of 1000'),
  post_message: Yup.string()
    .label('Message')
    .required()
    .test((str, { createError }) => {
      return stringOrHtmlIsEmpty(str)
        ? createError({ message: 'Message is a required field', path: 'post_message' })
        : true;
    })
    .max(8000, 'Text exceed the character limit of 8000'),
});

type Props = {
  goEdsId: number;
};

const GoEdsCreatePost = ({ goEdsId }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postAsync, postDetail] = useAddGoEdsPostMutation();
  const [getAsync, getDetail] = useLazyGetGoEdsPostByIdQuery();
  const pubnub = usePubNub();
  const { logonUser } = useAppSelector(s => s.user);

  const [alertMessage, setAlertMessage] = useState('');
  const [errorPostMessage, setErrorPostMessage] = useState<string>('');
  const helperRef = useRef<DiscussionHelperRef>(null);

  const [openFileSelector, filePicker] = useFilePicker({
    multiple: true,
    accept: fileConfig.acceptedFiles.map(m => '.' + m),
    limitFilesConfig: { max: parseInt(fileConfig.maxFileCount || '0') },
    maxFileSize: parseInt(fileConfig.maxFileSize || '0'),
    readFilesContent: false,
    validators: [fileTypeValidator],
  });

  const setNoPasteImageAlert = debounceLeading((hasImage: boolean) => {
    // added debounceLeading as error will not show on pasting in empty message
    if (hasImage) setErrorPostMessage('Pasting image is not allowed.');
    else setErrorPostMessage('');
  }, 100);

  const { handleSubmit, errors, touched, handleChange, values, resetForm, setFieldValue, setFieldTouched } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      post_subject: '',
      post_message: '',
    },
    onSubmit: ({ post_subject, post_message }) => {
      setErrorPostMessage('');
      if (logonUser && filePicker.errors.length <= 0) {
        const data = createFormData({
          go_eds_request_id: goEdsId,
          post_created_user_id: logonUser.ref_user_id,
          post_subject,
          post_message: post_message.replace(/\uFEFF/g, ''), //clean unwanted white space
          attachments: filePicker.plainFiles,
        });
        postAsync(data);
      }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const handlePostDetailChanges = async () => {
      const { isSuccess, isError, data: post } = postDetail;

      if (isSuccess) {
        setAlertMessage('Post successfully created.');
        const data = await getAsync(post.go_eds_post_id).unwrap(); // TODO
        pubnub.publish({
          channel: channelNameGoEdsMutation,
          message: {
            goEdsId,
            data: data[0],
            type: PubNubConstants.MessageEvent.Type.NEW,
          },
        });
        helperRef.current?.broadcastMentions({
          type: 'Governance Office EDS',
          postId: post.go_eds_post_id,
          message: data[0].post_message,
          data: { goEdsId },
        });
        setTimeout(() => {
          clearModal();
        }, 3000);
      } else if (isError) {
        setAlertMessage('There was an error processing your request, please try again later.');
      } else {
        setAlertMessage('');
      }
    };

    handlePostDetailChanges();
  }, [postDetail]);

  const clearModal = () => {
    filePicker.clear();
    resetForm();
    setAlertMessage('');
    setNoPasteImageAlert('');
    onClose();
  };

  return (
    <>
      <DiscussionHelper ref={helperRef} />
      <Button size="sm" leftIcon={<AddIcon color="white" />} colorScheme="brand.main" onClick={onOpen}>
        Create Post
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Post</ModalHeader>
          <ModalCloseButton isDisabled={postDetail.isLoading || getDetail.isLoading} />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={5}>
                <FormControl isInvalid={!!errors.post_subject && touched.post_subject}>
                  <FormLabel htmlFor="post_subject">Subject</FormLabel>
                  <Input
                    id="post_subject"
                    placeholder="Enter Subject"
                    name="post_subject"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('post_subject', true)}
                    value={values.post_subject}
                    maxLength={1000}
                  />
                  <FormErrorMessage>{errors.post_subject}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errorPostMessage || (!!errors.post_message && touched.post_message)}>
                  <FormLabel htmlFor="post_message">Message</FormLabel>
                  <RichTextEditor
                    value={values.post_message}
                    onChange={(value, { hasImage }) => {
                      setFieldValue('post_message', value);
                      setNoPasteImageAlert(hasImage);
                    }}
                    onBlur={() => setFieldTouched('post_message', true)}
                    removeImages
                  />
                  <FormErrorMessage>{errorPostMessage || errors.post_message}</FormErrorMessage>
                </FormControl>

                {filePicker.plainFiles.length > 0 && (
                  <HStack>
                    <Wrap
                      align="center"
                      p={2}
                      border="1px"
                      borderColor="chakra-border-color"
                      borderRadius={5}
                      maxH="75px"
                      overflowY="auto"
                    >
                      {filePicker.plainFiles.map((m, i) => (
                        <WrapItem key={i}>
                          <Badge variant="outline" textTransform="none" title={m.name}>
                            <Text noOfLines={1} maxW="225px" whiteSpace="break-spaces">
                              {m.name}
                            </Text>
                          </Badge>
                        </WrapItem>
                      ))}
                    </Wrap>
                    <IconButton
                      icon={<CgClose />}
                      aria-label="clear attachment"
                      variant="ghost"
                      onClick={filePicker.clear}
                      size="sm"
                    />
                  </HStack>
                )}
                {filePicker.errors.length > 0 && (
                  <Alert status="error" fontSize="xs" py={1} px={2}>
                    {((filePicker.errors[0] || {}) as any).fileTypeError}
                  </Alert>
                )}

                {alertMessage && (
                  <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                    <AlertIcon />
                    {alertMessage}
                  </Alert>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter gap={3}>
              <IconButton
                title="Add Attachment"
                aria-label="add attachment"
                onClick={openFileSelector}
                icon={<CgAttachment />}
                variant="solid"
                size="md"
                mr="auto"
              />
              <Button onClick={onClose} ml="auto" isDisabled={postDetail.isLoading || getDetail.isLoading}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={postDetail.isLoading || getDetail.isLoading}
                isDisabled={
                  stringOrHtmlIsEmpty(values.post_subject) ||
                  stringOrHtmlIsEmpty(values.post_message) ||
                  (alertMessage !== '' && postDetail.isSuccess)
                }
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GoEdsCreatePost;

const fileTypeValidator: Validator = {
  validateBeforeParsing: async (config, plainFiles) =>
    new Promise((res, rej) => {
      const invalidFiles = plainFiles.filter(f => {
        const selectedFileType = (f.name || '').split('.').pop() || '';
        return !fileConfig.acceptedFiles.includes(selectedFileType);
      });
      if (invalidFiles.length) {
        return rej({
          fileTypeError: `File type${invalidFiles.length > 1 ? 's are' : ' is'}  not allowed: ${invalidFiles
            .map(m => m.name)
            .join(', ')}`,
        });
      }
      if (config.limitFilesConfig?.max && plainFiles.length > config.limitFilesConfig.max) {
        return rej({
          fileTypeError: `The number of attachment exceeds its limit (10)`,
        });
      }

      const overSizedFiles = plainFiles.filter(f => {
        return f.size > parseInt(fileConfig.maxFileSize || '0') * 1000000;
      });
      if (overSizedFiles.length) {
        return rej({
          fileTypeError: `${
            overSizedFiles.length > 1 ? 'These files exceed' : 'This file exceeds'
          }  the maximum file size limit: ${overSizedFiles.map(m => m.name).join(', ')}`,
        });
      }
      return res();
    }),
  validateAfterParsing: async (config, file, reader) =>
    new Promise((res, rej) => {
      res();
    }),
};
