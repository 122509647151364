import { VStack, Heading, Text } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { getFileName } from '../../../../app/helpers/stringHelper';
import { useGetDataProductSelectDetailByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';

type Props = {};

const DataProductSelectedLineage = (props: Props) => {
  const params = useParams();
  const { data, isLoading } = useGetDataProductSelectDetailByProductIdQuery(+(params?.dataProductId || 0), {
    skip: +(params?.dataProductId || 0) == 0,
  });
  const onClickDownload = (url: string) => {
    window.open(url, '_blank');
  };

  const fileName = data?.data_product_lineage_filename
    ? getFileName(data.data_product_lineage_filename).split('-lineage-')[1]
    : '';
  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Data Product Lineage</Heading>
      {data?.data_product_lineage_filename && (
        <Text cursor="pointer" color="links" onClick={() => onClickDownload(data.data_product_lineage_filename)}>
          {fileName}
        </Text>
      )}
    </VStack>
  );
};

export default DataProductSelectedLineage;
