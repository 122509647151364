import { Box, Text } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import {
  useGetRefTagPaginatedFilterQuery,
  useGetRefTagPaginatedListQuery,
} from '../../../app/services/dme/api/refTags';
import { GetRefTagPaginatedFilterModel, GetRefTagPaginatedModel } from '../../../app/services/dme/api/types';
import { DynamicObject } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import { SortType } from '../../../app/services/types';

type Props = {};
const InitialSortBy: SortType<GetRefTagPaginatedModel> = {
  id: 'tag_name',
  desc: false,
};

const ExploreTagsList: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const navigate = useNavigate();
  const { data: filterData } = useGetRefTagPaginatedFilterQuery();

  const filters = useMemo(() => {
    const tmpFilters: { filter_header: string; filter_detail: string[] }[] = [];
    searchParams.forEach((value, key) => {
      tmpFilters.push({
        filter_header: key,
        filter_detail: [...decodeURIComponent(value).split(',')],
      });
    });
    if (tmpFilters.length > 0 && filterData) {
      const initFilters: GetRefTagPaginatedFilterModel[] = [];
      tmpFilters.forEach(filter => {
        filter.filter_detail.forEach((filter_detail: string) => {
          const item = filterData.find(f => f.filter_detail === filter_detail);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      return initFilters.map(({ filter_order, filter_detail }) => ({ filter_order, filter_detail }));
    }
    return [];
  }, [filterData, searchParams]);

  const { data, isLoading, isFetching } = useGetRefTagPaginatedListQuery({
    filters,
    page_number: pageNumber,
    page_size: pageSize,
    search_string: searchParams.get('search') ?? '',
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
  });

  const Header = [
    {
      Header: 'Tag',
      accessor: 'tag_name',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: GetRefTagPaginatedModel } }) => {
        return (
          <Box maxW="200px">
            <Text title={original.tag_name}>{original.tag_name}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'Tag Description',
      accessor: 'tag_desc',
      styles: { whiteSpace: 'initial' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: GetRefTagPaginatedModel } }) => {
        return (
          <Box maxW="800px">
            <Text noOfLines={3} title={original.tag_desc}>
              {original.tag_desc}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Reference Count',
      accessor: 'cnt',
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: GetRefTagPaginatedModel } }) => {
        return <>{original.cnt || 0}</>;
      },
    },
    {
      Header: 'Last Modified Date',
      accessor: 'row_modified_datetime_utc',
      Cell: ({ row: { original } }: { row: { original: GetRefTagPaginatedModel } }) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>;
      },
    },
  ];

  const onClickRow = (row: GetRefTagPaginatedModel) => {
    navigate(`./id/${row.ref_tag_id}/data-products`);
  };

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        isLoading={isLoading}
        isFetching={isFetching}
        data={data?.data ?? []}
        pageCount={data?.total_pages || 0}
        pageSize={data?.page_size || pageSize}
        totalRecords={data?.total_records || 0}
        pageIndex={pageNumber - 1}
        headers={Header}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [InitialSortBy] }}
        manualSortBy
        disableSortRemove
        onPageChange={index => {
          setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          setPageNumber(1);
          setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          setPageNumber(1);

          const params: DynamicObject = {};
          searchParams.forEach((val, key) => (params[key] = val));
          setSearchParams({ ...params, search: search }, { replace: true });
        }}
        onSort={sort => {
          if (sort[0]) {
            setPageNumber(1);
            setSortBy(sort[0]);
          }
        }}
        styles={{ pagination: { justifyContent: 'start' }, header: { justifyContent: 'left' } }}
        manual={true}
        onRowClick={row => onClickRow(row)}
      />
    </Box>
  );
};

export default ExploreTagsList;
