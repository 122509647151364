import { AuthenticatedTemplate, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIdTokenClaims } from '../../app/services/auth/helper';
import { useLazyGetUserByEmailQuery } from '../../app/services/dme/api/user';
import { setLogonUser } from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import PageLoading from '../../components/PageLoading';

type IProps = {
  children: ReactNode;
  onAuthenticated?: () => void;
};

const AuthenticatedUserWrapper: FC<IProps> = ({ children, onAuthenticated }) => {
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();

  const navigate = useNavigate();

  const { logonUser } = useAppSelector(s => s.user);
  const dispatch = useAppDispatch();

  const [lazyGetUserByEmail, queryResult] = useLazyGetUserByEmailQuery();

  useEffect(() => {
    if (isAuthenticated) {
      const { emails = [] } = getIdTokenClaims(account) || {};

      onAuthenticated && onAuthenticated();

      if (emails?.length && !logonUser) {
        lazyGetUserByEmail(emails[0]);
      }
      if (logonUser?.disabled_flag) {
        navigate('/unauthorized');
      }
    }
  }, [isAuthenticated, logonUser]);

  useEffect(() => {
    if (queryResult.isSuccess) {
      dispatch(setLogonUser(queryResult.data));
    }
    if (queryResult.isError) {
      navigate('/unauthorized');
    }
  }, [queryResult]);

  return (
    <>
      {!logonUser && (queryResult.isLoading || queryResult.isFetching) ? (
        <PageLoading />
      ) : (
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      )}
    </>
  );
};

export default AuthenticatedUserWrapper;
