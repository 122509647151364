import { WarningIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { serialize } from 'object-to-formdata';
import { createElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { addBreadcrumb, sliceBreadcrumb } from '../../../app/helpers/navigationHelpers';
import { ValueOf } from '../../../app/helpers/utilities';
import { useGetDataProductStateListQuery } from '../../../app/services/dme/api/dataProduct';
import { usePostDataProductMutation } from '../../../app/services/dme/api/editorDataProduct';
import {
  DataProductSelectPageModesForViewModel,
  DataProductSelectPageModesModel,
  DataProductSelectPagePayloadElementTagsModel,
  DataProductSelectPageTagsModel,
} from '../../../app/services/dme/api/types';
import { AddDataProductModel } from '../../../app/services/types';
import { useAppSelector } from '../../../app/state/hooks';
import DataProductSelectedDetails from './Details';
import { activeDpStateId, initialValues } from './initialValues';
import DataProductSelectedLineage from './Lineage';
import DataProductSelectedMode from './Mode';
import DataProductSelectedPayloadElementTag from './PayloadElementTag';
import DataProductSelectedTags from './Tags';

type Props = {};

const AddDataProduct = (props: Props) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { logonUser } = useAppSelector(s => s.user);
  const [localError, setLocalError] = useState<{ title: string; desc: string }>();
  const [localSuccess, setLocalSuccess] = useState<string>();
  const [tabErrorIndex, setTabErrorIndex] = useState<number>(-1);
  const [postAsync, postDetail] = usePostDataProductMutation();
  const { data: stateData, isLoading: isLoadingdpState } = useGetDataProductStateListQuery();

  const tabs = [
    {
      label: 'Details',
      path: 'details',
      component: DataProductSelectedDetails,
      model: 'detailModel',
      isArray: false,
    },
    {
      label: 'Payload and Element Tags',
      path: 'payload-element-tags',
      component: DataProductSelectedPayloadElementTag,
      model: 'payloadModel',
      isArray: true,
    },
    {
      label: 'Lineage',
      path: 'lineage',
      component: DataProductSelectedLineage,
      model: 'lineageModel',
      isArray: false,
    },
    {
      label: 'Mode',
      path: 'mode',
      component: DataProductSelectedMode,
      model: 'modeModel',
      isArray: true,
    },
    {
      label: 'Tags',
      path: 'tags',
      component: DataProductSelectedTags,
      onChange: (data: Array<DataProductSelectPageTagsModel>, isReset?: boolean) => {
        if (isReset) {
          resetForm();
        } else {
          setValues({ ...values, tagModel: data });
        }
        if (tabErrorIndex == 4) {
          setTabErrorIndex(-1);
          setLocalError(undefined);
        }
      },
      model: 'tagModel',
      isArray: true,
    },
  ];

  const FormSchema = Yup.object().shape({
    detailModel: Yup.object().shape({
      data_product_name: Yup.string().label('Name').required(),
      data_product_desc: Yup.string().label('Description').required(),
      ref_domain_id: Yup.number().moreThan(0).label('Domain').required(),
      data_product_type_id: Yup.number().moreThan(0).label('Product Type').required(),
      developer_user_id: Yup.number().moreThan(0).label('Developer').required(),
      dp_data_product_state_id: Yup.number().moreThan(0).label('State').required(),
    }),
    payloadModel: Yup.array<DataProductSelectPagePayloadElementTagsModel[]>().of(
      Yup.object().shape({
        data_element: Yup.string().required().label('Data Element'),
        data_element_data_type: Yup.string().required().label('Data Type'),
        data_element_description: Yup.string().required().label('Description'),
        ref_tag_ids: Yup.array<number[]>().of(Yup.number().moreThan(0)),
      }),
    ),
    modeModel: Yup.array<DataProductSelectPageModesModel[]>().of(
      Yup.object().shape({
        ref_delivery_mode_id: Yup.number().moreThan(0).required().label('Mode'),
        properties: Yup.string().required().label('Properties'),
        ref_refresh_frequency_id: Yup.number().moreThan(0).required().label('Refresh Frequency'),
        modesForView: Yup.array<DataProductSelectPageModesForViewModel[]>().of(
          Yup.object().shape({
            ref_delivery_mode_id: Yup.number().moreThan(0).required().label('Mode'),
            properties: Yup.string().required().label('Properties'),
          }),
        ),
      }),
    ),
    tagModel: Yup.array<DataProductSelectPageTagsModel[]>().of(
      Yup.object().shape({
        ref_tag_id: Yup.number().moreThan(0).required().label('Tag'),
      }),
    ),
  });

  const { handleSubmit, errors, touched, values, resetForm, setValues, validateForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      detailModel: initialValues.detailModel,
      payloadModel: initialValues.payloadModel,
      lineageModel: initialValues.lineageModel,
      modeModel: initialValues.modeModel,
      tagModel: initialValues.tagModel,
    },

    onSubmit: values => {
      const isLastTab = tabIndex == tabs.length - 1;
      if (isLastTab) {
        values.payloadModel = values.payloadModel.map((m, i) => ({ ...m, data_element_order: i }));
        values.modeModel = values.modeModel.map(m1 => ({
          ...m1,
          deliverymodeforview: m1.modesForView.map(m2 => ({
            ref_delivery_mode_id: m2.ref_delivery_mode_id,
            delivery_mode_properties: m2.properties,
          })),
          modesForView: [], // not needed, better delete
        }));
        const formDataValues = serialize(values, {
          indices: true,
          allowEmptyArrays: false,
          dotsForObjectNotation: true,
        });

        postAsync(formDataValues);
      }
    },
  });

  const validate = async () => {
    setLocalError(undefined);
    handleSubmit(); // will trigger errors

    const validationError = await validateForm(values);

    const tab = tabs[tabIndex];
    const tempError = validationError as any;
    const tempValues = values as any;
    const isLastTab = tabIndex == tabs.length - 1;

    if (!tempError[tab.model]) {
      if (tab.isArray && tempValues[tab.model].length == 0 && !isLastTab) {
        setLocalError({ title: tab.label, desc: 'must have atleast 1 entry' });
      } else {
        !isLastTab && setTabIndex(tabIndex + 1);
      }
    } else {
      setLocalError({ title: '', desc: 'Some field(s) are invalid' });
    }
  };

  const onChange = (field: keyof AddDataProductModel, data: ValueOf<AddDataProductModel>, isReset?: boolean) => {
    let tempIndex;
    switch (field) {
      case 'detailModel':
        tempIndex = 0;
        break;
      case 'payloadModel':
        tempIndex = 1;
        break;
      case 'lineageModel':
        tempIndex = 2;
        break;
      case 'modeModel':
        tempIndex = 3;
        break;
      case 'tagModel':
        tempIndex = 4;
        break;
    }
    if (isReset) {
      resetForm();
    } else {
      setValues({ ...values, [field]: data });
    }
    if (tabErrorIndex == tempIndex) {
      setTabErrorIndex(-1);
      setLocalError(undefined);
    }
  };

  const onDuplicateError = (index: number) => {
    setTabIndex(index);
    setTabErrorIndex(index);
  };

  useEffect(() => {
    sliceBreadcrumb(0, 2);
    addBreadcrumb({ label: 'Add Data Product', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (tabErrorIndex === -1) {
      setLocalError(undefined);
      window.scrollTo(0, 0);
    }
  }, [tabIndex]);

  useEffect(() => {
    const { data, isSuccess, isError, isLoading } = postDetail;
    const resData: any = data;
    if (isSuccess && resData?.isSuccess) {
      setLocalSuccess('Data Product successfully added.');
      setTimeout(() => {
        resetForm();
        setTabIndex(0);
        setLocalSuccess('');
      }, 3000);
    } else if (resData && !resData.isSuccess) {
      let tabError = '';
      if (resData.msg.includes('dp_data_product_payload')) {
        onDuplicateError(1);
        tabError = 'Cannot insert duplicate Data Product Payload Name';
      } else if (resData.msg.includes('dp_data_product_delivery_mode')) {
        onDuplicateError(3);
        tabError = 'Cannot insert duplicate Data Product Mode';
      } else if (resData.msg.includes('dp_tag_data_product')) {
        onDuplicateError(4);
        tabError = 'Cannot insert duplicate Data Product Tag';
      } else if (resData.msg.includes('dp_data_product')) {
        onDuplicateError(0);
        tabError = 'Cannot insert duplicate Data Product Name';
      }
      setLocalError({
        title: '',
        desc: tabError,
      });
    } else if (isError) {
      setLocalError({
        title: '',
        desc: 'There was an error processing your request, please try again later.',
      });
    } else {
      setLocalError(undefined);
    }

    if (isLoading) {
      setLocalSuccess('');
    }
  }, [postDetail]);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setLocalError(undefined);
    }
  }, [errors]);

  return (
    <VStack w="100%">
      <HStack>
        <HStack flex={1}>
          <Heading size="md" whiteSpace="nowrap">
            Add Data Product
          </Heading>
          {logonUser && (
            <ButtonGroup>
              <Button
                isLoading={postDetail.isLoading}
                variant="outline"
                colorScheme="brand.main"
                onClick={() => validate()}
                isDisabled={tabIndex !== tabs.length - 1}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        </HStack>
        {localError && (
          <Alert py={2} status="error">
            <AlertIcon />
            {localError.title && <AlertTitle>{tabs[tabIndex].label}</AlertTitle>}
            <AlertDescription>{localError.desc}</AlertDescription>
          </Alert>
        )}
        {localSuccess && (
          <Alert py={2} status="success">
            <AlertIcon />
            <AlertDescription>{localSuccess}</AlertDescription>
          </Alert>
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex}>
          <TabList>
            {tabs.map((m, i) => {
              const error = errors as any;
              return (
                <Tab key={i}>
                  {m.label}
                  {(error[m.model] && localError) || tabErrorIndex == i ? (
                    <Icon color="brand.error" ml={2} as={WarningIcon} />
                  ) : (
                    <></>
                  )}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tabs.map((tabPanel, i) => (
              <TabPanel key={i}>
                {tabPanel &&
                  values &&
                  createElement(tabPanel.component, {
                    onChangeData: onChange,
                    data: values,
                    isSubmitting: postDetail.isLoading,
                    errors: errors,
                    touched: touched,
                    title: tabPanel.label,
                    tabErrorIndex: tabErrorIndex,
                  })}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {tabs.length}
        </Text>
        <ButtonGroup>
          <Button size="sm" isDisabled={tabIndex == 0} onClick={() => setTabIndex(tabIndex - 1)}>
            Previous
          </Button>
          <Button
            size="sm"
            isDisabled={tabIndex == tabs.length - 1}
            onClick={() => {
              validate();
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default AddDataProduct;
