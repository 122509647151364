import { dmeApi } from './index';
import {
  UpdateUserRoleParamModel,
  UserRoleAppAccessByEmailModel,
  UserRoleAppAccessResultByEmailModel,
  UserRoleAppAccessResultModel,
  UserRoleModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    // Unused
    // getUserRoleListByRefUserId: builder.query<UserRoleModel[], number>({
    //   query: ref_user_id => `/userrole/user/${ref_user_id}`,
    //   providesTags: ['UserRole'],
    // }),

    getUserRoleListByEmail: builder.query<UserRoleModel[], string>({
      query: email => `/userrole/email/${email}`,
      providesTags: ['UserRole'],
    }),

    getUserRoleListByRefUserIdV2: builder.query<UserRoleModel[], number>({
      query: ref_user_id => `/userrole/role/ref_user_id/${ref_user_id}`,
    }),

    getUserRoleAccessListByRefUserId: builder.query<UserRoleAppAccessResultModel, number>({
      query: ref_user_id => `/userrole/appaccess/ref_user_id/${ref_user_id}`,
    }),

    getUserRoleAccessListByUserEmail: builder.query<UserRoleAppAccessByEmailModel[], string>({
      query: email => `/userrole/appaccess/email/${email}`,
    }),

    putUserRole: builder.mutation<void, { ref_user_id: number; model: UpdateUserRoleParamModel[] }>({
      query: params => ({
        url: `/userrole/user/${params.ref_user_id}`,
        method: 'PUT',
        body: params.model,
      }),
      invalidatesTags: ['UserRole'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserRoleListByEmailQuery,
  useGetUserRoleListByRefUserIdV2Query,
  useGetUserRoleAccessListByRefUserIdQuery,
  useGetUserRoleAccessListByUserEmailQuery,
  usePutUserRoleMutation,
} = extendedApi;
