import { FC, ReactNode, useEffect } from 'react';
import { Navigate, Route, Routes, To, useLocation, useNavigate } from 'react-router-dom';
import { addVisitedUrl } from '../app/slices/appSlice';
import { useAppDispatch, useAppSelector } from '../app/state/hooks';
import AppAuth from '../features/AppAuth';
import Claims from './Claims';
import DataLiteracy from './DataLiteracy';
import ExploreDataProducts from './DataProducts/ExploreDataProducts';
import DataProductHome from './DataProducts/ExploreDataProducts/Home';
import DataProductResult from './DataProducts/ExploreDataProducts/Result';
import DataProductSelected from './DataProducts/ExploreDataProducts/Selected';
import MyAccessDetail from './DataProducts/MyAccess/Detail';
import MyAccessResult from './DataProducts/MyAccess/Result';
import MyRoles from './Roles';

import { AppAccess, AppAccessName, AppAuthRequiredAppAccess } from '../app/constants/appAccesses';
import CustomRequestsSelected from '../features/CustomRequest/Selected';
import AccessApproval from './Admin/AccessApproval';
import CustomRequestsApproval from './Admin/CustomRequestsApproval';
import ReviewFeedback from './Admin/ReviewFeedback';
import { User } from './Admin/User';
import UserSelected from './Admin/User/Selected';
import CreateCustomRequest from './CustomRequests/CreateCustomRequest';
import MyCustomRequests from './CustomRequests/MyCustomRequests';
import MyAccess from './DataProducts/MyAccess';
import Domain from './Domain';
import AddDomain from './Domain/AddDomain';
import EditDomain from './Domain/EditDomain';
import ExploreDomain from './Domain/ExploreDomain';
import EditorDataProducts from './EditorDataProducts';
import AddDataProduct from './EditorDataProducts/AddDataProduct';
import EditDataProducts from './EditorDataProducts/EditDataProduct';
import EditDataProductSelected from './EditorDataProducts/EditDataProduct/Selected';
import LoreLanguage from './Glossary';
import AddLoreLanguage from './Glossary/AddGlossary';
import EditLoreLanguage from './Glossary/EditGlossary';
import EditLoreLanguageSelected from './Glossary/EditGlossary/Selected';
import LoreLanguageResult from './Glossary/ExploreGlossary';
import LoreLanguageSelected from './Glossary/ExploreGlossary/Selected';
import GovernanceOffice from './GovernanceOffice';
import ExploreDataSharingRequests from './GovernanceOffice/ExploreDataSharingRequests';
import ExternalDataSharingRequestsApproval from './GovernanceOffice/ExternalDataSharingRequestsApproval';
import MyExternalDataSharingRequests from './GovernanceOffice/MyExternalDataSharingRequests';
import MyExternalDataSharingRequestsCreateRequest from './GovernanceOffice/MyExternalDataSharingRequests/CreateRequest';
import GovernanceOfficeSelected from './GovernanceOffice/Selected';
import LoreLink from './ImportantLink';
import AddLoreLink from './ImportantLink/AddImportantLink';
import EditLoreLink from './ImportantLink/EditImportantLink';
import EditLoreLinkSelected from './ImportantLink/EditImportantLink/Selected';
import LoreLinkResult from './ImportantLink/ExploreImportantLink';
import LoreLinkSelected from './ImportantLink/ExploreImportantLink/Selected';
import Infoweave from './Infoweave';
import PlatServices from './PlatformServices';
import ExplorePlatformServices from './PlatformServices/ExplorePlatformServices';
// import MyServiceRequests from './PlatformServices/MyServiceRequests';
import ReleaseNotes from './ReleaseNotes';
import ReportHub from './ReportHub';
import ReportHubAddPortfolio from './ReportHub/AddPortfolio';
import ReportHubExplorePortfolios from './ReportHub/ExplorePortfolio';
import ReportHubExplorePortfoliosSelected from './ReportHub/ExplorePortfolio/Selected';
import ReportHubMyAccess from './ReportHub/MyAccess';
import ReportHubMyAccessSelected from './ReportHub/MyAccess/Selected';
import AddRole from './Roles/AddRole';
import AppAccessByRoles from './Roles/AppAccessByRoles';
import AppAccessByRolesSelected from './Roles/AppAccessByRoles/Selected';
import ExploreRolesDetail from './Roles/ExploreRoles/Details';
import ExploreRolesResult from './Roles/ExploreRoles/Result';
import MyRolesResult from './Roles/MyRoles/Result';
import MyRoleSelectedDetail from './Roles/MyRoles/Selected';
import Tags from './Tags';
import AddTags from './Tags/AddTags';
import ExploreTags from './Tags/ExploreTags';
import ExploreTagsSelected from './Tags/ExploreTags/Selected';
import UnAuthorized from './UnAuthorized';
// import PlatformServicesSelected from './PlatformServices/MyServiceRequests/Selected';
import ExplorePlatformServicesCreateRequest from './PlatformServices/ExplorePlatformServices/CreateRequest';
import CreateEmployeeOnboardingRequest from './EmployeeOnboarding/CreateEmployeeOnboardingRequest';
import MyEmployeeOnboardingRequest from './EmployeeOnboarding/MyEmployeeOnboardingRequest';
import EmployeeOnboarding from './EmployeeOnboarding';
import EmployeeOnboardingRequestApproval from './Admin/EORequestsApproval';
import Offboarding from './EmployeeOnboarding/Offboarding';
import Groups from './Groups';
import Squads from './Groups/Squads';

type Props = {};

const Routing = (props: Props) => {
  const isProd = process.env.REACT_APP_ENV === 'PROD';
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(addVisitedUrl(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Infoweave />} />
        <Route path="/home" element={<Infoweave />} />
        <Route
          path="/data-literacy"
          element={<ProtectedRoute Component={DataLiteracy} requiredAppAccess={AppAccess.DataLiteracy} />}
        />

        <Route path="/data-products" element={<Navigate to="explore-data-products" replace />} />
        <Route
          path="/data-products/explore-data-products"
          element={<ProtectedRoute Component={ExploreDataProducts} requiredAppAccess={AppAccess.ExploreDataProducts} />}
        >
          <Route
            index
            element={<ProtectedRoute Component={DataProductHome} requiredAppAccess={AppAccess.DataProducts} />}
          />
          <Route path="results" element={<DataProductResult />} />
          <Route
            path="selected/domain/:domain/data-product/:dataProduct/id/:dataProductId/:selectedTab"
            element={<DataProductSelected />}
          ></Route>
        </Route>

        <Route
          path="/data-products/my-access"
          element={<ProtectedRoute Component={MyAccess} requiredAppAccess={AppAccess.DataProductMyAccess} />}
        >
          <Route index element={<Navigate to="result" />} />
          <Route path="result" element={<MyAccessResult />} />
          <Route path="detail/accessmethod/:accessMethod/requestid/:requestId/">
            <Route index element={<MyAccessDetail />} />
            <Route path=":myAcessDetailTab" element={<MyAccessDetail />} />
          </Route>
        </Route>

        <Route
          path="/platform-services"
          element={<ProtectedRoute Component={PlatServices} requiredAppAccess={AppAccess.PlatformServices} />}
        >
          <Route index element={<Navigate to="explore-platform-services" />} />
          <Route
            path="explore-platform-services"
            element={
              <ProtectedRoute Component={ExplorePlatformServices} requiredAppAccess={AppAccess.PlatformServices} />
            }
          />
            <Route
            path="explore-platform-services/create-request"
            element={
              <ProtectedRoute
                Component={ExplorePlatformServicesCreateRequest}
                requiredAppAccess={AppAccess.PlatformServices}
              />
            }
          />
          {/* <Route index element={<Navigate to="my-requests" />} />
          <Route
            path="my-requests"
            element={
              <ProtectedRoute Component={MyServiceRequests} requiredAppAccess={AppAccess.PlatformServices} />
            }
          />
          <Route
            path="my-requests/selected/id/:psRequestId/:selectedTab"
            element={
              <ProtectedRoute Component={PlatformServicesSelected} requiredAppAccess={AppAccess.PlatformServices} />
            }
          /> */}
        </Route>

        <Route path="/custom-requests">
          <Route
            index
            element={
              <Redirector
                replace
                navigations={[
                  { navigateTo: 'create-request', requiredAppAccess: AppAccess.CreateCustomRequest },
                  { navigateTo: 'my-requests', requiredAppAccess: AppAccess.MyCustomRequest },
                ]}
              />
            }
          />
          <Route
            path="create-request"
            element={
              <ProtectedRoute
                Component={CreateCustomRequest}
                requiredAppAccess={AppAccess.CreateCustomRequest}
                navigateTo="/custom-requests/my-requests"
              />
            }
          />
          <Route
            path="my-requests"
            element={<ProtectedRoute Component={MyCustomRequests} requiredAppAccess={AppAccess.MyCustomRequest} />}
          />
          <Route
            path="my-requests/selected/id/:customRequestId/:selectedTab"
            element={
              <ProtectedRoute
                Component={() => CustomRequestsSelected({ page: 'custom-requests' })}
                requiredAppAccess={AppAccess.MyCustomRequest}
              />
            }
          />
        </Route>

        <Route
          path="/governance-office"
          element={<ProtectedRoute Component={GovernanceOffice} requiredAppAccess={AppAccess.GovernanceOffice} />}
        >
          <Route index element={<Navigate to="my-external-data-sharing-requests" />} />
          <Route
            path="my-external-data-sharing-requests"
            element={
              <ProtectedRoute Component={MyExternalDataSharingRequests} requiredAppAccess={AppAccess.MyEdsRequests} />
            }
          />
          <Route
            path="my-external-data-sharing-requests/selected/id/:goEdsId/:selectedTab"
            element={
              <ProtectedRoute Component={GovernanceOfficeSelected} requiredAppAccess={AppAccess.MyEdsRequests} />
            }
          />
          <Route
            path="my-external-data-sharing-requests/create-request"
            element={
              <ProtectedRoute
                Component={MyExternalDataSharingRequestsCreateRequest}
                requiredAppAccess={AppAccess.MyEdsRequestsWrite}
              />
            }
          />
          <Route
            path="explore-data-sharing-requests"
            element={
              <ProtectedRoute Component={ExploreDataSharingRequests} requiredAppAccess={AppAccess.ExploreEdsRequests} />
            }
          />
          <Route
            path="explore-data-sharing-requests/selected/id/:goEdsId/:selectedTab"
            element={
              <ProtectedRoute Component={GovernanceOfficeSelected} requiredAppAccess={AppAccess.ExploreEdsRequests} />
            }
          />
          <Route
            path="external-data-sharing-requests-approval"
            element={
              <ProtectedRoute
                Component={ExternalDataSharingRequestsApproval}
                requiredAppAccess={AppAccess.EdsRequestsApproval}
              />
            }
          />
        </Route>

        <Route path="/claims" element={<Claims />} />

        <Route path="/admin">
          {/* <Route index element={<Navigate to="users" replace />} /> */}
          <Route
            index
            element={
              <Redirector
                replace
                navigations={[
                  { navigateTo: 'users', requiredAppAccess: AppAccess.AdminUsers },
                  { navigateTo: 'review-feedback', requiredAppAccess: AppAccess.AdminReviewFeedback },
                  { navigateTo: 'access-approval', requiredAppAccess: AppAccess.AdminAccessApproval },
                  { navigateTo: 'custom-requests-approval', requiredAppAccess: AppAccess.CustomRequestApproval },
                  { navigateTo: 'employee-onboarding-requests', requiredAppAccess: AppAccess.EORequestApproval },
                ]}
              />
            }
          />
          <Route
            path="users"
            element={
              <ProtectedRoute
                Component={User}
                requiredAppAccess={AppAccess.AdminUsers}
                navigateTo="/admin/review-feedback"
              />
            }
          />
          <Route
            path="users/selected/id/:id/:selectedTab"
            element={
              <ProtectedRoute
                Component={UserSelected}
                requiredAppAccess={AppAccess.AdminUsers}
                navigateTo="/admin/review-feedback"
              />
            }
          />
          <Route
            path="review-feedback"
            element={
              <ProtectedRoute
                Component={ReviewFeedback}
                requiredAppAccess={AppAccess.AdminReviewFeedback}
                navigateTo="/admin/access-approval"
              />
            }
          />
          <Route path="access-approval" element={<Navigate to="role-access" />} />
          <Route
            path="access-approval/:selectedTab"
            element={
              <ProtectedRoute
                Component={AccessApproval}
                requiredAppAccess={AppAccess.AdminAccessApproval}
                navigateTo="/admin/custom-requests-approval"
              />
            }
          />
          <Route path="custom-requests-approval" element={<Navigate to="new" replace />} />
          <Route
            path="custom-requests-approval/:statusTab"
            element={
              <ProtectedRoute 
                Component={CustomRequestsApproval} 
                requiredAppAccess={AppAccess.CustomRequestApproval} 
                navigateTo="/admin/employee-onboarding-requests"
                />
            }
          />
          <Route
            path="custom-requests-approval/:statusTab/selected/id/:customRequestId/:selectedTab"
            element={
              <ProtectedRoute
                Component={() => CustomRequestsSelected({ page: 'admin' })}
                requiredAppAccess={AppAccess.CustomRequestApproval}
              />
            }
          />

          <Route
            path='employee-onboarding-offboarding-requests'
            element={
              <ProtectedRoute
                Component={EmployeeOnboardingRequestApproval}
                requiredAppAccess={AppAccess.EORequestApproval}
              />
            }
          />
        </Route>

        <Route path="/tags" element={<ProtectedRoute Component={Tags} requiredAppAccess={AppAccess.Tags} />}>
          <Route index element={<Navigate to="explore-tags" replace />} />
          <Route
            path="explore-tags"
            element={<ProtectedRoute Component={ExploreTags} requiredAppAccess={AppAccess.ExploreTags} />}
          />
          <Route
            path="explore-tags/id/:id/:selectedTab"
            element={<ProtectedRoute Component={ExploreTagsSelected} requiredAppAccess={AppAccess.ExploreTags} />}
          />
          <Route
            path="add-tag"
            element={<ProtectedRoute Component={AddTags} requiredAppAccess={AppAccess.AddTags} />}
          />
        </Route>

        <Route path="/roles" element={<MyRoles />}>
          <Route index element={<Navigate to="explore-roles" replace />} />
          <Route path="explore-roles" element={<ExploreRolesResult />} />
          <Route path="explore-roles/detail/id/:id" element={<ExploreRolesDetail />} />
          <Route
            path="app-access-by-roles"
            element={<ProtectedRoute Component={AppAccessByRoles} requiredAppAccess={AppAccess.AppAccessByRoles} />}
          />
          <Route
            path="app-access-by-roles/selected/id/:id/:selectedTab"
            element={
              <ProtectedRoute Component={AppAccessByRolesSelected} requiredAppAccess={AppAccess.AppAccessByRoles} />
            }
          />
          <Route
            path="add-role"
            element={<ProtectedRoute Component={AddRole} requiredAppAccess={AppAccess.AddRole} />}
          />
          <Route path="my-roles" element={<MyRolesResult />} />
          <Route path="my-roles/selected/id/:id/:selectedTab" element={<MyRoleSelectedDetail />} />
        </Route>

        <Route
          path="/glossary"
          element={<ProtectedRoute Component={LoreLanguage} requiredAppAccess={AppAccess.LoreLanguage} />}
        >
          <Route index element={<Navigate to="explore-glossary" />}></Route>
          <Route path="explore-glossary" element={<Navigate to="results" />} />
          <Route path="explore-glossary/results" element={<LoreLanguageResult />} />
          <Route path="explore-glossary/results/selected/id/:id/:selectedTab" element={<LoreLanguageSelected />} />
          <Route path="edit-glossary" element={<EditLoreLanguage />}></Route>
          <Route
            path="edit-glossary/selected/id/:id/:selectedTab"
            element={
              <ProtectedRoute Component={EditLoreLanguageSelected} requiredAppAccess={AppAccess.EditLoreLanguage} />
            }
          ></Route>
          <Route
            path="add-glossary"
            element={<ProtectedRoute Component={AddLoreLanguage} requiredAppAccess={AppAccess.AddLoreLanguage} />}
          ></Route>
        </Route>

        <Route
          path="/important-link"
          element={<ProtectedRoute Component={LoreLink} requiredAppAccess={AppAccess.LoreLink} />}
        >
          <Route index element={<Navigate to="explore-important-link" />}></Route>
          <Route path="explore-important-link" element={<Navigate to="results" />} />
          <Route path="explore-important-link/results" element={<LoreLinkResult />} />
          <Route path="explore-important-link/results/selected/id/:id/:selectedTab" element={<LoreLinkSelected />} />
          <Route path="edit-important-link" element={<EditLoreLink />}></Route>
          <Route
            path="edit-important-link/selected/id/:id/:selectedTab"
            element={<ProtectedRoute Component={EditLoreLinkSelected} requiredAppAccess={AppAccess.EditLoreLink} />}
          ></Route>
          <Route
            path="add-important-link"
            element={<ProtectedRoute Component={AddLoreLink} requiredAppAccess={AppAccess.AddLoreLink} />}
          ></Route>
        </Route>

        <Route
          path="/editor-data-products"
          element={<ProtectedRoute Component={EditorDataProducts} requiredAppAccess={AppAccess.EditorDataProducts} />}
        >
          <Route index element={<Navigate to="edit-data-products" replace />} />
          <Route path="edit-data-products" element={<EditDataProducts />}></Route>
          <Route
            path="edit-data-products/selected/domain/:domain/data-product/:dataProduct/id/:dataProductId/:selectedTab"
            element={<EditDataProductSelected />}
          ></Route>

          <Route
            path="add-data-products"
            element={<ProtectedRoute Component={AddDataProduct} requiredAppAccess={AppAccess.AddDataProducts} />}
          ></Route>
        </Route>

        <Route
          path="/report-hub"
          element={<ProtectedRoute Component={ReportHub} requiredAppAccess={AppAccess.ReportHub} />}
        >
          <Route index element={<Navigate to="explore-portfolios" replace />} />
          <Route
            path="explore-portfolios"
            element={
              <ProtectedRoute
                Component={ReportHubExplorePortfolios}
                requiredAppAccess={AppAccess.ExploreReportPortfolios}
              />
            }
          />
          <Route
            path="explore-portfolios/selected/id/:rhpId/:selectedTab"
            element={
              <ProtectedRoute
                Component={ReportHubExplorePortfoliosSelected}
                requiredAppAccess={AppAccess.ExploreReportPortfolios}
              />
            }
          />
          {/* <Route
            path="explore-portfolios/selected/id/:rhpId/:selectedTab/view"
            element={
              <ProtectedRoute
                Component={ReportHubExplorePortfoliosSelectedViewer}
                requiredAppAccess={AppAccess.ExploreReportPortfolios}
              />
            }
          />
          <Route
            path="explore-portfolios/selected/id/:rhpId/:selectedTab/view/:reportId"
            element={
              <ProtectedRoute
                Component={ReportHubExplorePortfoliosSelectedViewer}
                requiredAppAccess={AppAccess.ExploreReportPortfolios}
              />
            }
          /> */}
          <Route
            path="add-portfolio"
            element={
              <ProtectedRoute Component={ReportHubAddPortfolio} requiredAppAccess={AppAccess.AddReportPortfolios} />
            }
          />
          <Route
            path="my-access"
            element={
              <ProtectedRoute Component={ReportHubMyAccess} requiredAppAccess={AppAccess.ReportPortfoliosMyAccess} />
            }
          />
          <Route
            path="my-access/selected/access-method/:accessMethod/id/:requirestId/:selectedTab"
            element={
              <ProtectedRoute
                Component={ReportHubMyAccessSelected}
                requiredAppAccess={AppAccess.ReportPortfoliosMyAccess}
              />
            }
          />
        </Route>

        <Route path="/employee-onboarding" element={<ProtectedRoute Component={EmployeeOnboarding} requiredAppAccess={AppAccess.EmployeeOnboarding} />}>
          <Route index element={<Navigate to="create-eo-request" replace />} />
          <Route
            path="create-eo-request"
            element={<ProtectedRoute Component={CreateEmployeeOnboardingRequest} requiredAppAccess={AppAccess.CreateEORequest} />}
          />
          <Route
            path="my-eo-requests"
            element={<ProtectedRoute Component={MyEmployeeOnboardingRequest} requiredAppAccess={AppAccess.MyEORequest} />}
          />
          <Route
            path="my-eo-requests/id/:id/:selectedTab"
            element={<ProtectedRoute Component={MyEmployeeOnboardingRequest} requiredAppAccess={AppAccess.MyEORequest} />}
          />
          <Route
            path="offboarding"
            element={<ProtectedRoute Component={Offboarding} requiredAppAccess={AppAccess.Offboarding} />}
          />
        </Route>

        {/* To be removed domain from DME */}
        <Route path="/domain" element={<ProtectedRoute Component={Domain} requiredAppAccess={AppAccess.Domain} />}>
          <Route index element={<Navigate to="explore-domain" replace />} />
          <Route
            path="explore-domain"
            element={<ProtectedRoute Component={ExploreDomain} requiredAppAccess={AppAccess.ExploreDomain} />}
          />
          <Route
            path="add-domain"
            element={<ProtectedRoute Component={AddDomain} requiredAppAccess={AppAccess.AddDomain} />}
          />
          <Route
            path="edit-domain"
            element={<ProtectedRoute Component={EditDomain} requiredAppAccess={AppAccess.EditDomain} />}
          />
        </Route>

        <Route path="/groups" element={<ProtectedRoute Component={Groups} requiredAppAccess={AppAccess.Groups} />}>
          <Route index element={<Navigate to="squads" replace />} />
          <Route
            path="squads"
            element={<ProtectedRoute Component={Squads} requiredAppAccess={AppAccess.Squads} />}
          />
        </Route> 

        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />
        <Route path="*" element={<strong>Page not found</strong>} />
      </Routes>
    </>
  );
};

export default Routing;

type RedirectorProps = {
  navigations: { navigateTo: string; requiredAppAccess: Array<AppAccessName> }[];
  replace?: boolean;
};
const Redirector = ({ navigations, replace }: RedirectorProps) => {
  const navigate = useNavigate();
  const { currentUserUserRole } = useAppSelector(s => s.userRole);

  useEffect(() => {
    if (currentUserUserRole) {
      if (navigations.length < 1) navigate('/unauthorized'); // make sure to add a navigation

      for (let i = 0; i < navigations.length; i++) {
        const { navigateTo, requiredAppAccess } = navigations[i];
        if (currentUserUserRole.some(s => requiredAppAccess.includes(s.app_access_name as any) && s.access_flag)) {
          return navigate(navigateTo, { replace });
        }
      }
      return navigate('/unauthorized');
    }
  }, [navigate, currentUserUserRole, navigations]);

  return <></>;
};

type IProtectedRouteProps = {
  children?: ReactNode;
  Component: React.ComponentType;
  requiredAppAccess: AppAuthRequiredAppAccess;
  navigateTo?: To;
};
const ProtectedRoute: FC<IProtectedRouteProps> = ({ Component, requiredAppAccess, navigateTo }): any => {
  const navigate = useNavigate();

  const onUnAuthorized = () => {
    navigate(navigateTo ?? '/unauthorized');
  };
  return (
    <AppAuth requiredAppAccess={requiredAppAccess} onUnAuthorized={onUnAuthorized}>
      <Component />
    </AppAuth>
  );
};
