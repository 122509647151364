import { Box, Text, Divider, Heading, HStack, VStack, Skeleton, Stack } from '@chakra-ui/react';
import CustomTable from '../../../components/CustomTable';
import { RefSquadModel } from '../../../app/services/dme/api/types';
import { useGetRefSquadListQuery } from '../../../app/services/dme/api/refSquad';

type Props = {};

const Squads = (props: Props) => {
  const { data, isLoading, isFetching } = useGetRefSquadListQuery();
  const Header = [
    {
      Header: 'Squad',
      accessor: 'squad_name',
      styles: {width: '30%', whiteSpace: 'initial', wordBreak: 'break-word' },
    },
    {
      Header: 'Description',
      accessor: 'squad_desc',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: RefSquadModel } }) => {
        return (
          <Box maxW="800px">
            <Text noOfLines={3} title={original.squad_desc}>
              {original.squad_desc}
            </Text>
          </Box>
        );
      },
    },
  ];

  return (
    <VStack>
      <HStack>
        <Heading size="md">Squads</Heading>
      </HStack>
      <Divider />
      <HStack>
        {isLoading ? (
          <Stack mt={1}>
            {[...Array(8)].map((m, i) => (
              <Skeleton key={i} height="18px" />
            ))}
          </Stack>
        ) : (
          <CustomTable
            variant="table"
            headers={Header}
            isLoading={isLoading}
            isFetching={isFetching}
            data={data || []}
            pageCount={0}
            pageSize={data ? data.length : 0}
            totalRecords={data ? data.length : 0}
            search=""
            onPageChange={() => {}}
            onPageSizeChange={() => {}}
            onPageSearch={() => {}}
            onSort={() => {}}
            hidePagination={true}
            tableSort={true}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default Squads;
