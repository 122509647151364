import { Alert, Badge, HStack, IconButton, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { CgAttachment, CgClose } from 'react-icons/cg';
import { Validator, useFilePicker } from 'use-file-picker';
import { fileConfig } from '../../../../../../../app/constants';

type Props = {
  onChange: (files: File[]) => void;
};
type ForwardProps = {
  resetFilePicker: () => void;
};

const ReportHubCommentAttachmentUpload = forwardRef<ForwardProps, Props>(({ onChange }, ref) => {
  const [openFileSelector, filePicker] = useFilePicker({
    multiple: true,
    accept: fileConfig.acceptedFiles.map(m => '.' + m),
    limitFilesConfig: { max: parseInt(fileConfig.maxFileCount || '0') },
    maxFileSize: parseInt(fileConfig.maxFileSize || '0'),
    readFilesContent: false,
    validators: [fileTypeValidator],
  });

  useEffect(() => {
    onChange(filePicker.plainFiles);
  }, [filePicker]);

  useImperativeHandle(ref, () => ({
    resetFilePicker() {
      filePicker.clear();
    },
  }));

  return (
    <>
      {filePicker.plainFiles.length > 0 && (
        <HStack>
          <Wrap
            align="center"
            p={2}
            border="1px"
            borderColor="chakra-border-color"
            borderRadius={5}
            maxH="75px"
            overflowY="auto"
          >
            {filePicker.plainFiles.map((m, i) => (
              <WrapItem key={i}>
                <Badge variant="outline" textTransform="none" title={m.name}>
                  <Text noOfLines={1} maxW="225px" whiteSpace="break-spaces">
                    {m.name}
                  </Text>
                </Badge>
              </WrapItem>
            ))}
          </Wrap>
          <IconButton
            icon={<CgClose />}
            aria-label="clear attachment"
            variant="ghost"
            onClick={filePicker.clear}
            size="sm"
          />
        </HStack>
      )}
      {filePicker.errors.length > 0 && (
        <Alert status="error" fontSize="xs" py={1} px={2}>
          {((filePicker.errors[0] || {}) as any).fileTypeError}
        </Alert>
      )}

      <IconButton
        title="Add Attachment"
        aria-label="add attachment"
        onClick={openFileSelector}
        icon={<CgAttachment />}
        variant="solid"
        size="sm"
      />
    </>
  );
});

export default ReportHubCommentAttachmentUpload;

const fileTypeValidator: Validator = {
  validateBeforeParsing: async (config, plainFiles) =>
    new Promise((res, rej) => {
      const invalidFiles = plainFiles.filter(f => {
        const selectedFileType = (f.name || '').split('.').pop() || '';
        return !fileConfig.acceptedFiles.includes(selectedFileType);
      });
      if (invalidFiles.length) {
        return rej({
          fileTypeError: `File type${invalidFiles.length > 1 ? 's are' : ' is'}  not allowed: ${invalidFiles
            .map(m => m.name)
            .join(', ')}`,
        });
      }
      if (config.limitFilesConfig?.max && plainFiles.length > config.limitFilesConfig.max) {
        return rej({
          fileTypeError: `The number of attachment exceeds its limit (10)`,
        });
      }

      const overSizedFiles = plainFiles.filter(f => {
        return f.size > parseInt(fileConfig.maxFileSize || '0') * 1000000;
      });
      if (overSizedFiles.length) {
        return rej({
          fileTypeError: `${
            overSizedFiles.length > 1 ? 'These files exceed' : 'This file exceeds'
          }  the maximum file size limit: ${overSizedFiles.map(m => m.name).join(', ')}`,
        });
      }
      return res();
    }),
  validateAfterParsing: async (config, file, reader) =>
    new Promise((res, rej) => {
      res();
    }),
};
