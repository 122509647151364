import { Flex, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { DataProductMyAccessStatusModel, MyRoleDetailStatusTabModel } from '../../../../app/services/dme/api/types';
import { FiCircle } from 'react-icons/fi';
import { FaArrowCircleRight, FaCircle } from 'react-icons/fa';

type Props = {
  data: MyRoleDetailStatusTabModel[];
};

const MyRoleDetailStatusTab = ({ data: status }: Props) => {
  const [data, setData] = useState<MyRoleDetailStatusTabModel[]>([]);
  const [highlightArrowIndex, setHighlightArrowIndex] = useState<number>();
  useEffect(() => {
    const sorted = [...status].sort((a, b) => {
      if (a.status_tracking_order > b.status_tracking_order) {
        return 1;
      }
      if (b.status_tracking_order > a.status_tracking_order) {
        return -1;
      }
      return 0;
    });
    const arrowIndex = sorted.findIndex(f => f.status_highlight_arrow);
    setHighlightArrowIndex(arrowIndex);
    setData(sorted);
  }, [status]);

  const getStatusIcon = (row: MyRoleDetailStatusTabModel, index: number) => {
    let color = 'gray.400';
    let icon = FiCircle;
    if (highlightArrowIndex !== undefined && highlightArrowIndex >= 0 && index <= highlightArrowIndex) {
      color = 'brandColors.primary.green.300';
      icon = FaCircle;
    }

    if (row.status_highlight_arrow) {
      return <Icon as={FaArrowCircleRight} color={color} />;
    }

    return <Icon as={icon} color={color} />;
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">Status:</Heading>
        <Heading size="sm" fontWeight="normal">
          {data && data?.length > 0 && data[0].current_status}
        </Heading>
      </HStack>
      <VStack spacing={4}>
        {data &&
          data.slice(1).map((m, i) => (
            <Flex direction="row" alignItems="center" key={i}>
              {getStatusIcon(m, ++i)}
              <Text ml={3} w={81}>
                {m.status_tracking}
              </Text>
              <Text ml={1}>:</Text>
              <Text ml={2}>{convertUtcToLocal(m.status_timestamp)}</Text>
            </Flex>
          ))}
      </VStack>
    </VStack>
  );
};

export default MyRoleDetailStatusTab;
