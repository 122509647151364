import { dmeApi } from './index';
import { ReleaseNoteModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getReleaseNoteList: builder.query<ReleaseNoteModel[], void>({
      query: () => `/releasenotes`,
    }),

    getReleaseNoteLatestVersion: builder.query<string, void>({
      query: () => '/releasenotes/latest_version',
      transformResponse: (response: { dme_latest_version: string }) => {
        return response.dme_latest_version;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetReleaseNoteListQuery, useGetReleaseNoteLatestVersionQuery } = extendedApi;
