import { FunctionComponent, useEffect, useState, createElement } from 'react';
import UserSelectedAppAccessTab from './AppAccess';
import UserSelectedDataProductsTab from './DataProducts';
import UserSelectedDetailsTab from './Details';
import UserSelectedRolesTab from './Roles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { Box, Divider, HStack, Heading, Skeleton, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { useGetRefUserListDetailsQuery } from '../../../../app/services/dme/api/user';

type Props = {};
const tabs: {
  label: string;
  path: string;
  component: FunctionComponent;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: UserSelectedDetailsTab,
  },
  {
    label: 'Roles',
    path: 'roles',
    component: UserSelectedRolesTab,
  },
  {
    label: 'App Access',
    path: 'app-access',
    component: UserSelectedAppAccessTab,
  },
  {
    label: 'Data Products',
    path: 'data-products',
    component: UserSelectedDataProductsTab,
  },
];

const UserSelected: React.FC<Props> = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number | undefined>();

  const id: number = parseInt(params?.id || '');
  const { data, isLoading, isFetching } = useGetRefUserListDetailsQuery(id, { skip: !id });
  const name = data?.last_name ? `${data?.last_name}, ${data?.first_name}` : data?.first_name;

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  useEffect(() => {
    addBreadcrumb({ label: 'Users', path: '/admin/users' }, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  return (
    <VStack w="100%">
      <HStack>
        <Heading size="md" h="24px">
          {isLoading || isFetching ? <Skeleton height="20px" w="60" /> : name}
        </Heading>
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>{tabs[tabIndex] && createElement(tabs[tabIndex].component)}</Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default UserSelected;
