import { VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = {};

const LoreLanguage = (props: Props) => {
  return (
    <VStack w="full">
      <Outlet />
    </VStack>
  );
};

export default LoreLanguage;
