import { Box, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { FC, createElement, useState } from 'react';
import { AddReportHubPortfolioModel } from '../../../app/services/dme/api/types';
import { initialValuesReportPortfolio } from '../helpers';
import ReportHubAddPortfolioDetails from './Details';
import ReportHubAddPortfolioReports from './Reports';
import ReportHubAddPortfolioTags from './Tags';

type Props = {};

export const addReportHubPortfolioTabs = [
  { label: 'Details', component: ReportHubAddPortfolioDetails },
  { label: 'Reports', component: ReportHubAddPortfolioReports },
  { label: 'Tags', component: ReportHubAddPortfolioTags },
];

export type ReportHubAddPortfolioTabProps = {
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  data: AddReportHubPortfolioModel;
  setData: React.Dispatch<React.SetStateAction<AddReportHubPortfolioModel>>;
};

const ReportHubAddPortfolio: FC<Props> = props => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [data, setData] = useState<AddReportHubPortfolioModel>(initialValuesReportPortfolio);

  return (
    <Tabs index={tabIndex}>
      <TabList>
        {addReportHubPortfolioTabs.map((m, i) => (
          <Tab key={i}>{m.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        <Box p={3}>
          {createElement<ReportHubAddPortfolioTabProps>(addReportHubPortfolioTabs[tabIndex].component, {
            tabIndex,
            setTabIndex,
            data,
            setData,
          })}
        </Box>
      </TabPanels>
    </Tabs>
  );
};

export default ReportHubAddPortfolio;
