import { Alert, Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetMyRolesByRefUserIdQuery } from '../../../../app/services/dme/api/myRoles';
import {
  MyRoleDataset1Model,
  MyRoleDataset2Model,
  MyRoleFilterModel,
  MyRoleModel,
} from '../../../../app/services/dme/api/types';
import { FilterItem } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import FilterPane from '../../FilterPane';
import ResultData from './ResultData';

type Props = {};

const MyRoleResult = (props: Props) => {
  const location = useLocation();
  const { logonUser } = useAppSelector(s => s.user);

  const [isResultLoading, setIsResultLoading] = useState(true);
  const { data, isLoading } = useGetMyRolesByRefUserIdQuery(logonUser?.ref_user_id || 0, {
    skip: !logonUser,
  });

  const [mainData, setmainData] = useState<MyRoleModel | null>(null);
  const [filteredData, setFilteredData] = useState<MyRoleDataset1Model[] | null>(null);

  const [filterData, setFilterData] = useState<Array<MyRoleDataset2Model> | null>(null);

  const onFilterChange = (checkedFilters: MyRoleFilterModel[]) => {
    setIsResultLoading(true);
    if (checkedFilters.length > 0) {
      let filtered3rdData = mainData?.third_dataset_model?.filter(d =>
        checkedFilters.some(c => c.filter_header === d.filter_header && c.filter_detail === d.filter_detail),
      );

      const filterObj: Array<FilterItem> = [];
      filtered3rdData?.forEach(value => {
        const existingFilterIndex = filterObj.findIndex(f => f.filter_header === value.filter_header.toLowerCase());
        if (existingFilterIndex > -1) {
          filterObj[existingFilterIndex].ids = [
            ...filterObj[existingFilterIndex].ids,
            value.dme_role_request_user_role_id,
          ];
        } else {
          filterObj.push({
            filter_header: value.filter_header.toLowerCase(),
            ids: [value.dme_role_request_user_role_id],
          });
        }
      });

      let ids: Array<number> = filterObj ? filterObj.map(m => m.ids)[0] : [];
      filterObj.forEach(a => {
        const newDpIds = ids?.filter(f => a.ids.includes(f));
        if (newDpIds) {
          ids = [...newDpIds];
        }
      });

      const filtered1stData =
        mainData?.first_dataset_model?.filter(f => ids?.some(id => id === f.dme_role_request_user_role_id)) || [];

      setFilteredData(filtered1stData);
    } else {
      setFilteredData(mainData?.first_dataset_model || []);
    }
    setIsResultLoading(false);
  };

  useEffect(() => {
    addBreadcrumb({ label: 'Result', path: location.pathname }, 2);
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      setmainData(data);
      const filter: MyRoleDataset2Model[] = data.second_dataset_model.reduce(function (
        r: MyRoleFilterModel[],
        o: MyRoleFilterModel,
      ) {
        let dataElementExistIndex = r.findIndex(
          f => f.filter_header === o.filter_header && f.filter_order === o.filter_order,
        );
        if (dataElementExistIndex > -1) {
          r[dataElementExistIndex].filter_items = [
            ...(r[dataElementExistIndex].filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r];
        } else {
          const filterItems = [
            ...(o.filter_items || []),
            {
              filter_detail: o.filter_detail,
              filter_header: o.filter_header,
              filter_order: o.filter_order,
            },
          ];
          return [...r, { ...o, filter_items: filterItems }];
        }
      }, []);
      setFilterData(filter);
      setFilteredData(data.first_dataset_model);
      setIsResultLoading(false);
    }
  }, [data]);

  return (
    <Flex w="100%">
      <Box w={300}>
        {filterData ? <FilterPane filterData={filterData} onChange={onFilterChange} /> : <>Loading...</>}
      </Box>

      <Box flex={1} px={3}>
        {filterData && !isResultLoading && filterData.length == 0 ? (
          <Alert status="error" as="div">
            You do not have any request to access a role. Please go to this&nbsp;
            <Link to="/roles/explore-roles">
              <Text color="blue.500" as="strong">
                link
              </Text>
            </Link>
            &nbsp;to request access.
          </Alert>
        ) : (
          <ResultData
            data={filteredData || []}
            isLoading={isResultLoading}
            // onClickRow={(row: DataProductMyAccessFirstDatasetModel | null) =>
            //   setSelectedAccess(row)
            // }
          />
        )}
      </Box>
    </Flex>
  );
};

export default MyRoleResult;
