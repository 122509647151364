import { dmeApi } from './index';
import { RefDomainModel, VwRefDomainModel, AddRefDomainModel, UpdateRefDomainModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefDomainList: builder.query<RefDomainModel[], void>({
      query: () => `/refdomain`,
    }),

    getVwRefDomainList: builder.query<VwRefDomainModel[], void>({
      query: () => `/refdomain/list`,
      providesTags: ['RefDomain'],
    }),

    postRefDomain: builder.mutation<void, AddRefDomainModel>({
      query: params => ({
        url: `/refdomain`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['RefDomain'],
    }),

    putRefDomain: builder.mutation<void, UpdateRefDomainModel>({
      query: params => ({
        url: `/refdomain`,
        method: 'PUT',
        body: params,
      }),
      // invalidatesTags: ["RefDomain"],
    }),

    deleteRefDomain: builder.mutation<void, number>({
      query: ref_domain_id => ({
        url: `/refdomain/refdomainid/${ref_domain_id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRefDomainListQuery,
  useGetVwRefDomainListQuery,
  usePostRefDomainMutation,
  usePutRefDomainMutation,
  useDeleteRefDomainMutation,
} = extendedApi;
