import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { addUserConfig } from '../../../app/constants';
import { validateEmail } from '../../../app/helpers/stringHelper';
import { usePostPimGraphMutation } from '../../../app/services/dme/api/pim';
import { PostPimGraphDto, RefUserModel } from '../../../app/services/dme/api/types';
import { useLazyGetUserByEmailQuery } from '../../../app/services/dme/api/user';
import { useAppSelector } from '../../../app/state/hooks';

type Props = {
  triggerElement: JSX.Element;
  onSuccess: (newUser: RefUserModel) => void;
};

const AddRefUserDialog: FC<Props> = ({ triggerElement, onSuccess }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertDisclosure = useDisclosure();
  const { logonUser } = useAppSelector(s => s.user);

  const [postAsync, { isLoading, isSuccess, isError, error }] = usePostPimGraphMutation();

  const [getUserByUpnTrigger, { isFetching }] = useLazyGetUserByEmailQuery();

  const initialValues: PostPimGraphDto = {
    email: '',
    first_name: '',
    last_name: '',
    sso_name: addUserConfig.lore,
    source: addUserConfig.graph,
  };

  const postSubmit = async (values: PostPimGraphDto) => {
    const params = {
      ...values,
    };
    try {
      await postAsync(params);
    } catch (e) {}
  };

  const FormSchema = Yup.object().shape({
    email: Yup.string().required(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
  });

  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: initialValues,
    onSubmit: async values => {
      alertDisclosure.onClose();
      const userExist = await getUserByUpnTrigger(values.email);
      if (!validateEmail(values.email) || values.email.includes('+')) {
        errors.email = 'Email is invalid';
      } else if (userExist.data) {
        errors.email = 'User with this email already exists';
      } else {
        const val = {
          ...values,
        };
        await postSubmit(val);

        const newUser = await getUserByUpnTrigger(val.email);
        if (newUser.data) {
          onSuccess({ ...newUser.data, first_name: val.first_name, last_name: val.last_name });
        }
      }
    },
  });

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          onClose();
          resetForm();
          alertDisclosure.onClose();
        }, 2000);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  useEffect(() => {
    return () => {
      resetForm();
      alertDisclosure.onClose();
    };
  }, [isOpen]);
  return (
    <>
      {
        <>
          <Box
            onClick={e => {
              e.stopPropagation();
              onOpen();
            }}
          >
            {triggerElement}
          </Box>
          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <form onSubmit={handleSubmit}>
              {
                <ModalContent>
                  <ModalHeader>Invite User</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Alert status="info" borderRadius={5} mb={5} borderLeftWidth={4} borderLeftColor="#1f96f5">
                      <Stack>
                        <Text>
                          Complete the form to invite someone to the Mesh portal. Once you submit this form, we will
                          email the user with instructions for completing account setup and logging into the portal.
                        </Text>
                        <Spacer />
                        <Text as="strong">
                          New users must complete account setup within 7 days or the invitation will expire and need to
                          be re-sent.
                        </Text>
                      </Stack>
                    </Alert>
                    <FormControl mb={3} isInvalid={!!errors.email && touched.email}>
                      <FormLabel>Email</FormLabel>
                      <Input id="email" name="email" value={values.email} onChange={handleChange} />
                      <FormErrorMessage>
                        {errors.email?.includes('required') ? 'Email is required' : errors.email}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mb={3} isInvalid={!!errors.first_name && touched.first_name}>
                      <FormLabel>First Name</FormLabel>
                      <Input id="first_name" name="first_name" value={values.first_name} onChange={handleChange} />
                      <FormErrorMessage>First Name is required</FormErrorMessage>
                    </FormControl>

                    <FormControl mb={3} isInvalid={!!errors.last_name && touched.last_name}>
                      <FormLabel>Last Name</FormLabel>
                      <Input id="last_name" name="last_name" value={values.last_name} onChange={handleChange} />
                      <FormErrorMessage>Last Name is required</FormErrorMessage>
                    </FormControl>

                    {alertDisclosure.isOpen && (
                      <Alert status={isSuccess ? 'success' : isError ? 'error' : 'info'}>
                        <AlertIcon />
                        {isSuccess && 'Invitation Sent!'}
                        {isError &&
                          (error
                            ? (error as any)?.data?.error_message
                            : 'Something went wrong, please try again later or contact admin')}
                      </Alert>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button mr={3} onClick={onClose} isDisabled={isLoading || isFetching}>
                      Cancel
                    </Button>
                    <Button variant="solid" colorScheme="brand.main" type="submit" isLoading={isLoading || isFetching}>
                      Submit
                    </Button>
                  </ModalFooter>
                </ModalContent>
              }
            </form>
          </Modal>
        </>
      }
    </>
  );
};

export default AddRefUserDialog;
