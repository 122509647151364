import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useLazyGetDataProductMyAccessDetailQuery } from '../../../../app/services/dme/api/dataProduct';
import { useAppSelector } from '../../../../app/state/hooks';
import MyAccessDetailIndex from './MyAccessDetailIndex';
import MyAccessDetailStatus from './MyAccessDetailStatus';

type Props = {};
const tabs = [
  {
    label: 'Details',
    path: 'detail',
    component: MyAccessDetailIndex,
  },
  {
    label: 'Status',
    path: 'status',
    component: MyAccessDetailStatus,
  },
];

const MyAccessDetail = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();

  const { logonUser } = useAppSelector(s => s.user);

  const [query, queryResult] = useLazyGetDataProductMyAccessDetailQuery();

  useEffect(() => {
    addBreadcrumb({ label: 'Detail', path: location.pathname });
  }, []);

  useEffect(() => {
    const { accessMethod, requestId, myAcessDetailTab } = params;
    if (myAcessDetailTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === myAcessDetailTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }

    if (accessMethod && requestId && logonUser) {
      query({
        ref_user_id: logonUser.ref_user_id,
        access_method: accessMethod,
        request_id: +requestId,
      });
    }
  }, [params]);

  useEffect(() => {
    if (tabIndex !== undefined && params.myAcessDetailTab !== tabs[tabIndex].path) {
      navigate('../' + tabs[tabIndex].path);
    }
  }, [tabIndex]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <VStack>
      {queryResult.isLoading && <p>Loading...</p>}
      {queryResult.data && (
        <Tabs index={tabIndex} onChange={handleTabsChange} w="full">
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>

          <TabPanels>
            <TabPanel>
              <MyAccessDetailIndex details={queryResult.data.dataProductMyAccessDetailInfo} />
            </TabPanel>
            <TabPanel>
              <MyAccessDetailStatus status={queryResult.data.dataProductMyAccessStatus} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default MyAccessDetail;
