import { Box, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { ReportHubExploreModel } from '../../../../app/services/dme/api/types';
import ReportHubRequestAccess from '../../RequestAccess';

type Props = {
  original: ReportHubExploreModel;
  onClose: () => void;
};
const ExplorePortfoliosActionCellRequestAccess: FC<Props> = ({ original, onClose }) => {
  return (
    <>
      <ReportHubRequestAccess
        triggerElement={
          <Button
            size="sm"
            _hover={{ textDecoration: 'none' }}
            variant="link"
            fontWeight="normal"
            color="blackAlpha.900"
            textAlign="left"
            // onClick={onOpen}
          >
            <Box w="100%">Request Access</Box>
          </Button>
        }
        portfolio={original}
      />
    </>
  );
};

export default ExplorePortfoliosActionCellRequestAccess;
