import {
  Box,
  Button,
  HStack,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { GoCheck, GoCommentDiscussion, GoX } from 'react-icons/go';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { defaultErrorMessage } from '../../../app/constants';
import env from '../../../app/constants/env';
import { getName, stringOrHtmlIsEmpty } from '../../../app/helpers/stringHelper';
import {
  useGetGoEdsRequestForApprovalListQuery,
  useRefetchGoEdsRequestForApprovalListMutation,
  useVoteGovernanceOfficeEdsMutation,
} from '../../../app/services/dme/api/governanceOfficeEds';
import { GoEdsRequestForApprovalModel, VoteGovernanceOfficeEdsBodyModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import brandColors from '../../../app/theme/brandColors';
import { CustomTableHeader } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import PubNubConstants from '../../../features/PubNubWrapper/constants';
import RichTextEditor, { RichTextEditorProps } from '../../../features/RichTextEditor';

const ExternalDataSharingRequestsApproval = () => {
  const pubnub = usePubNub();
  const { messageEvent } = useAppSelector(s => s.pubNub);

  const listDetails = useGetGoEdsRequestForApprovalListQuery();
  const [refetch] = useRefetchGoEdsRequestForApprovalListMutation();

  useEffect(() => {
    pubnub.subscribe({
      channels: [_channelNameGoEdsApprovalList],
    });

    return () => {
      pubnub.unsubscribe({
        channels: [_channelNameGoEdsApprovalList],
      });
    };
  }, []);

  useEffect(() => {
    if (messageEvent?.channel === _channelNameGoEdsApprovalList) {
      const { message } = messageEvent;
      if (message.type === PubNubConstants.MessageEvent.Type.REFETCH) refetch();
    }
  }, [messageEvent]);

  return (
    <Box>
      <Heading size="sm" mb={4}>
        External Data Sharing Requests Approval
      </Heading>
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={listDetails.isLoading}
        isFetching={listDetails.isFetching}
        data={listDetails.data || []}
        pageCount={0}
        pageSize={listDetails.data ? listDetails.data.length : 10}
        totalRecords={listDetails.data ? listDetails.data.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        showNoRecords
      />
    </Box>
  );
};

const ActionCell = ({ row: { original } }: CellProps<GoEdsRequestForApprovalModel>) => {
  const [commentMessage, setCommentMessage] = useState('');

  const navigate = useNavigate();
  const pubnub = usePubNub();
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [vote, voteDetails] = useVoteGovernanceOfficeEdsMutation();

  const handleVote = (body: VoteGovernanceOfficeEdsBodyModel) => {
    vote(body)
      .unwrap()
      .then(res => {
        pubnub.publish({
          channel: _channelNameGoEdsApprovalList,
          message: {
            type: PubNubConstants.MessageEvent.Type.REFETCH,
          },
        });
        toast({ description: 'Successfully voted', status: 'success' });

        if (res.go_eds_post_id) {
          navigate(
            `../explore-data-sharing-requests/selected/id/${original.go_eds_request_id}/discussion?post=${res.go_eds_post_id}`,
          );
        }
      })
      .catch(() => {
        toast({ description: defaultErrorMessage, status: 'error' });
      });
  };

  const onTextAreaKeyEvent: RichTextEditorProps['onChange'] = (text, { hasImage }) => {
    if (hasImage) {
      toast({ description: 'Pasting image is not allowed.', status: 'warning' });
    }
    setCommentMessage(text);
  };

  useEffect(() => {
    if (isOpen) {
      setCommentMessage('');
    }
  }, [isOpen]);

  return (
    <>
      <HStack>
        <Tooltip label="Approve" openDelay={_openDelay}>
          <IconButton
            aria-label="Approve"
            size="sm"
            colorScheme="brand.main"
            icon={<GoCheck />}
            onClick={() =>
              handleVote({
                go_eds_request_id: original.go_eds_request_id,
                go_eds_ref_request_vote_id: env.db.goEds.vote.yesId,
              })
            }
            isLoading={
              voteDetails.isLoading && voteDetails.originalArgs?.go_eds_ref_request_vote_id === env.db.goEds.vote.yesId
            }
            isDisabled={voteDetails.isLoading || original.user_vote !== 'Awaiting Vote'}
          />
        </Tooltip>
        <Tooltip label="Deny" openDelay={_openDelay}>
          <IconButton
            aria-label="Deny"
            size="sm"
            bg="brand.error"
            colorScheme="brand.error"
            icon={<GoX />}
            onClick={() =>
              handleVote({
                go_eds_request_id: original.go_eds_request_id,
                go_eds_ref_request_vote_id: env.db.goEds.vote.noId,
              })
            }
            isLoading={
              voteDetails.isLoading && voteDetails.originalArgs?.go_eds_ref_request_vote_id === env.db.goEds.vote.noId
            }
            isDisabled={voteDetails.isLoading || original.user_vote !== 'Awaiting Vote'}
          />
        </Tooltip>
        <Tooltip label="Change Status to Discussion" openDelay={_openDelay}>
          <IconButton
            aria-label="Change Status to Discussion"
            size="sm"
            bg="brand.main"
            variant="outline"
            colorScheme="brand.main"
            icon={<GoCommentDiscussion />}
            onClick={() => {
              onOpen();
            }}
            isLoading={
              voteDetails.isLoading &&
              voteDetails.originalArgs?.go_eds_ref_request_vote_id === env.db.goEds.vote.discussionId
            }
            isDisabled={voteDetails.isLoading || original.user_vote !== 'Awaiting Vote'}
          />
        </Tooltip>
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Change Status to Discussion</ModalHeader>

          <ModalCloseButton />

          <ModalBody display="flex" flexDir="column" gap="4">
            <RichTextEditor value={commentMessage} onChange={onTextAreaKeyEvent} removeImages />
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} ml="auto">
              Cancel
            </Button>
            <Button
              colorScheme="brand.main"
              isDisabled={stringOrHtmlIsEmpty(commentMessage)}
              onClick={() => {
                handleVote({
                  go_eds_request_id: original.go_eds_request_id,
                  go_eds_ref_request_vote_id: env.db.goEds.vote.discussionId,
                  createPostModel: {
                    post_subject: 'STATE UPDATE: ' + env.db.goEds.status.discussionName,
                    post_message: commentMessage.replace(/\uFEFF/g, ''),
                  },
                  go_eds_ref_request_status_id: env.db.goEds.status.discussionId,
                });
                onClose();
                setCommentMessage('');
              }}
            >
              Change Status
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const _openDelay = 1000;
const _channelNameGoEdsApprovalList = 'go-eds-approval-list';

const Header: CustomTableHeader<GoEdsRequestForApprovalModel> = [
  {
    Header: 'actions',
    styles: {},
    isSortable: false,
    Cell: ActionCell,
  },
  {
    Header: 'Vote Details',
    accessor: 'user_vote',
    isSortable: false,
    styles: {},
  },
  {
    Header: 'Request #',
    accessor: 'go_eds_request_id',
    isSortable: false,
    styles: {},
  },
  {
    Header: 'Subject',
    accessor: 'request_subject',
    isSortable: false,
    styles: {
      whiteSpace: 'initial',
      // width: '400px',
      // maxWidth: '400px',
      // minWidth: '400px',
    },
  },
  {
    Header: 'Requestor Name',
    styles: {},
    isSortable: false,
    Cell: ({ row: { original } }: CellProps<GoEdsRequestForApprovalModel>) => {
      return (
        <>
          {getName({
            first_name: original.request_creator_first_name,
            last_name: original.request_creator_last_name,
          })}
        </>
      );
    },
  },
  {
    Header: ' ',
    styles: {},
    Cell: ({ row: { original } }: CellProps<GoEdsRequestForApprovalModel>) => {
      return (
        <Link
          as={RouterLink}
          color={brandColors.secondary.blue[200]}
          to={`../explore-data-sharing-requests/selected/id/${original.go_eds_request_id}/details`}
        >
          <BiLinkExternal size="18px" />
        </Link>
      );
    },
  },
];

export default ExternalDataSharingRequestsApproval;
