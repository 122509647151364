import { Alert, Box, IconButton, Text, VStack } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { viewableExt } from '../../../../../app/constants';
import { useGetGoEdsPostAttachmentsByIdQuery } from '../../../../../app/services/dme/api/governanceOfficeEdsPost';
import { GoEdsPostAttachmentModel } from '../../../../../app/services/dme/api/types';

type Props = {
  postId: number;
};

const GoEdsPostAttachmentList: FC<Props> = ({ postId }) => {
  const { data, isLoading, isFetching } = useGetGoEdsPostAttachmentsByIdQuery(postId, {
    skip: postId == 0,
  });
  const onClickDownload = (item: GoEdsPostAttachmentModel) => {
    window.open(item.go_eds_post_attachment_url, '_blank');
  };

  return (
    <VStack maxH="calc(100vh - 250px)" overflowY="auto" spacing={4}>
      {data && data.length == 0 && !isLoading && !isFetching && <Alert status="error">No Attachment(s)</Alert>}
      {isLoading || isFetching ? (
        <>Loading...</>
      ) : (
        data &&
        data.map((m: GoEdsPostAttachmentModel) => {
          const isViewable = viewableExt.some(s => s.includes(m.go_eds_post_attachment_url?.split('.')?.pop() || ''));
          return (
            <Fragment key={m.go_eds_post_attachment_id}>
              <Box alignItems="center" display="flex" maxW="600px">
                <Text noOfLines={1} whiteSpace="normal" wordBreak="break-all" title={m.go_eds_post_attachment_name}>
                  {m.go_eds_post_attachment_name}
                </Text>
                <IconButton
                  variant="link"
                  color="links"
                  size="md"
                  aria-label={isViewable ? 'View' : 'Download'}
                  icon={isViewable ? <HiOutlineExternalLink /> : <MdOutlineSimCardDownload />}
                  title={isViewable ? 'View' : 'Download'}
                  onClick={() => onClickDownload(m)}
                />
              </Box>
            </Fragment>
          );
        })
      )}
    </VStack>
  );
};

export default GoEdsPostAttachmentList;
