import { dmeApi } from '.';
import { DynamicObject } from '../../../types/appType';
import { PaginatedApiResult } from '../../types';
import { GetInfoweaveListParams, InfoweaveModel, PostInfoweaveStatusBody } from './types';

const baseUrl = '/infoweave';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getInfoweaveList: builder.query<PaginatedApiResult<InfoweaveModel>, GetInfoweaveListParams>({
      query: ({ filters, ...params }) => ({
        url: baseUrl,
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].filter_order`] = val.filter_order;
            obj[`filters[${i}].filter_ref_id`] = val.filter_ref_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
      providesTags: ['Infoweave'],
    }),
    //before refactoring
    getFocusTabCount_old: builder.query<number, number>({
      query: ref_user_id => baseUrl + `/focus/unread/${ref_user_id}`,
      transformResponse: (response: { unread_count: 0 }[]) => {
        return response[0]?.unread_count || 0;
      },
      providesTags: ['Infoweave'],
    }),
    //latest after refactoring
    getFocusTabCount: builder.query<number, number>({
      query: ref_user_id => baseUrl + `/focus/unread/${ref_user_id}`,
      transformResponse: (response: { unread_count: number }) => {
        return response.unread_count || 0;
      },
      providesTags: ['Infoweave'],
    }),
    postInfoweaveStatus: builder.mutation<void, PostInfoweaveStatusBody>({
      query: body => ({
        url: baseUrl + '/status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Infoweave'],
    }),
    refetchInfoweaveList: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['Infoweave'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetInfoweaveListQuery,
  useGetFocusTabCountQuery,
  usePostInfoweaveStatusMutation,
  useRefetchInfoweaveListMutation,
} = extendedApi;
