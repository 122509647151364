import { Box, HStack, Heading } from '@chakra-ui/react';
import ExploreDataSharingRequestsFilter from './Filter';
import ExploreDataSharingRequestsTable from './Table';

type Props = {};

// TODO
//  AppAccess.MyEdsRequestsWrite
//  AppAccess.exploreGovWrite

const ExploreDataSharingRequests = (props: Props) => {
  return (
    <Box w="100%">
      <HStack mb="6">
        <Heading as="h2" size="sm">
          Explore External Data Sharing Requests
        </Heading>
        {/* <AppAuth requiredAppAccess={AppAccess.MyEdsRequestsWrite}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="brand.main"
            leftIcon={<FaUnlockAlt />}
            as={Link}
            to="./create-request"
          >
            Create Request
          </Button>
        </AppAuth> */}
      </HStack>
      <HStack gap="6" alignItems="start">
        <ExploreDataSharingRequestsFilter />
        <ExploreDataSharingRequestsTable />
      </HStack>
    </Box>
  );
};

export default ExploreDataSharingRequests;
