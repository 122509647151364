import { dmeApi } from '.';
import { PostRefDeveloperEnvironmentDto } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    postRefDeveloperEnvironment: builder.mutation<
      { ref_developer_environment_id: number },
      PostRefDeveloperEnvironmentDto
    >({
      query: body => ({
        url: '/refdeveloperenvironment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RolesAppAccessDevEnv'],
    }),
  }),
});

export const { usePostRefDeveloperEnvironmentMutation } = extendedApi;
