import { Heading, HStack, Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MyRoleDetailTabModel } from '../../../../app/services/dme/api/types';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';

type Props = {
  data: MyRoleDetailTabModel[];
};

const MyRolesDetailTab = ({ data }: Props) => {
  const [localData, setLocalData] = useState<MyRoleDetailTabModel>();
  const location = useLocation();
  useEffect(() => {
    setLocalData(data[0]);
  }, [data]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Request Details</Heading>
      {!data ? (
        <></>
      ) : (
        <HStack>
          <Table size="sm">
            <Tbody
              sx={{
                '& tr > th': {
                  maxW: '50px',
                  whiteSpace: 'nowrap',
                },
                td: {
                  whiteSpace: 'initial',
                  maxW: '300px',
                },
              }}
            >
              <Tr>
                <Th minW={170}>Role Name</Th>
                <Td>{localData?.role_name}</Td>
                <Td>&nbsp;</Td>
                <Th minW={110}>Request ID</Th>
                <Td>{localData?.dme_role_request_user_role_id}</Td>
              </Tr>
              <Tr>
                <Th>Role Description</Th>
                <Td>{localData?.role_desc}</Td>
                <Td>&nbsp;</Td>
                <Th>Requestor</Th>
                <Td whiteSpace="nowrap">{localData?.requestor}</Td>
              </Tr>
              <Tr>
                <Th>BUsiness Justification</Th>
                <Td>{localData?.access_request_business_justification}</Td>
                <Td>&nbsp;</Td>
                <Th>Denied Explanation</Th>
                <Td>{localData?.access_denied_notes}</Td>
              </Tr>
            </Tbody>
          </Table>
        </HStack>
      )}
    </VStack>
  );
};

export default MyRolesDetailTab;
