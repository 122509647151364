import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { ValueOf } from '../../../../app/helpers/utilities';
import {
  ImportantLinkDetailsPageFirstDatasetExtendedModel,
  EditImportantLinkModel,
} from '../../../../app/services/dme/api/types';
import { FocusEvent } from 'react';
import { Select } from 'chakra-react-select';
import { useGetImportantLinkRefCategoryListQuery } from '../../../../app/services/dme/api/importantLink';

type Props = {
  data: EditImportantLinkModel;
  onChangeData: (field: keyof EditImportantLinkModel, data: ValueOf<EditImportantLinkModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const LoreLinkDetails = ({ data, onChangeData, errors, touched, tabErrorIndex }: Props) => {
  const { data: linkRefCategoryListData } = useGetImportantLinkRefCategoryListQuery();

  const onBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof ImportantLinkDetailsPageFirstDatasetExtendedModel,
  ) => {
    data.detailModel &&
      onChangeData('detailModel', {
        ...data.detailModel,
        [field]: e.target.value.trim(),
      });
  };
  return (
    <VStack spacing={5} p={1} pt={3}>
      {!data.detailModel || !linkRefCategoryListData ? (
        <></>
      ) : (
        <VStack w="container.md">
          <HStack>
            <Button
              size="sm"
              leftIcon={<FiRefreshCcw />}
              onClick={() => {
                data.detailModel && onChangeData('detailModel', data.detailModel, true);
              }}
            >
              Refresh
            </Button>
          </HStack>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)
                  ?.link_ref_category_id
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)
                  ?.link_ref_category_id
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Category</FormLabel>
            <Box w="full">
              <Select
                size="sm"
                id="link_ref_category_id"
                name="link_ref_category_id"
                useBasicStyles
                value={{
                  label:
                    linkRefCategoryListData.find(c => c.link_ref_category_id === data.detailModel?.link_ref_category_id)
                      ?.link_ref_category_name ?? '-',
                  value: data.detailModel.link_ref_category_id,
                }}
                options={[
                  { label: '-', value: 0 },
                  ...linkRefCategoryListData.map(m => ({
                    label: m.link_ref_category_name,
                    value: m.link_ref_category_id,
                  })),
                ]}
                onChange={e => {
                  data.detailModel &&
                    onChangeData('detailModel', {
                      ...data.detailModel,
                      link_ref_category_id: parseInt(e?.value?.toString() || '0'),
                    });
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: provided => ({
                    ...provided,
                    zIndex: 1401,
                  }),
                }}
                maxMenuHeight={300}
              />
            </Box>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              (!!(
                errors.detailModel &&
                (errors.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)?.link_name
              ) &&
                !!(
                  touched.detailModel &&
                  (touched.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)?.link_name
                )) ||
              tabErrorIndex === 0
            }
            display="flex"
          >
            <FormLabel minW="150px">Name</FormLabel>
            <Input
              size="sm"
              value={data.detailModel.link_name}
              onChange={e =>
                data.detailModel && onChangeData('detailModel', { ...data.detailModel, link_name: e.target.value })
              }
              onBlur={e => onBlur(e, 'link_name')}
            />
            <>
              {(errors.detailModel as any)?.link_name === 'Name is invalid' ? (
                <Tooltip label="Name already exist">
                  <IconButton
                    color="brand.error"
                    variant="link"
                    aria-label="Name already exist"
                    icon={<MdCancel />}
                    minW={7}
                  />
                </Tooltip>
              ) : (
                data.detailModel.link_name.length > 0 && (
                  <Tooltip label="Looks good">
                    <IconButton
                      color="brand.main.default"
                      variant="link"
                      aria-label="Name is valid"
                      icon={<MdCheckCircle />}
                      minW={7}
                    />
                  </Tooltip>
                )
              )}
            </>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)?.link_description
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)?.link_description
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Description</FormLabel>
            <Textarea
              size="sm"
              value={data.detailModel.link_description}
              onChange={e =>
                data.detailModel &&
                onChangeData('detailModel', { ...data.detailModel, link_description: e.target.value })
              }
              onBlur={e => onBlur(e, 'link_description')}
            />
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)?.link_url
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as ImportantLinkDetailsPageFirstDatasetExtendedModel)?.link_url
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">URL</FormLabel>
            <Input
              size="sm"
              value={data.detailModel.link_url}
              onChange={e =>
                data.detailModel &&
                onChangeData('detailModel', {
                  ...data.detailModel,
                  link_url: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'link_url')}
            />
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
        </VStack>
      )}
    </VStack>
  );
};

export default LoreLinkDetails;
