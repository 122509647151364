import { removeUnwantedCharacters } from '../../../helpers/stringHelper';
import { dmeApi } from './index';
import {
  DataProductDetailsPageDiscussionModel,
  DataProductPostAttachmentModel,
  ToggleDataProductPostReactionModel,
  UpdateDataProductPostModel,
} from './types';

const extendApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    // SP: p_dp_data_product_details_discussions_posts
    getDataProductDetailsDiscussionPostsByDataProductId: builder.query<
      Array<DataProductDetailsPageDiscussionModel>,
      { dpId: number; stateOnly: boolean }
    >({
      query: p => `/dataproductpost/details/discussion/posts/dp_data_product_id/${p.dpId}?state_flag=${p.stateOnly}`,
    }),

    getDataProductPostById: builder.query<Array<DataProductDetailsPageDiscussionModel>, number>({
      query: dp_post_id => `/dataproductpost/details/discussion/posts/dp_post_id/${dp_post_id}`,
    }),

    getDataProductPostAttachmentsById: builder.query<Array<DataProductPostAttachmentModel>, number>({
      query: dp_post_id => `/dataproductpost/attachment/dp_post_id/${dp_post_id}`,
      providesTags: ['DataProductPostAttachments'],
    }),

    addDataProductPost: builder.mutation<{ dp_post_id: number }, FormData>({
      query: params => ({
        url: '/dataproductpost',
        method: 'POST',
        body: params,
      }),
    }),

    postDataProductPostToggleReaction: builder.mutation<void, ToggleDataProductPostReactionModel>({
      query: params => ({
        url: '/dataproductpost/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateDataProductPost: builder.mutation<void, UpdateDataProductPostModel>({
      query: params => ({
        url: '/dataproductpost',
        method: 'PUT',
        body: { ...params, post_message: removeUnwantedCharacters(params.post_message) },
      }),
    }),

    deleteDataProductPost: builder.mutation<void, number>({
      query: dp_post_id => ({
        url: `/dataproductpost?${new URLSearchParams({ dp_post_id: dp_post_id.toString() }).toString()}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetDataProductDetailsDiscussionPostsByDataProductIdQuery,
  useLazyGetDataProductPostByIdQuery,
  useGetDataProductPostAttachmentsByIdQuery,
  useAddDataProductPostMutation,
  usePostDataProductPostToggleReactionMutation,
  useUpdateDataProductPostMutation,
  useDeleteDataProductPostMutation,
} = extendApi;
