import { dmeApi } from './index';
import { RefDeliveryModeModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefDeliveryModeList: builder.query<RefDeliveryModeModel[], void>({
      query: () => `/refdeliverymode/list`,
    }),

    getRefDeliveryModeListForView: builder.query<RefDeliveryModeModel[], void>({
      query: () => `/refdeliverymode/listforview`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefDeliveryModeListQuery, useGetRefDeliveryModeListForViewQuery } = extendedApi;
