import { Box, Flex, VStack } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { addBreadcrumb, sliceBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import FilterPane from '../FilterPane';
import Result from './Result';

type Props = {};

const DataProductResult: FC<Props> = () => {
  const location = useLocation();

  useEffect(() => {
    sliceBreadcrumb(0, 3);
    addBreadcrumb({ label: 'Result', path: location.pathname });
  }, []);

  return (
    <Flex align="start">
      <Box>
        <FilterPane />
      </Box>
      <VStack flexGrow={1} p={3} pt={0} bgColor="white" h="100vh" spacing={2}>
        <Result />
      </VStack>
    </Flex>
  );
};

export default DataProductResult;
