import { dmeApi } from '.';
import {
  ReportHubCommentAttachmentModel,
  ReportHubDetailsDiscussionTabModel,
  ToggleReportHubCommentReactionModel,
  UpdateReportHubCommentModel,
} from './types';

const baseUrl = '/reports';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getReportHubDetailsDiscussionTab: builder.query<
      Array<ReportHubDetailsDiscussionTabModel>,
      {
        rhPostId: number;
        query: string;
      }
    >({
      query: params => baseUrl + `/portfolio/details/discussions/tab/rh_post_id/${params.rhPostId}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getReportHubDetailsDiscussionTabByComment: builder.query<
      Array<ReportHubDetailsDiscussionTabModel>,
      {
        rh_comment_id: number;
        query: string;
      }
    >({
      query: params =>
        baseUrl + `/portfolio/details/discussions/tab/rh_comment_id/${params.rh_comment_id}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getReportHubCommentAttachmentsById: builder.query<Array<ReportHubCommentAttachmentModel>, number>({
      query: rh_comment_id => `/reporthubcomment/attachment/rh_comment_id/${rh_comment_id}`,
      providesTags: ['ReportHubCommentAttachments'],
    }),

    postReportHubComment: builder.mutation<{ rh_comment_id: number }, FormData>({
      query: params => ({
        url: '/reporthubcomment',
        method: 'POST',
        body: params,
      }),
    }),

    postReportHubCommentToggleReaction: builder.mutation<void, ToggleReportHubCommentReactionModel>({
      query: params => ({
        url: '/reporthubcomment/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateReportHubComment: builder.mutation<void, UpdateReportHubCommentModel>({
      query: params => ({
        url: '/reporthubcomment',
        method: 'PUT',
        body: params,
      }),
    }),

    deleteReportHubComment: builder.mutation<void, number>({
      query: rh_comment_id => ({
        url: `/reporthubcomment/${rh_comment_id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetReportHubDetailsDiscussionTabQuery,
  useLazyGetReportHubDetailsDiscussionTabQuery,
  useGetReportHubDetailsDiscussionTabByCommentQuery,
  useLazyGetReportHubDetailsDiscussionTabByCommentQuery,
  useGetReportHubCommentAttachmentsByIdQuery,
  useLazyGetReportHubCommentAttachmentsByIdQuery,
  usePostReportHubCommentMutation,
  usePostReportHubCommentToggleReactionMutation,
  useUpdateReportHubCommentMutation,
  useDeleteReportHubCommentMutation,
} = extendedApi;
