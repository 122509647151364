import { AddDataProductModel, EditDataProductModel } from '../../types';
import { dmeApi } from './index';
import { DataProductResultModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getEditorDataProductResult: builder.query<Array<DataProductResultModel>, void>({
      query: () => `/editordataproduct/result`,
      providesTags: ['EditorDataProductResult'],
    }),

    postDataProduct: builder.mutation<void, FormData>({
      query: params => ({
        url: `/editordataproduct/add`,
        method: 'POST',
        body: params,
      }),
    }),

    putDataProduct: builder.mutation<void, FormData>({
      query: params => ({
        url: `/editordataproduct/update`,
        method: 'PUT',
        body: params,
      }),
    }),

    deleteDataProduct: builder.mutation<void, number>({
      query: dpdataproductid => ({
        url: `/editordataproduct/delete/dpdataproductid/${dpdataproductid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DataProductResult'],
    }),
  }),
  overrideExisting: false,
});

export const { usePostDataProductMutation, usePutDataProductMutation, useDeleteDataProductMutation } = extendedApi;
