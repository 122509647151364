import { Box, Link } from '@chakra-ui/react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReportHubExploreModel } from '../../../../app/services/dme/api/types';

type Props = {
  original: ReportHubExploreModel;
  onClose: () => void;
};
const ExplorePortfoliosActionCellViewReport: FC<Props> = ({ original, onClose }) => {
  return (
    <>
      <Link
        fontSize="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        textAlign="left"
        as={RouterLink}
        {...(original.has_access_flag
          ? {
              color: 'blackAlpha.900',
              to: original.report_portfolio_link,
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {
              color: 'blackAlpha.500',
              cursor: 'not-allowed',
            })}
      >
        <Box
          w="100%"
          onClick={() => {
            original.has_access_flag && onClose();
          }}
        >
          View Portfolio
        </Box>
      </Link>
    </>
  );
};

export default ExplorePortfoliosActionCellViewReport;
