import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { GetCustomRequestMyRequestFirstDatasetModel } from '../../../app/services/dme/api/types';
import CustomTable from '../../../components/CustomTable';
import { Row } from 'react-table';

type Props = {
  data: GetCustomRequestMyRequestFirstDatasetModel[];
  isLoading: boolean;
};
type StatusRow = { row: Row<GetCustomRequestMyRequestFirstDatasetModel> };

const CustomRequestsMyRequestData = ({ data, isLoading }: Props) => {
  const navigate = useNavigate();

  const Header = [
    {
      Header: 'ID',
      accessor: 'cr_custom_request_id',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Status',
      accessor: 'current_status',
      styles: { whiteSpace: 'initial', verticalAlign: 'top' },
    },
    {
      Header: 'Title',
      accessor: 'request_title',
      styles: { maxWidth: '250px', whiteSpace: 'initial', verticalAlign: 'top' },
    },
    {
      Header: 'Details',
      accessor: 'request_details',
      styles: { maxWidth: '400px', whiteSpace: 'initial', verticalAlign: 'top' },
    },
    {
      Header: 'Expiration Date',
      styles: { whiteSpace: 'initial', verticalAlign: 'top' },
      Cell: ({ row: { original } }: StatusRow) => {
        return <>{convertUtcToLocal(original.request_expiration_datetime_utc, 'MMMM D, YYYY h:mm A') || 'N/A'}</>;
      },
    },
    {
      Header: 'Creation Date',
      styles: { whiteSpace: 'initial', verticalAlign: 'top' },
      Cell: ({ row: { original } }: StatusRow) => {
        return <>{convertUtcToLocal(original.request_datetime_utc, 'MMMM D, YYYY h:mm A') || 'N/A'}</>;
      },
    },
  ];

  const onClickRow = (row: GetCustomRequestMyRequestFirstDatasetModel) => {
    navigate(`./selected/id/${row.cr_custom_request_id}/details`);
  };

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isLoading}
        data={data || []}
        pageCount={0}
        pageSize={5}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={() => {}}
        onPageSizeChange={() => {}}
        onPageSearch={() => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        onRowClick={row => onClickRow(row)}
      />
    </Box>
  );
};

export default CustomRequestsMyRequestData;
