import React, { FC, useRef } from 'react';
import { VStack, HStack, Box, FormControl, FormLabel, Input, FormErrorMessage, Divider, Text, Button, ButtonGroup } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AddPlatformServiceModel } from '../../../../app/services/dme/api/types';
import { ExplorePlatfromServicesCreateRequestProps, explorePlatfromServicesCreateRequestFields, explorePlatfromServicesCreateRequestTabs } from './utils';
import { useAppSelector } from '../../../../app/state/hooks';

const ExplorePlatfromServicesCreateRequestDetails: FC<ExplorePlatfromServicesCreateRequestProps> = ({
  tabIndex,
  setTabIndex,
  data,
  setData,
  original,
}) => {
  const actionRef = useRef<number>(0);
  
  const {
    handleSubmit,
    errors,
    touched,
    submitForm,
  } = useFormik<AddPlatformServiceModel>({
    enableReinitialize: true,
    initialValues: data,
    onSubmit: (values, form) => {
      if (actionRef.current) {
        setData(d => ({ ...d, ...values }));
        setTabIndex(i => i + actionRef.current);
      }
    },
  });

  const categoryName = original.ps_category_name || '';
  const portfolioName = original.ps_portfolio_name || '';

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;

  return (
    <VStack w="container.md">
      <FormControl isInvalid={!portfolioName} display="flex">
        <FormLabel minW="150px">{explorePlatfromServicesCreateRequestFields.request_platform_portfolio.label}</FormLabel>
        <Box w="100%">
          <Input
            size="sm"
            id="request_platform_portfolio"
            name="request_platform_portfolio"
            value={portfolioName}
            isReadOnly
          />
        </Box>
      </FormControl>
      <FormControl isInvalid={!categoryName} display="flex">
        <FormLabel minW="150px">{explorePlatfromServicesCreateRequestFields.request_service_category.label}</FormLabel>
        <Box w="100%">
          <Input
            size="sm"
            id="request_service_category"
            name="request_service_category"
            value={categoryName}
            isReadOnly
          />
        </Box>
      </FormControl>  
      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {explorePlatfromServicesCreateRequestTabs.length}
        </Text>
        <ButtonGroup>
          <Button size="sm" isDisabled={tabIndex === 0} onClick={() => {}}>
            Previous
          </Button>
          <Button
            size="sm"
            isDisabled={tabIndex === explorePlatfromServicesCreateRequestTabs.length - 1}
            colorScheme="brand.main"
            onClick={() => {
              actionRef.current = 1;
              //Manually set the portfolio and category details
              data.ps_ref_category_id = original.ps_ref_category_id;
              data.request_created_ref_user_id = userId;
              submitForm();
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default ExplorePlatfromServicesCreateRequestDetails;
 