import { dmeApi } from '.';
import {
  CustomRequestCommentAttachmentModel,
  CustomRequestDetailsDiscussionTabCommentModel,
  CustomRequestDetailsDiscussionTabModel,
  ToggleCustomRequestCommentReactionModel,
  UpdateCustomRequestCommentModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getCustomRequestCommentDetail: builder.query<CustomRequestDetailsDiscussionTabCommentModel, number>({
      query: cr_comment_id => `/customrequestcomment/cr_comment_id/${cr_comment_id}`,
    }),

    getCustomRequestDetailsDiscussionTab: builder.query<
      Array<CustomRequestDetailsDiscussionTabModel>,
      {
        crPostId: number;
        query: string;
      }
    >({
      query: params => `/customrequest/discussions/tab/cr_post_id/${params.crPostId}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getCustomRequestDetailsDiscussionTabByComment: builder.query<
      Array<CustomRequestDetailsDiscussionTabModel>,
      {
        cr_comment_id: number;
        query: string;
      }
    >({
      query: params => `/customrequest/discussions/tab/cr_comment_id/${params.cr_comment_id}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getCustomRequestCommentAttachmentsById: builder.query<Array<CustomRequestCommentAttachmentModel>, number>({
      query: cr_comment_id => `/customrequestcomment/attachment/cr_comment_id/${cr_comment_id}`,
      providesTags: ['CustomRequestCommentAttachments'],
    }),

    postCustomRequestComment: builder.mutation< number , FormData>({
      query: params => ({
        url: '/customrequestcomment',
        method: 'POST',
        body: params,
      }),
    }),

    postCustomRequestCommentToggleReaction: builder.mutation<void, ToggleCustomRequestCommentReactionModel>({
      query: params => ({
        url: '/customrequestcomment/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateCustomRequestComment: builder.mutation<void, UpdateCustomRequestCommentModel>({
      query: params => ({
        url: '/customrequestcomment',
        method: 'PUT',
        body: params,
      }),
    }),

    deleteCustomRequestComment: builder.mutation<void, number>({
      query: cr_comment_id => ({
        url: `/customrequestcomment?${new URLSearchParams({ cr_comment_id: cr_comment_id.toString() }).toString()}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCustomRequestCommentDetailQuery,
  useLazyGetCustomRequestCommentDetailQuery,
  useGetCustomRequestDetailsDiscussionTabQuery,
  useLazyGetCustomRequestDetailsDiscussionTabQuery,
  useGetCustomRequestDetailsDiscussionTabByCommentQuery,
  useLazyGetCustomRequestDetailsDiscussionTabByCommentQuery,
  useGetCustomRequestCommentAttachmentsByIdQuery,
  useLazyGetCustomRequestCommentAttachmentsByIdQuery,
  usePostCustomRequestCommentMutation,
  usePostCustomRequestCommentToggleReactionMutation,
  useUpdateCustomRequestCommentMutation,
  useDeleteCustomRequestCommentMutation,
} = extendedApi;
