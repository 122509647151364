import { dmeApi } from './index';
import { RefSpecialtyModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefSpecialtyList: builder.query<RefSpecialtyModel[], void>({
      query: () => `/refspecialty`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefSpecialtyListQuery } = extendedApi;
