import {
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
  Alert,
  AlertIcon,
  Box,
  HStack,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Select } from 'chakra-react-select';

import { RefUserModel } from '../../../app/services/dme/api/types';
import { usePostRefDomainMutation, usePutRefDomainMutation } from '../../../app/services/dme/api/refDomain';

const FormSchema = Yup.object().shape({
  domain_name: Yup.string().label('Domain Name').required(),
  domain_desc: Yup.string().label('Description').optional(),
  ref_user_id: Yup.number().label('Owner').required().moreThan(0, 'Owner is a required field'),
});

type Props = {
  ref_domain_id?: number;
  domain_name?: string;
  domain_desc?: string;
  ref_user_id?: number;
  ref_user_list: RefUserModel[];
  triggerElement: JSX.Element;
  reloadList?: () => void;
};

const EditDomainDialog = ({
  ref_domain_id,
  ref_user_list,
  triggerElement,
  domain_name,
  domain_desc,
  ref_user_id,
  reloadList,
}: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [putAsync, putDetail] = usePutRefDomainMutation();
  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_domain_id: ref_domain_id || 0,
      domain_name: domain_name || '',
      domain_desc: domain_desc || '',
      ref_user_id: ref_user_id || 0,
    },
    onSubmit: values => {
      putAsync(values);
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = putDetail;

    if (isSuccess) {
      setAlertMessage('Domain successfully updated.');
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      setAlertMessage('There was an error processing your request, please try again later.');
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [putDetail]);

  useEffect(() => {
    if (!isOpen && putDetail.isSuccess) {
      reloadList && reloadList();
    }
  }, [isOpen]);

  return (
    <>
      <Box
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Domain</ModalHeader>
          <ModalCloseButton isDisabled={putDetail.isLoading} />
          <>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <VStack spacing={5}>
                  <FormControl isInvalid={!!errors.domain_name && touched.domain_name}>
                    <FormLabel htmlFor="domain_name">Domain</FormLabel>
                    <Input
                      id="domain_name"
                      name="domain_name"
                      onChange={handleChange}
                      onBlur={e =>
                        setValues({
                          ...values,
                          domain_name: e.target.value.trim(),
                        })
                      }
                      value={values.domain_name}
                      maxLength={100}
                    />
                    <FormErrorMessage>{errors.domain_name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.domain_desc && touched.domain_desc}>
                    <HStack justifyContent="space-between">
                      <FormLabel htmlFor="domain_desc">Description</FormLabel>
                      {values.domain_desc.length > 0 && <Text as="small">{`${values.domain_desc.length}/500`}</Text>}
                    </HStack>
                    <Textarea
                      id="domain_desc"
                      name="domain_desc"
                      onChange={handleChange}
                      onBlur={e =>
                        setValues({
                          ...values,
                          domain_desc: e.target.value.trim(),
                        })
                      }
                      value={values.domain_desc}
                      maxLength={500}
                    />
                    <FormErrorMessage>{errors.domain_desc}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.ref_user_id && touched.ref_user_id}>
                    <FormLabel htmlFor="ref_user_id">Owner</FormLabel>
                    <Select
                      id="ref_user_id"
                      name="ref_user_id"
                      useBasicStyles
                      defaultValue={ref_user_list.map((m: RefUserModel) =>
                        m.ref_user_id == ref_user_id
                          ? {
                              label: `${m.first_name} ${m.last_name}`,
                              value: m.ref_user_id,
                            }
                          : null,
                      )}
                      options={ref_user_list.map(m => {
                        return {
                          label: `${m.first_name} ${m.last_name}`,
                          value: m.ref_user_id,
                        };
                      })}
                      onChange={e => {
                        setValues({
                          ...values,
                          ref_user_id: parseInt(e?.value?.toString() || '0'),
                        });
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: provided => ({
                          ...provided,
                          zIndex: 1401,
                        }),
                      }}
                      maxMenuHeight={300}
                    />
                    <FormErrorMessage>{errors.ref_user_id}</FormErrorMessage>
                  </FormControl>

                  <VStack spacing={5}>
                    {alertMessage && (
                      <Alert status={putDetail.isSuccess ? 'success' : 'error'}>
                        <AlertIcon />
                        {alertMessage}
                      </Alert>
                    )}
                  </VStack>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} mr={3} ml="auto" isDisabled={putDetail.isLoading}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.main"
                  isLoading={putDetail.isLoading}
                  isDisabled={alertMessage !== '' && putDetail.isSuccess}
                >
                  Save
                </Button>
              </ModalFooter>
            </form>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditDomainDialog;
