import { Box, Flex, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FC, createElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useRefetchInfoweaveListMutation } from '../../app/services/dme/api/infoweave';
import brandColors from '../../app/theme/brandColors';
import { IconProps } from '../../app/types/appType';
import AppAuth from '../../features/AppAuth';
import { NavItemModel } from './navItems';

type IProps = {
  item: NavItemModel;
  isOpen: boolean;
};

const NavItem: FC<IProps> = ({ item, isOpen }) => {
  const location = useLocation();
  const [refetchInfoweave] = useRefetchInfoweaveListMutation();

  const isSelected = (href: string, strict?: boolean) => {
    if (strict) {
      return location.pathname === href;
    }
    return location.pathname === href || location.pathname.indexOf(href) === 0;
  };

  const handleOnclickSideNav = (href: string) => () => {
    if (href === '/') {
      refetchInfoweave();
    }
  };

  return (
    <VStack className={`navItem ${isSelected(item.href, item.strict) ? 'selected' : ''}`} spacing={0}>
      <Link
        py={3}
        px={3}
        as={RouterLink}
        to={item.href}
        w="100%"
        borderRadius={4}
        fontWeight={isSelected(item.href, item.strict) ? 'medium' : 'normal'}
        whiteSpace="nowrap"
        onClick={handleOnclickSideNav(item.href)}
      >
        <Flex align="stretch" alignItems="center">
          {item.icon ? (
            <Icon
              as={item.icon} //color="sideNavMenu.iconColor"
              fontSize={18}
            />
          ) : (
            createElement<IconProps>(item.iconComponent!, {
              color: brandColors.primary.teal[400],
              size: 18,
            })
          )}
          <Text ml={4}>{item.text}</Text>
        </Flex>
      </Link>
      <VStack
        align="stretch"
        hidden={!isSelected(item.href, item.strict)}
        overflowX="hidden"
        w="full"
        className="subNav"
      >
        {(item.subNav || []).map((sm, ii) =>
          sm.requiredAppAccess ? (
            <AppAuth key={sm.text} requiredAppAccess={sm.requiredAppAccess}>
              <SubNav sm={sm} ii={ii} isSelected={isSelected} isOpen={isOpen} />
            </AppAuth>
          ) : (
            <SubNav key={sm.text} sm={sm} ii={ii} isSelected={isSelected} isOpen={isOpen} />
          ),
        )}
      </VStack>
    </VStack>
  );
};

type SubNavProps = {
  sm: NavItemModel;
  ii: number;
  isSelected: (href: string, strict?: boolean) => boolean;
  isOpen: boolean;
};
const SubNav = ({ sm, ii, isSelected, isOpen }: SubNavProps) => {
  return (
    <Link
      key={ii}
      py={2}
      px={3}
      // pr={9}
      as={RouterLink}
      to={sm.href}
      w="100%"
      // whiteSpace="nowrap"
      borderColor="transparent"
      borderLeftWidth="3px"
      className={`${isSelected(sm.href) ? 'selected' : ''}`}
    >
      <HStack pl={isOpen ? 8 : undefined} spacing={4} alignItems="center">
        {sm.icon ? (
          <Icon
            as={sm.icon} //color="sideNavMenu.iconColor"
            fontSize={18}
          />
        ) : (
          <Box>
            {createElement<IconProps>(sm.iconComponent!, {
              color: brandColors.primary.teal[400],
              size: 18,
            })}
          </Box>
        )}
        <Text noOfLines={isOpen ? undefined : 1}>{sm.text}</Text>
      </HStack>
    </Link>
  );
};

export default NavItem;
