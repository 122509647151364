import {
    Box,
    Divider,
    FormControl,
    Text,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    VStack,
    Flex,
    Textarea,
    ButtonGroup,
    Button,
    Alert,
    AlertIcon,
    Select,
  } from '@chakra-ui/react';
  import { useFormik } from 'formik';
  import * as Yup from 'yup';
  import { AddEmployeeOnboardingRequestModel } from '../../../app/services/dme/api/types';
  import dayjs from 'dayjs';
  import CustomDatePicker from '../../../components/CustomDatePicker';
  import { useState, useEffect, ChangeEvent } from 'react';
import { useAppSelector } from '../../../app/state/hooks';
import { useAddEmployeeOnboardingRequestMutation } from '../../../app/services/dme/api/employeeOnboardingRequest';
import { useGetRefSpecialtyListQuery } from '../../../app/services/dme/api/refSpecialty';
import { useGetRefSquadListQuery } from '../../../app/services/dme/api/refSquad';
  
  const minDate = dayjs().toDate();
  const maxDate = dayjs().add(1, 'year').toDate();
  // const defaultDate = dayjs().add(30, "day").toDate();
  const _requestDetailsMaxLength = 2000;
  
  const FormSchema = Yup.object().shape({
    first_name: Yup.string().label('First Name').max(100).required(),
    last_name: Yup.string().label('Last Name').max(100).required(),
    personal_email_address: Yup.string().label('Personal Email').max(200).required(),
    start_date: Yup.string()
      .test((str, { createError }) => {
        if (!str) return true;
        const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
        return date.isValid()
          ? true
          : createError({
              message: 'Start Date in invalid',
              path: 'start_date',
            });
      })
      .test((str, { createError }) => {
        if (!str) return true;
        const date = dayjs(str, 'YYYY-MM-DD', true);
        return date.isAfter(minDate)
          ? true
          : createError({
              message: 'Start Date must be later than today',
              path: 'start_date',
            });
      })
      .test((str, { createError }) => {
        if (!str) return true;
        const date = dayjs(str, 'YYYY-MM-DD', true);
        return date.isBefore(maxDate)
          ? true
          : createError({
              message: 'Start Date may not exceed one year from current date',
              path: 'start_date',
            });
      })
      .label('Start Date')
      .required(),
    squad: Yup.string().label('Squad').max(1000).optional(),
    specialty: Yup.string().label('Specialty').max(1000).optional(),
    geographical_location: Yup.string().label('Geographical Location').max(100).optional(),
    virtual_machine_flag: Yup.boolean().label('Virtual Machine').optional(),
    additional_notes: Yup.string().label('Additional Notes').max(2000).optional(),
  });
  
  const CreateEmployeeOnboardingRequest = () => {
    const [StartDate, setStartDate] = useState<Date | undefined>();
    const [alertMessage, setAlertMessage] = useState('');
  
    const [postAsync, postDetail] = useAddEmployeeOnboardingRequestMutation();
    const specialtyDetails = useGetRefSpecialtyListQuery();
    const squadDetails = useGetRefSquadListQuery();

    //get user info
    const { logonUser } = useAppSelector(s => s.user);
    const userId = logonUser?.ref_user_id ?? 0;
    //data.request_created_ref_user_id = userId;
  
    const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik<AddEmployeeOnboardingRequestModel>(
      {
        enableReinitialize: true,
        validationSchema: FormSchema,
        initialValues: {
          first_name: '',
          last_name: '',
          personal_email_address: '',
          start_date: '',
          squad: '',
          specialty: '',
          geographical_location: '',
          additional_notes: '',
          virtual_machine_flag: false,
          requested_by_ref_user_id: 0,
        },
        onSubmit: values => {
          values.requested_by_ref_user_id = userId;
          postAsync(values);
        },
      },
    );
  
    const clearModal = () => {
      resetForm();
      setAlertMessage('');
      setStartDate(undefined);
    };
  
    useEffect(() => {
      const { isSuccess, isError, isLoading } = postDetail;
  
      if (isSuccess) {
        clearModal();
        setAlertMessage('Request successfully submitted.');
        setTimeout(() => {
          setAlertMessage('');
        }, 3000);
      } else if (isError) {
        setAlertMessage('There was an error processing your request, please try again later.');
      } else {
        setAlertMessage('');
      }
  
      if (isLoading) {
        setAlertMessage('');
      }
    }, [postDetail]);
  
    return (
      <VStack>
        <Heading size="md">Create Employee Onboarding Request</Heading>
        <Divider />
  
        <form onSubmit={handleSubmit}>
          <VStack spacing="5" pt={5} w="container.sm">
            <FormControl isInvalid={!!errors.first_name && touched.first_name} display="flex">
              <FormLabel minW="150px" htmlFor="first_name">
                First Name
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="first_name"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      first_name: e.target.value.trim(),
                    })
                  }
                  value={values.first_name}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </Box>
            </FormControl>
            
            <FormControl isInvalid={!!errors.last_name && touched.last_name} display="flex">
              <FormLabel minW="150px" htmlFor="last_name">
                Last Name
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="last_name"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      last_name: e.target.value.trim(),
                    })
                  }
                  value={values.last_name}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.personal_email_address && touched.personal_email_address} display="flex">
              <FormLabel minW="150px" htmlFor="personal_email_address">
                Personal Email Address
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="personal_email_address"
                  name="personal_email_address"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      personal_email_address: e.target.value.trim(),
                    })
                  }
                  value={values.personal_email_address}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.personal_email_address}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.start_date && touched.start_date}>
              <Flex>
                <FormLabel minW="150px" htmlFor="start_date">
                  Start Date
                </FormLabel>
                <VStack w="200px">
                  <CustomDatePicker
                    id="start_date"
                    name="start_date"
                    date={StartDate}
                    onDateChange={(date: Date) => {
                      setValues({
                        ...values,
                        start_date: dayjs(date).format('YYYY-MM-DD'),
                      });
                      setStartDate(date);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'brand.main.default',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                        },
                        selectedBtnProps: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.400',
                        },
                      },
                      inputProps: {
                        placeholder: 'YYYY-MM-DD',
                        size: 'sm',
                        value: values.start_date,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            start_date: e.target.value,
                          });
                          if (dayjs(e.target.value)?.isValid()) {
                            setStartDate(dayjs(e.target.value).toDate());
                          }
                        },
                      },
                    }}
                  />
                  <FormErrorMessage>{errors.start_date}</FormErrorMessage>
                </VStack>
              </Flex>
            </FormControl>
          
            <FormControl isInvalid={!!errors.squad && !!touched.squad} display="flex">
              <FormLabel minW="150px">Squad</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="squad"
                  name="squad"
                  placeholder="Select option"
                  width= '200px'
                  isDisabled={squadDetails.isLoading || squadDetails.isFetching}
                  value={values.squad || ''}  // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedSquad = e.target.value;
                    const selectedSquadObj = squadDetails.data?.find(m => m.squad_name === selectedSquad);
                    setValues({
                      ...values,
                      squad: selectedSquadObj?.squad_name || '',
                    });
                  }}
                >
                  {(squadDetails.data ?? []).map(m => (
                    <option key={m.squad_name} value={m.squad_name}>
                      {m.squad_name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.squad}</FormErrorMessage>
              </Box>
            </FormControl>


            <FormControl isInvalid={!!errors.specialty && !!touched.specialty} display="flex">
              <FormLabel minW="150px">Specialty</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="specialty"
                  name="specialty"
                  placeholder="Select option"
                  width= '200px'
                  isDisabled={specialtyDetails.isLoading || specialtyDetails.isFetching}
                  value={values.specialty || ''}  // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedSpecialty = e.target.value;
                    const selectedSpecialtyObj = specialtyDetails.data?.find(m => m.specialty_name === selectedSpecialty);
                    setValues({
                      ...values,
                      specialty: selectedSpecialtyObj?.specialty_name || '',
                    });
                  }}
                >
                  {(specialtyDetails.data ?? []).map(m => (
                    <option key={m.specialty_name} value={m.specialty_name}>
                      {m.specialty_name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.specialty}</FormErrorMessage>
              </Box>
            </FormControl>

            {/* <FormControl isInvalid={!!errors.geographical_location && touched.geographical_location} display="flex">
              <FormLabel minW="150px" htmlFor="squad">
                Geographical Location
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  width= '200px'
                  id="geographical_location"
                  name="geographical_location"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      geographical_location: e.target.value.trim(),
                    })
                  }
                  value={values.geographical_location}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.geographical_location}</FormErrorMessage>
              </Box>
            </FormControl> */}

            <FormControl isInvalid={!!errors.geographical_location && !!touched.geographical_location} display="flex">
              <FormLabel minW="150px">Geographical Location</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="geographical_location"
                  name="geographical_location"
                  placeholder="Select option"
                  width="200px"
                  value={values.geographical_location || ''} // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedLocation = e.target.value;
                    setValues({
                      ...values,
                      geographical_location: selectedLocation,
                    });
                  }}
                >
                  <option value="PH">PH</option>
                  <option value="USA">USA</option>
                </Select>
                <FormErrorMessage>{errors.geographical_location}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.additional_notes && touched.additional_notes} alignItems="start">
              <Box textAlign="right">
                {(values.additional_notes?.length || 0)> 0 && (
                  <Text as="small">{`${values.additional_notes?.length}/${2000}`}</Text>
                )}
              </Box>
              <Flex>
                <FormLabel minW="150px" htmlFor="additional_notes">
                  Additional Notes
                </FormLabel>
                <Box width="full">
                  <Textarea
                    size="sm"
                    id="additional_notes"
                    name="additional_notes"
                    onChange={handleChange}
                    onBlur={e =>
                      setValues({
                        ...values,
                        additional_notes: e.target.value.trim(),
                      })
                    }
                    value={values.additional_notes}
                    maxLength={_requestDetailsMaxLength}
                  />
                  <FormErrorMessage>{errors.additional_notes}</FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <FormControl isInvalid={false} display="flex">
              <FormLabel minW="150px">Virtual Machine</FormLabel>
                <Box minW="100px">
                  <Select
                        size="sm"
                        id="virtual_machine_flag"
                        name="virtual_machine_flag"
                        value={values.virtual_machine_flag ? 'true' : 'false'}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const selectedValue = e.target.value === 'true';
                          setValues({
                            ...values,
                            virtual_machine_flag: selectedValue,
                          });
                        }}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </Box>
              <FormErrorMessage></FormErrorMessage>
            </FormControl>

            {alertMessage && (
              <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {alertMessage}
              </Alert>
            )}
  
            <ButtonGroup>
              <Button isDisabled={postDetail.isLoading} variant="outline" onClick={() => clearModal()} ml="auto">
                Cancel
              </Button>
              <Button isLoading={postDetail.isLoading} variant="solid" colorScheme="brand.main" type="submit">
                Submit
              </Button>
            </ButtonGroup>
          </VStack>
        </form>
      </VStack>
    );
  };
  
  export default CreateEmployeeOnboardingRequest;
  