import { Heading, Table, Tbody, Td, Th, Tr, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { DataProductMyAccessDetailInfoModel } from '../../../../app/services/dme/api/types';

type Props = {
  details: DataProductMyAccessDetailInfoModel;
};

const MyAccessDetailIndex: FC<Props> = ({ details }) => {
  return (
    <VStack pt={3}>
      <Heading size="sm">Request Details</Heading>
      <Table>
        <Tbody>
          <Tr>
            <Th>Method</Th>
            <Td>{details.access_method}</Td>
            <Td>&nbsp;</Td>
            <Th>Request ID</Th>
            <Td>{details.request_id}</Td>
          </Tr>

          <Tr>
            <Th>Role Name</Th>
            <Td>{details.role_name}</Td>
            <Td>&nbsp;</Td>
            <Th>Requestor</Th>
            <Td>{details.requestor}</Td>
          </Tr>

          <Tr>
            <Th>Domain</Th>
            <Td>{details.domain}</Td>
            <Td>&nbsp;</Td>
            <Th>Current Status</Th>
            <Td>{details.current_status}</Td>
          </Tr>

          <Tr>
            <Th>Data Product Name</Th>
            <Td>{details.data_product_name}</Td>
            <Td>&nbsp;</Td>
            <Th>Properties</Th>
            <Td>{details.delivery_mode_properties}</Td>
          </Tr>

          <Tr>
            <Th>Delivery Mode</Th>
            <Td>{details.delivery_mode}</Td>
            <Td>&nbsp;</Td>
            <Th>Documentation</Th>
            <Td>{details.delivery_mode_user_documentation}</Td>
          </Tr>

          <Tr>
            <Th>Access Business Justification</Th>
            <Td>{details.access_request_business_justification}</Td>
            <Td>&nbsp;</Td>
            <Th>Denied Explanation</Th>
            <Td>{details.access_denied_notes}</Td>
          </Tr>
        </Tbody>
      </Table>
    </VStack>
  );
};

export default MyAccessDetailIndex;
