import { dmeApi } from '.';
import { removeUnwantedCharacters } from '../../../helpers/stringHelper';
import {
  GoEdsDetailsPageDiscussionModel,
  GoEdsPostAttachmentModel,
  ToggleGoEdsPostReactionModel,
  UpdateGoEdsPostModel,
} from './types';

const baseUrl = '/governanceofficeedspost';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getGoEdsDetailsDiscussionPostsByGoEdsId: builder.query<GoEdsDetailsPageDiscussionModel[], number>({
      query: goEdsId => baseUrl + `/discussions/posts/go_eds_request_id/${goEdsId}?state_flag=false`,
    }),

    getGoEdsPostById: builder.query<GoEdsDetailsPageDiscussionModel[], number>({
      query: goEdsPostId => baseUrl + `/discussions/posts/go_eds_post_id/${goEdsPostId}`,
    }),

    getGoEdsPostAttachmentsById: builder.query<Array<GoEdsPostAttachmentModel>, number>({
      query: go_eds_post_id => baseUrl + `/attachment/go_eds_post_id/${go_eds_post_id}`,
      providesTags: ['GoEdsPostAttachments'],
    }),

    addGoEdsPost: builder.mutation<{ go_eds_post_id: number }, FormData>({
      query: params => ({
        url: baseUrl,
        method: 'POST',
        body: params,
      }),
    }),

    postGoEdsPostToggleReaction: builder.mutation<void, ToggleGoEdsPostReactionModel>({
      query: params => ({
        url: baseUrl + '/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateGoEdsPost: builder.mutation<void, UpdateGoEdsPostModel>({
      query: params => ({
        url: baseUrl,
        method: 'PUT',
        body: { ...params, post_message: removeUnwantedCharacters(params.post_message) },
      }),
    }),

    deleteGoEdsPost: builder.mutation<void, number>({
      query: go_eds_post_id => ({
        url: baseUrl + `/` + go_eds_post_id,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetGoEdsDetailsDiscussionPostsByGoEdsIdQuery,
  useLazyGetGoEdsPostByIdQuery,
  useGetGoEdsPostAttachmentsByIdQuery,
  useAddGoEdsPostMutation,
  usePostGoEdsPostToggleReactionMutation,
  useUpdateGoEdsPostMutation,
  useDeleteGoEdsPostMutation,
} = extendedApi;
