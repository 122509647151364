import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { FC, Fragment, useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import { ReportHubAddPortfolioTabProps, addReportHubPortfolioTabs } from '.';
import { defaultErrorMessage } from '../../../app/constants';
import { useGetRefTagListQuery } from '../../../app/services/dme/api/refTags';
import { useAddReportPortfolioMutation } from '../../../app/services/dme/api/reportHub';
import { AddReportHubPortfolioModel } from '../../../app/services/dme/api/types';
import { PortfolioTagsFormSchema, initialValuesReportPortfolio, tagFields } from '../helpers';

const ReportHubAddPortfolioTags: FC<ReportHubAddPortfolioTabProps> = ({ tabIndex, setTabIndex, data, setData }) => {
  const actionRef = useRef<undefined | 'next' | 'back'>();

  const toast = useToast();
  const tagsDetails = useGetRefTagListQuery();
  const [addPortfolio, addPortfolioDetails] = useAddReportPortfolioMutation();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormik<AddReportHubPortfolioModel['tags']>({
    enableReinitialize: true,
    validationSchema: PortfolioTagsFormSchema,
    initialValues: data.tags,
    onSubmit: (values, form) => {
      if (actionRef.current) {
        const d: AddReportHubPortfolioModel = { ...data, tags: values };
        setData(d);
        if (actionRef.current === 'next') {
          addPortfolio(d)
            .unwrap()
            .then(() => {
              toast({ description: 'Portfolio successfully added', status: 'success' });
              setData(initialValuesReportPortfolio);
              setTabIndex(0);
            })
            .catch(() => toast({ description: defaultErrorMessage, status: 'error' }));
        } else {
          setTabIndex(i => i + -1);
        }
      }
    },
  });

  return (
    <VStack>
      {values.length > 0 && (
        <TableContainer>
          <Table
            variant="simple"
            sx={{
              td: {
                verticalAlign: 'top',
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>{tagFields.ref_tag_id.label}</Th>
                <Th>Description</Th>
                <Th>{tagFields.notes.label}</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {values.map((r, i) => {
                return (
                  <Fragment key={i}>
                    <Tr>
                      <Td maxW="300px" w="300px">
                        <FormControl isInvalid={!!errors[i]?.ref_tag_id && !!touched[i]?.ref_tag_id} display="flex">
                          <Box w="100%">
                            <Select
                              size="sm"
                              id="ref_tag_id"
                              name="ref_tag_id"
                              placeholder={tagsDetails.isLoading || tagsDetails.isFetching ? 'Loading...' : ''}
                              isDisabled={tagsDetails.isLoading || tagsDetails.isFetching}
                              useBasicStyles
                              menuPortalTarget={document.body}
                              value={(() => {
                                const val = tagsDetails.data?.find(m => m.ref_tag_id === values[i].ref_tag_id);
                                return val
                                  ? {
                                      label: val.tag_name,
                                      value: val.ref_tag_id,
                                    }
                                  : undefined;
                              })()}
                              options={[
                                ...(
                                  tagsDetails.data?.filter(t => !values.some(v => v.ref_tag_id === t.ref_tag_id)) ?? []
                                ).map(m => {
                                  return {
                                    label: m.tag_name,
                                    value: m.ref_tag_id,
                                  };
                                }),
                              ]}
                              onChange={e => {
                                if (e) {
                                  const tmp = [...values];
                                  tmp[i].ref_tag_id = e.value;
                                  setValues(tmp);
                                }
                              }}
                            />
                            <FormErrorMessage>{errors[i]?.ref_tag_id}</FormErrorMessage>
                          </Box>
                        </FormControl>
                      </Td>
                      <Td maxW="300px" w="300px" whiteSpace="initial">
                        <Text fontSize="sm">
                          {tagsDetails.data?.find(t => t.ref_tag_id === values[i].ref_tag_id)?.tag_desc}
                        </Text>
                      </Td>
                      <Td>
                        <FormControl isInvalid={!!errors[i]?.notes && !!touched[i]?.notes} h="100%">
                          <Input
                            size="sm"
                            id={i + '.notes'}
                            name={i + '.notes'}
                            value={values[i].notes ?? ''}
                            maxLength={tagFields.notes.max}
                            onChange={handleChange}
                            onBlur={handleChange}
                          />
                          <FormErrorMessage>{errors[i]?.notes}</FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <Tooltip label="Delete">
                          <IconButton
                            color="brand.error"
                            variant="link"
                            aria-label="Delete"
                            icon={<FaTrash />}
                            onClick={() => {
                              const tmp = [...values];
                              tmp.splice(i, 1);
                              setValues(tmp);
                            }}
                            minWidth={1}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Box>
        <Button
          colorScheme="brand.main"
          size="sm"
          isDisabled={addPortfolioDetails.isLoading}
          onClick={() => {
            setValues([
              ...values,
              {
                ref_tag_id: 0,
                notes: '',
              },
            ]);
          }}
        >
          Create New
        </Button>
      </Box>

      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {addReportHubPortfolioTabs.length}
        </Text>
        <ButtonGroup>
          <Button
            size="sm"
            isDisabled={addPortfolioDetails.isLoading}
            onClick={() => {
              actionRef.current = 'back';
              submitForm();
            }}
          >
            Previous
          </Button>
          <Button
            size="sm"
            isLoading={addPortfolioDetails.isLoading}
            colorScheme="brand.main"
            onClick={() => {
              actionRef.current = 'next';
              submitForm();
            }}
          >
            Submit
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default ReportHubAddPortfolioTags;
