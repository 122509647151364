import {
  Heading,
  HStack,
  VStack,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  Flex,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdInfo } from 'react-icons/md';
import { Row } from 'react-table';
import { getFileName } from '../../../../app/helpers/stringHelper';
import { ValueOf } from '../../../../app/helpers/utilities';
import {
  useGetRefDeliveryModeListForViewQuery,
  useGetRefDeliveryModeListQuery,
} from '../../../../app/services/dme/api/refDeliveryMode';
import { useGetRefRefreshFrequencyQuery } from '../../../../app/services/dme/api/refRefreshFrequency';
import {
  DataProductSelectPageModesForViewModel,
  DataProductSelectPageModesModel,
} from '../../../../app/services/dme/api/types';
import { EditDataProductModel } from '../../../../app/services/types';
import EditableCell from '../../../../components/CustomTable/EditableCell';
import { Fragment } from 'react';

type Props = {
  initialValues: EditDataProductModel;
  data: EditDataProductModel;
  onChangeData: (field: keyof EditDataProductModel, data: ValueOf<EditDataProductModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const DataProductSelectedMode = ({ data, onChangeData, errors, touched, title }: Props) => {
  const { data: modeData, isLoading: isLoadingMode, isFetching: isFetchingMode } = useGetRefDeliveryModeListQuery();
  const { data: modeForViewData, isLoading: isLoadingModeForView } = useGetRefDeliveryModeListForViewQuery();

  const {
    data: frequencyData,
    isLoading: isLoadingFrequency,
    isFetching: isFetchingRequency,
  } = useGetRefRefreshFrequencyQuery();

  const generateIdForAddedItem = (): number => {
    return data.modeModel && data.modeModel.some(f => f.dp_data_product_delivery_mode_id < 1)
      ? Math.min.apply(
          Math,
          data.modeModel.map(m => m.dp_data_product_delivery_mode_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow = {
      dp_data_product_delivery_mode_id: generateIdForAddedItem(),
      dp_data_product_id: data.data_product_id,
      ref_delivery_mode_id: 0,
      mode: '',
      mode_description: '',
      properties: '',
      documentation: '',
      preview_data: '',
      refresh_frequency: '',
      ref_refresh_frequency_id: 0,
      is_deleted_flag: false,
      view_flag: false,
      modesForView: [],
    };
    onChangeData('modeModel', data.modeModel ? [...data.modeModel, newRow] : [newRow]);
  };

  const onUpdateData = (
    rowIndex: number,
    columnId: string,
    value: string | File | number | number[],
    fileColumnName?: string,
  ) => {
    const finalValue = value instanceof File ? (value as File).name : value;
    const fileValue = value as File;
    const desc = modeData ? modeData.find(f => f.ref_delivery_mode_id === value)?.delivery_mode_desc || '' : '';
    data.modeModel &&
      onChangeData(
        'modeModel',
        data.modeModel.map((row, index) => {
          if (index === rowIndex && data.modeModel) {
            let item: DataProductSelectPageModesModel = {
              ...data.modeModel[rowIndex],
              [columnId]: finalValue,
              mode_description: columnId === 'ref_delivery_mode_id' ? desc : data.modeModel[rowIndex].mode_description,
            };
            if (fileColumnName) {
              item = {
                ...item,
                [fileColumnName]: fileValue,
              };
            }
            return item;
          }
          return row;
        }),
      );
  };

  const onRemove = (item: DataProductSelectPageModesModel) => {
    data.modeModel &&
      onChangeData(
        'modeModel',
        data.modeModel.filter(f => f.dp_data_product_delivery_mode_id != item.dp_data_product_delivery_mode_id),
      );
  };

  const onAddSubNew = (mode: DataProductSelectPageModesModel, rowIndex: number) => () => {
    data.modeModel &&
      onChangeData(
        'modeModel',
        data.modeModel.map((row, index) => {
          if (index === rowIndex && data.modeModel) {
            return {
              ...row,
              modesForView: [
                ...row.modesForView,
                {
                  dp_data_product_delivery_mode_id: mode.dp_data_product_delivery_mode_id,
                  ref_delivery_mode_id: 0,
                  mode: '',
                  mode_description: '',
                  properties: '',
                },
              ],
            };
          }
          return row;
        }),
      );
  };

  const onUpdateViewData =
    (mode: DataProductSelectPageModesModel) =>
    (rowIndex: number, columnId: string, value: string | File | number | number[], fileColumnName?: string) => {
      data.modeModel &&
        onChangeData(
          'modeModel',
          data.modeModel.map(row => {
            if (row.dp_data_product_delivery_mode_id === mode.dp_data_product_delivery_mode_id) {
              return {
                ...row,
                modesForView: row.modesForView.map((m, i) => {
                  if (i === rowIndex) {
                    return {
                      ...m,
                      [columnId]: value,
                    };
                  }
                  return m;
                }),
              };
            }
            return row;
          }),
        );
    };

  const onRemoveView =
    (mode: DataProductSelectPageModesModel, modeView: DataProductSelectPageModesForViewModel, index: number) => () => {
      if (data.modeModel) {
        const list = mode.modesForView.slice();
        list.splice(index, 1);

        onChangeData(
          'modeModel',
          data.modeModel.map(row => {
            if (modeView.dp_data_product_delivery_mode_id === row.dp_data_product_delivery_mode_id) {
              return {
                ...row,
                modesForView: list,
              };
            }
            return row;
          }),
        );
      }
    };

  const isViewMode = (d: DataProductSelectPageModesModel) => {
    return !!(modeData && modeData.find(f => f.ref_delivery_mode_id === d.ref_delivery_mode_id && f.view_flag));
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      <HStack>
        <Heading size="sm">{title}</Heading>
        <Button
          size="sm"
          leftIcon={<FiRefreshCcw />}
          onClick={() => {
            data.modeModel && onChangeData('modeModel', data.modeModel, true);
          }}
        >
          Refresh
        </Button>
      </HStack>
      {isLoadingMode || isLoadingModeForView || isLoadingFrequency ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data.modeModel ? null : (
      <VStack>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>Mode</Th>
              <Th minW="72">Properties</Th>
              <Th>Documentation</Th>
              <Th whiteSpace="nowrap">Preview Data</Th>
              <Th>Refresh Frequency</Th>
              <Th>Action</Th>
            </Tr>
            {(data.modeModel?.filter(f => !f.is_deleted_flag) || []).map((d, index) => (
              <Fragment key={d.dp_data_product_delivery_mode_id}>
                <Tr
                  key={d.dp_data_product_delivery_mode_id}
                  sx={{
                    td: {
                      borderBottom: isViewMode(d) ? 'none' : undefined,
                    },
                  }}
                >
                  <Td>
                    {modeData && (
                      <EditableCell
                        name={`modeModel.${index}.ref_delivery_mode_id`}
                        value={d.ref_delivery_mode_id}
                        row={{ index: index } as Row}
                        column={{ id: 'ref_delivery_mode_id' } as any}
                        updateData={onUpdateData}
                        type="dropdown"
                        dropdownOptions={modeData.map(m => {
                          return { text: m.delivery_mode, value: m.ref_delivery_mode_id.toString() };
                        })}
                        isInvalid={
                          !!(
                            errors.modeModel &&
                            (errors.modeModel as unknown as DataProductSelectPageModesModel[])[index]
                              ?.ref_delivery_mode_id
                          ) &&
                          !!(
                            touched.modeModel &&
                            (touched.modeModel as unknown as DataProductSelectPageModesModel[])[index]
                              ?.ref_delivery_mode_id
                          )
                        }
                      />
                    )}
                  </Td>
                  {/* <Td>
                  <Text noOfLines={1} title={d.mode_description}>
                    {d.mode_description}
                  </Text>
                </Td> */}
                  <Td>
                    {
                      <EditableCell
                        name={`modeModel.${index}.properties`}
                        value={d.properties}
                        row={{ index: index } as Row}
                        column={{ id: 'properties' } as any}
                        updateData={onUpdateData}
                        type="textarea"
                        isInvalid={
                          !!(
                            errors.modeModel &&
                            (errors.modeModel as unknown as DataProductSelectPageModesModel[])[index]?.properties
                          ) &&
                          !!(
                            touched.modeModel &&
                            (touched.modeModel as unknown as DataProductSelectPageModesModel[])[index]?.properties
                          )
                        }
                      />
                    }
                  </Td>

                  <Td>
                    {
                      <EditableCell
                        name={`modeModel.${index}.documentation`}
                        value={
                          d.documentation && d.documentation.includes('-document-')
                            ? getFileName(d.documentation).split('-document-')[1]
                            : d.documentation
                        }
                        row={{ index: index } as Row}
                        column={{ id: 'documentation' } as any}
                        type="file"
                        fileColumnName="documentation_file"
                        updateData={onUpdateData}
                      />
                    }
                  </Td>
                  <Td>
                    {
                      <EditableCell
                        name={`modeModel.${index}.preview_data`}
                        value={
                          d.preview_data && d.preview_data.includes('-preview-')
                            ? getFileName(d.preview_data).split('-preview-')[1]
                            : d.preview_data
                        }
                        row={{ index: index } as Row}
                        column={{ id: 'preview_data' } as any}
                        type="file"
                        fileColumnName="preview_data_file"
                        updateData={onUpdateData}
                      />
                    }
                  </Td>
                  <Td>
                    {frequencyData && (
                      <EditableCell
                        name={`modeModel.${index}.ref_refresh_frequency_id`}
                        value={d.ref_refresh_frequency_id}
                        row={{ index: index } as Row}
                        column={{ id: 'ref_refresh_frequency_id' } as any}
                        updateData={onUpdateData}
                        type="dropdown"
                        dropdownOptions={frequencyData.map(m => {
                          return {
                            text: m.refresh_frequency,
                            value: m.ref_refresh_frequency_id.toString(),
                          };
                        })}
                        isInvalid={
                          !!(
                            errors.modeModel &&
                            (errors.modeModel as unknown as DataProductSelectPageModesModel[])[index]
                              ?.ref_refresh_frequency_id
                          ) &&
                          !!(
                            touched.modeModel &&
                            (touched.modeModel as unknown as DataProductSelectPageModesModel[])[index]
                              ?.ref_refresh_frequency_id
                          )
                        }
                      />
                    )}
                  </Td>
                  <Td textAlign="center">
                    {
                      <ButtonGroup spacing={1}>
                        <Tooltip label="Delete">
                          <IconButton
                            color="brand.error"
                            variant="link"
                            aria-label="Delete"
                            icon={<FaTrash />}
                            onClick={() => onRemove(d)}
                            minWidth={1}
                          />
                        </Tooltip>
                      </ButtonGroup>
                    }
                  </Td>
                </Tr>
                {isViewMode(d) && (
                  <>
                    {d.modesForView.map((modeView, modeViewIndex) => (
                      <Tr
                        key={modeViewIndex}
                        sx={{
                          td: {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        <Td pl="8">
                          {modeForViewData && (
                            <EditableCell
                              name={`modeModel.modesForView.${modeViewIndex}.ref_delivery_mode_id`}
                              value={modeView.ref_delivery_mode_id}
                              row={{ index: modeViewIndex } as Row}
                              column={{ id: 'ref_delivery_mode_id' } as any}
                              updateData={onUpdateViewData(d)}
                              type="dropdown"
                              dropdownOptions={modeForViewData.map(m => {
                                return { text: m.delivery_mode, value: m.ref_delivery_mode_id.toString() };
                              })}
                              isInvalid={
                                !!(((errors.modeModel as unknown as DataProductSelectPageModesModel[]) ?? [])[index]
                                  ?.modesForView ?? [])[modeViewIndex]?.ref_delivery_mode_id &&
                                !!(((touched.modeModel as unknown as DataProductSelectPageModesModel[]) ?? [])[index]
                                  ?.modesForView ?? [])[modeViewIndex]?.ref_delivery_mode_id
                              }
                            />
                          )}
                        </Td>
                        <Td pl="8">
                          <EditableCell
                            name={`modeModel.modesForView.${modeViewIndex}.properties`}
                            value={modeView.properties}
                            row={{ index: modeViewIndex } as Row}
                            column={{ id: 'properties' } as any}
                            updateData={onUpdateViewData(d)}
                            type="textarea"
                            isInvalid={
                              !!(((errors.modeModel as unknown as DataProductSelectPageModesModel[]) ?? [])[index]
                                ?.modesForView ?? [])[modeViewIndex]?.properties &&
                              !!(((touched.modeModel as unknown as DataProductSelectPageModesModel[]) ?? [])[index]
                                ?.modesForView ?? [])[modeViewIndex]?.properties
                            }
                          />
                        </Td>
                        <Td colSpan={3}></Td>
                        <Td textAlign="center">
                          <ButtonGroup spacing={1}>
                            <Tooltip label="Delete">
                              <IconButton
                                color="brand.error"
                                variant="link"
                                aria-label="Delete"
                                icon={<FaTrash />}
                                onClick={onRemoveView(d, modeView, modeViewIndex)}
                                minWidth={1}
                              />
                            </Tooltip>
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td pl="8" colSpan={6}>
                        <Button variant="outline" colorScheme="brand.main" size="sm" onClick={onAddSubNew(d, index)}>
                          Create Sub Mode
                        </Button>
                      </Td>
                    </Tr>
                  </>
                )}
              </Fragment>
            ))}
          </Tbody>
        </Table>
        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
            Create New
          </Button>
        </Box>
      </VStack>
    );
  }
};

export default DataProductSelectedMode;
