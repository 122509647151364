import { dmeApi } from '.';
import {
  GoEdsCommentAttachmentModel,
  GoEdsDetailsDiscussionTabModel,
  ToggleGoEdsCommentReactionModel,
  UpdateGoEdsCommentModel,
} from './types';

const baseUrl = '/governanceofficeedscomment';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getGoEdsDetailsDiscussionTab: builder.query<
      Array<GoEdsDetailsDiscussionTabModel>,
      {
        goEdsPostId: number;
        query: string;
      }
    >({
      query: params => baseUrl + `/discussions/tab/go_eds_post_id/${params.goEdsPostId}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getGoEdsDetailsDiscussionTabByComment: builder.query<
      Array<GoEdsDetailsDiscussionTabModel>,
      {
        go_eds_comment_id: number;
        query: string;
      }
    >({
      query: params => baseUrl + `/discussions/tab/go_eds_comment_id/${params.go_eds_comment_id}?${params.query}`,
      keepUnusedDataFor: 0,
    }),

    getGoEdsCommentAttachmentsById: builder.query<Array<GoEdsCommentAttachmentModel>, number>({
      query: go_eds_comment_id => baseUrl + `/attachment/go_eds_comment_id/${go_eds_comment_id}`,
      providesTags: ['GoEdsCommentAttachments'],
    }),

    postGoEdsComment: builder.mutation<{ go_eds_comment_id: number }, FormData>({
      query: params => ({
        url: baseUrl,
        method: 'POST',
        body: params,
      }),
    }),

    postGoEdsCommentToggleReaction: builder.mutation<void, ToggleGoEdsCommentReactionModel>({
      query: params => ({
        url: baseUrl + '/reaction/toggle',
        method: 'POST',
        body: params,
      }),
    }),

    updateGoEdsComment: builder.mutation<void, UpdateGoEdsCommentModel>({
      query: params => ({
        url: baseUrl,
        method: 'PUT',
        body: params,
      }),
    }),

    deleteGoEdsComment: builder.mutation<void, number>({
      query: go_eds_comment_id => ({
        url: baseUrl + `/${go_eds_comment_id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetGoEdsDetailsDiscussionTabQuery,
  useLazyGetGoEdsDetailsDiscussionTabQuery,
  useGetGoEdsDetailsDiscussionTabByCommentQuery,
  useLazyGetGoEdsDetailsDiscussionTabByCommentQuery,
  useGetGoEdsCommentAttachmentsByIdQuery,
  useLazyGetGoEdsCommentAttachmentsByIdQuery,
  usePostGoEdsCommentMutation,
  usePostGoEdsCommentToggleReactionMutation,
  useUpdateGoEdsCommentMutation,
  useDeleteGoEdsCommentMutation,
} = extendedApi;
