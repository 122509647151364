import { Heading, VStack } from '@chakra-ui/react';
import React from 'react';

type Props = {};

const UnAuthorized = (props: Props) => {
  return (
    <VStack align="stretch" alignItems="center" mt="9vh" mx="auto">
      <Heading size="4xl">401</Heading>
      <Heading size="xl">Unauthorized Access</Heading>
    </VStack>
  );
};

export default UnAuthorized;
