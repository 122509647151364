import { Box, ButtonGroup, IconButton, Skeleton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FaUnlockAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { useGetMyRolesExploreRolesQuery } from '../../../../app/services/dme/api/myRoles';
import { MyRoleDataset1Model, MyRolesExploreRolesDataModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';
import RequestAccessDialog from '../../RequestAccess';

type Props = {
  data: MyRoleDataset1Model[];
  isLoading: boolean;
};

const ResultData = ({ data, isLoading }: Props) => {
  const {
    data: dataRefRoleList,
    isLoading: isLoadingRefRoleList,
    isFetching,
  } = useGetMyRolesExploreRolesQuery({
    page_number: 1,
    page_size: 9999,
    search_string: '',
    sort_column: 'role_name',
    sort_order: 'asc',
  });
  const navigate = useNavigate();
  const Header = (refRoleList: MyRolesExploreRolesDataModel[]) => [
    {
      Header: 'Request Access',
      styles: { textAlign: 'center' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: MyRoleDataset1Model } }) => {
        console.log(original);
        return (
          <>
            <ButtonGroup spacing={2}>
              <RequestAccessDialog
                ref_role_list={refRoleList}
                ref_role_id={original.ref_role_id.toString()}
                role_name={original.role_name}
                access_request_business_justification={original.access_request_business_justification}
                triggerElement={
                  <Tooltip label="Request Access">
                    <IconButton
                      variant="link"
                      aria-label="Request Access"
                      icon={<FaUnlockAlt />}
                      minWidth={1}
                      colorScheme="brand.main"
                    />
                  </Tooltip>
                }
              />
            </ButtonGroup>
          </>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'access_request_status',
    },
    {
      Header: 'Role Name',
      accessor: 'role_name',
    },
    {
      Header: 'Request Date',
      accessor: 'access_requested_datetime_utc',
      Cell: ({ row: { original } }: { row: { original: MyRoleDataset1Model } }) => {
        return <>{convertUtcToLocal(original.access_requested_datetime_utc) || '-'}</>;
      },
    },
    {
      Header: 'Expiration Date',
      accessor: 'access_expiration_datetime_utc',
      Cell: ({ row: { original } }: { row: { original: MyRoleDataset1Model } }) => {
        return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
      },
    },
  ];

  const onClickRow = (row: MyRoleDataset1Model) => {
    navigate(`./selected/id/${row.dme_role_request_user_role_id}/details`);
  };

  return isLoading || isLoadingRefRoleList ? (
    <Stack mt={1}>
      {[...Array(8)].map((m, i) => (
        <Skeleton key={i} height="18px" />
      ))}
    </Stack>
  ) : (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        headers={Header(dataRefRoleList?.data || [])}
        isLoading={isLoading || isLoadingRefRoleList}
        isFetching={isLoading || isLoadingRefRoleList}
        data={data || []}
        pageCount={0}
        pageSize={5}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={() => {}}
        onPageSizeChange={() => {}}
        onPageSearch={() => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        onRowClick={(row: any) => onClickRow(row)}
      />
    </Box>
  );
};

export default ResultData;
