import { Box, Button, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Row } from 'react-table';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { RolesAppAccessSelectedAppAccessMutateModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';
import EditableCell from '../../../../components/CustomTable/EditableCell';
import { useAppAccessByRolesSelectedContext } from './AppAccessByRolesSelectedContext';

const AppAccessByRolesSelectedAppAccess = () => {
  const {
    appAccessDetail: { data, isLoading, isFetching },
    refAppAccessDetail,
    isEdit,
    form,
    onChangeValue,
  } = useAppAccessByRolesSelectedContext();

  const Header = useMemo(() => {
    const headers = [];
    headers.push(
      isEdit
        ? {
            Header: 'App Access Name',
            Cell: ({ row: { original, index } }: { row: Row<RolesAppAccessSelectedAppAccessMutateModel> }) => {
              return original.role_app_access_id > 0 ? (
                <>{original.app_access_name}</>
              ) : (
                <EditableCell
                  name={`appAccess.${original.ref_app_access_id}.ref_app_access_id`}
                  value={original.ref_app_access_id}
                  row={{ index: index } as Row}
                  column={{ id: 'ref_app_access_id' } as any}
                  updateData={handleUpdate(original)}
                  type="dropdown"
                  dropdownOptions={refAppAccessDetail.data?.map(m => {
                    return {
                      text: m.app_access_name,
                      value: m.ref_app_access_id.toString(),
                      isDisabled: form?.values.appAccess?.some(
                        s => s.ref_app_access_id === m.ref_app_access_id && s.isDeleted !== true,
                      ),
                    };
                  })}
                  isInvalid={
                    !!(
                      form?.errors.appAccess &&
                      (form.errors.appAccess as unknown as RolesAppAccessSelectedAppAccessMutateModel[])[index]
                        ?.ref_app_access_id
                    ) &&
                    !!(
                      form?.touched.appAccess &&
                      (form.touched.appAccess as unknown as RolesAppAccessSelectedAppAccessMutateModel[])[index]
                        ?.ref_app_access_id
                    )
                  }
                />
              );
            },
          }
        : {
            Header: 'App Access Name',
            accessor: 'app_access_name',
            styles: { whiteSpace: 'initial' },
          },
    );

    headers.push({
      Header: 'App Access Display Name',
      accessor: 'app_access_display_name',
      styles: { whiteSpace: 'initial' },
    });

    if (isEdit) {
      headers.push({
        Header: 'Action',
        styles: { whiteSpace: 'initial', textAlign: 'center' },
        Cell: ({ row: { original } }: { row: Row<RolesAppAccessSelectedAppAccessMutateModel> }) => {
          return (
            <Tooltip label="Delete">
              <IconButton
                pr="3"
                aria-label="Delete"
                color="brand.error"
                variant="link"
                icon={<FaTrash />}
                minWidth={1}
                onClick={handleDelete(original)}
              />
            </Tooltip>
          );
        },
      });
    } else {
      headers.push({
        Header: 'Last Modified',
        Cell: ({ row: { original } }: { row: Row<RolesAppAccessSelectedAppAccessMutateModel> }) => {
          return <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>;
        },
        styles: { whiteSpace: 'initial', width: '190px' },
      });
    }

    return headers;
  }, [isEdit, form, refAppAccessDetail.data]);

  const handleUpdate =
    (original: RolesAppAccessSelectedAppAccessMutateModel) =>
    (rowIndex: number, columnId: string, value: string | File | number | number[]) => {
      if (form) {
        onChangeValue(
          'appAccess',
          form.values.appAccess.map(m =>
            m.role_app_access_id === original.role_app_access_id
              ? { ...m, ...refAppAccessDetail.data?.find(f => f.ref_app_access_id === value) }
              : m,
          ),
        );
      }
    };

  const handleCreate = () => {
    if (form) {
      let role_app_access_id =
        form.values.appAccess.reduce(
          (prev, curr) => (prev < curr.role_app_access_id ? prev : curr.role_app_access_id),
          0,
        ) ?? 0;
      role_app_access_id -= 1;

      onChangeValue('appAccess', [
        ...form.values.appAccess,
        {
          role_app_access_id: role_app_access_id,
          ref_app_access_id: role_app_access_id,
          isDeleted: false,
          dataset: '',
          app_access_name: '',
          app_access_display_name: '',
          app_access_desc: '',
          row_modified_datetime_utc: '',
        },
      ]);
    }
  };

  const handleDelete = (original: RolesAppAccessSelectedAppAccessMutateModel) => () => {
    if (form)
      onChangeValue(
        'appAccess',
        form.values.appAccess.map(m =>
          m.role_app_access_id === original.role_app_access_id ? { ...m, isDeleted: true } : m,
        ),
      );
  };

  const displayedData = useMemo(() => {
    return isEdit && form ? form.values.appAccess.filter(f => !f.isDeleted) : data;
  }, [data, form, isEdit]);

  return isLoading || refAppAccessDetail.isLoading ? (
    <>Loading...</>
  ) : (
    <VStack>
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isFetching}
        data={displayedData || []}
        pageCount={0}
        pageSize={displayedData ? displayedData.length : 0}
        totalRecords={displayedData ? displayedData.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        showNoRecords
        // onRowClick={onClickRow}
      />

      {isEdit && (
        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={handleCreate}>
            Create New
          </Button>
        </Box>
      )}
    </VStack>
  );
};

export default AppAccessByRolesSelectedAppAccess;
