import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { createElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addBreadcrumb, sliceBreadcrumb } from '../../../app/helpers/navigationHelpers';
import {
  LoreLanguageDetailsPageDomainModel,
  LoreLanguageDetailsPageTagModel,
  LoreLanguageDetailsPageDetailModel,
} from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import LoreLanguageDetails from './Details';
import { initialValues } from './initialValues';
import * as Yup from 'yup';
import { WarningIcon } from '@chakra-ui/icons';
import LoreLanguageDomain from './Domain';
import LoreLanguageTags from './Tags';
import { usePostLoreLanguageMutation } from '../../../app/services/dme/api/editorLoreLanguage';
import { AddLoreLanguageModel, PostLoreLanguageModel } from '../../../app/services/types';
import { useGetLoreLanguageListQuery } from '../../../app/services/dme/api/glossary';
import { ValueOf } from '../../../app/helpers/utilities';

type Props = {};

const AddLoreLanguage = (props: Props) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { logonUser } = useAppSelector(s => s.user);
  const [localError, setLocalError] = useState<{ title: string; desc: string }>();
  const [localSuccess, setLocalSuccess] = useState<string>();
  const [tabErrorIndex, setTabErrorIndex] = useState<number>(-1);
  const [postAsync, postDetail] = usePostLoreLanguageMutation();
  const { data: loreLangListData, isLoading, isFetching } = useGetLoreLanguageListQuery();
  const tabs = [
    {
      label: 'Details',
      path: 'details',
      component: LoreLanguageDetails,
      onChange: (data: LoreLanguageDetailsPageDetailModel, isReset?: boolean) => {
        if (isReset) {
          resetForm();
        } else {
          setValues({ ...values, detailModel: data });
        }
        if (tabErrorIndex == 0) {
          setTabErrorIndex(-1);
          setLocalError(undefined);
        }
      },
      model: 'detailModel',
      isArray: false,
    },
    {
      label: 'Domain',
      path: 'domain',
      component: LoreLanguageDomain,
      onChange: (data: Array<LoreLanguageDetailsPageDomainModel>, isReset?: boolean) => {
        if (isReset) {
          resetForm();
        } else {
          setValues({ ...values, domainModel: data });
        }
        if (tabErrorIndex == 1) {
          setTabErrorIndex(-1);
          setLocalError(undefined);
        }
      },
      model: 'domainModel',
      isArray: true,
    },
    {
      label: 'Tags',
      path: 'tags',
      component: LoreLanguageTags,
      onChange: (data: Array<LoreLanguageDetailsPageTagModel>, isReset?: boolean) => {
        if (isReset) {
          resetForm();
        } else {
          setValues({ ...values, tagModel: data });
        }
        if (tabErrorIndex == 2) {
          setTabErrorIndex(-1);
          setLocalError(undefined);
        }
      },
      model: 'tagModel',
      isArray: true,
    },
  ];

  const FormSchema = Yup.object().shape({
    detailModel: Yup.object().shape({
      ll_term: Yup.string()
        .label('Term')
        .required()
        .test((str, { createError }) => {
          if (loreLangListData?.some(s => s.ll_term.toLowerCase() == str?.toLowerCase())) {
            createError({
              message: 'Term already exist',
              path: 'll_term',
            });
            return false;
          } else {
            return true;
          }
        }),
      ll_definition: Yup.string().label('Definition').required(),
      ll_application_context: Yup.string().label('Application Context').required(),
    }),
    domainModel: Yup.array<LoreLanguageDetailsPageDomainModel[]>().of(
      Yup.object().shape({
        ref_domain_id: Yup.number().moreThan(0, 'Domain is a required field').required().label('Domain'),
        domain_user_context: Yup.string().required().label('User Context'),
      }),
    ),
    tagModel: Yup.array<LoreLanguageDetailsPageTagModel[]>().of(
      Yup.object().shape({
        ref_tag_id: Yup.number().moreThan(0, 'Tag is a required field').required().label('Tag'),
      }),
    ),
  });

  const { handleSubmit, errors, touched, values, resetForm, setValues, validateForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      detailModel: initialValues.detailModel,
      domainModel: initialValues.domainModel,
      tagModel: initialValues.tagModel,
    },

    onSubmit: values => {
      const isLastTab = tabIndex == tabs.length - 1;
      if (isLastTab) {
        const finalValue: PostLoreLanguageModel = {
          ll_term: values.detailModel.ll_term,
          ll_definition: values.detailModel.ll_definition,
          ll_application_context: values.detailModel.ll_application_context,
          domainModel: values.domainModel.map(m => ({
            ...m,
            user_context: m.domain_user_context,
          })),
          ref_tag_ids: values.tagModel?.map(m => m.ref_tag_id) || [],
        };
        postAsync(finalValue);
      }
    },
  });

  const validate = async () => {
    setLocalError(undefined);
    handleSubmit(); // will trigger errors

    const validationError = await validateForm(values);

    const tab = tabs[tabIndex];
    const tempError = validationError as any;
    const tempValues = values as any;
    const isLastTab = tabIndex == tabs.length - 1;

    if (!tempError[tab.model]) {
      if (tab.isArray && tempValues[tab.model].length == 0 && !isLastTab) {
        setLocalError({ title: '', desc: `${tab.label} must have atleast 1 entry` });
      } else {
        !isLastTab && setTabIndex(tabIndex + 1);
      }
    } else {
      setLocalError({ title: '', desc: 'Some field(s) are invalid' });
    }
  };

  const onChange = (field: keyof AddLoreLanguageModel, data: ValueOf<AddLoreLanguageModel>, isReset?: boolean) => {
    let tempIndex;
    switch (field) {
      case 'detailModel':
        tempIndex = 0;
        break;
      case 'domainModel':
        tempIndex = 1;
        break;
      case 'tagModel':
        tempIndex = 2;
        break;
    }

    if (isReset) {
      resetForm();
    } else {
      setValues({
        ...values,
        [field]: data,
      });
    }
    if (tabErrorIndex == tempIndex) {
      setTabErrorIndex(-1);
      setLocalError(undefined);
    }
  };

  const onDuplicateError = (index: number) => {
    setTabIndex(index);
    setTabErrorIndex(index);
  };

  useEffect(() => {
    sliceBreadcrumb(0, 2);
    addBreadcrumb({ label: 'Add Glossary', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (tabErrorIndex === -1) {
      setLocalError(undefined);
      window.scrollTo(0, 0);
    }
  }, [tabIndex]);

  useEffect(() => {
    const { data, isSuccess, isError, isLoading } = postDetail;
    const resData: any = data;
    if (isSuccess && resData?.isSuccess) {
      setLocalSuccess('Glossary successfully added.');
      setTimeout(() => {
        resetForm();
        setTabIndex(0);
        setLocalSuccess('');
      }, 3000);
    } else if (resData && !resData.isSuccess) {
      let tabError = '';
      if (resData.msg.includes('uidx_ll_term')) {
        onDuplicateError(0);
        tabError = 'Cannot insert duplicate Glossary Term';
      }
      setLocalError({
        title: '',
        desc: tabError,
      });
    } else if (isError) {
      setLocalError({
        title: '',
        desc: 'There was an error processing your request, please try again later.',
      });
    } else {
      setLocalError(undefined);
    }

    if (isLoading) {
      setLocalSuccess('');
    }
  }, [postDetail]);

  useEffect(() => {
    console.log({ errors });
    if (Object.keys(errors).length === 0) {
      setLocalError(undefined);
    }
  }, [errors]);

  return (
    <VStack w="100%">
      <HStack>
        <HStack flex={1}>
          <Heading size="md" whiteSpace="nowrap">
            Add Glossary
          </Heading>
          {logonUser && (
            <ButtonGroup>
              <Button
                isLoading={postDetail.isLoading}
                variant="outline"
                colorScheme="brand.main"
                onClick={() => validate()}
                isDisabled={tabIndex != tabs.length - 1}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        </HStack>
        {localError && (
          <Alert py={2} status="error">
            <AlertIcon />
            {localError.title && <AlertTitle>{tabs[tabIndex].label}</AlertTitle>}
            <AlertDescription>{localError.desc}</AlertDescription>
          </Alert>
        )}
        {localSuccess && (
          <Alert py={2} status="success">
            <AlertIcon />
            <AlertDescription>{localSuccess}</AlertDescription>
          </Alert>
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex}>
          <TabList>
            {tabs.map((m, i) => {
              const error = errors as any;
              return (
                <Tab key={i}>
                  {m.label}
                  {(error[m.model] && localError) || tabErrorIndex == i ? (
                    <Icon color="brand.error" ml={2} as={WarningIcon} />
                  ) : (
                    <></>
                  )}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tabs.map((tabPanel, i) => (
              <TabPanel key={i}>
                {tabPanel &&
                  values &&
                  loreLangListData &&
                  createElement(tabPanel.component, {
                    onChangeData: onChange,
                    data: values,
                    isSubmitting: postDetail.isLoading,
                    errors: errors,
                    touched: touched,
                    title: tabPanel.label,
                    tabErrorIndex: tabErrorIndex,
                  })}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {tabs.length}
        </Text>
        <ButtonGroup>
          <Button size="sm" isDisabled={tabIndex == 0} onClick={() => setTabIndex(tabIndex - 1)}>
            Previous
          </Button>
          <Button
            size="sm"
            isDisabled={tabIndex == tabs.length - 1}
            onClick={() => {
              validate();
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default AddLoreLanguage;
