import { useAccount } from '@azure/msal-react';
import { ExtendedClaimsType } from '../services/types';

function useClaims() {
  const account = useAccount();

  return { ...account, idTokenClaims: account?.idTokenClaims as ExtendedClaimsType };
}

export default useClaims;
