import { Box, Button, Checkbox, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { ensureSafeDecodeUri } from '../../../app/helpers/utilities';
import { useGetDataProductResultsPageFilterQuery } from '../../../app/services/dme/api/dataProduct';
import { DataProductResultsPageFilterModel, FilterPaneItemsNew } from '../../../app/services/dme/api/types';

type IProps = {};

const defaultFilter = ['domain'];

const FilterPane: FC<IProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetDataProductResultsPageFilterQuery();
  const [toggledFilterGroups, setToggledFilterGroups] = useState<string[]>(defaultFilter);
  const [checkedFilters, setCheckedFilters] = useState<FilterPaneItemsNew[] | undefined>();

  const onFilterGroupClick = (filterGroupName: string) => {
    if (toggledFilterGroups.includes(filterGroupName)) {
      setToggledFilterGroups(s => s.filter(f => f.toLowerCase() !== filterGroupName));
    } else {
      setToggledFilterGroups(s => [...s, filterGroupName]);
    }
  };

  const isFilterGroupSelected = (filterGroupName: string) => {
    return toggledFilterGroups.includes(filterGroupName.toLowerCase());
  };

  const updateCheckedFilters = (obj: FilterPaneItemsNew, isChecked: boolean) => {
    if (isChecked) {
      const isExisting =
        checkedFilters?.some(
          s =>
            s.filter_detail.toLowerCase() === obj.filter_detail.toLowerCase() &&
            s.filter_header.toLowerCase() === obj.filter_header.toLowerCase(),
        ) ?? false;

      if (!isExisting) setCheckedFilters(s => [...(s || []), obj]);
    } else {
      setCheckedFilters(s => s?.filter(f => !(f.filter_detail === obj.filter_detail)));
    }
  };

  useEffect(() => {
    if (checkedFilters) {
      setSearchParams({
        ...checkedFilters.reduce((obj, val) => {
          const key = val.filter_header.toLowerCase();
          if (obj[key]) {
            obj[key] = obj[key] + ',' + val.filter_detail;
          } else {
            obj[key] = val.filter_detail;
          }
          return obj;
        }, {} as any),
        ...(searchParams.get('search') ? { search: searchParams.get('search') } : {}),
      });
    }
  }, [checkedFilters]);

  useEffect(() => {
    //if there are initial filter coming from url, then auto check filters based on url
    const filters: { filter_header: string; filter_detail: string[] }[] = [];
    searchParams.forEach((value, key) => {
      filters.push({
        filter_header: key,
        filter_detail: [...ensureSafeDecodeUri(value).split(',')],
      });
    });
    if (filters.length > 0 && data) {
      const initFilters: DataProductResultsPageFilterModel[] = [];
      filters.forEach(filter => {
        filter.filter_detail.forEach((detail: string) => {
          const item = data.rawData.find(f => f.filter_detail === detail);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      setCheckedFilters(
        initFilters.map(({ filter_detail, filter_header, filter_ref_id }) => ({
          filter_detail,
          filter_header,
          filter_ref_id,
        })),
      );

      setToggledFilterGroups(
        initFilters.length > 0 ? initFilters.map(m => m.filter_header.toLowerCase()) : defaultFilter,
      );
    }
  }, [data]);

  return (
    <VStack w="300px" h="100vh" overflowY="scroll" maxW={300} bgColor="gray.50" p={3} pt={2}>
      <HStack justifyContent="space-between">
        <Heading size="md">Filters</Heading>
        <Button
          size="sm"
          onClick={() => {
            setCheckedFilters([]);
            setSearchParams(searchParams.get('search') ? { search: searchParams.get('search') ?? '' } : {}, {
              replace: true,
            });
          }}
        >
          Clear
        </Button>
      </HStack>
      <Divider />
      <VStack>
        {isLoading ? (
          <Text>Loading...</Text>
        ) : (
          <>
            {data?.data.map(({ filter_header, filter_items, filter_order }, i) => (
              <Box key={i}>
                <Button
                  w="100%"
                  rightIcon={isFilterGroupSelected(filter_header) ? <HiChevronDown /> : <HiChevronRight />}
                  justifyContent="space-between"
                  size="sm"
                  onClick={() => {
                    onFilterGroupClick(filter_header.toLowerCase());
                  }}
                >
                  {filter_header}
                </Button>
                {isFilterGroupSelected(filter_header) && (
                  <VStack bgColor="gray.100" p={3} pt={0}>
                    {filter_items.map(({ filter_detail, filter_ref_id }, ii) => (
                      <Checkbox
                        key={ii}
                        name={`${filter_header}_${filter_detail}_${filter_ref_id}`}
                        onChange={(e: any) => {
                          updateCheckedFilters(
                            {
                              filter_ref_id,
                              filter_detail,
                              filter_header,
                            },
                            e.target.checked,
                          );
                        }}
                        isChecked={checkedFilters?.some(s => s.filter_detail === filter_detail)}
                        _hover={{ bgColor: 'gray.200' }}
                      >
                        <Text whiteSpace="initial" fontSize="sm" noOfLines={2} title={filter_detail} maxW="210px">
                          {filter_detail}
                        </Text>
                      </Checkbox>
                    ))}
                  </VStack>
                )}
              </Box>
            ))}
          </>
        )}
      </VStack>
    </VStack>
  );
};

export default FilterPane;
