import { ReactNode, FC, useState, useEffect } from 'react';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import useClaims from '../../app/hooks/useClaims';
import { useAppSelector } from '../../app/state/hooks';
import { useDispatch } from 'react-redux';
import { setMessageEvent, setPresenceEvent, setSignalEvent } from '../../app/slices/pubNubSlice';
import Mentions from './Mentions';
import { useLazyGetPubNubTokenQuery } from '../../app/services/dme/api/pubNub';
import dayjs from 'dayjs';

type IProps = {
  children: ReactNode;
};

const PubNubWrapper: FC<IProps> = ({ children }) => {
  const { idTokenClaims } = useClaims();
  const { logonUser } = useAppSelector(s => s.user);
  const dispatch = useDispatch();
  const [pubNub, setPubNub] = useState<PubNub>();
  const [getPubNubToken] = useLazyGetPubNubTokenQuery();

  useEffect(() => {
    if (idTokenClaims.emails) {
      setPubNub(
        new PubNub({
          publishKey: `${process.env.REACT_APP_PUBNUB_PUBLISH_KEY}`,
          subscribeKey: `${process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY}`,
          uuid: idTokenClaims.emails[0],
        }),
      );
    }
  }, [idTokenClaims]);

  useEffect(() => {
    if (pubNub) {
      (async () => {
        await loadPubNubToken();
        const pubNubListeners: PubNub.ListenerParameters = {
          message: p => {
            dispatch(setMessageEvent(p));
          },
          presence: p => {
            //   if (p.uuid !== logonUser?.email) {
            //     dispatch(setPresenceEvent(p));
            //   }
            dispatch(setPresenceEvent(p));
          },
          signal: p => {
            // if (p.publisher !== logonUser?.email) {
            //   dispatch(setSignalEvent(p));
            // }
            dispatch(setSignalEvent(p));
          },
        };
        pubNub.unsubscribeAll();
        pubNub.removeListener(pubNubListeners);

        pubNub.addListener(pubNubListeners);

        if (logonUser) {
          const { email, first_name = '', last_name = '' } = logonUser;
          const full_name = `${first_name} ${last_name}`.trim();
          pubNub.objects.setUUIDMetadata({
            data: {
              name: !full_name ? email : full_name,
              email: email,
            },
            uuid: email,
          });

          pubNub.subscribe({
            channels: [`mention.${email}`],
          });
        }
      })();
    }
  }, [pubNub, logonUser]);

  const loadPubNubToken = async () => {
    const res = await getPubNubToken().unwrap();
    pubNub?.setToken(res.token);
    const ttlMinutes = dayjs(res.expirationUtc).diff(dayjs(), 'minutes');
    const ttlMinutesAllowance = 10;
    const ttlMs = (ttlMinutes - ttlMinutesAllowance) * 60000;

    setTimeout(() => {
      loadPubNubToken();
    }, ttlMs);
  };

  return (
    <>
      {pubNub && (
        <PubNubProvider client={pubNub}>
          <Mentions />
          {children}
        </PubNubProvider>
      )}
    </>
  );
};

export default PubNubWrapper;
