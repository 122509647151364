import { Button, ButtonGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePostInfoweaveStatusMutation } from '../../../app/services/dme/api/infoweave';
import { InfoweaveEvent, InfoweaveFilterTabName, InfoweaveParams, useInfoweaveContext } from '../InfoweaveContext';

const GroupSelectionButtons: React.FC = () => {
  const { selectedIds, setSelectedIds } = useInfoweaveContext();

  const [postAsync, postDetails] = usePostInfoweaveStatusMutation();

  const [searchParams] = useSearchParams();
  const tabParam = useMemo(() => searchParams.get(InfoweaveParams.tab) as InfoweaveFilterTabName, [searchParams]);

  const handleBookmark = () => {
    postAsync({
      dme_infoweave_ids: selectedIds,
      dme_infoweave_ref_event_type_id: tabParam === 'bookmark' ? InfoweaveEvent.unbookmark : InfoweaveEvent.bookmark,
    });
  };

  const handleArchive = () => {
    postAsync({
      dme_infoweave_ids: selectedIds,
      dme_infoweave_ref_event_type_id: tabParam === 'archive' ? InfoweaveEvent.unarchive : InfoweaveEvent.archive,
    });
  };

  const HandleRead = () => {
    postAsync({
      dme_infoweave_ids: selectedIds,
      dme_infoweave_ref_event_type_id: InfoweaveEvent.read,
    });
  };

  useEffect(() => {
    if (postDetails.isSuccess) setSelectedIds([]);
  }, [postDetails.isSuccess]);

  const postedEventId = postDetails.originalArgs?.dme_infoweave_ref_event_type_id;

  if (selectedIds.length <= 0) return null;

  return (
    <ButtonGroup ml="auto" size="sm">
      <Button
        colorScheme="brand.main"
        onClick={handleBookmark}
        isDisabled={postDetails.isLoading}
        isLoading={
          (postedEventId === InfoweaveEvent.bookmark || postedEventId === InfoweaveEvent.unbookmark) &&
          postDetails.isLoading
        }
      >
        {(tabParam === 'bookmark' ? 'Unbookmark' : 'Bookmark') + ' Selected'}
      </Button>
      <Button
        colorScheme="brand.main"
        onClick={handleArchive}
        isDisabled={postDetails.isLoading}
        isLoading={
          (postedEventId === InfoweaveEvent.archive || postedEventId === InfoweaveEvent.unarchive) &&
          postDetails.isLoading
        }
      >
        {(tabParam === 'archive' ? 'Unarchive' : 'Archive') + ' Selected'}
      </Button>
      <Button
        colorScheme="brand.main"
        onClick={HandleRead}
        isDisabled={postDetails.isLoading}
        isLoading={postedEventId === InfoweaveEvent.read && postDetails.isLoading}
      >
        Mark as Read
      </Button>
    </ButtonGroup>
  );
};

export default GroupSelectionButtons;
