import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MessageEvent,
  StatusEvent,
  PresenceEvent,
  SignalEvent,
  MessageActionEvent,
  FileEvent,
  ObjectsEvent,
} from 'pubnub';

type PubNubState = {
  statusEvent?: StatusEvent;
  messageEvent?: MessageEvent;
  presenceEvent?: PresenceEvent;
  signalEvent?: SignalEvent;
  messageActionEvent?: MessageActionEvent;
  fileEvent?: FileEvent;
  objectsEvent?: ObjectsEvent;
};

const initialState: PubNubState = {};

export const pubNubSlice = createSlice({
  name: 'pubNub',
  initialState,
  reducers: {
    setMessageEvent: (state, action: PayloadAction<MessageEvent>) => {
      state.messageEvent = action.payload;
    },
    setPresenceEvent: (state, action: PayloadAction<PresenceEvent>) => {
      state.presenceEvent = action.payload;
    },
    setSignalEvent: (state, action: PayloadAction<SignalEvent>) => {
      state.signalEvent = action.payload;
    },
  },
});

export const { setMessageEvent, setPresenceEvent, setSignalEvent } = pubNubSlice.actions;
export default pubNubSlice.reducer;
