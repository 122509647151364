import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getName } from '../../../app/helpers/stringHelper';
import useDebounce from '../../../app/hooks/useDebounce';
import {
  useGetGoEdsMyRequestAssociateUserListQuery,
  useGetGoEdsRequestStatusListQuery,
} from '../../../app/services/dme/api/governanceOfficeEds';
import { GoEdsMyRequestAssociateUserModel } from '../../../app/services/dme/api/types';
import CustomDatePicker, { customDatePickerDefaultPropsConfig } from '../../../components/CustomDatePicker';
import { MyExternalDataSharingRequestsHeader, goEdsSearchParams } from './utils';

const startOfEpochDate = dayjs(new Date(0)).startOf('day').toDate();
const startOfDayToday = dayjs().startOf('day').toDate();
const endOfDayToday = dayjs().endOf('day').toDate();

const StartDateSchema = Yup.string()
  .test((str, { createError }) => {
    const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
    return date.isValid()
      ? true
      : createError({
          message: 'Start Date in invalid',
          path: 'startDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isAfter(startOfEpochDate)
      ? true
      : createError({
          message: 'Start Date must be later than start of epoch',
          path: 'startDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isBefore(startOfDayToday)
      ? true
      : createError({
          message: 'Start Date may not exceed today',
          path: 'startDate',
        });
  })
  .label('Start Date')
  .required();

const EndDateSchema = Yup.string()
  .test((str, { createError }) => {
    const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
    return date.isValid()
      ? true
      : createError({
          message: 'End Date in invalid',
          path: 'endDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isAfter(startOfEpochDate)
      ? true
      : createError({
          message: 'End Date must be later than start of epoch',
          path: 'endDate',
        });
  })
  .test((str, { createError }) => {
    const date = dayjs(str, 'YYYY-MM-DD', true);
    return date.isBefore(endOfDayToday)
      ? true
      : createError({
          message: 'End Date may not exceed today',
          path: 'endDate',
        });
  })
  .label('End Date')
  .required();

const FormSchema = Yup.object().shape({
  startDate: StartDateSchema,
  endDate: EndDateSchema,
});

const sortOrderOptions = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

const MyExternalDataSharingRequestsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sdQueryParams = dayjs(searchParams.get(goEdsSearchParams.startDate));
  const defaultStartDate = sdQueryParams.isValid() ? sdQueryParams.toDate() : undefined;
  const edQueryParams = dayjs(searchParams.get(goEdsSearchParams.endDate));
  const defaultEndDate = edQueryParams.isValid() ? edQueryParams.toDate() : undefined;
  const [startDate, setStartDate] = useState<Date | undefined>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate);

  const debouncedStartDate = useDebounce(startDate, 500);
  const debouncedEndDate = useDebounce(endDate, 500);

  const statusList = useGetGoEdsRequestStatusListQuery();
  const userList = useGetGoEdsMyRequestAssociateUserListQuery();

  const { handleSubmit, errors, touched, handleChange, values, setValues, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      startDate: defaultStartDate ? dayjs(defaultStartDate).format('YYYY-MM-DD') : undefined,
      endDate: defaultEndDate ? dayjs(defaultEndDate).format('YYYY-MM-DD') : undefined,
    },
    onSubmit: values => {},
  });

  useEffect(() => {
    const d = dayjs(debouncedStartDate);
    if (debouncedStartDate && d.isValid()) {
      setSearchParams(prev => {
        prev.set(goEdsSearchParams.startDate, d.format('YYYY-MM-DD'));
        return prev;
      });
    } else {
      setSearchParams(prev => {
        prev.delete(goEdsSearchParams.startDate);
        return prev;
      });
    }
  }, [debouncedStartDate]);

  useEffect(() => {
    const d = dayjs(debouncedEndDate);
    if (debouncedEndDate && d.isValid()) {
      setSearchParams(prev => {
        prev.set(goEdsSearchParams.endDate, d.format('YYYY-MM-DD'));
        return prev;
      });
    } else {
      setSearchParams(prev => {
        prev.delete(goEdsSearchParams.endDate);
        return prev;
      });
    }
  }, [debouncedEndDate]);

  return (
    <VStack w="238px" minW="238px" gap="10px">
      <HStack justifyContent="space-between">
        <Heading as="h3" size="md">
          Filters
        </Heading>
        <Button
          size="sm"
          colorScheme="brand.main"
          onClick={() => {
            setSearchParams(s => {
              s.delete(goEdsSearchParams.status);
              s.delete(goEdsSearchParams.startDate);
              s.delete(goEdsSearchParams.endDate);
              s.delete(goEdsSearchParams.user);
              return s;
            });
            setStartDate(undefined);
            setEndDate(undefined);
            setValues({ startDate: undefined, endDate: undefined });
          }}
        >
          Clear
        </Button>
      </HStack>

      <Divider marginBlockStart="0px" mt="0 !important" />

      <Box marginTop="0 !important" bg="gray.200" rounded="lg">
        <Accordion defaultIndex={[0, 1, 2]} allowMultiple w="100%" borderColor="transparent">
          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Status
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Flex flexDir="column" gap="1">
                {statusList.isLoading || statusList.isFetching ? (
                  <>Loading...</>
                ) : (
                  statusList.data?.map(s => (
                    <Checkbox
                      key={s.go_eds_ref_request_status_id}
                      value={s.go_eds_ref_request_status_id}
                      sx={{
                        '& .chakra-checkbox__control:not([data-checked])': {
                          bg: 'white',
                        },
                      }}
                      isChecked={
                        !!searchParams.get(goEdsSearchParams.status) &&
                        searchParams
                          .get(goEdsSearchParams.status)
                          ?.split(',')
                          .some(
                            go_eds_ref_request_status_id =>
                              go_eds_ref_request_status_id === s.go_eds_ref_request_status_id.toString(),
                          )
                      }
                      onChange={e => {
                        setSearchParams(sp => {
                          const status = sp.get(goEdsSearchParams.status);
                          if (status) {
                            let list = status.split(',');

                            if (e.target.checked) list.push(e.target.value);
                            else list = list.filter(status => status !== e.target.value);

                            if (list.length <= 0) sp.delete(goEdsSearchParams.status);
                            else sp.set(goEdsSearchParams.status, list.join(','));
                          } else {
                            sp.set(goEdsSearchParams.status, e.target.value);
                          }

                          return sp;
                        });
                      }}
                    >
                      {s.request_status_name}
                    </Checkbox>
                  ))
                )}
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Date Range
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Flex flexDir="column" gap="2">
                <CustomDatePicker
                  id="startDate"
                  name="startDate"
                  date={startDate}
                  onDateChange={(date: Date) => {
                    setValues({
                      ...values,
                      startDate: dayjs(date).format('YYYY-MM-DD'),
                    });
                    setStartDate(date);
                    setSearchParams(prev => {
                      prev.set(goEdsSearchParams.startDate, dayjs(date).format('YYYY-MM-DD'));
                      return prev;
                    });
                  }}
                  minDate={startOfEpochDate}
                  maxDate={startOfDayToday}
                  propsConfigs={{
                    ...customDatePickerDefaultPropsConfig,
                    inputProps: {
                      placeholder: 'YYYY-MM-DD',
                      size: 'sm',
                      bg: '#ffffff',
                      // rounded: 'md',
                      value: values.startDate,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          startDate: e.target.value,
                        });
                        const d = dayjs(e.target.value);
                        if (d.isValid()) {
                          setStartDate(d.toDate());
                        } else {
                          setStartDate(undefined);
                        }
                      },
                    },
                  }}
                />

                <CustomDatePicker
                  id="endDate"
                  name="endDate"
                  date={endDate}
                  onDateChange={(date: Date) => {
                    setValues({
                      ...values,
                      endDate: dayjs(date).format('YYYY-MM-DD'),
                    });
                    setEndDate(date);
                    setSearchParams(prev => {
                      prev.set(goEdsSearchParams.endDate, dayjs(date).format('YYYY-MM-DD'));
                      return prev;
                    });
                  }}
                  minDate={startOfEpochDate}
                  maxDate={endOfDayToday}
                  propsConfigs={{
                    ...customDatePickerDefaultPropsConfig,
                    inputProps: {
                      placeholder: 'YYYY-MM-DD',
                      size: 'sm',
                      bg: '#ffffff',
                      value: values.endDate,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          endDate: e.target.value,
                        });
                        if (dayjs(e.target.value)?.isValid()) {
                          setEndDate(dayjs(e.target.value).toDate());
                        } else {
                          setEndDate(undefined);
                        }
                      },
                    },
                  }}
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Associated Users
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Select
                size="sm"
                useBasicStyles
                isMulti
                placeholder=""
                menuPortalTarget={document.body}
                value={(() => {
                  const tmp = searchParams.get(goEdsSearchParams.user);
                  const list = tmp ? tmp.split(',') : [];
                  const tmpList: GoEdsMyRequestAssociateUserModel[] = [];
                  list.forEach(id => {
                    const user = userList.data?.find(u => u.ref_user_id.toString() === id);
                    if (user) tmpList.push(user);
                  });

                  return tmpList.map(u => ({
                    label: getName(u),
                    value: u.ref_user_id.toString(),
                  }));
                })()}
                options={userList.data?.map(u => ({
                  label: getName(u),
                  value: u.ref_user_id.toString(),
                }))}
                onChange={e => {
                  setSearchParams(prev => {
                    const val = e.map(e => e.value).join(',');
                    if (val) {
                      prev.set(goEdsSearchParams.user, val);
                    } else {
                      prev.delete(goEdsSearchParams.user);
                    }
                    return prev;
                  });
                }}
                chakraStyles={{
                  container: (provided, state) => {
                    return {
                      ...provided,
                      bg: 'white',
                      rounded: 'md',
                    };
                  },
                  multiValue: (provided, state) => {
                    return {
                      ...provided,
                      py: '1',
                    };
                  },
                }}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <HStack justifyContent="space-between" marginTop="0 !important">
        <Heading as="h3" size="md">
          Sorting
        </Heading>
        <Button
          size="sm"
          colorScheme="brand.main"
          onClick={() =>
            setSearchParams(s => {
              s.delete(goEdsSearchParams.sortBy);
              s.delete(goEdsSearchParams.sortOrder);
              return s;
            })
          }
        >
          Clear
        </Button>
      </HStack>

      <Divider marginBlockStart="0px" mt="0 !important" />

      <Box marginTop="0 !important" bg="gray.200" rounded="lg">
        <Accordion defaultIndex={[0, 1]} allowMultiple w="100%" borderColor="transparent">
          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Sort By
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Select
                size="sm"
                useBasicStyles
                placeholder=""
                menuPortalTarget={document.body}
                value={(() => {
                  const tmp = searchParams.get(goEdsSearchParams.sortBy);
                  const sortBy = MyExternalDataSharingRequestsHeader.find(h => h.accessor === tmp);
                  return sortBy
                    ? {
                        label: sortBy.Header as string,
                        value: sortBy.accessor as string,
                      }
                    : {
                        label: '-',
                        value: null as string | null,
                      };
                })()}
                options={[
                  {
                    label: '-',
                    value: null,
                  },
                  ...MyExternalDataSharingRequestsHeader.map(h => ({
                    label: h.Header as string,
                    value: h.accessor as string,
                  })),
                ]}
                onChange={e => {
                  setSearchParams(prev => {
                    if (e?.value) {
                      prev.set(goEdsSearchParams.sortBy, e.value);
                    } else {
                      prev.delete(goEdsSearchParams.sortBy);
                    }
                    return prev;
                  });
                }}
                chakraStyles={{
                  container: (provided, state) => {
                    return {
                      ...provided,
                      bg: 'white',
                      rounded: 'md',
                    };
                  },
                }}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Sort Order
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Flex flexDir="column" gap="2">
                <Select
                  size="sm"
                  useBasicStyles
                  placeholder=""
                  menuPortalTarget={document.body}
                  value={(() => {
                    const tmp = searchParams.get(goEdsSearchParams.sortOrder);
                    const sortOrder = sortOrderOptions.find(h => h.value === tmp);
                    return sortOrder
                      ? {
                          label: sortOrder.label,
                          value: sortOrder.value,
                        }
                      : {
                          label: '-',
                          value: null as string | null,
                        };
                  })()}
                  options={[
                    {
                      label: '-',
                      value: null,
                    },
                    ...sortOrderOptions.map(h => ({
                      label: h.label,
                      value: h.value,
                    })),
                  ]}
                  onChange={e => {
                    setSearchParams(prev => {
                      if (e?.value) {
                        prev.set(goEdsSearchParams.sortOrder, e.value);
                      } else {
                        prev.delete(goEdsSearchParams.sortOrder);
                      }
                      return prev;
                    });
                  }}
                  chakraStyles={{
                    container: (provided, state) => {
                      return {
                        ...provided,
                        bg: 'white',
                        rounded: 'md',
                      };
                    },
                  }}
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </VStack>
  );
};

export default MyExternalDataSharingRequestsFilter;
