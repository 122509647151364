import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useSearchParams } from 'react-router-dom';
import { ExplorePlatformServicesHeader, platformServicesSearchParams } from './utils';
import { useGetPlatformServicesRefPlatformPortfolioListQuery, useGetPlatformServicesRefPlatformServicesCategoryListQuery } from '../../../app/services/dme/api/platformServices';

const sortOrderOptions = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

const ExplorePlatformServicesFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const portfolioList = useGetPlatformServicesRefPlatformPortfolioListQuery(); 
  const categoryList = useGetPlatformServicesRefPlatformServicesCategoryListQuery(); 

  return (
    <VStack w="238px" minW="238px" gap="10px">
      <HStack justifyContent="space-between">
        <Heading as="h3" size="md">
          Filters
        </Heading>
        <Button
          size="sm"
          colorScheme="brand.main"
          onClick={() => {
            setSearchParams(s => {
              s.delete(platformServicesSearchParams.portfolio);
              s.delete(platformServicesSearchParams.category);
              return s;
            });
          }}
        >
          Clear
        </Button>
      </HStack>

      <Divider marginBlockStart="0px" mt="0 !important" />

      <Box marginTop="0 !important" bg="gray.200" rounded="lg">
        <Accordion defaultIndex={[0, 1, 2]} allowMultiple w="100%" borderColor="transparent">
          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Portfolio
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Flex flexDir="column" gap="1">
                {portfolioList.isLoading || portfolioList.isFetching ? (
                  <>Loading...</>
                ) : (
                  portfolioList.data?.map(s => (
                    <Checkbox
                      key={s.ps_ref_platform_portfolio_id}
                      value={s.ps_ref_platform_portfolio_id}
                      sx={{
                        '& .chakra-checkbox__control:not([data-checked])': {
                          bg: 'white',
                        },
                      }}
                      isChecked={
                        !!searchParams.get(platformServicesSearchParams.portfolio) &&
                        searchParams
                          .get(platformServicesSearchParams.portfolio)
                          ?.split(',')
                          .some(
                            ps_ref_platform_portfolio_id =>
                              ps_ref_platform_portfolio_id === s.ps_ref_platform_portfolio_id.toString(),
                          )
                      }
                      onChange={e => {
                        setSearchParams(sp => {
                          const portfolio = sp.get(platformServicesSearchParams.portfolio);
                          if (portfolio) {
                            let list = portfolio.split(',');

                            if (e.target.checked) list.push(e.target.value);
                            else list = list.filter(portfolio => portfolio !== e.target.value);

                            if (list.length <= 0) sp.delete(platformServicesSearchParams.portfolio);
                            else sp.set(platformServicesSearchParams.portfolio, list.join(','));
                          } else {
                            sp.set(platformServicesSearchParams.portfolio, e.target.value);
                          }

                          return sp;
                        });
                      }}
                    >
                      {s.ps_portfolio_name}
                    </Checkbox>
                  ))
                )}
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Service Category
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Flex flexDir="column" gap="1">
                {categoryList.isLoading || categoryList.isFetching ? (
                  <>Loading...</>
                ) : (
                  categoryList.data?.map(s => (
                    <Checkbox
                      key={s.ps_ref_category_id}
                      value={s.ps_ref_category_id}
                      sx={{
                        '& .chakra-checkbox__control:not([data-checked])': {
                          bg: 'white',
                        },
                      }}
                      isChecked={
                        !!searchParams.get(platformServicesSearchParams.category) &&
                        searchParams
                          .get(platformServicesSearchParams.category)
                          ?.split(',')
                          .some(
                            ps_ref_platform_services_category_id =>
                              ps_ref_platform_services_category_id === s.ps_ref_category_id.toString(),
                          )
                      }
                      onChange={e => {
                        setSearchParams(sp => {
                          const category = sp.get(platformServicesSearchParams.category);
                          if (category) {
                            let list = category.split(',');

                            if (e.target.checked) list.push(e.target.value);
                            else list = list.filter(category => category !== e.target.value);

                            if (list.length <= 0) sp.delete(platformServicesSearchParams.category);
                            else sp.set(platformServicesSearchParams.category, list.join(','));
                          } else {
                            sp.set(platformServicesSearchParams.category, e.target.value);
                          }

                          return sp;
                        });
                      }}
                    >
                      {s.ps_category_name}
                    </Checkbox>
                  ))
                )}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <HStack justifyContent="space-between" marginTop="0 !important">
        <Heading as="h3" size="md">
          Sorting
        </Heading>
        <Button
          size="sm"
          colorScheme="brand.main"
          onClick={() =>
            setSearchParams(s => {
              s.delete(platformServicesSearchParams.sortBy);
              s.delete(platformServicesSearchParams.sortOrder);
              return s;
            })
          }
        >
          Clear
        </Button>
      </HStack>

      <Divider marginBlockStart="0px" mt="0 !important" />

      <Box marginTop="0 !important" bg="gray.200" rounded="lg">
        <Accordion defaultIndex={[0, 1]} allowMultiple w="100%" borderColor="transparent">
          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Sort By
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Select
                size="sm"
                useBasicStyles
                placeholder=""
                menuPortalTarget={document.body}
                value={(() => {
                  const tmp = searchParams.get(platformServicesSearchParams.sortBy);
                  const sortBy = ExplorePlatformServicesHeader.find(h => h.accessor === tmp);
                  return sortBy
                    ? {
                        label: sortBy.Header as string,
                        value: sortBy.accessor as string,
                      }
                    : {
                        label: '-',
                        value: null as string | null,
                      };
                })()}
                options={[
                  {
                    label: '-',
                    value: null,
                  },
                  ...ExplorePlatformServicesHeader.map(h => ({
                    label: h.Header as string,
                    value: h.accessor as string,
                  })),
                ]}
                onChange={e => {
                  setSearchParams(prev => {
                    if (e?.value) {
                      prev.set(platformServicesSearchParams.sortBy, e.value);
                    } else {
                      prev.delete(platformServicesSearchParams.sortBy);
                    }
                    return prev;
                  });
                }}
                chakraStyles={{
                  container: (provided, state) => {
                    return {
                      ...provided,
                      bg: 'white',
                      rounded: 'md',
                    };
                  },
                }}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h4>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Sort Order
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h4>
            <AccordionPanel>
              <Flex flexDir="column" gap="2">
                <Select
                  size="sm"
                  useBasicStyles
                  placeholder=""
                  menuPortalTarget={document.body}
                  value={(() => {
                    const tmp = searchParams.get(platformServicesSearchParams.sortOrder);
                    const sortOrder = sortOrderOptions.find(h => h.value === tmp);
                    return sortOrder
                      ? {
                          label: sortOrder.label,
                          value: sortOrder.value,
                        }
                      : {
                          label: '-',
                          value: null as string | null,
                        };
                  })()}
                  options={[
                    {
                      label: '-',
                      value: null,
                    },
                    ...sortOrderOptions.map(h => ({
                      label: h.label,
                      value: h.value,
                    })),
                  ]}
                  onChange={e => {
                    setSearchParams(prev => {
                      if (e?.value) {
                        prev.set(platformServicesSearchParams.sortOrder, e.value);
                      } else {
                        prev.delete(platformServicesSearchParams.sortOrder);
                      }
                      return prev;
                    });
                  }}
                  chakraStyles={{
                    container: (provided, state) => {
                      return {
                        ...provided,
                        bg: 'white',
                        rounded: 'md',
                      };
                    },
                  }}
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </VStack>
  );
};

export default ExplorePlatformServicesFilter;
