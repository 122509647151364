import { FormControl, FormErrorMessage, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react';
import { AddRoleModel } from '.';
import { ValueOf } from '../../../app/helpers/utilities';
import { FocusEvent } from 'react';

type Props = {
  data: AddRoleModel;
  onChangeData: (field: keyof AddRoleModel, data: ValueOf<AddRoleModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const AddRoleDetails: React.FC<Props> = ({ data, onChangeData, errors, touched, tabErrorIndex }) => {
  const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof AddRoleModel['details']) => {
    data.details &&
      onChangeData('details', {
        ...data.details,
        [field]: e.target.value.trim(),
      });
  };

  return (
    <VStack spacing={5} p={1} pt={3}>
      <VStack w="container.md">
        <FormControl
          isInvalid={
            (!!(errors.details && (errors.details as unknown as AddRoleModel['details'])?.roleName) &&
              !!(touched.details && (touched.details as unknown as AddRoleModel['details'])?.roleName)) ||
            tabErrorIndex === 0
          }
          display="flex"
        >
          <FormLabel minW="150px">Role Name</FormLabel>
          <Input
            size="sm"
            value={data.details.roleName}
            onChange={e =>
              data.details &&
              onChangeData('details', {
                ...data.details,
                roleName: e.target.value,
              })
            }
            onBlur={e => onBlur(e, 'roleName')}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={
            !!(errors.details && (errors.details as unknown as AddRoleModel['details'])?.roleDesc) &&
            !!(touched.details && (touched.details as unknown as AddRoleModel['details'])?.roleDesc)
          }
          display="flex"
        >
          <FormLabel minW="150px">Role Description</FormLabel>
          <Textarea
            size="sm"
            value={data.details.roleDesc}
            onChange={e =>
              data.details &&
              onChangeData('details', {
                ...data.details,
                roleDesc: e.target.value,
              })
            }
            onBlur={e => onBlur(e, 'roleDesc')}
          ></Textarea>
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
      </VStack>
    </VStack>
  );
};

export default AddRoleDetails;
