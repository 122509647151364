import {
  Badge,
  Box,
  Checkbox,
  CheckboxProps,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row } from 'react-table';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useGetRefTagListQuery } from '../../../app/services/dme/api/refTags';
import {
  InfoweaveData,
  InfoweaveFilterTabName,
  InfoweaveParams,
  colorBlue,
  useInfoweaveContext,
} from '../InfoweaveContext';

const CustomBadge = ({ name, color }: { name: string; color: string }) => {
  return (
    <Badge p="1" m="0" bg={color} color="white" textTransform="none">
      {name}
    </Badge>
  );
};

const TagsCell = ({ row: { original } }: { row: Row<InfoweaveData> }) => {
  const { selectedIds, setSelectedIds } = useInfoweaveContext();

  const [searchParams] = useSearchParams();
  const tabParam = useMemo(() => searchParams.get(InfoweaveParams.tab) as InfoweaveFilterTabName, [searchParams]);

  const tagDetails = useGetRefTagListQuery({ infoweave_flag: true });
  const getRefTag = (refTagId: number) => {
    return tagDetails.data?.find(t => t.ref_tag_id === refTagId);
  };

  const handleOnChangeCheckbox: CheckboxProps['onChange'] = e => {
    // e.target.checked
    setSelectedIds(prev => {
      const isChecked = prev.some(id => id === original.dme_infoweave_id);
      if (isChecked) {
        return prev.filter(id => id !== original.dme_infoweave_id);
      } else {
        return [...prev, original.dme_infoweave_id];
      }
    });
  };

  useEffect(() => {
    setSelectedIds([]);
  }, [tabParam]);

  // Calculations
  const maxChar = 25;
  let currChar = 0;
  let index = 0;
  for (let i = 0; i < original.tags.length; i++) {
    const t = original.tags[i];
    index = i;
    currChar = currChar + (t.tag_name.length ?? 0);
    if (currChar >= maxChar) break;
  }
  const restOfTagCount = original.tags.length - (index + 1);
  const defaultTagColor = 'gray.400';

  return (
    <VStack gap="1">
      <HStack gap="0">
        <Checkbox
          isChecked={selectedIds.some(id => id === original.dme_infoweave_id)}
          onChange={handleOnChangeCheckbox}
        />
        <Box rounded="full" w="2" h="2" bg={original.is_read ? undefined : colorBlue} />
        <Text>{convertUtcToLocal(original.row_created_datetime_utc)}</Text>
      </HStack>

      <Flex gap="2">
        {original.tags.slice(0, index + 1).map((t, i) => (
          <CustomBadge key={i} name={t.tag_name} color={getRefTag(t.ref_tag_id)?.color_hex || defaultTagColor} />
        ))}
        {restOfTagCount > 0 && (
          <Popover>
            <PopoverTrigger>
              <Badge p="1" m="0" bg="gray.500" color="white" textTransform="none" cursor="pointer">
                +{restOfTagCount}
              </Badge>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>More tags</PopoverHeader>
              <PopoverBody>
                <Flex gap="2" flexWrap="wrap">
                  {original.tags.slice(-restOfTagCount).map((t, i) => (
                    <CustomBadge
                      key={i}
                      name={t.tag_name}
                      color={getRefTag(t.ref_tag_id)?.color_hex || defaultTagColor}
                    />
                  ))}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    </VStack>
  );
};

export default TagsCell;
