import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { ReportHubExploreModel } from '../../../../app/services/dme/api/types';
import AppAuth from '../../../../features/AppAuth';
import ExplorePortfoliosActionCellDelete from './Delete';
import ExplorePortfoliosActionCellEdit from './Edit';
import ExplorePortfoliosActionCellRequestAccess from './RequestAccess';
import ExplorePortfoliosActionCellViewDetails from './ViewDetails';
import ExplorePortfoliosActionCellViewReport from './ViewReport';

const ExplorePortfoliosActionCell = ({ row: { original } }: { row: { original: ReportHubExploreModel } }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton aria-label="Options" icon={<FiMoreVertical />} variant="ghost" colorScheme="brand.main" h="5" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="fit-content">
              <PopoverArrow />
              <PopoverBody>
                <AppAuth requiredAppAccess={AppAccess.ExploreReportPortfoliosWrite}>
                  {isAuthorized => (
                    <VStack py="4" gap="2">
                      <ExplorePortfoliosActionCellViewDetails original={original} onClose={onClose} />
                      <ExplorePortfoliosActionCellViewReport original={original} onClose={onClose} />
                      <ExplorePortfoliosActionCellRequestAccess original={original} onClose={onClose} />
                      {isAuthorized && (
                        <>
                          <ExplorePortfoliosActionCellEdit original={original} onClose={onClose} />
                          <ExplorePortfoliosActionCellDelete original={original} onClose={onClose} />
                        </>
                      )}
                    </VStack>
                  )}
                </AppAuth>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default ExplorePortfoliosActionCell;
