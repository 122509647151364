import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DefaultInnerHtmlStyle } from '../../../../../app/constants';
import { useGetRefDomainListQuery } from '../../../../../app/services/dme/api/refDomain';
import {
  useGetRhAllPortfolioStatesQuery,
  useGetRhAllPortfolioTypesQuery,
  useGetRhPortfolioSelectedDetailsByRhpidQuery,
} from '../../../../../app/services/dme/api/reportHub';
import { useGetRefUserListQuery } from '../../../../../app/services/dme/api/user';
import { EditComponentProps, portfolioFields } from '../../../helpers';
import { useAppSelector } from '../../../../../app/state/hooks';

const ReportHubExplorePortfoliosSelectedEditDetails: FC<EditComponentProps> = ({ form }) => {
  const params = useParams();
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = form;

  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const rhpId: number = parseInt(params?.rhpId || '0');
  const { isLoading, isFetching } = useGetRhPortfolioSelectedDetailsByRhpidQuery(
    { userId, rhpId },
    {
      skip: userId === 0 || rhpId === 0,
    },
  );
  const domainsDetails = useGetRefDomainListQuery();
  const usersDetails = useGetRefUserListQuery({ disabled_flag: false });
  const statesDetails = useGetRhAllPortfolioStatesQuery();
  const typesDetails = useGetRhAllPortfolioTypesQuery();

  const selectedState = statesDetails.data?.find(
    f => f.rh_report_portfolio_state_id === values.rh_report_portfolio_state_id,
  );

  return isLoading || isFetching ? (
    <>Loading...</>
  ) : (
    <VStack w="container.md">
      <FormControl isInvalid={!!errors.report_portfolio_name && !!touched.report_portfolio_name} display="flex">
        <FormLabel minW="150px">{portfolioFields.report_portfolio_name.label}</FormLabel>
        <Box w="100%">
          <Input
            size="sm"
            id="report_portfolio_name"
            name="report_portfolio_name"
            value={values.report_portfolio_name ?? ''}
            maxLength={portfolioFields.report_portfolio_name.max}
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormErrorMessage>{errors.report_portfolio_name}</FormErrorMessage>
        </Box>
      </FormControl>

      <FormControl isInvalid={!!errors.report_portfolio_desc && !!touched.report_portfolio_desc} display="flex">
        <FormLabel minW="150px">{portfolioFields.report_portfolio_desc.label}</FormLabel>
        <Box w="100%">
          <Textarea
            size="sm"
            id="report_portfolio_desc"
            name="report_portfolio_desc"
            value={values.report_portfolio_desc ?? ''}
            maxLength={portfolioFields.report_portfolio_desc.max}
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormErrorMessage>{errors.report_portfolio_desc}</FormErrorMessage>
        </Box>
      </FormControl>

      <FormControl isInvalid={!!errors.report_portfolio_link && !!touched.report_portfolio_link} display="flex">
        <FormLabel minW="150px">{portfolioFields.report_portfolio_link.label}</FormLabel>
        <Box w="100%">
          <Input
            size="sm"
            id="report_portfolio_link"
            name="report_portfolio_link"
            value={values.report_portfolio_link ?? ''}
            maxLength={portfolioFields.report_portfolio_link.max}
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormErrorMessage>{errors.report_portfolio_link}</FormErrorMessage>
        </Box>
      </FormControl>

      {/* <FormControl
        isInvalid={!!errors.report_portfolio_embed_link && !!touched.report_portfolio_embed_link}
        display="flex"
      >
        <FormLabel minW="150px">{portfolioFields.report_portfolio_embed_link.label}</FormLabel>
        <Box w="100%">
          <Input
            size="sm"
            id="report_portfolio_embed_link"
            name="report_portfolio_embed_link"
            value={values.report_portfolio_embed_link ?? ''}
            maxLength={portfolioFields.report_portfolio_embed_link.max}
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormErrorMessage>{errors.report_portfolio_embed_link}</FormErrorMessage>
        </Box>
      </FormControl> */}

      <FormControl isInvalid={!!errors.ref_domain_id && !!touched.ref_domain_id} display="flex">
        <FormLabel minW="150px">{portfolioFields.ref_domain_id.label}</FormLabel>
        <Box w="100%">
          <Select
            size="sm"
            id="ref_domain_id"
            name="ref_domain_id"
            placeholder={domainsDetails.isLoading || domainsDetails.isFetching ? 'Loading...' : ''}
            isDisabled={domainsDetails.isLoading || domainsDetails.isFetching}
            useBasicStyles
            value={(() => {
              const val = domainsDetails.data?.find(m => m.ref_domain_id === values.ref_domain_id);
              return val
                ? {
                    label: val.domain_name,
                    value: val.ref_domain_id,
                  }
                : undefined;
            })()}
            options={[
              ...(domainsDetails.data ?? []).map(m => {
                return {
                  label: m.domain_name,
                  value: m.ref_domain_id,
                };
              }),
            ]}
            onChange={e => {
              e &&
                setValues({
                  ...values,
                  ref_domain_id: e.value,
                });
            }}
          />
          <FormErrorMessage>{errors.ref_domain_id}</FormErrorMessage>
        </Box>
      </FormControl>

      <FormControl
        isInvalid={!!errors.report_portfolio_developer_user_id && !!touched.report_portfolio_developer_user_id}
        display="flex"
      >
        <FormLabel minW="150px">{portfolioFields.report_portfolio_developer_user_id.label}</FormLabel>
        <Box w="100%">
          <Select
            size="sm"
            id="report_portfolio_developer_user_id"
            name="report_portfolio_developer_user_id"
            placeholder={usersDetails.isLoading || usersDetails.isFetching ? 'Loading...' : ''}
            isDisabled={usersDetails.isLoading || usersDetails.isFetching}
            useBasicStyles
            value={(() => {
              const val = usersDetails.data?.find(m => m.ref_user_id === values.report_portfolio_developer_user_id);
              return val
                ? {
                    label: val.first_name + ' ' + val.last_name,
                    value: val.ref_user_id,
                  }
                : undefined;
            })()}
            options={[
              ...(usersDetails.data ?? []).map(m => {
                return {
                  label: m.first_name + ' ' + m.last_name,
                  value: m.ref_user_id,
                };
              }),
            ]}
            onChange={e => {
              e &&
                setValues({
                  ...values,
                  report_portfolio_developer_user_id: e.value,
                });
            }}
          />
          <FormErrorMessage>{errors.report_portfolio_developer_user_id}</FormErrorMessage>
        </Box>
      </FormControl>

      <FormControl
        isInvalid={!!errors.ref_report_portfolio_type_id && !!touched.ref_report_portfolio_type_id}
        display="flex"
      >
        <FormLabel minW="150px">{portfolioFields.ref_report_portfolio_type_id.label}</FormLabel>
        <Box w="100%">
          <Select
            size="sm"
            id="ref_report_portfolio_type_id"
            name="ref_report_portfolio_type_id"
            placeholder={typesDetails.isLoading || typesDetails.isFetching ? 'Loading...' : ''}
            isDisabled={typesDetails.isLoading || typesDetails.isFetching}
            useBasicStyles
            value={(() => {
              const val = typesDetails.data?.find(
                m => m.ref_report_portfolio_type_id === values.ref_report_portfolio_type_id,
              );
              return val
                ? {
                    label: val.report_portfolio_type,
                    value: val.ref_report_portfolio_type_id,
                  }
                : undefined;
            })()}
            options={[
              ...(typesDetails.data ?? []).map(m => {
                return {
                  label: m.report_portfolio_type,
                  value: m.ref_report_portfolio_type_id,
                };
              }),
            ]}
            onChange={e => {
              e &&
                setValues({
                  ...values,
                  ref_report_portfolio_type_id: e.value,
                });
            }}
          />
          <FormErrorMessage>{errors.ref_report_portfolio_type_id}</FormErrorMessage>
        </Box>
      </FormControl>

      <FormControl isInvalid={false} display="flex">
        <FormLabel minW="150px">{portfolioFields.phi_present_flag.label}</FormLabel>
        <Box minW="100px">
          <Select
            size="sm"
            id="phi_present_flag"
            name="phi_present_flag"
            useBasicStyles
            value={{
              label: values.phi_present_flag ? 'Yes' : 'No',
              value: values.phi_present_flag,
            }}
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            onChange={e => {
              e &&
                setValues({
                  ...values,
                  phi_present_flag: e.value,
                });
            }}
            maxMenuHeight={250}
            menuPortalTarget={document.body}
          />
        </Box>
        <FormErrorMessage></FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={false} display="flex">
        <FormLabel minW="150px">{portfolioFields.pii_present_flag.label}</FormLabel>
        <Box minW="100px">
          <Select
            size="sm"
            id="pii_present_flag"
            name="pii_present_flag"
            useBasicStyles
            value={{
              label: values.pii_present_flag ? 'Yes' : 'No',
              value: values.pii_present_flag,
            }}
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            onChange={e => {
              e &&
                setValues({
                  ...values,
                  pii_present_flag: e.value,
                });
            }}
            maxMenuHeight={250}
            menuPortalTarget={document.body}
          />
        </Box>
        <FormErrorMessage></FormErrorMessage>
      </FormControl>

      {/* <FormControl
        isInvalid={!!errors.rh_report_portfolio_state_id && !!touched.rh_report_portfolio_state_id}
        display="flex"
      >
        <FormLabel minW="150px">{portfolioFields.rh_report_portfolio_state_id.label}</FormLabel>
        <Box minW="150px">
          <Select
            id="rh_report_portfolio_state_id"
            name="rh_report_portfolio_state_id"
            useBasicStyles
            size="sm"
            placeholder={statesDetails.isLoading || statesDetails.isFetching ? "Loading..." : ""}
            isDisabled={statesDetails.isLoading || statesDetails.isFetching}
            chakraStyles={{
              container: (provided, state) => {
                return {
                  ...provided,
                  color: selectedState ? selectedState.rh_state_color : undefined,
                };
              },
              control: (provided, state) => {
                return {
                  ...provided,
                  borderColor: selectedState ? selectedState.rh_state_color : "inherit",
                  _focusVisible: "none",
                  _hover: "none",
                };
              },
              option: (provided, state) => {
                const isSelected = values.rh_report_portfolio_state_id === state.data.value;
                return {
                  ...provided,
                  color: isSelected ? "white" : state.data.data.rh_state_color, // if selected white
                  bg: isSelected ? state.data.data.rh_state_color : undefined, // if selected state.data.data.rh_state_color
                };
              },
            }}
            options={(statesDetails.data ?? []).map((m) => ({
              value: m.rh_report_portfolio_state_id,
              label: m.rh_state_name,
              data: m,
            }))}
            value={(() => {
              return selectedState
                ? {
                    value: selectedState.rh_report_portfolio_state_id,
                    label: selectedState.rh_state_name,
                    data: selectedState,
                  }
                : undefined;
            })()}
            onChange={(e) => {
              e &&
                setValues({
                  ...values,
                  rh_report_portfolio_state_id: e.value,
                });
            }}
            components={{
              Option: (props) => {
                return (
                  <chakraComponents.Option {...props}>
                    <Box w="100%">
                      <Tooltip
                        label={
                          <Box
                            dangerouslySetInnerHTML={{ __html: props.data.data.rh_state_description }}
                            sx={DefaultInnerHtmlStyle}
                          ></Box>
                        }
                        placement="right"
                      >
                        <Text>{props.label}</Text>
                      </Tooltip>
                    </Box>
                  </chakraComponents.Option>
                );
              },
              SingleValue: (props) => {
                return (
                  <chakraComponents.SingleValue {...props}>
                    <Tooltip
                      label={
                        <Box
                          dangerouslySetInnerHTML={{ __html: props.data.data.rh_state_description }}
                          sx={DefaultInnerHtmlStyle}
                        ></Box>
                      }
                      placement="right"
                    >
                      {props.data.label}
                    </Tooltip>
                  </chakraComponents.SingleValue>
                );
              },
            }}
          />
          <FormErrorMessage>{errors.rh_report_portfolio_state_id}</FormErrorMessage>
        </Box>
      </FormControl> */}
    </VStack>
  );
};

export default ReportHubExplorePortfoliosSelectedEditDetails;
