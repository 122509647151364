import { dmeApi } from './index';
import { RefDataProductTypeModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefDataProductTypeList: builder.query<RefDataProductTypeModel[], void>({
      query: () => `/refdataproducttype/list`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefDataProductTypeListQuery } = extendedApi;
