import { dmeApi } from './index';
import { PostPimGraphDto, PostPimVerifyUserDto } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    postPimGraph: builder.mutation<void, PostPimGraphDto>({
      query: params => ({
        url: '/pim/graph/user',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['UserList'],
    }),

    postPimVerifyUser: builder.mutation<void, PostPimVerifyUserDto>({
      query: params => ({
        url: '/pim/verify',
        method: 'POST',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { usePostPimGraphMutation, usePostPimVerifyUserMutation } = extendedApi;
