import {
  Alert,
  AlertIcon,
  AlertProps,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { usePubNub } from 'pubnub-react';
import { ElementRef, FC, useEffect, useRef, useState } from 'react';
import { CgAttachment } from 'react-icons/cg';
import { MdOutlineThumbUpAlt, MdThumbUpAlt } from 'react-icons/md';
import { RiSendPlaneFill } from 'react-icons/ri';

import { BsPencil } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAvatarUrl } from '../../../../../app/helpers/avatarHelper';
import { convertUtcToLocal } from '../../../../../app/helpers/dateHelper';
import { createFormData } from '../../../../../app/helpers/formHelper';
import {
  useGetImportantLinkDetailsDiscussionTabQuery,
  useLazyGetImportantLinkDetailsDiscussionTabByCommentQuery,
  useLazyGetImportantLinkDetailsDiscussionTabQuery,
  usePostImportantLinkCommentMutation,
  usePostImportantLinkCommentToggleReactionMutation,
} from '../../../../../app/services/dme/api/importantLinkComment';
import {
  ImportantLinkDetailsDiscussionTabModel,
  ImportantLinkDetailsPageDiscussionModel,
} from '../../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../../app/state/hooks';
import PubNubConstants from '../../../../../features/PubNubWrapper/constants';
import TypingIndicator from '../../../../../features/PubNubWrapper/TypingIndicator';
import RichTextEditor, { RichTextEditorProps } from '../../../../../features/RichTextEditor';
import { debounceLeading } from '../../../../../app/helpers/utilities';
import ImportantLinkCommentAttachmentUpload from './Attachment/AttachmentUpload';
import ImportantLinkCommentModal from './Modals/CommentModal';
import { getName, stringOrHtmlIsEmpty } from '../../../../../app/helpers/stringHelper';
import { useParams } from 'react-router-dom';
import { MentionPubnubMessage } from '../../../../../app/types/appType';
import DiscussionHelper, { DiscussionHelperRef } from '../../../../../features/Discussion/DiscussionHelper';
import { DefaultInnerHtmlStyle } from '../../../../../app/constants';

type Props = {
  selectedPost: ImportantLinkDetailsPageDiscussionModel;
  onOccupants?: (occupants: string[]) => void;
  onClickAttachment: (commentId: number | null) => void;
};

const CommentList: FC<Props> = ({ selectedPost, onOccupants, onClickAttachment }) => {
  const pubnub = usePubNub();
  const params = useParams();
  const importantLinkId: number = parseInt(params.id || '0');

  const { logonUser } = useAppSelector(s => s.user);
  const { presenceEvent, messageEvent } = useAppSelector(s => s.pubNub);

  const [channelName, setChannelName] = useState('');
  const [isCurrentUserTyping, setIsCurrentUserTyping] = useState(false);

  const [commentMessage, setCommentMessage] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [alert, setAlert] = useState<{ message: string; status: AlertProps['status'] } | null>(null);

  type AttachmentForwardProps = ElementRef<typeof ImportantLinkCommentAttachmentUpload>;

  const attachmentRef = useRef<AttachmentForwardProps>(null);
  const helperRef = useRef<DiscussionHelperRef>(null);

  const [postAsync, postDetail] = usePostImportantLinkCommentMutation();

  const [toggleReactionAsync, toggleReactionAsyncDetail] = usePostImportantLinkCommentToggleReactionMutation();

  const [toggleReactionLoader, setToggleReactionLoader] = useState<{
    link_comment_id: number;
    isLoading: boolean;
  }>();

  const [getCommentDetail] = useLazyGetImportantLinkDetailsDiscussionTabByCommentQuery();
  const [comments, setComments] = useState<ImportantLinkDetailsDiscussionTabModel[]>([]);
  const [selectedComment, setSelectedComment] = useState<{
    comment: ImportantLinkDetailsDiscussionTabModel;
    action: 'update' | 'delete';
  } | null>(null);

  const [pageSize] = useState(5);

  const {
    data: initialData,
    isLoading,
    isFetching,
  } = useGetImportantLinkDetailsDiscussionTabQuery(
    {
      ilPostId: selectedPost.link_post_id,
      query: new URLSearchParams({
        pageNumber: '1',
        pageSize: '5',
      }).toString(),
    },
    {
      skip: selectedPost.link_post_id === 0,
    },
  );
  const [getComments] = useLazyGetImportantLinkDetailsDiscussionTabQuery();
  const [lazyLoadDataLength, setLazyLoadDataLength] = useState(0);

  const bottomRef = useRef(null);

  useEffect(() => {
    setComments([]);
    const _channelName = `link_post_id_${selectedPost.link_post_id}_comments`;
    setChannelName(_channelName);
    pubnub.subscribe({
      channels: [_channelName],
      withPresence: true,
    });

    return () => {
      setComments([]);
      pubnub.unsubscribe({
        channels: [_channelName],
      });
      setChannelName('');
    };
  }, [selectedPost.link_post_id]);

  useEffect(() => {
    if (initialData && initialData.length) {
      //const sorted = [...data].sort((a, b) => b.link_comment_id - a.link_comment_id).reverse();
      setComments(initialData);
    } else {
      setComments([]);
      forceRemoveLazyLoaderText();
    }
  }, [initialData]);

  useEffect(() => {
    setLazyLoadDataLength(comments.length);
  }, [comments]);

  useEffect(() => {
    if (presenceEvent?.channel === channelName) {
      pubnub.hereNow(
        {
          channels: [channelName],
          includeState: true,
        },
        function (status, response) {
          const { occupants } = response.channels[channelName as keyof {}];
          const filterString = occupants.map(m => `id == '${m.uuid}'`);
          pubnub.objects
            .getAllUUIDMetadata({
              filter: filterString.join(' || '),
            })
            .then(d => {
              onOccupants && onOccupants(d.data.map(m => `${m.name}`));
            });
        },
      );
    }
  }, [presenceEvent]);

  useEffect(() => {
    if (messageEvent?.channel === channelName) {
      const { message } = messageEvent;

      switch (message.type) {
        case PubNubConstants.MessageEvent.Type.NEW:
          setComments(s => [message.data, ...s]);
          break;

        case PubNubConstants.MessageEvent.Type.UPDATE:
          const updatedData = message.data;
          setComments(s =>
            s.map(m => {
              if (m.link_comment_id === updatedData.link_comment_id) {
                return updatedData;
              } else {
                return m;
              }
            }),
          );
          break;

        case PubNubConstants.MessageEvent.Type.DELETE:
          setComments(s => s.filter(m => m.link_comment_id !== message.commentId));
          break;

        default:
          break;
      }
    }
  }, [messageEvent]);

  useEffect(() => {
    setTimeout(async () => {
      if (isCurrentUserTyping) {
        await pubnub.signal({
          message: PubNubConstants.SignalEvent.TYPING,
          channel: channelName,
        });

        setIsCurrentUserTyping(false);
      }
    }, 900);
  }, [isCurrentUserTyping]);

  const fetchPagedComments = async () => {
    const nextPage = Math.floor((comments.length + pageSize) / pageSize);

    const response = await getComments({
      ilPostId: selectedPost.link_post_id,
      query: new URLSearchParams({
        pageNumber: nextPage.toString(),
        pageSize: pageSize.toString(),
      }).toString(),
    }).unwrap();

    const newRecords: ImportantLinkDetailsDiscussionTabModel[] = [];

    response.forEach(d => {
      if (!comments.some(s => s.link_comment_id === d.link_comment_id)) {
        newRecords.push(d);
      }
    });

    setComments(s => [...s, ...newRecords]);
    if (newRecords.length === 0) {
      forceRemoveLazyLoaderText();
    }
  };

  // (workaround)force remove the infinite loader text if data is empty
  const forceRemoveLazyLoaderText = () => {
    setLazyLoadDataLength(s => ++s);
  };

  const setNoPasteImageAlert = debounceLeading((hasImage: boolean) => {
    // added debounceLeading as error will not show on pasting in empty message
    if (hasImage) setAlert({ message: 'Pasting image is not allowed.', status: 'warning' });
    else setAlert(null);
  }, 100);

  const onTextAreaKeyEvent: RichTextEditorProps['onChange'] = (text, { hasImage }) => {
    setIsCurrentUserTyping(true);
    setNoPasteImageAlert(hasImage);
    setCommentMessage(text);
  };

  const postComment = async () => {
    setAlert(null);
    if (logonUser) {
      try {
        const values = {
          comment_by_user_id: logonUser?.ref_user_id,
          link_post_id: selectedPost.link_post_id,
          comment_message: commentMessage.replace(/\uFEFF/g, ''), //clean unwanted white space
          attachments: attachments,
        };
        const data = createFormData(values);
        const response = await postAsync(data).unwrap();

        setCommentMessage('');
        await broadcastComment(response.link_comment_id, PubNubConstants.MessageEvent.Type.NEW);
        await helperRef.current?.broadcastMentions({
          type: 'Important Link',
          postId: selectedPost.link_post_id,
          commentId: response.link_comment_id,
          message: commentMessage,
          data: { importantLinkId },
        });
        attachmentRef.current?.resetFilePicker();
      } catch (error) {
        console.error('postAsync error', { error });
      }
    }
  };

  const broadcastComment = async (link_comment_id: number, messageType: string) => {
    try {
      const result = await getCommentDetail({ link_comment_id, query: '' }).unwrap();
      pubnub.publish({
        channel: channelName,
        message: {
          data: result[0],
          type: messageType,
        },
      });
    } catch (error) {
      console.error('broadcastComment error', { error });
    }
  };

  const incrementPostCommentCount = () => {
    pubnub.signal({
      message: PubNubConstants.SignalEvent.INCREMENT_COMMENT_COUNT,
      channel: `link_post_id_${selectedPost.link_post_id}`,
    });
  };

  //TODO: Cliff - Refactor reaction implementation
  const toggleReaction = (link_comment_id: number) => async () => {
    if (logonUser) {
      setToggleReactionLoader({
        link_comment_id,
        isLoading: true,
      });
      await toggleReactionAsync({
        link_comment_id,
        reaction_user_id: logonUser.ref_user_id,
        ref_reaction_id: 1,
      });
      await broadcastComment(link_comment_id, PubNubConstants.MessageEvent.Type.UPDATE);

      setToggleReactionLoader({
        link_comment_id,
        isLoading: false,
      });
    }
  };

  return (
    <Flex flexDirection="column" maxH="calc(100vh - 250px)" h="full">
      <DiscussionHelper ref={helperRef} />
      {(isLoading || isFetching) && comments.length === 0 ? (
        <>Loading...</>
      ) : (
        <>
          {!comments.length && <Alert status="error">No Comment(s)</Alert>}
          <Flex id="scrollableDiv" overflow="auto" flexDirection="column-reverse" px={1}>
            <InfiniteScroll
              dataLength={lazyLoadDataLength}
              next={fetchPagedComments}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              inverse={true} //\
              hasMore={true}
              loader={comments.length ? <h5>Loading...</h5> : <></>}
              scrollableTarget="scrollableDiv"
            >
              {comments.map((m: ImportantLinkDetailsDiscussionTabModel) => (
                <VStack
                  key={m.link_comment_id}
                  w="full"
                  alignItems="baseline"
                  spacing={3}
                  p={3}
                  bgColor="gray.50"
                  borderRadius={4}
                  mb={3}
                >
                  <Flex w="full" alignItems="center">
                    <Avatar size={'sm'} bg="gray.300" src={getAvatarUrl(m.comment_by_user)} />
                    <Text fontSize={14} fontWeight="medium" ml={2}>
                      {m.comment_by_user}
                    </Text>
                    <Text fontSize={12} ml="auto">
                      {convertUtcToLocal(m.comment_created_date_time, 'MMM D, YYYY h:mm A')}
                    </Text>
                  </Flex>
                  <Divider />
                  <Box className='ql-editor' textAlign="left" px={3} py={2} w="full">
                    <Text
                      whiteSpace="initial"
                      as="small"
                      dangerouslySetInnerHTML={{ __html: m.comment_message }}
                      sx={DefaultInnerHtmlStyle}
                    />
                  </Box>
                  <Divider />
                  <HStack alignItems="center" w="full" justifyContent="space-between">
                    <HStack>
                      <Button
                        leftIcon={<CgAttachment />}
                        variant="link"
                        size="sm"
                        _hover={{ textDecoration: 'none' }}
                        sx={{ span: { marginRight: 1 } }}
                        onClick={e => {
                          return parseInt(m.cnt_comment_attachments) > 0 ? onClickAttachment(m.link_comment_id) : null;
                        }}
                      >
                        {m.cnt_comment_attachments}
                      </Button>

                      <Button
                        leftIcon={
                          m.reactor_user_id_list &&
                          m.reactor_user_id_list.split(',').some(s => s === `${logonUser?.ref_user_id}`) ? (
                            <MdThumbUpAlt />
                          ) : (
                            <MdOutlineThumbUpAlt />
                          )
                        }
                        variant="link"
                        size="sm"
                        onClick={toggleReaction(m.link_comment_id)}
                        isLoading={
                          toggleReactionLoader &&
                          toggleReactionLoader?.link_comment_id === m.link_comment_id &&
                          toggleReactionLoader.isLoading
                        }
                        isDisabled={toggleReactionAsyncDetail.isLoading}
                        _hover={{ textDecoration: 'none' }}
                        sx={{ span: { marginRight: 1 } }}
                      >
                        {m.cnt_comment_reaction}
                      </Button>
                    </HStack>

                    {logonUser?.email === m.comment_by_user && (
                      <HStack>
                        <Button
                          leftIcon={<BsPencil />}
                          variant="link"
                          size="sm"
                          _hover={{ textDecoration: 'none' }}
                          sx={{ span: { marginRight: 1 } }}
                          onClick={() => setSelectedComment({ comment: m, action: 'update' })}
                        />
                        <Button
                          leftIcon={<FaTrashAlt />}
                          variant="link"
                          size="sm"
                          _hover={{ textDecoration: 'none' }}
                          sx={{ span: { marginRight: 1 } }}
                          onClick={() => setSelectedComment({ comment: m, action: 'delete' })}
                        />
                      </HStack>
                    )}
                  </HStack>
                </VStack>
              ))}
            </InfiniteScroll>

            <div ref={bottomRef} />
          </Flex>

          <Flex flexDirection="column" mt="auto" pt={3}>
            <TypingIndicator channelName={channelName} />
            <RichTextEditor value={commentMessage} onChange={onTextAreaKeyEvent} removeImages />

            {/* <Textarea
              value={commentMessage}
              onChange={onTextAreaKeyEvent}
              isDisabled={postDetail.isLoading}
              placeholder="test typing indicator"
            ></Textarea> */}

            <HStack justifyContent="flex-end" mt={3}>
              {alert && (
                <Alert status={alert.status} h="8">
                  <AlertIcon />
                  {alert.message}
                </Alert>
              )}
              <ImportantLinkCommentAttachmentUpload ref={attachmentRef} onChange={files => setAttachments(files)} />
              <Button
                colorScheme="brand.main"
                size="sm"
                rightIcon={<RiSendPlaneFill />}
                onClick={postComment}
                isDisabled={stringOrHtmlIsEmpty(commentMessage)}
                isLoading={postDetail.isLoading}
                minW="77px"
              >
                Send
              </Button>
              {/* <Button onClick={incrementPostCommentCount} size="sm">
                increment post comment count
              </Button> */}
            </HStack>
          </Flex>
        </>
      )}

      <ImportantLinkCommentModal
        {...selectedComment}
        channelName={channelName}
        handleClose={() => {
          setSelectedComment(null);
        }}
      />
    </Flex>
  );
};

export default CommentList;
