import { Box, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportHubExploreModel } from '../../../../app/services/dme/api/types';

type Props = {
  original: ReportHubExploreModel;
  onClose: () => void;
};
const ExplorePortfoliosActionCellEdit: FC<Props> = ({ original }) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        size="sm"
        _hover={{ textDecoration: 'none' }}
        variant="link"
        fontWeight="normal"
        color="blackAlpha.900"
        textAlign="left"
        onClick={() => {
          navigate(`./selected/id/${original.rh_report_portfolio_id}/details`, { state: { isEdit: true } });
        }}
      >
        <Box w="100%">Edit</Box>
      </Button>
    </>
  );
};

export default ExplorePortfoliosActionCellEdit;
