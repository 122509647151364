import { Box, Flex } from '@chakra-ui/react';
import ExploreTagsFilterPane from './FilterPane';
import ExploreTagsList from './List';

const ExploreTags = () => {
  return (
    <Flex w="100%">
      <Box w={300}>
        <ExploreTagsFilterPane />
      </Box>
      <Box flex={1} px={3}>
        <ExploreTagsList />
      </Box>
    </Flex>
  );
};

export default ExploreTags;
