import {
  VStack,
  IconButton,
  Button,
  ButtonGroup,
  Tooltip,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Heading,
  HStack,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { MdInfo } from 'react-icons/md';
import { Row } from 'react-table';
import { ValueOf } from '../../../app/helpers/utilities';
import { useGetRefDomainListQuery } from '../../../app/services/dme/api/refDomain';
import { LoreLanguageDetailsPageDomainModel } from '../../../app/services/dme/api/types';
import { AddLoreLanguageModel } from '../../../app/services/types';
import EditableCell from '../../../components/CustomTable/EditableCell';

type Props = {
  data: AddLoreLanguageModel;
  onChangeData: (field: keyof AddLoreLanguageModel, data: ValueOf<AddLoreLanguageModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const LoreLanguageDomain = ({ data, onChangeData, errors, touched, title }: Props) => {
  const { data: domainData, isLoading: isLoadingDomain, isFetching: isFetchingDomain } = useGetRefDomainListQuery();

  const generateIdForAddedItem = (): number => {
    return data.domainModel && data.domainModel.length > 0
      ? Math.min.apply(
          Math,
          data.domainModel.map(m => m.ll_lore_language_domain_id),
        ) - 1
      : 0;
  };

  const onAddNew = () => {
    const newRow = {
      tab: '',
      ll_lore_language_domain_id: generateIdForAddedItem(),
      ref_domain_id: 0,
      domain_name: '',
      domain_user_context: '',
      user_context: '',
      domain_modified_datetime_utc: '',
      is_deleted_flag: false,
    };
    onChangeData('domainModel', data.domainModel ? [...data.domainModel, newRow] : [newRow]);
  };

  const onUpdateData = (rowIndex: number, columnId: string, value: string | File | boolean | number | number[]) => {
    data.domainModel &&
      onChangeData(
        'domainModel',
        data.domainModel.map((row, index) => {
          if (index === rowIndex && data.domainModel) {
            return {
              ...data.domainModel[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        }),
      );
  };

  const onRemove = (item: LoreLanguageDetailsPageDomainModel) => {
    if (item.ll_lore_language_domain_id > 0) {
      data.domainModel &&
        onChangeData(
          'domainModel',
          data.domainModel.map((row, index) => {
            if (row.ll_lore_language_domain_id === item.ll_lore_language_domain_id && data.domainModel) {
              return {
                ...data.domainModel[index],
                is_deleted_flag: true,
              };
            }
            return row;
          }),
        );
    } else {
      data.domainModel &&
        onChangeData(
          'domainModel',
          data.domainModel.filter(f => f.ll_lore_language_domain_id != item.ll_lore_language_domain_id),
        );
    }
  };

  return (
    <VStack spacing={5} p={1} pt={3} w="100%">
      <HStack>
        <Heading size="sm">{title}</Heading>
      </HStack>
      {isLoadingDomain || isFetchingDomain ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data.domainModel && !domainData ? null : (
      <VStack>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>Domain</Th>
              <Th>User Context</Th>
              <Th>Action</Th>
            </Tr>
            {(data.domainModel?.filter(f => !f.is_deleted_flag) || []).map((d, index) => (
              <Tr key={d.ll_lore_language_domain_id}>
                <Td verticalAlign="top">
                  {domainData && (
                    <EditableCell
                      name={`domainModel.${index}.ref_domain_id`}
                      value={d.ref_domain_id}
                      row={{ index: index } as Row}
                      column={{ id: 'ref_domain_id' } as any}
                      updateData={onUpdateData}
                      type="dropdown"
                      dropdownOptions={domainData.map(m => {
                        return { text: m.domain_name, value: m.ref_domain_id.toString() };
                      })}
                      isInvalid={
                        !!(
                          errors.domainModel &&
                          (errors.domainModel as unknown as LoreLanguageDetailsPageDomainModel[])[index]?.ref_domain_id
                        ) &&
                        !!(
                          touched.domainModel &&
                          (touched.domainModel as unknown as LoreLanguageDetailsPageDomainModel[])[index]?.ref_domain_id
                        )
                      }
                    />
                  )}
                </Td>
                <Td verticalAlign="top">
                  <EditableCell
                    name={`domainModel.${index}.domain_user_context`}
                    value={d.domain_user_context}
                    row={{ index: index } as Row}
                    column={{ id: 'domain_user_context' } as any}
                    updateData={onUpdateData}
                    isInvalid={
                      !!(
                        errors.domainModel &&
                        (errors.domainModel as unknown as LoreLanguageDetailsPageDomainModel[])[index]
                          ?.domain_user_context
                      ) &&
                      !!(
                        touched.domainModel &&
                        (touched.domainModel as unknown as LoreLanguageDetailsPageDomainModel[])[index]
                          ?.domain_user_context
                      )
                    }
                  />
                </Td>
                <Td textAlign="center">
                  {
                    <ButtonGroup spacing={1}>
                      <Tooltip label="Delete">
                        <IconButton
                          color="brand.error"
                          variant="link"
                          aria-label="Delete"
                          icon={<FaTrash />}
                          onClick={() => onRemove(d)}
                          minWidth={1}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box>
          <Button colorScheme="brand.main" size="sm" onClick={onAddNew}>
            Create New
          </Button>
        </Box>
      </VStack>
    );
  }
};

export default LoreLanguageDomain;
