import { Box, Button, Checkbox, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { MyRoleFilterModel } from '../../app/services/dme/api/types';
import { useSearchParams } from 'react-router-dom';

type IProps = {
  onChange?: (checkedFilters: MyRoleFilterModel[]) => void;
  filterData: MyRoleFilterModel[];
};

const FilterPane: FC<IProps> = ({ onChange, filterData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggledFilterGroups, setToggledFilterGroups] = useState<string[]>(['domain']);
  const [checkedFilters, setCheckedFilters] = useState<MyRoleFilterModel[] | undefined>();

  const onFilterGroupClick = (filterGroupName: string) => {
    if (toggledFilterGroups.includes(filterGroupName)) {
      setToggledFilterGroups(s => s.filter(f => f.toLowerCase() !== filterGroupName));
    } else {
      setToggledFilterGroups(s => [...s, filterGroupName]);
    }
  };

  const isFilterGroupSelected = (filterGroupName: string) =>
    toggledFilterGroups.includes(filterGroupName.toLowerCase());

  const updateCheckedFilters = (obj: MyRoleFilterModel, isChecked: boolean) => {
    if (isChecked) {
      setCheckedFilters(s => [...(s || []), obj]);
    } else {
      setCheckedFilters(s => s?.filter(f => f.filter_detail !== obj.filter_detail));
    }
  };

  useEffect(() => {
    if (checkedFilters) {
      onChange && onChange(checkedFilters);

      // create searchParams
      type FilterItem = { filter_header: string; filter_detail: string[] };
      const searchParamsObj: FilterItem[] = [];
      checkedFilters.forEach(filter => {
        const existingFilterIndex = searchParamsObj.findIndex(
          f => f.filter_header === filter.filter_header.toLowerCase(),
        );
        if (existingFilterIndex > -1) {
          searchParamsObj[existingFilterIndex].filter_detail.push(filter.filter_detail);
        } else {
          searchParamsObj.push({
            filter_header: filter.filter_header.toLowerCase(),
            filter_detail: [filter.filter_detail],
          });
        }
      });
      const searchParamsStr = searchParamsObj.map(f => `${f.filter_header}=${f.filter_detail.join(',')}`).join('&');
      setSearchParams(searchParamsStr, { replace: true });
    }
  }, [checkedFilters]);

  useEffect(() => {
    // from searchParams: should run one time only
    const filters: MyRoleFilterModel[] = [];
    searchParams.forEach((value, key) => {
      const filter_detail: string[] = decodeURIComponent(value).split(',');
      let hasDetail = false;
      filter_detail.forEach(detail => {
        const tempData = filterData.find(
          f => f.filter_header.toLowerCase() === key && f.filter_items?.some(s => s.filter_detail === detail),
        );
        if (tempData) {
          filters.push({
            filter_header: tempData.filter_header,
            filter_detail: detail,
            filter_order: tempData.filter_order,
          });
          hasDetail = true;
        }
      });
      hasDetail && setToggledFilterGroups(s => (s.some(v => v === key) ? s : [...s, key]));
    });
    setCheckedFilters(filters);
  }, [filterData]);

  return (
    <VStack w="100%" h="100vh" overflowY="scroll" maxW={300} bgColor="gray.50" p={3} pt={2}>
      <HStack justifyContent="space-between">
        <Heading size="md">Filters</Heading>
        <Button
          size="sm"
          onClick={() => {
            setCheckedFilters([]);
          }}
        >
          Clear
        </Button>
      </HStack>
      <Divider />
      <VStack>
        <>
          {filterData.length > 0 ? (
            filterData.map(({ filter_header, filter_order, filter_items }, i) => (
              <Box key={i}>
                <Button
                  w="100%"
                  rightIcon={isFilterGroupSelected(filter_header) ? <HiChevronDown /> : <HiChevronRight />}
                  justifyContent="space-between"
                  size="sm"
                  onClick={() => {
                    onFilterGroupClick(filter_header.toLowerCase());
                  }}
                >
                  {filter_header}
                </Button>
                {isFilterGroupSelected(filter_header) && (
                  <VStack bgColor="gray.100" p={3} pt={0}>
                    {filter_items?.map(({ filter_detail }, ii) => (
                      <Checkbox
                        key={ii}
                        name={`${filter_header}_${filter_detail}`.replace(/\s/g, '')}
                        onChange={(e: any) => {
                          updateCheckedFilters(
                            {
                              filter_detail,
                              filter_header,
                              filter_order,
                            },
                            e.target.checked,
                          );
                        }}
                        isChecked={checkedFilters?.some(s => s.filter_detail === filter_detail)}
                        _hover={{ bgColor: 'gray.200' }}
                      >
                        <Text whiteSpace="initial" fontSize="sm" noOfLines={2} title={filter_detail} maxW="210px">
                          {filter_detail}
                        </Text>
                      </Checkbox>
                    ))}
                  </VStack>
                )}
              </Box>
            ))
          ) : (
            <>No Filter(s) found</>
          )}
        </>
      </VStack>
    </VStack>
  );
};

export default FilterPane;
